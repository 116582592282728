import { RadioSelect, SelectProps } from '@atlaskit/select'
import React, { useCallback, useMemo } from 'react'

import { TransitionInformingStatus } from '../../../../../graphql'
import { INFORMING_STATUS_LABEL } from '../../utils'

type Option = { value: TransitionInformingStatus; label: string }

const OPTIONS: Option[] = [
  TransitionInformingStatus.HOLD,
  TransitionInformingStatus.HOLD_TO_INFORM_ASSISTANT,
  TransitionInformingStatus.HOLD_TO_INFORM_EXECUTIVE,
  TransitionInformingStatus.MANAGER_TO_INFORM_ASSISTANT,
  TransitionInformingStatus.EXECUTIVE_TO_INFORM_ASSISTANT,
  TransitionInformingStatus.SPECIALIST_TO_INFORM_EXECUTIVE,
  TransitionInformingStatus.ASSISTANT_TO_INFORM_EXECUTIVE,
  TransitionInformingStatus.ASSISTANT_INFORMED,
  TransitionInformingStatus.EXECUTIVE_INFORMED,
].map((value) => ({
  value,
  label: INFORMING_STATUS_LABEL[value],
}))

type Props = Omit<SelectProps<Option>, 'options'> & {
  value?: TransitionInformingStatus | undefined | null
  onChangeValue?: (
    newValue: TransitionInformingStatus | undefined | null,
  ) => void
}

const TransitionInformingStatusSelect = ({
  value: _value,
  onChangeValue,
  onChange: _onChange,
  ...selectProps
}: Props) => {
  const value = useMemo(() => {
    if (_value) {
      return OPTIONS.find(({ value }) => _value === value)
    }
  }, [_value])

  const onChange = useCallback(
    (newValue: Option) => {
      _onChange?.(newValue)
      onChangeValue?.(newValue?.value)
    },
    [_onChange, onChangeValue],
  )

  return (
    <RadioSelect
      options={OPTIONS}
      value={value}
      // @ts-ignore
      onChange={onChange}
      placeholder={'Current status'}
      isClearable={false}
      isSearchable={false}
      {...selectProps}
    />
  )
}

export default TransitionInformingStatusSelect
