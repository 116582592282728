import { DateTime } from 'luxon'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

import { InfoNotice as _InfoNotice } from '../pages/operations/Transitions/Panel/styled'

import { Dd as _Dd, Dl, Dt } from './DlDtDd'

const Dd = styled(_Dd)`
  min-width: 150px;
`

const InfoNotice = styled(_InfoNotice)`
  margin-top: 16px;
`

type Props = ComponentProps<typeof InfoNotice> & {
  dateTime?: Date | null | undefined
  title?: string
}

const TzInfoNotice = ({
  dateTime,
  title = 'See in all time-zones',
  ...props
}: Props) =>
  !dateTime ? null : (
    <InfoNotice
      type={'info'}
      title={title}
      noTopUnset
      placement={'top'}
      {...props}
    >
      <Dl dtWidth={'100px'}>
        <Dt>{'US East'}</Dt>
        <Dd>
          {DateTime.fromJSDate(dateTime, {
            zone: 'America/New_York',
          }).toLocaleString(DateTime.DATETIME_SHORT)}
        </Dd>
        <Dt>{'US Central'}</Dt>
        <Dd>
          {DateTime.fromJSDate(dateTime, {
            zone: 'America/Chicago',
          }).toLocaleString(DateTime.DATETIME_SHORT)}
        </Dd>
        <Dt>{'US Mountain'}</Dt>
        <Dd>
          {DateTime.fromJSDate(dateTime, {
            zone: 'America/Denver',
          }).toLocaleString(DateTime.DATETIME_SHORT)}
        </Dd>
        <Dt>{'US Pacific'}</Dt>
        <Dd>
          {DateTime.fromJSDate(dateTime, {
            zone: 'America/Los_Angeles',
          }).toLocaleString(DateTime.DATETIME_SHORT)}
        </Dd>
        <Dt>{'France & Belgium'}</Dt>
        <Dd>
          {DateTime.fromJSDate(dateTime, {
            zone: 'Europe/Paris',
          }).toLocaleString(DateTime.DATETIME_SHORT)}
        </Dd>
      </Dl>
    </InfoNotice>
  )

export default TzInfoNotice
