import AkDropdownMenu, {
  DropdownMenuStateless as AkDropdownMenuStateless,
  DropdownItem as AkDropdownItem,
} from '@atlaskit/dropdown-menu'
import { DropdownItemProps } from '@atlaskit/dropdown-menu/dist/types/components/item/DropdownItem'
import { OnOpenChangeArgs } from '@atlaskit/dropdown-menu/dist/types/types'
import CopyIcon from '@atlaskit/icon/glyph/copy'
import MoreIcon from '@atlaskit/icon/glyph/more'
import React, { ComponentProps, useCallback } from 'react'
import styled from 'styled-components'

import useSwitch from '../lib/useSwitch'

export { DropdownItemGroup } from '@atlaskit/dropdown-menu'

export const DropdownItem = (props: DropdownItemProps) => (
  <AkDropdownItem {...props} />
)

const Wrapper = styled.div`
  * {
    position: unset;
    box-sizing: unset;
  }
`

type DropdownMenuStatelessProps = Partial<
  ComponentProps<typeof AkDropdownMenuStateless>
> & {
  className?: string
}

export const DropdownMenuStateless = ({
  className,
  ...props
}: DropdownMenuStatelessProps) => (
  <Wrapper className={className}>
    <AkDropdownMenuStateless {...props} />
  </Wrapper>
)

type DropdownMenuProps = Partial<ComponentProps<typeof AkDropdownMenu>> & {
  className?: string
}

export const DropdownMenu = ({ className, ...props }: DropdownMenuProps) => (
  <Wrapper className={className}>
    <AkDropdownMenu {...props} />
  </Wrapper>
)

export default DropdownMenu

export const MoreDropdownMenu = ({
  triggerButtonProps,
  onOpenChange: onOpenChangeProp,
  ...props
}: ComponentProps<typeof DropdownMenuStateless>) => {
  const [isOpen, , , toggle, setIsOpen] = useSwitch(false)

  const onOpenChange = useCallback(
    (onOpenChangeArgs: OnOpenChangeArgs) => {
      setTimeout(() => {
        setIsOpen(onOpenChangeArgs.isOpen)
        onOpenChangeProp?.(onOpenChangeArgs)
      }, 1)
    },
    [onOpenChangeProp, setIsOpen],
  )

  return (
    <DropdownMenuStateless
      triggerType={'button'}
      triggerButtonProps={{
        iconBefore: <MoreIcon size={'small'} label={'more'} />,
        spacing: 'compact',
        appearance: 'subtle',
        onClick: toggle,
        ...triggerButtonProps,
      }}
      position={'bottom right'}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      {...props}
    />
  )
}

type DropdownItemCopyTextProps = ComponentProps<typeof DropdownItem> & {
  text: string
}

export const DropdownItemCopyText = ({
  text,
  ...props
}: DropdownItemCopyTextProps) => (
  <DropdownItem
    onClick={() => navigator.clipboard.writeText(text)}
    elemBefore={<CopyIcon size={'small'} label={''} />}
    description={text}
    {...props}
  />
)
