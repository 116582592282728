import React, { useState, useCallback, ComponentProps } from 'react'

import { UserCategory, BasicUserFragment } from '../../../graphql'
import Modal from '../../Modal'
import Cell from '../Cell'
import UsersList, { SearchInput } from '../List'

interface Props extends ComponentProps<typeof Modal> {
  onSelectUser: (user: BasicUserFragment) => void
  filterUser?: (user: BasicUserFragment) => boolean
  categories?: UserCategory[]
  adminRoles?: string[]
}

const UserSelectorModal = ({
  onSelectUser,
  categories,
  adminRoles,
  filterUser,
  heading = 'Select User',
  ...props
}: Props) => {
  const [search, setSearch] = useState('')

  const onExited = useCallback(() => {
    // Reset search
    setSearch('')
  }, [setSearch])

  return (
    <Modal
      heading={heading}
      width={'small'}
      onCloseComplete={onExited}
      autoFocus
      {...props}
    >
      <div style={{ height: 350 }}>
        <SearchInput onChangeText={setSearch} autoFocus />
        {!!(search || categories?.length || adminRoles?.length) && (
          <UsersList
            filterUser={filterUser}
            search={search}
            categories={categories}
            adminRoles={adminRoles}
            Cell={({ user, ...props }) => (
              <Cell
                user={user}
                {...props}
                selectable
                onClick={() => onSelectUser(user as BasicUserFragment)}
              />
            )}
          />
        )}
      </div>
    </Modal>
  )
}

export default UserSelectorModal
