import { DateTime } from 'luxon'
import { useMemo } from 'react'

import {
  AssistantPerformanceMetricKind,
  onlyIfAssistantUser,
  usePerformanceScoreHistoricalQuery,
} from '../../../../../graphql'
import useNow from '../../../../../lib/useNow'

import HistoricalDataContainer from './HistoricalDataContainer'

const MONTHS_COUNT = 9

interface Props {
  userId: string
}

const HistoricalData = ({ userId }: Props) => {
  const { data, error, loading } = usePerformanceScoreHistoricalQuery({
    variables: { userId },
  })

  // Get the series for the past 9 months
  const user = onlyIfAssistantUser(data?.user)
  const seriesArray = user?.performanceScore?.series

  const now = useNow()
  const pastNineMonths = useMemo(
    () =>
      Array.from(
        { length: MONTHS_COUNT },
        (_, i) => now.minus({ months: i }).monthShort,
      ).reverse(),
    [now],
  )

  const categories = Object.values(AssistantPerformanceMetricKind).map(
    (item) => item,
  )

  const seriesMatrix = useMemo(() => {
    const metricMap: Record<string, string[]> = {}
    categories.forEach((category) => {
      metricMap[category] = []
    })

    const computedScores = pastNineMonths.map((month) => {
      const monthSeries = seriesArray?.find(
        (series) => DateTime.fromISO(series.date).monthShort === month,
      )
      return monthSeries?.value || '-'
    })

    pastNineMonths.forEach((month) => {
      const monthSeries = seriesArray?.find(
        (series) => DateTime.fromISO(series.date).monthShort === month,
      )

      if (monthSeries?.metrics.length) {
        monthSeries.metrics.forEach((metric) => {
          const category = AssistantPerformanceMetricKind[metric.metricKind]
          metricMap[category].push(metric.value.toString() || '-')
        })
      } else {
        categories.forEach((category) => {
          metricMap[category].push('-')
        })
      }
    })

    return [
      ...categories.map((category) => ({
        category,
        metrics: metricMap[category],
      })),
      { category: 'COMPUTED SCORE', metrics: computedScores },
    ]
  }, [pastNineMonths, seriesArray, categories])

  if (!seriesArray) return <span>{`No historical data`}</span>

  return (
    <HistoricalDataContainer
      loading={loading}
      error={error}
      months={pastNineMonths}
      seriesMatrix={seriesMatrix}
    />
  )
}

export default HistoricalData
