export default function getQueryParam(
  paramName: string,
  defaultValue = '',
): string | typeof defaultValue {
  try {
    if (window.location.search) {
      // disable lint since we import a URLSearchParams polyfill for IE
      const params = new URLSearchParams(window.location.search) // eslint-disable-line
      const queryValue = params.get(paramName)
      if (queryValue) {
        return queryValue
      }
    }
  } catch (error) {
    console.error('Failed to read param from URL query', error)
  }

  return defaultValue
}
