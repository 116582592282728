import { RadioSelect, SelectProps } from '@atlaskit/select'
import React, { useCallback, useMemo } from 'react'

import { TransitionHandoverStatus } from '../../../../../graphql'
import { HANDOVER_STATUS_LABEL } from '../../utils'

type Option = { value: TransitionHandoverStatus; label: string }

const OPTIONS: Option[] = [
  TransitionHandoverStatus.NOT_NECESSARY,
  TransitionHandoverStatus.TO_BE_SCHEDULED,
  TransitionHandoverStatus.SCHEDULED,
  TransitionHandoverStatus.COMPLETED,
].map((value) => ({
  value,
  label: HANDOVER_STATUS_LABEL[value] || value,
}))

type Props = Omit<SelectProps<Option>, 'options'> & {
  value?: TransitionHandoverStatus | undefined | null
  onChangeValue?: (
    newValue: TransitionHandoverStatus | undefined | null,
  ) => void
}

const TransitionHandoverStatusSelect = ({
  value: _value,
  onChangeValue,
  onChange: _onChange,
  ...selectProps
}: Props) => {
  const value = useMemo(() => {
    if (_value) {
      return OPTIONS.find(({ value }) => _value === value)
    }
  }, [_value])

  const onChange = useCallback(
    (newValue: Option) => {
      _onChange?.(newValue)
      onChangeValue?.(newValue?.value)
    },
    [_onChange, onChangeValue],
  )

  return (
    <RadioSelect
      options={OPTIONS}
      value={value}
      // @ts-ignore
      onChange={onChange}
      placeholder={'Current status'}
      isClearable={false}
      isSearchable={false}
      {...selectProps}
    />
  )
}

export default TransitionHandoverStatusSelect
