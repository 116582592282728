import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import UserAvatar from '../../../../../../components/users/Avatar'
import UserCell from '../../../../../../components/users/Cell'
import {
  Assistantable_ForTransitionFragment,
  Matching_ForTransitionListItemFragment,
} from '../../../../../../graphql'

const Outer = styled.span`
  display: flex;
  align-items: center;
`

type Props = {
  matching: Matching_ForTransitionListItemFragment
  assistant: Assistantable_ForTransitionFragment | null | undefined
}

const Assistant = ({ matching, assistant }: Props) => {
  if (!assistant) {
    return null
  }

  return (
    <Outer>
      <Link
        to={`/users/${assistant.id}/workspaces/${matching.workspace?.id || ''}`}
        target={'_blank'}
      >
        <UserCell user={assistant} />
      </Link>
    </Outer>
  )
}

const Candidates = ({ matching }: Pick<Props, 'matching'>) => {
  return (
    <Outer>
      {matching.candidates?.map((candidate) => (
        <Link
          key={candidate.id}
          to={`/users/${candidate.id}`}
          target={'_blank'}
        >
          <UserAvatar user={candidate.assistant} />
        </Link>
      ))}
    </Outer>
  )
}

type TransitioningAssistantProps = Omit<Props, 'assistant'>

export const TransitioningAssistant = ({
  matching,
  ...props
}: TransitioningAssistantProps) => (
  <Assistant
    matching={matching}
    assistant={matching.transition?.fromAssistant}
    {...props}
  />
)

type EnteringAssistantProps = Omit<Props, 'assistant'>

export const EnteringAssistant = ({
  matching,
  ...props
}: EnteringAssistantProps) =>
  matching.confirmedAssistant ? (
    <Assistant
      matching={matching}
      assistant={matching.confirmedAssistant}
      {...props}
    />
  ) : (
    <Candidates matching={matching} />
  )
