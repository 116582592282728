import { DatePicker } from '@atlaskit/datetime-picker'
import React from 'react'
import styled from 'styled-components'

import { Field } from '../../../components/form'
import {
  useGetPayPeriodQuery,
  useUpdatePayPeriodMutation,
} from '../../../graphql'

const Outer = styled.div`
  margin-bottom: 24px;
`

const PayPeriod = () => {
  const { data, loading: loadingGet } = useGetPayPeriodQuery()
  const [update, { loading: loadingUpdate }] = useUpdatePayPeriodMutation()

  const loading = loadingGet || loadingUpdate

  return (
    <Outer>
      <Field
        label={'Remind Doubles to review their entries by'}
        helperMessage={
          'Reminder sent one business day before this date. Entries lock automatically every Saturday at 11:59am ET, and every 1st of the Month at 11am ET through the last day of the previous month at 11:59pm.'
        }
      >
        <DatePicker
          value={data?.payPeriod.lockTimeTrackingOn}
          onChange={(value) =>
            update({
              variables: {
                input: {
                  lockTimeTrackingOn: value,
                },
              },
            })
          }
          isDisabled={loading}
        />
      </Field>
    </Outer>
  )
}

export default PayPeriod
