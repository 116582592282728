import Button from '@atlaskit/button'
import DragIcon from '@atlaskit/icon/glyph/drag-handler'
import RemoveIcon from '@atlaskit/icon/glyph/editor/remove'
import Select from '@atlaskit/select'
import { colors, borderRadius, typography } from '@atlaskit/theme'
import cloneDeep from 'lodash/cloneDeep'
import truncate from 'lodash/truncate'
import { transparentize } from 'polished'
import React, { useCallback } from 'react'
import { components as ReactSelectComponents } from 'react-select'
import { SortableHandle } from 'react-sortable-hoc'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import AirtableClientFieldNameSelect from '../../../../components/AirtableClientFieldNameSelect'
import Editor from '../../../../components/MarkdownEditor'
import { ToggleStateless as Toggle } from '../../../../components/Toggle'
import {
  Field,
  TextField,
  FieldsRow,
  ImageUrlField,
} from '../../../../components/form'
import {
  TopicFormFieldFragment,
  TopicFormFieldValueConditionFragment,
  TopicFormFieldOptionTextFragment,
  TopicFormOperator,
} from '../../../../graphql'
import useSwitch from '../../../../lib/useSwitch'
import useValues from '../../../../lib/useValues'
import { areEquivalentObjects } from '../../../../lib/utils'
import OptionTagSelect from '../components/OptionTagSelect'

const Outer = styled.div`
  border-radius: ${borderRadius}px;
  border: 2px solid ${colors.backgroundHover};
  padding: 0 8px 8px;
  margin-bottom: 8px;
  background: white;
`

const CompactInner = styled.div`
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Question = styled.div`
  ${typography.h400()}
  margin: 0;
`

const ToggleOuter = styled.div`
  display: flex;
  align-items: center;
`

const Buttons = styled.div`
  padding: 16px 4px;
  position: sticky;
  bottom: 0;
  background-color: ${transparentize(0.2, 'white')};
  display: flex;
  & > * + * {
    margin-left: 8px;
  }
`

export const DragHandle = SortableHandle(styled.div`
  flex: 0 0 auto;
  margin-right: 4px;
  cursor: grab;

  [draggable='true'] & {
    cursor: grabbing;
  }
`)

const Spacer = styled.div`
  flex: 1;
`

const FieldOption = styled.div`
  display: flex;

  & > * + * {
    margin-left: 8px;
  }

  & + & {
    margin-top: 8px;
  }
`

const EMPTY_OPTION: TopicFormFieldOptionTextFragment = Object.freeze({
  text: '',
  tags: [],
})

const EMPTY_FIELD: Omit<TopicFormFieldFragment, 'id'> = Object.freeze({
  __typename: 'TopicFormFieldText',
  question: '',
  message: '',
  options: [],
  maxValues: 1,
  isAllowingAudio: false,
  isAllowingFreeForm: true,
  freeFormPlaceholder: null,
  airtableClientField: null,
})

interface ConditionOption {
  shortLabel: string
  longLabel: string
  value: string
  condition: TopicFormFieldValueConditionFragment
}

const isConditionOption = (o: ConditionOption | null): o is ConditionOption =>
  Boolean(o)

const buildConditionOption = (
  fields: TopicFormFieldFragment[],
  condition: TopicFormFieldValueConditionFragment,
): ConditionOption | null => {
  if (!('text' in condition.value)) {
    return null
  }

  const field = fields.find(({ id }) => id === condition.fieldId)
  if (!field) {
    return null
  }

  return {
    condition,
    value: field.id + ' → ' + condition.value.text,
    longLabel:
      truncate(field.question, { length: 40, separator: ' ' }) +
      ' → ' +
      condition.value.text,
    shortLabel: condition.value.text,
  }
}

interface FormFieldProps {
  field?: TopicFormFieldFragment
  previousFields: TopicFormFieldFragment[]
  onChangeValue: (field: TopicFormFieldFragment | null) => void
}

const FormField = ({
  field: originalField,
  previousFields,
  onChangeValue,
}: FormFieldProps) => {
  const defaultField = originalField || EMPTY_FIELD
  const [editing, edit, hideEdit] = useSwitch(false)
  const [field, { patch, reset }] = useValues(cloneDeep(defaultField))

  const isNewField = !('id' in field)
  const fieldsForConditions = previousFields.filter(
    ({ question, options }) => !!question && options?.length > 0,
  )

  const getConditionOption = buildConditionOption.bind(null, previousFields)

  const cancel = useCallback(() => {
    reset()
    hideEdit()
  }, [reset, hideEdit])

  const save = useCallback(() => {
    onChangeValue({
      id: uuid(),
      ...field,
    })
    hideEdit()
  }, [field, hideEdit, onChangeValue])

  const del = useCallback(() => {
    if (window.confirm('Are you sure you want to delete this question?')) {
      onChangeValue(null)
    }
  }, [onChangeValue])

  if (!editing) {
    return isNewField ? (
      <Button onClick={edit}>{'Add question'}</Button>
    ) : (
      <Outer>
        <CompactInner>
          <DragHandle>
            <DragIcon label={''} />
          </DragHandle>
          <Question>{field.question}</Question>
          <Spacer />
          <Button onClick={edit} appearance={'subtle'}>
            {'Edit'}
          </Button>
        </CompactInner>
      </Outer>
    )
  }

  return (
    <Outer>
      <Field label={'Question'} isRequired>
        <Editor
          value={field.question || ''}
          onChange={(question) => patch({ question })}
          isInline
        />
      </Field>

      <Field label={'Message'} isRequired>
        <Editor
          value={field.message || ''}
          onChange={(message) => patch({ message })}
          isInline={false}
        />
      </Field>

      <Field label={'Illustration'}>
        <ImageUrlField
          value={field.imageUrl}
          onChangeValue={(imageUrl) => patch({ imageUrl })}
        />
      </Field>

      <Field label={'How many answers max?'} isRequired>
        <Select<{ maxValues: null | number }>
          menuPlacement={'auto'}
          value={{ maxValues: field.maxValues || null }}
          creatable={false}
          isSearchable={false}
          isClearable={false}
          options={[
            { maxValues: null },
            ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((maxValues) => ({
              maxValues,
            })),
          ]}
          // @ts-ignore
          getOptionLabel={({ maxValues }) =>
            !maxValues ? 'No limit' : maxValues
          }
          // @ts-ignore
          getOptionValue={({ maxValues }) => maxValues}
          isMulti={false}
          // @ts-ignore
          onChange={({ maxValues }) => patch({ maxValues })}
        />
      </Field>

      <Field label={'Options'}>
        {[...(field.options || []), EMPTY_OPTION].map(
          ({ text, tags }, index) => (
            <FieldOption key={index}>
              <TextField
                isCompact
                value={text || ''}
                isInvalid={index < field.options.length && !text.trim()}
                placeholder={index === field.options.length ? 'New Option' : ''}
                onChangeValue={(text) => {
                  if (index === field.options.length && text.trim()) {
                    patch({
                      options: [
                        ...field.options,
                        { text: text.trim(), tags: [] },
                      ],
                    })
                  } else {
                    field.options[index].text = text
                    patch({ options: [...field.options] })
                  }
                }}
              />
              {index < field.options.length && (
                <>
                  <OptionTagSelect
                    values={tags}
                    onChangeValues={(tags) => {
                      field.options[index].tags = tags
                      patch({ options: [...field.options] })
                    }}
                  />
                  <Button
                    iconAfter={<RemoveIcon label={'Remove'} />}
                    appearance={'subtle'}
                    spacing={'none'}
                    onClick={() => {
                      field.options.splice(index, 1)
                      patch({ options: [...field.options] })
                    }}
                  />
                </>
              )}
            </FieldOption>
          ),
        )}
      </Field>

      <Field label={'Other ways to answer'}>
        <FieldsRow>
          <ToggleOuter>
            <Toggle
              size={'large'}
              isChecked={!!field.isAllowingFreeForm}
              onChange={() =>
                patch({ isAllowingFreeForm: !field.isAllowingFreeForm })
              }
            />
            {'Free-form'}
          </ToggleOuter>
          {!!field.isAllowingFreeForm && (
            <TextField
              isCompact
              value={field.freeFormPlaceholder || ''}
              placeholder={'Free-form placeholder'}
              onChangeValue={(freeFormPlaceholder) =>
                patch({ freeFormPlaceholder })
              }
            />
          )}
        </FieldsRow>
        <ToggleOuter>
          <Toggle
            size={'large'}
            isChecked={!!field.isAllowingAudio}
            onChange={() => patch({ isAllowingAudio: !field.isAllowingAudio })}
          />
          {'Audio message'}
        </ToggleOuter>
      </Field>

      {fieldsForConditions.length > 0 && (
        <Field
          label={'Displayed if'}
          helperMessage={
            'If ONE OR MORE of the conditions above is true, then this question is displayed.'
          }
        >
          <Select<ConditionOption>
            placeholder={'Question is always displayed'}
            // @ts-ignore
            isMulti
            options={fieldsForConditions.map(({ id, question, options }) => ({
              label: question,
              options: options
                .map(({ text }) =>
                  getConditionOption({ fieldId: id, value: { text } }),
                )
                .filter(isConditionOption),
            }))}
            getOptionLabel={({ shortLabel }) => shortLabel}
            value={
              // @ts-ignore
              field.enabledIf?.conditions
                ?.map(getConditionOption)
                .filter(isConditionOption) || []
            }
            isOptionSelected={(option) =>
              // @ts-ignore
              field.enabledIf?.conditions?.some(
                (condition: TopicFormFieldValueConditionFragment) =>
                  areEquivalentObjects(condition, option.condition),
              )
            }
            // @ts-ignore
            onChange={(options: null | ConditionOption[]) => {
              if (!options) {
                patch({ enabledIf: null })
              } else {
                patch({
                  enabledIf: {
                    operator: TopicFormOperator.OR,
                    conditions: options.map(({ condition }) => condition),
                  },
                })
              }
            }}
            components={{
              MultiValueLabel(props: any) {
                return (
                  <ReactSelectComponents.MultiValueLabel
                    {...props}
                    children={props.data.longLabel || props.children}
                  />
                )
              },
            }}
          />
        </Field>
      )}

      <Field label={'Record answer in Airtable Client as'}>
        <AirtableClientFieldNameSelect
          useAirtableValues
          value={field.airtableClientField}
          onChangeValue={(airtableClientField) =>
            patch({ airtableClientField })
          }
          isClearable
          spacing={'compact'}
        />
      </Field>

      <Field label={'Configuration'}>
        <ToggleOuter>
          <Toggle
            size={'large'}
            isChecked={!!field.isRequired}
            onChange={() => patch({ isRequired: !field.isRequired })}
          />
          {'Is Required'}
        </ToggleOuter>
      </Field>

      <Buttons>
        <Button
          appearance={'primary'}
          onClick={save}
          isDisabled={areEquivalentObjects(field, defaultField)}
        >
          {'Save'}
        </Button>
        <Button onClick={cancel}>{'Cancel'}</Button>
        <Spacer />
        {!isNewField && (
          <Button appearance={'danger'} onClick={del}>
            {'Delete'}
          </Button>
        )}
      </Buttons>
    </Outer>
  )
}

export default FormField
