import Lozenge from '@atlaskit/lozenge'
// @ts-ignore
import { Item as AkItem } from '@atlaskit/navigation-next'
import React, { ComponentProps } from 'react'

import { UserAvatarsGroup } from '../../../../components/users/Avatar'
import { AutomationJob_ItemFragment } from '../../../../graphql'
import { formatDateTime, getSectionedName } from '../../utils'
import {
  hasFailed,
  isPending,
  isCancelled,
  hasCompleted,
  isReviewed,
  hasError,
} from '../utils'

import {
  ItemOuter,
  AutomationName,
  ITEM_HEIGHT,
  Column,
  MetaColumn,
  SectionName,
} from './styled'

export { ITEM_HEIGHT }

type JobItemProps = ComponentProps<typeof ItemOuter> & {
  job: AutomationJob_ItemFragment
  onClick?: (jobId: string) => void
}

const JobItem = ({ job, onClick, ...props }: JobItemProps) => {
  const user = job.user || job.executive || job.assistant
  const { workspace } = job

  const { section, name } = getSectionedName(job.automation.name)

  return (
    <ItemOuter
      {...props}
      hasFailed={hasFailed(job)}
      isPending={isPending(job)}
      hasCompleted={hasCompleted(job)}
      isCancelled={isCancelled(job)}
      onClick={() => onClick?.(job.id)}
    >
      <Column>
        <SectionName>{section}</SectionName>
        <AutomationName>{name}</AutomationName>
        {workspace ? (
          <AkItem
            text={workspace.executives[0]?.profile.displayName}
            subText={workspace.assistants[0]?.profile.displayName}
            // @ts-ignore
            before={() => (
              <UserAvatarsGroup
                size={'small'}
                maxCount={2}
                users={[...workspace.executives, ...workspace.assistants]}
              />
            )}
            spacing={'compact'}
          />
        ) : user ? (
          <AkItem
            text={user.profile.displayName}
            // @ts-ignore
            before={() => (
              <UserAvatarsGroup size={'small'} maxCount={2} users={[user]} />
            )}
            spacing={'compact'}
          />
        ) : null}
      </Column>

      <MetaColumn>
        {job.endedAt ? (
          <Lozenge appearance={'success'}>
            {formatDateTime(job.endedAt)}
          </Lozenge>
        ) : job.cancelledAt ? (
          <Lozenge appearance={'moved'}>
            {formatDateTime(job.cancelledAt)}
          </Lozenge>
        ) : hasFailed(job) ? (
          <Lozenge appearance={'removed'}>
            {formatDateTime(job.updatedAt)}
          </Lozenge>
        ) : (
          <Lozenge appearance={'default'}>
            {formatDateTime(job.delayUntil)}
          </Lozenge>
        )}
        {hasError(job) && <Lozenge appearance={'removed'}>{'Errors'}</Lozenge>}
        {isReviewed(job) && (
          <Lozenge appearance={'moved'}>{'Reviewed'}</Lozenge>
        )}
      </MetaColumn>
    </ItemOuter>
  )
}

export default JobItem
