import { ApolloError } from '@apollo/client'
import Banner from '@atlaskit/banner'
import ErrorIcon from '@atlaskit/icon/glyph/error'
import React from 'react'

type Props = {
  error?: ApolloError | Error
}

const ErrorBanner = ({ error }: Props) => (
  <Banner
    appearance={'error'}
    icon={<ErrorIcon label={'Error icon'} secondaryColor={'inherit'} />}
    isOpen={!!error}
  >
    {error?.message}
  </Banner>
)

export default ErrorBanner
