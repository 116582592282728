import Select, { CreatableSelect, OptionType } from '@atlaskit/select'
import uniq from 'lodash/uniq'
import React, { ComponentProps } from 'react'
import { components as ReactSelectComponents } from 'react-select'

type Props = ComponentProps<typeof Select> & {
  isCreatable?: boolean
  values?: string[] | null
  onChangeValues: (newValues: string[]) => void
}

const NEW_EXECUTIVE_WORKSPACE = 'NEW_EXECUTIVE_WORKSPACE'
const NEW_SANDBOX_WORKSPACE = 'NEW_SANDBOX_WORKSPACE'
const ONBOARDING = 'ONBOARDING'
const ONBOARDING_CALL = 'ONBOARDING_CALL'
const FIRST_30_DAYS = 'FIRST_30_DAYS'
const TECH_CHECK_UP = 'TECH_CHECK_UP'
const DISCOVER = 'DISCOVER'
const TODO_CREATION = 'TODO_CREATION'

const getOptionLabel = ({ value }: OptionType): string => {
  let label = String(value)

  if (value === NEW_EXECUTIVE_WORKSPACE) {
    label += ' (auto-created on new executive workspace)'
  }
  if (value === NEW_SANDBOX_WORKSPACE) {
    label += ' (auto-created on new sandbox workspace)'
  }
  if (value === ONBOARDING) {
    label += ' (created during exec onboarding)'
  }
  if (value === ONBOARDING_CALL) {
    label += ' (part of new pre-ob)'
  }
  if (value === FIRST_30_DAYS) {
    label += ' (part of new pre-ob)'
  }
  if (value === TECH_CHECK_UP) {
    label += ' (in addition to pre-ob labels)'
  }
  if (value === DISCOVER) {
    label += ' ("Templates" section of the app)'
  }
  if (value === TODO_CREATION) {
    label += ' (on iOS)'
  }

  return label
}

const LabelsSelect = ({
  values,
  onChangeValues,
  isCreatable,
  ...props
}: Props) => {
  const S = isCreatable ? CreatableSelect : Select

  return (
    <S
      placeholder={'Labels'}
      noOptionsMessage={() => 'Add a label'}
      value={values?.map((value) => ({ value }))}
      isClearable
      isMulti
      options={uniq([
        ONBOARDING,
        NEW_EXECUTIVE_WORKSPACE,
        NEW_SANDBOX_WORKSPACE,
        ONBOARDING_CALL,
        FIRST_30_DAYS,
        TECH_CHECK_UP,
        DISCOVER,
        TODO_CREATION,
        ...(values || []),
      ]).map((value) => ({ value }))}
      // @ts-expect-error BS
      getOptionLabel={getOptionLabel}
      // @ts-expect-error BS
      getOptionValue={({ value }) => value}
      onCreateOption={(industry: string) =>
        onChangeValues(uniq([...(values || []), industry]))
      }
      onChange={(values) =>
        // @ts-ignore
        onChangeValues((values || []).map(({ value }) => value))
      }
      components={{
        MultiValueLabel(props: any) {
          return (
            <ReactSelectComponents.MultiValueLabel
              {...props}
              children={props.data.value || props.children}
            />
          )
        },
      }}
      {...props}
    />
  )
}
export default LabelsSelect
