import isEqual from 'lodash/isEqual'

const UUID_REGEX =
  /^[0-9a-f]{8}-?[0-9a-f]{4}-?[1-5][0-9a-f]{3}-?[89ab][0-9a-f]{3}-?[0-9a-f]{12}$/i

export function isUuid(id: string) {
  return UUID_REGEX.test(id.toString())
}

export function notFalsy<T>(o: T | false | null | undefined | ''): o is T {
  return !!o || typeof o === 'number'
}

function withoutTypename<T extends Record<string, any>>({
  __typename,
  ...clean
}: T): Omit<T, '__typename'> {
  return clean
}

export function deepCleanTypename<T>(dirty: any): T {
  // Scalars
  if (!dirty || ['boolean', 'string', 'number'].includes(typeof dirty)) {
    return dirty
  }

  // Arrays
  if (Array.isArray(dirty)) {
    return dirty.map(deepCleanTypename) as unknown as T
  }

  const clean = withoutTypename(dirty)
  for (const key in clean) {
    clean[key] = deepCleanTypename(clean[key])
  }
  return clean
}

export function areEquivalentObjects<A extends object, B extends object>(
  a: A | null | undefined,
  b: B | null | undefined,
): boolean {
  return !!a && !!b && isEqual(deepCleanTypename(a), deepCleanTypename(b))
}
