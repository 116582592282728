import PageHeader from '@atlaskit/page-header'
import { colors } from '@atlaskit/theme'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

import Tabs from '../../../../components/Tabs'
import { FeatureFragment } from '../../../../graphql'

import AppVersions from './AppVersions'
import Targets from './Targets'

const Outer = styled.div<ComponentProps<'div'>>`
  box-shadow: 0 0 0 2px ${colors.backgroundHover},
    0 0 10px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: stretch;
  padding: 0 24px;
  overflow: auto;
  max-width: 800px;
  min-width: 600px;
`

const TabContent = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`

interface FeatureProps {
  feature: FeatureFragment
}

const Feature = ({ feature }: FeatureProps) => {
  const tabs = [
    {
      label: 'Workspaces',
      content: (
        <TabContent>
          <Targets feature={feature} />
        </TabContent>
      ),
    },
    {
      label: 'Apps',
      content: (
        <TabContent>
          <AppVersions feature={feature} />
        </TabContent>
      ),
    },
  ]

  return (
    <>
      <PageHeader>{feature.id}</PageHeader>
      <Tabs leftTabs={tabs} />
    </>
  )
}

type MaybeFeatureProps = Partial<FeatureProps>

const MaybeFeature = ({ feature }: MaybeFeatureProps) => (
  <Outer>{!!feature && <Feature feature={feature} />}</Outer>
)

export default MaybeFeature
