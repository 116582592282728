import Button, { ButtonGroup } from '@atlaskit/button'
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut'
import Select, { CreatableSelect } from '@atlaskit/select'
import TableTree from '@atlaskit/table-tree'
import TextArea from '@atlaskit/textarea'
import TextField from '@atlaskit/textfield'
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber'
import uniq from 'lodash/uniq'
import { transparentize } from 'polished'
import React, { ComponentProps, useState, useCallback } from 'react'
import styled from 'styled-components'

import DateFormater from '../../../components/DateFormater'
import { ErrorEmptyState } from '../../../components/EmptyState'
import Guard from '../../../components/Guard'
import MarkdownEditor from '../../../components/MarkdownEditor'
import PopupButton from '../../../components/PopupButton'
import Section from '../../../components/Section'
import SkillsSelect from '../../../components/SkillsSelect'
import { LoadingSpinner } from '../../../components/Spinner'
import { ToggleStateless as Toggle } from '../../../components/Toggle'
import { Field, FieldsRow } from '../../../components/form'
import UserCell from '../../../components/users/Cell'
import {
  useGetAssistantQuery,
  useUpdateAssistantMutation,
  FullUserFragment as FullUser,
  UpdateAssistantInput,
  MobileOs,
  namedOperations,
  UserCategory,
  useUpdateUserMutation,
  isAssistantable,
  isOfType,
  AssistantableHourlyRateUpdateFragment,
} from '../../../graphql'

import useQueryHourlyRateUpdates from './useQueryHourlyRateUpdates'

const Outer = styled.div``

const Buttons = styled.div`
  padding: 16px 4px;
  position: sticky;
  bottom: -24px;
  background-color: ${transparentize(0.2, 'white')};
`

const VisitLink = styled.a`
  margin-left: 8px;
  margin-right: 8px;
`

const RateRow = styled.div`
  display: flex;

  & > div {
    min-width: 80px;
  }

  & > div:first-child {
    min-width: 150px;
  }

  & > div:last-child {
    min-width: 100px;
  }

  & > div + div {
    margin-left: 16px;
  }
`

function formatPhoneNumber(phone?: string): string | undefined {
  if (!phone) {
    return
  }

  const pn = PhoneNumberUtil.getInstance().parse(phone)
  if (!pn) {
    return
  }

  return PhoneNumberUtil.getInstance().format(
    pn,
    PhoneNumberFormat.INTERNATIONAL,
  )
}

interface Props extends ComponentProps<'div'> {
  user: Pick<FullUser, 'id'> & Partial<FullUser>
}

const currencySymbol = (code: string) =>
  (0)
    .toLocaleString('en-US', { style: 'currency', currency: code })
    .split('0')[0]
    .trim()

interface FormValues extends UpdateAssistantInput {
  position?: string | null
  experienceHighlights?: string | null
  // Just so that we can mess around with `values[key] = blablabla`
  [key: string]: any
}

interface FormProps extends ComponentProps<'div'> {
  assistantId: string
  category?: null | UserCategory
  defaultValues: FormValues
  onChangeValues: (values: FormValues) => void
  userEmails: string[]
  phoneNumber?: string
}

const AssistantInfoForm = ({
  assistantId,
  category,
  defaultValues,
  onChangeValues,
  userEmails,
  children,
  phoneNumber,
}: FormProps) => {
  const [newValues, setNewValues] = useState<FormValues>({})

  const [queryHourlyRateUpdates, assistantHourlyRateUpdatesQuery] =
    useQueryHourlyRateUpdates(assistantId)

  const setValues = useCallback(
    (values: Partial<FormValues>) => {
      Object.keys(values).forEach((key) => {
        if (values[key] === '') {
          values[key] = null
        }
      })

      setNewValues((newValues) => ({
        ...newValues,
        ...values,
      }))
    },
    [setNewValues],
  )

  const hasChanged = (key: string) => newValues[key] !== defaultValues[key]
  const changed = Object.keys(newValues).some(hasChanged)

  const state = {
    ...defaultValues,
    ...newValues,
  }

  return (
    <Outer>
      <Section title={'Exec Onboarding'}>
        <Field
          label={'Bio'}
          helperMessage={'Displayed to executive during onboarding'}
          isRequired
        >
          <TextArea
            placeholder={'Present in a few words'}
            value={state.biography || ''}
            onChange={({ currentTarget: { value: biography } }) =>
              setValues({ biography })
            }
          />
        </Field>

        <Field
          label={'Experience Highlights'}
          helperMessage={
            '1 to 3 bullet points, displayed to executive during onboarding'
          }
        >
          <MarkdownEditor
            value={state.experienceHighlights || ''}
            onChange={(experienceHighlights) =>
              setValues({ experienceHighlights })
            }
            minHeight={50}
          />
        </Field>

        <Field
          label={'Calendly'}
          helperMessage={'To schedule onboarding call'}
          isRequired
        >
          <TextField
            placeholder={'https://calendly.com/...'}
            value={state.schedulingUrl || ''}
            onChange={({ currentTarget: { value: schedulingUrl } }) =>
              setValues({ schedulingUrl })
            }
            elemAfterInput={
              !!state.schedulingUrl && (
                <VisitLink href={state.schedulingUrl} target={'calendly'}>
                  <ShortcutIcon size={'small'} label={'Visit Calendly'} />
                </VisitLink>
              )
            }
          />
        </Field>
      </Section>

      <Section title={'Who’s Who'}>
        {category === UserCategory.TEAM && (
          <Field
            label={'Position'}
            helperMessage={'For team members, describe role at Double HQ'}
          >
            <TextField
              value={state.position || ''}
              onChange={({ currentTarget: { value: position } }) =>
                setValues({ position })
              }
            />
          </Field>
        )}

        <Field
          label={'Fun Facts'}
          helperMessage={'For presentation to other assistants, not to execs'}
        >
          <TextArea
            value={state.funFacts || ''}
            onChange={({ currentTarget: { value: funFacts } }) =>
              setValues({ funFacts })
            }
          />
        </Field>
        <Field label={'Ask me About'}>
          <SkillsSelect
            isCreatable
            values={state.skills}
            onChangeValues={(skills) => setValues({ skills })}
          />
        </Field>
      </Section>

      <Section title={'Communication'}>
        <FieldsRow>
          <Field label={'Primary Account'} isRequired>
            <Select<{ primaryEmail: string }>
              value={
                state.primaryEmail
                  ? { primaryEmail: state.primaryEmail }
                  : undefined
              }
              creatable={false}
              isSearchable={false}
              isClearable={false}
              options={userEmails.map((primaryEmail) => ({ primaryEmail }))}
              getOptionLabel={({ primaryEmail }) => primaryEmail}
              getOptionValue={({ primaryEmail }) => primaryEmail}
              isMulti={false}
              // @ts-ignore
              onChange={({ primaryEmail }) => setValues({ primaryEmail })}
            />
          </Field>

          <Field label={'Personal Email'} isRequired>
            <CreatableSelect
              value={
                state.personalEmail ? { value: state.personalEmail } : undefined
              }
              isClearable={false}
              options={uniq(
                [state.personalEmail, ...userEmails].filter(
                  (e): e is string => !!e,
                ),
              ).map((value) => ({ value }))}
              getOptionLabel={({ value }) => value || ''}
              getOptionValue={({ value }) => value}
              onCreateOption={(personalEmail: string) =>
                setValues({ personalEmail })
              }
              // @ts-ignore
              onChange={({ value }) => setValues({ personalEmail: value })}
            />
          </Field>
        </FieldsRow>

        <FieldsRow>
          <Field label={'Displayed Phone Number'}>
            <TextField
              placeholder={'Phone Number'}
              value={formatPhoneNumber(phoneNumber)}
              isReadOnly
            />
          </Field>
        </FieldsRow>

        <FieldsRow>
          <Field label={'Slack Username'}>
            <TextField
              placeholder={'Username'}
              value={state.slackUsername || ''}
              onChange={({ currentTarget: { value: slackUsername } }) =>
                setValues({ slackUsername })
              }
              elemBeforeInput={<span style={{ marginLeft: 8 }}>{'@'}</span>}
            />
          </Field>
          <Field label={'Slack User ID'}>
            <TextField
              placeholder={'User ID (eg: UN8T67WE)'}
              value={state.slackUserId || ''}
              onChange={({ currentTarget: { value: slackUserId } }) =>
                setValues({ slackUserId })
              }
            />
          </Field>
        </FieldsRow>

        <FieldsRow>
          <Field label={'Mobile OS'}>
            <Select<{ mobileOs: MobileOs }>
              value={state.mobileOs ? { mobileOs: state.mobileOs } : undefined}
              creatable={false}
              isSearchable={false}
              isClearable={false}
              options={Object.values(MobileOs).map((mobileOs) => ({
                mobileOs,
              }))}
              getOptionLabel={({ mobileOs }) => {
                switch (mobileOs) {
                  case MobileOs.ANDROID:
                    return 'Android'
                  case MobileOs.IOS:
                    return 'iOS'
                }
              }}
              getOptionValue={({ mobileOs }) => mobileOs}
              isMulti={false}
              // @ts-ignore
              onChange={({ mobileOs }) => setValues({ mobileOs })}
            />
          </Field>

          <Field label={'Send Notifications over Slack'}>
            <Toggle
              size={'large'}
              isChecked={!!state.hasSlackPush}
              isDisabled={!(state.slackUserId && state.slackUsername)}
              onChange={() => setValues({ hasSlackPush: !state.hasSlackPush })}
            />
          </Field>
        </FieldsRow>
      </Section>

      <Guard operationId={'AssistantUser.hourlyRate'} policy={'blur'}>
        <Section title={'Justworks'}>
          <Field label={'Given name'}>
            <TextField
              placeholder={'Given name'}
              value={state.justworksGivenName || ''}
              onChange={({ currentTarget: { value: justworksGivenName } }) =>
                setValues({ justworksGivenName })
              }
            />
          </Field>
          <Field label={'Family name'}>
            <TextField
              placeholder={'Family name'}
              value={state.justworksFamilyName || ''}
              onChange={({ currentTarget: { value: justworksFamilyName } }) =>
                setValues({ justworksFamilyName })
              }
            />
          </Field>
          <Field label={'Vendor name'}>
            <TextField
              placeholder={'Vendor name'}
              value={state.justworksVendorName || ''}
              onChange={({ currentTarget: { value: justworksVendorName } }) =>
                setValues({ justworksVendorName })
              }
            />
          </Field>
          <Field label={'EIN'}>
            <TextField
              placeholder={'EIN'}
              value={state.ein || ''}
              onChange={({ currentTarget: { value: ein } }) =>
                setValues({ ein })
              }
            />
          </Field>
        </Section>

        <Section title={'Admin'}>
          <RateRow>
            <Field label={'Currency'}>
              <Select<{ currency: string }>
                placeholder={''}
                value={
                  state.currency ? { currency: state.currency } : undefined
                }
                isSearchable={false}
                isClearable={false}
                options={['USD', 'EUR', 'CAD'].map((currency) => ({
                  currency,
                }))}
                getOptionLabel={({ currency }) => currencySymbol(currency)}
                getOptionValue={({ currency }) => currency}
                isMulti={false}
                // @ts-ignore
                onChange={({ currency }) => setValues({ currency })}
              />
            </Field>

            <Field label={'Hourly Rate'}>
              <TextField
                placeholder={''}
                step={1}
                min={10}
                max={100}
                inputMode={'numeric'}
                type={'number'}
                value={state.hourlyRate || ''}
                onChange={({ currentTarget: { value: hourlyRate } }) =>
                  setValues({
                    hourlyRate: hourlyRate ? parseFloat(hourlyRate) : null,
                  })
                }
              />
            </Field>
            <Field label={'Rate history'}>
              <div>
                <PopupButton
                  onOpen={queryHourlyRateUpdates}
                  content={() => {
                    if (assistantHourlyRateUpdatesQuery.loading) {
                      return 'Loading'
                    }

                    if (
                      !isAssistantable(
                        assistantHourlyRateUpdatesQuery.data?.assistant,
                      )
                    ) {
                      return 'Not available'
                    }

                    return (
                      <TableTree
                        headers={['Rate', 'On', 'Updated by']}
                        columnWidths={['60px', '110px', '280px']}
                        columns={[
                          ({
                            hourlyRate,
                          }: AssistantableHourlyRateUpdateFragment) => (
                            <span>{hourlyRate.toFixed(2)}</span>
                          ),
                          ({
                            updatedAt,
                          }: AssistantableHourlyRateUpdateFragment) => (
                            <DateFormater
                              dateTime={updatedAt}
                              format={{
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                              }}
                            />
                          ),
                          ({
                            updatedBy,
                          }: AssistantableHourlyRateUpdateFragment) =>
                            !!updatedBy && (
                              <UserCell size={'small'} user={updatedBy} />
                            ),
                        ]}
                        items={
                          assistantHourlyRateUpdatesQuery.data?.assistant.hourlyRateUpdates?.map(
                            (content, index) => ({
                              id: index,
                              content,
                            }),
                          ) || []
                        }
                      />
                    )
                  }}
                  buttonProps={{
                    isLoading: assistantHourlyRateUpdatesQuery.loading,
                    children: 'Review',
                  }}
                  placement={'top'}
                />
              </div>
            </Field>
          </RateRow>

          <Field label={'Years of relevant experience'}>
            <TextField
              placeholder={''}
              step={1}
              min={0}
              max={40}
              inputMode={'numeric'}
              type={'number'}
              value={state.yearsOfExperience || ''}
              onChange={({ currentTarget: { value: yearsOfExperience } }) =>
                setValues({
                  yearsOfExperience: yearsOfExperience
                    ? parseInt(yearsOfExperience)
                    : null,
                })
              }
            />
          </Field>

          <Field label={'Lever'}>
            <TextField
              placeholder={'Candidate ID'}
              value={state.leverId || ''}
              onChange={({ currentTarget: { value: leverId } }) =>
                setValues({ leverId })
              }
              elemAfterInput={
                !!state.leverId && (
                  <VisitLink
                    href={`https://hire.lever.co/candidates/${encodeURIComponent(
                      state.leverId || '',
                    )}`}
                    target={'lever'}
                  >
                    <ShortcutIcon size={'small'} label={'Visit Lever'} />
                  </VisitLink>
                )
              }
            />
          </Field>
        </Section>
      </Guard>

      {changed && (
        <Buttons>
          <ButtonGroup>
            <Button
              appearance={'primary'}
              onClick={() => onChangeValues(newValues)}
            >
              {'Save'}
            </Button>
            <Button onClick={() => setNewValues({})}>{'Cancel'}</Button>
          </ButtonGroup>
        </Buttons>
      )}

      {children}
    </Outer>
  )
}

const AssistantInfo = ({ user }: Props) => {
  const {
    data,
    loading: getLoading,
    error: getError,
  } = useGetAssistantQuery({
    variables: { userId: user.id },
  })
  const [updateAssistant, { loading: updateLoading, error: updateError }] =
    useUpdateAssistantMutation({
      refetchQueries: [namedOperations.Query.GetUserFieldsOptions],
    })
  const [updateUser, { loading: updateUserLoading, error: updateUserError }] =
    useUpdateUserMutation()

  const update = useCallback(
    ({ position, experienceHighlights, ...values }: FormValues) => {
      if (Object.keys(values).length > 0) {
        updateAssistant({
          variables: {
            userId: user.id,
            input: values,
          },
        })
      }
      if (
        typeof position !== 'undefined' ||
        typeof experienceHighlights !== 'undefined'
      ) {
        updateUser({
          variables: {
            userId: user.id,
            input: { position, experienceHighlights },
          },
        })
      }
    },
    [updateAssistant, updateUser, user.id],
  )

  const loading = getLoading || updateLoading || updateUserLoading
  const error = getError || updateError || updateUserError

  if (error) {
    return <ErrorEmptyState error={error} />
  }

  if (!(data && isAssistantable(data.assistant) && isAssistantable(user))) {
    return null
  }

  const {
    biography,
    funFacts,
    skills,
    hourlyRate,
    currency,
    justworksVendorName,
    justworksGivenName,
    justworksFamilyName,
    ein,
    leverId,
    yearsOfExperience,
    slackUsername,
    slackUserId,
    hasSlackPush,
    mobileOs,
    primaryEmail,
    personalEmail,
    schedulingUrl,
  } = data?.assistant

  return (
    <AssistantInfoForm
      assistantId={user.id}
      category={user.category}
      userEmails={
        user.accounts
          ?.map((account) => account.profile?.email)
          .filter(Boolean) as string[]
      }
      phoneNumber={
        (isOfType('AssistantUser')(user) && user.phoneNumber) || undefined
      }
      defaultValues={{
        biography,
        funFacts,
        skills,
        hourlyRate,
        currency,
        justworksVendorName,
        justworksGivenName,
        justworksFamilyName,
        ein,
        leverId,
        yearsOfExperience,
        slackUsername,
        slackUserId,
        hasSlackPush,
        mobileOs,
        primaryEmail,
        personalEmail,
        schedulingUrl,
        position: (isOfType('HqUser')(user) && user.position) || undefined,
        experienceHighlights: user.experienceHighlights,
      }}
      onChangeValues={update}
    >
      <LoadingSpinner show={loading} />
    </AssistantInfoForm>
  )
}

export default AssistantInfo
