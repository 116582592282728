import Drawer from '@atlaskit/drawer'
import FilterIcon from '@atlaskit/icon/glyph/filter'
import React, { useState } from 'react'
import styled from 'styled-components'

import PopupButton from '../../../components/PopupButton'
import useValues from '../../../lib/useValues'

import Detail from './Detail'
import FilterEditor from './FilterEditor'
import List from './List'
import { ListFilter } from './utils'

const Outer = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  min-width: 500px;
  max-width: 1100px;
  height: 100%;
`

type Props = {
  filter: ListFilter['fixed']
}

const AutomationJobsInline = ({ filter: fixedFilter }: Props) => {
  const [selectedJobId, selectJobId] = useState<string>()
  const [filter, { patch: patchFilters }] = useValues<ListFilter>({
    fixed: fixedFilter || {},
  })

  return (
    <Outer>
      <PopupButton
        content={() => (
          <FilterEditor isCompact value={filter} onPatch={patchFilters} />
        )}
        buttonProps={{
          iconBefore: <FilterIcon label={'Filter'} />,
        }}
      />

      <List filter={filter} onClickItem={selectJobId} />
      <Drawer
        width={'wide'}
        onClose={() => selectJobId(undefined)}
        isOpen={!!selectedJobId}
      >
        <Detail automationJobId={selectedJobId} />
      </Drawer>
    </Outer>
  )
}
export default AutomationJobsInline
