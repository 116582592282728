import { LoadingButton as Button } from '@atlaskit/button'
import { FormSection } from '@atlaskit/form'
import AddIcon from '@atlaskit/icon/glyph/add'
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message'
import { colors } from '@atlaskit/theme'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import Modal from '../../../../components/Modal'
import { LoadingSpinner } from '../../../../components/Spinner'
import {
  BasicWorkspaceFragment,
  useAddWorkspaceAssistantMutation,
  useGetWorkspaceQuery,
  useReadTopicsMutation,
  useSpectateWorkspaceMutation,
  useCompleteTransitionMutation,
} from '../../../../graphql'
import useSwitch from '../../../../lib/useSwitch'

import SelectAssistantModal, {
  Values as SelectAssistantValues,
} from './SelectAssistantModal'
import User from './User'

const SectionMessageOuter = styled.div`
  margin-top: 24px;
  color: ${colors.text};

  section h1 {
    margin-top: 0;
  }
`

const FormSectionButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
`

const ModalMessageOuter = styled.div`
  padding-top: 16px;
`

const renderUsers = (
  users: { id: string }[] | undefined,
  workspace: BasicWorkspaceFragment | undefined,
  showAccounts?: boolean,
  isRemovable?: boolean,
) =>
  (users || []).map(
    (user) =>
      !!workspace && (
        <User
          key={user.id}
          userId={user.id}
          workspace={workspace}
          showAccounts={showAccounts}
          isRemovable={isRemovable}
        />
      ),
  )

type Props = { workspaceId: string }

const WorkspaceUsers = ({ workspaceId }: Props) => {
  const { data: workspaceData, loading: loadingWorkspace } =
    useGetWorkspaceQuery({
      variables: { workspaceId },
    })
  const [showAssistantModal, , hideAssistantModal, toggleAssistantModal] =
    useSwitch(false)
  const [showSpectatorModal, , hideSpectatorModal, toggleSpectatorModal] =
    useSwitch(false)
  const [addWorkspaceAssistant, { loading: loadingAddAssistant }] =
    useAddWorkspaceAssistantMutation()
  const [completeTransition, { loading: loadingCompleteTransition }] =
    useCompleteTransitionMutation({
      variables: {
        input: {
          workspaceId,
        },
      },
    })
  const [
    spectateWorkspace,
    { data: spectateData, loading: loadingSpectate, error: spectateError },
  ] = useSpectateWorkspaceMutation({
    variables: {
      workspaceId,
    },
  })

  const [readTopics, { loading: loadingReadTopics }] = useReadTopicsMutation()

  const loading =
    loadingWorkspace ||
    loadingAddAssistant ||
    loadingReadTopics ||
    loadingCompleteTransition // || loadingSpectate

  const onSubmitAssistant = useCallback(
    async ({
      assistantId,
      markTopicsAsRead,
      ...values
    }: SelectAssistantValues) => {
      hideAssistantModal()
      if (assistantId) {
        await addWorkspaceAssistant({
          variables: {
            input: {
              workspaceId,
              assistantId,
              ...values,
            },
          },
        })
        if (markTopicsAsRead) {
          readTopics({
            variables: {
              input: {
                workspaceId,
                userId: assistantId,
              },
            },
          })
        }
      }
    },
    [addWorkspaceAssistant, hideAssistantModal, readTopics, workspaceId],
  )

  const workspace = workspaceData?.workspace

  const spectateModalActions = useMemo(
    () =>
      spectateData
        ? // Has enabled spectate
          [
            {
              text: 'Open Dashboard',
              onClick: () => [
                window.open(
                  `https://dashboard.withdouble.com/workspaces/${workspaceId}`,
                  '_blank',
                ),
                hideSpectatorModal(),
              ],
            },
            { text: 'Close', onClick: hideSpectatorModal },
          ]
        : // May enable spectate
          [
            {
              text: 'Spectate for 30min',
              isLoading: loadingSpectate,
              onClick: () => spectateWorkspace(),
            },
            { text: 'Cancel', onClick: hideSpectatorModal },
          ],
    [
      spectateData,
      loadingSpectate,
      spectateWorkspace,
      hideSpectatorModal,
      workspaceId,
    ],
  )

  return (
    <>
      {workspace?.isPendingTransition && (
        <SectionMessageOuter>
          <SectionMessage
            appearance={'warning'}
            title={'Pending transition'}
            actions={[
              <SectionMessageAction
                key={'complete-transition'}
                onClick={() => completeTransition()}
              >
                {'Mark transition as complete'}
              </SectionMessageAction>,
            ]}
          >
            {
              "This workspace is transitioning to a new Double. Once they're all set, mark the transition as complete to trigger automations (emails, Slack, etc)."
            }
          </SectionMessage>
        </SectionMessageOuter>
      )}
      {workspace?.hasCoverage && (
        <SectionMessageOuter>
          <SectionMessage
            appearance={'information'}
            title={'Temporary coverage'}
          >
            {
              'This workspace is currently having temporary coverage. Remove the assistant providing coverage to exit this mode. Temporary coverage impacts some features (e.g. weekly digest, CSAT).'
            }
          </SectionMessage>
        </SectionMessageOuter>
      )}

      <FormSection title={'Executives'}>
        {renderUsers(workspace?.executives, workspace, true, false)}
      </FormSection>

      <FormSection title={'Assistants'}>
        <FormSectionButton
          appearance={'subtle'}
          onClick={toggleAssistantModal}
          isLoading={showAssistantModal}
          iconBefore={<AddIcon label={''} />}
        />
        {renderUsers(workspace?.assistants, workspace, true, true)}
      </FormSection>

      <FormSection title={'Spectators'}>
        <FormSectionButton
          appearance={'subtle'}
          onClick={toggleSpectatorModal}
          isLoading={showSpectatorModal}
          iconBefore={<AddIcon label={''} />}
        />
        {renderUsers(workspace?.spectators, workspace, false, true)}
      </FormSection>

      <LoadingSpinner show={loading} />

      <SelectAssistantModal
        isOpen={showAssistantModal}
        onClose={hideAssistantModal}
        onSubmit={onSubmitAssistant}
      />
      <Modal
        isOpen={showSpectatorModal}
        actions={spectateModalActions}
        onClose={hideSpectatorModal}
        heading={'Spectate this workspace'}
        width={'medium'}
        autoFocus={false}
      >
        <b>{'You'}</b>
        {' will have access to this workspace for '}
        <b>{'30 minutes'}</b>
        {' from the Dashboard app.'}
        <br />
        <br />
        {
          'As a spectator, you will have permission to read this workspace data.'
        }
        <br />
        {'You will '}
        <b>{'not'}</b>
        {' be able to modify this workspace data.'}
        <br />
        {'This is '}
        <b>{'not'}</b>
        {' like impersonating the assistant.'}
        <br />
        <br />
        {'You may get logged out from the app after 30 minutes.'}
        <br />
        {'This is to ensure proper respect of the time limitations.'}
        {spectateData ? (
          <ModalMessageOuter>
            <SectionMessage appearance={'success'}>
              <p>{'You have been granted spectator access'}</p>
            </SectionMessage>
          </ModalMessageOuter>
        ) : spectateError ? (
          <ModalMessageOuter>
            <SectionMessage appearance={'error'}>
              <p>{spectateError.message}</p>
            </SectionMessage>
          </ModalMessageOuter>
        ) : null}
      </Modal>
    </>
  )
}

export default WorkspaceUsers
