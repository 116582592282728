import { colors } from '@atlaskit/theme'
import styled from 'styled-components'

export const Outer = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: stretch;
  padding: 8px;
  gap: 8px;
`

export const ListOuter = styled.ul`
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: stretch;
  gap: 8px;
`

export const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom: 1px solid ${colors.N30};
  &:hover {
    background: ${colors.backgroundActive};
  }
`

export const Label = styled.span`
  display: flex;
  gap: 8px;
`
