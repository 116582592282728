import { DateTime } from 'luxon'
import React from 'react'

import { DisplayDateOuter, TimeWrapper } from './styled'

type Props = {
  title: string
  date: string | null | undefined | Date
}

const DisplayDate = ({ title, date }: Props) => {
  const dateAsDateTime = date
    ? typeof date === 'string'
      ? DateTime.fromISO(date)
      : DateTime.fromJSDate(date)
    : null

  const formattedDate = dateAsDateTime
    ? dateAsDateTime.toLocaleString({
        day: 'numeric',
        month: 'short',
      })
    : null

  return (
    <DisplayDateOuter $missingDate={!formattedDate}>
      {' '}
      {title}
      {': '}
      {formattedDate && (
        <TimeWrapper dateTime={formattedDate} title={formattedDate}>
          {formattedDate}
        </TimeWrapper>
      )}
    </DisplayDateOuter>
  )
}

export default DisplayDate
