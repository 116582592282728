import CompletedIcon from '@atlaskit/icon/glyph/check-circle-outline'
import NotCompletedIcon from '@atlaskit/icon/glyph/media-services/preselected'
import { colors, typography } from '@atlaskit/theme'
import { ComponentProps, ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'

export const Outer = styled.div`
  display: flex;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 150px;

  & + & {
    margin-left: 16px;
  }
`

const Panel = styled(Column)`
  justify-content: flex-start;

  > * {
    margin-bottom: 8px;
  }
`

const TabBar = styled.div`
  max-width: 260px;
  min-width: 220px;
  color: ${colors.text};
  box-shadow: inset -2px 0 0 ${colors.backgroundHover};

  & + ${Column} {
    margin-left: 16px;
  }
`

const Tab = styled.div<{ isSelected: boolean }>`
  display: flex;
  padding: 4px 8px;
  justify-content: space-between;
  align-items: center;
  ${typography.h200}
  margin-top: 0;
  min-height: 33px;
  cursor: pointer;
  border-right: 2px solid ${colors.backgroundHover};
  white-space: pre;

  ${(props) =>
    props.isSelected
      ? css`
          color: ${colors.textActive};
          border-right-color: ${colors.primary};
          background: ${colors.backgroundActive};
        `
      : css`
          &:hover {
            color: ${colors.textActive};
            background: ${colors.backgroundHover};
          }
        `}
`

type TabSpecs = {
  label: string
  isChecked?: boolean
  content: ReactNode
}

type Props = Omit<ComponentProps<typeof Outer>, 'children'> & {
  tabs: TabSpecs[]
}

const VerticalTabs = ({ tabs, ...props }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <Outer {...props}>
      <TabBar>
        {tabs.map(({ label, isChecked }, index) => (
          <Tab
            key={index}
            isSelected={selectedIndex === index}
            onClick={() => setSelectedIndex(index)}
          >
            {label}
            {isChecked === true ? (
              <CompletedIcon label={''} primaryColor={colors.green()} />
            ) : isChecked === false ? (
              <NotCompletedIcon
                label={''}
                primaryColor={colors.backgroundHover()}
              />
            ) : null}
          </Tab>
        ))}
      </TabBar>
      <Panel>{tabs[selectedIndex].content}</Panel>
    </Outer>
  )
}

export default VerticalTabs
