import React, { useEffect, useState } from 'react'

import { GraphQLError } from '../graphql'

import Banners from './Banners'

type Props = {
  onGraphQLErrorRef: React.MutableRefObject<(error: any) => void>
}

const GraphQLErrors = ({ onGraphQLErrorRef }: Props) => {
  const [graphQLErrors, setGraphQLErrors] = useState<GraphQLError[]>([])

  useEffect(() => {
    onGraphQLErrorRef.current = (error) => {
      setGraphQLErrors((errors) => [...errors, error])
    }
  }, [onGraphQLErrorRef])

  return (
    <Banners
      onClearAll={() => setGraphQLErrors([])}
      banners={graphQLErrors.map((graphqlError) => ({
        message: `[GraphQL error] Message: ${graphqlError.message}\n\nPath: ${graphqlError.path}`,
        onDismiss: () =>
          setGraphQLErrors((errors) =>
            errors.filter((error) => error !== graphqlError),
          ),
      }))}
    />
  )
}

export default GraphQLErrors
