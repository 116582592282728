import Button from '@atlaskit/button'
import AddIcon from '@atlaskit/icon/glyph/add'
import PageHeader from '@atlaskit/page-header'
import React, { useCallback, useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
} from 'react-sortable-hoc'

import { useUpsertDossierFolderMutation } from '../../../graphql'

import Folder from './Folder'
import { NarrowListOuter as ListOuter } from './components/styled'
import useDossier from './useDossier'
import { getSortedEntity, sortEntities } from './utils'

const SortableFolder = SortableElement(Folder)
const SortableListOuter = SortableContainer(ListOuter)

const Folders = () => {
  const navHistory = useHistory()
  const { folders: _folders, createLocalFolder } = useDossier()
  const match = useRouteMatch<{
    0: string
    folderId?: string
    isEditing?: string
  }>('*/folders/:folderId?/:isEditing(edit)?')
  const [upsertFolder] = useUpsertDossierFolderMutation()

  const folders = useMemo(
    () => [...(_folders || [])].sort(sortEntities),
    [_folders],
  )

  const { folderId, isEditing } = {
    ...match?.params,
  }

  const sortFolders: SortEndHandler = useCallback(
    ({ oldIndex, newIndex }) => {
      const sorted = getSortedEntity(folders, oldIndex, newIndex)
      if (sorted) {
        upsertFolder({
          variables: {
            input: {
              folderId: sorted.entity.id,
              orderIndex: sorted.orderIndex,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            upsertDossierFolder: {
              __typename: 'UpsertDossierFolderOutput',
              folder: {
                ...sorted.entity,
                orderIndex: sorted.orderIndex,
              },
            },
          },
        })
      }
    },
    [folders, upsertFolder],
  )

  const createFolder = useCallback(() => {
    const folder = createLocalFolder()
    navHistory.push(`${match?.params[0] || ''}/folders/${folder.id}/edit`)
  }, [createLocalFolder, match, navHistory])

  return (
    <SortableListOuter onSortEnd={sortFolders} useDragHandle lockAxis={'y'}>
      <PageHeader
        actions={
          <Button
            iconBefore={<AddIcon label={''} />}
            appearance={'default'}
            onClick={createFolder}
          />
        }
      >
        {'Tabs'}
      </PageHeader>

      {folders.map((folder, index) => (
        <SortableFolder
          key={folder.id}
          index={index}
          folder={folder}
          isEditing={!!isEditing}
          isSelected={folderId === folder.id}
        />
      ))}
    </SortableListOuter>
  )
}

export default Folders
