import { LoadingButton as _Button } from '@atlaskit/button'
import { DatePicker } from '@atlaskit/datetime-picker'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import SectionMessage from '@atlaskit/section-message'
import { colors } from '@atlaskit/theme'
import { DateTime } from 'luxon'
import React from 'react'
import styled from 'styled-components'

import {
  MoreDropdownMenu,
  DropdownItem,
  DropdownItemGroup,
} from '../../../components/DropdownMenu'
import Modal from '../../../components/Modal'
import { Field, TextField } from '../../../components/form'
import {
  ContractorReportFragment,
  useSendContractorsPaymentsEmailsMutation,
} from '../../../graphql'
import useSwitch from '../../../lib/useSwitch'
import useValues from '../../../lib/useValues'

import exportToCsv, { Format } from './exportToCsv'
import {
  ReportsQuery,
  TIME_ZONE,
  getContractorEmail,
  getTotalAmount,
  getReportDisplayName,
  getReportSection,
  ReportsSection,
} from './utils'

const Outer = styled.div`
  flex: 0 0 auto;
  border-top: solid 2px ${colors.backgroundHover};
  margin: 0;
  padding: 24px 0;
  margin: 0 24px;
  display: flex;
  flex-direction: row-reverse;
`

const WarningBoxOuter = styled.div`
  margin-top: 24px;
`

const Button = styled(_Button)`
  margin-left: 8px;
`

type Props = {
  query: ReportsQuery
  reports: ContractorReportFragment[]
}

const ListActions = ({ query, reports }: Props) => {
  const [isOpen, openModal, closeModal] = useSwitch(false)
  const [{ greeting, creditDate }, { patch }] = useValues<
    Partial<{ greeting: string; creditDate: string }>
  >({})

  const fromDate = DateTime.fromJSDate(query.from)
    .setZone(TIME_ZONE.iana)
    .toISODate()
  const toDate = DateTime.fromJSDate(query.to)
    .setZone(TIME_ZONE.iana)
    .toISODate()

  const [sendEmails, { loading, error }] =
    useSendContractorsPaymentsEmailsMutation({
      variables: {
        input: {
          fromDate,
          toDate,
          creditDate: creditDate || '',
          greeting,
          contractors: reports.map((report) => ({
            givenName: report.user?.profile.givenName,
            displayName: getReportDisplayName(report)!,
            email: getContractorEmail(report)!,
            hours: report.totalHours,
            amount: getTotalAmount(report),
            currency: report.user?.currency!,
          })),
        },
      },
      onCompleted: closeModal,
    })

  return (
    <Outer>
      <Button
        appearance={'default'}
        isDisabled={reports.length === 0}
        onClick={openModal}
        isLoading={isOpen || loading}
      >
        {'Send emails'}
      </Button>

      <MoreDropdownMenu
        trigger={'Export'}
        triggerButtonProps={{
          iconBefore: undefined,
          iconAfter: <ChevronDownIcon label={'chevron-down'} />,
          appearance: 'default',
          spacing: 'default',
        }}
      >
        <DropdownItemGroup title={'CSV'}>
          <DropdownItem
            onClick={() =>
              exportToCsv(
                reports.filter(
                  (report) =>
                    getReportSection(report) !== ReportsSection.VENDORS_USA,
                ),
                query,
                Format.JUSTWORKS,
              )
            }
          >
            {'Justworks'}
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              exportToCsv(
                reports.filter(
                  (report) =>
                    getReportSection(report) === ReportsSection.VENDORS_USA,
                ),
                query,
                Format.VENDORS,
              )
            }
          >
            {'Vendors'}
          </DropdownItem>
          <DropdownItem
            onClick={() => exportToCsv(reports, query, Format.REGULAR)}
          >
            {'All'}
          </DropdownItem>
        </DropdownItemGroup>
      </MoreDropdownMenu>

      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        width={'small'}
        heading={'Contractors payments emails'}
        autoFocus
        actions={[
          {
            text: 'Send',
            onClick: () => sendEmails(),
            isDisabled: !(creditDate && creditDate > toDate),
          },
          { text: 'Cancel', onClick: closeModal },
        ]}
      >
        <Field
          label={'Greeting to contractors'}
          helperMessage={
            'Displayed after "Hello there," in email to contractor'
          }
        >
          <TextField
            placeholder={'ex: Happy Monday!'}
            value={greeting || ''}
            onChangeValue={(greeting) => patch({ greeting })}
          />
        </Field>
        <Field
          isRequired
          label={'Payment Date'}
          helperMessage={'When contractors will receive payment'}
        >
          <DatePicker
            value={creditDate || ''}
            onChange={(creditDate) => patch({ creditDate })}
            isInvalid={!!creditDate && creditDate <= toDate}
          />
        </Field>
        <WarningBoxOuter>
          <SectionMessage appearance={'warning'}>
            <p>
              {`Will send a recap email to billing@withdouble.com and an individual payment confirmation to ${reports.length} contractors.`}
            </p>
          </SectionMessage>
        </WarningBoxOuter>
        {!!error && (
          <WarningBoxOuter>
            <SectionMessage appearance={'error'} title={error.name}>
              <p>{error.message}</p>
            </SectionMessage>
          </WarningBoxOuter>
        )}
      </Modal>
    </Outer>
  )
}

export default ListActions
