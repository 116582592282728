import { useCallback, useEffect } from 'react'

import { useGetMeQuery } from '../graphql'
import { useAuth } from '../lib/auth'

const useIsAllowedOperation = (): ((operationId: string) => boolean) => {
  const { isAuthenticated } = useAuth()
  const { data, refetch } = useGetMeQuery({
    skip: !isAuthenticated,
  })

  useEffect(() => {
    if (isAuthenticated) {
      refetch()
    }
  }, [isAuthenticated, refetch])

  return useCallback(
    (operationId: string): boolean => {
      return (
        isAuthenticated &&
        !!data?.me.allowedOperations.some(({ id }) => {
          if (id === operationId) {
            return true
          } else if (id.startsWith(operationId + '.')) {
            return true
          }
        })
      )
    },
    [data, isAuthenticated],
  )
}

export default useIsAllowedOperation
