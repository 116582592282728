import React, { ComponentProps } from 'react'

import { EmailComposerButton } from '../../../../../components/messages/EmailMessageComposer'
import {
  Matching_ForTransitionFullFragment,
  useSentTransitionMessageMutation,
} from '../../../../../graphql'
import { Bundled } from '../../utils'

type Props = {
  matching: Bundled<Matching_ForTransitionFullFragment>
  messageTemplateIds: string[]
  composerProps: Partial<
    ComponentProps<typeof EmailComposerButton>['composerProps']
  >
  isDisabled?: boolean
}

const PreviewAndSendEmailButton = ({
  matching,
  messageTemplateIds,
  composerProps,
  isDisabled,
}: Props) => {
  const [didSendMessage] = useSentTransitionMessageMutation({})

  const sentMessages = matching.transition!.sentMessages?.filter((sent) =>
    messageTemplateIds.includes(sent.messageTemplate.id),
  )

  return (
    <EmailComposerButton
      composerProps={{
        toUserId: matching.executive?.id,
        executiveId: matching.executive?.id,
        assistantId: matching.transition?.fromAssistant?.id,
        messageTemplateIds,
        onSendComplete: (template) => {
          didSendMessage({
            variables: {
              input: {
                matchingId: matching.id,
                messageTemplateId: template.id,
              },
            },
          })
        },
        ...composerProps,
      }}
      buttonProps={{
        children: 'Preview & send' + (sentMessages?.length ? ' (again)' : ''),
        appearance: sentMessages?.length ? 'default' : 'primary',
        isDisabled,
      }}
      activityProps={{
        queryDateAfter: matching.transition?.requestedOn
          ? new Date(matching.transition?.requestedOn)
          : undefined,
        defaultSentAt: sentMessages?.[0]?.at,
        messageTemplateIds,
        recipientEmails: matching.executive?.profile.email
          ? [matching.executive?.profile.email]
          : undefined,
      }}
    />
  )
}

export default PreviewAndSendEmailButton
