import { colors } from '@atlaskit/theme'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import CollapsibleText from '../CollapsibleText'

export const SectionMessageOuter = styled.div`
  margin-top: 24px;
  color: ${colors.text};

  section h1 {
    margin-top: 0;
  }
`

export const Notepad = styled(CollapsibleText)`
  margin-top: 8px;
`

export const Timestamp = styled.span`
  white-space: nowrap;
  font-size: smaller;
  font-weight: 600;
  color: ${colors.subtleText};
`

export const PackageRow = styled(NavLink)`
  color: ${colors.text};
`
