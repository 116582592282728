import { typography } from '@atlaskit/theme'
import styled from 'styled-components'

export const Outer = styled.div`
  display: flex;
  flex-direction: column;
`
export const DisplayDateOuter = styled.span<{ $missingDate: boolean }>`
  color: ${({ $missingDate }) => ($missingDate ? 'red' : 'inherit')};
`

export const TimeWrapper = styled.time`
  ${typography.h300}
`
