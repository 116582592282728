import Button, { ButtonGroup } from '@atlaskit/button'
import Range from '@atlaskit/range'
import { transparentize } from 'polished'
import React, { ComponentProps, useCallback, useState } from 'react'
import styled from 'styled-components'

import CefrScaleSelect from '../../../components/CefrScaleSelect'
import IndustriesSelect from '../../../components/IndustriesSelect'
import MarkdownEditor from '../../../components/MarkdownEditor'
import PersonalityTraitsSelect from '../../../components/PersonalityTraitsSelect'
import Section from '../../../components/Section'
import { LoadingSpinner } from '../../../components/Spinner'
import TimeZoneSelect from '../../../components/TimeZoneSelect'
import { ToggleStateless as Toggle } from '../../../components/Toggle'
import ToolsSelect from '../../../components/ToolsSelect'
import { Field, FieldsRow as Row, TextField } from '../../../components/form'
import AssistantOnboardingAvailabilityButton from '../../../components/onboarding-availability/AssistantOnboardingAvailabilityButton'
import { useSkillset } from '../../../components/skillset/utils'
import {
  FullUserFragment as FullUser,
  UpdateUserInput,
  useUpdateUserMutation,
  namedOperations,
} from '../../../graphql'
import useValues from '../../../lib/useValues'
import { areEquivalentObjects, deepCleanTypename } from '../../../lib/utils'

const Outer = styled.div`
  padding: 8px 0;
  flex: 1;
  margin-bottom: 24px;
`

export const DiscRow = styled.div`
  display: flex;

  & > div {
    flex: 1 1;

    & + div {
      margin-left: 4px;
    }
  }
`

const Buttons = styled.div`
  padding: 16px 4px;
  position: sticky;
  bottom: 0;
  background-color: ${transparentize(0.2, 'white')};
`

const Skillset = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Skill = styled.div`
  flex: 0 0 50%;
  &:nth-child(2n) {
    padding-left: 8px;
  }
  &:nth-child(2n + 1) {
    padding-right: 8px;
  }
`

type UpdatableData = Pick<
  UpdateUserInput,
  | 'industries'
  | 'interestedInIndustries'
  | 'experienceInTools'
  | 'internalDisc'
  | 'externalDisc'
  | 'skillsetRating'
  | 'isOpenToMatch'
  | 'isOpenToSupport'
  | 'targetTotalWeeklyHours'
  | 'targetAdditionalWeeklyHours'
  | 'personalityTraits'
  | 'workTimeZones'
  | 'matchingNotepad'
  | 'englishLevel'
>

interface Props extends ComponentProps<'div'> {
  user: FullUser
}

const Matching = ({ user: originalUser }: Props) => {
  const [rev, setRev] = useState(0)
  const { skillset } = useSkillset()
  const [values, { set, setters, reset }] = useValues<Partial<UpdatableData>>(
    {},
    [
      'industries',
      'interestedInIndustries',
      'experienceInTools',
      'personalityTraits',
      'isOpenToMatch',
      'isOpenToSupport',
      'targetTotalWeeklyHours',
      'targetAdditionalWeeklyHours',
      'workTimeZones',
      'matchingNotepad',
      'englishLevel',
    ],
  )
  const [updateUser, { loading }] = useUpdateUserMutation({
    refetchQueries: [namedOperations.Query.GetUserFieldsOptions],
  })

  const user = {
    ...originalUser,
    ...values,
  }

  const onChangeInternalDisc = useCallback(
    (patch) => {
      set((values) => ({
        ...values,
        internalDisc: {
          ...user.internalDisc,
          ...patch,
        },
      }))
    },
    [set, user.internalDisc],
  )

  const onChangeExternalDisc = useCallback(
    (patch) => {
      set((values) => ({
        ...values,
        externalDisc: {
          ...user.externalDisc,
          ...patch,
        },
      }))
    },
    [set, user.externalDisc],
  )

  const onChangeSkillsetRating = useCallback(
    (patch) => {
      set((values) => ({
        ...values,
        skillsetRating: {
          ...user.skillsetRating,
          ...patch,
        },
      }))
    },
    [set, user.skillsetRating],
  )

  const save = useCallback(async () => {
    await updateUser({
      variables: {
        userId: user.id,
        input: deepCleanTypename(values),
      },
      onCompleted: () => setRev((r) => r + 1),
    })
    reset()
  }, [updateUser, user.id, values, reset, setRev])

  const cancel = useCallback(() => {
    reset()
    setRev((r) => r + 1)
  }, [reset, setRev])

  return (
    <Outer>
      <Row>
        <Field label={'Is Open to Match?'}>
          <Toggle
            size={'large'}
            isChecked={!!user.isOpenToMatch}
            onChange={() => setters.isOpenToMatch?.(!user.isOpenToMatch)}
          />
        </Field>

        <Field label={'Is Open to Support?'}>
          <Toggle
            size={'large'}
            isChecked={!!user.isOpenToSupport}
            onChange={() => setters.isOpenToSupport?.(!user.isOpenToSupport)}
          />
        </Field>

        <Field label={'Onboarding Availability'}>
          <AssistantOnboardingAvailabilityButton
            assistantId={user.id}
            children={'See slots'}
          />
        </Field>
      </Row>

      <Row>
        <Field label={'Total weekly hours desired'}>
          <TextField
            value={user.targetTotalWeeklyHours ?? ''}
            onChangeValue={setters.targetTotalWeeklyHours}
          />
        </Field>

        <Field label={'Additional weekly hours desired'}>
          <TextField
            value={user.targetAdditionalWeeklyHours ?? ''}
            onChangeValue={setters.targetAdditionalWeeklyHours}
          />
        </Field>
      </Row>

      <Field label={'Personality'}>
        <PersonalityTraitsSelect
          isCreatable
          values={user.personalityTraits || []}
          onChangeValues={setters.personalityTraits!}
        />
      </Field>

      <Field label={'Industries experienced with'}>
        <IndustriesSelect
          isCreatable
          values={user.industries || []}
          onChangeValues={setters.industries!}
        />
      </Field>

      <Field label={'Industries of interest'}>
        <IndustriesSelect
          isCreatable
          values={user.interestedInIndustries || []}
          onChangeValues={setters.interestedInIndustries!}
        />
      </Field>

      <Field label={'Tools experienced with'}>
        <ToolsSelect
          isCreatable
          values={user.experienceInTools || []}
          onChangeValues={setters.experienceInTools!}
        />
      </Field>

      <Field label={'Work time zones'}>
        <TimeZoneSelect
          isMulti
          value={user.workTimeZones || []}
          // @ts-ignore
          onChangeValue={setters.workTimeZones}
        />
      </Field>

      <Field label={'English level'}>
        <CefrScaleSelect
          value={user.englishLevel}
          onChangeValue={setters.englishLevel}
          isClearable
        />
      </Field>

      <Row>
        <Field label={'Internal DiSC'}>
          <DiscRow>
            {['D', 'i', 'S', 'C'].map((letter) => (
              <TextField
                key={letter}
                placeholder={letter}
                step={1}
                min={0}
                max={20}
                inputMode={'numeric'}
                type={'number'}
                // @ts-ignore
                value={user.internalDisc?.[letter.toLowerCase()] ?? ''}
                onChangeValue={(value) =>
                  onChangeInternalDisc({
                    [letter.toLowerCase()]: value ? parseInt(value) : null,
                  })
                }
              />
            ))}
          </DiscRow>
        </Field>

        <Field label={'External DiSC'}>
          <DiscRow>
            {['D', 'i', 'S', 'C'].map((letter) => (
              <TextField
                key={letter}
                placeholder={letter}
                step={1}
                min={0}
                max={20}
                inputMode={'numeric'}
                type={'number'}
                // @ts-ignore
                value={user.externalDisc?.[letter.toLowerCase()] ?? ''}
                onChangeValue={(value) =>
                  onChangeExternalDisc({
                    [letter.toLowerCase()]: value ? parseInt(value) : null,
                  })
                }
              />
            ))}
          </DiscRow>
        </Field>
      </Row>

      <Field
        label={'Internal Matching Notes'}
        helperMessage={'There are not visible to Double or executives'}
      >
        <MarkdownEditor
          key={rev}
          value={user?.matchingNotepad || null}
          onChange={setters.matchingNotepad!}
        />
      </Field>

      <Section title={'Skillset'}>
        <Skillset>
          {skillset?.skills.map(({ id, label }) => (
            <Skill key={id}>
              <Field label={`${label}: ${user.skillsetRating?.[id] || '–'}`}>
                <Range
                  step={1}
                  min={0}
                  max={10}
                  value={user.skillsetRating?.[id] || 0}
                  onChange={(value) => onChangeSkillsetRating({ [id]: value })}
                />
              </Field>
            </Skill>
          ))}
        </Skillset>
      </Section>

      {!areEquivalentObjects(originalUser, user) && (
        <Buttons>
          <ButtonGroup>
            <Button appearance={'primary'} onClick={save}>
              {'Save'}
            </Button>
            <Button onClick={cancel}>{'Cancel'}</Button>
          </ButtonGroup>
        </Buttons>
      )}

      <LoadingSpinner show={loading} />
    </Outer>
  )
}

export default Matching
