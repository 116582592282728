import { typography } from '@atlaskit/theme'
import { DateTime } from 'luxon'
import React from 'react'
import styled from 'styled-components'

import { Matching_ForTransitionListItemFragment } from '../../../../../../graphql'

const Outer = styled.time`
  ${typography.h300}
`

type Props = {
  matching: Matching_ForTransitionListItemFragment
}

const RequestDate = ({ matching }: Props) => {
  const requestedOn = matching?.transition?.requestedOn
  if (!requestedOn) {
    return null
  }

  const formatedDate = DateTime.fromISO(requestedOn).toLocaleString({
    day: 'numeric',
    month: 'short',
  })

  return (
    <Outer dateTime={requestedOn} title={requestedOn}>
      {formatedDate}
    </Outer>
  )
}

export default RequestDate
