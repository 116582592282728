import Button, { ButtonGroup } from '@atlaskit/button'
import { colors, borderRadius } from '@atlaskit/theme'
import React, { useState, useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { FeatureTarget, FeatureTargetPredicate } from '../../../../graphql'
import { areEquivalentObjects } from '../../../../lib/utils'
import { findPredicateOfType } from '../utils'

import {
  AssistantPredicate,
  FeaturePredicate,
  ExecutivePredicate,
  ConstantPredicate,
  CreatedAfterPredicate,
} from './Predicate'

const Outer = styled.div`
  border-radius: ${borderRadius}px;
  border: 2px solid ${colors.backgroundHover};
  padding: 8px;

  & + & {
    margin-top: 32px;

    &:before {
      content: 'or';
      font-weight: 600;
      display: block;
      color: ${colors.subtleText};
      padding-left: 4px;
      position: absolute;
      top: -29px;
    }
  }
`

const Buttons = styled.div`
  padding: 4px;
`

const NEW_TARGET: FeatureTarget = Object.freeze({
  predicates: [
    {
      constant: false,
    },
  ],
})

type Props = {
  target: FeatureTarget | null
  onChange: (newTarget: FeatureTarget | null) => void
  onDelete?: undefined | null | (() => void)
}

const Target = ({ target: defaultTarget, onChange, onDelete }: Props) => {
  const [target, setTarget] = useState(defaultTarget)

  const predicates = useMemo(
    () => ({
      constant: findPredicateOfType(
        target?.predicates || [],
        'FeatureTargetConstantPredicate',
      ),
      executive: findPredicateOfType(
        target?.predicates || [],
        'FeatureTargetExecutivePredicate',
      ),
      assistant: findPredicateOfType(
        target?.predicates || [],
        'FeatureTargetAssistantPredicate',
      ),
      feature: findPredicateOfType(
        target?.predicates || [],
        'FeatureTargetFeaturePredicate',
      ),
      createdAfter: findPredicateOfType(
        target?.predicates || [],
        'FeatureTargetCreatedAtPredicate',
      ),
    }),
    [target],
  )

  const hasOtherPredicates = Boolean(
    !predicates.constant && target && target.predicates.length > 0,
  )

  const setPredicate = useCallback(
    (update: Partial<typeof predicates>) => {
      setTarget({
        ...target,
        predicates: Object.values({
          ...predicates,
          ...update,
        }).filter((p): p is FeatureTargetPredicate => !!p),
      })
    },
    [predicates, target],
  )

  const saveTarget = useCallback(() => {
    onChange(target)
  }, [target, onChange])

  const resetTarget = useCallback(() => {
    setTarget(defaultTarget)
  }, [setTarget, defaultTarget])

  if (!target) {
    return (
      <Outer>
        <Buttons>
          <Button onClick={() => setTarget(NEW_TARGET)}>
            {'Add conditions'}
          </Button>
        </Buttons>
      </Outer>
    )
  }

  const hasBeenUpdated = !areEquivalentObjects(target, defaultTarget)

  return (
    <Outer>
      <ConstantPredicate
        isDisabled={hasOtherPredicates}
        predicate={predicates.constant}
        onChange={(constant) => setPredicate({ constant })}
      />
      {!predicates.constant && (
        <>
          <ExecutivePredicate
            isFirst
            predicate={predicates.executive}
            onChange={(executive) => setPredicate({ executive })}
          />
          <AssistantPredicate
            isFirst={!predicates.executive}
            predicate={predicates.assistant}
            onChange={(assistant) => setPredicate({ assistant })}
          />
          <FeaturePredicate
            isFirst={!predicates.executive && !predicates.assistant}
            predicate={predicates.feature}
            onChange={(feature) => setPredicate({ feature })}
          />
          <CreatedAfterPredicate
            isFirst={
              !predicates.executive &&
              !predicates.assistant &&
              !predicates.feature
            }
            predicate={predicates.createdAfter}
            onChange={(createdAfter) => setPredicate({ createdAfter })}
          />
        </>
      )}

      <Buttons>
        <ButtonGroup>
          <Button isDisabled={!hasBeenUpdated} onClick={resetTarget}>
            {'Cancel'}
          </Button>
          <Button
            isDisabled={!hasBeenUpdated}
            appearance={'primary'}
            onClick={saveTarget}
          >
            {'Save'}
          </Button>
          {!!onDelete && (
            <Button
              isDisabled={hasBeenUpdated}
              appearance={'warning'}
              onClick={onDelete}
            >
              {'Delete'}
            </Button>
          )}
        </ButtonGroup>
      </Buttons>
    </Outer>
  )
}

export default Target
