import Button from '@atlaskit/button'
import VisitIcon from '@atlaskit/icon/glyph/shortcut'
import Tooltip from '@atlaskit/tooltip'
import React, { ComponentProps, useCallback, useMemo } from 'react'
import styled from 'styled-components'

import {
  useListLeverOpportunitiesQuery,
  LeverOpportunityFragment,
} from '../graphql'

import { Select } from './form'

const TooltipContent = styled.span`
  white-space: pre;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  & > *:first-child {
    flex: 1;

    & + * {
      margin-left: 4px;
    }
  }
`

const getOpportunityOption = (
  opp: LeverOpportunityFragment | undefined | null,
): undefined | null | { label: string; value: string } =>
  opp && opp.id && opp.displayName
    ? {
        value: opp.id,
        label: opp.displayName,
      }
    : undefined

function notFalsy<T>(v: T | undefined | null | false | '' | 0): v is T {
  return !!v
}

type Props = Omit<ComponentProps<typeof Select>, 'onChangeValue'> & {
  includePostingTitle?: boolean
  hiredAfter?: Date
  offeredAfter?: Date
  onboardingAfter?: Date
  isUser?: boolean
  valueId?: LeverOpportunityFragment['id'] | null
  value?: LeverOpportunityFragment | null
  onChangeValue?: (newValue: LeverOpportunityFragment | null) => void
  onChangeValueId?: (newValue: LeverOpportunityFragment['id'] | null) => void
}

const LeverOpportunitySelect = ({
  valueId,
  value,
  onChangeValue,
  onChangeValueId,
  hiredAfter,
  offeredAfter,
  onboardingAfter,
  isUser,
  includePostingTitle,
  ...props
}: Props) => {
  const { data } = useListLeverOpportunitiesQuery({
    variables: {
      hiredAfter,
      offeredAfter,
      onboardingAfter,
      isUser,
      includePostingTitle: !!includePostingTitle,
    },
  })
  const opportunities = useMemo(() => data?.list.items || [], [data])

  valueId = valueId || value?.id
  value = opportunities?.find((o) => o?.id === valueId)

  const onSelect = useCallback(
    (newValueId: LeverOpportunityFragment['id']) => {
      onChangeValueId?.(newValueId)
      onChangeValue?.(opportunities?.find((o) => o?.id === newValueId) || null)
    },
    [onChangeValue, onChangeValueId, opportunities],
  )

  return (
    <Tooltip
      content={
        !!value && (
          <TooltipContent>
            <strong>{'Emails'}</strong>
            <br />
            {value.emails.join('\n') || 'N/A'}
            <br />
            <br />
            <strong>{'Phones'}</strong>
            <br />
            {value.phoneNumbers.join('\n') || 'N/A'}
            <br />
            <br />
            <strong>{'Location'}</strong>
            <br />
            {value.location || 'N/A'}
            <br />
            <br />
            <strong>{'Posting'}</strong>
            <br />
            {value.postingTitle || 'N/A'}
          </TooltipContent>
        )
      }
      position={'left'}
    >
      <Row>
        <Select
          value={value ? getOpportunityOption(value) : null}
          options={opportunities.map(getOpportunityOption).filter(notFalsy)}
          placeholder={'Select Lever candidate'}
          onChangeValue={onSelect}
          {...props}
        />
        {!!value && (
          <Button
            target={'_blank'}
            href={`https://hire.lever.co/candidates/${value.id}`}
            appearance={'subtle'}
            iconBefore={<VisitIcon label={''} />}
          />
        )}
      </Row>
    </Tooltip>
  )
}

export default LeverOpportunitySelect
