import { DateTime } from 'luxon'

import { StripeInvoiceStatus } from '../../../../graphql'

const getStripeCustomerInvoicesVariables = (invoicesCreatedAfter: Date) => ({
  invoiceStatus: [
    StripeInvoiceStatus.DRAFT,
    StripeInvoiceStatus.OPEN,
    StripeInvoiceStatus.PAID,
    StripeInvoiceStatus.UNCOLLECTIBLE,
  ],
  invoicesFirst: 4,
  invoicesCreatedAfter,
  invoicesCreatedBefore: DateTime.fromJSDate(invoicesCreatedAfter)
    .setZone('America/New_York')
    .plus({ month: 1 })
    .endOf('month')
    .toJSDate(),
})

export default getStripeCustomerInvoicesVariables
