import { useApolloClient } from '@apollo/client'
import React, { useState, useCallback, SyntheticEvent } from 'react'
import ReactS3Uploader, { S3Response } from 'react-s3-uploader'
import S3Uploader from 'react-s3-uploader/s3upload'
import styled, { css } from 'styled-components'

import {
  UploadPublicFileDocument,
  UploadPublicFileMutation,
  UploadPublicFileMutationVariables,
} from '../../graphql'
import { LoadingSpinner } from '../Spinner'

import TextField from './TextField'

export { S3Uploader }

export const useGetSignedUrl = (setLoading?: (isLoading: boolean) => void) => {
  const client = useApolloClient()
  return useCallback(
    async (file: File, upload: (res: S3Response) => void) => {
      setLoading?.(true)

      try {
        const { data } = await client.mutate<
          UploadPublicFileMutation,
          UploadPublicFileMutationVariables
        >({
          mutation: UploadPublicFileDocument,
          variables: {
            input: {
              name: file.name,
              mime: file.type,
            },
          },
        })

        data?.upload && upload({ fileKey: '', ...data.upload })
      } catch (error) {
        console.error(error)
        setLoading?.(false)
      }
    },
    [client, setLoading],
  )
}

const Row = styled.div`
  display: flex;

  & > * {
    flex: 1 1;

    & + * {
      margin-left: 16px;
    }
  }
`

const UploaderWrapper = styled.div`
  position: relative;

  input[type='file'] {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
  }
`

const Img = styled.img<{ hide?: boolean; isRound?: boolean }>`
  flex: 0 0 auto;
  max-width: 80px;
  height: 40px;
  min-width: 40px;
  border: none;
  border-radius: 3px;

  ${({ hide }) =>
    !!hide &&
    css`
      display: none;
    `}

  ${({ isRound }) =>
    !!isRound &&
    css`
      width: 40px;
      border-radius: 40px;
    `}
`

type Props = {
  value?: string | null
  onChangeValue: (newValue: null | string) => void
  isInvalid?: boolean
  isRound?: boolean
}

const ImageUrlField = ({ value, onChangeValue, isInvalid, isRound }: Props) => {
  const [url, setUrl] = useState<string>(value || '')
  const [isValid, setIsValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const getSignedUrl = useGetSignedUrl(setLoading)

  const onLoadOrError = useCallback(
    ({ type, target }: SyntheticEvent<HTMLImageElement>) => {
      // @ts-ignore
      if (target.currentSrc === url) {
        if (type === 'load') {
          setIsValid(true)
          setLoading(false)
          onChangeValue(url)
        } else {
          setIsValid(false)
          setLoading(false)
          onChangeValue(null)
        }
      }
    },
    [onChangeValue, url],
  )

  const onUploadError = useCallback(() => {
    setUrl('')
    setLoading(false)
  }, [setUrl, setLoading])

  const onUploadSuccess = useCallback(
    ({ publicUrl }: S3Response) => {
      setUrl(publicUrl)
      setLoading(false)
    },
    [setUrl, setLoading],
  )

  return (
    <Row>
      <UploaderWrapper>
        <TextField
          value={url}
          onChangeValue={setUrl}
          placeholder={'Drop or click to select file'}
          isInvalid={isInvalid || (!!url && !isValid)}
        />
        {!url && (
          <ReactS3Uploader
            getSignedUrl={getSignedUrl}
            // 💩 Passing an empty object to remove default x-amz-acl header,
            // ACL is already set on the signed url
            // See directory.api src/admin/modules/uploadPublicFile.ts:59
            uploadRequestHeaders={{}}
            accept={'image/*'}
            // onProgress={(args) => console.log('===== on progress', args)}
            onError={onUploadError}
            onFinish={onUploadSuccess}
            contentDisposition={'auto'}
          />
        )}
        <LoadingSpinner size={'medium'} show={loading} />
      </UploaderWrapper>
      <Img
        src={url}
        onError={onLoadOrError}
        onLoad={onLoadOrError}
        hide={!isValid}
        isRound={isRound}
      />
    </Row>
  )
}

export default ImageUrlField
