// import React from 'react'
import { borderRadius, colors } from '@atlaskit/theme'
import styled from 'styled-components'

export const ITEM_LINK_ACTIVE_CLASSNAME = 'active'

interface ItemProps {
  inverted?: boolean
}

const Item = styled.div<ItemProps>`
  align-items: center;
  border-radius: ${borderRadius}px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  padding: 0.6em 1em;

  background: ${(props) => (props.inverted ? colors.backgroundActive : 'none')};
  color: ${(props) => (props.inverted ? colors.textActive : colors.text)};

  &:hover {
    background-color: ${(props) =>
      props.inverted ? colors.backgroundActive : colors.backgroundHover};
    color: ${(props) =>
      props.inverted ? colors.textActive : colors.textHover};
  }

  a.${ITEM_LINK_ACTIVE_CLASSNAME} & {
    &,
    &:hover {
      background: ${colors.backgroundActive};
      color: ${colors.textActive};
    }
  }
`

export default Item
