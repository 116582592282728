import type {
  ListAutomationJobsQueryVariables,
  AutomationJob_ItemFragment,
} from '../../../graphql'

const MINUTE_IN_MS = 60_000
const HOUR_IN_MS = 60 * MINUTE_IN_MS

type ListQueryVariables = Omit<
  ListAutomationJobsQueryVariables,
  'before' | 'first'
>

export type ListFilter = ListQueryVariables & {
  fixed?: ListQueryVariables
}

export const reduceFilter = (_filter: ListFilter): ListQueryVariables => {
  const { fixed: fixedFilter, ...filter } = _filter
  return {
    ...filter,
    ...fixedFilter,
    hasParams: {
      ...filter.hasParams,
      ...fixedFilter?.hasParams,
    },
  }
}

export const hasCompleted = (job: AutomationJob_ItemFragment): boolean =>
  Boolean(job.endedAt)

export const hasFailed = (job: AutomationJob_ItemFragment): boolean =>
  Boolean(
    !job.endedAt &&
      !job.cancelledAt &&
      job.startedAt &&
      new Date(job.startedAt) < new Date(Date.now() - 5 * MINUTE_IN_MS),
  )

export const isPending = (job: AutomationJob_ItemFragment): boolean =>
  Boolean(
    !job.endedAt &&
      !job.cancelledAt &&
      new Date(job.delayUntil) >= new Date(Date.now() - 4 * HOUR_IN_MS),
  )

export const hasError = (job: AutomationJob_ItemFragment): boolean =>
  Boolean(job.hasError)

export const isReviewed = (job: AutomationJob_ItemFragment): boolean =>
  Boolean(job.reviewedAt && job.reviewedAt >= job.updatedAt)

export const isCancelled = (job: AutomationJob_ItemFragment): boolean =>
  Boolean(job.cancelledAt)
