import React, { useMemo } from 'react'
import { RouteChildrenProps } from 'react-router-dom'

import Spinner from '../../../../../components/Spinner'
import { TimeTrackingCategoryId } from '../../../../../graphql'
import PromptEditor from '../../PromptEditor'
// TODO Move
import useSubprompt from '../../PromptEditor/useSubprompt'
import useSystemFunctions from '../useSystemFunctions'
import { CopilotPromptSettingsOverrideType } from '../useUpdateAssistantCopilot'

const CategoryPromptEditor = ({
  match,
}: RouteChildrenProps<{ categoryId?: string }>) => {
  const categoryId = match?.params.categoryId as
    | TimeTrackingCategoryId
    | undefined

  const { systemFunctions, loading: systemFunctionsLoading } =
    useSystemFunctions()
  const { subprompt, loading: promptLoading } = useSubprompt(categoryId)

  // TODO: Get emoji from the backend
  const title = useMemo(() => `🗂️ ${subprompt?.label}`, [subprompt?.label])

  if (!categoryId) return null

  if (systemFunctionsLoading || promptLoading || !subprompt) return <Spinner />

  return (
    <PromptEditor
      promptId={categoryId}
      subpromptId={categoryId}
      prompt={subprompt}
      title={title}
      upperLevelFunctions={systemFunctions}
      overridenPromptType={CopilotPromptSettingsOverrideType.CATEGORY}
      helperMessage={
        'If set, will be used in place of the Everyday Tasks prompt, unless a prompt is set for the task.'
      }
    />
  )
}

export default CategoryPromptEditor
