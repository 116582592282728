import Button, { ButtonProps } from '@atlaskit/button'
import React from 'react'
import styled from 'styled-components'

import { ReactComponent as _Icon } from './LinkedIn.svg'

const Icon = styled(_Icon)`
  width: 20px;
  height: 20px;
`

const LinkedInButton = (props: ButtonProps) => (
  <Button appearance={'subtle'} iconBefore={<Icon />} {...props} />
)

export default LinkedInButton
