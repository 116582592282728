import React, { useEffect } from 'react'
import styled from 'styled-components'

import EmojiPicker from '../../../../../components/EmojiPicker'
import MarkdownEditor from '../../../../../components/MarkdownEditor'
import {
  Field,
  FieldsRow,
  ImageUrlField,
  TextField,
} from '../../../../../components/form'
import type { SlackFieldsValues } from '../../../../../components/messages/types'
import { Outer } from '../../components/styled'

const AvatarRow = styled(FieldsRow)`
  & > div {
    flex: 0 0 auto;
  }

  & > div:last-child {
    flex: 1;
  }
`

const Or = styled.div`
  align-self: end;
  padding-bottom: 11px;
`

const isValidIconEmoji = (emoji: string | null | undefined): boolean =>
  !emoji || /^:[-a-z0-9_]+:$/.test(emoji)

type SlackFieldsProps = {
  values: SlackFieldsValues
  onPatch: (newValues: Partial<SlackFieldsValues>) => void | Promise<void>
  onValidity: (isValid: boolean) => void | Promise<void>
}

const SlackFields = ({ values, onPatch, onValidity }: SlackFieldsProps) => {
  useEffect(() => {
    onValidity(
      !(
        !values?.text?.trim() ||
        (values.botName && !values.botName.trim()) ||
        !isValidIconEmoji(values.iconEmoji)
      ),
    )
  }, [values, onValidity])

  return (
    <Outer>
      <Field
        label={'Channel or user ID'}
        helperMessage={'Do not include prefix "#" or "@"'}
      >
        <TextField
          placeholder={''}
          value={values.channelId}
          onChangeValue={(channelId) => onPatch({ channelId })}
        />
      </Field>
      <Field label={'Bot name'}>
        <TextField
          placeholder={''}
          value={values.botName}
          onChangeValue={(botName) => onPatch({ botName })}
        />
      </Field>
      <AvatarRow>
        {!!(values.iconEmoji || !values.iconUrl) && (
          <Field label={'Bot avatar emoji'}>
            <div>
              <EmojiPicker
                useColons
                value={values.iconEmoji}
                onChangeValue={(iconEmoji) => onPatch({ iconEmoji })}
              />
            </div>
          </Field>
        )}
        {!values.iconEmoji === !values.iconUrl && <Or>{'or'}</Or>}
        {!!(values.iconUrl || !values.iconEmoji) && (
          <Field label={'Bot avatar image'}>
            <ImageUrlField
              value={values.iconUrl}
              onChangeValue={(iconUrl) =>
                onPatch({ iconUrl: iconUrl || undefined })
              }
            />
          </Field>
        )}
      </AvatarRow>
      <Field label={'Message'} isRequired>
        <MarkdownEditor
          isInvalid={!values.text?.trim()}
          value={values.text}
          onChange={(text) => onPatch({ text })}
          minHeight={90}
        />
      </Field>
    </Outer>
  )
}

export default SlackFields
