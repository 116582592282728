import Button from '@atlaskit/button'
import { DateTimePicker } from '@atlaskit/datetime-picker'
import CopyIcon from '@atlaskit/icon/glyph/copy'
import OpenIcon from '@atlaskit/icon/glyph/open'
import Lozenge, { ThemeAppearance } from '@atlaskit/lozenge'
import Tooltip from '@atlaskit/tooltip'
import React from 'react'

import { Dd, Dl, Dt } from '../../../../../components/DlDtDd'
import { ErrorEmptyState } from '../../../../../components/EmptyState'
import { LoadingSpinner } from '../../../../../components/Spinner'
import TzInfoNotice from '../../../../../components/TzInfoNotice'
import VerticalTabs from '../../../../../components/VerticalTabs'
import { Field, FieldsRow, TextField } from '../../../../../components/form'
import UserCell from '../../../../../components/users/Cell'
import {
  Matching_ForTransitionFullFragment,
  useUpdateTransitionChangeoverMutation,
} from '../../../../../graphql'
import { Bundled } from '../../utils'
import {
  MEET_YOUR_DOUBLE_URL,
  SCHEDULING_HTT_TRANSITION_TABS,
  SCHEDULING_REGULAR_TRANSITION_TABS,
  STATUS_APPEARANCE_MAP,
} from '../CONSTANTS'
import PreviewAndSendEmailButton from '../components/PreviewAndSendEmailButton'
import { Actions, InfoNotice, InfoNoticeText } from '../styled'

import CallAtValue from './CallAtValue'
import useGetIntroCallStatus from './hooks/useGetIntroCallStatus'
import { MatchingForTransitionStatus } from './types'

type Props = {
  matching: Bundled<Matching_ForTransitionFullFragment>
}

function getMeetYourDoubleUrl(matchingId: string) {
  return `${MEET_YOUR_DOUBLE_URL}${matchingId}`
}

function getLozengeApperance(introCallStatus: MatchingForTransitionStatus) {
  return (STATUS_APPEARANCE_MAP[introCallStatus] ||
    'default') as ThemeAppearance
}

const Scheduling = ({ matching }: Props) => {
  const changeoverAt = matching.onboarding.callStartAt
    ? new Date(matching.onboarding.callStartAt)
    : undefined

  const [update, updateMutation] = useUpdateTransitionChangeoverMutation()

  const scheduleCallToken = matching.transition!.scheduleCallToken

  const meetYourDoublePageUrl = getMeetYourDoubleUrl(matching.id)

  const tabs = matching.isHighTouch
    ? SCHEDULING_HTT_TRANSITION_TABS
    : SCHEDULING_REGULAR_TRANSITION_TABS

  const meetYourDoublePageReady =
    matching.isHighTouch && matching?.candidates?.length === 2

  const introCallStatus = useGetIntroCallStatus({ matching })

  return (
    <>
      {matching.isHighTouch && (
        <>
          <Field label={'👑 Meet Your Double Page URL'}>
            <FieldsRow>
              <TextField
                defaultValue={[meetYourDoublePageUrl]}
                isReadOnly={true}
                isDisabled={!meetYourDoublePageReady}
              />
              {meetYourDoublePageReady ? (
                <Actions>
                  <Tooltip content={'Copy to Clipboard'} position={'top'}>
                    <Button
                      appearance={'link'}
                      onClick={() =>
                        navigator.clipboard.writeText(meetYourDoublePageUrl)
                      }
                    >
                      <CopyIcon label={'Copy to Clipboard'} />
                    </Button>
                  </Tooltip>
                  <Tooltip content={'Go To Dashboard'} position={'top'}>
                    <Button
                      appearance={'link'}
                      href={meetYourDoublePageUrl}
                      target={'_blank'}
                    >
                      <OpenIcon label={'Open Dashboard'} />
                    </Button>
                  </Tooltip>
                </Actions>
              ) : (
                <InfoNotice
                  type={'info'}
                  title={'Why is the HTT content disabled?'}
                  noTopUnset
                  placement={'top'}
                >
                  <InfoNoticeText>
                    {`The page and email template is only accessible once the matching has exactly 2 candidates. This executive currently has ${matching?.candidates?.length} proposal(s).`}
                  </InfoNoticeText>
                </InfoNotice>
              )}
            </FieldsRow>
          </Field>
          <Dl dtWidth={'180px'}>
            <Dt>{'👑 Meet Your Double Status'}</Dt>
            <Dd>
              <Lozenge
                maxWidth={'300px'}
                appearance={getLozengeApperance(introCallStatus)}
              >
                {introCallStatus}
              </Lozenge>
            </Dd>
            {matching.candidates?.map((candidate) => (
              <React.Fragment key={candidate.id}>
                <Dt>
                  <UserCell user={candidate.assistant} />
                </Dt>
                <Dd>
                  <Dl dtWidth={'100px'}>
                    <Dt>{'Intro Call'}</Dt>
                    <Dd>
                      {/* 💩 Call duration should be served from the backend */}
                      <CallAtValue
                        date={candidate.introCallAt}
                        callDuration={20}
                      />
                    </Dd>
                    {candidate.assistant.id ===
                      matching.confirmedAssistant?.id && (
                      <>
                        <Dt>{'Transition Call'}</Dt>
                        <Dd>
                          {/* 💩 Call duration should be served from the backend */}
                          <CallAtValue
                            date={matching.onboarding.callStartAt}
                            callDuration={40}
                          />
                        </Dd>
                      </>
                    )}
                  </Dl>
                </Dd>
              </React.Fragment>
            ))}
          </Dl>
        </>
      )}
      <Field label={'Email the executive'}>
        <VerticalTabs
          tabs={tabs.map(({ label, messageTemplateIds }) => ({
            label,
            isChecked: matching.transition!.sentMessages?.some((sent) =>
              messageTemplateIds.includes(sent.messageTemplate.id),
            ),
            content: !!scheduleCallToken && (
              <PreviewAndSendEmailButton
                isDisabled={
                  label.includes('HTT') &&
                  matching.isHighTouch &&
                  !meetYourDoublePageReady
                }
                matching={matching}
                messageTemplateIds={messageTemplateIds}
                composerProps={{
                  assistantId: matching.confirmedAssistant?.id,
                  email: {
                    bcc: [
                      ...new Set([
                        matching.transition?.fromAssistant?.dsm?.profile
                          .email || undefined,
                        matching.confirmedAssistant?.dsm?.profile.email ||
                          undefined,
                        matching.transition!.csm?.profile.email || undefined,
                      ]),
                    ]
                      .filter(Boolean)
                      .map((email) => ({ email })),
                    vars: {
                      SENDER_GIVEN_NAME:
                        matching.transition!.csm?.profile.givenName,
                      SCHEDULING_URL: `https://app.withdouble.com/schedule-transition?token=${encodeURIComponent(
                        scheduleCallToken,
                      )}`,
                      MEET_YOUR_DOUBLE_URL: meetYourDoublePageUrl,
                      REASON_FOR_MATCH: (() => {
                        const candidate = matching.candidates?.find(
                          (c) =>
                            c.assistant?.id === matching.confirmedAssistant?.id,
                        )
                        return (
                          candidate?.executiveReasonForMatch ||
                          candidate?.reasonForMatch
                        )
                      })(),
                    },
                  },
                }}
              />
            ),
          }))}
        />
      </Field>
      <Field
        label={'Transition call date-time'}
        helperMessage={'Select in YOUR LOCAL time-zone'}
      >
        <FieldsRow>
          <DateTimePicker
            value={changeoverAt?.toISOString()}
            onChange={(dateTimeStr) =>
              update({
                variables: {
                  input: {
                    matchingId: matching.id,
                    changeoverAt: dateTimeStr ? new Date(dateTimeStr) : null,
                  },
                },
              })
            }
          />

          <InfoNotice
            type={'info'}
            title={'Why do I need to enter this?'}
            noTopUnset
            placement={'top'}
          >
            <InfoNoticeText>
              {
                'Until a transition call is booked by the executive, the field should remain empty or null. This reminds the team to send timely follow ups via email/sms.'
              }
              <br />
              <br />
              {
                'We intend on automating this date-time with a scheduling tool, but until then please enter it manually as soon as the call is scheduled.'
              }
            </InfoNoticeText>
          </InfoNotice>
        </FieldsRow>
      </Field>
      {!!changeoverAt && <TzInfoNotice dateTime={changeoverAt} />}
      {updateMutation.loading ? (
        <LoadingSpinner show />
      ) : updateMutation.error ? (
        <ErrorEmptyState error={updateMutation.error} />
      ) : null}
    </>
  )
}

export default Scheduling
