import { useCallback, useState } from 'react'

import { createWritableJpegFileStream } from '../lib/writeFile'

const useDownloadPhoto = (
  photoUrl?: string | null,
  fileName?: string | null,
) => {
  const [isDownloading, setIsDownloading] = useState(false)

  const downloadPhoto = useCallback(async () => {
    setIsDownloading(true)

    if (!photoUrl) {
      return
    }

    const writableStream = await createWritableJpegFileStream(
      `${fileName || 'photo'}.jpg`,
    )

    const imageUrl = `https://proxy.withdouble.com/${photoUrl
      .split(/^(https?:)?\/\//)
      .pop()}`

    const imageResponse = await fetch(imageUrl)

    if (imageResponse.body) {
      await imageResponse.body.pipeTo(writableStream)
    } else {
      writableStream.abort()
    }

    setIsDownloading(false)
  }, [fileName, photoUrl])

  return { isDownloading, downloadPhoto }
}

export default useDownloadPhoto
