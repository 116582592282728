import React from 'react'
import styled from 'styled-components'

import EmptyState from '../../../../components/EmptyState'
import { LoadingSpinner } from '../../../../components/Spinner'
import ItemWithNavlink from '../../../../components/list/ItemWithNavlink'
import { useListMessageTemplatesQuery } from '../../../../graphql/generated/hooks'
import { getKindEmoji } from '../../Templates/utils'
import { getSectionedName } from '../../utils'

const Outer = styled.div`
  padding: 8px 0;
  flex: 1;
  margin-bottom: 24px;
`

type TemplatesProps = {
  templateIds: string[]
}

const Templates = ({ templateIds }: TemplatesProps) => {
  const { data, loading } = useListMessageTemplatesQuery({
    variables: {
      ids: templateIds,
    },
    skip: !templateIds.length,
  })

  return (
    <Outer>
      {loading ? (
        <LoadingSpinner show />
      ) : data?.list.items.length ? (
        data?.list.items.map((template) => (
          <ItemWithNavlink
            key={template.id}
            href={`/message-templates/${template.id}`}
            isSelected={false}
            text={getSectionedName(template.name).name}
            after={() => getKindEmoji(template)}
          />
        ))
      ) : (
        <EmptyState
          emoji={'✉️'}
          header={'No templates found for this automation'}
          description={
            'If you expected to see templates here, make sure they are assigned to this automation by raising an engineering ticket in height.'
          }
        />
      )}
    </Outer>
  )
}

export default Templates
