import Badge, { Tint } from '../../../../../../components/Badge'

type Props = {
  isExecutive: boolean
  startDate: Date
  endDate?: Date
}

const WorkspaceUserStatusBadge = ({
  isExecutive,
  startDate,
  endDate,
}: Props) => {
  const now = new Date()

  if (startDate > now) {
    return (
      <Badge
        text={isExecutive ? 'Executive' : 'Future'}
        tint={'purple' as Tint}
      />
    )
  }

  if (endDate && endDate < now) {
    return (
      <Badge
        text={isExecutive ? 'Executive' : 'Inactive'}
        tint={(isExecutive ? 'blue' : 'gray') as Tint}
      />
    )
  }

  return (
    <Badge
      text={isExecutive ? 'Executive' : 'Active'}
      tint={(isExecutive ? 'blue' : 'green') as Tint}
    />
  )
}

export default WorkspaceUserStatusBadge
