import {
  TransitionInformingStatus,
  TransitionMatchingStatus,
  TransitionHandoverStatus,
  TransitionInformingCategory,
  Matching_ForTransitionListItemFragment,
} from '../../../graphql'
import graph from '../../../graphql/generated/introspection.json'

export type Bundled<T> = T & {
  bundle?: T[]
}

export const buildBundles = <T extends Matching_ForTransitionListItemFragment>(
  matchings: T[],
): Bundled<T>[] => {
  const bundledMatchings: Bundled<T>[] = []
  const otherMatchings: Bundled<T>[] = []

  matchings.forEach((matching) => {
    const principalUserId = matching.executive?.principalUser?.id
    if (!principalUserId) {
      bundledMatchings.push({ bundle: [], ...matching })
    } else {
      otherMatchings.push({ bundle: [], ...matching })
    }
  })

  // Dispatch other transitions in principal ones
  otherMatchings.forEach((matching) => {
    const principalUserId = matching.executive?.principalUser?.id

    // Find principal transition
    const principalMatching = bundledMatchings.find(
      (matching) => matching.executive?.id === principalUserId,
    )
    if (!principalMatching) {
      // Did not find an associated principal transition
      bundledMatchings.push(matching)
    } else {
      // Add transition to principal bundle
      if (!principalMatching.bundle?.length) {
        principalMatching.bundle = [{ bundle: [], ...principalMatching }]
      }
      principalMatching.bundle.push(matching)
    }
  })

  return bundledMatchings
}

export const getBundledId = <T extends Matching_ForTransitionListItemFragment>(
  matching: Bundled<T>,
): string => {
  if (!matching.bundle?.length) {
    return matching.id
  }

  return matching.bundle.map(({ id }) => id).join(',')
}

export const INFORMING_STATUS_LABEL: Record<TransitionInformingStatus, string> =
  graph.__schema.types
    .find(({ name }) => name === 'TransitionInformingStatus')
    // @ts-ignore
    ?.enumValues?.reduce(
      (
        labels: Record<TransitionInformingStatus, string>,
        { name, description }: { name: string; description: string },
      ) => ({
        ...labels,
        [name.trim()]: (description || name).trim(),
      }),
      {},
    ) || {}

export const INFORMING_CATEGORY_LABEL: Record<
  TransitionInformingCategory,
  string
> =
  graph.__schema.types
    .find(({ name }) => name === 'TransitionInformingCategory')
    // @ts-ignore
    ?.enumValues?.reduce(
      (
        labels: Record<TransitionInformingCategory, string>,
        { name, description }: { name: string; description: string },
      ) => ({
        ...labels,
        [name.trim()]: (description || name).trim(),
      }),
      {},
    ) || {}

export const MATCHING_STATUS_LABEL: Record<TransitionMatchingStatus, string> =
  graph.__schema.types
    .find(({ name }) => name === 'TransitionMatchingStatus')
    // @ts-ignore
    ?.enumValues?.reduce(
      (
        labels: Record<TransitionMatchingStatus, string>,
        { name, description }: { name: string; description: string },
      ) => ({
        ...labels,
        [name.trim()]: (description || name).trim(),
      }),
      {},
    ) || {}

export const HANDOVER_STATUS_LABEL: Record<TransitionHandoverStatus, string> =
  graph.__schema.types
    .find(({ name }) => name === 'TransitionHandoverStatus')
    // @ts-ignore
    ?.enumValues?.reduce(
      (
        labels: Record<TransitionMatchingStatus, string>,
        { name, description }: { name: string; description: string },
      ) => ({
        ...labels,
        [name.trim()]: (description || name).trim(),
      }),
      {},
    ) || {}
