// @ts-ignore
import { Item as AkItem } from '@atlaskit/navigation-next'
import React, { ComponentProps } from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

type Props = ComponentProps<AkItem> & {
  href: string
}

const Item = ({ href, ...props }: Props) => {
  const match = useRouteMatch(href)

  return (
    <NavLink to={href}>
      <AkItem isSelected={!!match} {...props} />
    </NavLink>
  )
}

export default Item
