import Select from '@atlaskit/select'
import React, { ComponentProps, useCallback, useMemo } from 'react'

import graph from '../graphql/generated/introspection.json'

type Option = { label: string; value: string }

const OPTIONS: Option[] =
  graph.__schema.types
    .find(({ name }) => name === 'AirtableClientFieldName')
    ?.enumValues?.map(({ name, description }) => ({
      label: (description || name).trim(),
      value: name.trim(),
    })) || []

type Props = Omit<ComponentProps<typeof Select>, 'options'> & {
  useAirtableValues?: boolean
  value?: string | undefined | null
  onChangeValue?: (newValue: string | undefined | null) => void
}

const AirtableClientFieldNameSelect = ({
  useAirtableValues,
  value: _value,
  onChangeValue,
  onChange: _onChange,
  ...selectProps
}: Props) => {
  const value = useMemo(() => {
    if (_value) {
      return OPTIONS.find(
        ({ label, value }) => _value === (useAirtableValues ? label : value),
      )
    }
  }, [_value, useAirtableValues])

  const onChange = useCallback(
    (newValue: Option) => {
      _onChange?.(newValue)
      onChangeValue?.(
        newValue && (useAirtableValues ? newValue.label : newValue.value),
      )
    },
    [_onChange, onChangeValue, useAirtableValues],
  )

  return (
    <Select
      options={OPTIONS}
      value={value}
      // @ts-ignore
      onChange={onChange}
      placeholder={'Airtable Client field'}
      {...selectProps}
    />
  )
}

export default AirtableClientFieldNameSelect
