import React from 'react'

import { getTokenString } from '../utils'

import { Balance, Outer, Title } from './styled'

type Props = {
  balance?: number
}

const TokenBalance = ({ balance = 0 }: Props) => {
  return (
    <Outer>
      <Title> {'Token Balance'}</Title>
      <Balance $zeroBalance={balance === 0}>{getTokenString(balance)}</Balance>
    </Outer>
  )
}

export default TokenBalance
