import { DateTimePicker } from '@atlaskit/datetime-picker'
import React from 'react'

import { LoadingSpinner } from '../../../../components/Spinner'
import {
  ToggleStateless as Toggle,
  ToggleOuter,
} from '../../../../components/Toggle'
import TzInfoNotice from '../../../../components/TzInfoNotice'
import { Field, FieldsRow } from '../../../../components/form'
import AssistantOnboardingAvailabilityButton from '../../../../components/onboarding-availability/AssistantOnboardingAvailabilityButton'
import {
  Matching_ForTransitionFullFragment,
  useUpdateTransitionHandoverMutation,
} from '../../../../graphql'
import { Bundled } from '../utils'

import HandoverStatusSelect from './components/HandoverStatusSelect'
import { InfoNotice, InfoNoticeText, LimitedWidth } from './styled'

type Props = {
  matching: Bundled<Matching_ForTransitionFullFragment>
}

const Handover = ({ matching }: Props) => {
  const [update, updateMutation] = useUpdateTransitionHandoverMutation()

  const handoverAt = matching.transition?.handoverAt
    ? new Date(matching.transition?.handoverAt)
    : undefined
  const newAssistant = matching.confirmedAssistant

  return (
    <>
      <Field label={'Handover call status'}>
        <FieldsRow>
          <LimitedWidth>
            <HandoverStatusSelect
              value={matching.transition!.handoverStatus}
              onChangeValue={(handoverStatus) =>
                handoverStatus &&
                update({
                  variables: {
                    input: { matchingId: matching.id, handoverStatus },
                  },
                })
              }
              isDisabled={updateMutation.loading}
              isLoading={updateMutation.loading}
            />
          </LimitedWidth>
          <InfoNotice type={'info'} title={'What is this about?'} noTopUnset>
            <InfoNoticeText>
              {
                "Ideally a handover call should be scheduled between the old and new Doubles, before the new Double's transition call with the executive. Handover calls are not always necessary, but generally recommended."
              }
              <br />
              <br />
              {
                'Depending on the sensitivity of the transition, the Ops Manager of the old Double will attend the handover call to facilitate.'
              }
            </InfoNoticeText>
          </InfoNotice>
        </FieldsRow>
      </Field>
      <Field label={''}>
        <ToggleOuter>
          <Toggle
            isChecked={!!matching.transition!.shouldDsmAttendHandover}
            onChange={() =>
              update({
                variables: {
                  input: {
                    matchingId: matching.id,
                    shouldManagerAttendHandover:
                      !matching.transition!.shouldDsmAttendHandover,
                  },
                },
              })
            }
          />
          {'Ops Manager to attend handover call (add to calendar invite)'}
        </ToggleOuter>
      </Field>

      {!!newAssistant && (
        <Field label={'Call availability'}>
          <FieldsRow>
            <div>
              <AssistantOnboardingAvailabilityButton
                assistantId={newAssistant.id}
              >
                {"See new Double's available call slots"}
              </AssistantOnboardingAvailabilityButton>
            </div>

            <InfoNotice type={'info'} title={'Why do I need this?'} noTopUnset>
              <InfoNoticeText>
                {
                  'When confirming their match with the executive, the new Double has provided availabilities for the transition call. Once the transition call has been scheduled, the Ops Manager should view the remaining available slots to schedule a handover call to take place before the transition call.'
                }
              </InfoNoticeText>
            </InfoNotice>
          </FieldsRow>
        </Field>
      )}

      <Field
        label={'Handover call date-time'}
        helperMessage={'Select in YOUR LOCAL time-zone'}
      >
        <FieldsRow>
          <DateTimePicker
            value={handoverAt?.toISOString()}
            onChange={(dateTimeStr) =>
              update({
                variables: {
                  input: {
                    matchingId: matching.id,
                    handoverAt: dateTimeStr ? new Date(dateTimeStr) : null,
                  },
                },
              })
            }
          />

          <InfoNotice
            type={'info'}
            title={'What will this do?'}
            noTopUnset
            placement={'top'}
          >
            <InfoNoticeText>
              {
                'Once the handover has been scheduled, the Ops Manager should update the date/time here.'
              }
              <br />
              <br />
              {'🚧 NOT YET →'}
              <br />
              {
                "Once set, the new Double will automatically get access to the exec's workspace for 1 hour, starting this time."
              }
              <br />
              {'🚧'}
            </InfoNoticeText>
          </InfoNotice>
        </FieldsRow>
      </Field>

      {!!handoverAt && <TzInfoNotice dateTime={handoverAt} />}

      {updateMutation.loading ? <LoadingSpinner show /> : null}
    </>
  )
}

export default Handover
