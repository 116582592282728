import useGetClientDomain from '../../../../hooks/useGetClientDomain'

type Props = {
  adhocId?: string
  isPool?: boolean
}

const ADHOC_SUB_PATH = 'adhoc/'
const POOL_SUB_PATH = 'pool/'

const useGetTaskUrl = ({ adhocId, isPool = true }: Props) => {
  const clientDomains = useGetClientDomain()

  const subPath = isPool ? POOL_SUB_PATH : ADHOC_SUB_PATH

  if (!adhocId) {
    return null
  }

  return clientDomains?.appUrl + subPath + adhocId
}

export default useGetTaskUrl
