import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import PageHeader from '../../../components/PageHeader'
import ItemWithNavlink from '../../../components/list/ItemWithNavlink'
import ListContainer from '../../../components/list/ListContainer'
import ListOuter from '../../../components/list/ListOuter'
import PageHeaderOuter from '../../../components/list/PageHeaderOuter'

import {
  DELEGATION_COACH_SYSTEM_PROMPT,
  DELEGATION_COACH_TASK_SUGGESTIONS,
} from './ROUTES'

const Outer = styled(ListContainer)`
  min-width: 280px;
  max-width: 400px;
`

const List = () => {
  const { url } = useRouteMatch()

  return (
    <Outer>
      <PageHeaderOuter>
        <PageHeader>{'Delegation Coach'}</PageHeader>
      </PageHeaderOuter>
      <ListOuter>
        <ItemWithNavlink
          href={`${url}${DELEGATION_COACH_SYSTEM_PROMPT}`}
          isSelected={false}
          text={'System Prompt'}
        />
        <ItemWithNavlink
          href={`${url}${DELEGATION_COACH_TASK_SUGGESTIONS}`}
          isSelected={false}
          text={'Tasks Suggestions Function'}
        />
      </ListOuter>
    </Outer>
  )
}

export default List
