import { DateTime } from 'luxon'
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import enumKeys from '../../../../../../../lib/enumKeys'
import useSwitch from '../../../../../../../lib/useSwitch'

import { getHour, getMeridiem, getNewValue } from './helpers'
import { Outer, HourInput, MinuteInput } from './styled'
import { Hour, Meridiem } from './types'

type Props = {
  onChange: (dateTime: DateTime) => void
  value: DateTime
  name?: string
}

const TimePicker = ({ onChange, value, name }: Props) => {
  const [isFocused, setIsFocusedTrue, setIsFocusedFalse] = useSwitch(false)

  const [hourState, setHourState] = useState<Hour | undefined>(getHour(value))
  const [minuteState, setMinuteState] = useState<number | undefined>(
    value?.minute,
  )
  const meridiemState = useMemo<Meridiem>(() => getMeridiem(value), [value])

  // When the input is not focused, we want to update the time picker when the
  // value changes. This is because the time picker is not controlled by the
  // value, but rather by the state.
  useEffect(() => {
    if (isFocused) return

    setHourState(getHour(value))
    setMinuteState(value.minute)
  }, [isFocused, value])

  const onHourChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const hourInputValue = event.currentTarget.value
      const intValue = parseInt(hourInputValue)
      if (isNaN(intValue)) {
        setHourState(undefined)
        return
      }

      if (!(1 <= intValue && intValue <= 12)) return

      setHourState(intValue as Hour)
      onChange(getNewValue(value, { hour: intValue as Hour }))
    },
    [onChange, value],
  )

  const onMinuteChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const minuteInputValue = event.currentTarget.value
      const intValue = parseInt(minuteInputValue)
      if (isNaN(intValue)) {
        setMinuteState(undefined)
        return
      }

      if (!(0 <= intValue && intValue < 60)) return

      setMinuteState(intValue)
      onChange(getNewValue(value, { minute: intValue }))
    },
    [onChange, value],
  )

  const [
    isMinuteInputFocused,
    setIsMinuteInputFocusedTrue,
    setIsMinuteInputFocusedFalse,
  ] = useSwitch(false)
  const onFocusMinuteInput = useCallback(() => {
    setIsFocusedTrue()
    setIsMinuteInputFocusedTrue()
  }, [setIsFocusedTrue, setIsMinuteInputFocusedTrue])
  const onBlurMinuteInput = useCallback(() => {
    setIsFocusedFalse()
    setIsMinuteInputFocusedFalse()
  }, [setIsFocusedFalse, setIsMinuteInputFocusedFalse])

  const hourInputRef = useRef<HTMLInputElement>()
  const onHourKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Enter') return

      hourInputRef.current?.blur()
      setIsFocusedFalse()
    },
    [setIsFocusedFalse],
  )

  const minuteInputRef = useRef<HTMLInputElement>()
  const onMinuteKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Enter') return

      minuteInputRef.current?.blur()
      onBlurMinuteInput()
    },
    [onBlurMinuteInput],
  )

  const onMeridiemChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const meridiem = event.currentTarget.value as Meridiem

      onChange(getNewValue(value, { meridiem }))
    },
    [onChange, value],
  )

  return (
    <Outer>
      <HourInput
        type={'text'}
        // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
        ref={hourInputRef as any}
        name={`${name}-hour`}
        onFocus={setIsFocusedTrue}
        onBlur={setIsFocusedFalse}
        value={hourState?.toString() || ''}
        onChange={onHourChange}
        onKeyPress={onHourKeyPress}
      />
      <span>{':'}</span>
      <MinuteInput
        type={'text'}
        name={`${name}-minute`}
        onFocus={onFocusMinuteInput}
        onBlur={onBlurMinuteInput}
        value={
          isMinuteInputFocused
            ? minuteState?.toString() || ''
            : value.toFormat('mm')
        }
        onChange={onMinuteChange}
        onKeyPress={onMinuteKeyPress}
      />
      <select
        name={`${name}-meridiem`}
        value={meridiemState}
        onChange={onMeridiemChange}
      >
        {enumKeys(Meridiem).map((enumKey) => (
          <option key={Meridiem[enumKey]} value={Meridiem[enumKey]}>
            {Meridiem[enumKey]}
          </option>
        ))}
      </select>
    </Outer>
  )
}

export default TimePicker
