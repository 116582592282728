import { EmailAddressWithNameInput } from '../../graphql'

export const SENDERS: (EmailAddressWithNameInput & { givenName: string })[] = [
  {
    email: 'success@withdouble.com',
    name: 'Customer Success @ Double',
    givenName: 'Customer Success @ Double',
  },
  {
    email: 'welcome@withdouble.com',
    name: 'Double Customer Success Team',
    givenName: 'Customer Success Team',
  },
  {
    email: 'support@withdouble.com',
    name: 'Double Support Team',
    givenName: 'Double Support Team',
  },
  {
    email: 'sandra@withdouble.com',
    name: 'Sandra Tinel',
    givenName: 'Sandra',
  },
  {
    email: 'lea.janne@withdouble.com',
    name: 'Léa Janne',
    givenName: 'Léa',
  },
  {
    email: 'alice.default@withdouble.com',
    name: 'Alice Default',
    givenName: 'Alice',
  },
  {
    email: 'maegan@withdouble.com',
    name: 'Maegan Raisch',
    givenName: 'Maegan',
  },
  {
    email: 'mercedes@withdouble.com',
    name: 'Mercedes Marquez Macri',
    givenName: 'Mercedes',
  },
]

export function formatEmailWithName({
  email,
  name,
  displayName,
  givenName,
}: {
  email: string
  name?: string | null
  displayName?: string | null
  givenName?: string | null
}): string {
  name = name || displayName || givenName
  return name ? `${name} <${email}>` : email
}
