import Icon from '@atlaskit/icon/glyph/people'
import React from 'react'
import { Route, useRouteMatch, Switch } from 'react-router-dom'
import styled from 'styled-components'

import { Page } from '../types'

import List from './List'
import NavigationSidebar from './NavigationSidebar'
import User from './User'
import Workspace from './Workspace'

const Outer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  height: 100%;
`

const Users: Page = () => {
  const { url } = useRouteMatch()

  return (
    <Outer>
      <Route path={`${url}`} component={List} />
      <Route path={`${url}/:userId`} component={NavigationSidebar} />
      <Switch>
        <Route
          path={`${url}/:userId/workspaces/:workspaceId`}
          component={Workspace}
        />
        <Route path={`${url}/:userId`} component={User} />
      </Switch>
    </Outer>
  )
}

Users.path = '/users'
Users.navTitle = 'Users'
Users.Icon = Icon
Users.requiredOperationIds = ['Query.usersList']

export default Users
