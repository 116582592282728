import styled from 'styled-components'

import Executive, { ExecutiveProps } from './Executive'
import TaskRating, { TaskRatingProps } from './TaskRating'

type ExecutiveWithFeedbackProps = TaskRatingProps &
  ExecutiveProps & {
    showFeedback?: boolean
  }

export const Outer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const ExecutiveWithFeedback = ({
  executive,
  rating,
  comments,
  showFeedback = false,
}: ExecutiveWithFeedbackProps) => {
  return (
    <Outer>
      <Executive executive={executive} />
      {showFeedback && <TaskRating rating={rating} comments={comments} />}
    </Outer>
  )
}

export default ExecutiveWithFeedback
