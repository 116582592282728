import { borderRadius, colors } from '@atlaskit/theme'
import styled from 'styled-components'

import CollapsibleText from '../../../../../components/CollapsibleText'
import { SubtleDate } from '../../../../../components/DateFormater'

export const Outer = styled.div`
  background: ${colors.N20};
  border-radius: ${borderRadius}px;
  padding: 8px 12px;
  margin-top: 8px;
`

export const Motivation = styled(CollapsibleText)`
  margin-top: 8px;
`

export const SubmitedDate = styled(SubtleDate)`
  position: absolute;
  top: -8px;
  right: 16px;
`
