import _Button from '@atlaskit/button'
import { colors } from '@atlaskit/theme'
import styled from 'styled-components'

import _AssistantScore from '../../../../../components/users/AssistantScore'

export const ScoreRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
  height: 90px;

  border-radius: 8px;
  border: 1px solid ${colors.N30};
`

export const ScoreColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
`

export const Button = styled(_Button)`
  padding: 0px;
  span {
    margin: 0px;
  }
`

export const AssistantScore = styled(_AssistantScore)`
  height: 100%;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100px;
    font-size: 20px;
  }
`
