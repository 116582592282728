import Button from '@atlaskit/button'
import AddIcon from '@atlaskit/icon/glyph/add'
import PageHeader from '@atlaskit/page-header'
import React, { useCallback, useMemo } from 'react'
import { RouteComponentProps, useHistory, useRouteMatch } from 'react-router'
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
} from 'react-sortable-hoc'

import { useUpsertDossierFieldMutation } from '../../../graphql'

import Field from './Field'
import { WideListOuter as ListOuter } from './components/styled'
import useDossier from './useDossier'
import { getSortedEntity, sortEntities } from './utils'

const SortableField = SortableElement(Field)
const SortableListOuter = SortableContainer(ListOuter)

type Props = RouteComponentProps<{ folderId: string; sectionId: string }>

const Fields = ({
  match: {
    params: { folderId, sectionId },
  },
}: Props) => {
  const navHistory = useHistory()
  const { folders, createLocalField } = useDossier()
  const _fields = folders
    ?.find(({ id }) => id === folderId)
    ?.sections?.find(({ id }) => id === sectionId)?.fields

  const fields = useMemo(
    () => [...(_fields || [])].sort(sortEntities),
    [_fields],
  )

  const match = useRouteMatch<{
    0: string
    fieldId?: string
    isEditing?: string
  }>('*/fields/:fieldId?/:isEditing(edit)?')
  const [upsertField] = useUpsertDossierFieldMutation()

  const { fieldId, isEditing } = {
    ...match?.params,
  }

  const sortFields: SortEndHandler = useCallback(
    ({ oldIndex, newIndex }) => {
      const sorted = getSortedEntity(fields, oldIndex, newIndex)
      if (sorted) {
        upsertField({
          variables: {
            input: {
              fieldId: sorted.entity.id,
              orderIndex: sorted.orderIndex,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            upsertDossierField: {
              __typename: 'UpsertDossierFieldOutput',
              field: {
                ...sorted.entity,
                orderIndex: sorted.orderIndex,
              },
            },
          },
        })
      }
    },
    [fields, upsertField],
  )

  const createField = useCallback(() => {
    const field = createLocalField(sectionId)
    navHistory.push(`${match?.params[0] || ''}/fields/${field.id}/edit`)
  }, [createLocalField, sectionId, navHistory, match])

  return (
    <SortableListOuter onSortEnd={sortFields} useDragHandle lockAxis={'y'}>
      <PageHeader
        actions={
          <Button
            iconBefore={<AddIcon label={''} />}
            appearance={'default'}
            onClick={createField}
          />
        }
      >
        {'Fields'}
      </PageHeader>
      {fields.map((field, index) => (
        <SortableField
          key={field.id}
          index={index}
          sectionId={sectionId}
          field={field}
          isSelected={fieldId === field.id}
          isEditing={!!isEditing}
        />
      ))}
    </SortableListOuter>
  )
}

export default Fields
