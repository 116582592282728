import { LoadingButton as Button } from '@atlaskit/button'
import { colors } from '@atlaskit/theme'
import React, { useState, useEffect } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { LoadingSpinner } from '../../components/Spinner'
import { useLoginMutation } from '../../graphql'
import auth, { useAuth } from '../../lib/auth'

const backgroundSrc = require('./background.png')

const Outer = styled.div<{ withBackground?: boolean }>`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${colors.background};
  background-size: cover;
  background-position: center;
  padding: 28px;
  ${({ withBackground }) =>
    withBackground &&
    css`
      background-image: url(${backgroundSrc});
    `}
`

const LoginComponent: React.FC<
  RouteComponentProps<{}, {}, { from: { pathname: string } }>
> = (props) => {
  const [minimal, setMinimal] = useState(true)
  const { isInitialized, isAuthenticated, isSignedIn, signIn } = useAuth()
  const [login, { loading, error }] = useLoginMutation({
    onCompleted({ login }) {
      auth.token = login ? login.token : null
    },
  })

  useEffect(
    () =>
      clearTimeout.bind(
        null,
        setTimeout(() => setMinimal(false), 1000),
      ),
    [],
  )

  if (isAuthenticated) {
    const { from } = props.location.state || { from: { pathname: '/' } }
    return <Redirect to={from} />
  }

  if (isInitialized && isSignedIn && !loading && !error && auth.idToken) {
    login({ variables: { idToken: auth.idToken } })
  }

  return (
    <Outer withBackground={!minimal}>
      {minimal ? (
        <LoadingSpinner show size={'large'} />
      ) : (
        <Button
          isLoading={loading || !isInitialized}
          appearance={'primary'}
          onClick={signIn}
        >
          {'Sign in to Double Cockpit'}
        </Button>
      )}
    </Outer>
  )
}

export default LoginComponent
