import Button from '@atlaskit/button'
import AddIcon from '@atlaskit/icon/glyph/add'
import FilterIcon from '@atlaskit/icon/glyph/filter'
import AkPageHeader from '@atlaskit/page-header'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

import {
  MoreDropdownMenu,
  DropdownItemGroup,
  DropdownItem,
} from './DropdownMenu'
import PopupButton from './PopupButton'
import { SearchTextField } from './form'

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  & > * + * {
    margin-left: 16px;
  }
`

type Props = ComponentProps<typeof AkPageHeader> & {
  searchTerm?: string | null | undefined
  searchInputPlaceholder?: string
  onChangeSearchTerm?: (term: string | null | undefined) => void

  filtersContent?: ComponentProps<typeof PopupButton>['content']
  hasActiveFilter?: boolean

  createActions?: {
    label: string
    onClick: () => void
  }[]
}

const PageHeader = ({
  searchTerm,
  searchInputPlaceholder = 'Search',
  onChangeSearchTerm,
  filtersContent,
  hasActiveFilter,
  createActions,
  ...akProps
}: Props) => {
  return (
    <AkPageHeader
      actions={
        !createActions?.length ? undefined : createActions.length === 1 ? (
          <Button
            iconBefore={<AddIcon label={''} />}
            onClick={createActions[0].onClick}
          />
        ) : (
          <MoreDropdownMenu
            triggerButtonProps={{
              appearance: 'default',
              spacing: 'default',
              iconBefore: <AddIcon label={''} />,
            }}
          >
            <DropdownItemGroup>
              {createActions.map(({ label, onClick }) => (
                <DropdownItem key={label} onClick={onClick}>
                  {label}
                </DropdownItem>
              ))}
            </DropdownItemGroup>
          </MoreDropdownMenu>
        )
      }
      bottomBar={
        <>
          <SearchBar>
            {!!onChangeSearchTerm && (
              <SearchTextField
                isCompact
                autoFocus
                placeholder={searchInputPlaceholder}
                waitMs={200}
                value={searchTerm || undefined}
                onChangeValue={onChangeSearchTerm}
              />
            )}
            {!!filtersContent && (
              <PopupButton
                content={filtersContent}
                buttonProps={{
                  iconBefore: <FilterIcon label={'Filter'} />,
                  appearance: hasActiveFilter ? 'primary' : undefined,
                }}
                placement={'right-start'}
              />
            )}
          </SearchBar>
        </>
      }
      {...akProps}
    />
  )
}

export default PageHeader
