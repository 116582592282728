import {
  FeatureFragment,
  FeatureTargetPredicate,
  FeatureTargetAssistantPredicate,
  FeatureTargetExecutivePredicate,
  FeatureTargetConstantPredicate,
  FeatureTargetFeaturePredicate,
  FeatureTargetCreatedAtPredicate,
} from '../../../graphql'

function assertNever(x: never): never {
  return x
}

export const APP_VERSIONS_ALL = 'x'
export const APP_VERSIONS_NONE = '< 0'

export function featureIsEnabledForAllAppVersions(
  feature: FeatureFragment,
): boolean {
  for (const appId in feature.appVersions) {
    const version = feature.appVersions[appId]
    if (version && version !== APP_VERSIONS_ALL) {
      return false
    }
  }
  return true
}

export function featureIsEnabledForEveryone(feature: FeatureFragment): boolean {
  return feature.targets.some(({ predicates }) =>
    predicates.every(
      (predicate) => 'constant' in predicate && predicate.constant === true,
    ),
  )
}

export function featureIsDisabledForEveryone(
  feature: FeatureFragment,
): boolean {
  return feature.targets.every(({ predicates }) =>
    predicates.some(
      (predicate) => 'constant' in predicate && predicate.constant === false,
    ),
  )
}

export type PredicateTypename = string &
  Required<FeatureTargetPredicate>['__typename']

export type PredicateType<Typename extends PredicateTypename> =
  Typename extends 'FeatureTargetConstantPredicate'
    ? FeatureTargetConstantPredicate
    : Typename extends 'FeatureTargetExecutivePredicate'
    ? FeatureTargetExecutivePredicate
    : Typename extends 'FeatureTargetAssistantPredicate'
    ? FeatureTargetAssistantPredicate
    : Typename extends 'FeatureTargetFeaturePredicate'
    ? FeatureTargetFeaturePredicate
    : Typename extends 'FeatureTargetCreatedAtPredicate'
    ? FeatureTargetCreatedAtPredicate
    : never

export function getPredicateTypename(
  predicate: FeatureTargetPredicate,
): PredicateTypename {
  if (predicate.__typename) {
    return predicate.__typename
  }

  if ('constant' in predicate) {
    return 'FeatureTargetConstantPredicate'
  }

  if ('executiveIds' in predicate) {
    return 'FeatureTargetExecutivePredicate'
  }

  if ('assistantIds' in predicate) {
    return 'FeatureTargetAssistantPredicate'
  }

  if ('featureId' in predicate) {
    return 'FeatureTargetFeaturePredicate'
  }

  if ('createdAfter' in predicate) {
    return 'FeatureTargetCreatedAtPredicate'
  }

  assertNever(predicate)
}

export function predicateIfOfType<Typename extends PredicateTypename>(
  predicate: FeatureTargetPredicate,
  typename: Typename,
) {
  if (predicate.__typename === typename) {
    return predicate as PredicateType<Typename>
  }
}

export function findPredicateOfType<Typename extends PredicateTypename>(
  predicates: FeatureTargetPredicate[],
  typename: Typename,
) {
  for (const predicate of predicates) {
    if (getPredicateTypename(predicate) === typename) {
      return predicate as PredicateType<Typename>
    }
  }

  return null
}
