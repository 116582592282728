import { FormSection } from '@atlaskit/form'
import { colors, borderRadius } from '@atlaskit/theme'
import { transparentize } from 'polished'
import React, { ComponentProps } from 'react'
import { Bar } from 'react-chartjs-2'
import styled, { css } from 'styled-components'

import ErrorBanner from '../../../components/ErrorBanner'
import { LoadingSpinner } from '../../../components/Spinner'
import { useGetWorkspaceReportQuery } from '../../../graphql'

const COLORS = [
  colors.P200,
  colors.Y200,
  colors.T200,
  colors.R200,
  colors.G200,
  colors.B200,
  colors.P400,
  colors.Y400,
  colors.T400,
  colors.R400,
  colors.G400,
  colors.B400,
]

const WEEKS_COUNT = 6
const MONTHS_COUNT = 4

function formatMonth(month: number): string {
  const localDate = new Date(2021, month - 1, 1)
  return localDate.toLocaleDateString(undefined, { month: 'short' })
}

function formatWeek(someDay: string): string {
  const [year, month, day] = someDay.split('-')
  const localDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
  return localDate.toLocaleDateString(undefined, {
    month: 'short',
    day: 'numeric',
  })
}

const Outer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px 0px;
`

const ChartOuter = styled.div`
  padding: 16px 0;
`

const Table = styled.table``
const Td = styled.td<{
  empty?: boolean
  centered?: boolean
  bold?: boolean
  grey?: boolean
}>`
  font-size: smaller;
  padding: 4px 8px;
  border: 1px solid ${colors.backgroundHover()};

  ${({ empty }) =>
    !!empty &&
    css`
      border: none;
      background-color: transparent !important;
    `}

  ${({ centered }) =>
    !!centered &&
    css`
      text-align: center;
    `}

  ${({ bold }) =>
    !!bold &&
    css`
      font-weight: 600;
    `}

  ${({ grey }) =>
    !!grey &&
    css`
      background-color: ${colors.N20};
    `}
`

const Tr = styled.tr`
  tbody &:hover ${Td} {
    background-color: ${colors.backgroundActive()};
    /* color: ${colors.textHover()}; */
  }
`

interface Props extends ComponentProps<'div'> {
  workspaceId: string
}

const Reporting = ({ workspaceId }: Props) => {
  const { data, loading, error } = useGetWorkspaceReportQuery({
    variables: {
      workspaceId,
    },
  })

  const report = data?.workspace?.usage
  const limitedWeeks = report?.weeklyUsages.slice(0, WEEKS_COUNT).reverse()
  const limitedMonths = report?.monthlyUsages.slice(0, MONTHS_COUNT).reverse()

  return (
    <Outer>
      <ErrorBanner error={error} />

      <FormSection title={'Hours delegated per week'}>
        <ChartOuter>
          <Bar
            height={200}
            options={{
              legend: false,
              tooltips: {
                backgroundColor: transparentize(
                  0.1,
                  /*colors.heading()*/ '#0052CC',
                ),
                cornerRadius: borderRadius(),
                callbacks: {
                  title: ([{ label }]: { label: string }[]) => 'Week ' + label,
                  label: ({ value }: { value: string }) => value + ' hrs',
                },
              },
              scales: {
                xAxes: [
                  {
                    stacked: true,
                    gridLines: {
                      color: colors.backgroundHover(),
                    },
                    ticks: {
                      fontColor: colors.text(),
                    },
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                    gridLines: {
                      color: colors.backgroundHover(),
                    },
                    ticks: {
                      min: 0,
                      stepSize: 1,
                      fontColor: colors.text(),
                      callback(value: number) {
                        return `${value} hr${value !== 1 ? 's' : ''}`
                      },
                    },
                  },
                ],
              },
            }}
            data={{
              labels: report?.weeklyUsages
                .map(({ week }) => `${week}`)
                .reverse(),
              datasets: (report ? [report] : []).map(
                ({ weeklyUsages }, index) => ({
                  backgroundColor: COLORS[index],
                  data: weeklyUsages.map(({ hours }) => hours).reverse(),
                }),
              ),
            }}
          />
        </ChartOuter>
      </FormSection>

      <FormSection title={`Last ${WEEKS_COUNT} weeks`}>
        <Table cellSpacing={'0'} cellPadding={'0'}>
          <thead>
            <Tr>
              <Td empty />
              {limitedWeeks?.map(({ week, weekYear }) => (
                <Td key={week + weekYear} centered bold>{`Week ${week}`}</Td>
              ))}
            </Tr>
            <Tr>
              <Td empty />
              {limitedWeeks?.map(({ sunday }) => (
                <Td key={sunday} centered>
                  {formatWeek(sunday)}
                </Td>
              ))}
            </Tr>
          </thead>
          <tbody>
            {limitedWeeks &&
              [
                ...limitedWeeks.reduce(
                  (cats, { categories }) =>
                    categories.reduce(
                      (cats, { category }) => cats.add(category || null),
                      cats,
                    ),
                  new Set<string | null>(),
                ),
              ]
                .sort()
                .map((category) => (
                  <Tr key={category}>
                    <Td bold>{category || 'N/A'}</Td>
                    {limitedWeeks?.map(({ week, hours, categories }) => (
                      <Td key={week} centered>
                        {categories.find((u) => u.category === category) &&
                          (
                            ((categories.find((u) => u.category === category)
                              ?.hours || 0) /
                              hours) *
                            100
                          ).toFixed(1) + '%'}
                      </Td>
                    ))}
                  </Tr>
                ))}
          </tbody>
          <tfoot>
            <Tr>
              <Td bold grey>
                {'Total'}
              </Td>
              {limitedWeeks?.map(({ hours }, index) => (
                <Td key={index} centered grey>
                  {hours.toFixed(2)}
                </Td>
              ))}
            </Tr>
          </tfoot>
        </Table>
      </FormSection>

      <FormSection title={`Last ${MONTHS_COUNT} months`}>
        <Table cellSpacing={'0'} cellPadding={'0'}>
          <thead>
            <Tr>
              <Td empty />
              {limitedMonths?.map(({ month, year }) => (
                <Td key={year + month} centered bold>
                  {formatMonth(month)}
                </Td>
              ))}
            </Tr>
          </thead>
          <tbody>
            {limitedMonths &&
              [
                ...limitedMonths.reduce(
                  (cats, { categories }) =>
                    categories.reduce(
                      (cats, { category }) => cats.add(category || null),
                      cats,
                    ),
                  new Set<string | null>(),
                ),
              ]
                .sort()
                .map((category) => (
                  <Tr key={category}>
                    <Td bold>{category || 'N/A'}</Td>
                    {limitedMonths?.map(({ hours, categories }, index) => (
                      <Td key={index} centered>
                        {categories.find((u) => u.category === category) &&
                          (
                            ((categories.find((u) => u.category === category)
                              ?.hours || 0) /
                              hours) *
                            100
                          ).toFixed(1) + '%'}
                      </Td>
                    ))}
                  </Tr>
                ))}
          </tbody>
          <tfoot>
            <Tr>
              <Td bold grey>
                {'Total'}
              </Td>
              {limitedMonths?.map(({ hours }, index) => (
                <Td key={index} centered grey>
                  {hours.toFixed(2)}
                </Td>
              ))}
            </Tr>
          </tfoot>
        </Table>
      </FormSection>

      <LoadingSpinner show={loading} />
    </Outer>
  )
}

export default Reporting
