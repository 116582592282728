import { transparentize } from 'polished'
import styled from 'styled-components'

export const Outer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  min-width: 600px;
  max-width: 1000px;
  height: 100%;

  padding: 24px 16px;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ToggleInputContainer = styled(InputContainer)`
  align-items: center;
`

export const PromptsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

export const PromptInputContainer = styled(InputContainer)`
  min-width: 300px;
  flex: 1;
  height: 30vh;
`

export const ButtonOuter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 16px 4px;
  position: sticky;
  bottom: 0;
  background-color: ${transparentize(0.2, 'white')};
`

export const FieldsRow = styled.div`
  display: flex;

  & > div {
    flex: 1 1;

    & + div {
      margin-left: 16px;
    }
  }

  & + & {
    margin-top: 4px;
  }
`

export const MessageCapField = styled.div`
  max-width: 120px;
`
