import EditorFeedbackIcon from '@atlaskit/icon/glyph/editor/feedback'
import { colors } from '@atlaskit/theme'
import Tooltip from '@atlaskit/tooltip'
import React, { ComponentProps, useMemo } from 'react'

import Badge, { Tint } from '../../../../../../components/Badge'
import { Maybe } from '../../../../../../graphql'
import { CenterColumnContainer } from '../../styled'

export interface TaskRatingProps extends Partial<ComponentProps<typeof Badge>> {
  rating?: Maybe<number>
  comments?: Maybe<string>
}

const TaskRating = ({ rating, comments }: TaskRatingProps) => {
  const [emoji, tint] = useMemo(() => {
    switch (rating) {
      case 1:
        return ['😡', 'red']
      case 2:
        return ['😕', 'red']
      case 3:
        return ['😐', 'yellow']
      case 4:
        return ['🙂', 'green']
      case 5:
        return ['😃', 'green']
      default:
        return ['❓', 'gray']
    }
  }, [rating])

  return (
    <CenterColumnContainer>
      <Badge
        emoji={emoji}
        title={`Task Rating`}
        text={rating ?? ''}
        isLight
        tint={tint as Tint}
      />
      {comments && (
        <Tooltip content={comments}>
          <EditorFeedbackIcon
            label={'Feedback'}
            size={'small'}
            primaryColor={colors.primary()}
          />
        </Tooltip>
      )}
    </CenterColumnContainer>
  )
}

export default TaskRating
