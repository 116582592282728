import { RadioSelect, SelectProps } from '@atlaskit/select'
import React, { useCallback, useMemo } from 'react'

import { TransitionInformingCategory } from '../../../../../graphql'
import { INFORMING_CATEGORY_LABEL } from '../../utils'

type Option = { value: TransitionInformingCategory; label: string }

const OPTIONS: Option[] = [
  TransitionInformingCategory.CHURN_RISK,
  TransitionInformingCategory.DETERMINING_NEEDS,
  TransitionInformingCategory.NON_RESPONSIVE,
  TransitionInformingCategory.ACCEPTED,
].map((value) => ({
  value,
  label: INFORMING_CATEGORY_LABEL[value] || value,
}))

type Props = Omit<SelectProps<Option>, 'options'> & {
  value?: TransitionInformingCategory | undefined | null
  onChangeValue?: (
    newValue: TransitionInformingCategory | undefined | null,
  ) => void
}

const TransitionInformingCategorySelect = ({
  value: _value,
  onChangeValue,
  onChange: _onChange,
  ...selectProps
}: Props) => {
  const value = useMemo(() => {
    if (_value) {
      return OPTIONS.find(({ value }) => _value === value)
    }
  }, [_value])

  const onChange = useCallback(
    (newValue: Option) => {
      _onChange?.(newValue)
      onChangeValue?.(newValue?.value)
    },
    [_onChange, onChangeValue],
  )

  return (
    <RadioSelect
      options={OPTIONS}
      value={value}
      // @ts-ignore
      onChange={onChange}
      placeholder={'How did they take it?!'}
      isClearable
      isSearchable={false}
      {...selectProps}
    />
  )
}

export default TransitionInformingCategorySelect
