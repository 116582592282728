import Icon from '@atlaskit/icon/glyph/bitbucket/commits'
import PageHeader from '@atlaskit/page-header'
import { colors } from '@atlaskit/theme'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import ListContainer from '../../../components/list/ListContainer'
import PageHeaderOuter from '../../../components/list/PageHeaderOuter'
import useValues from '../../../lib/useValues'
import { Page } from '../../types'

import Detail from './Detail'
import FilterEditor from './FilterEditor'
import List from './List'
import { ListFilter } from './utils'

const Outer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  min-width: 600px;
  height: 100%;
`

const Sidebar = styled(ListContainer)`
  min-width: 250px;
  max-width: 300px;
`

const ListOuter = styled.div`
  box-shadow: 0 0 0 2px ${colors.backgroundHover},
    0 0 10px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: stretch;
  padding: 0 12px;
  overflow: auto;
  min-width: 450px;
  max-width: 650px;
`

const DetailOuter = styled.div`
  flex: 1;
  min-width: 400px;
  padding: 0 24px;
`

const FilterOuter = styled.div`
  padding: 0 24px;
`

const AutomationJobs: Page = () => {
  const [filter, { patch: patchFilters }] = useValues<ListFilter>({})
  const navHistory = useHistory()
  const { url } = useRouteMatch()
  const {
    params: { automationJobId },
  } = useRouteMatch<{ automationJobId: string }>(`${url}/:automationJobId`) || {
    params: {},
  }

  return (
    <Outer>
      <Sidebar>
        <PageHeaderOuter>
          <PageHeader>{'Automation Jobs'}</PageHeader>
        </PageHeaderOuter>
        <FilterOuter>
          <FilterEditor value={filter} onPatch={patchFilters} />
        </FilterOuter>
      </Sidebar>
      <ListOuter>
        <List
          filter={filter}
          onClickItem={(jobId) => navHistory.push(`${url}/${jobId}`)}
        />
      </ListOuter>
      <DetailOuter>
        <Detail automationJobId={automationJobId} />
      </DetailOuter>
    </Outer>
  )
}

AutomationJobs.path = '/automation-jobs'
AutomationJobs.navTitle = 'Automation Jobs'
AutomationJobs.Icon = Icon
AutomationJobs.requiredOperationIds = ['Query.automationJobsList']

export default AutomationJobs
