import { colors } from '@atlaskit/theme'
import { Link as _Link } from 'react-router-dom'
import styled from 'styled-components'

export const Outer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  padding: 8px;

  background: ${colors.backgroundActive};
  color: ${colors.text};

  &:hover {
    background-color: ${colors.backgroundHover};
    color: ${colors.textHover};
  }
`
