import memoize from 'lodash/memoize'

// https://www.remove.bg/api#api-reference
interface Config {
  originalImageUrl: string

  // Maximum output image resolution
  size:
    | 'preview' // Resize image to 0.25 megapixels (e.g. 625×400 pixels)
    | 'full' // Use original image resolution
    | 'auto' // Use highest available resolution (based on image size and available credits)

  // Foreground type
  type:
    | 'auto' // Automatically detect kind of foreground
    | 'person' // Use person(s) as foreground
    | 'product' // Use product(s) as foreground
    | 'car' // Use car as foreground

  // Classification level of the detected foreground type
  typeLevel?:
    | 'none' // No classification (X-Type Header won't bet set on the response)
    | '1' // Use coarse classification classes: [person, product, animal, car, other]
    | '2' // Use more specific classification classes: [person, product, animal, car, car_interior, car_part, transportation, graphics, other]
    | 'latest' // Always use the latest classification classes available

  format: // | 'auto' // Use PNG format if transparent regions exist, otherwise use JPG format (default)
  // | 'zip' // ZIP format, contains color image and alpha matte image, supports transparency (recommended).
  | 'png' // PNG format with alpha transparency
    | 'jpg' // JPG format, no transparency

  // Region of interest: Only contents of this rectangular region can be detected as foreground.
  // Everything outside is considered background and will be removed.
  // The rectangle is defined as two x/y coordinates in the format "x1 y1 x2 y2".
  // The coordinates can be in absolute pixels (suffix 'px') or relative to the width/height of the image (suffix '%').
  // By default, the whole image is the region of interest ("0% 0% 100% 100%").
  roi?: string

  // Whether to crop off all empty regions (default: false)
  crop?: boolean

  // Adds a margin around the cropped subject (default: 0).
  // Can be an absolute value (e.g. "30px") or relative to the subject size (e.g. "10%").
  // Can be a single value (all sides), two values (top/bottom and left/right) or four values (top, right, bottom, left).
  // This parameter only has an effect when "crop=true".
  // The maximum margin that can be added on each side is 50% of the subject dimensions or 500 pixels.
  cropMargin?: string

  // Scales the subject relative to the total image size.
  // Can be any value from "10%" to "100%", or "original" (default).
  // Scaling the subject implies "position=center" (unless specified otherwise).
  scale?: 'original' | string

  // Positions the subject within the image canvas.
  position?:
    | 'original' // default unless "scale" is given
    | 'center' // default when "scale" is given
    | string // a value from "0%" to "100%" (both horizontal and vertical) or two values (horizontal, vertical)

  channels?:
    | 'rgba' // Request either the finalized image (default)
    | 'alpha' // or an alpha mask

  // Whether to add an artificial shadow to the result (default: false).
  // NOTE: Adding shadows is currently only supported for car photos.
  // Other subjects are returned without shadow, even if set to true (this might change in the future).
  addShadow?: boolean

  // Whether to have semi-transparent regions in the result (default: true).
  // NOTE: Semitransparency is currently only supported for car windows (this might change in the future).
  // Other objects are returned without semitransparency, even if set to true.
  semitransparency?: boolean

  // Adds a solid color background.
  // Can be a hex color code (e.g. 81d4fa, fff) or a color name (e.g. green).
  // For semi-transparency, 4-/8-digit hex codes are also supported (e.g. 81d4fa77).
  // (If this parameter is present, the other bg_ parameters must be empty.)
  backgroundColor?: string
}

export async function getModifiedImage(config: Config): Promise<string> {
  let imageUrl: string | undefined
  let imageFileBase64: string | undefined

  if (config.originalImageUrl.startsWith('data:')) {
    imageFileBase64 = config.originalImageUrl.split(';base64,').pop()
  } else {
    imageUrl = config.originalImageUrl
  }

  const response = await window.fetch('https://api.remove.bg/v1.0/removebg', {
    method: 'POST',
    headers: {
      'X-API-Key': process.env.REACT_APP_REMOVEBG_API_KEY || '',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      // eslint-disable-next-line camelcase
      image_url: imageUrl,
      // eslint-disable-next-line camelcase
      image_file_b64: imageFileBase64,
      size: config.size,
      type: config.type,
      // eslint-disable-next-line camelcase
      type_level: config.typeLevel,
      format: config.format,
      roi: config.roi,
      crop: config.crop,
      // eslint-disable-next-line camelcase
      crop_margin: config.cropMargin,
      scale: config.scale,
      position: config.position,
      channels: config.channels,
      // eslint-disable-next-line camelcase
      add_shadow: config.addShadow,
      semitransparency: config.semitransparency,
      // eslint-disable-next-line camelcase
      bg_color: config.backgroundColor,
    }),
  })

  const body = await response.json()
  const base64 = body.data.result_b64

  const dataUri = `data:image/${
    config.format === 'jpg' ? 'jpeg' : 'png'
  };base64,${base64}`

  return dataUri
}

export const getTransparentAvatar = memoize(function (imageUrl: string) {
  return getModifiedImage({
    originalImageUrl: imageUrl,
    size: 'full',
    type: 'person',
    format: 'png',
    crop: false,
    position: 'original',
    scale: 'original',
  })
})
