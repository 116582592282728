import React from 'react'

import Badge from '../../../../components/Badge'
import { Matching_CatalogVisibilityFragment } from '../../../../graphql'

type Props = {
  matching: Matching_CatalogVisibilityFragment
  noText?: boolean
  onlyIfHidden?: boolean
}

const CatalogVisibilityBadge = ({ matching, noText, onlyIfHidden }: Props) => {
  if (!matching.isHiddenFromCatalog && onlyIfHidden) {
    return null
  }

  return (
    <Badge
      emoji={matching.isHiddenFromCatalog ? '👻' : '📖'}
      title={
        matching.isHiddenFromCatalog
          ? 'Not visible in catalog by assistants'
          : 'In catalog, visible to assistants'
      }
      text={
        noText
          ? undefined
          : matching.isHiddenFromCatalog
          ? 'Not in catalog'
          : 'In catalog'
      }
    />
  )
}

export default CatalogVisibilityBadge
