import PageHeader from '@atlaskit/page-header'
import { colors } from '@atlaskit/theme'
import Tooltip from '@atlaskit/tooltip'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import Tabs from '../../../../components/Tabs'
import { ToggleStateless as Toggle } from '../../../../components/Toggle'
import {
  AutomationActionGroupFragment,
  AutomationFragment,
} from '../../../../graphql'
import AutomationJobsInline from '../../Jobs/ListInline'

import Configuration from './Configuration'
import Templates from './Templates'

const Outer = styled.div`
  box-shadow: 0 0 0 2px ${colors.backgroundHover},
    0 0 10px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: stretch;
  padding: 0 24px;
  overflow: auto;
  min-width: 400px;
`

const TabContent = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Subtitle = styled.div`
  font-size: small;
  font-weight: 400;
`

interface AutomationProps {
  automation: AutomationFragment
}

const Automation = ({ automation }: AutomationProps) => {
  const messageTemplateIds = useMemo(
    () =>
      automation.actionGroups
        .map((actionGroup: AutomationActionGroupFragment) =>
          actionGroup.actions.map((action) => {
            return action.config?.messageTemplateId
          }),
        )
        .flat()
        .filter(Boolean) as string[],
    [automation],
  )

  const tabs = [
    {
      label: 'Configuration',
      content: (
        <TabContent>
          <Configuration automation={automation} />
        </TabContent>
      ),
    },
    {
      label: 'Jobs',
      content: (
        <TabContent>
          <AutomationJobsInline
            key={automation.id}
            filter={{
              automationIds: [automation.id],
            }}
          />
        </TabContent>
      ),
    },
    {
      label: 'Templates',
      content: (
        <TabContent>
          <Templates key={automation.id} templateIds={messageTemplateIds} />
        </TabContent>
      ),
    },
  ].filter(Boolean)

  return (
    <>
      <PageHeader
        actions={
          <Tooltip
            content={
              automation.isEnabled
                ? 'Turn OFF (pending jobs will not complete)'
                : 'Turn ON'
            }
          >
            <Toggle
              size={'large'}
              isChecked={automation.isEnabled}
              isDisabled
            />
          </Tooltip>
        }
      >
        {automation.name}
        <br />
        <Subtitle>{automation.description}</Subtitle>
      </PageHeader>
      <Tabs isContentPersisted leftTabs={tabs} />
    </>
  )
}

type MaybeAutomationProps = Partial<AutomationProps>

const MaybeAutomation = ({ automation }: MaybeAutomationProps) => (
  <Outer>
    {!!automation && <Automation key={automation.id} automation={automation} />}
  </Outer>
)

export default MaybeAutomation
