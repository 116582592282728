import React, { ComponentProps, useMemo, useState, useEffect } from 'react'

import MarkdownEditor from '../../../../components/MarkdownEditor'
import Modal from '../../../../components/Modal'
import { Field, Checkbox, Select } from '../../../../components/form'
import { SingleUserSelect } from '../../../../components/users/Select'
import { UserCategory } from '../../../../graphql'
import useValues from '../../../../lib/useValues'

export type Values = {
  assistantId: string
  matchingRationale?: string
  subscribeToCsatEmail?: boolean
  markTopicsAsRead?: boolean
  isTransition?: boolean
  isCoverage?: boolean
}

enum Purpose {
  TRANSITION = 'Transition',
  COVERAGE = 'Temporary coverage',
  PERMANENT = 'Permanent',
}

type Props = ComponentProps<typeof Modal> & {
  onSubmit: (values: Values) => void
}

const SelectAssistantModal = ({ onSubmit, ...modalProps }: Props) => {
  const [values, { reset, setters }] = useValues<Values>(
    {
      assistantId: '',
      markTopicsAsRead: true,
    },
    [
      'assistantId',
      'matchingRationale',
      'subscribeToCsatEmail',
      'markTopicsAsRead',
      'isTransition',
      'isCoverage',
    ],
  )

  const [purpose, setPurpose] = useState<Purpose>()

  const actions = useMemo(
    () => [
      {
        text: 'Select',
        onClick: () => onSubmit(values),
        isDisabled: !(values.assistantId && purpose),
      },
      { text: 'Cancel', onClick: modalProps.onClose },
    ],
    [modalProps.onClose, onSubmit, purpose, values],
  )

  useEffect(() => {
    const isTransition = purpose === Purpose.TRANSITION
    if (isTransition !== values.isTransition) {
      setters.isTransition?.(isTransition)
    }

    const isCoverage = purpose === Purpose.COVERAGE
    if (isCoverage !== values.isCoverage) {
      setters.isCoverage?.(isCoverage)
    }
  }, [purpose, setters, values.isCoverage, values.isTransition])

  return (
    <Modal
      heading={'Select Assistant'}
      width={'small'}
      onCloseComplete={() => {
        reset()
        setPurpose(undefined)
      }}
      {...modalProps}
      actions={actions}
    >
      <Field label={'Assistant'} isRequired>
        <SingleUserSelect
          value={values.assistantId ? { id: values.assistantId } : undefined}
          categories={[
            UserCategory.ASSISTANT,
            UserCategory.SANDBOX,
            UserCategory.TEAM,
          ]}
          isClearable
          onChange={(user: null | { id: string }) =>
            setters?.assistantId?.(user?.id || '')
          }
          validationState={!values.assistantId ? 'error' : undefined}
          menuPlacement={'bottom'}
          maxMenuHeight={130}
        />
      </Field>

      <Field label={'Purpose'} isRequired>
        <Select<Purpose>
          value={purpose ? { value: purpose, label: purpose } : undefined}
          onChangeValue={setPurpose}
          options={Object.values(Purpose).map((value) => ({
            value,
            label: value,
          }))}
          validationState={purpose ? 'default' : 'error'}
        />
      </Field>

      <Field
        label={'Matching Rationale'}
        helperMessage={'2 to 3 bullets, visible to exec'}
      >
        <MarkdownEditor
          value={values.matchingRationale}
          onChange={setters.matchingRationale!}
          minHeight={90}
        />
      </Field>

      <Field label={'Actions'} helperMessage={''}>
        <Checkbox
          label={'Mark all topics as read'}
          isChecked={values.markTopicsAsRead}
          onChangeValue={setters.markTopicsAsRead}
        />
      </Field>

      <Field
        label={''}
        helperMessage={'Force re-subscription if user had unsubscribed'}
      >
        <Checkbox
          label={'Subscribe to CSAT emails'}
          isChecked={values.subscribeToCsatEmail}
          onChangeValue={setters.subscribeToCsatEmail}
        />
      </Field>
    </Modal>
  )
}

export default SelectAssistantModal
