import Lozenge from '@atlaskit/lozenge'
// @ts-ignore
import { Item as AtlaskitItem } from '@atlaskit/navigation-next'
import truncate from 'lodash/truncate'
import React, { ComponentProps } from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

import { AutomationFragment } from '../../../../graphql'
import { getSectionedName } from '../../utils'

type Props = Omit<ComponentProps<typeof NavLink>, 'to'> & {
  automation: AutomationFragment
  withSectionedName?: boolean
  withDescription?: boolean
}

const Item = ({
  automation,
  withSectionedName,
  withDescription,
  ...props
}: Props) => {
  const href = `/automations/${automation.id}`
  const match = useRouteMatch(href)

  return (
    <NavLink to={href} {...props}>
      <AtlaskitItem
        isSelected={!!match}
        text={
          withSectionedName
            ? getSectionedName(automation.name).name
            : automation.name
        }
        subText={
          withDescription
            ? truncate(automation.description || '', { length: 70 })
            : undefined
        }
        after={() => (
          <Lozenge appearance={automation.isEnabled ? 'success' : 'default'}>
            {automation.isEnabled ? 'ON' : 'OFF'}
          </Lozenge>
        )}
      />
    </NavLink>
  )
}

export default Item
