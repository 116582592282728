import { QueryResult } from '@apollo/client'
import { useMemo } from 'react'

import {
  Exact,
  GetAssistantHourlyRateUpdatesQuery,
  useClearAssistantHourlyRateUpdatesMutation,
  useGetAssistantHourlyRateUpdatesLazyQuery,
} from '../../../graphql'

const useQueryHourlyRateUpdates = (
  assistantId: string,
): [
  () => Promise<any>,
  QueryResult<
    GetAssistantHourlyRateUpdatesQuery,
    Exact<{
      userId: string
    }>
  >,
] => {
  const [queryHourlyRateUpdates, assistantHourlyRateUpdatesQuery] =
    useGetAssistantHourlyRateUpdatesLazyQuery({
      variables: {
        userId: assistantId,
      },
    })

  const [clearAssistantHourlyRateUpdates] =
    useClearAssistantHourlyRateUpdatesMutation()

  return useMemo(
    () => [
      async () => {
        await clearAssistantHourlyRateUpdates()
        queryHourlyRateUpdates()
      },
      assistantHourlyRateUpdatesQuery,
    ],
    [
      assistantHourlyRateUpdatesQuery,
      clearAssistantHourlyRateUpdates,
      queryHourlyRateUpdates,
    ],
  )
}

export default useQueryHourlyRateUpdates
