import { useState, useCallback, SetStateAction, Dispatch } from 'react'

type TurnOn = () => void
type TurnOff = () => void
type Toggle = () => void
type Set = Dispatch<SetStateAction<boolean>>

type Output = [boolean, TurnOn, TurnOff, Toggle, Set]

const useSwitch = (defaultValue = false): Output => {
  const [value, setValue] = useState(defaultValue)

  return [
    value,
    useCallback(() => setValue(true), [setValue]),
    useCallback(() => setValue(false), [setValue]),
    useCallback(() => setValue((prev) => !prev), [setValue]),
    setValue,
  ]
}

export default useSwitch
