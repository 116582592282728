import Icon from '@atlaskit/icon/glyph/canvas'
import PageHeader from '@atlaskit/page-header'
import React from 'react'

import PageHeaderOuter from '../../../components/list/PageHeaderOuter'
import {
  isAssistantable,
  useListAssistantsInOrientationQuery,
  UserCategory,
} from '../../../graphql'
import { Page } from '../../types'

import HeadersRow from './HeadersRow'
import UserRow from './UserRow'
import { Outer, List, EmptyState } from './styled'

const Orientation: Page = () => {
  const { data } = useListAssistantsInOrientationQuery({
    variables: {
      first: 50,
      categories: [
        UserCategory.TEAM,
        UserCategory.SANDBOX,
        UserCategory.ASSISTANT,
      ],
    },
  })

  const allUsers = [...(data?.list.items || [])]
    .filter(isAssistantable)
    .sort((a, b) =>
      (a.profile.displayName || '') < (b.profile.displayName || '') ? -1 : 1,
    )

  return (
    <Outer>
      <PageHeaderOuter>
        <PageHeader>{'Orientation'}</PageHeader>
      </PageHeaderOuter>
      <List>
        <HeadersRow />
        {!allUsers.length && (
          <EmptyState>
            {'No orientation this week 🌴. Let’s hire more awesome people! 💪'}
          </EmptyState>
        )}
        {allUsers.map((user) => (
          <UserRow key={user.id} user={user} />
        ))}
      </List>
    </Outer>
  )
}

Orientation.path = '/orientation'
Orientation.navTitle = 'Orientation'
Orientation.Icon = Icon
Orientation.requiredOperationIds = ['Query.usersList']

export default Orientation
