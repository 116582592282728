import { colors, typography } from '@atlaskit/theme'
import { DateTime } from 'luxon'
import React, { ComponentProps, useMemo } from 'react'
import styled from 'styled-components'

type Props = Omit<ComponentProps<'time'>, 'dateTime'> & {
  dateTime: Date | string | null | undefined
  noTime?: boolean
  format?: Intl.DateTimeFormatOptions
}

const DateFormater = ({ dateTime, noTime, format, ...props }: Props) => {
  const formats = useMemo(() => {
    if (!dateTime) {
      return null
    }

    const date = new Date(dateTime)
    const dt = DateTime.fromJSDate(date)
    return {
      machine: dt.toISO(),
      short: dt.toLocaleString(
        format || {
          day: '2-digit',
          month: 'short',
          year:
            date.getFullYear() !== new Date().getFullYear()
              ? 'numeric'
              : undefined,
          ...(noTime
            ? null
            : {
                hour: '2-digit',
                minute: '2-digit',
              }),
        },
      ),
      long: dt.toLocaleString({
        weekday: 'long',
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }),
    }
  }, [dateTime, noTime, format])

  if (!formats) {
    return null
  }

  return (
    <time title={formats.long} dateTime={formats.machine} {...props}>
      {formats.short}
    </time>
  )
}

export default DateFormater

export const SubtleDate = styled(DateFormater)`
  ${typography.h300};
  color: ${colors.subtleText};
`
