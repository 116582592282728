import React from 'react'
import styled from 'styled-components'

import EmptyState from '../../../../components/EmptyState'
import { LoadingSpinner } from '../../../../components/Spinner'
import { useListAutomationsQuery } from '../../../../graphql'
import _Item from '../../Automations/List/Item'
import { Outer } from '../components/styled'

const Item = styled(_Item)`
  & + & > div {
    margin-top: 8px;
  }
`

type AutomationsProps = {
  templateId: string
}

const Automations = ({ templateId }: AutomationsProps) => {
  const { data, loading } = useListAutomationsQuery({
    variables: {
      messageTemplateIds: [templateId],
    },
  })

  return (
    <Outer>
      {loading ? (
        <LoadingSpinner show />
      ) : data?.list.items.length ? (
        data?.list.items.map((automation) => (
          <Item key={automation.id} automation={automation} withDescription />
        ))
      ) : (
        <EmptyState
          emoji={'🤖'}
          header={'No Autopilot automation is using this message'}
          description={
            'It might still be used by automated actions outside of Autopilot though, so handle with care, as always 🙏'
          }
        />
      )}
    </Outer>
  )
}

export default Automations
