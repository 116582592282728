import AkTextField from '@atlaskit/textfield'
import debounce from 'lodash/debounce'
import React, {
  ComponentProps,
  useCallback,
  forwardRef,
  useState,
  useRef,
  useEffect,
} from 'react'

type TextFieldProps = Partial<ComponentProps<typeof AkTextField>> & {
  onChangeValue?: (value: string) => void
}

export const TextField = forwardRef(function TextField(
  { onChangeValue, onChange, ...props }: TextFieldProps,
  ref,
) {
  const onFieldChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      onChangeValue?.(event.currentTarget.value)
      onChange?.(event)
    },
    [onChange, onChangeValue],
  )

  return <AkTextField ref={ref} onChange={onFieldChange} {...props} />
})

type DebouncedTextFieldProps = TextFieldProps & {
  debounceMs: number
}

export const DebouncedTextField = ({
  debounceMs,
  value: _value,
  onChangeValue: _onChangeValue,
  isInvalid,
  ...props
}: DebouncedTextFieldProps) => {
  const [value, setValue] = useState(_value)
  const localUpdate = useRef<boolean>(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateExternalValue = useCallback(
    debounce((newValue: string) => {
      localUpdate.current = false
      _onChangeValue?.(newValue)
    }, debounceMs),
    [_onChangeValue, localUpdate],
  )

  const updateValue = useCallback(
    (newValue: string) => {
      localUpdate.current = true
      setValue(newValue)
      updateExternalValue(newValue)
    },
    [setValue, updateExternalValue, localUpdate],
  )

  useEffect(() => {
    if (!localUpdate.current) {
      setValue(_value)
    }
  }, [_value, setValue, localUpdate])

  return (
    <TextField
      value={value}
      onChangeValue={updateValue}
      isInvalid={isInvalid || _value !== value}
      {...props}
    />
  )
}

export default TextField
