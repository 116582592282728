import { useCallback, useMemo } from 'react'

import { EmailLink, EmailMessageContainer, EmailText } from './styled'

type Props = {
  rawText: string
}

// Regular expression to match bold text between asterisks
const BOLD_REGEX = /\*(.*?)\*/g

// Regular expression to match links
const LINK_REGEX = /<([^<>]+)>/g

const StyledEmailMessage = ({ rawText }: Props) => {
  // Function to render bold text and links
  const renderFormattedText = useCallback((text: string) => {
    return text.split(BOLD_REGEX).map((part, index) => {
      if (index % 2 === 0) {
        // Normal text
        return part.split(LINK_REGEX).map((innerPart, innerIndex) => {
          if (innerIndex % 2 === 0) {
            return <span key={innerIndex}>{innerPart}</span>
          } else {
            // Link
            return (
              <EmailLink
                key={innerIndex}
                href={innerPart}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                {innerPart}
              </EmailLink>
            )
          }
        })
      } else {
        // Bold text
        return <strong key={index}>{part}</strong>
      }
    })
  }, [])

  // Filter out lines starting with '>'
  const filteredText = useMemo(() => {
    return rawText
      .split('\n')
      .filter((line) => !line.startsWith('>')) // Filter out quoted lines
      .join('\n')
  }, [rawText])

  // Parse and render the email content
  const content = useMemo(() => {
    return filteredText
      .split('\n\n')
      .map((paragraph, index) => (
        <EmailText key={index}>{renderFormattedText(paragraph)}</EmailText>
      ))
  }, [filteredText, renderFormattedText])

  return (
    <EmailMessageContainer>
      {filteredText.trim() ? (
        content
      ) : (
        <EmailText>
          {'Previous messages filtered out from Cockpit and shown below'}
        </EmailText>
      )}
    </EmailMessageContainer>
  )
}

export default StyledEmailMessage
