import Button, { ButtonProps } from '@atlaskit/button'
import truncate from 'lodash/truncate'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { Matching_ForTransitionListItemFragment } from '../../../../../../../graphql'
import { Bundled, getBundledId } from '../../../../utils'

type Props = Omit<ButtonProps, 'href' | 'onClick'> & {
  matching: Bundled<Matching_ForTransitionListItemFragment>
  stageId: string
}

const StageButton = ({ matching, stageId, children, ...props }: Props) => {
  const navHistory = useHistory()

  const openTransitionStage = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation()
      navHistory.push(`/transitions/${getBundledId(matching)}/${stageId}`)
    },
    [stageId, matching, navHistory],
  )

  if (typeof children === 'string') {
    children = truncate(children, { length: 10 })
  }

  return (
    <Button onClick={openTransitionStage} spacing={'compact'} {...props}>
      {children}
    </Button>
  )
}

export default StageButton
