import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Avatar from '../../../../../../components/users/Avatar'
import UserCell from '../../../../../../components/users/Cell'
import {
  Assistantable_ForTransitionFragment,
  Matching_ForTransitionListItemFragment,
} from '../../../../../../graphql'

const Outer = styled.span`
  display: flex;
  align-items: center;
`

type Props = {
  matching: Matching_ForTransitionListItemFragment
  assistant: Assistantable_ForTransitionFragment | null | undefined
  withName?: boolean
}

const AssistantManager = ({ assistant, withName }: Props) => {
  const manager = assistant?.dsm

  if (!manager) {
    return null
  }

  return (
    <Outer title={manager.profile.displayName || ''}>
      <Link to={`/users/${manager.id}`} target={'_blank'}>
        {withName ? <UserCell user={manager} /> : <Avatar user={manager} />}
      </Link>
    </Outer>
  )
}

type TransitioningAssistantManagerProps = Omit<Props, 'assistant'>

export const TransitioningAssistantManager = ({
  matching,
  ...props
}: TransitioningAssistantManagerProps) => (
  <AssistantManager
    matching={matching}
    assistant={matching.transition?.fromAssistant}
    {...props}
  />
)

type EnteringAssistantManagerProps = Omit<Props, 'assistant'>

export const EnteringAssistantManager = ({
  matching,
  ...props
}: EnteringAssistantManagerProps) => (
  <AssistantManager
    matching={matching}
    assistant={matching.confirmedAssistant}
    {...props}
  />
)

// export default Manager
