import { CheckboxSelect, RadioSelect } from '@atlaskit/select'
import memoize from 'lodash/memoize'
import React, { ComponentProps, useCallback, useMemo } from 'react'

const TIME_ZONES: Record<string, string> = {
  'America/Los_Angeles': 'PDT',
  'America/Phoenix': '',
  'America/Denver': 'MDT',
  'America/Chicago': 'CDT',
  'America/New_York': 'EDT',
  'Europe/London': 'BST',
  'Europe/Paris': 'CEST',
  'Europe/Brussels': 'CEST',
}

const ALIASES: Record<string, keyof typeof TIME_ZONES> = {
  'US + Canada / EST': 'America/New_York',
  'US + Canada / PST': 'America/Los_Angeles',
  'US + Canada / CT': 'America/Chicago',
  'US + Canada / MT': 'America/Denver',
  'EST (US + Canada)': 'America/New_York',
  'PST (US + Canada)': 'America/Los_Angeles',
  'CT (US + Canada)': 'America/Chicago',
  'MT (US + Canada)': 'America/Denver',
  'CET (France)': 'Europe/Paris',
  France: 'Europe/Paris',
  'France / CET': 'Europe/Paris',
  Germany: 'Europe/Paris',
  Belgium: 'Europe/Brussels',
  'Europe/Berlin': 'Europe/Berlin',
  Italy: 'Europe/Paris',
  'United Kingdom': 'Europe/London',
  'New York City, NY': 'America/New_York',
  Netherlands: 'Europe/Paris',
}

export const normalizeTimeZone = memoize(function (
  tz: string | null | undefined,
  strict = true,
): string | null {
  if (!tz) {
    return null
  }

  if (TIME_ZONES[tz]) {
    return tz
  }

  return ALIASES[tz] || (strict ? null : tz) || null
})

const toOption = memoize((tz: string) => {
  tz = normalizeTimeZone(tz, false)!

  return {
    value: tz,
    label: tz + (TIME_ZONES[tz] ? ` (${TIME_ZONES[tz]})` : ''),
  }
})

const fromOption = ({ value }: { value: string }) => value

const OPTIONS = Object.keys(TIME_ZONES).map(toOption)

type Props = Omit<ComponentProps<typeof CheckboxSelect>, 'options'> & {
  value?: string | string[] | null
  onChangeValue?: (newValue: string | string[] | undefined | null) => void
}

const TimeZoneSelect = ({
  value: _value,
  onChangeValue,
  isMulti,
  onChange: _onChange,
  ...selectProps
}: Props) => {
  const value = useMemo(() => {
    if (Array.isArray(_value)) {
      return _value.map(toOption)
    } else if (_value) {
      return toOption(_value)
    } else {
      return isMulti ? [] : undefined
    }
  }, [_value, isMulti])

  const onChange = useCallback(
    (newValue) => {
      _onChange?.(newValue)

      if (onChangeValue) {
        if (Array.isArray(newValue)) {
          // @ts-ignore
          onChangeValue(newValue.map(fromOption))
        } else if (newValue) {
          // @ts-ignore
          onChangeValue(fromOption(newValue))
        } else {
          onChangeValue(isMulti ? [] : undefined)
        }
      }
    },
    [_onChange, isMulti, onChangeValue],
  )

  const Select = isMulti ? CheckboxSelect : RadioSelect

  return (
    <Select
      options={OPTIONS}
      isMulti={isMulti}
      // @ts-ignore
      value={value}
      onChange={onChange}
      {...selectProps}
    />
  )
}

export default TimeZoneSelect
