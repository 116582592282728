import { useApolloClient } from '@apollo/client'
import Button from '@atlaskit/button'
import OpenIcon from '@atlaskit/icon/glyph/shortcut'
import { colors } from '@atlaskit/theme'
import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'

import {
  ListAirtableLeadsDocument,
  ListAirtableLeadsQuery,
  ListAirtableLeadsQueryVariables,
  AirtableLeadFragment,
} from '../graphql'

import AsyncSelectWithDebounce, {
  SelectProps,
  ValueType,
} from './AsyncSelectWithDebounce'

const Outer = styled.div`
  display: flex;
  align-items: center;

  & > *:first-child {
    flex: 1;
    margin-right: 8px;
  }
`

const Option = styled.div<{ isFocused: boolean }>`
  padding: 6px 12px;

  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: ${colors.backgroundHover};
    `}

  strong {
    font-size: 14px;
    font-weight: 400;
  }

  span {
    font-size: 9px;
    font-weight: 500;
    color: ${colors.subtleText};
    text-transform: uppercase;
  }
`

function notFalsy<T>(v: T | undefined | null | false | '' | 0): v is T {
  return !!v
}

type Props = SelectProps<AirtableLeadFragment> & {
  inNextSteps?: string[]
  isClient?: boolean
  valueId?: AirtableLeadFragment['id'] | null
  // value?: AirtableLeadFragment | null,
  onChangeValue?: (newValue: AirtableLeadFragment | null) => void
  onChangeValueId?: (newValue: AirtableLeadFragment['id'] | null) => void
}

const AirtableLeadSelect = ({
  valueId,
  value,
  onChangeValue,
  onChangeValueId,
  inNextSteps,
  isClient,
  ...props
}: Props) => {
  const client = useApolloClient()

  const loadOptions = useCallback(
    (inputValue: string): Promise<AirtableLeadFragment[]> => {
      return new Promise(async (resolve) => {
        try {
          const { data } = await client.query<
            ListAirtableLeadsQuery,
            ListAirtableLeadsQueryVariables
          >({
            query: ListAirtableLeadsDocument,
            variables: {
              nameContains: inputValue || null,
              inNextSteps: inputValue ? null : inNextSteps,
              isClient,
            },
          })
          resolve(data.list.items.filter(notFalsy))
        } catch (error) {
          resolve([])
        }
      })
    },
    [client, inNextSteps, isClient],
  )

  const onSelect = useCallback(
    (newValue: ValueType<AirtableLeadFragment>) => {
      onChangeValueId?.(newValue?.id || null)
      onChangeValue?.(newValue || null)
    },
    [onChangeValue, onChangeValueId],
  )

  return (
    <Outer>
      <AsyncSelectWithDebounce<AirtableLeadFragment>
        minimumInputLength={3}
        defaultOptions
        loadOptions={loadOptions}
        getOptionValue={(lead) => lead.id}
        placeholder={'Search Airtable lead by name'}
        onChange={onSelect}
        noOptionsMessage={() => 'No match found'}
        components={{
          Option({ innerRef, data: lead, innerProps, isFocused }) {
            return (
              <Option ref={innerRef} {...innerProps} isFocused={isFocused}>
                <strong>{lead.name}</strong>
                <br />
                <span>
                  {'Next step: '}
                  {lead.nextStep}
                </span>
              </Option>
            )
          },
          SingleValue({ innerProps, data: lead }) {
            return <span {...innerProps}>{lead.name}</span>
          },
        }}
        {...props}
      />
      {!!(value || valueId) && (
        <Button
          href={`https://airtable.com/appr49XC9TIVZoflS/tblOzxDMT8Fguk2iq/${
            value?.id || valueId
          }`}
          target={'_blank'}
          iconBefore={<OpenIcon label={''} />}
          appearance={'subtle'}
        />
      )}
    </Outer>
  )
}

export default AirtableLeadSelect
