import { LoadingButton as Button, ButtonGroup } from '@atlaskit/button'
import { CodeBlock } from '@atlaskit/code'
import { colors, elevation, borderRadius, layers } from '@atlaskit/theme'
import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip'
import { transparentize } from 'polished'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { LoadingSpinner } from '../../../../components/Spinner'
import { Field, TextField, FieldsRow } from '../../../../components/form'
import {
  useUpsertPricingMutation,
  UpsertPricingInput,
  useListQueryCache,
  ListPricingsQuery,
  ListPricingsDocument,
  PricingModel,
  PricingFragment,
} from '../../../../graphql'
import useValues from '../../../../lib/useValues'
import { areEquivalentObjects } from '../../../../lib/utils'
import {
  isNewPricing,
  isHourlyPricing,
  isOriginalPricing,
  getPricingFormulaString,
  FORMULA_LEGEND,
} from '../utils'

import ModelSelect from './ModelSelect'

const mayParseInt = (
  value: string | null | undefined,
): number | null | undefined => {
  if (typeof value !== 'string') {
    return value
  }
  const int = parseInt(value)
  if (isNaN(int)) {
    return null
  }
  return int
}

const Outer = styled.div`
  padding: 8px 0;
  flex: 1;
  margin-bottom: 24px;
`

const Buttons = styled.div`
  padding: 16px 4px;
  position: sticky;
  bottom: 0;
  background-color: ${transparentize(0.2, 'white')};
`

const TooltipInner = styled(TooltipPrimitive)`
  white-space: pre;
  ${elevation.e200()};
  border-radius: ${borderRadius}px;
  z-index: ${layers.tooltip()};
  background-color: ${colors.heading};
  color: white;
  padding: 4px 8px;
  font-size: 12px;
`

const DOLLAR_SIGN = <span style={{ paddingLeft: 8 }}>{'$'}</span>

interface ConfigurationProps {
  pricing: PricingFragment
  isEditable?: boolean
}

const Model = ({ pricing: defaultPricing, isEditable }: ConfigurationProps) => {
  const navHistory = useHistory()
  const [values, { reset, patch }] = useValues<
    Omit<UpsertPricingInput, 'pricingId'>
  >({}, ['model'])
  const [upsertPricing, { loading }] = useUpsertPricingMutation()
  const { removeItem: removePricing } = useListQueryCache<
    ListPricingsQuery,
    PricingFragment
  >(ListPricingsDocument)

  const cancelEdit = useCallback(() => {
    if (isNewPricing(defaultPricing)) {
      // cancel creation of new pricing
      removePricing(defaultPricing)
      navHistory.replace('/pricings')
    } else {
      reset()
    }
  }, [defaultPricing, navHistory, removePricing, reset])

  const savePricing = useCallback(async () => {
    try {
      await upsertPricing({
        variables: {
          input: {
            pricingId: defaultPricing.id,
            ...values,
          },
        },
      })

      reset()
    } catch (error) {
      console.error(error)
    }
  }, [upsertPricing, defaultPricing.id, values, reset])

  const pricing: PricingFragment = {
    ...defaultPricing,
    ...(values as PricingFragment),
  }

  const setModel = useCallback(
    (model: PricingModel) => patch({ model, modelConfig: {} }),
    [patch],
  )
  const patchModelConfig = useCallback(
    (configPatch: Partial<PricingFragment['modelConfig']>) => {
      patch({
        modelConfig: {
          ...pricing.modelConfig,
          ...configPatch,
        },
      })
    },
    [patch, pricing.modelConfig],
  )

  return (
    <Outer>
      <Field label={'Model'} isRequired>
        <ModelSelect
          value={pricing.model || null}
          // @ts-ignore
          onChangeValue={setModel}
          isReadOnly={!isEditable}
          isDisabled={!isEditable}
        />
      </Field>

      {isHourlyPricing(pricing) ? (
        <>
          <Field
            label={'Hourly Price'}
            isRequired
            helperMessage={
              pricing.modelConfig.baseHours
                ? `Billed for every hour beyond the included ${pricing.modelConfig.baseHours} hours`
                : undefined
            }
          >
            <TextField
              value={pricing.modelConfig.hourlyPrice}
              type={'number'}
              min={0}
              step={1}
              placeholder={'0'}
              isReadOnly={!isEditable}
              elemBeforeInput={DOLLAR_SIGN}
              isInvalid={!pricing.modelConfig.hourlyPrice}
              onChangeValue={(hourlyPrice) =>
                patchModelConfig({ hourlyPrice: mayParseInt(hourlyPrice) })
              }
            />
          </Field>
          <FieldsRow>
            <Field label={'Base Price'} helperMessage={''}>
              <TextField
                value={pricing.modelConfig.basePrice}
                type={'number'}
                min={0}
                step={1}
                placeholder={'0'}
                isReadOnly={!isEditable}
                isDisabled={!!pricing.modelConfig.minPrice}
                elemBeforeInput={DOLLAR_SIGN}
                onChangeValue={(basePrice) =>
                  patchModelConfig({ basePrice: mayParseInt(basePrice) })
                }
              />
            </Field>
            <Field
              label={'Hours Included'}
              helperMessage={'Hours included with base price'}
              isRequired={!!pricing.modelConfig.basePrice}
            >
              <TextField
                value={pricing.modelConfig.baseHours}
                type={'number'}
                min={0}
                step={1}
                placeholder={'0'}
                isReadOnly={!isEditable}
                isDisabled={!pricing.modelConfig.basePrice}
                isInvalid={
                  !!pricing.modelConfig.basePrice &&
                  !pricing.modelConfig.baseHours
                }
                onChangeValue={(baseHours) =>
                  patchModelConfig({ baseHours: mayParseInt(baseHours) })
                }
              />
            </Field>
          </FieldsRow>
          <Field
            label={'Minimum Price'}
            helperMessage={
              'Minimum amount paid no matter how many hours are used'
            }
          >
            <TextField
              value={pricing.modelConfig.minPrice}
              type={'number'}
              min={0}
              step={1}
              placeholder={'0'}
              isReadOnly={!isEditable}
              isDisabled={!!pricing.modelConfig.basePrice}
              elemBeforeInput={DOLLAR_SIGN}
              onChangeValue={(minPrice) =>
                patchModelConfig({ minPrice: mayParseInt(minPrice) })
              }
            />
          </Field>
          <FieldsRow>
            <Field
              label={'Grace Period (in months)'}
              helperMessage={'Simple hourly price is applied'}
            >
              <TextField
                value={pricing.modelConfig.graceMonths}
                type={'number'}
                min={0}
                step={1}
                placeholder={'0'}
                isReadOnly={!isEditable}
                onChangeValue={(graceMonths) =>
                  patchModelConfig({ graceMonths: mayParseInt(graceMonths) })
                }
              />
            </Field>
            <Field
              label={'Hourly Price During Grace Period'}
              isRequired={!!pricing.modelConfig.basePrice}
            >
              <TextField
                value={pricing.modelConfig.graceHourlyPrice}
                type={'number'}
                min={0}
                step={1}
                placeholder={'0'}
                isReadOnly={!isEditable}
                elemBeforeInput={DOLLAR_SIGN}
                isDisabled={!pricing.modelConfig.graceMonths}
                isInvalid={
                  !!pricing.modelConfig.graceMonths &&
                  !pricing.modelConfig.graceHourlyPrice
                }
                onChangeValue={(graceHourlyPrice) =>
                  patchModelConfig({
                    graceHourlyPrice: mayParseInt(graceHourlyPrice),
                  })
                }
              />
            </Field>
          </FieldsRow>
        </>
      ) : isOriginalPricing(pricing) ? (
        <>
          <Field
            label={'Minimum Price'}
            helperMessage={
              'Minimum amount paid no matter how many hours are used'
            }
          >
            <TextField
              value={pricing.modelConfig.minPrice}
              type={'number'}
              min={0}
              step={1}
              placeholder={'0'}
              isReadOnly={!isEditable}
              elemBeforeInput={DOLLAR_SIGN}
              onChangeValue={(minPrice) =>
                patchModelConfig({ minPrice: mayParseInt(minPrice) })
              }
            />
          </Field>
        </>
      ) : null}

      <Field label={'Final Formula'}>
        <Tooltip
          content={FORMULA_LEGEND}
          position={'top-end'}
          component={TooltipInner}
        >
          <CodeBlock
            showLineNumbers={false}
            text={getPricingFormulaString(pricing, false)}
          />
        </Tooltip>
      </Field>

      {!areEquivalentObjects(pricing, defaultPricing) && (
        <Buttons>
          <ButtonGroup>
            <Button appearance={'primary'} onClick={savePricing}>
              {'Save'}
            </Button>
            <Button onClick={cancelEdit}>{'Cancel'}</Button>
          </ButtonGroup>
        </Buttons>
      )}

      <LoadingSpinner show={loading} />
    </Outer>
  )
}

export default Model
