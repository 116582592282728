import Icon from '@atlaskit/icon/glyph/marketplace'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { ErrorEmptyState } from '../../../components/EmptyState'
import { LoadingSpinner } from '../../../components/Spinner'
import { useListPricingsQuery } from '../../../graphql'
import { Page } from '../../types'

import List from './List'
import Pricing from './Pricing'

const Outer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  height: 100%;
  min-width: 600px;
  max-width: 1000px;
`

const Pricings: Page = () => {
  const { data, loading, error } = useListPricingsQuery()
  const { url } = useRouteMatch()
  const {
    params: { pricingId },
  } = useRouteMatch<{ pricingId: string }>(`${url}/:pricingId`) || {
    params: {},
  }

  if (error) {
    return <ErrorEmptyState error={error} />
  }

  if (loading || !data) {
    return <LoadingSpinner />
  }

  return (
    <Outer>
      <List pricings={data.list.items} selectedPricingId={pricingId} />
      <Pricing pricingId={pricingId} />
    </Outer>
  )
}

Pricings.path = '/pricings'
Pricings.navTitle = 'Pricing Packages'
Pricings.Icon = Icon
Pricings.requiredOperationIds = ['Query.pricingsList']

export default Pricings
