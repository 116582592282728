import { colors, typography } from '@atlaskit/theme'
import styled, { css } from 'styled-components'

export const Row = styled.div`
  display: flex;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 150px;

  & + & {
    margin-left: 16px;
  }
`

export const StepDetail = styled(Column)`
  justify-content: flex-start;

  > * {
    margin-bottom: 8px;
  }
`

export const Steps = styled.div`
  max-width: 260px;
  min-width: 220px;
  color: ${colors.text};
  box-shadow: inset -2px 0 0 ${colors.backgroundHover};

  & + ${Column} {
    margin-left: 16px;
  }
`

export const Step = styled.div<{ selected: boolean }>`
  display: flex;
  padding: 4px 8px;
  justify-content: space-between;
  align-items: center;
  ${typography.h200}
  margin-top: 0;
  min-height: 33px;
  cursor: pointer;
  border-right: 2px solid ${colors.backgroundHover};
  white-space: pre;

  ${(props) =>
    props.selected
      ? css`
          color: ${colors.textActive};
          border-right-color: ${colors.primary};
          background: ${colors.backgroundActive};
        `
      : css`
          &:hover {
            color: ${colors.textActive};
            background: ${colors.backgroundHover};
          }
        `}
`
