import Lozenge from '@atlaskit/lozenge'
import { DateTime } from 'luxon'

import { DataContainer } from './styled'

type Props = {
  date?: Date | string | null
  callDuration: number
}

const CallAtValue = ({ date, callDuration }: Props) => {
  if (!date) {
    return <Lozenge>{'Not Scheduled'}</Lozenge>
  }
  const atDateTimeFormatted = DateTime.fromJSDate(new Date(date))
  const endOfCall = atDateTimeFormatted
    .plus({
      minutes: callDuration,
    })
    .toRelative()

  return (
    <DataContainer>
      <Lozenge appearance={endOfCall?.includes('ago') ? 'success' : undefined}>
        {endOfCall}
      </Lozenge>
      <Lozenge isBold>
        {atDateTimeFormatted.toLocaleString({
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          timeZone: 'America/New_York',
        })}
      </Lozenge>
    </DataContainer>
  )
}

export default CallAtValue
