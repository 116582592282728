import Lozenge from '@atlaskit/lozenge'
import { DateTime } from 'luxon'

import UserAvatar from '../../../../../../../components/users/Avatar'
import {
  AssistantPerformanceMetricKind,
  AssistantUser_PerformanceScoreTagsFragment,
  Maybe,
  Userable_MinimalFragment,
} from '../../../../../../../graphql'

import { Outer, RowItem } from './styled'

type Props = {
  metricKind: string
  executive?: Maybe<Userable_MinimalFragment>
  tags: AssistantUser_PerformanceScoreTagsFragment[]
  date: string
}

const MetricItem = ({ executive, tags, date, metricKind }: Props) => {
  const showExecutive =
    metricKind !== AssistantPerformanceMetricKind.HQ_FEEDBACK
  return (
    <Outer>
      <RowItem>
        {DateTime.fromISO(date, {
          zone: 'America/New_York',
        }).toLocaleString()}
      </RowItem>
      {showExecutive && (
        <RowItem>
          <UserAvatar user={executive || undefined} size={'small'} />{' '}
          {executive?.profile.displayName || 'Executive'}
        </RowItem>
      )}
      <RowItem>
        {tags?.map((tag) => (
          <Lozenge
            key={tag.id}
            appearance={
              tag.sentiment === 'POSITIVE'
                ? 'success'
                : tag.sentiment === 'NEGATIVE'
                ? 'removed'
                : 'default'
            }
          >
            {tag.text}
          </Lozenge>
        ))}
      </RowItem>
    </Outer>
  )
}

export default MetricItem
