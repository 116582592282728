import { RadioGroup } from '@atlaskit/radio'
import React, { useCallback, useState } from 'react'

import Modal, { Props as ModalProps } from '../../../../../components/Modal'
import { Field, TextArea } from '../../../../../components/form'
import {
  MatchingProposalReply,
  MatchingProposal_FullFragment,
  useUpsertMatchingProposalMutation,
} from '../../../../../graphql'

type Props = ModalProps & {
  matchingId: string
  proposal: MatchingProposal_FullFragment
}

const ReplyModal = ({ matchingId, proposal, onClose, ...props }: Props) => {
  const [reply, setReply] = useState(proposal.reply || undefined)
  const [comment, setComment] = useState(proposal.replyComment || undefined)
  const [upsert, upsertMutation] = useUpsertMatchingProposalMutation()

  const updateReply = useCallback(() => {
    if (reply) {
      upsert({
        variables: {
          input: {
            matchingId,
            assistantId: proposal.assistant.id,
            reply,
            replyComment: comment || undefined,
          },
        },
        onCompleted: () => {
          onClose?.()
        },
      })
    }
  }, [upsert, reply, comment, proposal, matchingId, onClose])

  const isLoading = upsertMutation.loading

  return (
    <Modal
      heading={`Reply to proposal for ${proposal.assistant.profile.displayName}`}
      width={'small'}
      actions={[
        {
          text: 'Reply',
          onClick: updateReply,
          isDisabled: isLoading || !reply,
          isLoading,
        },
        {
          text: 'Cancel',
          onClick: onClose,
          isDisabled: isLoading,
        },
      ]}
      onCloseComplete={() => {
        setReply(proposal.reply || undefined)
        setComment(proposal.replyComment || undefined)
      }}
      onClose={onClose}
      {...props}
    >
      <Field label={'Reply'} isRequired>
        <RadioGroup
          value={reply}
          onChange={({ target }) =>
            setReply(target.value as MatchingProposalReply)
          }
          options={[
            {
              label: 'Accept',
              value: MatchingProposalReply.YES,
            },
            {
              label: 'Need more info',
              value: MatchingProposalReply.NEED_MORE_INFO,
            },
            {
              label: 'Decline',
              value: MatchingProposalReply.NO,
            },
          ]}
        />
      </Field>

      <Field label={'Comment'}>
        <TextArea value={comment} onChangeValue={setComment} minimumRows={5} />
      </Field>
    </Modal>
  )
}

export default ReplyModal
