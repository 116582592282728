import { LoadingButton, LoadingButtonProps } from '@atlaskit/button'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

import { ReactComponent as _Icon } from './Slack.svg'

export const COMMUNITY_TEAM_ID = 'TNARQK3RD'

const Icon = styled(_Icon)`
  width: 20px;
  height: 20px;
`

const SlackButton = (props: LoadingButtonProps) => (
  <LoadingButton appearance={'subtle'} iconBefore={<Icon />} {...props} />
)

type SlackLinkButtonProps = ComponentProps<typeof SlackButton> & {
  teamId: string
  userId?: string | null | undefined
  channelId?: string | null | undefined
}

export const SlackLinkButton = ({
  teamId,
  userId,
  channelId,
  ...props
}: SlackLinkButtonProps) => (
  <SlackButton
    target={'_blank'}
    href={
      // https://api.slack.com/reference/deep-linking#client
      `slack://${
        userId ? 'user' : channelId ? 'channel' : 'open'
      }?team=${teamId}` +
      (userId || channelId ? `&id=${userId || channelId}` : '')
    }
    {...props}
  />
)

export default SlackButton
