import Select from '@atlaskit/select'
import React, { useCallback } from 'react'

import { useGetUserFieldsOptionsQuery } from '../../graphql'

export type SearchTerms = {
  name?: string
  skill?: string
}

type Props = {
  value: SearchTerms
  onChange: (newTerms: SearchTerms) => void
}

const SearchField = ({ value: { name, skill }, onChange }: Props) => {
  const { data } = useGetUserFieldsOptionsQuery()

  const isSkill = useCallback(
    (term?: string): term is string =>
      !!(term && data?.userFieldsOptions.assistantSkills.includes(term)),
    [data],
  )
  const submitChange = useCallback(
    (term?: null | string | { value?: string }) => {
      if (term && typeof term !== 'string') {
        term = term.value
      }

      if (!term) {
        onChange({})
      } else if (isSkill(term)) {
        onChange({ skill: term })
      } else {
        onChange({ name: term })
      }
    },
    [onChange, isSkill],
  )

  const onInputChange = useCallback(
    (text: string | null | undefined, { action }: { action: string }) => {
      if (action === 'input-change') {
        submitChange(text)
      }
    },
    [submitChange],
  )

  const options = [...(data?.userFieldsOptions.assistantSkills || [])]
  if (name) {
    options.unshift(name)
  }

  return (
    <Select
      placeholder={'Name or Ask Me About'}
      value={
        name || skill
          ? // @ts-ignore
            { value: name || skill, label: name || skill }
          : undefined
      }
      isClearable
      options={options.map((value) => ({ value }))}
      createOptionPosition={'first'}
      // @ts-ignore
      getOptionLabel={({ value, label }) =>
        label || (isSkill(value) ? value : `Search for "${value}"`)
      }
      // onCreateOption={submitChange}
      // @ts-ignore
      onChange={submitChange}
      onInputChange={onInputChange}
    />
  )
}

export default SearchField
