import AtlaskitModal, { ModalTransition } from '@atlaskit/modal-dialog'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

const Inner = styled.div`
  > * {
    position: relative;
  }
`

type AtlaskitProps = ComponentProps<typeof AtlaskitModal>

export type Props = Omit<
  AtlaskitProps,
  'autoFocus' | 'scrollBehavior' | 'onClose'
> &
  Partial<Pick<AtlaskitProps, 'autoFocus' | 'scrollBehavior'>> & {
    isOpen?: boolean
    onClose?: () => unknown
  }

const Modal = ({ isOpen, children, ...props }: Props) => (
  <ModalTransition>
    {!!isOpen && (
      <AtlaskitModal {...props}>
        <Inner children={children} />
      </AtlaskitModal>
    )}
  </ModalTransition>
)

export default Modal
