import Button from '@atlaskit/button'
import React, { useCallback, useState } from 'react'

import { TextArea } from '../../../components/form'
import {
  useDelegationCoachQuery,
  useUpdateDelegationCoachSettingsMutation,
} from '../../../graphql'

import { PageOuter } from './styled'

const DelegationCoachSystemPrompt = () => {
  const { data, loading: queryLoading, error } = useDelegationCoachQuery()
  const [updateDelegationCoachSettings, { loading: mutationLoading }] =
    useUpdateDelegationCoachSettingsMutation()

  const [systemPromptState, setSystemPropmtState] = useState<null | string>(
    null,
  )

  const onSave = useCallback(() => {
    updateDelegationCoachSettings({
      variables: {
        input: {
          systemPrompt: systemPromptState,
        },
      },
      // TODO OptimisticResponse
    })
  }, [systemPromptState, updateDelegationCoachSettings])

  return (
    <PageOuter>
      <h1>{'🤖 Delegation Coach Prompt'}</h1>
      {error ? (
        <h3>{'Error loading prompt. Try again later.'}</h3>
      ) : queryLoading || !data ? (
        <h3>{'Loading prompt...'}</h3>
      ) : (
        <>
          <TextArea
            value={systemPromptState || data.delegationCoach.systemPrompt}
            onChangeValue={setSystemPropmtState}
            defaultValue={data.delegationCoach.systemPrompt}
            isDisabled={mutationLoading}
          />
          <Button
            onClick={onSave}
            appearance={'primary'}
            isDisabled={
              !systemPromptState ||
              systemPromptState === data.delegationCoach.systemPrompt
            }
          >
            {'Save'}
          </Button>
        </>
      )}
    </PageOuter>
  )
}

export default DelegationCoachSystemPrompt
