import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import MarkdownEditor from '../../../../components/MarkdownEditor'
import { Field, TextField, SaveButtons } from '../../../../components/form'
import {
  ListMessageTemplatesDocument,
  ListMessageTemplatesQuery,
  MessageTemplate_LabelsFragment,
  UpsertMessageTemplateInput,
  useListQueryCache,
  useUpsertMessageTemplateMutation,
} from '../../../../graphql'
import useValues from '../../../../lib/useValues'
import { areEquivalentObjects } from '../../../../lib/utils'
import { Outer } from '../components/styled'
import { isNewTemplate, KIND_BY_TYPENAME, NEW_TEMPLATE_TITLE } from '../utils'

import useMessageTemplate from './useMessageTemplate'

interface ConfigurationProps {
  templateId: string
}

const Configuration = ({ templateId }: ConfigurationProps) => {
  const navHistory = useHistory()
  const [rev, setRev] = useState(0)

  const { removeItem: evictLocalTemplate } = useListQueryCache<
    ListMessageTemplatesQuery,
    MessageTemplate_LabelsFragment
  >(ListMessageTemplatesDocument)

  const { messageTemplate: defaultTemplate, loading: loadingData } =
    useMessageTemplate(templateId)

  const [upsert, { loading: loadingUpsert }] =
    useUpsertMessageTemplateMutation()

  const [values, { setters, reset, isValid }] = useValues<
    Omit<UpsertMessageTemplateInput, 'id'>
  >({}, ['name', 'description'], {
    validate: (values) => {
      const { name, description } = { ...defaultTemplate, ...values }
      return Boolean(name?.trim() && description?.trim())
    },
  })

  const cancel = useCallback(() => {
    if (defaultTemplate && isNewTemplate(defaultTemplate)) {
      // cancel creation of new template
      evictLocalTemplate(defaultTemplate)
      navHistory.replace('/message-templates')
    } else {
      reset()
    }
    setRev((r) => r + 1)
  }, [defaultTemplate, reset, navHistory, evictLocalTemplate])

  const save = useCallback(() => {
    if (isValid) {
      upsert({
        variables: {
          input: {
            id: templateId,
            kind: KIND_BY_TYPENAME[defaultTemplate?.__typename!],
            // Create default language
            languages: isNewTemplate(defaultTemplate)
              ? [
                  {
                    languageCode: 'en',
                    fields: {},
                  },
                ]
              : [],
            ...values,
          },
        },
        onCompleted: () => setRev((r) => r + 1),
      })
    }
  }, [values, upsert, templateId, isValid, defaultTemplate, setRev])

  const template = {
    ...defaultTemplate,
    ...values,
  }

  const loading = loadingData || loadingUpsert

  return (
    <Outer>
      <Field label={'Name'} isRequired>
        <TextField
          appearance={'subtle'}
          value={
            (template.name !== NEW_TEMPLATE_TITLE && template.name) || undefined
          }
          onChangeValue={setters.name}
          isInvalid={
            template.name === NEW_TEMPLATE_TITLE || !template.name?.trim()
          }
        />
      </Field>

      <Field label={'Description'} isRequired>
        <MarkdownEditor
          key={rev}
          appearance={'subtle'}
          value={template?.description || undefined}
          onChange={setters.description!}
          isInvalid={!template.description?.trim()}
        />
      </Field>

      {(isNewTemplate(defaultTemplate) ||
        !areEquivalentObjects(defaultTemplate, template)) && (
        <SaveButtons
          isDisabled={!isValid}
          isLoading={loading}
          onCancel={cancel}
          onSave={save}
        />
      )}
    </Outer>
  )
}

export default Configuration
