import Icon from '@atlaskit/icon/glyph/comment'
import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { Page } from '../../types'

import List from './List'
import Template from './Template'

const Outer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  min-width: 600px;
  max-width: 1000px;
  height: 100%;
`

const MessageTemplates: Page = () => {
  const { url } = useRouteMatch()
  const {
    params: { templateId },
  } = useRouteMatch<{ templateId: string }>(`${url}/:templateId`) || {
    params: {},
  }

  return (
    <Outer>
      <List />
      <Route path={`${url}/:templateId?`}>
        <Template templateId={templateId} />
      </Route>
    </Outer>
  )
}

MessageTemplates.path = '/message-templates'
MessageTemplates.navTitle = 'Templates'
MessageTemplates.Icon = Icon
MessageTemplates.requiredOperationIds = ['Query.messageTemplatesList']

export default MessageTemplates
