import { useState, useEffect, useCallback } from 'react'

const LOCAL_STORAGE_KEY = 'nav-shortcuts'

export interface Shortcut {
  title: string
  path: string
}

const useShortcuts = () => {
  const [shortcuts, setShortcuts] = useState<Shortcut[]>([])

  useEffect(() => {
    const stored = window.localStorage.getItem(LOCAL_STORAGE_KEY)
    if (stored) {
      setShortcuts(JSON.parse(stored))
    }
  }, [setShortcuts])

  const toggleShortcut = useCallback(
    (shortcut: Shortcut) => {
      setShortcuts((shortcuts) => {
        const count = shortcuts.length
        shortcuts = shortcuts.filter(({ path }) => path !== shortcut.path)
        if (shortcuts.length === count) {
          shortcuts.push(shortcut)
        }

        window.localStorage.setItem(
          LOCAL_STORAGE_KEY,
          JSON.stringify(shortcuts),
        )

        return shortcuts
      })
    },
    [setShortcuts],
  )

  return {
    shortcuts,
    toggleShortcut,
  }
}

export default useShortcuts
