import styled from 'styled-components'

export const Outer = styled.div`
  flex: 1;
  padding: 0 24px;
`

export const Inner = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Row = styled.div`
  width: 400px;
  display: flex;
  align-items: center;
  gap: 8px;
`
