import React from 'react'

import Badge, { Tint } from '../../../../../../components/Badge'
import DateFormater from '../../../../../../components/DateFormater'
import UserCell from '../../../../../../components/users/Cell'
import {
  Maybe,
  Userable_MinimalFragment,
  UserCategory,
} from '../../../../../../graphql'
import { NavLink } from '../../../../NavigationSidebar/styled'
import { Outer } from '../sharedStyles'

import WorkspaceUserStatusBadge from './WorkspaceUserStatusBadge'
import { Column, Row } from './styled'

type Props = {
  user: Userable_MinimalFragment
  startDate: string | Date
  endDate?: Maybe<string | Date>
  isCoverage?: boolean
}

const WorkspaceUserRow = ({ user, startDate, endDate, isCoverage }: Props) => (
  <Outer>
    <Row>
      <NavLink key={user.id} to={`/users/${user.id}`}>
        <UserCell user={user} />
      </NavLink>
      <Column>
        <div>
          {`Start: `}
          <DateFormater dateTime={startDate} noTime />
        </div>
        <div>
          {`End: `}
          <DateFormater dateTime={endDate} noTime />
        </div>
      </Column>
    </Row>
    <Row>
      {isCoverage && <Badge text={'TC'} tint={'yellow' as Tint} />}
      <WorkspaceUserStatusBadge
        isExecutive={user.category === UserCategory.EXECUTIVE}
        startDate={new Date(startDate)}
        endDate={endDate ? new Date(endDate) : undefined}
      />
    </Row>
  </Outer>
)

export default WorkspaceUserRow
