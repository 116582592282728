import { Label } from '@atlaskit/form'
import { useCallback, useState, useMemo } from 'react'

import Modal from '../../../../../components/Modal'
import Toggle from '../../../../../components/Toggle'
import { Select } from '../../../../../components/form'
import UserCell from '../../../../../components/users/Cell'
import { SingleUserSelect } from '../../../../../components/users/Select'
import {
  TimeTrackingEntryFragment,
  useGetUserNavigationQuery,
  useUpdateTimeTrackingEntryBillToMutation,
  TimeTrackingEntryTemplateFragment,
  useGetTimeTrackingEntryTemplatesListQuery,
} from '../../../../../graphql'
import useSwitch from '../../../../../lib/useSwitch'
import { DoubleIcon } from '../styled'

import { Column, Inner, Row, ToggleContainer } from './styled'

type Props = {
  onClose: () => void
  entry: TimeTrackingEntryFragment
}

const UpdateExecutiveModal = ({ onClose, entry }: Props) => {
  const assistantId = entry.user.id
  const currentExec = entry.workspace?.executives[0]
  const [isBillToDoubleState, , , _toggleBillToDouble] = useSwitch()
  const [templateTask, setTemplateTask] =
    useState<TimeTrackingEntryTemplateFragment | null>(null)
  const [updatedWorkspaceId, setUpdatedWorkspaceId] = useState<string | null>(
    null,
  )
  const [updateTimeEntry, { loading: updateTimeTrackingLoading }] =
    useUpdateTimeTrackingEntryBillToMutation()

  const { data: ttListData, loading: ttListLoading } =
    useGetTimeTrackingEntryTemplatesListQuery({
      variables: {
        isWorkspaced: !isBillToDoubleState,
      },
    })

  const { data: userData, loading: getUserDataLoading } =
    useGetUserNavigationQuery({
      variables: { id: assistantId },
      skip: !assistantId,
    })

  const executives = useMemo(
    () =>
      userData?.user.workspaces.filter(
        (workspace) =>
          !workspace.isPool &&
          workspace.executives[0].id !== currentExec?.id &&
          workspace.executives[0].category !== 'SANDBOX',
      ) || [],
    [currentExec?.id, userData?.user.workspaces],
  )

  const toggleBillToDouble = useCallback(() => {
    _toggleBillToDouble()
    setUpdatedWorkspaceId(null)
    setTemplateTask(null)
  }, [_toggleBillToDouble])

  const onUserSelect = useCallback(
    (user) => {
      //find the workspace id of the selected user
      const newWorkspaceId = userData?.user.workspaces.find(
        (workspace) => workspace.executives[0].id === user.id,
      )?.id
      if (!newWorkspaceId) return
      setUpdatedWorkspaceId(newWorkspaceId)
    },
    [userData?.user.workspaces],
  )

  const onSave = useCallback(() => {
    updateTimeEntry({
      variables: {
        input: {
          timeTrackingEntryId: entry.id,
          workspaceId: isBillToDoubleState ? null : updatedWorkspaceId,
          templateId: templateTask?.id,
          title: templateTask?.title,
        },
      },
      onCompleted: onClose,
    })
  }, [
    entry.id,
    isBillToDoubleState,
    onClose,
    templateTask?.id,
    templateTask?.title,
    updateTimeEntry,
    updatedWorkspaceId,
  ])

  const onTaskTemplateChange = useCallback(
    (selectedId) => {
      const selectedTemplate =
        ttListData?.timeTrackingEntryTemplatesList.items.find(
          (template) => template.id === selectedId,
        )
      if (!selectedTemplate) return
      setTemplateTask(selectedTemplate)
    },
    [ttListData?.timeTrackingEntryTemplatesList.items],
  )

  const options = useMemo(
    () =>
      ttListData?.timeTrackingEntryTemplatesList.items.map((template) => ({
        label: template.title,
        value: template.id,
      })) || [],
    [ttListData?.timeTrackingEntryTemplatesList.items],
  )

  const loading = getUserDataLoading || updateTimeTrackingLoading
  const showTemplateSelect = !currentExec || isBillToDoubleState
  const hasValidTemplateSelection = showTemplateSelect ? !!templateTask : true
  const hasValidExecSelection = isBillToDoubleState
    ? true
    : !!updatedWorkspaceId
  const isSubmitDisabled = useMemo(
    () => loading || !hasValidTemplateSelection || !hasValidExecSelection,
    [hasValidExecSelection, hasValidTemplateSelection, loading],
  )

  return (
    <Modal
      isOpen
      onClose={onClose}
      height={800}
      heading={'Update Executive on a Locked Entry'}
      actions={[
        {
          text: isBillToDoubleState ? 'Bill to Double' : 'Update executive',
          onClick: onSave,
          isDisabled: isSubmitDisabled,
        },
        { text: 'Cancel', onClick: onClose },
      ]}
    >
      <Inner>
        <h2>{'Entry'}</h2>

        <Row>
          <Column>
            {'Assistant'}
            <UserCell user={entry.user} />
          </Column>
          <Column>
            {'Executive'}
            {currentExec ? (
              <UserCell user={currentExec} />
            ) : (
              <Row>
                <DoubleIcon /> {'Bill to Double'}
              </Row>
            )}
          </Column>
          <Column>
            <p>{'Task'}</p>
            <p>{entry.title}</p>
          </Column>
        </Row>
        <h2>{'Update Bill To'}</h2>
        {currentExec && (
          <ToggleContainer>
            <Label htmlFor={'BillToDouble'}>{'Bill To Double'}</Label>
            <Toggle
              id={'BillToDouble'}
              label={'Bill To Double'}
              isChecked={isBillToDoubleState}
              isDisabled={loading}
              onChange={toggleBillToDouble}
            />
          </ToggleContainer>
        )}
        {!isBillToDoubleState && executives.length > 0 && (
          <SingleUserSelect
            onChange={onUserSelect}
            isLoading={loading}
            isDisabled={loading || isBillToDoubleState}
            defaultOptions={executives}
            placeholder={
              isBillToDoubleState
                ? 'Untoggle Bill to Double to Select an Exec'
                : 'Select Executive'
            }
          />
        )}

        {showTemplateSelect && (
          <Select
            isDisabled={ttListLoading}
            isLoading={ttListLoading}
            placeholder={
              isBillToDoubleState
                ? 'Select a Double Task Template'
                : 'Update the Task Template'
            }
            options={options}
            onChangeValue={onTaskTemplateChange}
          />
        )}
      </Inner>
    </Modal>
  )
}

export default UpdateExecutiveModal
