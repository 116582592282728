import React, { useState } from 'react'

import Modal from '../../../../../../components/Modal'
import { LoadingSpinner } from '../../../../../../components/Spinner'
import { Field } from '../../../../../../components/form'
import {
  MultiUserSelect,
  OptionType as UserOptionType,
} from '../../../../../../components/users/Select'
import {
  TimeTrackingCategoryId,
  TimeTrackingEntryTemplateId,
  UserCategory,
} from '../../../../../../graphql'
import useUpdateAssistantCopilot, {
  CopilotPromptSettingsOverrideType,
} from '../../../assistant/useUpdateAssistantCopilot'

import { Outer } from './styled'

type Props = {
  onClose: () => void
  subPromptId?: TimeTrackingCategoryId | TimeTrackingEntryTemplateId
  overridenPromptType: CopilotPromptSettingsOverrideType
}

const CreateOverridePromptModal = ({
  onClose,
  subPromptId,
  overridenPromptType,
}: Props) => {
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])
  const { createCopilotPromptSettingsOverride, loading } =
    useUpdateAssistantCopilot()

  return (
    <Modal
      isOpen
      onClose={onClose}
      width={'large'}
      heading={'Create Override Prompt'}
      actions={[
        {
          text: 'Create',
          onClick: async () => {
            await createCopilotPromptSettingsOverride({
              overridenPromptInfo: {
                id: subPromptId,
                type: overridenPromptType,
              },
              userIds: selectedUserIds,
            })

            onClose()
          },
          isDisabled: selectedUserIds.length === 0,
        },
        { text: 'Cancel', onClick: onClose },
      ]}
    >
      <Outer>
        <Field label={'Users'}>
          <MultiUserSelect
            isArchived={null}
            categories={[UserCategory.ASSISTANT, UserCategory.SANDBOX]}
            value={selectedUserIds.map((id) => ({ id }))}
            onChange={(users: UserOptionType[]) =>
              setSelectedUserIds((users || []).map(({ id }) => id))
            }
          />
        </Field>
        {loading && <LoadingSpinner />}
      </Outer>
    </Modal>
  )
}

export default CreateOverridePromptModal
