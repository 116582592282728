import ClickedIcon from '@atlaskit/icon/glyph/link'
import SentIcon from '@atlaskit/icon/glyph/send'
import ReceivedIcon from '@atlaskit/icon/glyph/tray'
import OpenedIcon from '@atlaskit/icon/glyph/watch'
import { typography, colors } from '@atlaskit/theme'
import Tooltip from '@atlaskit/tooltip'
import React from 'react'
import styled from 'styled-components'

import { useGetEmailActivitiesQuery } from '../../graphql'
import { LoadingSpinner } from '../Spinner'

const MAYBE_COLOR = colors.subtleText()
const YES_COLOR = colors.green()
const NO_COLOR = colors.red()

const formatDateTime = (
  at: string | number | boolean | Date | null,
): string | undefined => {
  if (typeof at === 'string') {
    return new Date(at).toLocaleString()
  }
}

const Outer = styled.div`
  padding: 0 4px 16px;
`

const Icons = styled.div`
  display: flex;
  align-items: center;
  max-width: 150px;
`

const Line = styled.div`
  height: 2px;
  flex: 1 1 auto;
  background-color: ${MAYBE_COLOR};
  margin: 0 4px;
  border-radius: 4px;
`

const Notice = styled.span`
  ${typography.h100};
`

interface Props {
  defaultSentAt?: Date | string | null
  recipientEmails?: undefined | null | string[]
  sendgridTemplateIds?: string[]
  messageTemplateIds?: string[]
  queryDateAfter?: Date
}

const EmailActivity = ({
  defaultSentAt,
  recipientEmails,
  sendgridTemplateIds,
  messageTemplateIds,
  queryDateAfter,
}: Props) => {
  const { data: emailActivitiesData, loading: loadingEmailActivities } =
    useGetEmailActivitiesQuery({
      variables: {
        dateAfter: queryDateAfter,
        recipients: recipientEmails,
        sendgridTemplateIds,
        messageTemplateIds,
      },
    })

  const status: Record<string, null | string | Date> = {
    sentAt: defaultSentAt || null,
    deliveredAt: null,
    openedAt: null,
    clickedAt: null,
  }

  emailActivitiesData?.emailActivities.forEach(
    ({ processedAt, deliveredAt, opened, clicked }) => {
      if (processedAt) {
        status.sentAt = processedAt
      }
      if (deliveredAt) {
        status.deliveredAt = deliveredAt
      }
      if (opened.length > 0) {
        status.openedAt = opened[0].at
      }
      if (clicked.length > 0) {
        status.clickedAt = clicked[0].at
      }
    },
  )

  const emailActivity = emailActivitiesData?.emailActivities[0]

  return (
    <Outer>
      <Icons>
        <Tooltip
          content={
            status.sentAt
              ? `Sent on ${formatDateTime(status.sentAt)}`
              : !emailActivity
              ? 'Sent?'
              : 'Not sent'
          }
        >
          <SentIcon
            label={''}
            size={'small'}
            primaryColor={
              status.sentAt
                ? YES_COLOR
                : !emailActivity
                ? MAYBE_COLOR
                : NO_COLOR
            }
          />
        </Tooltip>
        <Line />
        <Tooltip
          content={
            !emailActivity
              ? 'Delivered?'
              : status.deliveredAt
              ? `Delivered on ${formatDateTime(status.deliveredAt)}`
              : 'Not received'
          }
        >
          <ReceivedIcon
            label={''}
            size={'small'}
            primaryColor={
              !emailActivity
                ? MAYBE_COLOR
                : status.deliveredAt
                ? YES_COLOR
                : NO_COLOR
            }
          />
        </Tooltip>
        <Line />
        <Tooltip
          content={
            !emailActivity
              ? 'Opened?'
              : status.openedAt
              ? `Opened on ${formatDateTime(status.openedAt)}`
              : 'Not opened'
          }
        >
          <OpenedIcon
            label={''}
            size={'small'}
            primaryColor={
              !emailActivity
                ? MAYBE_COLOR
                : status.openedAt
                ? YES_COLOR
                : NO_COLOR
            }
          />
        </Tooltip>
        <Line />
        <Tooltip
          content={
            !emailActivity
              ? 'Clicked?'
              : status.clickedAt
              ? `Clicked on ${formatDateTime(status.clickedAt)}`
              : 'Not clicked'
          }
        >
          <ClickedIcon
            label={''}
            size={'small'}
            primaryColor={
              !emailActivity
                ? MAYBE_COLOR
                : status.clickedAt
                ? YES_COLOR
                : NO_COLOR
            }
          />
        </Tooltip>
      </Icons>
      <Notice>{'(Not real-time: max 12 hours delay)'}</Notice>
      <LoadingSpinner show={loadingEmailActivities} />
    </Outer>
  )
}

export default EmailActivity
