import Select, { SelectProps, ValueType } from '@atlaskit/select'
import { colors } from '@atlaskit/theme'
import React, { useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'

import {
  useListAutomationTriggersQuery,
  AutomationTriggerFragment,
} from '../../../../graphql'

const Option = styled.div<{ isFocused: boolean }>`
  padding: 6px 12px;

  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: ${colors.backgroundHover};
    `}

  strong {
    font-size: 14px;
    font-weight: 400;
  }

  span {
    font-size: 9px;
    font-weight: 500;
    color: ${colors.subtleText};
    text-transform: uppercase;
  }
`

type Props = SelectProps<AutomationTriggerFragment> & {
  valueId?: AutomationTriggerFragment['id'] | null
  // value?: AutomationTriggerFragment | null,
  onChangeValue?: (newValue: AutomationTriggerFragment | null) => void
  onChangeValueId?: (newValue: AutomationTriggerFragment['id'] | null) => void
  showDescription?: boolean
}

const AutomationTriggerSelect = ({
  valueId,
  _value,
  onChangeValue,
  onChangeValueId,
  showDescription,
  ...props
}: Props) => {
  const { data } = useListAutomationTriggersQuery()

  const options = useMemo(() => data?.list.items || [], [data])
  const value = useMemo(
    () => _value || options.find(({ id }) => id === valueId),
    [options, valueId, _value],
  )

  const onSelect = useCallback(
    (newValue: ValueType<AutomationTriggerFragment>) => {
      onChangeValueId?.(newValue?.id || null)
      onChangeValue?.(newValue || null)
    },
    [onChangeValue, onChangeValueId],
  )

  return (
    <Select<AutomationTriggerFragment>
      value={value}
      options={options}
      getOptionValue={(automation) => automation.id}
      placeholder={'Select trigger'}
      onChange={onSelect}
      components={{
        Option({ innerRef, data: automation, innerProps, isFocused }) {
          return (
            <Option ref={innerRef} {...innerProps} isFocused={isFocused}>
              <strong>{automation.id}</strong>
              <br />
              <span>{automation.description}</span>
            </Option>
          )
        },
        SingleValue({ innerProps, data: automation }) {
          return (
            <span {...innerProps}>
              {showDescription ? automation.description : automation.id}
            </span>
          )
        },
      }}
      {...props}
    />
  )
}

export default AutomationTriggerSelect
