import Table from '@atlaskit/dynamic-table'
import Tag, { TagColor } from '@atlaskit/tag'
import { colors } from '@atlaskit/theme'
import memoize from 'lodash/memoize'
import React from 'react'
import styled from 'styled-components'

import { formatDate } from '../../../components/utils'
import { useGetEmailActivitiesQuery } from '../../../graphql'

// @ts-ignore
const TAG_COLORS: TagColor[] = [
  'green',
  'blue',
  'red',
  'purple',
  'grey',
  'teal',
  'yellow',
  'greenLight',
  'blueLight',
  'redLight',
  'purpleLight',
  'greyLight',
  'tealLight',
  'yellowLight',
]

export const getTemplateColor = memoize(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (_template: unknown): TagColor => TAG_COLORS.pop()!,
)

const Outer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px 0px;
`

const Timestamp = styled.span`
  white-space: nowrap;
  font-size: smaller;
  font-weight: 600;
  color: ${colors.subtleText};
`

const Action = styled.div`
  white-space: nowrap;
  font-size: smaller;
  font-weight: 600;
  color: ${colors.subtleText};
`

const TABLE_HEAD = {
  cells: [
    {
      content: 'Template',
      shouldTruncate: false,
    },
    {
      content: 'Delivered',
      shouldTruncate: false,
    },
    {
      content: 'Opened',
      shouldTruncate: false,
    },
    {
      content: 'Clicked',
      shouldTruncate: false,
    },
  ],
}

interface Props {
  recipients: string[]
}

const Emails = ({ recipients }: Props) => {
  const { data, loading, error } = useGetEmailActivitiesQuery({
    variables: {
      // userId removed but maybe needed later for full picture
      // passing recipient instead of userId to fix query
      recipients,
    },
  })

  const emailActivities = [...(data?.emailActivities || [])]
    // Order by processed date
    .sort(
      ({ processedAt: a }, { processedAt: b }) => +new Date(b) - +new Date(a),
    )

  return (
    <Outer>
      <Table
        isLoading={loading}
        head={TABLE_HEAD}
        rows={emailActivities.map(
          ({ id, deliveredAt, opened, clicked, template }) => ({
            key: id,
            cells: [
              {
                content: (
                  <Tag
                    text={template?.name || ''}
                    isRemovable={false}
                    color={getTemplateColor(template?.name)}
                  />
                ),
              },
              {
                content: <Timestamp>{formatDate(deliveredAt)}</Timestamp>,
              },
              {
                content: <Action>{opened?.length > 0 ? '✅' : '🚫'}</Action>,
              },
              {
                content: <Action>{clicked?.length > 0 ? '✅' : '🚫'}</Action>,
              },
            ],
          }),
        )}
        emptyView={
          <span>
            {error ? `Error: ${error?.message}` : 'No emails sent yet'}
          </span>
        }
      />
    </Outer>
  )
}

export default Emails
