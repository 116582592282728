import { colors } from '@atlaskit/theme'
import styled from 'styled-components'

export const Outer = styled.div`
  display: flex;
  flex-direction: column;
`

export const BalanceAndGrantOuter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin: 10px 0;
  gap: 4px;
  border-radius: 8px;

  background-color: ${colors.N20};
`
