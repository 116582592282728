import Button from '@atlaskit/button'
import { DatePicker } from '@atlaskit/datetime-picker'
import { DateTime } from 'luxon'
import React from 'react'
import styled from 'styled-components'

import { ToggleStateless } from '../../../../components/Toggle'
import FeatureSelect from '../../../../components/features/Select'
import { MultiUserSelect } from '../../../../components/users/Select'
import {
  FeatureTargetPredicate,
  FeatureTargetAssistantPredicate,
  FeatureTargetFeaturePredicate,
  FeatureTargetExecutivePredicate,
  FeatureTargetConstantPredicate,
  FeatureTargetCreatedAtPredicate,
} from '../../../../graphql'

const Outer = styled.div`
  display: flex;
  min-width: 500px;
  max-width: 800px;
  margin-bottom: 24px;
  align-items: baseline;
`

const ToggleOuter = styled.div`
  top: 3px;
`

const DatePickerOuter = styled.div`
  width: 200px;
`

const Text = styled.span`
  margin-left: 8px;
  margin-right: 8px;
  font-weight: 500;
  flex-shrink: 0;
`

const SelectOuter = styled.div`
  min-width: 200px;
`

type OnOffProps = {
  onToggle: () => void
  onLabel: string
  offLabel: string
  isOn: boolean
  isDisabled?: boolean
}

const OnOff = ({
  onToggle,
  onLabel,
  offLabel,
  isOn,
  isDisabled,
}: OnOffProps) => (
  <Button isDisabled={isDisabled} appearance={'default'} onClick={onToggle}>
    {isOn ? onLabel : offLabel}
  </Button>
)

type PredicateProps<T extends FeatureTargetPredicate> = {
  isDisabled?: boolean
  predicate: T | null | undefined
  onChange: (newPredicate: T | null) => void
  isFirst?: boolean
}

export const ConstantPredicate = ({
  isDisabled,
  predicate,
  onChange,
}: PredicateProps<FeatureTargetConstantPredicate>) => (
  <Outer>
    <ToggleOuter>
      <ToggleStateless
        isDisabled={isDisabled}
        isChecked={!!predicate}
        size={'large'}
        onChange={() => onChange(predicate ? null : { constant: false })}
      />
    </ToggleOuter>
    <Text>{'Feature is'}</Text>
    <OnOff
      isDisabled={isDisabled}
      isOn={!!predicate?.constant}
      onLabel={'enabled'}
      offLabel={'disabled'}
      onToggle={() =>
        onChange({ ...predicate, constant: !predicate?.constant })
      }
    />
    <Text>{'for all workspaces'}</Text>
  </Outer>
)

export const ExecutivePredicate = ({
  isDisabled,
  predicate,
  onChange,
}: PredicateProps<FeatureTargetExecutivePredicate>) => (
  <Outer>
    <ToggleOuter>
      <ToggleStateless
        isDisabled={isDisabled}
        isChecked={!!predicate}
        size={'large'}
        onChange={() =>
          onChange(predicate ? null : { isNegated: false, executiveIds: [] })
        }
      />
    </ToggleOuter>
    <Text>{'Executive'}</Text>
    <OnOff
      isDisabled={isDisabled || !predicate}
      isOn={!predicate?.isNegated}
      onLabel={'is'}
      offLabel={'is not'}
      onToggle={() =>
        onChange({
          executiveIds: [],
          ...predicate,
          isNegated: !predicate?.isNegated,
        })
      }
    />
    <Text> </Text>
    <SelectOuter>
      <MultiUserSelect
        isDisabled={isDisabled || !predicate}
        isExecutive
        defaultOptions
        value={predicate?.executiveIds.map((id) => ({ id })) || []}
        validationState={
          predicate && !predicate.executiveIds?.length ? 'error' : 'default'
        }
        onChange={(users) =>
          onChange({
            isNegated: false,
            ...predicate,
            executiveIds: (users || []).map(({ id }) => id),
          })
        }
      />
    </SelectOuter>
  </Outer>
)

export const AssistantPredicate = ({
  isFirst,
  isDisabled,
  predicate,
  onChange,
}: PredicateProps<FeatureTargetAssistantPredicate>) => (
  <Outer>
    <ToggleOuter>
      <ToggleStateless
        isDisabled={isDisabled}
        isChecked={!!predicate}
        size={'large'}
        onChange={() =>
          onChange(predicate ? null : { isNegated: false, assistantIds: [] })
        }
      />
    </ToggleOuter>
    <Text>{isFirst ? 'Assistant' : 'and assistant'}</Text>
    <OnOff
      isDisabled={isDisabled || !predicate}
      isOn={!predicate?.isNegated}
      onLabel={'is'}
      offLabel={'is not'}
      onToggle={() =>
        onChange({
          assistantIds: [],
          ...predicate,
          isNegated: !predicate?.isNegated,
        })
      }
    />
    <Text> </Text>
    <SelectOuter>
      <MultiUserSelect
        isDisabled={isDisabled || !predicate}
        isAssistant
        defaultOptions
        value={predicate?.assistantIds.map((id) => ({ id })) || []}
        validationState={
          predicate && !predicate.assistantIds?.length ? 'error' : 'default'
        }
        onChange={(users) =>
          onChange({
            isNegated: false,
            ...predicate,
            assistantIds: (users || []).map(({ id }) => id),
          })
        }
      />
    </SelectOuter>
  </Outer>
)

export const FeaturePredicate = ({
  isFirst,
  isDisabled,
  predicate,
  onChange,
}: PredicateProps<FeatureTargetFeaturePredicate>) => (
  <Outer>
    <ToggleOuter>
      <ToggleStateless
        isDisabled={isDisabled}
        isChecked={!!predicate}
        size={'large'}
        onChange={() =>
          // @ts-ignore
          onChange(predicate ? null : { isNegated: false, featureId: null })
        }
      />
    </ToggleOuter>
    <Text>{isFirst ? 'Feature' : 'and feature'}</Text>
    <SelectOuter>
      <FeatureSelect
        isDisabled={isDisabled || !predicate}
        value={predicate?.featureId ? { id: predicate.featureId } : null}
        validationState={
          predicate && !predicate.featureId ? 'error' : 'default'
        }
        onChange={(feature) =>
          onChange(
            feature
              ? {
                  isNegated: false,
                  ...predicate,
                  // @ts-ignore
                  featureId: feature.id,
                }
              : null,
          )
        }
      />
    </SelectOuter>
    <Text> </Text>
    <OnOff
      isDisabled={isDisabled || !predicate}
      isOn={!predicate?.isNegated}
      onLabel={'is'}
      offLabel={'is not'}
      onToggle={() =>
        // @ts-ignore
        onChange({ ...predicate, isNegated: !predicate?.isNegated })
      }
    />
    <Text>{'enabled for workspace'}</Text>
  </Outer>
)
export const CreatedAfterPredicate = ({
  isFirst,
  isDisabled,
  predicate,
  onChange,
}: PredicateProps<FeatureTargetCreatedAtPredicate>) => {
  const today = new Date().toISOString()
  return (
    <Outer>
      <ToggleOuter>
        <ToggleStateless
          isDisabled={isDisabled}
          isChecked={!!predicate}
          size={'large'}
          // @ts-ignore
          onChange={() =>
            onChange(
              predicate ? null : { isNegated: false, createdAfter: today },
            )
          }
        />
      </ToggleOuter>
      <Text>
        {isFirst ? 'Workspace was created' : 'and workspace was created'}
      </Text>
      <OnOff
        isDisabled={isDisabled || !predicate}
        isOn={!predicate?.isNegated}
        onLabel={'after'}
        offLabel={'before'}
        onToggle={() =>
          // @ts-ignore
          onChange({ ...predicate, isNegated: !predicate?.isNegated })
        }
      />
      <Text> </Text>
      <DatePickerOuter>
        <DatePicker
          isDisabled={isDisabled || !predicate}
          icon={undefined}
          value={
            predicate?.createdAfter
              ? new Date(predicate.createdAfter).toISOString()
              : today
          }
          onChange={(value) =>
            onChange({
              ...predicate,
              isNegated: !!predicate?.isNegated,
              createdAfter: DateTime.fromISO(value).toISO(),
            })
          }
        />
      </DatePickerOuter>
    </Outer>
  )
}
