import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import Item, { ITEM_LINK_ACTIVE_CLASSNAME } from '../../../components/Item'
import PageHeader from '../../../components/PageHeader'
// import CreateTeamModal from '../../../components/teams/CreateTeamModal'
import Cell from '../../../components/teams/Cell'
import List from '../../../components/teams/List'
import getQueryParam from '../../../lib/getQueryParam'

import { Outer, ListOuter } from './styled'

const TeamsList = () => {
  const [search, setSearch] = useState<string | null | undefined>(
    getQueryParam('search', ''),
  )

  // TODO - implement create team action
  // const [
  //   isCreateTeamModalOpen,
  //   openCreateTeamModal,
  //   closeCreateTeamModal,
  // ] = useSwitch(false)

  return (
    <Outer>
      <PageHeader
        // createActions={[
        //   {
        //     label: 'Team',
        //     onClick: openCreateTeamModal,
        //   },
        // ]}
        searchTerm={search}
        onChangeSearchTerm={setSearch}
      >
        {'Teams'}
      </PageHeader>
      <ListOuter>
        <List
          search={search || ''}
          isArchived={search ? null : false}
          autoNavigateToSingleResult
          showFooter
          Cell={({ team, style }) => (
            <NavLink
              to={`/teams/${team?.id}`}
              style={style}
              activeClassName={ITEM_LINK_ACTIVE_CLASSNAME}
            >
              <Item>
                <Cell team={team} withSuccessManagerAvatar />
              </Item>
            </NavLink>
          )}
        />
      </ListOuter>

      {/* <CreateTeamModal
        isOpen={isCreateTeamModalOpen}
        onClose={closeCreateTeamModal}
      /> */}
    </Outer>
  )
}

export default TeamsList
