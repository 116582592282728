import { colors } from '@atlaskit/theme'
import styled from 'styled-components'

export const BlueColor = colors.blue()
export const RedColor = colors.red()
export const GrayColor = colors.N60

export const RemoveDoubleText = styled.span<{ $isDisabled: boolean }>`
  color: ${RedColor};

  ${({ $isDisabled }) =>
    $isDisabled &&
    `
    cursor: not-allowed !important;
    color: ${GrayColor};

    &:hover {
        color: ${GrayColor};
    }
    `}
`

export const VisibilityText = styled.span<{
  $isDisabled: boolean
  $isHiddenFromPool: boolean
}>`
  color: ${({ $isHiddenFromPool }) =>
    $isHiddenFromPool ? BlueColor : RedColor};

  ${({ $isDisabled }) =>
    $isDisabled &&
    `
    cursor: not-allowed !important;
    color: ${GrayColor};

    &:hover {
        color: ${GrayColor};
    }
    `}
`
