import React from 'react'

import {
  useGetAssistantOnboardingAvailabilityQuery,
  onlyIfAssistantable,
} from '../../graphql'
import useSwitch from '../../lib/useSwitch'

import OnboardingAvailabilityButton, {
  OnboardingAvailabilityButtonProps,
} from './OnboardingAvailabilityButton'

type Props = Partial<OnboardingAvailabilityButtonProps> & {
  assistantId: string
}

const AssistantOnboardingAvailabilityButton = ({
  assistantId,
  ...buttonProps
}: Props) => {
  const [isOpen, open, close] = useSwitch(false)

  const { data, loading, error } = useGetAssistantOnboardingAvailabilityQuery({
    variables: {
      assistantId,
    },
    skip: !isOpen,
  })

  const assistant = onlyIfAssistantable(data?.assistant)

  return (
    <OnboardingAvailabilityButton
      isOpen={isOpen}
      open={open}
      close={close}
      slots={assistant?.onboardingAvailability.slots}
      error={error}
      isLoading={loading}
      {...buttonProps}
    />
  )
}

export default AssistantOnboardingAvailabilityButton
