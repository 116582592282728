import Spinner from '@atlaskit/spinner'
import TableTree, {
  Cell,
  Header,
  Headers,
  Row,
  Rows,
} from '@atlaskit/table-tree'
import Tooltip from '@atlaskit/tooltip'
import sortBy from 'lodash/sortBy'
import { Duration } from 'luxon'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { ErrorEmptyState } from '../../../../components/EmptyState'
import { LoadingSpinner } from '../../../../components/Spinner'
import UserCell from '../../../../components/users/Cell'
import {
  withListPagination,
  useListUpdateSubscription,
} from '../../../../graphql'
import {
  TimeTrackingEntriesListQueryVariables,
  TimeTrackingEntryFragment,
  useTimeTrackingEntriesListQuery,
  TimeTrackingEntryUpsertedDocument,
} from '../../../../graphql/generated/hooks'
import useHasScrolledToBottom from '../../../../lib/useHasScrolledToBottom'
import useTimeEntriesLockedAt from '../useTimeEntriesLockedAt'

import MoreButton from './MoreButton'
import Time from './Time'
import Title from './Title'
import { DoubleIcon } from './styled'

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 auto;

  & > div {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
`

const RowsContainer = styled.div`
  flex: 1 1 0px;
  overflow: auto;
`

const SpinnerOuter = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

type Item = {
  id: string
  content: TimeTrackingEntryFragment
  hasChildren: boolean
  children?: Item[]
}

type Props = TimeTrackingEntriesListQueryVariables

const EntryList = (timeTrackingEntriesListQueryVariables: Props) => {
  const { data, loading, error, fetchMore, hasMore } = withListPagination(
    useListUpdateSubscription(
      useTimeTrackingEntriesListQuery({
        variables: timeTrackingEntriesListQueryVariables,
        // Force refetch list in case we use filters and might miss updates via subscription
        fetchPolicy: 'cache-and-network',
      }),
      TimeTrackingEntryUpsertedDocument,
    ),
  )

  const lastLockedAt = useTimeEntriesLockedAt(
    timeTrackingEntriesListQueryVariables,
  )

  const items = useMemo((): Item[] | undefined => {
    if (!data) {
      return
    }

    // Sort by end date DESC, then start date DESC
    // Open entries will be at the top
    return sortBy(
      data.list.items,
      (item) =>
        item.endedAt ? -1 * new Date(item.endedAt).getTime() : -Infinity,
      (item) =>
        item.startedAt ? -1 * new Date(item.startedAt).getTime() : -Infinity,
    ).map((item) => ({
      id: item.id,
      content: item,
      hasChildren: false,
    }))
  }, [data])

  const [rowsContainerRefState, setRowsContainerRefState] =
    useState<HTMLDivElement>()
  const hasScrolledToBottom = useHasScrolledToBottom(rowsContainerRefState, {
    offset: 300,
  })
  useEffect(() => {
    if (hasScrolledToBottom && hasMore) {
      fetchMore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasScrolledToBottom]) // Only run on hasScrolledToBottom change

  if (error) return <ErrorEmptyState error={error} />
  if (loading && !items) return <LoadingSpinner />

  return (
    <Outer>
      <TableTree>
        <Headers>
          <Header width={'45%'}>{'Time Entry'}</Header>
          <Header width={'15%'}>{'Executive'}</Header>
          <Header width={'15%'}>{'Assistant'}</Header>
          <Header width={'10%'}>{'Duration'}</Header>
          <Header width={'10%'}>{'Time'}</Header>
          <Header width={'5%'} />
        </Headers>
        <RowsContainer ref={setRowsContainerRefState as any}>
          <Rows
            items={items}
            render={({ id, content: timeTrackingEntry, children }: Item) => {
              const entryDuration = timeTrackingEntry.duration
                ? Duration.fromISO(timeTrackingEntry.duration)
                : undefined

              const currentExec = timeTrackingEntry.workspace?.executives[0]
              return (
                <Row itemId={id} items={children} hasChildren={false}>
                  <Cell>
                    <Title entry={timeTrackingEntry} />
                  </Cell>
                  <Cell>
                    {currentExec ? (
                      <UserCell user={currentExec} />
                    ) : (
                      <DoubleIcon />
                    )}
                  </Cell>
                  <Cell>
                    <UserCell user={timeTrackingEntry.user} />
                  </Cell>
                  <Cell>
                    {entryDuration && (
                      <Tooltip
                        content={`${entryDuration
                          .as('hours')
                          .toFixed(2)} hours`}
                      >
                        <span>{entryDuration.toFormat('hh:mm')}</span>
                      </Tooltip>
                    )}
                  </Cell>
                  <Cell>
                    <Time
                      lastLockedAt={lastLockedAt}
                      entry={timeTrackingEntry}
                    />
                  </Cell>
                  <Cell>
                    <MoreButton entry={timeTrackingEntry} />
                  </Cell>
                </Row>
              )
            }}
          />
          {hasMore && (
            <Row>
              <SpinnerOuter>
                <Spinner />
              </SpinnerOuter>
            </Row>
          )}
        </RowsContainer>
      </TableTree>
    </Outer>
  )
}

export default EntryList
