import { v4 as uuid } from 'uuid'

import { PricingFragment, PricingModel } from '../../../graphql'

const NEW_PRICING_REF = 'NEW'

export function generateNewPricing(): Required<PricingFragment> {
  return {
    __typename: 'Pricing',
    id: uuid(),
    description: null,
    ref: NEW_PRICING_REF,
    label: 'New Pricing',
    hoursDescription: null,
    priceDescription: null,
    sellingPoints: [],
    sellingPointsNotice: null,
    isPublic: false,
    isAvailable: false,
    isSharable: false,
    instantInviteMaxUsers: 0,
    learnMoreUrl: null,
    model: null,
    modelConfig: {},
    includesPremiumPerks: false,
    includesTeamFeatures: false,
  }
}

export function isNewPricing(pricing: PricingFragment): boolean {
  return pricing.ref === NEW_PRICING_REF
}

export const isHourlyPricing = (
  p?: PricingFragment | null,
): p is TypedPricing<PricingModel.HOURLY> => p?.model === PricingModel.HOURLY

export const isOriginalPricing = (
  p?: PricingFragment | null,
): p is TypedPricing<PricingModel.ORIGINAL> =>
  p?.model === PricingModel.ORIGINAL

export type PricingModelConfig<M = PricingModel> = M extends PricingModel.HOURLY
  ? {
      hourlyPrice: number
      basePrice?: number
      baseHours?: number
      minPrice?: number
      graceMonths?: number
      graceHourlyPrice?: number
    }
  : M extends PricingModel.ORIGINAL
  ? {
      minPrice?: number
    }
  : Record<string, unknown>

export type TypedPricing<M = PricingModel> = Omit<
  PricingFragment,
  'modelConfig' | 'model'
> & {
  model: M
  modelConfig: PricingModelConfig<M>
}

export function getPricingFormulaString(
  pricing: PricingFragment,
  ignoreBasePrice: boolean,
): string {
  if (isHourlyPricing(pricing)) {
    const {
      minPrice,
      hourlyPrice,
      basePrice,
      baseHours,
      graceMonths,
      graceHourlyPrice,
    } = pricing.modelConfig

    let formula = basePrice
      ? `$${basePrice} + Max(0, Hours - ${baseHours}) * $${hourlyPrice}`
      : minPrice
      ? `Max($${minPrice}, Hours * $${hourlyPrice})`
      : `Hours * $${hourlyPrice}`

    if (graceMonths && graceHourlyPrice) {
      formula = [
        `Month <= ${graceMonths}  ?  Hours * $${graceHourlyPrice}`,
        `            :  ${formula}`,
      ].join('\n')
    }

    // Exception pricing: https://double.height.app/T-4913
    // https://www.notion.so/withdouble/CS-2-Optimize-Invoicing-2a47d76fb3cc45dbb95e693d8fde0202#8be98c4ef3d2481c9754450e84f544ca
    // Apply minimum possible, ignoring the base price
    if (ignoreBasePrice) {
      formula = `Min(${formula}, Hours * $${hourlyPrice})`
    }

    return formula
  }

  if (isOriginalPricing(pricing)) {
    const { minPrice } = pricing.modelConfig

    const formula = [
      `   Hours <= 10  ?  ${
        minPrice ? `Max($${minPrice}, ` : ''
      }Min(Hours * $50, $450)${minPrice ? ')' : ''}`,
      ':  Hours <= 25  ?  Min(Hours * $45, $1000)',
      '                :  Hours * $40',
    ].join('\n')

    return formula
  }

  return '0'
}

export const FORMULA_LEGEND = [
  'Hours: number of billable hours',
  'Month: current billing month (onboarding month is `1` and so forth)',
].join('\n')

export interface ComputeParams {
  hours: number
  monthsSinceStartDate: number // TODO: Delete, unused now since no graceMonths anywhere
  ignoreBasePrice: boolean
}

export function getPricingFormulaWithValues(
  pricing: PricingFragment | undefined | null,
  params: ComputeParams,
): string {
  if (!pricing) return '$0'

  const { hours = 0, monthsSinceStartDate = Infinity, ignoreBasePrice } = params

  const formula = getPricingFormulaString(pricing, ignoreBasePrice)
    .replace(/Hours/g, hours.toFixed(2))
    .replace(/Month/g, (monthsSinceStartDate + 1).toFixed(0))

  return formula
}

export function computeAmount(
  pricing: PricingFragment | undefined | null,
  params: ComputeParams,
): number {
  const formula = getPricingFormulaWithValues(pricing, params)
    .replace(/\$/g, '')
    .replace(/Max/g, 'Math.max')
    .replace(/Min/g, 'Math.min')

  // eslint-disable-next-line no-eval
  return eval(formula)
}
