import React, { ComponentProps, useCallback, useMemo } from 'react'

import { useListPricingsQuery, PricingFragment } from '../graphql'

import { Select } from './form'

const getPricingOption = (
  pricing: PricingFragment | undefined,
): undefined | { label: string; value: string } =>
  pricing && {
    value: pricing.id,
    label: [pricing.ref, pricing.priceDescription, pricing.hoursDescription]
      .filter(Boolean)
      .join(' - '),
  }

function notFalsy<T>(v: T | undefined | null | false | '' | 0): v is T {
  return !!v
}

type Props = Omit<ComponentProps<typeof Select>, 'onChangeValue'> & {
  valueId?: PricingFragment['id'] | null
  value?: PricingFragment | null
  onChangeValue?: (newValue: PricingFragment | null) => void
  onChangeValueId?: (newValue: PricingFragment['id'] | null) => void
}

const EXCEPTION_PRICING_REF = 'EXCEPTION'

const PricingSelect = ({
  valueId,
  value,
  onChangeValue,
  onChangeValueId,
  ...props
}: Props) => {
  const { data: pricingsData } = useListPricingsQuery()
  const pricings = useMemo(() => {
    return (pricingsData?.list.items || [])
      .filter(
        ({ isAvailable, ref }) => isAvailable || ref === EXCEPTION_PRICING_REF,
      )
      .sort((a, b) => {
        return (
          // Move EXCEPTION at the bottom, by defaulting to 10000 when no baseHours
          (a.modelConfig?.baseHours || 10000) -
          (b.modelConfig?.baseHours || 10000)
        )
      })
  }, [pricingsData])

  valueId = valueId || value?.id
  value = pricings?.find(({ id }) => id === valueId)

  const onSelect = useCallback(
    (newValueId: PricingFragment['id']) => {
      onChangeValueId?.(newValueId)
      onChangeValue?.(pricings?.find(({ id }) => id === newValueId) || null)
    },
    [onChangeValue, onChangeValueId, pricings],
  )

  return (
    <Select
      isRadio
      value={value ? getPricingOption(value) : null}
      options={pricings.map(getPricingOption).filter(notFalsy)}
      placeholder={'Select package'}
      onChangeValue={onSelect}
      {...props}
    />
  )
}

export default PricingSelect
