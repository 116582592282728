import React, { useCallback, ComponentProps } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import {
  useCreateExecutiveWorkspaceMutation,
  CreateExecutiveWorkspaceInput,
  UserCategory,
} from '../../graphql'
import useValues from '../../lib/useValues'
import MarkdownEditor from '../MarkdownEditor'
import Modal from '../Modal'
import TimeZoneSelect from '../TimeZoneSelect'
import { Field, Checkbox, FieldsRow } from '../form'
import PreviewOnboardingCallAvailabilityButton from '../onboarding-availability/PreviewOnboardingCallAvailabilityButton'

import { SingleUserSelect } from './Select'

const AssistantRow = styled(FieldsRow)`
  align-items: center;
  & > * + * {
    margin-left: 8px;
  }
`

export type FormValues = Partial<
  Omit<CreateExecutiveWorkspaceInput, 'executiveId'>
>

export const isValidForm = (formValues: FormValues) => {
  if (!formValues.workingHoursTimeZone) {
    return false
  }

  return true
}

type FormProps = {
  values: FormValues
  onPatch: (values: Partial<FormValues>) => void
}

export const Form = ({ values, onPatch }: FormProps) => (
  <>
    <Field
      label={'Onboarding Specialist (optional)'}
      helperMessage={
        'Only assign a specialist if you want a specific CSM to do the onboarding call'
      }
    >
      <SingleUserSelect
        value={
          values.onboardingSpecialistId
            ? { id: values.onboardingSpecialistId }
            : undefined
        }
        adminRoles={['ONBOARDING_SPECIALIST']}
        isClearable
        onChange={(user: null | { id: string }) =>
          onPatch({ onboardingSpecialistId: user?.id })
        }
        placeholder={'Assign onboarding specialist'}
        defaultOptions
        maxMenuHeight={250}
      />
    </Field>

    <Field label={'Working Hours Time-Zone'} isRequired>
      <TimeZoneSelect
        value={values.workingHoursTimeZone}
        onChangeValue={(workingHoursTimeZone) =>
          // @ts-ignore
          onPatch({ workingHoursTimeZone })
        }
        validationState={!values.workingHoursTimeZone ? 'error' : undefined}
      />
    </Field>

    <Field label={'Add assistant to workspace'}>
      <AssistantRow>
        <SingleUserSelect
          value={values.assistantId ? { id: values.assistantId } : undefined}
          categories={[
            UserCategory.ASSISTANT,
            UserCategory.SANDBOX,
            UserCategory.TEAM,
          ]}
          isClearable
          onChange={(user: null | { id: string }) => {
            onPatch({
              assistantId: user?.id || null,
              sendOnboardingEmail: !!user?.id,
            })
          }}
          maxMenuHeight={130}
        />
        {!!(
          (values.workingHoursTimeZone || values.onboardingSpecialistId) &&
          values.assistantId
        ) && (
          <PreviewOnboardingCallAvailabilityButton
            specialistId={values.onboardingSpecialistId}
            timeZone={values.workingHoursTimeZone}
            assistantId={values.assistantId}
          />
        )}
      </AssistantRow>
    </Field>

    <Field
      label={'Matching Rationale'}
      helperMessage={'2 to 3 bullets, visible to exec'}
    >
      <MarkdownEditor
        value={values.matchingRationale}
        onChange={(matchingRationale) => onPatch({ matchingRationale })}
        minHeight={90}
        isDisabled={!values.assistantId}
      />
    </Field>

    <Field label={'Actions'}>
      <Checkbox
        isChecked={!!values.sendOnboardingEmail}
        onChangeValue={(sendOnboardingEmail) =>
          onPatch({ sendOnboardingEmail })
        }
        label={'Send onboarding email & SMS'}
        isDisabled={!values.assistantId}
      />
    </Field>
  </>
)

type Props = ComponentProps<typeof Modal> & {
  executiveId: string
}

const CreateExecutiveWorkspaceModal = ({
  executiveId,
  ...modalProps
}: Props) => {
  const history = useHistory()

  const [formValues, { reset, patch }] = useValues<FormValues>({})

  const [
    createExecutiveWorkspace,
    { loading: loadingCreateExecutiveWorkspace },
  ] = useCreateExecutiveWorkspaceMutation({
    onCompleted: (data) => {
      // @ts-ignore
      modalProps.onClose?.()
      history.push(
        `/users/${executiveId}/workspaces/${data.createExecutiveWorkspace.workspace.id}`,
      )
    },
  })

  const onSubmit = useCallback(() => {
    if (formValues.workingHoursTimeZone) {
      createExecutiveWorkspace({
        variables: {
          input: {
            ...formValues,
            workingHoursTimeZone: formValues.workingHoursTimeZone,
            executiveId,
          },
        },
      })
    }
  }, [createExecutiveWorkspace, executiveId, formValues])

  return (
    <Modal
      onCloseComplete={reset}
      width={'small'}
      heading={'Create new workspace'}
      autoFocus
      actions={[
        {
          text: 'Create',
          onClick: onSubmit,
          isDisabled: !isValidForm(formValues),
          isLoading: loadingCreateExecutiveWorkspace,
        },
        { text: 'Cancel', onClick: modalProps.onClose },
      ]}
      {...modalProps}
    >
      <Form values={formValues} onPatch={patch} />
    </Modal>
  )
}

export default CreateExecutiveWorkspaceModal
