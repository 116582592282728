import Button from '@atlaskit/button'
import Icon from '@atlaskit/icon/glyph/marketplace'
import SettingsIcon from '@atlaskit/icon/glyph/settings'
import Tooltip from '@atlaskit/tooltip'
import React, { useMemo, useState } from 'react'

import PageHeader from '../../../components/PageHeader'
import Toggle, { ToggleOuter } from '../../../components/Toggle'
import { Field, Select } from '../../../components/form'
import {
  TodoStatus,
  useAdhocTodosListQuery,
  withListPagination,
} from '../../../graphql'
import getQueryParam from '../../../lib/getQueryParam'
import useSwitch from '../../../lib/useSwitch'
import { Page } from '../../types'

import AdhocTodoSettingsModal from './SettingsModal'
import Table from './Table'
import { FilterContainer, Outer, TableOuter } from './styled'

const IRRELEVANT_STATUSES = [
  TodoStatus.ON_HOLD,
  TodoStatus.NEEDS_ATTENTION,
  TodoStatus.SNOOZED,
]
const ADHOC_TODO_STATUSES = Object.values(TodoStatus).filter(
  (status) => !IRRELEVANT_STATUSES.includes(status),
)

const toSelectOption = (statuses: TodoStatus[]) =>
  statuses.map((status) => ({
    label: status,
    value: status,
  }))

const STATUS_OPTIONS = toSelectOption(ADHOC_TODO_STATUSES)

const AdhocTodos: Page = () => {
  const [isSandboxHidden, , , toggleSandbox] = useSwitch(true)
  const [excludedStatusesState, setExcludedStatusesState] = useState<
    TodoStatus[]
  >([TodoStatus.DRAFT])
  const [isModalOpen, openModal, closeModal] = useSwitch()
  const [search, setSearch] = useState<string | null | undefined>(
    getQueryParam('search', ''),
  )
  const { data, loading, error, fetchMore, hasMore } = withListPagination(
    useAdhocTodosListQuery({
      variables: {
        excludedStatuses: excludedStatusesState,
      },
    }),
  )

  const filteredData = useMemo(() => {
    return data?.list.items.filter(
      (item) =>
        (search
          ? [
              item.title,
              item.executive?.givenName,
              item.executive?.familyName,
              item.dispatchedTo?.profile.displayName,
            ].some((field) =>
              field?.toLowerCase().includes(search.toLowerCase()),
            )
          : true) &&
        (!isSandboxHidden || item.dispatchedTo?.category !== 'SANDBOX') &&
        !excludedStatusesState.includes(item.status || ''),
    )
  }, [data?.list.items, search, isSandboxHidden, excludedStatusesState])

  return (
    <Outer>
      <FilterContainer>
        <PageHeader
          actions={
            <Button
              iconBefore={
                <Tooltip content={'Configure Settings/Prompt'}>
                  <SettingsIcon label={'Configure Settings/Prompt'} />
                </Tooltip>
              }
              onClick={openModal}
            />
          }
          searchTerm={search}
          onChangeSearchTerm={setSearch}
          searchInputPlaceholder={'Search (Task Title, Customer, or Assistant)'}
          filtersContent={() => (
            <>
              <Field label={'Hide Status'}>
                <Select<TodoStatus[]>
                  isMulti
                  isClearable
                  options={STATUS_OPTIONS}
                  value={toSelectOption(excludedStatusesState)}
                  onChangeValue={(selectedOptions) => {
                    setExcludedStatusesState(selectedOptions)
                  }}
                />
              </Field>
              <Field label={''}>
                <ToggleOuter>
                  <Toggle
                    isChecked={isSandboxHidden}
                    onChange={toggleSandbox}
                  />
                  {'Hide Sandbox'}
                </ToggleOuter>
              </Field>
            </>
          )}
        >
          {'Ad Hoc Todos'}
        </PageHeader>
      </FilterContainer>
      <TableOuter>
        <Table
          items={filteredData || []}
          sort={false}
          loading={loading}
          error={error}
          fetchMore={fetchMore}
          hasMore={hasMore}
        />
      </TableOuter>
      {isModalOpen && <AdhocTodoSettingsModal onClose={closeModal} />}
    </Outer>
  )
}

AdhocTodos.path = '/adhoc-todos'
AdhocTodos.navTitle = 'Ad Hoc Todos'
AdhocTodos.Icon = Icon
AdhocTodos.requiredOperationIds = ['Query.adhocTodosList']

export default AdhocTodos
