import Button from '@atlaskit/button'
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import React, { ComponentProps, useCallback, useEffect, useState } from 'react'

import Modal from '../../../../components/Modal'
import UserCell from '../../../../components/users/Cell'
import { ReportSummary } from '../utils'

type Props = ComponentProps<typeof Modal> & {
  reports: (ReportSummary | undefined | null)[]
  isOpen: boolean
  onClose: () => void
}

const WarningDialog: React.FC<Props> = ({ reports, isOpen, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen)
  const closeModal = useCallback(() => {
    onClose()
    setIsModalOpen(false)
  }, [onClose])

  useEffect(() => {
    setIsModalOpen(isOpen)
  }, [isOpen])

  const users = reports?.filter(Boolean)

  return users?.length > 0 ? (
    <ModalTransition>
      <Modal onClose={closeModal} isOpen={isModalOpen} appearance={'warning'}>
        <ModalHeader>
          <ModalTitle>{'Some of the actions performed failed'}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {'This is the list of users for which the action failed:'}
          {users?.map(
            (report) =>
              report && <UserCell key={report.id} user={report.invoicedUser} />,
          )}
        </ModalBody>
        <ModalFooter>
          <Button appearance={'warning'} onClick={closeModal} autoFocus>
            {'Ok'}
          </Button>
        </ModalFooter>
      </Modal>
    </ModalTransition>
  ) : null
}

export default WarningDialog
