import CloseIcon from '@atlaskit/icon/glyph/cross'
import SectionMessage from '@atlaskit/section-message'
import { colors } from '@atlaskit/theme'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Tabs from '../../../../components/Tabs'
import AutomationJobsInline from '../../../autopilot/Jobs/ListInline'
import useActiveMatching from '../useActiveMatching'

import Changeover from './Changeover'
import Handover from './Handover'
import Informing from './Informing'
import Matching from './Matching'
import Scheduling from './Scheduling'
import Infos from './components/Infos'
import { PanelOuter } from './styled'

const Outer = styled.div`
  height: 100%;
  border-left: 2px solid ${colors.backgroundHover};
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 700px;
`

const CloseLink = styled(Link)`
  padding: 8px;
  margin: 16px;
`

const Inner = styled.div`
  padding: 0 24px 24px;
  overflow: auto;
  height: 100%;
`

type Props = {
  isEmbedded?: boolean
}

const Panel = ({ isEmbedded }: Props) => {
  const { matching } = useActiveMatching()

  if (!matching) {
    return null
  }

  const isBundle = (matching.bundle?.length || 0) > 1

  const tabs = [
    {
      label: 'Informing',
      content: (
        <PanelOuter>
          <Informing matching={matching} />
        </PanelOuter>
      ),
    },
    {
      label: 'Matching',
      content: (
        <PanelOuter>
          <Matching matching={matching} noProfile={isEmbedded} />
        </PanelOuter>
      ),
    },
    {
      label: 'Scheduling',
      content: (
        <PanelOuter>
          <Scheduling matching={matching} />
        </PanelOuter>
      ),
    },
    {
      label: 'Handover',
      content: (
        <PanelOuter>
          <Handover matching={matching} />
        </PanelOuter>
      ),
    },
    {
      label: 'Changeover',
      content: (
        <PanelOuter>
          <Changeover matching={matching} />
        </PanelOuter>
      ),
    },
  ].filter(Boolean)

  const rightTabs = [
    {
      label: '🤖',
      slug: 'autopilot',
      content: (
        <PanelOuter>
          <AutomationJobsInline
            filter={{
              hasParams: {
                matchingId: matching.id,
              },
            }}
          />
        </PanelOuter>
      ),
    },
  ]

  if (isEmbedded) {
    return (
      <>
        <Infos matching={matching} />
        {!isBundle && (
          <Tabs
            isContentPersisted
            leftTabs={tabs}
            rightTabs={rightTabs}
            notUrlBased={isEmbedded}
          />
        )}
        {isBundle && (
          <SectionMessage
            appearance={'warning'}
            title={'Bundled team transitions'}
          >
            {'Actions taken on this screen will affect multiple transitions.'}
            <br />
            {
              'Information displayed is extracted from the Team Principal. Open individual transitions to see their specific data.'
            }
          </SectionMessage>
        )}
      </>
    )
  }

  return (
    <Outer>
      <CloseLink to={'/transitions'}>
        <CloseIcon label={''} />
      </CloseLink>
      <Inner>
        <Infos matching={matching} />
        {!isBundle && (
          <Tabs isContentPersisted leftTabs={tabs} rightTabs={rightTabs} />
        )}
      </Inner>
      {isBundle && (
        <SectionMessage
          appearance={'warning'}
          title={'Bundled team transitions'}
        >
          {'Actions taken on this screen will affect multiple transitions.'}
          <br />
          {
            'Information displayed is extracted from the Team Principal. Open individual transitions to see their specific data.'
          }
        </SectionMessage>
      )}
    </Outer>
  )
}

export default Panel
