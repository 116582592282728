import { colors } from '@atlaskit/theme'
import styled from 'styled-components'

export const Outer = styled.div`
  background-color: ${colors.N20};
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  flex: 1 1 30%;
  min-width: 280px;
  max-width: 400px;
`

export const ListOuter = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-bottom: 32px;
`
