import Icon from '@atlaskit/icon/glyph/creditcard'
import PageHeader from '@atlaskit/page-header'
import { colors } from '@atlaskit/theme'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useListPricingsQuery } from '../../../graphql'
import useValues from '../../../lib/useValues'
import { Page } from '../../types'

import FilterEditor from './FilterEditor'
import List from './List'
import QueryEditor from './QueryEditor'
import SortEditor from './SortEditor'
import {
  ReportsQuery,
  isValidQuery,
  SortKey,
  Filter,
  DEFAULT_DEFAULT_PRICING_REF,
} from './utils'

const Outer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  flex: 1;
`

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 0 8px;
  flex: 1 1 30%;
  min-width: 360px;
  max-width: 400px;
  overflow: scroll;
  flex: 1;
  padding: 0 24px;
  background-color: ${colors.skeleton};
  border-right: solid 2px ${colors.backgroundHover};
`

const Spacer = styled.div`
  flex: 1;
`

const Header = styled.div`
  border-bottom: 2px solid ${colors.backgroundHover};
`

const Invoicing: Page = () => {
  const [query, { patch: patchQuery }] = useValues<Partial<ReportsQuery>>({})
  const [filter, { patch: patchFilter }] = useValues<Partial<Filter>>({})
  const [sortKeys, setSortKeys] = useState<SortKey[]>([
    SortKey.EXECUTIVE_GIVEN_NAME,
  ])

  const { data: pricingsData } = useListPricingsQuery()

  // Auto-fill default pricings
  useEffect(() => {
    if (pricingsData?.list.items.length) {
      if (typeof query.defaultPricing === 'undefined') {
        patchQuery({
          defaultPricing:
            pricingsData.list.items.find(
              ({ ref }) => ref === DEFAULT_DEFAULT_PRICING_REF,
            ) || null,
        })
      }
    }
  }, [pricingsData, query, patchQuery])

  return (
    <Outer>
      <Sidebar>
        <Header>
          <PageHeader>{'Invoicing'}</PageHeader>
        </Header>
        <QueryEditor query={query} onChange={patchQuery} />
        <SortEditor value={sortKeys} onChangeValue={setSortKeys} />
        <FilterEditor value={filter} onPatch={patchFilter} />
        <Spacer />
      </Sidebar>
      {isValidQuery(query) && (
        <List query={query} sortKeys={sortKeys} filter={filter} />
      )}
    </Outer>
  )
}

Invoicing.path = '/invoicing'
Invoicing.navTitle = 'Client Invoicing'
Invoicing.Icon = Icon
Invoicing.requiredOperationIds = ['Query.invoicingReportsList']

export default Invoicing
