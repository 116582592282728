import { DatePicker } from '@atlaskit/datetime-picker'
import Select, { OptionType } from '@atlaskit/select'
import { DateTime } from 'luxon'
import React, { ComponentProps, useCallback, useMemo } from 'react'
import styled from 'styled-components'

import CitySelect from '../../../components/CitySelect'
import IndustriesSelect from '../../../components/IndustriesSelect'
import { LoadingSpinner } from '../../../components/Spinner'
import { ToggleStateless as Toggle } from '../../../components/Toggle'
import { Field, FieldsRow as Row } from '../../../components/form'
import { LanguagesSelect } from '../../../components/languages/select'
import CategorySelect from '../../../components/users/CategorySelect'
import FacetSelect from '../../../components/users/FacetSelect'
import { SingleUserSelect } from '../../../components/users/Select'
import {
  useUpdateUserMutation,
  UserPronoun,
  UpdateUserInput,
  UserCategory,
  FullUserFragment,
  namedOperations,
  UserFacet,
  isExecutivable,
} from '../../../graphql'
import { deepCleanTypename } from '../../../lib/utils'

const LocaleRow = styled(Row)`
  & > div {
    flex: 2;
  }

  & > div:first-child {
    flex: 1;
  }
`

const BirthdayRow = styled(Row)`
  & > div:first-child {
    max-width: 130px;
  }

  & > div:last-child {
    max-width: 70px;
  }
`

interface Props extends ComponentProps<'div'> {
  user: FullUserFragment
}

const BIRTHDAY_MONTHS: OptionType[] = []
const BIRTHDAY_DAYS: OptionType[] = []

const getMonthOption = (month: number): OptionType => ({
  label: DateTime.local(2020, month).toFormat('LLLL'),
  value: month,
})
const getDayOption = (day: number): OptionType => ({
  label: String(day),
  value: day,
})

// Build month options
for (let month = 1; month <= 12; month++) {
  BIRTHDAY_MONTHS.push(getMonthOption(month))
}

// Build day options
for (let day = 1; day <= 31; day++) {
  BIRTHDAY_DAYS.push(getDayOption(day))
}

const PRONOUN_OPTIONS = [
  {
    value: UserPronoun.THEY,
    label: 'They, them, their, theirs',
  },
  {
    value: UserPronoun.SHE,
    label: 'She, her, her, hers',
  },
  {
    value: UserPronoun.HE,
    label: 'He, him, his, his',
  },
]

const UserInfo = ({ user }: Props) => {
  const [updateUser, { loading }] = useUpdateUserMutation({
    refetchQueries: [namedOperations.Query.GetUserFieldsOptions],
  })

  const onSubmitLanguages = useCallback(
    (languages: string[]) =>
      updateUser({
        variables: {
          userId: user.id,
          input: {
            languages,
          },
        },
      }),
    [user.id, updateUser],
  )

  const [
    onSubmitPronoun,
    onSubmitBirthday,
    onSubmitCategory,
    onSubmitCityPlaceId,
    onSubmitFacet,
    onSubmitIsSaas,
    onSubmitStartDate,
    onSubmitIndustries,
    onSubmitPrincipalUserId,
  ] = useMemo(
    () =>
      (
        [
          'pronoun',
          'birthday',
          'category',
          'cityPlaceId',
          'facet',
          'isSaas',
          'startDate',
          'industries',
          'principalUserId',
        ] as (keyof UpdateUserInput)[]
      ).map(
        (fieldName) =>
          (
            patch:
              | undefined
              | null
              | { value: UpdateUserInput[typeof fieldName] }
              | UpdateUserInput[typeof fieldName],
          ) => {
            // don't submit empty startDate, ignore it and open the date picker
            if (fieldName === 'startDate' && !patch) return

            updateUser({
              variables: {
                userId: user.id,
                input: {
                  [fieldName]:
                    patch != null &&
                    typeof patch === 'object' &&
                    'value' in patch
                      ? patch.value
                      : patch ?? null,
                },
              },
            })
          },
      ),
    [updateUser, user.id],
  )

  const onSubmitBirthdayMonth = useCallback(
    ({ value: month }: { value: number; label: string }) => {
      onSubmitBirthday(
        deepCleanTypename({
          ...user.birthday,
          month,
        }),
      )
    },
    [onSubmitBirthday, user.birthday],
  )

  const onSubmitBirthdayDay = useCallback(
    ({ value: day }: { value: number; label: string }) => {
      onSubmitBirthday(
        deepCleanTypename({
          ...user.birthday,
          day,
        }),
      )
    },
    [onSubmitBirthday, user.birthday],
  )

  return (
    <>
      <Field
        label={
          user.acceptedPptcAt
            ? `Accepted privacy policy and terms & conditions on ${new Date(
                user.acceptedPptcAt,
              ).toLocaleDateString('en-us', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}`
            : 'Has not accepted privacy policy and terms & conditions yet'
        }
      >
        {false}
      </Field>

      <Row>
        <Field label={'Category'}>
          <CategorySelect
            value={user.category}
            // @ts-ignore
            onChange={onSubmitCategory}
          />
        </Field>

        {user.category &&
        [UserCategory.EXECUTIVE, UserCategory.ASSISTANT].includes(
          user.category,
        ) ? (
          <Field label={'Is SaaS?'}>
            <Toggle
              size={'large'}
              isChecked={!!user.isSaas}
              onChange={() => onSubmitIsSaas(!user.isSaas)}
            />
          </Field>
        ) : (
          <div />
        )}
      </Row>

      {isExecutivable(user) && (
        <Row>
          <Field label={'Facet'}>
            <FacetSelect
              value={user.facet}
              // @ts-ignore
              onChange={onSubmitFacet}
            />
          </Field>
          {[UserFacet.TEAM_MEMBER, UserFacet.SIGNIFICANT_OTHER].includes(
            user.facet as UserFacet,
          ) ? (
            <Field
              label={
                user.facet === UserFacet.TEAM_MEMBER
                  ? 'Team Principal'
                  : 'Significant other of'
              }
            >
              <SingleUserSelect
                value={user.principalUser}
                categories={[user.category || UserCategory.EXECUTIVE]}
                isArchived={null}
                isClearable
                onChange={(user: null | { id: string }) =>
                  onSubmitPrincipalUserId(user?.id || null)
                }
                validationState={!user.principalUser ? 'error' : undefined}
              />
            </Field>
          ) : (
            <div />
          )}
        </Row>
      )}

      <Row>
        <Field label={'Pronouns'}>
          <Select
            value={
              user.pronoun
                ? PRONOUN_OPTIONS.find(({ value }) => value === user.pronoun)
                : undefined
            }
            isSearchable={false}
            isClearable={false}
            options={PRONOUN_OPTIONS}
            isMulti={false}
            // @ts-ignore
            onChange={onSubmitPronoun}
          />
        </Field>

        <Field label={'Birthday'}>
          <BirthdayRow>
            <Select
              value={
                user.birthday?.month
                  ? getMonthOption(user.birthday.month)
                  : undefined
              }
              isSearchable
              isClearable={false}
              options={BIRTHDAY_MONTHS}
              isMulti={false}
              placeholder={'Month'}
              // @ts-ignore
              onChange={onSubmitBirthdayMonth}
            />
            <Select
              value={
                user.birthday?.day ? getDayOption(user.birthday.day) : undefined
              }
              isSearchable={false}
              isClearable={false}
              options={BIRTHDAY_DAYS}
              isMulti={false}
              placeholder={'Day'}
              // @ts-ignore
              onChange={onSubmitBirthdayDay}
            />
          </BirthdayRow>
        </Field>
      </Row>

      <LocaleRow>
        <Field label={'Start Date'}>
          <DatePicker
            value={user.startDate ?? undefined}
            onChange={onSubmitStartDate}
            isInvalid={!user.startDate}
          />
        </Field>
        <Field label={'Languages'}>
          <LanguagesSelect
            values={user.languages}
            // @ts-ignore
            onChangeValues={onSubmitLanguages}
          />
        </Field>
      </LocaleRow>

      <Field label={'City'}>
        <CitySelect
          value={user.city}
          onChangeValue={(city) => onSubmitCityPlaceId(city?.googleMapsPlaceId)}
        />
      </Field>

      {user.category === UserCategory.EXECUTIVE && (
        <Field label={'Industries'}>
          <IndustriesSelect
            isCreatable
            values={user.industries}
            onChangeValues={(values) => onSubmitIndustries(values)}
          />
        </Field>
      )}

      <LoadingSpinner show={loading} />
    </>
  )
}

export default UserInfo
