import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { v4 as uuid } from 'uuid'

import {
  useGetStandardDossierFoldersQuery,
  GetStandardDossierFoldersDocument,
  DossierFolderFragment,
  DossierSectionFragment,
  DossierFieldFragment,
} from '../../../graphql'

import { orderIndexAfter } from './utils'

const useDossier = () => {
  const client = useApolloClient()
  const { data, ...result } = useGetStandardDossierFoldersQuery()

  const createLocalFolder = useCallback(() => {
    const newFolder: Required<DossierFolderFragment> = {
      __typename: 'DossierFolder',
      id: uuid(),
      isPrivate: false,
      label: null,
      header: null,
      orderIndex: orderIndexAfter(data?.standardDossierFolders),
    }
    client.writeQuery({
      query: GetStandardDossierFoldersDocument,
      data: {
        ...data,
        standardDossierFolders: [
          ...(data?.standardDossierFolders || []),
          {
            ...newFolder,
            sections: [],
          },
        ],
      },
    })
    return newFolder
  }, [client, data])

  const createLocalSection = useCallback(
    (folderId: string) => {
      const folder = data?.standardDossierFolders.find(
        ({ id }) => id === folderId,
      )
      const newSection: Required<DossierSectionFragment> = {
        __typename: 'DossierSection',
        id: uuid(),
        isPrivate: false,
        label: null,
        emoji: null,
        defaultInputType: null,
        orderIndex: orderIndexAfter(folder?.sections),
      }
      client.writeQuery({
        query: GetStandardDossierFoldersDocument,
        data: {
          ...data,
          standardDossierFolders: [
            ...(data?.standardDossierFolders || []).filter(
              ({ id }) => id !== folderId,
            ),
            {
              ...folder,
              sections: (folder?.sections || []).concat({
                ...newSection,
                fields: [],
              }),
            },
          ],
        },
      })
      return newSection
    },
    [client, data],
  )

  const createLocalField = useCallback(
    (sectionId: string) => {
      const { folder = null, section = null } = (() => {
        for (const folder of data?.standardDossierFolders || []) {
          for (const section of folder.sections) {
            if (section.id === sectionId) {
              return { folder, section }
            }
          }
        }
        return {}
      })()

      const newField: Required<DossierFieldFragment> = {
        __typename: 'DossierField',
        id: uuid(),
        label: null,
        orderIndex: orderIndexAfter(section?.fields),
        input: null,
      }
      client.writeQuery({
        query: GetStandardDossierFoldersDocument,
        data: {
          ...data,
          standardDossierFolders: [
            ...(data?.standardDossierFolders || []).filter(
              ({ id }) => id !== folder?.id,
            ),
            {
              ...folder,
              sections: [
                ...(folder?.sections || []).filter(
                  ({ id }) => id !== section?.id,
                ),
                {
                  ...section,
                  fields: (section?.fields || []).concat(newField),
                },
              ],
            },
          ],
        },
      })
      return newField
    },
    [client, data],
  )

  const evictLocalFolder = useCallback(
    (folderId: string) => {
      client.cache.evict({
        id: `DossierFolder:${folderId}`,
      })
    },
    [client.cache],
  )

  const evictLocalSection = useCallback(
    (sectionId: string) => {
      client.cache.evict({
        id: `DossierSection:${sectionId}`,
      })
    },
    [client.cache],
  )

  const evictLocalField = useCallback(
    (fieldId: string) => {
      client.cache.evict({
        id: `DossierField:${fieldId}`,
      })
    },
    [client.cache],
  )

  return {
    data,
    folders: data?.standardDossierFolders,
    ...result,
    createLocalFolder,
    evictLocalFolder,
    createLocalSection,
    evictLocalSection,
    createLocalField,
    evictLocalField,
  }
}
export default useDossier
