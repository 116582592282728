import Icon from '@atlaskit/icon/glyph/discover'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { ErrorEmptyState } from '../../../components/EmptyState'
import { LoadingSpinner } from '../../../components/Spinner'
import { useListTopicTemplatesQuery } from '../../../graphql'
import { Page } from '../../types'

import List from './List'
import Template from './Template'

const Outer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  min-width: 600px;
  max-width: 1000px;
  height: 100%;
`

const Templates: Page = () => {
  const { data, loading, error } = useListTopicTemplatesQuery()
  const { url } = useRouteMatch()
  const {
    params: { templateId },
  } = useRouteMatch<{ templateId: string }>(`${url}/:templateId`) || {
    params: {},
  }

  if (error) {
    return <ErrorEmptyState error={error} />
  }

  if (loading || !data) {
    return <LoadingSpinner />
  }

  return (
    <Outer>
      <List templates={data.list.items} selectedTemplateId={templateId} />
      <Template
        template={
          templateId
            ? data.list.items.find(({ id }) => id === templateId)
            : undefined
        }
      />
    </Outer>
  )
}

Templates.path = '/templates'
Templates.navTitle = 'Templates'
Templates.Icon = Icon
Templates.requiredOperationIds = ['Query.topicTemplatesList']

export default Templates
