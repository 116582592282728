import Avatar, { AvatarItem } from '@atlaskit/avatar'
import React, { useMemo } from 'react'

import { MatchingApplicationFragment } from '../../../../../graphql/generated/hooks'
import { getAssistantCountryRateCurrency } from '../utils'

import { Outer, Motivation, SubmitedDate } from './styled'

interface MatchingApplicationProps {
  matchingId: string
  application: MatchingApplicationFragment
}

const MatchingApplication = ({ application }: MatchingApplicationProps) => {
  const { assistant, at, motivation } = application

  const secondaryText = useMemo(() => {
    return getAssistantCountryRateCurrency(
      assistant.city?.address?.countryCode,
      assistant.currency,
      assistant.hourlyRate,
    )
  }, [assistant])

  return (
    <Outer>
      <AvatarItem
        avatar={<Avatar src={assistant.profile.avatarUrl!} />}
        primaryText={assistant.profile.displayName}
        href={`/users/${assistant.id}`}
        target={'_blank'}
        secondaryText={secondaryText}
      />
      <SubmitedDate dateTime={at} noTime />
      <Motivation text={motivation} withQuote />
    </Outer>
  )
}

export default MatchingApplication
