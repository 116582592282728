import React, { useCallback } from 'react'

import UserAvatar from '../../../components/users/Avatar'
import UserCell from '../../../components/users/Cell'
import {
  isAssistantable,
  UpdateOrientationInput,
  Userable_InOrientationFragment,
  useUpdateOrientationMutation,
} from '../../../graphql'

import StepToggle from './StepToggle'
import { Row, AvatarCell, SquareCell } from './styled'

type Props = {
  user: Userable_InOrientationFragment
}

const UserRow = ({ user }: Props) => {
  const [_updateOrientation] = useUpdateOrientationMutation()

  const updateOrientation = useCallback(
    (patch: Omit<UpdateOrientationInput, 'userIds'>) => {
      _updateOrientation({
        variables: {
          input: {
            userIds: [user.id],
            ...patch,
          },
        },
      })
    },
    [_updateOrientation, user.id],
  )

  const exitOrientation = useCallback(
    (markAsComplete: boolean) => updateOrientation({ markAsComplete }),
    [updateOrientation],
  )
  const grantAppAccess = useCallback(
    (grantAppAccess: boolean) => updateOrientation({ grantAppAccess }),
    [updateOrientation],
  )

  if (!isAssistantable(user)) {
    return null
  }

  return (
    <Row withHover>
      <AvatarCell>
        <UserCell user={user} />
      </AvatarCell>

      <SquareCell>
        {!!user.dsm && <UserAvatar user={user.dsm} size={'small'} />}
      </SquareCell>
      <SquareCell>
        <StepToggle isCompleted={user.orientation?.hasSeenIntro} />
      </SquareCell>
      <SquareCell>
        <StepToggle isCompleted={user.orientation?.hasEnabledGoogle2Fa} />
      </SquareCell>
      <SquareCell>
        <StepToggle isCompleted={user.orientation?.hasJoinedSlack} />
      </SquareCell>
      <SquareCell>
        <StepToggle isCompleted={user.orientation?.hasCompletedSetup} />
      </SquareCell>
      <SquareCell>
        <StepToggle isCompleted={user.orientation?.hasFilledProfile} />
      </SquareCell>
      <SquareCell>
        <StepToggle
          isCompleted={user.orientation?.hasAppAccess}
          onComplete={grantAppAccess}
          confirmToggle
          tooltip={'Allow to access the full app'}
        />
      </SquareCell>
      <SquareCell>
        <StepToggle
          isCompleted={user.orientation?.isCompleted}
          onComplete={exitOrientation}
          confirmToggle
          tooltip={'Allow to access the app and remove from this screen'}
        />
      </SquareCell>
      {/* <HoverSquareCell>
        <MoreDropdownMenu>
          <DropdownItemGroup>
            <DropdownItem
              onClick={exitOrientation}
              isDisabled={!!user.orientation?.isCompleted}
            >{'Exit orientation'}
            </DropdownItem>
          </DropdownItemGroup>
        </MoreDropdownMenu>
      </HoverSquareCell> */}
    </Row>
  )
}

export default UserRow
