import DraftIcon from '@atlaskit/icon/glyph/billing'
import SendIcon from '@atlaskit/icon/glyph/send'
import VoidIcon from '@atlaskit/icon/glyph/trash'
import ProgressBar from '@atlaskit/progress-bar'
import Tabs, { Tab, TabList } from '@atlaskit/tabs'
import { SelectedType } from '@atlaskit/tabs/dist/types/types'
import groupBy from 'lodash/groupBy'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
  VariableSizeList as List,
  VariableSizeListProps as ListProps,
} from 'react-window'

import {
  MoreDropdownMenu,
  DropdownItemGroup,
  DropdownItem,
} from '../../../../components/DropdownMenu'
import { LoadingSpinner } from '../../../../components/Spinner'
import { Checkbox } from '../../../../components/form'
import {
  InvoicingReportFragment,
  useListInvoicingReportsQuery,
} from '../../../../graphql'
import useValues from '../../../../lib/useValues'
import { formatAmount, formatHours } from '../../Contractors/utils'
import { PricingDrawer } from '../../Pricings/Pricing'
import {
  getReportsSummaries,
  SortKey,
  ReportsQuery,
  ReportSummary,
  InvoiceEdit,
  getReportTotals,
  Filter,
  mapStripeInformationToInvoicingReport,
  filterReports,
  getReportSection,
  SECTIONS,
  getSortedReports,
} from '../utils'

import InvoiceEditor from './InvoiceEditor'
import ReportRow from './ReportRow'
import Totals from './Totals'
import WarningDialog from './WarningDialog'
import exportToCsv from './exportToCsv'
import getStripeCustomerInvoicesVariables from './getStripeCustomerInvoicesVariables'
import {
  ListOuter,
  ListInner,
  SectionTitle,
  HoursCell,
  AmountCell,
  MoreMenuCell,
  PaymentStatusCell,
  ExceptionCell,
  StartDateCell,
  InvoiceStatusCell,
  TableSection,
  CheckboxOuter,
  ProgressBarContainer,
  getInvoiceHeight,
  CsmCell,
} from './styled'
import usePublicPricings from './usePublicPricings'
import useReportInvoiceActions from './useReportInvoiceActions'
import useStripeCustomers from './useStripeCustomers'

type Props = {
  query: ReportsQuery
  sortKeys: SortKey[]
  filter: Filter
}

const InvoicesList = ({ query, sortKeys, filter }: Props) => {
  const [scrollOffset, setScrollOffset] = useState(0)

  const [editingReportId, setEditingReportId] = useState<
    ReportSummary['id'] | null
  >(null)
  const [invoiceEdits, { patch: patchInvoiceEdits, set: setInvoiceEdits }] =
    useValues<Record<InvoicingReportFragment['id'], InvoiceEdit | null>>({})
  const cancelEdit = useCallback(
    () => setEditingReportId(null),
    [setEditingReportId],
  )
  const patchEdit = useCallback(
    (reportId: string, patch: Partial<InvoiceEdit>) => {
      setInvoiceEdits((edits) => ({
        ...edits,
        [reportId]: {
          ...edits[reportId],
          ...patch,
        },
      }))
    },
    [setInvoiceEdits],
  )
  const saveEdit = useCallback(
    (edit: InvoiceEdit | null) => {
      if (!editingReportId) return

      patchInvoiceEdits(editingReportId, edit)
      setEditingReportId(null)
    },
    [editingReportId, patchInvoiceEdits],
  )

  const { data } = useListInvoicingReportsQuery({
    variables: {
      from: query.from,
      to: query.to,
      invoicedPricingDate: query.from,
    },
  })
  const [isBatchActionLoading, setIsBatchActionLoading] =
    useState<boolean>(false)

  const billingVariables = useMemo(
    () => getStripeCustomerInvoicesVariables(query.to),
    [query],
  )

  const [{ createInvoice, payAndSendInvoice, voidInvoice }] =
    useReportInvoiceActions(query, billingVariables)

  const createMultipleStripeInvoice = useCallback(
    async (reports: ReportSummary[]) => {
      setIsBatchActionLoading(true)
      const failedReports: (ReportSummary | null | undefined)[] = []

      for (const report of reports) {
        if (invoiceEdits[report.id]?.isExcluded) continue

        await createInvoice(report, invoiceEdits[report.id]).catch((err) => {
          console.error(err)
          failedReports.push(report)
        })
      }
      setFailedBatchActionUsers(failedReports)
      setIsBatchActionLoading(false)
    },
    [invoiceEdits, createInvoice],
  )

  const payAndSendMultipleStripeInvoice = useCallback(
    async (reports: ReportSummary[]) => {
      setIsBatchActionLoading(true)
      const failedReports: (ReportSummary | null | undefined)[] = []

      for (const report of reports) {
        if (invoiceEdits[report.id]?.isExcluded) continue

        await payAndSendInvoice(report, invoiceEdits[report.id]).catch(
          (err) => {
            console.error(err)
            failedReports.push(report)
          },
        )
      }

      setFailedBatchActionUsers(failedReports)
      setIsBatchActionLoading(false)
    },
    [invoiceEdits, payAndSendInvoice],
  )

  const voidMultipleStripeInvoice = useCallback(
    async (reports: ReportSummary[]) => {
      setIsBatchActionLoading(true)
      const failedReports: (ReportSummary | null | undefined)[] = []

      for (const report of reports) {
        if (invoiceEdits[report.id]?.isExcluded) continue

        await voidInvoice(report).catch((err) => {
          console.error(err)
          failedReports.push(report)
        })
      }
      setFailedBatchActionUsers(failedReports)
      setIsBatchActionLoading(false)
    },
    [invoiceEdits, voidInvoice],
  )

  const publicPricings = usePublicPricings()
  const rawSummaries: ReportSummary[] = useMemo(
    () =>
      getReportsSummaries({
        query,
        data: data?.list.items || [],
        edits: invoiceEdits,
        patchEdit,
        publicPricings: publicPricings || [],
      }),
    [invoiceEdits, patchEdit, query, data, publicPricings],
  )

  const {
    progress: stripeInfoLoadingProgress,
    loading: stripeInfoLoading,
    items: stripeUsers,
  } = useStripeCustomers(
    rawSummaries.map(({ invoicedUser }) => invoicedUser.id).sort(),
    query,
  )

  const [selectedSection, setSelectedSection] = useState<
    (typeof SECTIONS)[number]
  >(SECTIONS[0])

  const { sections, all: allReports } = useMemo(() => {
    const summariesWithStripeUserInfo =
      data &&
      stripeUsers.length &&
      !isBatchActionLoading &&
      rawSummaries.map((report) =>
        mapStripeInformationToInvoicingReport(report, stripeUsers),
      )

    if (!summariesWithStripeUserInfo) return { sections: {}, all: [] }

    const reportSummariesFiltered = summariesWithStripeUserInfo.filter(
      (report) => filterReports(filter, report),
    )

    const reportSummariesFilteredAndSorted = getSortedReports(
      reportSummariesFiltered,
      sortKeys,
    )
    // TODO
    // .map((report) => {
    //   report.packages = report.packages.filter(makePackageFilter(filter))
    //   return report
    // })

    return {
      sections: groupBy(reportSummariesFilteredAndSorted, getReportSection),
      all: reportSummariesFilteredAndSorted,
    }
  }, [data, stripeUsers, isBatchActionLoading, rawSummaries, sortKeys, filter])

  const selectedSectionReports = useMemo(
    () => sections[selectedSection.value] || [],
    [sections, selectedSection],
  )

  const [failedBatchActionUsers, setFailedBatchActionUsers] = useState<
    (ReportSummary | null | undefined)[]
  >([])

  const onTabChange = useCallback(
    (index: SelectedType) => setSelectedSection(SECTIONS[index]),
    [setSelectedSection],
  )

  const summary = selectedSectionReports.reduce(
    (summary, report) => {
      const { id } = report
      if (!invoiceEdits[id]?.isExcluded) {
        const { hours, amount } = getReportTotals(
          report,
          invoiceEdits[report.id],
        )
        summary.count++
        summary.hours += hours
        summary.amount += amount
      }

      return summary
    },
    { count: 0, amount: 0, hours: 0 },
  )

  const getItemSize: ListProps['itemSize'] = useCallback(
    (index) =>
      getInvoiceHeight(
        selectedSectionReports[index],
        invoiceEdits[selectedSectionReports[index].id],
      ),
    [invoiceEdits, selectedSectionReports],
  )

  const renderReport: ListProps['children'] = useCallback(
    ({ index, style }) => (
      <ReportRow
        key={selectedSectionReports[index].id}
        style={style}
        isOdd={Boolean(index % 2)}
        query={query}
        report={selectedSectionReports[index]}
        edit={invoiceEdits[selectedSectionReports[index].id]}
        onPatchEdit={patchEdit}
        onEditInvoice={setEditingReportId}
      />
    ),
    [selectedSectionReports, query, invoiceEdits, patchEdit],
  )

  // 💩 bump Auto-sizer key to force re-compute of line height
  const autoSizerRef = useRef<number>(0)
  const autoSizerKey = useMemo(() => {
    autoSizerRef.current = autoSizerRef.current + 1
    return autoSizerRef.current
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderReport]) // KEEP `renderReport` as dependency!!

  return (
    <ListOuter>
      <div>
        <Tabs
          onChange={onTabChange}
          selected={SECTIONS.indexOf(selectedSection)}
          id={'sections'}
        >
          <TabList>
            {SECTIONS.map(({ value, label }) => {
              return (
                <Tab key={value}>{`${label} (${
                  sections[value]?.length || 0
                })`}</Tab>
              )
            })}
            <Totals reports={allReports} edits={invoiceEdits} query={query} />
          </TabList>
        </Tabs>
      </div>

      {stripeInfoLoading && (
        <ProgressBarContainer>
          <ProgressBar
            appearance={stripeInfoLoading ? 'default' : 'success'}
            value={stripeInfoLoadingProgress}
          />
        </ProgressBarContainer>
      )}

      <TableSection style={{ top: 0 }}>
        <SectionTitle>
          {selectedSection.label}
          {` (${
            summary.count !== selectedSectionReports.length
              ? `${summary.count}/${selectedSectionReports.length}`
              : selectedSectionReports.length
          })`}
        </SectionTitle>
        <CsmCell>{'CSM'}</CsmCell>
        <StartDateCell>{'Start date'}</StartDateCell>
        <ExceptionCell>{'Exception'}</ExceptionCell>
        <HoursCell bold>{formatHours(summary.hours)}</HoursCell>
        <AmountCell bold>{formatAmount(summary.amount, 'USD')}</AmountCell>
        <PaymentStatusCell>{'Payment'}</PaymentStatusCell>
        <InvoiceStatusCell>{'Invoice'}</InvoiceStatusCell>
        <CheckboxOuter>
          {
            <Checkbox
              isChecked={summary.count > 0}
              isIndeterminate={summary.count !== selectedSectionReports.length}
              onChangeValue={(checked) => {
                for (const report of selectedSectionReports) {
                  patchEdit(report.id, { isExcluded: !checked })
                }
              }}
            />
          }
        </CheckboxOuter>
        <MoreMenuCell>
          <MoreDropdownMenu>
            <DropdownItemGroup>
              <DropdownItem
                isDisabled={!summary.count}
                onClick={() => {
                  const filteredReports = selectedSectionReports.filter(
                    (r) => !invoiceEdits[r.id]?.isExcluded,
                  )
                  exportToCsv(filteredReports, invoiceEdits, query)
                }}
              >
                {'Export to CSV'}
              </DropdownItem>
            </DropdownItemGroup>
            <DropdownItemGroup title={'Invoice'}>
              <DropdownItem
                isDisabled={!summary.count}
                elemBefore={<DraftIcon label={''} size={'small'} />}
                onClick={() =>
                  createMultipleStripeInvoice(selectedSectionReports)
                }
              >
                {'Create draft invoice'}
              </DropdownItem>
              <DropdownItem
                isDisabled={!summary.count}
                elemBefore={<SendIcon label={''} size={'small'} />}
                onClick={() =>
                  payAndSendMultipleStripeInvoice(selectedSectionReports)
                }
              >
                {'Charge Cards/Send invoices'}
              </DropdownItem>
              <DropdownItem
                isDisabled={!summary.count}
                elemBefore={<VoidIcon label={''} size={'small'} />}
                onClick={() =>
                  voidMultipleStripeInvoice(selectedSectionReports)
                }
              >
                {'Void/Delete draft invoice'}
              </DropdownItem>
            </DropdownItemGroup>
          </MoreDropdownMenu>
        </MoreMenuCell>
      </TableSection>

      <ListInner>
        <AutoSizer
          key={autoSizerKey}
          children={(size) => {
            return (
              <List
                initialScrollOffset={scrollOffset}
                onScroll={({ scrollOffset }) => setScrollOffset(scrollOffset)}
                key={JSON.stringify({ editingReportId, filter, sortKeys })}
                {...size}
                itemCount={selectedSectionReports.length}
                itemSize={getItemSize}
                children={renderReport}
              />
            )
          }}
        />
      </ListInner>

      <LoadingSpinner show={stripeInfoLoading || isBatchActionLoading} />

      <WarningDialog
        isOpen={!isBatchActionLoading && failedBatchActionUsers?.length > 0}
        reports={failedBatchActionUsers}
        onClose={() => setFailedBatchActionUsers([])}
      />

      <PricingDrawer />

      <InvoiceEditor
        key={editingReportId}
        isOpen={!!editingReportId}
        onClose={cancelEdit}
        value={editingReportId ? invoiceEdits[editingReportId] : null}
        onChangeValue={saveEdit}
      />
    </ListOuter>
  )
}

export default InvoicesList
