import PageHeader from '@atlaskit/page-header'
import { DateTime } from 'luxon'
import React from 'react'
import styled from 'styled-components'

import ItemWithNavlink from '../../../components/list/ItemWithNavlink'
import ListContainer from '../../../components/list/ListContainer'
import ListOuter from '../../../components/list/ListOuter'
import PageHeaderOuter from '../../../components/list/PageHeaderOuter'
import { BugReportFragment } from '../../../graphql'

const Outer = styled(ListContainer)`
  min-width: 280px;
  max-width: 400px;
`

const trim = (text: string, length: number): string => {
  if (text.length > length) {
    return text.substr(0, length - 1) + '…'
  }
  return text
}

interface Props {
  bugReports: BugReportFragment[]
  selectedBugReportId?: BugReportFragment['id']
}

const BugReportsList = ({ bugReports, selectedBugReportId }: Props) => (
  <Outer>
    <PageHeaderOuter>
      <PageHeader>{'Bug Reports'}</PageHeader>
    </PageHeaderOuter>
    <ListOuter>
      {bugReports.map((bugReport) => (
        <ItemWithNavlink
          key={bugReport.id}
          href={`/bugReports/${bugReport.id}`}
          isSelected={bugReport.id === selectedBugReportId}
          text={bugReport.message ? trim(bugReport.message, 30) : 'No message'}
          subText={DateTime.fromJSDate(
            new Date(bugReport.createdAt),
          ).toLocaleString(DateTime.DATETIME_MED)}
        />
      ))}
    </ListOuter>
  </Outer>
)

export default BugReportsList
