import { colors, typography } from '@atlaskit/theme'
import styled from 'styled-components'

export const Outer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  ${typography.h200()}
  padding: 8px 16px;
  border-top: 2px solid ${colors.backgroundHover};
`
