import { LoadingButton as Button } from '@atlaskit/button'
import { ErrorMessage, ValidMessage } from '@atlaskit/form'
import PageHeader from '@atlaskit/page-header'
import React, { useCallback, useEffect, useState } from 'react'
import { RouteChildrenProps } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { Field, TextField } from '../../../../components/form'
import { ChatType, useExportChatToStudioMutation } from '../../../../graphql'
import useSwitch from '../../../../lib/useSwitch'
import { isUuid } from '../../../../lib/utils'

import { Inner, Outer, Row } from './styled'

const ExportChat = ({ match }: RouteChildrenProps<{ topicId?: string }>) => {
  const [topicId, setTopicId] = useState(match?.params.topicId || '')
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [displaySuccessMessage, showSuccessMessage, hideSuccessMessage] =
    useSwitch(false)

  const [exportChatToStudio, { loading }] = useExportChatToStudioMutation()

  // clear success message after delay
  useEffect(() => {
    if (displaySuccessMessage) {
      const timeoutId = setTimeout(hideSuccessMessage, 3000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [displaySuccessMessage, hideSuccessMessage])

  const onSubmit = useCallback(
    async (chatType: ChatType) => {
      setErrorMessage(undefined)

      if (!isUuid(topicId)) {
        setErrorMessage('Topic ID is not a valid UUID')
        return
      }

      try {
        const result = await exportChatToStudio({
          variables: {
            input: {
              mutationId: uuid(),
              topicId,
              chatType,
            },
          },
        })

        if (result.data?.exportChatToStudio.isSuccess) {
          showSuccessMessage()
        } else {
          setErrorMessage('Error exporting chat to Studio')
        }
      } catch (error) {
        setErrorMessage(
          (error as Error)?.message || 'Error exporting chat to Studio',
        )
      }
    },
    [exportChatToStudio, showSuccessMessage, topicId],
  )

  return (
    <Outer>
      <PageHeader>{'Export to Axflow Studio'}</PageHeader>
      <Inner>
        <Field label={'Topic ID'} isRequired>
          <TextField
            autoFocus={true}
            value={topicId || ''}
            onChange={({ currentTarget: { value } }) => setTopicId(value)}
          />
        </Field>
        <Row>
          <Button
            isLoading={loading}
            appearance={'primary'}
            onClick={() => onSubmit(ChatType.SHARED)}
          >
            {'Export Shared Chat'}
          </Button>
          <Button
            isLoading={loading}
            appearance={'primary'}
            onClick={() => onSubmit(ChatType.ASSISTANT)}
          >
            {'Export Assistant Chat'}
          </Button>
        </Row>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {displaySuccessMessage && (
          <ValidMessage>{'Chat exported successfully'}</ValidMessage>
        )}
      </Inner>
    </Outer>
  )
}

export default ExportChat
