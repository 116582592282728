import AkColorPicker from '@atlaskit/color-picker'
import React, { ComponentProps } from 'react'

const colorHexCodeToOption = (
  colorCode: string,
): { label: string; value: string } => ({ label: colorCode, value: colorCode })

type Props = Omit<ComponentProps<typeof AkColorPicker>, 'palette'> & {
  palette: string[]
}

const ColorPicker = ({ palette, ...props }: Props) => (
  <AkColorPicker {...props} palette={palette.map(colorHexCodeToOption)} />
)

export default ColorPicker
