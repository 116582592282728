import React from 'react'

import { LoadingSpinner } from '../../../../components/Spinner'
import { Field, FieldsRow } from '../../../../components/form'
import {
  Matching_ForTransitionFullFragment,
  useUpdateTransitionMatchingStatusMutation,
} from '../../../../graphql'
import MatchingProfile from '../../Matchings/Matching/Profile'
import { Bundled } from '../utils'

import MatchingStatusSelect from './components/MatchingStatusSelect'
import {
  ActionsHeader,
  InfoNotice,
  InfoNoticeText,
  LimitedWidth,
} from './styled'

type Props = {
  matching: Bundled<Matching_ForTransitionFullFragment>
  noProfile?: boolean
}

const Matching = ({ matching, noProfile }: Props) => {
  const [updateStatus, updateStatusMutation] =
    useUpdateTransitionMatchingStatusMutation()

  return (
    <>
      <div>
        <Field label={"Executive's current matching status"}>
          <FieldsRow>
            <LimitedWidth>
              <MatchingStatusSelect
                value={matching.transition!.matchingStatus}
                onChangeValue={(matchingStatus) =>
                  matchingStatus &&
                  updateStatus({
                    variables: {
                      input: {
                        matchingId: matching.id,
                        matchingStatus,
                      },
                    },
                  })
                }
                isDisabled={updateStatusMutation.loading}
                isLoading={updateStatusMutation.loading}
              />
            </LimitedWidth>
            <InfoNotice
              type={'info'}
              title={'How to use this?'}
              noTopUnset
              placement={'top'}
            >
              <InfoNoticeText>
                {
                  'When the status is set to "Ready to match", Cockpit will (1) ping the Ops lead in Slack, (2) set the exec\'s lead card\' next step as "Match" in Airtable, and (3) publish the exec\'s profile to the matching catalog.'
                }
                <br />
                <br />
                {
                  '"Match proposed" and "Match confirmed" statuses are automatically updated from the matching machine and are not meant to be directly edited from here.'
                }
                <br />
                <br />
                {
                  'When a match is DECLINED by a Double, Cockpit will auto-update the status to "Ready to match" and set the exec\'s lead card\'s next step back to "Match".'
                }
              </InfoNoticeText>
            </InfoNotice>
          </FieldsRow>
        </Field>

        {!noProfile && (
          <>
            <ActionsHeader>{'Matching Profile'}</ActionsHeader>
            <InfoNotice
              type={'info'}
              title={'Why is this here?'}
              noTopUnset
              placement={'top'}
            >
              <InfoNoticeText>
                {
                  'Before moving the exec to "Ready to Match" status, the CS lead should review this matching profile content, and edit it as needed to reflect the executive\'s most recent preferences.'
                }
              </InfoNoticeText>
            </InfoNotice>

            {!!matching.profiles?.[0] && (
              <MatchingProfile profile={matching.profiles[0]} />
            )}
          </>
        )}

        {updateStatusMutation.loading ? <LoadingSpinner show /> : null}
      </div>
    </>
  )
}

export default Matching
