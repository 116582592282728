import { showSaveFilePicker as showSaveFilePickerPolyfill } from 'native-file-system-adapter-ts'

type Options = {
  filename: string
  type: {
    mime: string
    extension: string
    description: string
  }
}

export type WritableFileStream = FileSystemWritableFileStream

export const createWritableFileStream = async (
  options: Options,
): Promise<WritableFileStream> => {
  const showSaveFilePicker =
    window.showSaveFilePicker || showSaveFilePickerPolyfill

  // create a new handle
  const newHandle = await showSaveFilePicker({
    suggestedName: options.filename,
    types: [
      {
        description: options.type.description,
        accept: { [options.type.mime]: [options.type.extension] },
      },
    ],
  })

  // create a FileSystemWritableFileStream to write to
  return await newHandle.createWritable()
}

export const createWritableCSVFileStream = (filename: string) =>
  createWritableFileStream({
    filename,
    type: {
      mime: 'text/csv',
      description: 'CSV file',
      extension: '.csv',
    },
  })

export const createWritableJpegFileStream = (filename: string) =>
  createWritableFileStream({
    filename,
    type: {
      mime: 'image/jpeg',
      description: 'JPG image',
      extension: '.jpg',
    },
  })
