import { DateTime } from 'luxon'

interface GroupableItem {
  name: string
}

/**
 * Divide a string in path
 * eg: "Section / Page / Item"
 * will become ["Section", "Page", "Item"]
 */
function getPath(text: string): string[] {
  return text
    .split('/')
    .map((part) => part.trim())
    .filter(Boolean)
}

export function getSectionedName(fullName: string): {
  section: string | null
  name: string
} {
  const [section, ...rest] = getPath(fullName)

  if (!rest.length) {
    return {
      section: null,
      name: section,
    }
  }

  return {
    section,
    name: rest.join(' / '),
  }
}

type Group<Item extends GroupableItem> = {
  name: string | null
  items: Item[]
}

export function group<Item extends GroupableItem>(
  allItems: Item[],
): Group<Item>[] {
  const groups: Group<Item>[] = []

  for (const item of allItems) {
    const { section } = getSectionedName(item.name)
    let group = groups.find(({ name }) => name === section)
    if (!group) {
      group = { name: section, items: [] }
      groups.push(group)
    }
    group.items.push(item)
  }

  groups.sort(({ name: nameA }, { name: nameB }) => {
    if (!nameA) {
      return 1
    }
    if (!nameB) {
      return -1
    }
    return nameA < nameB ? -1 : 1
  })

  return groups
}

export const formatDateTime = (dt: string | Date): string => {
  return DateTime.fromJSDate(new Date(dt)).toLocaleString({
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
}
