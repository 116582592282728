import React, { ComponentProps } from 'react'

import { Select } from '../../../../components/form'
import { DiscoverCategory } from '../../../../graphql'

type Props = Omit<
  ComponentProps<typeof Select>,
  'onChangeValue' | 'options'
> & {
  onChangeValue?: (newValue: DiscoverCategory) => void
}

const CategorySelect = ({ value, ...props }: Props) => (
  <Select
    menuPlacement={'auto'}
    value={value ? { value } : undefined}
    creatable={false}
    isSearchable={false}
    isClearable={false}
    options={Object.values(DiscoverCategory).map((value) => ({ value }))}
    // @ts-expect-error BS
    getOptionLabel={({ value }) => value}
    // @ts-expect-error BS
    getOptionValue={({ value }) => value}
    isMulti={false}
    placeholder={'Category'}
    {...props}
  />
)

export default CategorySelect
