import Avatar from '@atlaskit/avatar'
import Lozenge from '@atlaskit/lozenge'
import PageHeader from '@atlaskit/page-header'
import { colors, typography } from '@atlaskit/theme'
import React from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import styled from 'styled-components'

import BooleanSelect from '../../components/BooleanSelect'
import Spinner from '../../components/Spinner'
import TimeZoneSelect from '../../components/TimeZoneSelect'
import { LanguageSelect } from '../../components/languages/select'
import ItemWithNavlink from '../../components/list/ItemWithNavlink'
import PageHeaderOuter from '../../components/list/PageHeaderOuter'
import { UserCategory } from '../../graphql'

import SearchField from './SearchField'
import useWhos, { Filters } from './useWhos'

const Outer = styled.div`
  background-color: ${colors.N20};
  border-right: solid ${colors.backgroundHover} 2px;
  display: flex;
  flex-direction: column;
  padding: 0;
  flex: 1 1 30%;
  min-width: 240px;
  max-width: 300px;
`

const ListOuter = styled.div`
  flex: 1;
`

const ListInner = styled(FixedSizeList)`
  padding: 8px 0;
`

const ItemOuter = styled.div`
  padding: 0 24px;
`

const Counter = styled.div`
  ${typography.h200()}
  background-color: ${colors.N20};
  padding: 8px 16px;
  margin: 0 24px;
  border-top: 2px solid ${colors.backgroundHover};
`

type Props = {
  filters: Filters
  onPatchFilters: (patch: Partial<Filters>) => void
}

const WhosList = ({ filters, onPatchFilters }: Props) => {
  const {
    users,
    loadingUsers,
    fetchMore,
    hasMore,
    errorUsers: error,
  } = useWhos(filters)
  const isLoadingInitial = users.length === 0 && loadingUsers
  const itemCount = isLoadingInitial
    ? 30
    : hasMore
    ? users.length + 1
    : users.length
  const isItemLoaded = (index: number): boolean =>
    !hasMore || index < users.length

  if (error) {
    return <Outer>{'Error: ' + error.message}</Outer>
  }

  return (
    <Outer>
      <PageHeaderOuter>
        <PageHeader
          bottomBar={
            <>
              <SearchField
                value={filters.search || {}}
                onChange={(search) => onPatchFilters({ search })}
              />
              <TimeZoneSelect
                isMulti
                value={filters.timeZones}
                // @ts-ignore
                onChangeValue={(timeZones) => onPatchFilters({ timeZones })}
                placeholder={'Time zone'}
              />
              <LanguageSelect
                placeholder={'Language'}
                isClearable
                value={filters.language}
                onChangeValue={(language) => onPatchFilters({ language })}
              />
              <BooleanSelect
                value={filters.hqOnly}
                onChangeValue={(value) => onPatchFilters({ hqOnly: !!value })}
                yesLabel={'HQ only'}
                noLabel={'Everyone'}
              />
            </>
          }
        >
          {'Who’s Who?'}
        </PageHeader>
      </PageHeaderOuter>
      <ListOuter>
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              // @ts-ignore
              loadMoreItems={fetchMore}
            >
              {({ onItemsRendered, ref }) => (
                <ListInner
                  itemCount={itemCount}
                  itemSize={40}
                  onItemsRendered={onItemsRendered}
                  height={height}
                  width={width}
                  ref={ref}
                >
                  {({ index, style }) => (
                    <ItemOuter style={style} key={users[index]?.id || ''}>
                      {isLoadingInitial || !isItemLoaded(index) ? (
                        <Spinner />
                      ) : (
                        <ItemWithNavlink
                          key={users[index].id}
                          href={`/whoswho/${users[index].id}`}
                          text={users[index].givenName}
                          subText={users[index].position}
                          before={() => (
                            <Avatar
                              src={users[index].avatarUrl!}
                              size={'small'}
                            />
                          )}
                          after={() =>
                            users[index].category === UserCategory.TEAM && (
                              <Lozenge appearance={'default'}>{'HQ'}</Lozenge>
                            )
                          }
                        />
                      )}
                    </ItemOuter>
                  )}
                </ListInner>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </ListOuter>
      <Counter>
        {users.length
          ? `${users.length}${hasMore ? '+' : ''} people`
          : 'Loading...'}
      </Counter>
    </Outer>
  )
}

export default WhosList
