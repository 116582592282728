import Avatar, { AvatarPropTypes, Skeleton } from '@atlaskit/avatar'
import React from 'react'

import { TeamFragment } from '../../graphql'

type Team = Pick<TeamFragment, 'name' | 'iconUrl'>

export interface Props extends Omit<AvatarPropTypes, 'url'> {
  team: Team
  skeleton?: boolean
}

const TeamAvatar = ({ team, skeleton, size = 'medium', ...props }: Props) => {
  skeleton = skeleton || !team

  if (skeleton) {
    return <Skeleton appearance={'circle'} size={size} />
  }

  return (
    <Avatar
      src={team.iconUrl || undefined}
      appearance={'circle'}
      size={size}
      name={team.name || ''}
      {...props}
    />
  )
}

export default TeamAvatar
