import { useApolloClient } from '@apollo/client'
import ArchiveIcon from '@atlaskit/icon/glyph/trash'
import { colors } from '@atlaskit/theme'
import { after, before, between } from '@withdouble/ordering'
import sortBy from 'lodash/sortBy'
import { ComponentProps, useCallback, useMemo } from 'react'
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
} from 'react-sortable-hoc'
import styled from 'styled-components'

import {
  MoreDropdownMenu,
  DropdownItemGroup,
  DropdownItem,
  DropdownItemCopyText,
} from '../../../../components/DropdownMenu'
import EmptyState, { ErrorEmptyState } from '../../../../components/EmptyState'
import PageHeader from '../../../../components/PageHeader'
import { LoadingSpinner } from '../../../../components/Spinner'
import Tabs from '../../../../components/Tabs'
import UserCell from '../../../../components/users/Cell'
import {
  MatchingProposalReply,
  useArchiveMatchingMutation,
  useGetMatchingByIdQuery,
  useUpsertMatchingProposalMutation,
} from '../../../../graphql'
import { notFalsy } from '../../../../lib/utils'
import AutomationJobsInline from '../../../autopilot/Jobs/ListInline'
import TransitionPanel from '../../Transitions/Panel'
import BookmarksBadge from '../components/BookmarksBadge'
import CatalogVisibilityBadge from '../components/CatalogVisibilityBadge'
import CompletedBadge from '../components/CompletedBadge'
import HighTouchBadge from '../components/HighTouchBadge'
import TeamInviteBadge from '../components/TeamInviteBadge'
import { PATH } from '../constants'

import Application from './Application'
import Configuration from './Configuration'
import MatchingProfile from './Profile'
import _Proposal from './Proposal'

const Outer = styled.div<ComponentProps<'div'>>`
  box-shadow: 0 0 0 2px ${colors.backgroundHover},
    0 0 10px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 2;
  flex-flow: column;
  align-items: stretch;
  padding: 0 24px;
  overflow: auto;
  min-width: 300px;
`

const TabContent = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
`

const Proposals = SortableContainer(TabContent)
const Proposal = SortableElement(_Proposal)

interface MatchingProps {
  matchingId: string
}

const Matching = ({ matchingId }: MatchingProps) => {
  const { cache: apolloCache } = useApolloClient()
  const { data, loading, error } = useGetMatchingByIdQuery({
    variables: {
      matchingId,
    },
  })

  const [archiveMatching, { loading: loadingArchiveMatching }] =
    useArchiveMatchingMutation({
      variables: {
        input: {
          matchingId,
        },
      },
    })

  const onArchiveMatching = useCallback(async () => {
    if (!window.confirm('Are you sure you want to archive this matching?')) {
      return
    }

    await archiveMatching()

    window.location.replace(PATH)
  }, [archiveMatching])

  const [upsertProposal] = useUpsertMatchingProposalMutation()

  const matching = data?.matching
  const canSendProposal =
    !!matching &&
    matching.proposals.filter(
      ({ sentAt, reply }) => sentAt && reply !== MatchingProposalReply.NO,
    ).length < matching.targetCandidatesCount

  const sortedProposals = useMemo(() => {
    return sortBy(
      matching?.proposals || [],
      // Declines at the bottom
      ({ reply }) => (reply === MatchingProposalReply.NO ? 1 : 0),
      // Accepts at the top
      ({ reply }) => (reply === MatchingProposalReply.YES ? 0 : 1),
      // Replied above not replied
      ({ reply }) => (reply ? 0 : 1),
      // Sent above queued
      ({ sentAt }) => (sentAt ? 0 : 1),
      // Then apply order index
      'orderIndex',
    )
  }, [matching?.proposals])

  const sortProposals: SortEndHandler = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) {
        return
      }

      const movedProposal = sortedProposals[oldIndex]
      let newOrderIndex: string

      if (newIndex === 0) {
        newOrderIndex = before(sortedProposals[0].orderIndex)
      } else if (newIndex >= sortedProposals.length - 1) {
        newOrderIndex = after(
          sortedProposals[sortedProposals.length - 1].orderIndex,
        )
      } else if (newIndex < oldIndex) {
        const low = sortedProposals[newIndex - 1].orderIndex
        const high = sortedProposals[newIndex].orderIndex
        newOrderIndex = low < high ? between(low, high) : before(high)
      } else {
        const low = sortedProposals[newIndex].orderIndex
        const high = sortedProposals[newIndex + 1].orderIndex
        newOrderIndex = low < high ? between(low, high) : after(low)
      }

      apolloCache.modify({
        id: apolloCache.identify(movedProposal),
        fields: {
          orderIndex() {
            return newOrderIndex
          },
        },
      })

      upsertProposal({
        variables: {
          input: {
            matchingId,
            assistantId: movedProposal.assistant.id,
            orderIndex: newOrderIndex,
          },
        },
      })
    },
    [sortedProposals, upsertProposal, matchingId, apolloCache],
  )

  return (
    <>
      <PageHeader
        actions={
          <>
            <MoreDropdownMenu>
              <DropdownItemGroup>
                <DropdownItemCopyText
                  isDisabled={!matching}
                  text={matching?.id || ''}
                >
                  {'Copy matching ID'}
                </DropdownItemCopyText>
                <DropdownItem
                  onClick={onArchiveMatching}
                  elemBefore={<ArchiveIcon size={'small'} label={''} />}
                  isDisabled={!matching || loadingArchiveMatching}
                >
                  {'Archive matching'}
                </DropdownItem>
              </DropdownItemGroup>
            </MoreDropdownMenu>
          </>
        }
        bottomBar={
          <>
            {!!matching && <HighTouchBadge matching={matching} />}
            {!!matching && <BookmarksBadge matching={matching} />}
            {!!matching && <CompletedBadge matching={matching} />}
            {!!matching && <CatalogVisibilityBadge matching={matching} />}
            {!!matching && <TeamInviteBadge matching={matching} />}
          </>
        }
      >
        {matching?.executive ? (
          <UserCell user={matching.executive} />
        ) : (
          matching?.profiles[0]?.name || 'No name'
        )}
      </PageHeader>

      {!!error && <ErrorEmptyState error={error} />}

      <Tabs
        isContentPersisted
        leftTabs={[
          {
            label: `Proposals (${matching?.proposals.length || 0})`,
            slug: 'proposals',
            content: (
              <Proposals onSortEnd={sortProposals} lockAxis={'y'} useDragHandle>
                {matching && sortedProposals.length ? (
                  sortedProposals.map((proposal, index) => (
                    <Proposal
                      key={proposal.id}
                      index={index}
                      matchingId={matching.id}
                      proposal={proposal}
                      canSend={canSendProposal}
                      canConfirm={!matching.confirmedAssistant}
                      isSortable={!proposal.sentAt}
                      disabled={!!proposal.sentAt}
                      collection={proposal.sentAt ? 'fixed' : 'sortable'}
                    />
                  ))
                ) : (
                  <EmptyState header={'No proposal yet'} emoji={'👤'} />
                )}
              </Proposals>
            ),
          },
          {
            label: `Applications (${matching?.applications.length || 0})`,
            slug: 'applications',
            content: (
              <TabContent>
                {matching?.applications.map((application) => (
                  <Application
                    key={application.id}
                    matchingId={matching.id}
                    application={application}
                  />
                ))}
              </TabContent>
            ),
          },
          ...(matching?.profiles || []).map((profile, index) => ({
            label:
              index > 0
                ? `#${index + 1}`
                : (matching?.profiles.length || 0) > 1
                ? 'Lead #1'
                : 'Lead profile',
            slug: 'profile-' + index,
            content: (
              <TabContent>
                <MatchingProfile
                  profile={profile}
                  withDisplayName
                  principalProfile={matching?.principalProfile}
                  teamInvite={matching?.teamInvite}
                />
              </TabContent>
            ),
          })),
          matching?.isTransition && {
            label: `Transition`,
            slug: 'transition',
            content: (
              <TabContent>
                <TransitionPanel isEmbedded />
              </TabContent>
            ),
          },
        ].filter(notFalsy)}
        rightTabs={[
          {
            label: '⚙️',
            slug: 'configuration',
            content: (
              <TabContent>
                {!!matching && <Configuration matching={matching} />}
              </TabContent>
            ),
          },
          {
            label: '🤖',
            slug: 'autopilot',
            content: (
              <TabContent>
                <AutomationJobsInline
                  filter={{
                    hasParams: {
                      matchingId: matching?.id,
                    },
                  }}
                />
              </TabContent>
            ),
          },
        ]}
      />

      <LoadingSpinner show={loading} />
    </>
  )
}

type MaybeMatchingProps = Partial<MatchingProps>

const MaybeMatching = ({ matchingId, ...rest }: MaybeMatchingProps) => (
  <Outer>
    {!!matchingId && (
      <Matching key={matchingId} matchingId={matchingId} {...rest} />
    )}
  </Outer>
)

export default MaybeMatching
