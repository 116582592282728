import { colors } from '@atlaskit/theme'
import styled, { css } from 'styled-components'

export const Outer = styled.div<{ $isExpanded: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  ${({ $isExpanded }) =>
    $isExpanded
      ? css`
          height: 100%;
          overflow: auto;
        `
      : css`
          height: auto;
        `};
`

export const ClearAllButton = styled.button`
  top: 5px;
  position: absolute;
  z-index: 100000;
  background: ${colors.primary};
  border: none;
  border-radius: 4px;
  color: ${colors.N0};
  font-size: 16px;
  cursor: pointer;
  align-self: center;
  padding: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  /* Only show on hover with a .3s animation */
  opacity: 0;
  transition: 0.1s;
  ${Outer}:hover & {
    opacity: 1;
  }

  /* display: none;
  ${Outer}:hover & {
    display: block;
  } */
`

const PADDING = 16

export const BannersList = styled.div<{ $isExpanded: boolean }>`
  height: 100%;
  max-height: 80vh;

  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      -webkit-mask-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) calc(100% - 16px),
        rgba(0, 0, 0, 0) 100%
      );
    `}

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  overflow: auto;
  padding: ${PADDING}px;
`

const BETWEEN_BANNERS_GAP = 16
const MAX_NB_BANNERS_NOT_EXPANDED = 3

export const Banner = styled.div<{
  $index: number
  $isExpanded: boolean
}>`
  background: ${colors.red};
  color: ${colors.N0};
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  transition: 0.3s;

  display: flex;
  align-items: center;

  ${({ $index }) =>
    $index <= MAX_NB_BANNERS_NOT_EXPANDED &&
    css`
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    `}
  ${({ $index, $isExpanded }) => {
    if ($index === 0) {
      return css`
        z-index: 1000;
        width: 100%;
      `
    }

    const cappedIndex = $isExpanded
      ? $index
      : Math.min($index, MAX_NB_BANNERS_NOT_EXPANDED - 1)

    return css`
      position: absolute;
      ${$isExpanded
        ? css`
            width: calc(100% - ${PADDING * 2}px);
            transform: translateY(
              calc(${cappedIndex * 100}% + ${16 * cappedIndex}px)
            );
          `
        : css`
            transform: translateY(${BETWEEN_BANNERS_GAP * cappedIndex}px);
            width: calc(${100 - cappedIndex * 5}% - ${PADDING * 2}px);
          `};
      z-index: ${1000 - cappedIndex};
    `
  }}
`

export const BannerText = styled.span<{ $isExpanded: boolean }>`
  ${({ $isExpanded }) =>
    $isExpanded
      ? css`
          min-height: 54px; // Roughly 3 lines of text
        `
      : css`
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `}

  flex: 1;
`
