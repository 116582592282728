import AkPanel from '@atlaskit/panel'
import { typography } from '@atlaskit/theme'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  & > div > div:first-child {
    left: 0;
  }
`

const PanelHeader = styled.div`
  ${typography.h500()};
  margin: 0;
`

type PanelProps = Omit<ComponentProps<typeof AkPanel>, 'header'> & {
  header: string
}

const Panel = ({ header, ...props }: PanelProps) => (
  <Outer>
    <AkPanel header={<PanelHeader>{header}</PanelHeader>} {...props} />
  </Outer>
)

export default Panel
