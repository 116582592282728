import React, { useState } from 'react'

import Panel from '../../../../components/Panel'
import { EmailComposerButton } from '../../../../components/messages/SendgridEmailComposer'
import { GetWorkspaceOnboardingQuery } from '../../../../graphql'

import { Steps, Step, Row, StepDetail } from './components'

enum StepId {
  OFFER,
  CONFIRM,
}

const OFFER_EMAIL_TEMPLATE_IDS = [
  'd-9212334a6ec0426a98683c1b99d24f80', // Offer
]

const CONFIRM_EMAIL_TEMPLATE_IDS = [
  'd-0758b23a21834ee0aee586ec7a7376b6', // Confirm
]

interface Props {
  data: GetWorkspaceOnboardingQuery
}

const TemporaryCoverage = ({ data }: Props) => {
  const workspace = data.workspace
  const [stepId, setStepId] = useState<StepId>(StepId.OFFER)

  return (
    <Panel header={'Temporary Coverage'}>
      <Row>
        <Steps>
          <Step
            selected={stepId === StepId.OFFER}
            onClick={() => setStepId(StepId.OFFER)}
          >
            {'Offer'}
          </Step>
          <Step
            selected={stepId === StepId.CONFIRM}
            onClick={() => setStepId(StepId.CONFIRM)}
          >
            {'Confirm'}
          </Step>
        </Steps>
        <StepDetail>
          {stepId === StepId.OFFER && (
            <EmailComposerButton
              buttonProps={{
                children: 'Preview & send email',
              }}
              activityProps={{
                recipientEmails: workspace?.executives[0]?.profile.email
                  ? [workspace?.executives[0]?.profile.email]
                  : undefined,
              }}
              composerProps={{
                templateIds: OFFER_EMAIL_TEMPLATE_IDS,
                email: {
                  workspaceId: workspace?.id,
                  userId: workspace?.executives[0].id,
                  vars: {},
                  tags: ['temporary-coverage'],
                },
              }}
            />
          )}

          {stepId === StepId.CONFIRM && (
            <EmailComposerButton
              buttonProps={{
                children: 'Preview & send email',
              }}
              activityProps={{
                recipientEmails: workspace?.executives[0]?.profile.email
                  ? [workspace?.executives[0]?.profile.email]
                  : undefined,
              }}
              composerProps={{
                templateIds: CONFIRM_EMAIL_TEMPLATE_IDS,
                email: {
                  workspaceId: workspace?.id,
                  userId: workspace?.executives[0].id,
                  vars: {},
                  tags: ['temporary-coverage'],
                },
              }}
            />
          )}
        </StepDetail>
      </Row>
    </Panel>
  )
}

export default TemporaryCoverage
