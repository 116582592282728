import React, { ComponentProps } from 'react'

import { UserAccountType, UserAccount } from '../../graphql'

import gmailIconSrc from './gmail.png'
import gsuiteIconSrc from './gsuite.png'
import slackIconSrc from './slack.png'

interface Props extends ComponentProps<'img'> {
  account: Pick<UserAccount, 'type' | 'label'>
}

const imgStyle = {
  width: 16,
  height: 16,
}

const AccountIcon = ({ account: { type, label }, style, ...props }: Props) => {
  let src = gsuiteIconSrc

  if (type === UserAccountType.SLACK) {
    src = slackIconSrc
  } else if (type === UserAccountType.GOOGLE && label.endsWith('@gmail.com')) {
    src = gmailIconSrc
  }

  return (
    <img alt={label} src={src} style={{ ...imgStyle, ...style }} {...props} />
  )
}

export default AccountIcon
