import {
  ProgressTracker,
  Stage as AkStage,
  Stages,
} from '@atlaskit/progress-tracker'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import Modal, { Props as ModalProps } from '../../../components/Modal'

type Stage = Pick<AkStage, 'id' | 'label' | 'noLink'> &
  Partial<Pick<AkStage, 'status'>> & {
    videoId: string
  }

const STAGES: readonly Stage[] = [
  {
    id: 'intro',
    label: 'Intro & list',
    noLink: false,
    videoId: '7Xwt1vk1IAA',
  },
  {
    id: 'machine',
    label: 'Machine',
    noLink: false,
    videoId: 'BYtFuS5m1T8',
  },
  {
    id: 'proposals',
    label: 'Proposals',
    noLink: false,
    videoId: 'aWyueMvCSf0',
  },
  {
    id: 'transitions',
    label: 'Transitions (soon)',
    noLink: false,
    videoId: '',
    status: 'disabled',
  },
  {
    id: 'high-touch',
    label: 'High-Touch (soon)',
    noLink: false,
    videoId: '',
    status: 'disabled',
  },
] as const

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

type Props = ModalProps

const EducationalModal = (props: Props) => {
  const [stageId, setStageId] = useState<string>(STAGES[0].id)
  const stage = STAGES.find(({ id }) => stageId === id)

  const stages = useMemo<Stages>(() => {
    const currentIndex = STAGES.findIndex(({ id }) => id === stageId)
    return STAGES.map(({ id, label, noLink, status }, index) => ({
      id,
      label,
      noLink,
      percentageComplete:
        index < currentIndex ? 100 : index === currentIndex ? 10 : 0,
      status:
        status ||
        (index < currentIndex
          ? 'visited'
          : index === currentIndex
          ? 'current'
          : 'visited'),
      onClick: () => setStageId(id),
    }))
  }, [setStageId, stageId])

  return (
    <Modal
      heading={'Learn about matchings! Fun with matchings!'}
      width={'large'}
      actions={[
        {
          text: 'Close',
          onClick: props.onClose,
        },
      ]}
      autoFocus={false}
      {...props}
    >
      <Outer>
        <iframe
          key={stageId}
          width={720}
          height={424}
          src={`https://www.youtube.com/embed/${stage?.videoId}`}
          frameBorder={0}
          allow={
            'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          }
          allowFullScreen
        ></iframe>
        <ProgressTracker items={stages} />
      </Outer>
    </Modal>
  )
}

export default EducationalModal
