import Button from '@atlaskit/button'
import { colors, typography } from '@atlaskit/theme'
import styled from 'styled-components'

import { Select } from '../../../../components/form'

export const Outer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px 0px;
`

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
  padding: 10px 0px;
  gap: 10px;
`

export const StyledButton = styled(Button)`
  height: 40px;
  padding: 10px;
  border: none;
`
export const StyledSelect = styled(Select)<{ $isInvalid?: boolean }>`
  ${({ $isInvalid }) =>
    $isInvalid &&
    `
    outline: 1px solid ${colors.red()};
  `}
`

export const DatePickerOuter = styled.div`
  min-width: 150px;
`

export const SectionTitle = styled.span`
  ${typography.h600()}
  padding: 0 8px;
  margin: 0;
`
