import {
  getNames,
  registerLocale,
  LocaleData,
} from '@cospired/i18n-iso-languages'
import enLocale from '@cospired/i18n-iso-languages/langs/en.json'

// Build list of languages
registerLocale(enLocale as unknown as LocaleData)

const LANGUAGE_NAME_BY_CODE = getNames('en')

export const LANGUAGE_CODES = Object.keys(LANGUAGE_NAME_BY_CODE)

export const getLanguageName = (code: string): string =>
  LANGUAGE_NAME_BY_CODE[code]
