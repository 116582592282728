import { useEffect, useMemo } from 'react'

import {
  withListPagination,
  useListMatcherAssistantsQuery,
} from '../../../../graphql'

type Options = {
  skip?: boolean
}

type Variables = {
  countryCodes?: string[]
}

export const ASSISTANTS_PER_PAGE = 20

const useAssistants = (variables?: Variables, options?: Options) => {
  const result = withListPagination(
    useListMatcherAssistantsQuery({
      variables: { first: ASSISTANTS_PER_PAGE, ...variables },
      ...options,
    }),
  )

  // Fetch ALLLLLLLL assistants
  // 💩 This should not work like this, ideally this query should be paginated
  // and it should return the assistants oredered by matching score directly
  useEffect(() => {
    if (result.hasMore) {
      result.fetchMore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.hasMore, result.data?.list.items.length])

  return useMemo(
    () => ({ ...result, loading: result.loading || result.hasMore }),
    [result],
  )
}

export default useAssistants
