import Button from '@atlaskit/button'
import Icon from '@atlaskit/icon/glyph/book'
import React from 'react'
import styled from 'styled-components'

import useSwitch from '../../lib/useSwitch'
import useValues from '../../lib/useValues'
import { Page } from '../types'

import Calendar from './Calendar'
import Detail from './Detail'
import List from './List'
import Map from './Map'
import { Filters } from './useWhos'

const Outer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  height: 100%;
`

const RightPanel = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  overflow: hidden;
`

const MapOrCalendar = styled.div`
  flex: 1 1 auto;
  display: flex;
  overflow: hidden;
`
const SwitchOuter = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 3;
`

const WhosWho: Page = () => {
  const [showCalendar, , , toggle] = useSwitch()
  const [filters, { patch: patchFilters }] = useValues<Filters>({
    search: {},
    hqOnly: false,
    timeZones: null,
    language: null,
  })

  return (
    <Outer>
      <List filters={filters} onPatchFilters={patchFilters} />
      <RightPanel>
        <MapOrCalendar>
          {showCalendar ? <Calendar /> : <Map filters={filters} />}
          <SwitchOuter>
            <Button onClick={toggle}>{showCalendar ? '🗺️' : '🎂'}</Button>
          </SwitchOuter>
        </MapOrCalendar>
        <Detail />
      </RightPanel>
    </Outer>
  )
}

WhosWho.path = '/whoswho'
WhosWho.navTitle = 'Who’s Who?'
WhosWho.Icon = Icon
WhosWho.requiredOperationIds = ['Query.whosList']

export default WhosWho
