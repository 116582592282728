import React from 'react'
import styled from 'styled-components'

import { Field, Select } from '../../../components/form'

import { SortKey } from './utils'

const Outer = styled.div``

const getSortKeySelectOption = (key: SortKey) => ({
  value: key,
  label: key,
})

const SORT_KEY_OPTIONS = Object.values(SortKey).map(getSortKeySelectOption)

type Props = {
  value: SortKey[]
  onChangeValue: (newValue: SortKey[]) => void
}

const SortEditor = ({ value, onChangeValue }: Props) => (
  <Outer>
    <Field label={'Sort by'}>
      <Select<SortKey>
        isClearable
        isMulti
        options={SORT_KEY_OPTIONS}
        value={value.map(getSortKeySelectOption)}
        placeholder={'Select sorting'}
        // @ts-ignore
        onChangeValue={(sortKeys: SortKey[]) => onChangeValue(sortKeys || [])}
      />
    </Field>
  </Outer>
)

export default SortEditor
