import Button from '@atlaskit/button'
import LoadingButton from '@atlaskit/button/loading-button'
import Calendar from '@atlaskit/calendar'
import { colors } from '@atlaskit/theme'
import React, { useState } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0px;

  width: 321px;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: flex-start;

  padding: 0px 16px 16px;
  border-bottom: 1px solid ${colors.backgroundHover};
`

const Title = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.N500};
  padding-bottom: 10px;
`

const Subtitle = styled.span`
  font-size: 14px;
  color: ${colors.N100};
  font-weight: 400;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0px 16px 8px;
  gap: 8px;

  align-self: stretch;
`

type Props = {
  title: string
  subtitle: string
  /** A date string with the format 'yyyy-MM-dd' (use luxon .toISODate) */
  minDate?: string
  /** A date string with the format 'yyyy-MM-dd' (use luxon .toISODate) */
  maxDate: string
  onClose: () => void
  isSaving: boolean
  /** isoDate is a date string with the format 'yyyy-MM-dd' (use luxon .toISODate) */
  onSave: (isoDate: string) => any
}

const DatePickerPopUp = ({
  title,
  subtitle,
  minDate,
  maxDate,
  onClose,
  isSaving,
  onSave,
}: Props) => {
  const [selectedISODate, setSelectedISODate] = useState<string | null>(null)

  return (
    <Outer>
      <Header>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Header>

      <Calendar
        maxDate={maxDate}
        minDate={minDate}
        onSelect={({ iso }) => {
          setSelectedISODate(iso)
        }}
      />

      <Footer>
        <Button onClick={onClose} appearance={'default'}>
          {'Cancel'}
        </Button>
        <LoadingButton
          appearance={'primary'}
          isDisabled={!selectedISODate || isSaving}
          onClick={() => selectedISODate && onSave(selectedISODate)}
          isLoading={isSaving}
        >
          {'Save'}
        </LoadingButton>
      </Footer>
    </Outer>
  )
}

export default DatePickerPopUp
