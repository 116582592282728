import { useRouteMatch } from 'react-router-dom'

const useActiveUserId = () => {
  const routeMatch = useRouteMatch<{
    userId: string
  }>('*/users/:userId')

  const { userId } = routeMatch?.params || {}
  return userId
}

export default useActiveUserId
