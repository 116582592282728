import React from 'react'

import Section from '../../../components/Section'

const Reporting = () => {
  return (
    <div>
      <Section title={'Team Reporting coming soon!'} />
    </div>
  )
}

export default Reporting
