import Icon from '@atlaskit/icon/glyph/vid-raised-hand'
import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { Page } from '../../types'

import List from './List'
import Machine from './Machine'
import Matching from './Matching'
import { PATH } from './constants'

const Outer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
`

const MatchingCatalog: Page = () => {
  const { url } = useRouteMatch()

  return (
    <Outer>
      <List />
      <Route
        path={`${url}/:matchingId?`}
        render={({
          match: {
            params: { matchingId },
          },
        }) => <Matching matchingId={matchingId} />}
      />
      <Route
        path={`${url}/:matchingId`}
        render={({
          match: {
            params: { matchingId },
          },
        }) => <Machine matchingId={matchingId} />}
      />
    </Outer>
  )
}

MatchingCatalog.path = PATH
MatchingCatalog.navTitle = 'Matchings'
MatchingCatalog.Icon = Icon
MatchingCatalog.requiredOperationIds = ['Query.matchingsList']

export default MatchingCatalog
