import React from 'react'

import { UserCategory } from '../../graphql'
import { Select } from '../form'

const CATEGORY_OPTIONS = [
  {
    value: UserCategory.EXECUTIVE,
    label: 'Executive',
  },
  {
    value: UserCategory.ASSISTANT,
    label: 'Assistant',
  },
  {
    value: UserCategory.TEAM,
    label: 'Double Team Member',
  },
  {
    value: UserCategory.SANDBOX,
    label: 'Sandbox',
  },
]

const CategorySelect = ({
  value,
  ...props
}: Omit<React.ComponentProps<typeof Select>, 'options'>) => (
  <Select
    value={
      value
        ? CATEGORY_OPTIONS.find(({ value: cat }) => value === cat)
        : undefined
    }
    isSearchable={false}
    isClearable={false}
    options={CATEGORY_OPTIONS}
    isMulti={false}
    {...props}
  />
)

export default CategorySelect
