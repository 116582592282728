import Icon from '@atlaskit/icon/glyph/star'
import React from 'react'
import styled from 'styled-components'

import { Page } from '../../types'

import List from './List'

const Outer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  height: 100%;
  min-width: 600px;
  max-width: 1000px;
`

const Skillset: Page = () => (
  <Outer>
    <List />
  </Outer>
)

Skillset.path = '/skillset'
Skillset.navTitle = 'Skillset'
Skillset.Icon = Icon
Skillset.requiredOperationIds = ['Query.usersList']

export default Skillset
