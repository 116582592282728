import { colors } from '@atlaskit/theme'
import Tooltip from '@atlaskit/tooltip'
import { DateTime } from 'luxon'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { AirtableLinkButton } from '../../../../../components/AirtableButton'
import CollapsibleText from '../../../../../components/CollapsibleText'
import { Dl as _Dl, Dt, Dd } from '../../../../../components/DlDtDd'
import { LoadingSpinner } from '../../../../../components/Spinner'
import Toggle, { ToggleOuter } from '../../../../../components/Toggle'
import { DebouncedTextArea } from '../../../../../components/form'
import UserCell from '../../../../../components/users/Cell'
import { timeAgo, formatDate } from '../../../../../components/utils'
import {
  useUpdateTransitionNotepadMutation,
  Matching_ForTransitionFullFragment,
  useUpdateMatchingMutation,
  useGetLatestExecutiveCompletedTransitionQuery,
} from '../../../../../graphql'
import {
  TransitioningAssistant,
  EnteringAssistant,
} from '../../List/Table/cells/Assistant'
import {
  EnteringAssistantManager,
  TransitioningAssistantManager,
} from '../../List/Table/cells/AssistantManager'
import Deadline from '../../List/Table/cells/Deadline'
import Executive from '../../List/Table/cells/Executive'
import RequestDate from '../../List/Table/cells/RequestDate'
import RequestedBy from '../../List/Table/cells/RequestedBy'
import { Bundled } from '../../utils'

const Dl = styled(_Dl)`
  padding-bottom: 16px;
`

const HighTouchCriteria = styled.span<{ $isHighlighted?: boolean }>`
  ${({ $isHighlighted }) =>
    !!$isHighlighted &&
    css`
      font-weight: bold;
      color: ${colors.red};
    `}
`

type Props = {
  matching: Bundled<Matching_ForTransitionFullFragment>
}

const Infos = ({ matching }: Props) => {
  const { data: latestTransitionData, loading: isLoadingLatestTransition } =
    useGetLatestExecutiveCompletedTransitionQuery({
      variables: {
        executiveId: matching.executive?.id || '',
      },
      skip: !matching.executive?.id,
    })

  const [updateNotepad, updateNotepadMutation] =
    useUpdateTransitionNotepadMutation()

  const [updateMatching, updateMatchingMutation] = useUpdateMatchingMutation()

  const genericNotes = matching.transition!.airtableGenericNotes

  return (
    <Dl dtWidth={'220px'}>
      <Dt>{'Transition request submitted'}</Dt>
      <Dd>
        {!!matching.transition?.airtableTransitionRequestId && (
          <AirtableLinkButton
            tableId={'tblEMytMhBXVoSSRM'}
            recordId={matching.transition!.airtableTransitionRequestId}
          />
        )}
        <RequestDate matching={matching} />
      </Dd>

      <Dt>{'Requested by'}</Dt>
      <Dd>
        <RequestedBy matching={matching} />
      </Dd>

      <Dt>{'Completion Deadline'}</Dt>
      <Dd>
        <Deadline matching={matching} />
      </Dd>

      <Dt>{'Transition reason'}</Dt>
      <Dd>
        {matching.transition!.reasons?.map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </Dd>

      {(matching.bundle?.length || 0) > 1 ? (
        <>
          <Dt>{'Executives'}</Dt>
          <Dd>
            {matching.bundle?.map((matching) => (
              <Executive key={matching.id} matching={matching} />
            ))}
          </Dd>
        </>
      ) : (
        <>
          <Dt>{'Executive'}</Dt>
          <Dd>
            <Executive matching={matching} />
          </Dd>
        </>
      )}

      <Dt>{'Is High Touch? 👑'}</Dt>
      <Dd>
        <ToggleOuter>
          <Toggle
            isChecked={matching.isHighTouch}
            onChange={() => {
              updateMatching({
                variables: {
                  input: {
                    matchingId: matching.id,
                    isHighTouch: !matching.isHighTouch,
                  },
                },
              })
            }}
          />
        </ToggleOuter>
        <Dl>
          <Dt>{'Success Segment'}</Dt>
          <Dd>{matching.executive?.airtableClient?.successSegment}</Dd>
          <Dt>{'Onboarding date'}</Dt>
          <Dd>
            {!!matching.executive?.startDate ? (
              <Tooltip content={formatDate(matching.executive?.startDate)}>
                {(props) => (
                  <HighTouchCriteria
                    {...props}
                    $isHighlighted={
                      timeAgo(matching.executive!.startDate!, 'months') <= 2
                    }
                  >
                    {timeAgo(matching.executive!.startDate!, 'months')}
                    {' months ago'}
                  </HighTouchCriteria>
                )}
              </Tooltip>
            ) : (
              'N/A'
            )}
          </Dd>

          <Dt>{'Pricing plan'}</Dt>
          <Dd>{matching.executive?.currentPricing?.pricing.label}</Dd>

          <Dt>{'Previous transitions'}</Dt>
          <Dd>{latestTransitionData?.list.total}</Dd>

          <Dt>{'Last transition request'}</Dt>
          <Dd>
            {!!latestTransitionData?.list.items[0]?.transition?.requestedOn ? (
              <Tooltip
                content={formatDate(
                  latestTransitionData?.list.items[0]?.transition?.requestedOn,
                )}
              >
                {(props) => (
                  <HighTouchCriteria
                    {...props}
                    $isHighlighted={
                      timeAgo(
                        latestTransitionData!.list.items[0]!.transition!
                          .requestedOn,
                        'months',
                      ) <= 6
                    }
                  >
                    {timeAgo(
                      latestTransitionData!.list.items[0]!.transition!
                        .requestedOn,
                      'months',
                    )}
                    {' months ago'}
                  </HighTouchCriteria>
                )}
              </Tooltip>
            ) : null}
          </Dd>
        </Dl>
      </Dd>

      <Dt>{'Transitioning Double'}</Dt>
      <Dd>
        <TransitioningAssistant matching={matching} />
      </Dd>

      <Dt>{'Ops Manager'}</Dt>
      <Dd>
        <TransitioningAssistantManager matching={matching} withName />
      </Dd>

      <Dt>{'CS owner'}</Dt>
      <Dd>
        <Link to={`/users/${matching.transition!.csm?.id}`} target={'_blank'}>
          <UserCell user={matching.transition!.csm} />
        </Link>
      </Dd>

      <Dt>{'Additional feedback ✏️'}</Dt>
      <Dd>
        <DebouncedTextArea
          value={matching.transition!.notepad || ''}
          appearance={'subtle'}
          minimumRows={6}
          onChangeValue={(notepad) =>
            updateNotepad({
              variables: {
                input: {
                  matchingId: matching.id,
                  notepad,
                },
              },
            })
          }
          debounceMs={1_000}
          resize={'smart'}
        />
      </Dd>

      <Dt>{'Associated notes'}</Dt>
      <Dd>
        {genericNotes?.map(({ id, date, notepad }) => (
          <Fragment key={id}>
            <AirtableLinkButton tableId={'tbl3rrPscmVApqfq5'} recordId={id} />
            {!!date &&
              DateTime.fromJSDate(new Date(date)).toLocaleString({
                month: 'long',
                day: 'numeric',
              })}
            <CollapsibleText text={notepad} />
          </Fragment>
        ))}
      </Dd>

      <Dt>{'New Double proposed'}</Dt>
      <Dd>
        <EnteringAssistant matching={matching} />
      </Dd>

      <Dt>{'Ops Manager'}</Dt>
      <Dd>
        <EnteringAssistantManager matching={matching} withName />
      </Dd>

      <Dt>{'Transition call date-time'}</Dt>
      <Dd>
        {matching.onboarding.callStartAt
          ? DateTime.fromJSDate(
              new Date(matching.onboarding.callStartAt),
            ).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
          : 'TBD'}
      </Dd>
      <LoadingSpinner
        show={
          updateNotepadMutation.loading ||
          updateMatchingMutation.loading ||
          isLoadingLatestTransition
        }
      />
    </Dl>
  )
}

export default Infos
