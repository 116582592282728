import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import IndustriesSelect from '../../../components/IndustriesSelect'
import Item, { ITEM_LINK_ACTIVE_CLASSNAME } from '../../../components/Item'
import PageHeader from '../../../components/PageHeader'
import Toggle, { ToggleOuter } from '../../../components/Toggle'
import ToolsSelect from '../../../components/ToolsSelect'
import { Field } from '../../../components/form'
import CategorySelect from '../../../components/users/CategorySelect'
import CreateAssistantModal from '../../../components/users/CreateAssistantModal'
import CreateExecutiveModal from '../../../components/users/CreateExecutiveModal'
import CreateHqModal from '../../../components/users/CreateHqModal'
import CreateSandboxModal from '../../../components/users/CreateSandboxModal'
import { Cell } from '../../../components/users/List'
import { SingleUserSelect } from '../../../components/users/Select'
import { BasicUserFragment, UserCategory } from '../../../graphql'
import getQueryParam from '../../../lib/getQueryParam'
import useSwitch from '../../../lib/useSwitch'

import { Outer, ListOuter, List } from './styled'

const UsersList = () => {
  const [search, setSearch] = useState<string | null | undefined>(
    getQueryParam('search', ''),
  )
  const [category, setCategory] = useState<UserCategory | null | undefined>()
  const [successManagerId, setSuccessManagerId] = useState<
    string | null | undefined
  >()
  const [hasSuccessManager, setHasSuccessManager] = useState<null | boolean>(
    null,
  )
  const [tools, setTools] = useState<string[] | null | undefined>()
  const [industries, setIndustries] = useState<string[] | null | undefined>()

  const [
    isCreateAssistantModalOpen,
    openCreateAssistantModal,
    closeCreateAssistantModal,
  ] = useSwitch(false)
  const [
    isCreateExecutiveModalOpen,
    openCreateExecutiveModal,
    closeCreateExecutiveModal,
  ] = useSwitch(false)
  const [isCreateHqModalOpen, openCreateHqModal, closeCreateHqModal] =
    useSwitch(false)
  const [
    isCreateSandboxModalOpen,
    openCreateSandboxModal,
    closeCreateSandboxModal,
  ] = useSwitch(false)

  return (
    <Outer>
      <PageHeader
        createActions={[
          {
            label: 'Executive',
            onClick: openCreateExecutiveModal,
          },
          {
            label: 'Assistant',
            onClick: openCreateAssistantModal,
          },
          {
            label: 'Double HQ',
            onClick: openCreateHqModal,
          },
          {
            label: 'Sandbox',
            onClick: openCreateSandboxModal,
          },
        ]}
        searchTerm={search}
        onChangeSearchTerm={setSearch}
        hasActiveFilter={Boolean(
          category || hasSuccessManager != null || successManagerId,
        )}
        filtersContent={() => (
          <>
            <Field label={'User Category'}>
              <CategorySelect
                isClearable
                value={category}
                onChangeValue={setCategory}
              />
            </Field>
            <Field
              label={`${
                category === UserCategory.ASSISTANT
                  ? 'Double '
                  : category === UserCategory.EXECUTIVE
                  ? 'Customer '
                  : ''
              }Success Manager`}
            >
              <SingleUserSelect
                isDisabled={hasSuccessManager === false}
                isClearable
                defaultOptions
                value={successManagerId ? { id: successManagerId } : undefined}
                adminRoles={['EA_TEAM_LEAD', 'ONBOARDING_SPECIALIST']}
                onChange={(successManager: null | BasicUserFragment) =>
                  setSuccessManagerId(successManager?.id)
                }
              />
            </Field>
            <Field label={''}>
              <ToggleOuter>
                <Toggle
                  isDisabled={!!successManagerId}
                  isChecked={hasSuccessManager === false}
                  onChange={() =>
                    setHasSuccessManager((prev) =>
                      prev === false ? null : false,
                    )
                  }
                />
                {'Only users without success manager'}
              </ToggleOuter>
            </Field>
            <Field label={'Industries experienced with'}>
              <IndustriesSelect
                isMulti
                values={industries}
                onChangeValues={setIndustries}
              />
            </Field>
            <Field label={'Tools experienced with'}>
              <ToolsSelect isMulti values={tools} onChangeValues={setTools} />
            </Field>
          </>
        )}
      >
        {{
          [UserCategory.EXECUTIVE]: 'Executives',
          [UserCategory.ASSISTANT]: 'Assistants',
          [UserCategory.TEAM]: 'HQ Team',
          [UserCategory.SANDBOX]: 'Test accounts',
        }[category!] || 'Users'}
      </PageHeader>
      <ListOuter>
        <List
          search={search || ''}
          successManagerId={successManagerId || undefined}
          hasSuccessManager={hasSuccessManager}
          isArchived={search ? null : false}
          categories={category ? [category] : undefined}
          tools={tools}
          industries={industries}
          autoNavigateToSingleResult
          showFooter
          Cell={({ user, style }) => (
            <NavLink
              to={`/users/${user?.id}`}
              style={style}
              activeClassName={ITEM_LINK_ACTIVE_CLASSNAME}
            >
              <Item>
                <Cell
                  user={user}
                  withCategoryBadge={!category}
                  withOnPauseBadge
                  withSuccessManagerAvatar
                />
              </Item>
            </NavLink>
          )}
        />
      </ListOuter>

      <CreateAssistantModal
        isOpen={isCreateAssistantModalOpen}
        onClose={closeCreateAssistantModal}
      />

      <CreateExecutiveModal
        isOpen={isCreateExecutiveModalOpen}
        onClose={closeCreateExecutiveModal}
      />

      <CreateHqModal
        isOpen={isCreateHqModalOpen}
        onClose={closeCreateHqModal}
      />

      <CreateSandboxModal
        isOpen={isCreateSandboxModalOpen}
        onClose={closeCreateSandboxModal}
      />
    </Outer>
  )
}

export default UsersList
