import Icon from '@atlaskit/icon/glyph/decision'
import React from 'react'

import { Page } from '../../types'

import List from './List'
import Panel from './Panel'
import { Outer } from './styled'

const Transitions: Page = () => (
  <Outer>
    <List />
    <Panel />
  </Outer>
)

Transitions.path = '/transitions'
Transitions.navTitle = 'Transitions'
Transitions.Icon = Icon
Transitions.requiredOperationIds = ['Query.matchingsList']

export default Transitions
