import Select from '@atlaskit/select'
import React, { ComponentProps } from 'react'

import { useSkillset } from '../../../../components/skillset/utils'

type Props = ComponentProps<typeof Select> & {
  values?: string[] | null
  onChangeValues: (newValues: string[]) => void
}

const SkillsetSelect = ({
  values,
  onChangeValues,
  isCreatable = true,
  ...props
}: Props) => {
  const { skillset, skillNameById } = useSkillset()
  const skills = skillset?.skills || []

  return (
    <Select
      value={values?.map((value) => ({ value }))}
      isClearable={isCreatable}
      isMulti
      options={skills.map(({ id }) => ({ value: id }))}
      // @ts-ignore
      getOptionLabel={({ value }) => skillNameById(value)}
      // @ts-ignore
      getOptionValue={({ value }) => value}
      onChange={(values) =>
        // @ts-ignore
        onChangeValues((values || []).map(({ value }) => value))
      }
      {...props}
    />
  )
}
export default SkillsetSelect
