import React from 'react'

import { UserFacet } from '../../graphql'
import { Select } from '../form'

const OPTIONS = [
  {
    value: UserFacet.PRINCIPAL,
    label: 'Principal',
  },
  {
    value: UserFacet.TEAM_MEMBER,
    label: 'Team Member',
  },
  {
    value: UserFacet.SIGNIFICANT_OTHER,
    label: 'Significant Other',
  },
]

const FacetSelect = ({
  value,
  ...props
}: Omit<React.ComponentProps<typeof Select>, 'options'>) => (
  <Select
    value={value ? OPTIONS.find(({ value: cat }) => value === cat) : undefined}
    isSearchable={false}
    isClearable={false}
    options={OPTIONS}
    isMulti={false}
    {...props}
  />
)

export default FacetSelect
