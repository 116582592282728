import React, { ComponentProps } from 'react'

import Badge from '../../../../components/Badge'
import {
  MatchingProposalReply,
  Matching_ProposalsReplyFragment,
} from '../../../../graphql'

type Props = {
  matching: Matching_ProposalsReplyFragment
}

const ProposalsBadge = ({ matching }: Props) => {
  const replies = new Set<MatchingProposalReply | null>()

  for (const { reply, sentAt } of matching.proposals) {
    if (sentAt) {
      replies.add(reply || null)
    }
  }

  let emoji: string | undefined = undefined
  let tint: ComponentProps<typeof Badge>['tint']
  let isLight: boolean = false

  if (replies.has(MatchingProposalReply.YES)) {
    emoji = '🙋'
    tint = 'green'
    isLight = false
  } else if (replies.has(MatchingProposalReply.NEED_MORE_INFO)) {
    emoji = '🤷'
    tint = 'yellow'
    isLight = false
  } else if (replies.has(null)) {
    emoji = '💆'
    tint = 'purple'
    isLight = false
  } else if (replies.has(MatchingProposalReply.NO)) {
    emoji = '🙅'
    tint = 'red'
    isLight = false
  } else {
    emoji = '👤'
    isLight = false
  }

  return (
    <Badge
      text={matching.proposals.length || 0}
      emoji={emoji}
      tint={tint}
      isLight={isLight}
      title={'Proposals'}
    />
  )
}

export default ProposalsBadge
