import AddIcon from '@atlaskit/icon/glyph/editor/add'
import SignOutIcon from '@atlaskit/icon/glyph/sign-out'
import MeIcon from '@atlaskit/icon/glyph/user-avatar-circle'
// @ts-ignore
import { GlobalNav, GlobalItem } from '@atlaskit/navigation-next'
import { Content, LeftSidebar, Main, PageLayout } from '@atlaskit/page-layout'
import {
  ButtonItem,
  NavigationHeader,
  NestableNavigationContent,
  Section,
  SideNavigation,
} from '@atlaskit/side-navigation'
import { typography } from '@atlaskit/theme'
import React, { useCallback, useMemo } from 'react'
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  NavLink,
  useRouteMatch,
} from 'react-router-dom'
import styled from 'styled-components'

import { useGetMeQuery } from '../../graphql'
import useIsAllowedOperation from '../../hooks/useIsAllowedOperation'
import { useAuth } from '../../lib/auth'
import Login from '../../pages/Login'
import { Page } from '../../pages/types'

import NotProdBanner from './NotProdBanner'
import { ReactComponent as DoubleIcon } from './double.svg'
import { Space } from './types'
import useShortcuts, { Shortcut } from './useShortcuts'

export * from './types'

interface Props {
  spaces: Space[]
}

const PageItem = ({
  Page,
  isSelected,
}: {
  Page: Page
  isSelected: boolean
}) => (
  <NavLink to={Page.path}>
    <ButtonItem isSelected={isSelected} iconBefore={<Page.Icon />}>
      {Page.navTitle}
    </ButtonItem>
  </NavLink>
)

const Divider = styled.div`
  height: 16px;
`

type GlobalItemNavProps = {
  icon?: React.ReactNode
  onClick?: () => void
  tooltip?: string

  space?: Space

  isDivider?: boolean

  element?: React.ReactNode

  shortcut?: Shortcut
}

const GlobalNavItem = ({
  shortcut,
  element,
  icon,
  onClick,
  space,
  isDivider,
  tooltip,
}: GlobalItemNavProps) => {
  const match = useRouteMatch(
    shortcut?.path || space?.pages.map(({ path }) => path) || '',
  )

  if (element) {
    return element
  }

  if (space) {
    return (
      <NavLink to={space.pages[0].path}>
        <GlobalItem
          icon={space.Icon}
          isSelected={!!match}
          tooltip={tooltip || space.title}
        />
      </NavLink>
    )
  }

  if (shortcut) {
    return (
      <NavLink to={shortcut.path} onClick={onClick}>
        <GlobalItem
          icon={icon}
          isSelected={!!match}
          tooltip={tooltip || shortcut.title}
        />
      </NavLink>
    )
  }

  if (icon) {
    const props = {
      icon,
      onClick,
      isSelected: false,
      tooltip,
    }
    if (!onClick) {
      // @ts-ignore
      props.isHover = false
      // @ts-ignore
      props.isActive = false
    }
    return <GlobalItem {...props} />
  }

  if (isDivider) {
    return <Divider />
  }

  return null
}

const Protected: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth()

  return (
    <Route
      render={(props) =>
        // @ts-ignore
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

const MainInner = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  overflow-y: auto;
`

const SidebarHeader = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 8px 10px;
  align-items: center;
  color: rgb(66, 82, 110);

  b {
    ${typography.h400()};
    margin: 0;
    margin-left: 16px;
    color: rgb(66, 82, 110);
  }
`

const NavLayout = ({ spaces: _spaces }: Props) => {
  const { pathname } = useLocation()
  const { shortcuts, toggleShortcut } = useShortcuts()
  const { signOut } = useAuth()
  const isAllowed = useIsAllowedOperation()
  const { data } = useGetMeQuery()

  const spaces = _spaces
    .map((space) => ({
      ...space,
      pages: space.pages.filter(
        ({ requiredOperationIds }) =>
          !requiredOperationIds.some((opId) => !isAllowed(opId)),
      ),
    }))
    .filter((space) => space.pages.length > 0)

  const allPages = spaces.map(({ pages }) => pages).flat()
  const firstPagePath = allPages[0]?.path

  let currentPage: Page | undefined
  const currentSpace = spaces.find(({ pages }) =>
    Boolean(
      (currentPage = pages.find(({ path }) => pathname.startsWith(path))),
    ),
  )
  const CurrentSpaceIcon = currentSpace?.Icon

  const addShortcut = useCallback(() => {
    toggleShortcut({
      path: pathname,
      title:
        allPages.find(({ path }) => pathname.startsWith(path))?.name ||
        window.document.title,
    })
  }, [allPages, pathname, toggleShortcut])

  const globalNavItems = useMemo(
    () => ({
      primary: [
        { element: <DoubleIcon /> },
        { isDivider: true },
        ...spaces.map((space) => ({ space })),
        { isDivider: true },
        { isDivider: true },
        ...shortcuts.map((shortcut) => ({
          shortcut,
          icon: allPages.find(({ path }) => shortcut.path.startsWith(path))
            ?.Icon,
          onClick:
            pathname === shortcut.path
              ? () => toggleShortcut(shortcut)
              : undefined,
        })),
        !shortcuts.some(({ path }) => pathname === path) && {
          icon: AddIcon,
          tooltip: 'Add shortcut',
          onClick: addShortcut,
        },
      ].filter(Boolean),
      secondary: [
        {
          icon: MeIcon,
          shortcut: {
            path: `/users/${data?.me.id}/admin`,
            title: 'Me',
          },
        },
        {
          icon: SignOutIcon,
          onClick: signOut,
        },
      ],
    }),
    [
      spaces,
      shortcuts,
      addShortcut,
      data?.me.id,
      signOut,
      allPages,
      pathname,
      toggleShortcut,
    ],
  )

  return (
    <PageLayout>
      <NotProdBanner />
      <Content>
        <GlobalNav
          primaryItems={globalNavItems.primary}
          secondaryItems={globalNavItems.secondary}
          itemComponent={GlobalNavItem}
        />
        {(currentSpace?.pages?.length || 0) > 1 && (
          <LeftSidebar isFixed={false}>
            <SideNavigation label={'side nav label'}>
              <NavigationHeader>
                <SidebarHeader>
                  {!!CurrentSpaceIcon && <CurrentSpaceIcon />}
                  <b>{currentSpace?.title}</b>
                </SidebarHeader>
              </NavigationHeader>
              <NestableNavigationContent initialStack={[]}>
                <Section>
                  {currentSpace?.pages.map((Page) => (
                    <PageItem
                      key={Page.path}
                      Page={Page}
                      isSelected={Page === currentPage}
                    />
                  ))}
                </Section>
              </NestableNavigationContent>
            </SideNavigation>
          </LeftSidebar>
        )}
        <Main>
          <MainInner>
            <Switch>
              <Route exact path={'/login'} component={Login} />
              <Protected>
                {/* Redirect to first page of first space when going to root */}
                <Route exact path={'/'}>
                  {firstPagePath && (
                    <Redirect to={firstPagePath} push={false} />
                  )}
                </Route>
                {allPages.map((Page) => (
                  <Route key={Page.path} path={Page.path} component={Page} />
                ))}
              </Protected>
            </Switch>
          </MainInner>
        </Main>
      </Content>
    </PageLayout>
  )
}

export default NavLayout
