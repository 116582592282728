import AkButton from '@atlaskit/button'
import EditIcon from '@atlaskit/icon/glyph/edit-filled'
import BoldIcon from '@atlaskit/icon/glyph/editor/bold'
import BulletListIcon from '@atlaskit/icon/glyph/editor/bullet-list'
import CodeIcon from '@atlaskit/icon/glyph/editor/code'
import ItalicIcon from '@atlaskit/icon/glyph/editor/italic'
import LinkIcon from '@atlaskit/icon/glyph/editor/link'
import NumberListIcon from '@atlaskit/icon/glyph/editor/number-list'
import QuoteIcon from '@atlaskit/icon/glyph/editor/quote'
import StrikeIcon from '@atlaskit/icon/glyph/editor/strikethrough'
import HeadingIcon from '@atlaskit/icon/glyph/editor/text-style'
import UnlinkIcon from '@atlaskit/icon/glyph/editor/unlink'
import PreviewIcon from '@atlaskit/icon/glyph/watch-filled'
import Tooltip from '@atlaskit/tooltip'
import {
  Editor,
  useMark,
  Mark,
  Block,
  useBlock,
  useFocused,
  useLink,
  markdownToHtml,
} from '@withdouble/markdown'
import React, { ComponentProps, useCallback, useState } from 'react'

import { ReactComponent as MarkdownIcon } from './markdown.svg'
import {
  Editable,
  HoveringToolbar,
  Outer,
  Inner,
  AnchoredToolbar,
  ToolbarDivider,
  MarkdownPreview,
  PreviewOuter,
} from './styled'

enum Mode {
  EDIT = 'EDIT',
  MARKDOWN = 'MARKDOWN',
  PREVIEW = 'PREVIEW',
}

const TOOLTIP_TEXT: Record<Mark | Block, string> = {
  [Mark.BOLD]: 'Bold (Cmd+B)',
  [Mark.ITALIC]: 'Italic (Cmd+I)',
  [Mark.CODE]: 'Code',
  [Mark.STRIKE_THROUGH]: 'Strikethrough',
  [Mark.VARIABLE]: '',
  [Block.PARAGRAPH]: 'Paragraph',
  [Block.BULLETED_LIST]: 'Bulleted List',
  [Block.NUMBERED_LIST]: 'Numbered List',
  [Block.QUOTE]: 'Quote',
  [Block.LIST_ITEM]: '',
  [Block.HEADING_1]: 'Heading 1',
  [Block.HEADING_2]: 'Heading 2',
  [Block.HEADING_3]: 'Heading 3',
}

const Button: React.FC<ComponentProps<typeof AkButton>> = (props) => (
  <AkButton
    appearance={'subtle'}
    spacing={'compact'}
    style={{ margin: '0 1px' }}
    {...props}
  />
)

const MarkButton: React.FC<{ mark: Mark; icon: React.ReactChild }> = ({
  mark,
  icon,
}) => {
  const { isActive, toggle } = useMark(mark)
  const onMouseDown: React.MouseEventHandler<HTMLElement> = useCallback(
    (event) => {
      event.preventDefault()
      toggle()
    },
    [toggle],
  )

  return (
    <Tooltip content={TOOLTIP_TEXT[mark]} position={'top'}>
      <Button
        isSelected={isActive}
        iconBefore={icon}
        onMouseDown={onMouseDown}
      />
    </Tooltip>
  )
}

const LinkButtons: React.FC = () => {
  const { isActive, insert, remove, url } = useLink()
  const onAdd: React.MouseEventHandler<HTMLElement> = useCallback(
    (event) => {
      event.preventDefault()
      const newUrl = window.prompt('Enter the URL of the link:', url)
      if (newUrl) {
        insert(newUrl)
      }
    },
    [insert, url],
  )

  const onRemove: React.MouseEventHandler<HTMLElement> = useCallback(
    (event) => {
      event.preventDefault()
      remove()
    },
    [remove],
  )

  return (
    <>
      <Tooltip content={isActive ? 'Edit link' : 'Add link'} position={'top'}>
        <Button
          isSelected={isActive}
          iconBefore={<LinkIcon label={''} />}
          onMouseDown={onAdd}
        />
      </Tooltip>
      {isActive && (
        <Tooltip content={'Remove link'} position={'top'}>
          <Button
            isSelected
            iconBefore={<UnlinkIcon label={''} />}
            onMouseDown={onRemove}
          />
        </Tooltip>
      )}
    </>
  )
}

const BlockButton: React.FC<{ block: Block; icon: React.ReactChild }> = ({
  block,
  icon,
}) => {
  const { isActive, toggle } = useBlock(block)
  const onMouseDown: React.MouseEventHandler<HTMLElement> = useCallback(
    (event) => {
      event.preventDefault()
      toggle()
    },
    [toggle],
  )

  return (
    <Tooltip content={TOOLTIP_TEXT[block]} position={'top'}>
      <Button
        isSelected={isActive}
        iconBefore={icon}
        onMouseDown={onMouseDown}
      />
    </Tooltip>
  )
}

const HeadingButton: React.FC = () => {
  const { isActive: isH1Active, toggle: toggleH1 } = useBlock(Block.HEADING_1)
  const { isActive: isH2Active, toggle: toggleH2 } = useBlock(Block.HEADING_2)
  const { isActive: isH3Active, toggle: toggleH3 } = useBlock(Block.HEADING_3)

  const isActive = isH1Active || isH2Active || isH3Active

  const onMouseDown: React.MouseEventHandler<HTMLElement> = useCallback(
    (event) => {
      event.preventDefault()
      if (isH1Active) {
        toggleH2()
      } else if (isH2Active) {
        toggleH3()
      } else if (isH3Active) {
        toggleH3()
      } else {
        toggleH1()
      }
    },
    [toggleH1, toggleH2, toggleH3, isH1Active, isH2Active, isH3Active],
  )

  return (
    <Tooltip content={'H1 - H2 - H3'} position={'top'}>
      <Button
        isSelected={isActive}
        iconBefore={<HeadingIcon label={''} />}
        onMouseDown={onMouseDown}
      />
    </Tooltip>
  )
}

const Toolbar: React.FC<{
  isInline?: boolean
  isBelow?: boolean
  mode: Mode
  setMode: (mode: Mode) => void
}> = ({ isInline, mode, setMode, isBelow }) => {
  const { isActive: isLinkActive } = useLink()
  const focused = useFocused()

  const setEdit = useCallback(() => setMode(Mode.EDIT), [setMode])
  const setPreview = useCallback(() => setMode(Mode.PREVIEW), [setMode])
  const setMarkdown = useCallback(() => setMode(Mode.MARKDOWN), [setMode])

  return (
    <AnchoredToolbar isHidden={!focused} $isBelow={isBelow}>
      {isLinkActive && mode === Mode.EDIT && (
        <>
          <LinkButtons />
          <ToolbarDivider />
        </>
      )}
      {!isInline && mode === Mode.EDIT && (
        <>
          <HeadingButton />
          <BlockButton
            block={Block.BULLETED_LIST}
            icon={<BulletListIcon label={''} />}
          />
          <BlockButton
            block={Block.NUMBERED_LIST}
            icon={<NumberListIcon label={''} />}
          />
          <BlockButton block={Block.QUOTE} icon={<QuoteIcon label={''} />} />
          <ToolbarDivider />
        </>
      )}
      <Tooltip content={'Edit'} position={'top'}>
        <Button
          isSelected={mode === Mode.EDIT}
          iconBefore={<EditIcon size={'small'} label={''} />}
          onClick={setEdit}
        />
      </Tooltip>
      <Tooltip content={'Preview'} position={'top'}>
        <Button
          isSelected={mode === Mode.PREVIEW}
          iconBefore={<PreviewIcon size={'small'} label={''} />}
          onClick={setPreview}
        />
      </Tooltip>
      <Tooltip content={'Markdown'} position={'top'}>
        <Button
          isSelected={mode === Mode.MARKDOWN}
          iconBefore={
            <MarkdownIcon
              style={{
                width: 20,
                fill: 'currentColor',
                stroke: 'currentColor',
              }}
            />
          }
          onClick={setMarkdown}
        />
      </Tooltip>
    </AnchoredToolbar>
  )
}

interface Props {
  value?: string | null
  onChange: (newValue: string) => void
  onChangeDebounceMillis?: number
  isDisabled?: boolean
  isInline?: boolean
  minHeight?: number
  isInvalid?: boolean
  noToolbar?: boolean
  bottomToolbar?: boolean
  appearance?: 'default' | 'subtle'
}

const MarkdownEditor = ({
  value,
  onChange: _onChange,
  onChangeDebounceMillis,
  isDisabled,
  isInline,
  minHeight,
  noToolbar,
  bottomToolbar,
  isInvalid,
  appearance,
}: Props) => {
  const [mode, setMode] = useState<Mode>(Mode.EDIT)
  const onChange = useCallback(
    (md: string) =>
      _onChange?.(
        md
          // Slate escapes "{" and "}" in what it thinks are urls,
          // so we need to preserve them manually
          .replace(/%7B%7B%7B([^%]+)%7D%7D%7D/g, '{{{$1}}}')
          .replace(/%7B%7B([^%]+)%7D%7D/g, '{{$1}}'),
      ),
    [_onChange],
  )
  return (
    <Outer>
      <Editor
        markdown={value || undefined}
        onChangeMarkdown={onChange}
        isInline={isInline}
        onChangeDebounceMillis={onChangeDebounceMillis}
      >
        <Inner>
          <Toolbar
            isInline={isInline}
            mode={mode}
            setMode={setMode}
            isBelow={bottomToolbar}
          />
          {mode === Mode.EDIT && (
            <>
              <Editable
                isInvalid={isInvalid}
                isSubtle={appearance === 'subtle'}
                disabled={isDisabled}
                style={minHeight ? { minHeight } : undefined}
              />
              {!noToolbar && (
                <HoveringToolbar style={{ zIndex: 9999999 }}>
                  <MarkButton mark={Mark.BOLD} icon={<BoldIcon label={''} />} />
                  <MarkButton
                    mark={Mark.ITALIC}
                    icon={<ItalicIcon label={''} />}
                  />
                  <MarkButton
                    mark={Mark.STRIKE_THROUGH}
                    icon={<StrikeIcon label={''} />}
                  />
                  <MarkButton mark={Mark.CODE} icon={<CodeIcon label={''} />} />
                  <LinkButtons />
                </HoveringToolbar>
              )}
            </>
          )}
          {mode === Mode.MARKDOWN && (
            <MarkdownPreview>{value || ''}</MarkdownPreview>
          )}
          {mode === Mode.PREVIEW && (
            <PreviewOuter
              dangerouslySetInnerHTML={{
                __html: markdownToHtml(value || '', { inline: isInline }),
              }}
              style={minHeight ? { minHeight } : undefined}
            />
          )}
        </Inner>
      </Editor>
    </Outer>
  )
}

export default MarkdownEditor
