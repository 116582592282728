import PeopleIcon from '@atlaskit/icon/glyph/people'
import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { Page } from '../../types'

import DelegationCoachSystemPrompt from './DelegationCoachSystemPrompt'
import DelegationCoachTaskSuggestionsFunction from './DelegationCoachTaskSuggestionsFunction'
import List from './List'
import {
  DELEGATION_COACH,
  DELEGATION_COACH_SYSTEM_PROMPT,
  DELEGATION_COACH_TASK_SUGGESTIONS,
} from './ROUTES'
import { Outer } from './styled'

const DelegationCoach: Page = () => {
  const { url } = useRouteMatch()

  return (
    <Outer>
      <Route path={`${url}`} component={List} />
      <Route
        path={`${url}${DELEGATION_COACH_SYSTEM_PROMPT}`}
        component={DelegationCoachSystemPrompt}
      />
      <Route
        path={`${url}${DELEGATION_COACH_TASK_SUGGESTIONS}`}
        component={DelegationCoachTaskSuggestionsFunction}
      />
    </Outer>
  )
}

DelegationCoach.path = DELEGATION_COACH
DelegationCoach.navTitle = 'Delegation Coach'
DelegationCoach.Icon = PeopleIcon
DelegationCoach.requiredOperationIds = [
  'Mutation.updateDelegationCoachSettings',
]

export default DelegationCoach
