import React, { useCallback, useState } from 'react'

import { SaveButtons } from '../../../../../components/form'
import {
  MessageTemplateFragment,
  useUpsertMessageTemplateMutation,
} from '../../../../../graphql'
import useValues from '../../../../../lib/useValues'
import {
  areEquivalentObjects,
  deepCleanTypename,
} from '../../../../../lib/utils'
import { Outer } from '../../components/styled'

import EmailFields from './EmailFields'
import SlackFields from './SlackFields'
import SmsFields from './SmsFields'
import TextFields from './TextFields'

type LanguageProps = {
  template: MessageTemplateFragment
  languageCode: string
}

const Language = ({ template, languageCode }: LanguageProps) => {
  const [upsert, { loading: loadingUpsert }] =
    useUpsertMessageTemplateMutation()

  const language =
    // Find language
    template.languages.find((l) => l.languageCode === languageCode) ||
    // or copy from english
    template.languages.find((l) => l.languageCode === 'en') ||
    // or any language
    template.languages[0]

  const defaultValues = language?.fields || {}

  const [revision, setRevision] = useState(0)
  const [isValid, setIsValid] = useState(false)
  const [newValues, { patch, reset }] = useValues<Record<string, unknown>>(
    deepCleanTypename(defaultValues),
    [],
  )

  const cancelEdit = useCallback(() => {
    reset()
    setRevision((prev) => prev + 1)
  }, [setRevision, reset])

  const save = useCallback(() => {
    if (isValid) {
      upsert({
        variables: {
          input: {
            id: template.id,
            languages: [
              {
                languageCode,
                fields: newValues,
              },
            ],
          },
        },
      })
    }
  }, [isValid, newValues, upsert, template.id, languageCode])

  return (
    // Set `key` to hard reset the whole thing
    // because Markdown editor are trouble makers :)
    <Outer key={JSON.stringify(defaultValues) + revision}>
      {template.__typename === 'TextMessageTemplate' ? (
        <TextFields
          values={newValues}
          onPatch={patch}
          onValidity={setIsValid}
        />
      ) : template.__typename === 'SmsMessageTemplate' ? (
        <SmsFields values={newValues} onPatch={patch} onValidity={setIsValid} />
      ) : template.__typename === 'SlackMessageTemplate' ? (
        <SlackFields
          values={newValues}
          onPatch={patch}
          onValidity={setIsValid}
        />
      ) : template.__typename === 'EmailMessageTemplate' ? (
        <EmailFields
          values={newValues}
          onPatch={patch}
          onValidity={setIsValid}
        />
      ) : (
        `no nothing ${template.__typename}`
      )}

      {(language?.languageCode !== languageCode ||
        !areEquivalentObjects(defaultValues, newValues)) && (
        <SaveButtons
          isDisabled={!isValid}
          onCancel={cancelEdit}
          onSave={save}
          isLoading={loadingUpsert}
        />
      )}
    </Outer>
  )
}

export default Language
