import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import PageHeader from '../../../../../components/PageHeader'
import ItemWithNavlink from '../../../../../components/list/ItemWithNavlink'
import ListOuter from '../../../../../components/list/ListOuter'
import PageHeaderOuter from '../../../../../components/list/PageHeaderOuter'
import {
  CopilotPromptSettingsOverrideFragment,
  TimeTrackingCategoryId,
  TimeTrackingEntryTemplateId,
} from '../../../../../graphql'
import useSwitch from '../../../../../lib/useSwitch'
import { CopilotPromptSettingsOverrideType } from '../../assistant/useUpdateAssistantCopilot'

import CreateOverridePromptModal from './CreateOverridePromptModal'
import { Outer } from './styled'

type Props = {
  overridePrompts: CopilotPromptSettingsOverrideFragment[] | null
  overridenPromptType: CopilotPromptSettingsOverrideType
  subpromptId?: TimeTrackingCategoryId | TimeTrackingEntryTemplateId
}

const OverrideList = ({
  overridePrompts,
  subpromptId,
  overridenPromptType,
}: Props) => {
  const [isModalOpen, openModal, closeModal] = useSwitch()

  const { url } = useRouteMatch()

  return (
    <Outer>
      <PageHeaderOuter>
        <PageHeader
          createActions={[
            {
              label: 'Add new override',
              onClick: openModal,
            },
          ]}
        >
          {'User prompts'}
        </PageHeader>
      </PageHeaderOuter>
      <ListOuter>
        <ItemWithNavlink
          key={'Main'}
          text={'Main'}
          matchOptions={{ exact: true }}
          href={`${url}`}
          subText={'Applied to all users not in the other groups'}
        />
        {overridePrompts?.map(({ id, users }) => (
          <ItemWithNavlink
            href={`${url}/${id}`}
            matchOptions={{ exact: true }}
            key={id}
            text={users.map(({ profile }) => profile.givenName).join(', ')}
          />
        ))}
      </ListOuter>

      {isModalOpen && (
        <CreateOverridePromptModal
          overridenPromptType={overridenPromptType}
          subPromptId={subpromptId}
          onClose={closeModal}
        />
      )}
    </Outer>
  )
}

export default OverrideList
