import AkInlineMessage from '@atlaskit/inline-message'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

const Outer = styled.div<{ noTopUnset?: boolean }>`
  & > div > button + div {
    position: absolute !important;
    ${({ noTopUnset }) => !noTopUnset && `top: unset !important;`}
    left: 10% !important;
    bottom: 100%;
  }
`

const InlineMessage = ({
  className,
  noTopUnset,
  ...props
}: Partial<ComponentProps<typeof AkInlineMessage>> & {
  className?: string
  noTopUnset?: boolean
}) => (
  <Outer className={className} noTopUnset={noTopUnset}>
    <AkInlineMessage {...props} />
  </Outer>
)

export default InlineMessage
