import { colors, borderRadius } from '@atlaskit/theme'
import React, { ComponentProps } from 'react'
import styled, { css } from 'styled-components'

const Outer = styled.div<{ hasWarning?: boolean; hasError?: boolean }>`
  ${({ hasError, hasWarning }) => css`
    padding: 12px;
    background-color: #fff;
    border: 2px solid
      ${hasError
        ? colors.red()
        : hasWarning
        ? colors.yellow()
        : colors.backgroundHover()};
    border-radius: ${borderRadius()}px;
    box-sizing: border-box;
    transition: box-shadow linear 40ms, border-color linear 0.2s;
    position: relative;
    box-shadow: 0 0 0 transparent;
    margin: 6px 0;
    display: flex;
    flex-direction: column;
  `}
`

export type Props = ComponentProps<typeof Outer> & {
  title?: string
  hasWarning?: boolean
  hasError?: boolean
}

const Box = ({ title, children, hasError, hasWarning, ...props }: Props) => (
  <Outer hasWarning={hasWarning} hasError={hasError} {...props}>
    {title ? <h4>{title}</h4> : null}
    {children}
  </Outer>
)

export default Box
