import ShortcutIcon from '@atlaskit/icon/glyph/shortcut'
import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import Section from '../../../../components/Section'
import { Field, TextField } from '../../../../components/form'
import {
  onlyIfAssistantable,
  useGetAdminableConfigQuery,
  useUpdateAssistantMutation,
} from '../../../../graphql'

const VisitLink = styled.a`
  margin-left: 8px;
  margin-right: 8px;
`

type Props = {
  userId: string
}

const EaTeamLead = ({ userId }: Props) => {
  const [schedulingUrl, setSchedulingUrl] = useState<string>()

  const { data, loading: loadingData } = useGetAdminableConfigQuery({
    variables: {
      userId,
    },
  })

  const assistantable = onlyIfAssistantable(data?.user)

  const [update, { loading: loadingUpdate }] = useUpdateAssistantMutation()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSchedulingUrl = useCallback(
    debounce((schedulingUrl: string) => {
      update({
        variables: {
          userId,
          input: {
            schedulingUrl,
          },
        },
      })
    }, 1_000),
    [userId, update],
  )

  useEffect(() => {
    if (
      assistantable &&
      schedulingUrl &&
      schedulingUrl !== assistantable?.schedulingUrl
    ) {
      updateSchedulingUrl(schedulingUrl)
    }
  }, [updateSchedulingUrl, schedulingUrl, assistantable])

  const loading = loadingData || loadingUpdate

  return (
    <Section title={'Scheduling'}>
      <Field label={'Calendly'} helperMessage={'To schedule call'} isRequired>
        <TextField
          isDisabled={loading}
          placeholder={'https://calendly.com/...'}
          value={schedulingUrl || assistantable?.schedulingUrl || ''}
          onChangeValue={setSchedulingUrl}
          elemAfterInput={
            !!assistantable?.schedulingUrl && (
              <VisitLink
                href={assistantable?.schedulingUrl}
                target={'calendly'}
              >
                <ShortcutIcon size={'small'} label={'Visit Calendly'} />
              </VisitLink>
            )
          }
        />
      </Field>
    </Section>
  )
}

export default EaTeamLead
