import { colors } from '@atlaskit/theme'
import styled from 'styled-components'

export const Outer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.h2`
  color: ${colors.text};
`

export const Balance = styled.h3<{
  $zeroBalance: boolean
}>`
  color: ${({ $zeroBalance }) => ($zeroBalance ? colors.N100 : colors.N800)};
`
