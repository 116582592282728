import styled from 'styled-components'

import { PromptsContainer as UnstyledPromptsContainer } from '../../ExecCopilot/styled'

export const Outer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
`

export const PromptsContainer = styled(UnstyledPromptsContainer)`
  flex-wrap: wrap;
  margin-bottom: 16px;
`
