import Tooltip from '@atlaskit/tooltip'
import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

import useIsAllowedOperation from '../hooks/useIsAllowedOperation'

const Outer = styled.div<{ $blur: boolean }>`
  position: relative;
  ${({ $blur }) =>
    !!$blur &&
    css`
      filter: blur(3px);
    `}
`
const Overlay = styled.div<{ $opaque: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  ${({ $opaque }) =>
    !!$opaque &&
    css`
      background: rgba(255, 255, 255, 0.6);
    `}
`

type Props = PropsWithChildren<{
  operationId: string
  policy: 'hide' | 'blur' | 'overlay'
}>

const Guard = ({ operationId, children, policy }: Props) => {
  const isAllowedOperation = useIsAllowedOperation()

  if (isAllowedOperation(operationId)) {
    return <>{children}</>
  }

  if (policy === 'hide') {
    return null
  }

  return (
    <Tooltip
      content={`You do not have access to this resource (${operationId})`}
    >
      <Outer $blur={policy === 'blur'}>
        {children}
        <Overlay $opaque={policy === 'blur'} />
      </Outer>
    </Tooltip>
  )
}

export default Guard
