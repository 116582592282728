import Button from '@atlaskit/button'
import TagsIcon from '@atlaskit/icon/glyph/preferences'
import { PopupSelect, CheckboxOption } from '@atlaskit/select'
import React, { ComponentProps } from 'react'

import { TopicFormFieldOptionTag } from '../../../../graphql'
import graph from '../../../../graphql/generated/introspection.json'

const TAGS =
  graph.__schema.types.find(({ name }) => name === 'TopicFormFieldOptionTag')
    ?.enumValues || []

type Props = Omit<
  ComponentProps<typeof PopupSelect>,
  'onChangeValue' | 'options' | 'value'
> & {
  values?: TopicFormFieldOptionTag[] | null
  onChangeValues: (newValues: TopicFormFieldOptionTag[]) => void
}

const TopicFormFieldOptionTagSelect = ({
  values,
  onChangeValues,
  ...props
}: Props) => (
  // @ts-ignore
  <PopupSelect
    value={values?.map((value) => ({ value }))}
    isMulti
    placeholder={'Tags'}
    options={Object.values(TopicFormFieldOptionTag).map((value) => ({ value }))}
    target={({ isOpen, ...triggerProps }) => (
      <Button
        {...triggerProps}
        isSelected={isOpen}
        appearance={values?.length ? 'primary' : 'default'}
        iconBefore={<TagsIcon label={''} />}
      />
    )}
    getOptionLabel={({ value }) =>
      // @ts-ignore
      TAGS.find(({ name }) => name === value)?.description?.trim() || value
    }
    getOptionValue={({ value }) => value}
    // @ts-ignore
    isOptionSelected={({ value }) => !!values?.includes(value)}
    onChange={(values) =>
      // @ts-ignore
      onChangeValues((values || []).map(({ value }) => value))
    }
    searchThreshold={10}
    hideSelectedOptions={false}
    closeMenuOnSelect // otherwise weird bug
    components={{
      Option: CheckboxOption,
    }}
    {...props}
  />
)

export default TopicFormFieldOptionTagSelect
