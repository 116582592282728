import { Radio } from '@atlaskit/radio'
import React from 'react'
import styled from 'styled-components'

import { Field } from '../../../components/form'

import { SortKey } from './utils'

const Outer = styled.div``

type Props = {
  value: SortKey
  onChangeValue: (newValue: SortKey) => void
}

const QueryEditor = ({ value, onChangeValue }: Props) => (
  <Outer>
    <Field label={'Sort by'}>
      <Radio
        value={SortKey.FAMILY_NAME}
        label={'Last name'}
        name={'sort-key'}
        isChecked={value === SortKey.FAMILY_NAME}
        onChange={() => onChangeValue(SortKey.FAMILY_NAME)}
      />
      <Radio
        value={SortKey.GIVEN_NAME}
        label={'First name'}
        name={'sort-key'}
        isChecked={value === SortKey.GIVEN_NAME}
        onChange={() => onChangeValue(SortKey.GIVEN_NAME)}
      />
    </Field>
  </Outer>
)

export default QueryEditor
