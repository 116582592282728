import { useApolloClient } from '@apollo/client'
import times from 'lodash/times'
import { useRouteMatch } from 'react-router-dom'

import {
  onlyIfType,
  Matching_ForTransitionFullFragment,
  Matching_ForTransitionListItemFragment,
  Matching_ForTransitionListItemFragmentDoc,
  useGetMatchingByIdQuery,
} from '../../../graphql'
import { notFalsy } from '../../../lib/utils'

import { buildBundles, Bundled } from './utils'

const STAGE_IDS = [
  'informing',
  'matching',
  'scheduling',
  'handover',
  'changeover',
]

const useActiveMatching = () => {
  const routeMatch = useRouteMatch<{
    matchingId: string
    stageId: string
  }>('*/(transitions|matchings)/:matchingId/:stageId?')

  const { matchingId: bundleId, stageId } = routeMatch?.params || {}
  const matchingIds = bundleId?.split(',') || []

  const client = useApolloClient()

  const cachedMatchings = matchingIds
    .map((matchingId) =>
      onlyIfType('Matching')(
        client.readFragment<Matching_ForTransitionListItemFragment>({
          fragment: Matching_ForTransitionListItemFragmentDoc,
          fragmentName: 'Matching_ForTransitionListItem',
          id: `Matching:${matchingId}`,
        }),
      ),
    )
    .filter(notFalsy)

  const freshMatchings = times(20)
    .map(
      (index) =>
        // MEGA HACK
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useGetMatchingByIdQuery({
          variables: {
            matchingId: matchingIds[index] || '',
          },
          skip: !matchingIds[index],
        }).data?.matching,
    )
    .filter(notFalsy)

  const matching: null | Bundled<Matching_ForTransitionFullFragment> =
    freshMatchings.length === matchingIds.length
      ? buildBundles(freshMatchings)[0]
      : cachedMatchings.length === matchingIds.length
      ? (buildBundles(
          cachedMatchings,
        )[0] as Bundled<Matching_ForTransitionFullFragment>)
      : null

  return {
    stageId: stageId && STAGE_IDS.includes(stageId) ? stageId : undefined,
    matching,
  }
}

export default useActiveMatching
