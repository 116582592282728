import {
  ApolloClient,
  InMemoryCache,
  from,
  split,
  ApolloLink,
  NormalizedCacheObject,
} from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'
import { v4 as uuid } from 'uuid'

import auth from '../lib/auth'

import introspection from './generated/introspection'
import { GraphQLError } from './types'

const sessionId = uuid()

const httpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT!.replace('http', 'ws'),
  }),
)

const httpHeadersLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: auth.token ? `Bearer ${auth.token}` : '',
    // Set Request ID: UUID
    'X-Request-ID': uuid(),
    'X-Session-ID': sessionId,
  },
}))

const wsHeadersLink = new ApolloLink((operation, forward) => {
  operation.extensions.headers = {
    authorization: auth.token ? `Bearer ${auth.token}` : '',
    // Set Request ID: UUID
    'X-Request-ID': uuid(),
    'X-Session-ID': sessionId,
  }
  return forward(operation)
})

const getErrorLink = (onGraphQLError: (graphQLError: GraphQLError) => void) =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      // filter out `me` queries because we expect them to fail on initial load
      const filteredErrors = graphQLErrors?.filter(
        ({ path }) => !path?.includes('me'),
      )

      filteredErrors.forEach((error) => {
        const { message, path } = error
        console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`)
        onGraphQLError(error)
      })
    }
    if (networkError) console.error(`[Network error]: ${networkError}`)
  })

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    const useWs =
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    return useWs
  },
  from([wsHeadersLink, wsLink]),
  from([httpHeadersLink, httpLink]),
)

let client: ApolloClient<NormalizedCacheObject>

const getApolloClient = ({
  onGraphQLError,
}: {
  onGraphQLError: (graphQLError: GraphQLError) => void
}) => {
  if (!client) {
    client = new ApolloClient({
      cache: new InMemoryCache({
        possibleTypes: introspection.possibleTypes,
      }),
      link: from([
        getErrorLink(onGraphQLError),
        createPersistedQueryLink({
          async sha256(message: string): Promise<string> {
            const msgUint8 = new TextEncoder().encode(message) // encode as (utf-8) Uint8Array
            const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8) // hash the message
            // eslint-disable-next-line
            const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
            const hashHex = hashArray
              .map((b) => b.toString(16).padStart(2, '0'))
              .join('') // convert bytes to hex string
            return hashHex
          },
        }),
        splitLink,
      ]),
    })
  }

  return client
}

export default getApolloClient
