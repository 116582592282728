import { DateTime } from 'luxon'

import { ContractorReportFragment } from '../../../graphql'
import { csvFormater, CsvColumn } from '../../../lib/csv'
import { createWritableCSVFileStream } from '../../../lib/writeFile'

import {
  getContractorEmail,
  getReportDisplayName,
  getTotalAmount,
  formatHours,
  formatAmount,
  ReportsQuery,
} from './utils'

export enum Format {
  REGULAR = 'REGULAR',
  JUSTWORKS = 'JUSTWORKS',
  VENDORS = 'VENDORS',
}

async function exportToCsv(
  reports: ContractorReportFragment[],
  query: ReportsQuery,
  format: Format,
) {
  const columns: (CsvColumn<ContractorReportFragment> & {
    formats: Format[]
  })[] = [
    {
      header: 'Name',
      getter: getReportDisplayName,
      formats: [Format.REGULAR],
    },
    {
      header: 'Email',
      getter: getContractorEmail,
      formats: [Format.REGULAR],
    },
    {
      header: 'Vendor Name',
      getter: (r) => r.user?.justworksVendorName,
      formats: [Format.REGULAR, Format.VENDORS],
    },
    {
      header: 'Justworks Given Name',
      getter: (r) => r.user?.justworksGivenName,
      formats: [Format.REGULAR, Format.JUSTWORKS],
    },
    {
      header: 'Justworks Family Name',
      getter: (r) => r.user?.justworksFamilyName,
      formats: [Format.REGULAR, Format.JUSTWORKS],
    },
    {
      header: 'Justworks Email',
      getter: (r) => r.user?.personalEmail,
      formats: [Format.REGULAR, Format.JUSTWORKS],
    },
    {
      header: 'EIN',
      getter: (r) => r.user?.ein,
      formats: [Format.REGULAR, Format.VENDORS],
    },
    {
      header: 'Pay Date',
      getter: () => '',
      formats: [Format.JUSTWORKS, Format.VENDORS],
    },
    {
      header: 'Hours',
      getter: (r) => formatHours(r.totalHours || 0, false),
      formats: [Format.REGULAR],
    },
    {
      header: 'Currency',
      getter: (r) => r.user?.currency,
      formats: [Format.REGULAR, Format.JUSTWORKS, Format.VENDORS],
    },
    {
      header: 'Rate',
      getter: (r) => formatAmount(r.user?.hourlyRate),
      formats: [Format.REGULAR],
    },
    {
      header: format === Format.REGULAR ? 'Total' : 'Amount',
      getter: (r) => formatAmount(getTotalAmount(r)),
      formats: [Format.REGULAR, Format.JUSTWORKS, Format.VENDORS],
    },
    {
      header: 'Notes',
      getter: () =>
        `${DateTime.fromJSDate(
          query.from,
        ).toISODate()} to ${DateTime.fromJSDate(query.to).toISODate()}`,
      formats: [Format.JUSTWORKS, Format.VENDORS],
    },
  ]

  const stream = await createWritableCSVFileStream(
    `Double ${
      format === Format.JUSTWORKS
        ? 'JustWorks'
        : format === Format.VENDORS
        ? 'Vendors'
        : 'Contractors'
    } from ${DateTime.fromJSDate(
      query.from,
    ).toISODate()} to ${DateTime.fromJSDate(
      query.to,
    ).toISODate()} (exported on ${new Date().toISOString().split('T')[0]}).csv`,
  )

  const { headersLine, toLine } = csvFormater<ContractorReportFragment>(
    columns.filter(({ formats }) => formats.includes(format)),
  )

  await stream.write(headersLine)

  for (const report of reports) {
    await stream.write(toLine(report))
  }

  await stream.close()
}

export default exportToCsv
