// @ts-ignore
import { Item as AtlaskitItem } from '@atlaskit/navigation-next'
import React, { ComponentProps } from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

const ItemWithNavlink = ({
  href,
  matchOptions,
  ...props
}: ComponentProps<typeof AtlaskitItem> & {
  href?: string
  matchOptions?: Parameters<typeof useRouteMatch>[0]
}) => {
  const match = useRouteMatch({ path: href, ...matchOptions })

  if (!href) {
    return <AtlaskitItem {...props} />
  }

  return (
    <NavLink to={href}>
      <AtlaskitItem {...props} isSelected={!!match} />
    </NavLink>
  )
}

export default ItemWithNavlink
