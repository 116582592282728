import { useMemo } from 'react'

import { CopilotFunction, useAssistantCopilotQuery } from '../../../../graphql'

const useSystemFunctions = (): {
  systemFunctions: CopilotFunction[] | null | undefined
  loading: boolean
} => {
  const { data, loading } = useAssistantCopilotQuery()

  const systemFunctions = useMemo(() => {
    if (!data) return null

    return [
      ...(data.assistantCopilot.everydayTasksCopilotPromptSettings.functions ||
        []),
      ...(data.assistantCopilot.execTasksCopilotPromptSettings.functions || []),
    ]
  }, [data])

  return { systemFunctions, loading }
}

export default useSystemFunctions
