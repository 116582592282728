import { colors } from '@atlaskit/theme'
import styled from 'styled-components'

const ListContainer = styled.div`
  background-color: ${colors.N20};
  display: flex;
  flex-direction: column;
  padding: 0;
  flex: 1 1 30%;
`

export default ListContainer
