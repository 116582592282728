import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Avatar from '../../../../../../components/users/Avatar'
import UserCell from '../../../../../../components/users/Cell'
import { Matching_ForTransitionListItemFragment } from '../../../../../../graphql'

const Outer = styled.span`
  display: flex;
  align-items: center;
`

type Props = {
  matching: Matching_ForTransitionListItemFragment
  withName?: boolean
}

const SuccessSpecialist = ({ matching, withName }: Props) => {
  const specialist = matching.transition?.csm

  if (!specialist) {
    return null
  }

  return (
    <Outer title={specialist.profile.displayName || ''}>
      <Link to={`/users/${specialist.id}`} target={'_blank'}>
        {withName ? (
          <UserCell user={specialist} />
        ) : (
          <Avatar user={specialist} />
        )}
      </Link>
    </Outer>
  )
}

export default SuccessSpecialist
