import React from 'react'

import Badge from '../../../../components/Badge'
import { Matching_IsTeamInviteFragment } from '../../../../graphql'

type Props = {
  matching: Matching_IsTeamInviteFragment
  noText?: boolean
}

const TeamInviteBadge = ({ matching, noText }: Props) => {
  if (!matching.isTeamInvite) {
    return null
  }

  return (
    <Badge
      emoji={'+1'}
      title={'+1 Teammate'}
      text={noText ? undefined : 'Teammate'}
    />
  )
}

export default TeamInviteBadge
