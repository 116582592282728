import Button from '@atlaskit/button'
import InlineMessage from '@atlaskit/inline-message'
import Select from '@atlaskit/select'
import { useState, useCallback } from 'react'

import ErrorBanner from '../../../../../components/ErrorBanner'
import Guard from '../../../../../components/Guard'
import Modal from '../../../../../components/Modal'
import { Field, TextField } from '../../../../../components/form'
import {
  PoolTokenGrantSource,
  useGrantPoolTokensMutation,
} from '../../../../../graphql'
import useSwitch from '../../../../../lib/useSwitch'
import { getTokenString } from '../utils'

import { ButtonContainer, ModalOuter } from './styled'

type Props = {
  executiveId: string
}

type Option = { label: string; value: string }

const OPTIONS = Object.keys(PoolTokenGrantSource).map((key) => ({
  label: key,
  value: key as PoolTokenGrantSource,
})) as Option[]

const TOKEN_MAX = 30
const TOKEN_MIN = 0

const GrantPoolTokens = ({ executiveId }: Props) => {
  const [isModalOpen, openModal, closeModal] = useSwitch()
  const [isSuccessShown, showSuccess, hideSuccess] = useSwitch()
  const [grantPoolTokens, { loading, error }] = useGrantPoolTokensMutation()
  const [tokensState, setTokensState] = useState<number>(0)
  const [grantSourceState, setGrantSourceState] =
    useState<PoolTokenGrantSource | null>(PoolTokenGrantSource.GIFT)

  const onGrantTokensComplete = useCallback(() => {
    showSuccess()
    setTokensState(0)
    setTimeout(() => {
      hideSuccess()
      closeModal()
    }, 2000)
  }, [closeModal, hideSuccess, showSuccess])

  const onGrantTokensClick = useCallback(() => {
    if (tokensState && grantSourceState) {
      grantPoolTokens({
        variables: {
          input: {
            userId: executiveId,
            poolTokens: tokensState,
            source: grantSourceState,
          },
        },
        onCompleted: onGrantTokensComplete,
      })
    }
  }, [
    executiveId,
    grantPoolTokens,
    grantSourceState,
    onGrantTokensComplete,
    tokensState,
  ])

  const invalidTokenValue = tokensState < TOKEN_MIN || tokensState > TOKEN_MAX

  return (
    <>
      <Guard operationId={'Mutation.grantPoolTokens'} policy={'overlay'}>
        <ButtonContainer>
          <Button onClick={openModal} appearance={'primary'}>
            {'Grant tokens'}
          </Button>
        </ButtonContainer>
      </Guard>
      <Modal
        isOpen={isModalOpen}
        heading={`Add Tokens to Exec's account`}
        actions={[
          {
            text: `Confirm ${grantSourceState} ${getTokenString(
              tokensState || 0,
            )}`,
            onClick: onGrantTokensClick,
            isDisabled: invalidTokenValue || !tokensState,
            isLoading: loading,
          },
          {
            text: 'Cancel',
            onClick: closeModal,
          },
        ]}
        autoFocus={false}
        onClose={closeModal}
        height={400}
      >
        <ModalOuter>
          {isSuccessShown && (
            <InlineMessage
              title={'Tokens were granted!'}
              appearance={'confirmation'}
            />
          )}
          <ErrorBanner error={error} />
          <Field label={'Grant tokens to an executive account'} isRequired>
            <Select
              inputId={'grant-source-select'}
              value={OPTIONS.find(({ value }) => value === grantSourceState)}
              onChange={(option) => {
                setGrantSourceState(option?.value as PoolTokenGrantSource)
              }}
              options={OPTIONS}
            />
          </Field>
          <Field
            label={'How many tokens would you like to grant?'}
            helperMessage={'Acceptable amounts: 1-30'}
            isRequired
          >
            <TextField
              id={'token-input'}
              type={'number'}
              value={tokensState}
              min={TOKEN_MIN}
              max={TOKEN_MAX}
              isInvalid={invalidTokenValue}
              onChangeValue={(value) => setTokensState(parseInt(value, 10))}
              required
            />
          </Field>
        </ModalOuter>
      </Modal>
    </>
  )
}

export default GrantPoolTokens
