import { Link } from 'react-router-dom'
import styled from 'styled-components'

import UserCell from '../../../../../../components/users/Cell'
import {
  AdhocExecutiveFragment,
  Userable_MinimalFragment,
} from '../../../../../../graphql'

const Outer = styled.span`
  display: flex;
  align-items: center;
`

export type ExecutiveProps = {
  executive?: Userable_MinimalFragment | AdhocExecutiveFragment | null
}

const Executive = ({ executive }: ExecutiveProps) => {
  if (!executive) {
    return null
  }

  return (
    <Outer>
      {executive.__typename === 'ExecutiveUser' ? (
        <Link to={`/users/${executive.id}`} target={'_blank'}>
          <UserCell user={executive} />
        </Link>
      ) : executive.__typename === 'AdhocExecutive' ? (
        <>{`${executive.givenName} ${executive.familyName}`}</>
      ) : null}
    </Outer>
  )
}

export default Executive
