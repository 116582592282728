import React from 'react'

import { useGetWorkspaceOnboardingAvailabilityQuery } from '../../graphql'
import useSwitch from '../../lib/useSwitch'

import OnboardingAvailabilityButton, {
  OnboardingAvailabilityButtonProps,
} from './OnboardingAvailabilityButton'

type Props = Partial<OnboardingAvailabilityButtonProps> & {
  workspaceId: string
}

const WorkspaceOnboardingAvailabilityButton = ({
  workspaceId,
  ...buttonProps
}: Props) => {
  const [isOpen, open, close] = useSwitch(false)

  const { data, loading, error } = useGetWorkspaceOnboardingAvailabilityQuery({
    variables: {
      workspaceId,
    },
    skip: !isOpen,
  })

  return (
    <OnboardingAvailabilityButton
      isOpen={isOpen}
      open={open}
      close={close}
      slots={data?.workspace.onboarding?.availability.slots}
      error={error}
      isLoading={loading}
      {...buttonProps}
    />
  )
}

export default WorkspaceOnboardingAvailabilityButton
