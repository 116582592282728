import { LoadingButton as Button } from '@atlaskit/button'
import EmojiIcon from '@atlaskit/icon/glyph/emoji-add'
import Popup from '@atlaskit/popup'
import { colors } from '@atlaskit/theme'
import { Picker, emojiIndex } from 'emoji-mart'
import React from 'react'
import styled from 'styled-components'

import useSwitch from '../lib/useSwitch'

const EmojiPickerContainer = styled.div`
  * {
    position: relative;
  }

  .emoji-mart {
    border: none;
  }

  .emoji-mart-search {
    .emoji-mart-sr-only,
    .emoji-mart-search-icon {
      position: absolute;
    }
  }
`

type Props = {
  value?: string | undefined | null
  onChangeValue?: (newValue: string) => void
  useColons?: boolean
}

const EmojiPicker = ({ value, onChangeValue, useColons }: Props) => {
  const [isEmojiPickerOpen, , closeEmojiPicker, toggleEmojiPicker] = useSwitch()

  const displayedValue =
    (value &&
      useColons &&
      // @ts-ignore
      emojiIndex.search(value.replace(/:/g, ''))?.[0]?.native) ||
    value

  return (
    <Popup
      isOpen={isEmojiPickerOpen}
      onClose={closeEmojiPicker}
      placement={'bottom-start'}
      content={() => (
        <EmojiPickerContainer>
          <Picker
            set={'apple'}
            useButton={false}
            theme={'auto'}
            showSkinTones={false}
            native
            showPreview={false}
            color={colors.primary()}
            // @ts-ignore
            onSelect={({ native: emoji, colons }) => [
              onChangeValue?.(useColons ? colons : emoji),
              closeEmojiPicker(),
            ]}
          />
        </EmojiPickerContainer>
      )}
      trigger={(triggerProps) => (
        <Button
          {...triggerProps}
          appearance={'subtle'}
          onClick={toggleEmojiPicker}
          isSelected={isEmojiPickerOpen}
        >
          {displayedValue || (
            <EmojiIcon
              size={'small'}
              label={''}
              primaryColor={colors.placeholderText()}
            />
          )}
        </Button>
      )}
    />
  )
}

export default EmojiPicker
