import {
  TransitionInformingCategory,
  TransitionMatchingStatus,
  Matching_ForTransitionListItemFragment,
} from '../../../../graphql'

export enum ListView {
  ACTIVE = 'ACTIVE',
  MINE = 'MINE',
  ARCHIVED = 'ARCHIVED',
}

export const LIST_VIEW_LABEL: Record<ListView, string> = {
  [ListView.ACTIVE]: 'All transitions',
  [ListView.MINE]: 'My transitions',
  [ListView.ARCHIVED]: 'Archived transitions',
}

export enum TransitionStatus {
  INFORMING = 'INFORMING',
  INFORMING_CHURN_RISK = 'INFORMING_CHURN_RISK',
  INFORMING_DETERMINING_NEEDS = 'INFORMING_DETERMINING_NEEDS',
  INFORMING_NON_RESPONSIVE = 'INFORMING_NON_RESPONSIVE',
  INFORMING_ACCEPTED = 'INFORMING_ACCEPTED',
  READY_TO_MATCH = 'READY_TO_MATCH',
  MATCH_PROPOSED = 'MATCH_PROPOSED',
  CANDIDATES = 'CANDIDATES',
  MATCH_CONFIRMED = 'MATCH_CONFIRMED',
  SCHEDULED = 'SCHEDULED',
  COMPLETED = 'COMPLETED',
}

export const TRANSITION_STATUS_LABEL: Record<TransitionStatus, string> = {
  [TransitionStatus.INFORMING]: 'On hold',
  [TransitionStatus.INFORMING_CHURN_RISK]: 'Churn risk',
  [TransitionStatus.INFORMING_NON_RESPONSIVE]: 'Non responsive',
  [TransitionStatus.INFORMING_DETERMINING_NEEDS]: 'Determining needs',
  [TransitionStatus.INFORMING_ACCEPTED]: 'Accepted',
  [TransitionStatus.READY_TO_MATCH]: 'Ready to match',
  [TransitionStatus.MATCH_PROPOSED]: 'Match proposed',
  [TransitionStatus.CANDIDATES]: 'Candidate confirmation',
  [TransitionStatus.MATCH_CONFIRMED]: 'Match confirmed',
  [TransitionStatus.SCHEDULED]: 'Changeover call scheduled',
  [TransitionStatus.COMPLETED]: 'Changeover call completed',
}

export const getTransitionStatus = ({
  candidates,
  transition,
  onboarding,
}: Matching_ForTransitionListItemFragment): TransitionStatus => {
  if (!transition) {
    return TransitionStatus.INFORMING
  }

  if (onboarding.isCompleted) {
    return TransitionStatus.COMPLETED
  }

  if (onboarding.callStartAt) {
    return TransitionStatus.SCHEDULED
  }

  if (transition.matchingStatus === TransitionMatchingStatus.MATCH_CONFIRMED) {
    return TransitionStatus.MATCH_CONFIRMED
  }

  if (transition.matchingStatus === TransitionMatchingStatus.MATCH_PROPOSED) {
    if ((candidates?.length || 0) > 1) {
      return TransitionStatus.CANDIDATES
    } else {
      return TransitionStatus.MATCH_PROPOSED
    }
  }

  if (transition.matchingStatus === TransitionMatchingStatus.READY_TO_MATCH) {
    return TransitionStatus.READY_TO_MATCH
  }

  if (transition.informingCategory === TransitionInformingCategory.CHURN_RISK) {
    return TransitionStatus.INFORMING_CHURN_RISK
  }

  if (
    transition.informingCategory ===
    TransitionInformingCategory.DETERMINING_NEEDS
  ) {
    return TransitionStatus.INFORMING_DETERMINING_NEEDS
  }

  if (
    transition.informingCategory === TransitionInformingCategory.NON_RESPONSIVE
  ) {
    return TransitionStatus.INFORMING_NON_RESPONSIVE
  }

  if (transition.informingCategory === TransitionInformingCategory.ACCEPTED) {
    return TransitionStatus.INFORMING_ACCEPTED
  }

  return TransitionStatus.INFORMING
}
