import { createContext, useContext } from 'react'

export type Sorting = { key: string }
type SortingContextValue = [Sorting, (value: Sorting) => void]

const SortingContext = createContext<SortingContextValue>([
  { key: 'transition.requestedOn' },
  () => null,
])

export const Provider = SortingContext.Provider

export const useSorting = (): SortingContextValue => useContext(SortingContext)
