import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message'
import React from 'react'

import {
  StripeCustomerPaymentStatus,
  StripeCustomer_PaymentStatusFragment,
} from '../../graphql'

import { SectionMessageOuter } from './styled'

type Props = {
  stripeCustomer?: StripeCustomer_PaymentStatusFragment | null
  loading?: boolean
  createStripeCustomer: () => void
}

const StripeCustomer = ({
  stripeCustomer,
  loading,
  createStripeCustomer,
}: Props) => {
  return (
    <>
      {stripeCustomer ? (
        <SectionMessageOuter>
          <SectionMessage
            appearance={
              stripeCustomer?.paymentStatus === StripeCustomerPaymentStatus.CARD
                ? 'success'
                : stripeCustomer?.paymentStatus ===
                  StripeCustomerPaymentStatus.REJECTED
                ? 'error'
                : 'warning'
            }
            title={
              stripeCustomer?.paymentStatus === StripeCustomerPaymentStatus.CARD
                ? 'Valid card'
                : stripeCustomer?.paymentStatus ===
                  StripeCustomerPaymentStatus.REJECTED
                ? 'Payments were rejected'
                : stripeCustomer?.paymentStatus ===
                  StripeCustomerPaymentStatus.MANUAL
                ? 'Payments are sent manually'
                : stripeCustomer?.paymentStatus ===
                  StripeCustomerPaymentStatus.WIRE
                ? 'ACH Credit'
                : 'Missing payment method'
            }
            actions={[
              <SectionMessageAction
                key={'open-in-stripe'}
                onClick={() =>
                  window.open(
                    `https://dashboard.stripe.com/customers/${encodeURIComponent(
                      stripeCustomer.id || '',
                    )}`,
                    '_blank',
                  )
                }
              >
                {'Open in Stripe'}
              </SectionMessageAction>,
            ]}
          >
            {null}
          </SectionMessage>
        </SectionMessageOuter>
      ) : !loading ? (
        <SectionMessageOuter>
          <SectionMessage
            appearance={'error'}
            title={'Missing Stripe customer'}
            actions={[
              <SectionMessageAction
                key={'create-stripe-customer'}
                onClick={() => createStripeCustomer()}
              >
                {'Create Stripe customer'}
              </SectionMessageAction>,
            ]}
          >
            {null}
          </SectionMessage>
        </SectionMessageOuter>
      ) : null}
    </>
  )
}

export default StripeCustomer
