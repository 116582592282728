import React from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import InfiniteLoader from 'react-window-infinite-loader'

import { ErrorEmptyState } from '../../../../components/EmptyState'
import Spinner, { LoadingSpinner } from '../../../../components/Spinner'
import {
  useListAutomationJobsQuery,
  withListPagination,
} from '../../../../graphql'
import { ListFilter, reduceFilter } from '../utils'

import Item, { ITEM_HEIGHT } from './Item'
import { ListOuter, ListInner, ItemContainer } from './styled'

type JobsListProps = {
  filter: ListFilter
  onClickItem?: (jobId: string) => void
}

const JobsList = ({ filter, onClickItem }: JobsListProps) => {
  const { data, loading, error, fetchMore, hasMore } = withListPagination(
    useListAutomationJobsQuery({
      variables: {
        ...reduceFilter(filter),
        first: 40,
      },
    }),
  )

  if (error) {
    return <ErrorEmptyState error={error} />
  }

  if (loading || !data) {
    return <LoadingSpinner />
  }

  const jobs = data?.list.items || []

  const isLoadingInitial = jobs.length === 0 && loading
  const itemCount = isLoadingInitial
    ? 30
    : hasMore
    ? jobs.length + 1
    : jobs.length
  const isItemLoaded = (index: number): boolean =>
    !hasMore || index < jobs.length

  return (
    <ListOuter>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            // @ts-ignore
            loadMoreItems={fetchMore}
          >
            {({ onItemsRendered, ref }) => (
              <ListInner
                itemCount={itemCount}
                itemSize={ITEM_HEIGHT}
                onItemsRendered={onItemsRendered}
                height={height}
                width={width}
                ref={ref}
              >
                {({ index, style }) => (
                  <ItemContainer key={jobs[index]?.id || index} style={style}>
                    {isLoadingInitial || !isItemLoaded(index) ? (
                      <Spinner />
                    ) : (
                      <Item
                        key={jobs[index].id}
                        job={jobs[index]}
                        onClick={onClickItem}
                      />
                    )}
                  </ItemContainer>
                )}
              </ListInner>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </ListOuter>
  )
}

export default JobsList
