import { colors, typography, borderRadius } from '@atlaskit/theme'
import { FixedSizeList } from 'react-window'
import styled, { css } from 'styled-components'

export const ListOuter = styled.div`
  flex: 1;
`

export const ListInner = styled(FixedSizeList)`
  padding: 8px 0;
`

export const ITEM_HEIGHT = 110

export const ItemContainer = styled.div`
  height: ${ITEM_HEIGHT}px;
  padding: 3px 0;
`

export const ItemOuter = styled.div<{
  hasFailed?: boolean
  isPending?: boolean
  hasCompleted?: boolean
  isCancelled?: boolean
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: ${borderRadius}px;
  height: 100%;
  padding: 8px 12px 6px;
  background: ${colors.N20};
  border-left: 4px solid
    ${({ hasFailed, isPending, hasCompleted, isCancelled }) =>
      isCancelled
        ? colors.yellow()
        : hasCompleted
        ? colors.green()
        : isPending
        ? colors.blue()
        : hasFailed
        ? colors.red()
        : colors.blue()};
  &:hover {
    background: ${colors.backgroundHover};
  }

  ${({ onClick }) =>
    !!onClick &&
    css`
      cursor: pointer;
    `}
`

export const SectionName = styled.span`
  ${typography.h200};
  padding: 0;
  margin: 0;
  margin-bottom: 2px;
`

export const AutomationName = styled.h3`
  ${typography.h500};
  padding: 0;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const MetaColumn = styled(Column)`
  align-items: flex-end;
  overflow: visible;
  padding-top: 19px;

  & > * + * {
    margin-top: 4px;
  }
`
