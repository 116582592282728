import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import * as Sentry from '@sentry/react'
import 'emoji-mart/css/emoji-mart.css'
import 'normalize.css/normalize.css'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import './index.css'

if (
  window.location.href.startsWith('http:') &&
  !window.location.href.startsWith('http://localhost')
) {
  window.location.href = 'https' + window.location.href.substring(4)
}

if (
  process.env.REACT_APP_SENTRY_DSN &&
  !window.location.href.startsWith('http://localhost')
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  })
}

ReactDOM.render(<App />, document.getElementById('root'))
