import Avatar, { Skeleton, AvatarPropTypes } from '@atlaskit/avatar'
import AvatarGroup from '@atlaskit/avatar-group'
import React from 'react'

import { Userable_MinimalFragment, useMinimalUser } from '../../../graphql'

import poolIcon from './icons/Pool.svg'

const AVATARS_COUNT = 3

type AvatarUser = Pick<Userable_MinimalFragment, 'id'> & {
  profile?: null | undefined | Partial<Userable_MinimalFragment['profile']>
}

export interface Props extends Omit<AvatarPropTypes, 'url'> {
  skeleton?: boolean
  user?: AvatarUser
  isPool?: boolean
}

const UserAvatar = ({
  user: baseUser,
  skeleton,
  size = 'medium',
  isPool = false,
  ...props
}: Props) => {
  const user = useMinimalUser(baseUser?.id)

  skeleton = skeleton || (!user && !isPool)

  if (skeleton) {
    return <Skeleton appearance={'circle'} size={size} />
  }

  return (
    <Avatar
      src={isPool ? poolIcon : user?.profile?.avatarUrl || undefined}
      appearance={'circle'}
      size={size}
      name={user?.profile?.displayName || ''}
      {...props}
    />
  )
}

interface UserAvatarsGroupProps extends Omit<Props, 'user'> {
  users: AvatarUser[]
  maxCount?: number
}

export const UserAvatarsGroup = ({
  users,
  skeleton,
  maxCount = AVATARS_COUNT,
  ...props
}: UserAvatarsGroupProps) =>
  users?.length > 1 ? (
    // @ts-ignore
    <AvatarGroup
      // @ts-ignore
      avatar={UserAvatar}
      // @ts-ignore
      data={users.map((user) => ({ user, skeleton }))}
      maxCount={maxCount}
      {...props}
    />
  ) : (
    <UserAvatar user={users[0]} skeleton={skeleton} {...props} />
  )

export default UserAvatar
