import { useState, useEffect } from 'react'

type Options = {
  offset?: number
}

const useHasScrolledToBottom = (
  element: HTMLDivElement | undefined,
  { offset }: Options = {},
) => {
  const [isAtBottom, setIsAtBottom] = useState(false)

  useEffect(() => {
    if (!element) {
      return
    }

    const getIsAtBottom = () =>
      element.scrollHeight - element.scrollTop - element.clientHeight <
      (offset || 0)

    setIsAtBottom(getIsAtBottom())

    const onScroll = () => setIsAtBottom(getIsAtBottom())

    element.addEventListener('scroll', onScroll, { passive: true })

    return () => element.removeEventListener('scroll', onScroll)
  }, [element, offset])

  return isAtBottom
}

export const useOnHasScrolledToBottomEffect = (
  element: HTMLDivElement | undefined,
  onScrollToBottom: () => void,
  options: Options & {
    dependencies?: any[]
  } = {},
) => {
  const hasScrolledToBottom = useHasScrolledToBottom(element, options)
  useEffect(() => {
    if (!hasScrolledToBottom) return

    onScrollToBottom()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasScrolledToBottom, ...(options.dependencies ?? [])]) // Only run on hasScrolledToBottom change
}

export default useHasScrolledToBottom
