import Icon from '@atlaskit/icon/glyph/flag-filled'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { ErrorEmptyState } from '../../../components/EmptyState'
import { LoadingSpinner } from '../../../components/Spinner'
import { useListFeaturesQuery } from '../../../graphql'
import { Page } from '../../types'

import Feature from './Feature'
import List from './List'

const Outer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  height: 100%;
`

const Features: Page = () => {
  const { data, loading, error } = useListFeaturesQuery()
  const { url } = useRouteMatch()
  const {
    params: { featureId },
  } = useRouteMatch<{ featureId: string }>(`${url}/:featureId`) || {
    params: {},
  }

  if (error) {
    return <ErrorEmptyState error={error} />
  }

  if (loading || !data) {
    return <LoadingSpinner />
  }

  return (
    <Outer>
      <List features={data.list.items} selectedFeatureId={featureId} />
      <Feature
        feature={
          featureId
            ? data.list.items.find(({ id }) => id === featureId)
            : undefined
        }
      />
    </Outer>
  )
}

Features.navTitle = 'Feature Flags'
Features.path = '/features'
Features.Icon = Icon
Features.requiredOperationIds = ['Query.featuresList']

export default Features
