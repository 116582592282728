import Icon from '@atlaskit/icon/glyph/lock'
import { Route, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { Page } from '../../types'

import AdminRole from './AdminRole'
import List from './List'
import { PATH } from './utils'

const Outer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  min-width: 600px;
  max-width: 1000px;
  height: 100%;
`

const AdminRoles: Page = () => {
  const { url } = useRouteMatch()
  const {
    params: { adminRoleId },
  } = useRouteMatch<{ adminRoleId: string }>(`${url}/:adminRoleId`) || {
    params: {},
  }

  return (
    <Outer>
      <List />
      <Route path={`${url}/:adminRoleId?`}>
        {adminRoleId && (
          <AdminRole key={adminRoleId} adminRoleId={adminRoleId} />
        )}
      </Route>
    </Outer>
  )
}

AdminRoles.path = PATH
AdminRoles.navTitle = 'Admin Roles'
AdminRoles.Icon = Icon
AdminRoles.requiredOperationIds = [
  'Query.adminRolesList',
  'Query.guardedOperations',
]

export default AdminRoles
