import Avatar from '@atlaskit/avatar'
import Lozenge from '@atlaskit/lozenge'
import { colors, typography } from '@atlaskit/theme'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { LoadingSpinner } from '../../components/Spinner'
import { getLanguageName } from '../../components/languages/utils'
import { UserCategory } from '../../graphql'

import useWhos from './useWhos'
import { formatBirthday, formatStartDate } from './utils'

const Row = styled.div`
  display: flex;
  flex-direction: row;

  & + & {
    padding-top: 16px;
  }
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`

const Outer = styled(Row)`
  background: ${colors.background};
  flex: 0 0 auto;
  min-height: 120px;
  max-height: 30vh;
  padding: 0;
  border-top: solid ${colors.backgroundHover} 2px;
`

const Section = styled(Column)`
  padding: 0 16px;
  flex-basis: 50%;
`

const NameSection = styled(Section)`
  flex-grow: 0;
  white-space: nowrap;
  flex-basis: auto;
  padding: 16px;
`

const TextsColumn = styled(Column)`
  overflow: auto;
  padding: 16px;
`

const SectionTitle = styled.h3`
  ${typography.h100()}
  text-transform: uppercase;
  opacity: 0.7;
  margin: 0 0 4px;

  * + & {
    margin-top: 8px;
  }
`

const NameRow = styled(Row)`
  align-items: center;
  & > *:first-child {
    margin-right: 8px;
  }
`

const Name = styled.h1`
  ${typography.h600()}
  margin: 6px 0 8px;
`

const Text = styled.span`
  color: ${colors.text};
`

const SubtleText = styled.span`
  color: ${colors.subtleText};
  font-size: 0.8em;
`

const Detail = () => {
  const { user, loadingUser, errorUser: error } = useWhos({})

  if (error) {
    return <Outer>{'Error: ' + error.message}</Outer>
  }

  return (
    <Outer>
      <NameSection>
        <Row>
          <Avatar src={user?.avatarUrl!} size={'large'} />
          <Column style={{ paddingLeft: 8 }}>
            <NameRow>
              <Name>{user?.givenName}</Name>
              {user?.category === UserCategory.TEAM && (
                <Lozenge appearance={'default'}>{'HQ'}</Lozenge>
              )}
            </NameRow>
            {user?.position && (
              <SubtleText>
                {'💼 '}
                {user?.position}
              </SubtleText>
            )}
            {user?.city?.address?.formatted && (
              <SubtleText>
                {'📍 '}
                {user?.city?.address.formatted}
              </SubtleText>
            )}
            {user?.languages?.length && (
              <SubtleText>
                {'🗣️ '}
                {user.languages.map(getLanguageName).join(', ')}
              </SubtleText>
            )}
            {user?.startDate && (
              <SubtleText>
                {'✨ '}
                {formatStartDate(user?.startDate)}
              </SubtleText>
            )}
            {user?.birthday?.day && user?.birthday?.month && (
              <SubtleText>
                {'🎂 '}
                {formatBirthday(user?.birthday?.month, user?.birthday?.day)}
              </SubtleText>
            )}
          </Column>
        </Row>
      </NameSection>
      <TextsColumn>
        {!!user?.biography?.trim() && (
          <Row>
            <Section>
              <Text>{user?.biography}</Text>
            </Section>
          </Row>
        )}
        <Row>
          <Section>
            {user?.assistant && (
              <>
                <SectionTitle>{'Double'}</SectionTitle>
                <NavLink to={`/whoswho/${user?.assistant?.id}`}>
                  <NameRow>
                    <Avatar src={user?.assistant?.avatarUrl!} size={'small'} />
                    <Text>{user?.assistant?.givenName}</Text>
                  </NameRow>
                </NavLink>
              </>
            )}
            {!!user?.skills?.length && (
              <>
                <SectionTitle>{'Ask Me About'}</SectionTitle>
                <ul>
                  {user?.skills.map((skill) => (
                    <li key={skill}>
                      <Text>{skill}</Text>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </Section>
          <Section>
            {!!user?.funFacts && (
              <>
                <SectionTitle>{'Fun Facts'}</SectionTitle>
                <Text>{user?.funFacts}</Text>
              </>
            )}
          </Section>
        </Row>
      </TextsColumn>

      <LoadingSpinner show={loadingUser} />
    </Outer>
  )
}

export default Detail
