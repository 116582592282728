import Button, { ButtonGroup } from '@atlaskit/button'
import { FormSection, Field } from '@atlaskit/form'
import Select, { OptionType } from '@atlaskit/select'
import TextField from '@atlaskit/textfield'
import { transparentize } from 'polished'
import React, { useCallback, useState } from 'react'
import semverSatisfies from 'semver/functions/satisfies'
import styled from 'styled-components'

import { LoadingSpinner } from '../../../../components/Spinner'
import { FeatureFragment, useUpsertFeatureMutation } from '../../../../graphql'
import { areEquivalentObjects } from '../../../../lib/utils'
import { APP_VERSIONS_ALL, APP_VERSIONS_NONE } from '../utils'

const Outer = styled.div`
  padding: 0 8px 16px;
`

const Row = styled.div`
  display: flex;

  > * {
    flex: 0 1 100px !important;

    + * {
      margin-left: 8px;
    }
  }

  > *:first-child {
    flex: 0 1 200px !important;
  }
`

const Buttons = styled.div`
  margin: 16px 0 0;
  padding: 16px 0 16px;
  position: sticky;
  bottom: 0;
  background-color: ${transparentize(0.2, 'white')};
`

const OPERATOR_OPTIONS = [
  { value: APP_VERSIONS_ALL, label: 'Any version' },
  { value: APP_VERSIONS_NONE, label: 'None' },
  { value: '=', label: 'Equal' },
  { value: '>', label: 'Greater than' },
  { value: '>=', label: 'Greater than or equal' },
  { value: '<', label: 'Lower than' },
  { value: '<=', label: 'Lower than or equal' },
]
function getOperatorOption(operator: string): undefined | OptionType {
  return OPERATOR_OPTIONS.find(({ value }) => value === operator)
}

function formatAppVersion(operator: string, version = '') {
  return operator === APP_VERSIONS_ALL
    ? APP_VERSIONS_ALL
    : operator === APP_VERSIONS_NONE
    ? APP_VERSIONS_NONE
    : [operator.trim(), version.trim()].join(' ').trim()
}

function parseCondition(
  value: string | null | undefined,
): [string] | [string, string] {
  value = value || APP_VERSIONS_ALL

  return value === APP_VERSIONS_ALL
    ? [APP_VERSIONS_ALL]
    : value === APP_VERSIONS_NONE
    ? [APP_VERSIONS_NONE]
    : (value.trim().split(' ').slice(0, 2) as [string, string])
}

interface AppVersionProps {
  label: string
  value?: string
  onChangeValue: (value: string) => void
}

const AppVersion = ({ label, value, onChangeValue }: AppVersionProps) => {
  const [testVersion, setTestVersion] = useState<string>('')
  const [operator, version] = parseCondition(value)

  return (
    <Field name={'operator'} label={label}>
      {() => (
        <Row>
          <Select
            options={OPERATOR_OPTIONS}
            // @ts-ignore
            value={getOperatorOption(operator)}
            isSearchable={false}
            isClearable={false}
            isMulti={false}
            // @ts-ignore
            onChange={({ value: operator }) =>
              onChangeValue(formatAppVersion(operator, version))
            }
          />
          {operator !== APP_VERSIONS_ALL && operator !== APP_VERSIONS_NONE && (
            <>
              <TextField
                placeholder={'x.y.z'}
                value={version || ''}
                onChange={({ currentTarget: { value: version } }) =>
                  onChangeValue(formatAppVersion(operator, version))
                }
              />
              <TextField
                placeholder={'Test it'}
                value={testVersion}
                onChange={({ currentTarget: { value } }) =>
                  setTestVersion(value)
                }
                appearance={'subtle'}
                elemAfterInput={
                  !!testVersion &&
                  (semverSatisfies(
                    testVersion,
                    formatAppVersion(operator, version),
                  )
                    ? '👍'
                    : '👎')
                }
              />
            </>
          )}
        </Row>
      )}
    </Field>
  )
}

const APPS = [
  {
    name: '💻 Web Executive',
    envs: [
      {
        label: 'All Environments',
        appId: 'com.withdouble.aero.web*',
      },
    ],
  },
  {
    name: '🖥️ Web Assistant',
    envs: [
      {
        label: 'All Environments',
        appId: 'com.withdouble.dash.web*',
      },
    ],
  },
  {
    name: '📱 iOS Executive',
    envs: [
      {
        label: 'Production',
        appId: 'com.withdouble.driven',
      },
      {
        label: 'Daily',
        appId: 'com.withdouble.driven.ios.daily',
      },
      {
        label: 'WIP',
        appId: 'com.withdouble.driven.ios.wip',
      },
    ],
  },
  {
    name: '📱 iOS Assistant',
    envs: [
      {
        label: 'Production',
        appId: 'com.withdouble.dash',
      },
      {
        label: 'Daily',
        appId: 'com.withdouble.dash.ios.daily',
      },
      {
        label: 'WIP',
        appId: 'com.withdouble.dash.ios.wip',
      },
    ],
  },
]

interface AppVersionsProps {
  feature: FeatureFragment
}

const AppVersions = ({ feature }: AppVersionsProps) => {
  const [appVersions, setAppVersions] = useState<
    FeatureFragment['appVersions']
  >(feature.appVersions)
  const [upsertFeature, { loading }] = useUpsertFeatureMutation()

  const saveAppVersions = useCallback(() => {
    upsertFeature({
      variables: {
        input: {
          featureId: feature.id,
          appVersions,
        },
      },
    })
  }, [upsertFeature, feature.id, appVersions])

  const resetAppVersions = useCallback(() => {
    setAppVersions(feature.appVersions)
  }, [feature.appVersions, setAppVersions])

  const hasBeenUpdated = !areEquivalentObjects(appVersions, feature.appVersions)

  return (
    <Outer>
      {APPS.map(({ name, envs }) => (
        <FormSection key={name} title={name}>
          {envs.map(({ label, appId }) => (
            <AppVersion
              key={appId}
              label={label}
              value={appVersions[appId]}
              onChangeValue={(value) =>
                setAppVersions(
                  (prevValues: FeatureFragment['appVersions']) => ({
                    ...prevValues,
                    [appId]: value,
                  }),
                )
              }
            />
          ))}
        </FormSection>
      ))}

      {hasBeenUpdated && (
        <Buttons>
          <ButtonGroup>
            <Button
              isDisabled={!hasBeenUpdated}
              appearance={'primary'}
              onClick={saveAppVersions}
            >
              {'Save'}
            </Button>
            <Button isDisabled={!hasBeenUpdated} onClick={resetAppVersions}>
              {'Cancel'}
            </Button>
          </ButtonGroup>
        </Buttons>
      )}

      <LoadingSpinner show={loading} />
    </Outer>
  )
}

export default AppVersions
