import styled from 'styled-components'

export const ModalOuter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  gap: 8px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
