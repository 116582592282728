import { DateTime } from 'luxon'
import React, { useMemo } from 'react'

import { useLockTimeTrackingEntriesMutation } from '../../../../graphql'
import useNow from '../../../../lib/useNow'

import DatePickerPopUp from './DatePickerPopUp'

const LOCKED_AT_TIME_ET_INFO = {
  zone: 'America/New_York',
  hour: 23,
  minute: 59,
}

const ARBITRARY_DATE_WITH_LOCKED_TIME = DateTime.fromObject(
  {
    year: 2021,
    month: 1,
    day: 1,
    hour: LOCKED_AT_TIME_ET_INFO.hour,
    minute: LOCKED_AT_TIME_ET_INFO.minute,
  },
  {
    zone: LOCKED_AT_TIME_ET_INFO.zone,
  },
)

type Props = {
  lastLockedAtDate?: DateTime
  onClose: () => void
}

const LockEntriesPopup = ({ lastLockedAtDate, onClose }: Props) => {
  const now = useNow({
    zone: LOCKED_AT_TIME_ET_INFO.zone,
  })

  const minISODate = useMemo(() => {
    if (!lastLockedAtDate) return undefined

    const lastLockedAtDateInET = lastLockedAtDate.setZone(
      LOCKED_AT_TIME_ET_INFO.zone,
    )

    return (
      lastLockedAtDateInET.get('hour') < LOCKED_AT_TIME_ET_INFO.hour &&
      lastLockedAtDateInET.get('minute') < LOCKED_AT_TIME_ET_INFO.minute
        ? // If the last locked at time was manually set before 11:59pm ET (8:59pm PT)
          // then we allow the user to select the same date to push the locked at
          // time to 11:59pm ET (8:59pm PT)
          lastLockedAtDateInET
        : lastLockedAtDateInET.plus({ days: 1 })
    ).toISODate()
  }, [lastLockedAtDate])

  const maxISODate = useMemo(() => {
    return (
      LOCKED_AT_TIME_ET_INFO.hour <= now.get('hour') &&
      LOCKED_AT_TIME_ET_INFO.minute <= now.get('minute')
        ? // If the current time is after 11:59pm ET (8:59pm PT) then we can select today
          now
        : now.minus({ days: 1 })
    ).toISODate()
  }, [now])

  const [lockTimeEntries, { loading: lockTimeEntriesLoading }] =
    useLockTimeTrackingEntriesMutation({
      onCompleted: () => {
        window.location.reload()
      },
    })

  return (
    <DatePickerPopUp
      title={'Lock Time entries'}
      subtitle={`All time entries before ${ARBITRARY_DATE_WITH_LOCKED_TIME.setZone(
        LOCKED_AT_TIME_ET_INFO.zone,
      ).toFormat(
        'HH:mm ZZZZ',
      )} (${ARBITRARY_DATE_WITH_LOCKED_TIME.toLocal().toFormat(
        'HH:mm ZZZZ',
      )}) on the selected date are locked. \n Auto-lock is set for every Saturday at 23:59 EST (20:59 PST) and the 1st of every month at 11:00 EST (8:00 PST).`}
      minDate={minISODate}
      maxDate={maxISODate}
      onClose={onClose}
      isSaving={lockTimeEntriesLoading}
      onSave={async (isoDate) => {
        // isoDate is a date string with the format 'yyyy-mm-dd'
        const [year, month, day] = isoDate
          .split('-')
          .map((stringValue) => parseInt(stringValue))

        const endedBeforeDateTime = DateTime.fromObject(
          {
            year,
            month,
            day,
            hour: LOCKED_AT_TIME_ET_INFO.hour,
            minute: LOCKED_AT_TIME_ET_INFO.minute,
          },
          {
            zone: LOCKED_AT_TIME_ET_INFO.zone,
          },
        )

        await lockTimeEntries({
          variables: {
            input: {
              endedBefore: endedBeforeDateTime.toISO(),
            },
          },
        })

        onClose()
      }}
    />
  )
}

export default LockEntriesPopup
