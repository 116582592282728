import InternalIcon from '@atlaskit/icon/glyph/addon'
import React from 'react'

import {
  DiscoverSectionKey,
  DiscoverCategory,
  TopicTemplateType,
  TopicTemplateFragment,
} from '../../../graphql'

import { ReactComponent as AdminIcon } from './icons/admin.svg'
import { ReactComponent as CalendarIcon } from './icons/calendar.svg'
import { ReactComponent as CoreIcon } from './icons/core.svg'
import { ReactComponent as FoodIcon } from './icons/food.svg'
import { ReactComponent as PeopleIcon } from './icons/people.svg'
import { ReactComponent as PersonalIcon } from './icons/personal.svg'
import { ReactComponent as TravelIcon } from './icons/travel.svg'

function assertNever(x: never) {
  console.error(new Error(`Unknown value: ${x}`))
}

export const NEW_TEMPLATE_TITLE = 'New'

export const isNewTemplate = (template: TopicTemplateFragment): boolean =>
  template.title === NEW_TEMPLATE_TITLE

const INTERNAL_ICON = () => <InternalIcon label={''} />

export function getSectionTitle(sectionKey: DiscoverSectionKey): string {
  switch (sectionKey) {
    case DiscoverSectionKey.FEATURED:
      return 'Most popular'
    case DiscoverSectionKey.CALENDAR:
      return 'Hand off your calendar'
    case DiscoverSectionKey.MEETINGS:
      return 'Keep meetings productive'
    case DiscoverSectionKey.ADMIN:
      return 'Admin & office'
    case DiscoverSectionKey.IMPACT:
      return 'Impact'
    case DiscoverSectionKey.PERSONAL:
      return 'Personal'
    case DiscoverSectionKey.RESEARCH:
      return 'Research projects'
    case DiscoverSectionKey.TRAVEL:
      return 'Travel & entertainment'
    case DiscoverSectionKey.CONTACTS:
      return 'Contacts & relationships'
    case DiscoverSectionKey.HIRING:
      return 'Put hiring on autopilot'
    case DiscoverSectionKey.SOCIAL:
      return 'Stay on top of social'
    case DiscoverSectionKey.COVID19:
      return 'Getting help during COVID-19'
    default:
      assertNever(sectionKey)
      return 'Unknown Section'
  }
}

export function getCategoryIcon(
  category: DiscoverCategory,
): React.FunctionComponent {
  switch (category) {
    case DiscoverCategory.ADMIN:
      return AdminIcon
    case DiscoverCategory.CALENDAR:
      return CalendarIcon
    case DiscoverCategory.CORE:
      return CoreIcon
    case DiscoverCategory.FOOD:
      return FoodIcon
    case DiscoverCategory.PEOPLE:
      return PeopleIcon
    case DiscoverCategory.PERSONAL:
      return PersonalIcon
    case DiscoverCategory.TRAVEL:
      return TravelIcon
    case DiscoverCategory.INTERNAL:
      return INTERNAL_ICON
    default:
      assertNever(category)
      return CoreIcon
  }
}

export const getTypeName = (type: TopicTemplateType, plural = false) => {
  switch (type) {
    case TopicTemplateType.TODO:
      return plural ? 'Todos' : 'Todo'
    default:
      return assertNever(type)
  }
}

export const canFilterByCategory = (type: TopicTemplateType) => {
  switch (type) {
    case TopicTemplateType.TODO:
      return true
    default:
      return assertNever(type)
  }
}

export const canFilterByLabel = (type: TopicTemplateType) => {
  switch (type) {
    case TopicTemplateType.TODO:
      return true
    default:
      return assertNever(type)
  }
}

export const groupBySection = (type: TopicTemplateType) => {
  switch (type) {
    case TopicTemplateType.TODO:
      return true
    default:
      return assertNever(type)
  }
}
