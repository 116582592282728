import { MutationResult } from '@apollo/client'
import { useCallback } from 'react'

import {
  useUpsertSettingMutation,
  useGetSettingByIdQuery,
  UpsertSettingMutation,
} from '../../graphql'

export const SETTING_ID = 'skillset'

export interface Skill {
  id: string
  label: string
}

export interface Skillset {
  skills: Skill[]
}

export const sanitizeId = (id: string): string =>
  id
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9_]/g, '_')
    .replace(/_+/g, '_')
    .replace(/(^_)|(_$)/g, '')

export const sanitizeSkill = (skill: Skill): Skill => ({
  label: skill.label.trim(),
  id: sanitizeId(skill.id),
})

export const isValidSkill = (_skill: Skill): boolean => {
  const skill = sanitizeSkill(_skill)
  return skill.label.length > 5 && skill.id.length > 5
}

export function useSkillset() {
  const { data, ...rest } = useGetSettingByIdQuery({
    variables: {
      id: SETTING_ID,
    },
  })

  const skillset: Skillset | undefined = data?.settingById.data as Skillset

  const skillNameById = useCallback(
    (_id: string): string | undefined =>
      skillset?.skills?.find(({ id }) => id === _id)?.label,
    [skillset],
  )

  return {
    skillset,
    skillNameById,
    data,
    ...rest,
  }
}

export function useSaveSkillset(): [
  (skillset: Skillset) => Promise<unknown>,
  MutationResult<UpsertSettingMutation>,
] {
  const [upsertSetting, metas] = useUpsertSettingMutation()

  const saveSkillset = useCallback(
    (skillset: Skillset) =>
      upsertSetting({
        variables: {
          input: {
            settingId: 'skillset',
            data: skillset,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          upsertSetting: {
            __typename: 'UpsertSettingOutput',
            setting: {
              __typename: 'Setting',
              id: 'skillset',
              data: skillset,
            },
          },
        },
      }),
    [upsertSetting],
  )

  return [saveSkillset, metas]
}
