import times from 'lodash/times'
import React, { useEffect } from 'react'

import MarkdownEditor from '../../../../../components/MarkdownEditor'
import {
  Field,
  TextField,
  FieldsRow,
  EmailAddressField,
  Select,
} from '../../../../../components/form'
import type { EmailFieldsValues } from '../../../../../components/messages/types'
import {
  EmailAddressWithNameInput,
  useListEmailTemplatesQuery,
} from '../../../../../graphql'
import { Outer } from '../../components/styled'

const layoutOption = ({
  id,
  name,
}: {
  id: string
  name: string
}): { value: string; label: string } => ({
  value: id,
  label: name,
})

type EmailFieldsProps = {
  values: EmailFieldsValues
  onPatch: (newValues: Partial<EmailFieldsValues>) => void | Promise<void>
  onValidity: (isValid: boolean) => void | Promise<void>
}

const removeEmptyEmailWithName = (
  emailsWithName: Partial<EmailAddressWithNameInput>[],
) => {
  return emailsWithName.filter((emailWithName) => emailWithName.email)
}

const EmailFields = ({ values, onPatch, onValidity }: EmailFieldsProps) => {
  useEffect(() => {
    onValidity(!(!values?.body?.trim() || !values?.subject?.trim()))
  }, [values, onValidity])

  const { data: layoutsData, loading: loadingLayouts } =
    useListEmailTemplatesQuery()

  const layouts = layoutsData?.emailTemplates || []
  const layout = layouts.find((layout) => layout.id === values.layoutId)

  values.to = removeEmptyEmailWithName(values.to || [])
  values.cc = removeEmptyEmailWithName(values.cc || [])
  values.bcc = removeEmptyEmailWithName(values.bcc || [])
  values.tags = values.tags || []

  return (
    <Outer>
      <Field
        label={'Layout'}
        helperMessage={
          '⚠️ Do not edit this unless you know what you are doing. Spoiler: you do not.'
        }
      >
        <Select
          isLoading={loadingLayouts}
          value={layout ? layoutOption(layout) : undefined}
          options={layouts.map(layoutOption)}
          onChangeValue={(layoutId) => onPatch({ layoutId })}
          validationState={loadingLayouts || layout ? 'default' : 'error'}
        />
      </Field>
      <Field label={'From'}>
        <FieldsRow>
          <EmailAddressField
            isCompact
            domain={'@withdouble.com'}
            value={values.from?.email}
            onChangeValue={(email) =>
              onPatch({
                from: {
                  ...values.from,
                  email,
                },
              })
            }
            isInvalid={!values.from?.email?.trim()}
          />
          <TextField
            isCompact
            isDisabled={!values.from?.email}
            placeholder={'From name'}
            value={values.from?.name || undefined}
            onChangeValue={(name) =>
              onPatch({
                from: {
                  ...values.from,
                  name,
                },
              })
            }
          />
        </FieldsRow>
      </Field>

      <Field label={'Reply to'}>
        <FieldsRow>
          <EmailAddressField
            isCompact
            value={values.replyTo?.email}
            onChangeValue={(email) =>
              onPatch({
                replyTo: {
                  ...values.replyTo,
                  email,
                },
              })
            }
          />
          <TextField
            isCompact
            isDisabled={!values.replyTo?.email}
            placeholder={'Reply to name'}
            value={values.replyTo?.name || undefined}
            onChangeValue={(name) =>
              onPatch({
                replyTo: {
                  ...values.replyTo,
                  name,
                },
              })
            }
          />
        </FieldsRow>
      </Field>
      <Field label={'CC'}>
        {times(values.cc.length + 1).map((index) => (
          <FieldsRow key={index}>
            <EmailAddressField
              isCompact
              value={values.cc?.[index]?.email}
              onChangeValue={(email) => {
                const cc = values.cc || []
                cc[index] = {
                  ...cc[index],
                  email,
                }
                onPatch({ cc })
              }}
            />
            <TextField
              isCompact
              isDisabled={!values.cc?.[index]?.email}
              placeholder={'CC name'}
              value={values.cc?.[index]?.name || undefined}
              onChangeValue={(name) => {
                const cc = values.cc || []
                cc[index] = {
                  ...cc[index],
                  name,
                }
                onPatch({ cc })
              }}
            />
          </FieldsRow>
        ))}
      </Field>
      <Field label={'BCC'}>
        {times(values.bcc.length + 1).map((index) => (
          <FieldsRow key={index}>
            <EmailAddressField
              isCompact
              value={values.bcc?.[index]?.email}
              onChangeValue={(email) => {
                const bcc = values.bcc || []
                bcc[index] = {
                  ...bcc[index],
                  email,
                }
                onPatch({ bcc })
              }}
            />
            <TextField
              isDisabled={!values.bcc?.[index]?.email}
              isCompact
              placeholder={'BCC name'}
              value={values.bcc?.[index]?.name || undefined}
              onChangeValue={(name) => {
                const bcc = values.bcc || []
                bcc[index] = {
                  ...bcc[index],
                  name,
                }
                onPatch({ bcc })
              }}
            />
          </FieldsRow>
        ))}
      </Field>
      <Field label={'Subject'} isRequired>
        <MarkdownEditor
          value={values.subject}
          onChange={(subject) => onPatch({ subject })}
          isInvalid={!values.subject?.trim()}
          isInline
          noToolbar
        />
      </Field>
      <Field label={'Preview text'}>
        <MarkdownEditor
          value={values.preview}
          onChange={(preview) => onPatch({ preview })}
          isInline
          noToolbar
        />
      </Field>
      <Field label={'Message'} isRequired>
        <MarkdownEditor
          isInvalid={!values.body?.trim()}
          value={values.body}
          onChange={(body) => onPatch({ body })}
          minHeight={150}
        />
      </Field>
    </Outer>
  )
}

export default EmailFields
