import Button from '@atlaskit/button'
import ExpandedChevron from '@atlaskit/icon/glyph/chevron-down'
import CollapsedChevron from '@atlaskit/icon/glyph/chevron-left'
import { colors } from '@atlaskit/theme'
import truncate from 'lodash/truncate'
import React, { ComponentProps } from 'react'
import styled, { css } from 'styled-components'

import useSwitch from '../lib/useSwitch'

export const Text = styled.p<{ $withQuote?: boolean }>`
  color: ${colors.text};
  font-size: smaller;
  line-height: 1.3em;
  padding-right: 32px;
  white-space: break-spaces;

  ${({ $withQuote }) =>
    !!$withQuote &&
    css`
      padding-left: 32px;
      &:before {
        content: '“';
        color: ${colors.N60};
        position: absolute;
        font-size: 50px;
        top: 13px;
        left: 4px;
      }
    `}

  button {
    position: absolute;
    right: 0;
    top: 0;
  }
`

type Props = Omit<ComponentProps<typeof Text>, 'children'> & {
  text: string | undefined | null
  collapsedTextLength?: number
  withQuote?: boolean
  noCollapse?: boolean
}

const CollapsibleText = ({
  text,
  collapsedTextLength = 150,
  withQuote,
  noCollapse,
  ...props
}: Props) => {
  const [isExpanded, , , toggleExpanded] = useSwitch(!!noCollapse)

  if (!text?.trim()) {
    return null
  }

  const truncatedText = truncate(text, {
    length: collapsedTextLength,
  })

  return (
    <Text
      title={isExpanded ? undefined : text}
      $withQuote={withQuote}
      {...props}
    >
      {isExpanded ? text : truncatedText}
      {truncatedText !== text && !noCollapse && (
        <Button
          appearance={'subtle'}
          onClick={toggleExpanded}
          iconBefore={
            isExpanded ? (
              <ExpandedChevron label={''} />
            ) : (
              <CollapsedChevron label={''} />
            )
          }
        />
      )}
    </Text>
  )
}

export default CollapsibleText
