import { PropsWithChildren } from 'react'

import {
  onlyIfAdminable,
  useGetAdminableConfigQuery,
} from '../../../../graphql'

const OnlyIfAdminRoles = ({
  userId,
  roles,
  children,
}: PropsWithChildren<{
  userId: string
  roles: string[]
}>) => {
  const { data } = useGetAdminableConfigQuery({
    variables: {
      userId,
    },
  })

  const adminable = onlyIfAdminable(data?.user)

  if (
    !roles.every((role) =>
      adminable?.adminRoles?.map((ar) => ar.id).includes(role),
    )
  ) {
    return null
  }

  return <>{children}</>
}

export default OnlyIfAdminRoles
