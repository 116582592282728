import { useApolloClient } from '@apollo/client'
import { DocumentNode } from 'graphql'

interface AbstractItem {
  id: string
}

interface AbstractListQuery<T extends AbstractItem> {
  list?: {
    items?: T[]
  }
}

const filterById =
  (searchedId: string, isEqual = true) =>
  ({ id }: AbstractItem): boolean =>
    (id === searchedId) === isEqual

function useListQueryCache<
  ListQuery extends AbstractListQuery<Item>,
  Item extends AbstractItem,
>(query: DocumentNode, variables?: Record<string, unknown>) {
  const client = useApolloClient()

  const getCachedData = () => {
    try {
      return client.readQuery({
        query,
        variables,
      })
    } catch (error) {
      console.debug(error)
      return null
    }
  }

  function getCachedDataItems(data: ListQuery | null): Item[] {
    if (data?.list?.items) {
      // clone array as it is returned as immutable
      return [...data.list.items]
    }

    return []
  }

  function findDataItem(
    data: ListQuery | null,
    filter: (item: Item) => boolean,
  ): Item | undefined {
    return getCachedDataItems(data).find(filter)
  }

  function findItem(
    filter: ((item: Item) => boolean) | string,
  ): Item | undefined {
    if (typeof filter === 'string') {
      filter = filterById(filter)
    }
    return findDataItem(getCachedData(), filter)
  }

  function updateDataItems(data: ListQuery, items: Item[]): void {
    client.writeQuery<ListQuery>({
      query,
      // @ts-ignore 🤷‍♂️
      data: {
        ...data,
        list: {
          ...data?.list,
          items,
        },
      },
    })
  }

  function upsertItem(item: Item, prepend = true, update = true): void {
    const data = getCachedData()
    if (!data) {
      return
    }

    const items = getCachedDataItems(data)

    const itemIndex = items.findIndex(filterById(item.id))
    if (itemIndex !== -1) {
      // Update
      if (!update) {
        return
      }
      items.splice(itemIndex, 1, item)
    } else if (prepend) {
      // Insert at head of list
      items.unshift(item)
    } else {
      // Insert at tail of list
      items.push(item)
    }

    updateDataItems(data, items)
  }

  function removeItem(item: Item): void {
    const data = getCachedData()
    if (!data) {
      return
    }

    const items = getCachedDataItems(data).filter(filterById(item.id, false))

    updateDataItems(data, items)
  }

  return {
    upsertItem,
    findItem,
    removeItem,
  }
}

export default useListQueryCache
