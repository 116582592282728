import React, { ComponentProps, useEffect, ComponentType } from 'react'
import { useHistory } from 'react-router'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

import {
  useListUsersQuery,
  BasicUserFragment as User,
  UserCategory,
  withListPagination,
} from '../../../graphql'
import { ErrorEmptyState } from '../../EmptyState'
import { LoadingSpinner } from '../../Spinner'
import Cell, { Skeleton, UserCellProps } from '../Cell'

import Footer, { HEIGHT as FOOTER_HEIGHT } from './Footer'
import SearchInput from './SearchInput'

export { Cell, SearchInput }

interface Props extends ComponentProps<'div'> {
  search?: string
  isArchived?: boolean | null
  categories?: UserCategory[] | null | undefined
  adminRoles?: string[]
  successManagerId?: string
  hasSuccessManager?: boolean | null | undefined
  Cell: ComponentType<UserCellProps>
  filterUser?: (user: User) => boolean
  autoNavigateToSingleResult?: boolean
  showFooter?: boolean
  tools?: string[] | undefined | null
  industries?: string[] | undefined | null
}

const PAGE_SIZE = 50

const UsersList = ({
  search,
  isArchived,
  categories,
  adminRoles,
  successManagerId,
  hasSuccessManager,
  filterUser,
  Cell,
  className,
  autoNavigateToSingleResult,
  showFooter,
  tools,
  industries,
}: Props) => {
  const navHistory = useHistory()
  const { data, loading, error, fetchMore } = withListPagination(
    useListUsersQuery({
      variables: {
        q: search,
        isArchived,
        categories,
        adminRoles,
        successManagerId,
        hasSuccessManager,
        first: PAGE_SIZE,
        tools,
        industries,
      },
    }),
  )

  const users =
    loading || !data
      ? []
      : data.list?.items?.filter(filterUser || (() => true)) || []
  const after = loading || !data ? '' : data.list?.after
  const itemCount = loading
    ? PAGE_SIZE
    : after
    ? users.length + 1
    : users.length
  const isItemLoaded = (index: number) => !after || index < users.length

  const singleUser = users?.length === 1 ? users[0] : undefined

  useEffect(() => {
    if (autoNavigateToSingleResult && singleUser) {
      navHistory.push(`/users/${singleUser.id}`)
    }
  }, [autoNavigateToSingleResult, navHistory, singleUser])

  if (error) {
    return <ErrorEmptyState error={error} />
  }

  return (
    <>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            // @ts-ignore
            loadMoreItems={fetchMore}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                itemCount={itemCount}
                itemSize={56}
                onItemsRendered={onItemsRendered}
                height={height - (showFooter ? FOOTER_HEIGHT : 0)}
                width={width}
                ref={ref}
                className={className}
              >
                {({ index, style }) =>
                  loading ? (
                    <Skeleton />
                  ) : isItemLoaded(index) ? (
                    // @ts-ignore
                    <Cell user={users[index]} style={style} />
                  ) : (
                    <LoadingSpinner size={'xsmall'} />
                  )
                }
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
      {!!showFooter && (
        <Footer isLoading={loading} hasMore={!!after} itemCount={itemCount} />
      )}
    </>
  )
}

export default UsersList
