import {
  ListMessageTemplatesDocument,
  ListMessageTemplatesQuery,
  ListMessageTemplatesQueryVariables,
  MessageTemplate_LabelsFragment,
  useListMessageTemplatesQuery,
  useListQueryCache,
  withListPagination,
} from '../../../graphql'

const DEFAULT_VARIABLES = {
  first: 100,
}

const useListMessageTemplates = (
  variables: ListMessageTemplatesQueryVariables = {},
) =>
  withListPagination(
    useListMessageTemplatesQuery({
      variables: {
        ...DEFAULT_VARIABLES,
        ...variables,
      },
    }),
  )

export const useMessageTemplateListCache = () =>
  useListQueryCache<ListMessageTemplatesQuery, MessageTemplate_LabelsFragment>(
    ListMessageTemplatesDocument,
    DEFAULT_VARIABLES,
  )

export default useListMessageTemplates
