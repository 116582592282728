import React from 'react'

import useSwitch from '../../lib/useSwitch'

import { Banner, BannersList, BannerText, ClearAllButton, Outer } from './style'

type Props = {
  banners: {
    message: string
    onDismiss: () => void
  }[]
  onClearAll: () => void
}

const preventPropagation = (event: React.MouseEvent) => {
  event.stopPropagation()
  event.preventDefault()
}

const Banners = ({ banners, onClearAll }: Props) => {
  const [isExpandedState, setIsExpandedTrue, _, toggleIsExpanded] =
    useSwitch(false)

  if (banners.length === 0) {
    return null
  }

  return (
    <Outer
      onClick={toggleIsExpanded}
      $isExpanded={banners.length > 1 && isExpandedState}
    >
      <ClearAllButton onClick={onClearAll}>{'Clear all'}</ClearAllButton>
      <BannersList $isExpanded={banners.length > 1 && isExpandedState}>
        {banners.map(({ message, onDismiss }, index) => (
          <Banner
            onClick={(e) => {
              preventPropagation(e)
              setIsExpandedTrue()
            }}
            key={index}
            $index={index}
            $isExpanded={isExpandedState || banners.length === 1}
          >
            <BannerText $isExpanded={isExpandedState || banners.length === 1}>
              {message.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </BannerText>
            {(isExpandedState || banners.length === 1) && (
              <a onClick={onDismiss}>{'Dismiss'}</a>
            )}
          </Banner>
        ))}
      </BannersList>
    </Outer>
  )
}

export default Banners
