import Select, { SelectProps, ValueType } from '@atlaskit/select'
import React, { useMemo } from 'react'

import {
  withListPagination,
  useListAutomationsQuery,
} from '../../../../graphql'
import { getSectionedName, group } from '../../utils'

type Props = {
  valueIds: ValueType<{ value: string; label: string }, true>
  onChangeValueIds: (
    newValues: ValueType<{ value: string; label: string }, true>,
  ) => void
  filters?: {
    triggerId: string | null
  }
} & SelectProps<{ value: string; label: string }, true>

const AutomationSelect = ({
  valueIds,
  onChangeValueIds,
  filters,
  ...props
}: Props) => {
  const { data } = withListPagination(useListAutomationsQuery())

  const options = useMemo(() => {
    const filteredAutomations =
      data?.list.items.filter(
        ({ triggerOn: { id } }) =>
          !filters?.triggerId || filters.triggerId === id,
      ) || []

    return (
      group(filteredAutomations).map(({ name, items: automations }) => ({
        label: name || 'Other',
        options: automations.map((automation) => ({
          value: automation.id,
          label: getSectionedName(automation.name).name,
        })),
      })) || []
    )
  }, [data?.list.items, filters?.triggerId])

  return (
    <Select<{ value: string; label: string }, true>
      value={valueIds}
      options={options}
      placeholder={'Select automations'}
      onChange={onChangeValueIds}
      isMulti
      {...props}
    />
  )
}

export default AutomationSelect
