import { colors, borderRadius, layers } from '@atlaskit/theme'
import {
  Editable as _Editable,
  HoveringToolbar as _HoveringToolbar,
} from '@withdouble/markdown'
import styled, { css } from 'styled-components'

export const Outer = styled.div`
  color: ${colors.text};

  ul {
    list-style: unset;
    padding-left: 24px;
  }

  a {
    text-decoration: underline;
    color: ${colors.link};
  }

  var {
    background-color: #fffdd583;
    padding: 0 2px;
    border-radius: 2px;
    border: 1px solid #f7f273;
    font-style: normal;
    color: #73710b;
    font-weight: 500;
  }
`

export const Inner = styled.div`
  position: relative;
`

export const Editable = styled(_Editable)<{
  isInvalid?: boolean
  isSubtle?: boolean
}>`
  border-radius: ${borderRadius}px;
  border: solid 2px transparent;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  padding: 8px 6px;

  ${({ isSubtle }) =>
    !isSubtle &&
    css`
      background-color: ${colors.N10};
      border-color: ${colors.N40};
    `}

  &:hover {
    background-color: ${colors.N30};
  }

  &:focus {
    background-color: white;
    box-shadow: none;
    border: solid 2px ${colors.linkOutline};
  }

  p,
  h1,
  h2,
  h3 {
    color: ${colors.text()};
  }

  ${({ isInvalid }) =>
    !!isInvalid &&
    css`
      border: solid 2px ${colors.R400};
    `}
`

export const HoveringToolbar = styled(_HoveringToolbar)`
  border-radius: ${borderRadius}px;
  background-color: ${colors.backgroundHover};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  color: white;
  padding: 4px;
  font-size: 12px;
  display: flex;
`

export const AnchoredToolbar = styled.div<{
  isHidden?: boolean
  $isBelow?: boolean
}>`
  z-index: ${layers.spotlight()};
  padding: 4px;
  font-size: 12px;
  display: flex;
  position: absolute;
  background: white;
  border-radius: ${borderRadius}px ${borderRadius}px 0 0;
  border: 2px solid ${colors.linkOutline};
  right: 0;
  ${({ $isBelow }) =>
    $isBelow
      ? css`
          bottom: -30px;
          border-top-color: ${colors.N20};
        `
      : css`
          top: -31px;
          border-bottom-color: ${colors.N20};
        `}

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}
`

export const ToolbarDivider = styled.div`
  width: 2px;
  background-color: ${colors.N20};
  margin: 0 4px;
`

export const MarkdownPreview = styled.div`
  border-radius: ${borderRadius}px;
  border: solid 2px ${colors.linkOutline};
  padding: 8px 6px;
  background-color: white;

  white-space: pre-wrap;
  font-family: 'Menlo', 'Monaco', monospace;
  line-height: 1.5em;
  font-size: 12px;
`

export const PreviewOuter = styled.div`
  border-radius: ${borderRadius}px;
  border: solid 2px ${colors.linkOutline};
  padding: 8px 6px;
  background-color: white;
  p,
  h1,
  h2,
  h3 {
    color: ${colors.text()};
  }
`
