import { colors, typography } from '@atlaskit/theme'
import React, {
  useState,
  useCallback,
  useMemo,
  ComponentProps,
  useEffect,
} from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import {
  CreateExecutiveInput,
  CityFragment,
  useCreateExecutiveMutation,
  UserFacet,
  UserCategory,
  AirtableLeadFragment,
} from '../../graphql'
import useValues from '../../lib/useValues'
import AirtableLeadSelect from '../AirtableLeadSelect'
import CitySelect from '../CitySelect'
import Modal from '../Modal'
import { normalizeTimeZone } from '../TimeZoneSelect'
import { Field, TextField, FieldsRow, Checkbox } from '../form'

import {
  Form as WorkspaceForm,
  FormValues as WorkspaceFormValues,
  isValidForm as isValidWorkspaceForm,
} from './CreateExecutiveWorkspaceModal'
import FacetSelect from './FacetSelect'
import { SingleUserSelect } from './Select'

const AIRTABLE_LEAD_NEXT_STEPS = ['Send onboarding email']

type Props = ComponentProps<typeof Modal>

const Outer = styled.div`
  display: flex;
`

const Panel = styled.div`
  flex: 1 0 48%;
  padding-right: 24px;
  padding-bottom: 16px;

  & + & {
    border-left: 1px solid ${colors.backgroundHover()};
    padding-right: 0;
    padding-left: 24px;
  }
`

const PanelTitle = styled.p`
  ${typography.h300}
  margin-top: 8px;
`

const CreateModal = ({ ...modalProps }: Props) => {
  const history = useHistory()

  const [cityLookup, setCityLookup] = useState<string | null>(null)
  const [city, setCity] = useState<CityFragment | null>(null)
  const [formValues, { reset: resetForm, setters }] = useValues<
    Omit<CreateExecutiveInput, keyof WorkspaceFormValues>
  >(
    {
      givenName: '',
      familyName: '',
      email: '',
    },
    [
      'givenName',
      'familyName',
      'email',
      'cityPlaceId',
      'airtableLeadRecordId',
      'pronoun',
      'facet',
      'principalUserId',
      'hoursLimit',
      'isAllowedPersonal',
    ],
  )
  const [
    workspaceFormValues,
    { patch: patchWorkspaceFormValues, reset: resetWorkspaceFormValues },
  ] = useValues<WorkspaceFormValues>({})

  const reset = useCallback(() => {
    resetForm()
    resetWorkspaceFormValues()
    setCity(null)
    setCityLookup(null)
  }, [resetForm, resetWorkspaceFormValues])

  const [createExecutive, { loading: loadingCreateExecutive }] =
    useCreateExecutiveMutation({
      onCompleted({ user: { id } }) {
        // @ts-ignore
        modalProps.onClose?.()
        history.push(`/users/${id}/workspaces/${id}/delegation-path`)
      },
    })

  const onChangeCity = useCallback(
    (city: CityFragment | null) => {
      setCityLookup(null)
      setCity(city)
      setters.cityPlaceId?.(city?.googleMapsPlaceId)
    },
    [setters],
  )

  const onSelectAirtableLead = useCallback(
    (lead: AirtableLeadFragment | null) => {
      if (lead) {
        // Set lead id
        setters.airtableLeadRecordId?.(lead.id)
        // Try to auto-fill names
        if (!(formValues.givenName || formValues.familyName) && lead.name) {
          const [givenName, familyName, ...others] = lead.name
            .replace(/\([^)]*\)/g, '')
            .trim()
            .split(/\s+/g)
          if (!others.length) {
            setters.givenName?.(givenName)
            setters.familyName?.(familyName)
          }
        }
        // Auto-fill email
        if (!formValues.email && lead.email) {
          setters.email?.(lead.email)
        }
        // Auto-fill city
        if (!formValues.cityPlaceId && lead.city) {
          setCityLookup(lead.city)
        }
        // Auto-fill double
        const assistantId = lead?.doubles?.[0]?.userId
        if (!workspaceFormValues.assistantId && assistantId) {
          patchWorkspaceFormValues({
            assistantId,
            sendOnboardingEmail: true,
            matchingRationale: lead.reasonForMatch || null,
            workingHoursTimeZone:
              (lead.country && normalizeTimeZone(lead.country)) || undefined,
          })
        }
        // Is it a team invite?
        const teamInvite = lead?.teamInvites?.[0]
        if (teamInvite && !formValues.facet) {
          // Change facet to team member
          setters.facet?.(UserFacet.TEAM_MEMBER)
          setters.hoursLimit?.(teamInvite.hoursLimit)
          setters.isAllowedPersonal?.(teamInvite.isAllowedPersonal)
          const teamLeadUserId = teamInvite.teamLeads?.[0]?.userId
          if (teamLeadUserId) {
            setters.principalUserId?.(teamLeadUserId)
          }
        } else {
          setters.facet?.(UserFacet.PRINCIPAL)
        }
      }
    },
    [
      formValues.cityPlaceId,
      formValues.email,
      formValues.facet,
      formValues.familyName,
      formValues.givenName,
      patchWorkspaceFormValues,
      setters,
      workspaceFormValues.assistantId,
    ],
  )

  const isValidForm = useMemo(() => {
    if (
      !(
        formValues.givenName?.trim() &&
        formValues.familyName?.trim() &&
        formValues.email?.trim()
      )
    ) {
      return false
    }

    if (!formValues.facet) {
      return false
    }

    if (
      formValues.facet === UserFacet.TEAM_MEMBER ||
      formValues.facet === UserFacet.SIGNIFICANT_OTHER
    ) {
      if (!formValues.principalUserId) {
        return false
      }
      if (formValues.isAllowedPersonal == null) {
        return false
      }
    }

    if (!isValidWorkspaceForm(workspaceFormValues)) {
      return false
    }

    return true
  }, [
    formValues.email,
    formValues.facet,
    formValues.familyName,
    formValues.givenName,
    formValues.isAllowedPersonal,
    formValues.principalUserId,
    workspaceFormValues,
  ])

  const onSubmit = useCallback(() => {
    if (isValidForm && workspaceFormValues.workingHoursTimeZone) {
      createExecutive({
        variables: {
          input: {
            ...formValues,
            ...workspaceFormValues,
            workingHoursTimeZone: workspaceFormValues.workingHoursTimeZone,
          },
        },
      })
    }
  }, [createExecutive, formValues, workspaceFormValues, isValidForm])

  // Auto-fill time-zone when selecting a city
  useEffect(() => {
    if (city?.timeZone && !workspaceFormValues.workingHoursTimeZone) {
      patchWorkspaceFormValues({
        workingHoursTimeZone: city.timeZone,
      })
    }
  }, [city, patchWorkspaceFormValues, workspaceFormValues.workingHoursTimeZone])

  return (
    <Modal
      onCloseComplete={reset}
      width={'large'}
      heading={'Create new executive'}
      autoFocus
      actions={[
        {
          text: 'Create',
          onClick: onSubmit,
          isDisabled: !isValidForm,
          isLoading: loadingCreateExecutive,
        },
        { text: 'Cancel', onClick: modalProps.onClose },
      ]}
      {...modalProps}
    >
      <Outer>
        <Panel>
          <Field
            label={'Import lead from Airtable'}
            helperMessage={`Defaults to leads in ${AIRTABLE_LEAD_NEXT_STEPS.map(
              (step) => `"${step}"`,
            ).join(', ')}`}
          >
            <AirtableLeadSelect
              inNextSteps={AIRTABLE_LEAD_NEXT_STEPS}
              isClient={false}
              onChangeValue={onSelectAirtableLead}
              valueId={formValues.airtableLeadRecordId}
            />
          </Field>

          <Field label={'Name'} isRequired>
            <FieldsRow>
              <TextField
                placeholder={'Given name'}
                value={formValues.givenName}
                onChangeValue={setters.givenName}
                isInvalid={!formValues.givenName?.trim()}
              />
              <TextField
                placeholder={'Family name'}
                value={formValues.familyName || ''}
                onChangeValue={setters.familyName}
                isInvalid={!formValues.familyName?.trim()}
              />
            </FieldsRow>
          </Field>

          <Field label={'Email'} isRequired>
            <TextField
              type={'email'}
              name={'email'}
              placeholder={'example@company.com'}
              value={formValues.email}
              onChangeValue={setters.email}
              isInvalid={!formValues.email?.trim()}
            />
          </Field>

          <Field label={'Facet'} isRequired>
            <FacetSelect
              value={formValues.facet}
              // @ts-ignore
              onChange={(option) => setters.facet?.(option?.value)}
              menuPlacement={'top'}
              validationState={!formValues.facet ? 'error' : undefined}
            />
          </Field>
          {[UserFacet.TEAM_MEMBER, UserFacet.SIGNIFICANT_OTHER].includes(
            formValues.facet as UserFacet,
          ) ? (
            <>
              <Field
                isRequired
                label={
                  formValues.facet === UserFacet.TEAM_MEMBER
                    ? 'Team Principal'
                    : 'Significant other of'
                }
              >
                <SingleUserSelect
                  value={
                    formValues.principalUserId
                      ? { id: formValues.principalUserId }
                      : undefined
                  }
                  categories={[UserCategory.EXECUTIVE]}
                  isClearable
                  onChange={(user: null | { id: string }) =>
                    setters?.principalUserId?.(user?.id)
                  }
                  validationState={
                    !formValues.principalUserId ? 'error' : undefined
                  }
                  menuPlacement={'top'}
                />
              </Field>
              <FieldsRow>
                <Field label={'Hours Limit'}>
                  <TextField
                    type={'number'}
                    placeholder={'No limit'}
                    min={1}
                    step={1}
                    value={formValues.hoursLimit || undefined}
                    onChangeValue={(value) =>
                      setters.hoursLimit?.(value ? parseInt(value) : null)
                    }
                  />
                </Field>
                <Field label={'Personal Tasks are...'} isRequired>
                  <Checkbox
                    isChecked={!!formValues.isAllowedPersonal}
                    onChangeValue={setters.isAllowedPersonal}
                    isIndeterminate={formValues.isAllowedPersonal == null}
                    isInvalid={formValues.isAllowedPersonal == null}
                    label={
                      formValues.isAllowedPersonal == null
                        ? ''
                        : formValues.isAllowedPersonal
                        ? 'Allowed'
                        : 'NOT allowed'
                    }
                  />
                </Field>
              </FieldsRow>
            </>
          ) : (
            <div />
          )}

          <Field
            label={'City'}
            helperMessage={
              cityLookup ? `Looking for "${cityLookup}"` : undefined
            }
          >
            <CitySelect
              lookup={cityLookup}
              value={city}
              onChangeValue={onChangeCity}
              menuPlacement={'top'}
            />
          </Field>
        </Panel>
        <Panel>
          <PanelTitle>{'Executive workspace'}</PanelTitle>

          <WorkspaceForm
            // Trick to reload the Markdown editor
            key={formValues.airtableLeadRecordId || 'default'}
            values={workspaceFormValues}
            onPatch={patchWorkspaceFormValues}
          />
        </Panel>
      </Outer>
    </Modal>
  )
}

export default CreateModal
