import React, { ComponentProps } from 'react'

import { SearchTextField } from '../../../form'

interface Props extends ComponentProps<typeof SearchTextField> {
  onChangeText: (value: string) => void
}

function SearchInput({ onChangeText, ...props }: Props) {
  return (
    <SearchTextField
      isCompact
      placeholder={'Search by name, email or ID'}
      onChangeValue={onChangeText}
      {...props}
    />
  )
}

export default SearchInput
