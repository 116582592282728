import styled from 'styled-components'

export const Outer = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 0 300px;
  min-width: 300px;
  max-width: 400px;
  padding: 0 24px;
`

export const ListOuter = styled.div`
  flex: 1;
  border-top: 2px solid #ebecf0;
`
