import { DateTime } from 'luxon'
import React from 'react'

import { Matching_ForTransitionListItemFragment } from '../../../../../../graphql'

import StageButton from './components/StageButton'

type Props = {
  matching: Matching_ForTransitionListItemFragment
}

const Scheduling = ({ matching }: Props) => {
  const changeoverAt = matching.onboarding.callStartAt
  return (
    <StageButton matching={matching} stageId={'scheduling'}>
      {changeoverAt
        ? DateTime.fromJSDate(new Date(changeoverAt)).toLocaleString({
            month: 'short',
            day: '2-digit',
          })
        : 'TBD'}
    </StageButton>
  )
}

export default Scheduling
