import { MenuGroup, Section, ButtonItem } from '@atlaskit/menu'
import { colors } from '@atlaskit/theme'
import { DateTime } from 'luxon'
import React from 'react'
import styled, { css } from 'styled-components'

import { OnboardingAvailabilityFragment } from '../../graphql'
import EmptyState from '../EmptyState'

const EmptyStateOuter = styled.div`
  padding: 0 16px;
`

const SlotText = styled.span<{ isWarning: boolean }>`
  ${({ isWarning }) =>
    !!isWarning &&
    css`
      color: ${colors.red()};
      font-weight: 500;
      :after {
        content: ' < 24hrs';
      }
    `}
`

export type SlotFragment = OnboardingAvailabilityFragment['slots'][number]

type SlotsProps = {
  slots: SlotFragment[]
}

const Slots = ({ slots }: SlotsProps) => {
  if (!slots?.length) {
    return (
      <EmptyStateOuter>
        <EmptyState header={'No slot available'} emoji={'🚨'} />
      </EmptyStateOuter>
    )
  }

  // Sort slots
  const sortedSlots = [...slots].sort((a, b) =>
    a.startAt < b.startAt ? -1 : 1,
  )

  // Group slots by day
  const groupedSlots = sortedSlots.reduce<Record<string, SlotFragment[]>>(
    (groups, slot) => {
      const day = DateTime.fromJSDate(new Date(slot.startAt)).toISODate()
      if (!groups[day]) {
        groups[day] = []
      }
      groups[day].push(slot)
      return groups
    },
    {},
  )

  const days = Object.keys(groupedSlots).sort()
  const in24Hrs = new Date(Date.now() + 24 * 3_600_000)

  return (
    <MenuGroup>
      <Section
        title={`${slots.length} slots (in your local TZ)`}
        children={null}
      />
      {days.map((day) => (
        <Section
          // @ts-ignore
          title={DateTime.fromISO(day).toLocaleString(DateTime.DATE_FULL)}
          key={day}
        >
          {groupedSlots[day].map((slot) => (
            <ButtonItem key={String(slot.startAt)} isDisabled>
              <SlotText isWarning={new Date(slot.endAt) < in24Hrs}>
                {/* @ts-ignore */}
                {DateTime.fromJSDate(new Date(slot.startAt)).toLocaleString(
                  DateTime.TIME_SIMPLE,
                )}
                {' → '}
                {/* @ts-ignore */}
                {DateTime.fromJSDate(new Date(slot.endAt)).toLocaleString(
                  DateTime.TIME_SIMPLE,
                )}
              </SlotText>
            </ButtonItem>
          ))}
        </Section>
      ))}
    </MenuGroup>
  )
}

export default Slots
