import cloneDeep from 'lodash/cloneDeep'
import React, { useCallback, useMemo } from 'react'
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
} from 'react-sortable-hoc'
import styled from 'styled-components'

import { LoadingSpinner } from '../../../../components/Spinner'
import {
  TopicTemplateFragment,
  TopicFormFieldTypename,
  TopicFormFieldFragment,
  useSaveTopicTemplateFormMutation,
} from '../../../../graphql'
import { deepCleanTypename } from '../../../../lib/utils'

import FormField from './FormField'

const Outer = SortableContainer(styled.div`
  padding: 8px 0;
  flex: 1;
  margin-bottom: 24px;
`)

const SortableFormField = SortableElement(FormField)

interface FormProps {
  template: TopicTemplateFragment
}

const Form = ({ template }: FormProps) => {
  const [saveTemplateForm, { loading }] = useSaveTopicTemplateFormMutation()

  const formFields = useMemo(() => template.form?.fields || [], [template])

  const saveFields = useCallback(
    (fields: TopicFormFieldFragment[]) => {
      saveTemplateForm({
        variables: {
          input: {
            templateId: template.id,
            fields: fields.map(({ __typename, ...field }) => ({
              ...deepCleanTypename(field),
              typename:
                TopicFormFieldTypename[__typename as TopicFormFieldTypename],
            })),
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          saveTopicTemplateForm: {
            __typename: 'SaveTopicTemplateFormOutput',
            template: {
              ...template,
              form: template.form
                ? {
                    ...template.form,
                    fields,
                  }
                : null,
            },
          },
        },
      })
    },
    [saveTemplateForm, template],
  )

  const saveFieldAtIndex = useCallback(
    (field: TopicFormFieldFragment | null, index: number) => {
      const fields = cloneDeep(formFields)
      fields.splice(index, 1, ...(field ? [field] : []))
      saveFields(fields)
    },
    [formFields, saveFields],
  )

  const sortFields: SortEndHandler = useCallback(
    ({ oldIndex, newIndex }) => {
      // Do not trigger save if order did not change
      if (oldIndex === newIndex) {
        return
      }

      const fields = [...formFields]
      const field = fields.splice(oldIndex, 1)[0]
      fields.splice(newIndex, 0, field)

      saveFields(fields)
    },
    [formFields, saveFields],
  )

  return (
    <Outer onSortEnd={sortFields} useDragHandle lockAxis={'y'}>
      {formFields.map((field, index) => (
        <SortableFormField
          key={field.id}
          index={index}
          field={field}
          previousFields={formFields.slice(0, index)}
          onChangeValue={(field) => saveFieldAtIndex(field, index)}
        />
      ))}
      <FormField
        key={formFields.length}
        previousFields={formFields}
        onChangeValue={(field) => saveFieldAtIndex(field, formFields.length)}
      />
      <LoadingSpinner show={loading} />
    </Outer>
  )
}

export default Form
