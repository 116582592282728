import Button, { ButtonGroup } from '@atlaskit/button'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import LockFilledIcon from '@atlaskit/icon/glyph/lock-filled'
import Popup from '@atlaskit/popup'
import { DateTime } from 'luxon'
import React from 'react'

import {
  MoreDropdownMenu,
  DropdownItem,
  DropdownItemGroup,
} from '../../../../components/DropdownMenu'
import useSwitch from '../../../../lib/useSwitch'
import { ReactComponent as DollarIcon } from '../icons/dollar.svg'
import { Filter } from '../types'

import LockEntriesPopup from './LockEntriesPopup'
import MarkPaidEntriesPopup from './MarkPaidEntriesPopup'
import useExportToCSV from './useExportToCSV'

type Props = {
  filter: Filter
  lockedAt?: DateTime
  paidAt?: DateTime
}

const Actions = ({ filter, lockedAt, paidAt }: Props) => {
  const [exportToCsv, { loading: exportToCsvLoading }] = useExportToCSV(filter)
  const [isDropdownOpen, openDropdown, closeDropdown] = useSwitch(false)

  const [isLockEntriesPopupOpen, openLockEntriesPopup, closeLockEntriesPopup] =
    useSwitch(false)

  const [
    isMarkPaidEntriesPopupOpen,
    openMarkPaidEntriesPopup,
    closeMarkPaidEntriesPopup,
  ] = useSwitch(false)

  return (
    <ButtonGroup>
      <Popup
        content={() => (
          <MarkPaidEntriesPopup
            lastPaidAtDate={paidAt}
            onClose={closeMarkPaidEntriesPopup}
          />
        )}
        onClose={closeMarkPaidEntriesPopup}
        isOpen={isMarkPaidEntriesPopupOpen}
        trigger={(triggerProps) => (
          <Button
            {...triggerProps}
            onClick={openMarkPaidEntriesPopup}
            iconBefore={<DollarIcon />}
          >
            {paidAt
              ? `Paid through ${paidAt
                  .setZone('America/New_York')
                  .toFormat('dd LLL yyyy HH:mm ZZZZ')}`
              : 'Mark paid'}
          </Button>
        )}
      />
      <Popup
        content={() => (
          <LockEntriesPopup
            lastLockedAtDate={lockedAt}
            onClose={closeLockEntriesPopup}
          />
        )}
        onClose={closeLockEntriesPopup}
        isOpen={isLockEntriesPopupOpen}
        trigger={(triggerProps) => (
          <Button
            {...triggerProps}
            onClick={openLockEntriesPopup}
            iconBefore={<LockFilledIcon label={'lock'} />}
            appearance={'default'}
          >
            {lockedAt
              ? `Locked through ${lockedAt
                  .setZone('America/New_York')
                  .toFormat('dd LLL yyyy HH:mm ZZZZ')}`
              : 'Lock entries'}
          </Button>
        )}
      />
      <MoreDropdownMenu
        trigger={'Export'}
        triggerButtonProps={{
          iconBefore: undefined,
          iconAfter: <ChevronDownIcon label={'chevron-down'} />,
          appearance: 'primary',
          spacing: 'default',
        }}
        isOpen={isDropdownOpen || exportToCsvLoading}
        onOpenChange={({ isOpen }) => {
          isOpen ? openDropdown() : closeDropdown()
        }}
      >
        <DropdownItemGroup>
          <DropdownItem
            isDisabled={!filter.endedAfter || exportToCsvLoading}
            onClick={exportToCsv}
          >
            {exportToCsvLoading ? 'Exporting...' : 'Full Report CSV'}
          </DropdownItem>
        </DropdownItemGroup>
      </MoreDropdownMenu>
    </ButtonGroup>
  )
}

export default Actions
