import { DatePicker } from '@atlaskit/datetime-picker'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Modal, { Props as ModalProps } from '../../../../components/Modal'
import { Field } from '../../../../components/form'
import { FullUserFragment, useArchiveUserMutation } from '../../../../graphql'

type Props = ModalProps & {
  user: FullUserFragment
}

const ArchiveUserModal = ({
  user,
  onClose,
  onCloseComplete: _onCloseComplete,
  ...modalProps
}: Props) => {
  const userId = user?.id || ''

  const navHistory = useHistory()

  const [endDate, setEndDate] = useState<string>('')
  const [archiveUser, { loading: isArchivingUser }] = useArchiveUserMutation({
    onCompleted: () => {
      navHistory.replace('/users')
      // 💩 Force clear cache
      setTimeout(() => window.location.reload(), 300)
    },
  })

  const onSelectEndDate = useCallback(
    (newEndDate: string) => {
      const today = new Date().toISOString().split('T')[0]
      if (today >= newEndDate) {
        setEndDate(newEndDate)
      }
    },
    [setEndDate],
  )

  const onArchiveUser = useCallback(
    () =>
      archiveUser({
        variables: {
          input: {
            userId: userId,
            endDate,
          },
        },
      }),
    [archiveUser, endDate, userId],
  )

  const actions = useMemo(
    () => [
      {
        text: 'Archive',
        isDisabled: !endDate,
        isLoading: isArchivingUser,
        onClick: onArchiveUser,
      },
      { text: 'Cancel', onClick: onClose },
    ],
    [onClose, endDate, isArchivingUser, onArchiveUser],
  )

  return (
    <Modal
      actions={actions}
      heading={`Archive ${user?.profile.displayName}`}
      appearance={'danger'}
      width={'small'}
      autoFocus={false}
      onCloseComplete={(e) => [setEndDate(''), _onCloseComplete?.(e)]}
      onClose={onClose}
      {...modalProps}
    >
      {'This will archive user and all their data.'}
      <br />
      {'This action is reversible, although not easily.'}
      <br />
      <Field label={'Select End Date'} isRequired>
        <DatePicker value={endDate} onChange={onSelectEndDate} />
      </Field>
    </Modal>
  )
}

export default ArchiveUserModal
