import { LoadingButton as Button } from '@atlaskit/button'
import Drawer from '@atlaskit/drawer'
import InlineMessage from '@atlaskit/inline-message'
import React, { ComponentProps, useCallback, useState } from 'react'
import styled from 'styled-components'

import {
  useGetEmailTemplateByIdQuery,
  useSendEmailFromTemplateMutation,
  WorkspaceUserRole,
  namedOperations,
} from '../../../graphql'
import useSwitch from '../../../lib/useSwitch'
import useValues from '../../../lib/useValues'
import { LoadingSpinner } from '../../Spinner'
import EmailActivity from '../EmailActivity'

import Editor from './Editor'
import Preview from './Preview'
import { Email } from './types'
import { missingVars, isValidEmail } from './utils'

const Outer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Spacer = styled.div`
  flex: 0 0 auto;
  min-width: 32px;
`

const PreviewOuter = styled.div`
  flex: 1 1 auto;
  min-width: 400px;
  max-width: 640px;
`

const Buttons = styled.div`
  padding: 16px 4px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  & > * + * {
    margin-right: 8px;
  }
`

type Props = {
  templateIds: string[]
  toRole?: WorkspaceUserRole.EXECUTIVE | WorkspaceUserRole.ASSISTANT | null
  email?: Partial<Email>
  onCancel?: () => void
  onSendComplete?: (email: Email) => void
}

const EmailComposer = ({
  templateIds,
  email: defaultEmail,
  onCancel,
  onSendComplete,
  toRole = WorkspaceUserRole.EXECUTIVE,
}: Props) => {
  const [isSent, setIsSent] = useState(false)
  const [email, { patch }] = useValues<Partial<Email>>({
    vars: {},
    ...defaultEmail,
  })
  const { data } = useGetEmailTemplateByIdQuery({
    variables: { id: email.templateId || '' },
    skip: !email.templateId,
  })
  const [sendEmail, { loading: loadingSend, error: errorSend }] =
    useSendEmailFromTemplateMutation({
      refetchQueries: [namedOperations.Query.GetEmailActivities],
    })

  const onPatch = useCallback(
    (...args: Parameters<typeof patch>) => {
      setIsSent(false)
      return patch(...args)
    },
    [patch, setIsSent],
  )

  const template =
    data?.emailTemplate.id === email.templateId ? data?.emailTemplate : null

  const send = useCallback(async () => {
    if (template && isValidEmail(template, email)) {
      const result = await sendEmail({
        variables: {
          input: {
            ...email,
            // don't validate because the onboarding templates now utilize slot data variables as well (which are not sent here)
            dontValidateVars: true,
          },
        },
      })
      setIsSent(true)
      onSendComplete?.(email)

      return result
    }
  }, [email, sendEmail, template, onSendComplete])

  return (
    <Outer>
      <Editor
        toRole={toRole}
        email={email}
        templateIds={templateIds}
        onPatch={onPatch}
      />
      <Spacer />
      {template && (
        <PreviewOuter>
          <Preview email={email} highlightVars />
          <Buttons>
            <Button
              isDisabled={
                isSent || !(template && isValidEmail(template, email))
              }
              appearance={'primary'}
              onClick={send}
              isLoading={loadingSend}
            >
              {'Send'}
            </Button>
            {!!onCancel && (
              <Button onClick={onCancel}>{isSent ? 'Close' : 'Cancel'}</Button>
            )}
            {!!(template && missingVars(template, email).length > 0) && (
              <InlineMessage
                title={'Missing: ' + missingVars(template, email).join(', ')}
                type={'warning'}
              />
            )}
            {errorSend && (
              <InlineMessage
                title={errorSend.message || 'Error sending email'}
                type={'error'}
              />
            )}
            {isSent && (
              <InlineMessage
                title={'Email was successfully sent 👍'}
                type={'confirmation'}
              />
            )}
          </Buttons>
        </PreviewOuter>
      )}

      <LoadingSpinner show={loadingSend} />
    </Outer>
  )
}

const DrawerInner = styled.div`
  padding: 0 24px 0 8px;
`

type DrawerProps = ComponentProps<typeof EmailComposer> & {
  isOpen?: boolean
  onClose?: () => void
  onOpenComplete?: () => void
  onCloseComplete?: () => void
}

export const EmailComposerDrawer = ({
  isOpen,
  onClose,
  onOpenComplete,
  onCloseComplete,
  ...props
}: DrawerProps) => (
  <Drawer
    isOpen={!!isOpen}
    onClose={onClose}
    onOpenComplete={onOpenComplete}
    onCloseComplete={onCloseComplete}
    width={'extended'}
  >
    <DrawerInner>
      <EmailComposer onCancel={onClose} {...props} />
    </DrawerInner>
  </Drawer>
)

type EmailComposerButtonProps = {
  noActivity?: boolean
  buttonProps: ComponentProps<typeof Button>
  activityProps?: Omit<ComponentProps<typeof EmailActivity>, 'templateIds'>
  composerProps: ComponentProps<typeof EmailComposer>
}

export const EmailComposerButton = ({
  noActivity,
  buttonProps: _buttonProps,
  activityProps,
  composerProps,
}: EmailComposerButtonProps) => {
  const [isComposerOpen, openComposer, closeComposer] = useSwitch(false)

  const { isLoading, ...buttonProps } = { ..._buttonProps }

  return (
    <>
      <Button
        appearance={'default'}
        isLoading={isComposerOpen || isLoading}
        {...buttonProps}
        onClick={openComposer}
      />
      {!noActivity && (
        <EmailActivity
          sendgridTemplateIds={composerProps.templateIds}
          {...activityProps}
        />
      )}
      <EmailComposerDrawer
        isOpen={isComposerOpen}
        onClose={closeComposer}
        {...composerProps}
      />
    </>
  )
}

export default EmailComposer
