import CancelIcon from '@atlaskit/icon/glyph/archive'
import CopyIcon from '@atlaskit/icon/glyph/copy'
import ClearLogsIcon from '@atlaskit/icon/glyph/trash'
import RunIcon from '@atlaskit/icon/glyph/vid-play'
import ReviewIcon from '@atlaskit/icon/glyph/watch-filled'
import React, { useCallback } from 'react'

import {
  MoreDropdownMenu,
  DropdownItemGroup,
  DropdownItem,
} from '../../../../components/DropdownMenu'
import {
  AutomationJob_FullFragment,
  useReviewAutomationJobMutation,
  useCancelAutomationJobMutation,
  namedOperations,
  useRunAutopilotJobManuallyMutation,
} from '../../../../graphql'
import useIsAllowedOperation from '../../../../hooks/useIsAllowedOperation'
import { hasCompleted, isPending, isReviewed } from '../utils'

type Props = {
  job: AutomationJob_FullFragment
}

const ActionsMenu = ({ job }: Props) => {
  const isAllowed = useIsAllowedOperation()
  const canReview = isAllowed('Mutation.reviewAutomationJob')
  const canCancel = isAllowed('Mutation.cancelAutomationJob')
  const canRun = isAllowed('Mutation.runAutopilotJobManually')

  const [cancelJob, cancelResults] = useCancelAutomationJobMutation({
    variables: {
      input: {
        id: job.id,
      },
    },
    refetchQueries: [namedOperations.Query.GetUser],
  })

  const [runJob, runResults] = useRunAutopilotJobManuallyMutation({
    variables: {
      input: {
        jobId: job.id,
      },
    },
    refetchQueries: [namedOperations.Query.GetAutomationJobById],
  })

  const [markAsReviewed, reviewResults] = useReviewAutomationJobMutation({
    variables: {
      input: {
        id: job.id,
        clearLogs: false,
      },
    },
  })

  const [clearLogs, clearLogsResults] = useReviewAutomationJobMutation({
    variables: {
      input: {
        id: job.id,
        clearLogs: true,
      },
    },
  })

  const onRunJob = useCallback(async () => {
    if (
      !window.confirm(
        'Last chance to turn back! ⛔️\n\nMake sure you are aware of all side effects of running this job. For example, will values be written to Airtable?\n\n You still sure? Okay, its your funeral. 💀',
      )
    ) {
      return
    }

    await runJob()
  }, [runJob])

  const loading =
    cancelResults.loading ||
    runResults.loading ||
    reviewResults.loading ||
    clearLogsResults.loading

  return (
    <MoreDropdownMenu isLoading={loading}>
      <DropdownItemGroup title={'I know what I’m doing!'}>
        <DropdownItem
          onClick={() => cancelJob()}
          isDisabled={!isPending(job) || !canCancel}
          elemBefore={<CancelIcon size={'small'} label={''} />}
          description={'Will not run unless rescheduled'}
        >
          {'Cancel job'}
        </DropdownItem>
        {canRun && (
          <DropdownItem
            onClick={onRunJob}
            isDisabled={hasCompleted(job)}
            elemBefore={<RunIcon size={'small'} label={''} />}
            description={"Seriously, do you know what you're doing?"}
          >
            {'Run job'}
          </DropdownItem>
        )}
      </DropdownItemGroup>
      <DropdownItemGroup title={'Review'}>
        <DropdownItem
          onClick={() => markAsReviewed()}
          isDisabled={isReviewed(job) || !canReview}
          elemBefore={<ReviewIcon size={'small'} label={''} />}
        >
          {'Mark as reviewed'}
        </DropdownItem>
        <DropdownItem
          onClick={() => clearLogs()}
          isDisabled={!canReview}
          elemBefore={<ClearLogsIcon size={'small'} label={''} />}
        >
          {'Clear logs'}
        </DropdownItem>
      </DropdownItemGroup>
      <DropdownItemGroup title={'Inside the matrix'}>
        <DropdownItem
          onClick={() => navigator.clipboard.writeText(job.id)}
          elemBefore={<CopyIcon size={'small'} label={''} />}
          description={job.id}
        >
          {'Copy job ID'}
        </DropdownItem>
        <DropdownItem
          onClick={() => navigator.clipboard.writeText(job.automation.id)}
          elemBefore={<CopyIcon size={'small'} label={''} />}
          description={job.automation.id}
        >
          {'Copy automation ID'}
        </DropdownItem>
      </DropdownItemGroup>
    </MoreDropdownMenu>
  )
}

export default ActionsMenu
