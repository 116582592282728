import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down'
import ArrowUpIcon from '@atlaskit/icon/glyph/arrow-up'
import CommentIcon from '@atlaskit/icon/glyph/comment'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import { ModalTitle } from '@atlaskit/modal-dialog'
import { useCallback, useMemo, useRef } from 'react'

import Guard from '../../../../../../components/Guard'
import { AdhocTodoFragment } from '../../../../../../graphql'
import useIsAllowedOperation from '../../../../../../hooks/useIsAllowedOperation'
import useSwitch from '../../../../../../lib/useSwitch'

import ChatMessage from './ChatMessage'
import EmailMessage from './EmailMessage'
import {
  CloseButton,
  MessagesContainer,
  Modal,
  ModalHeader,
  ScrollButton,
} from './styled'

type Props = {
  title?: string
  conversationThreads: AdhocTodoFragment['communicationThreads']
}

const ConversationThread = ({
  title = 'Email thread',
  conversationThreads,
}: Props) => {
  const { emailThread, chatThread } = conversationThreads || {
    emailThread: [],
    chatThread: [],
  }
  const [isModalOpen, openModal, closeModal] = useSwitch(false)
  const isAllowed = useIsAllowedOperation()

  //create an time ordered combination of email and chat messages where email is forwardedAt and chat is createdAt and they are strings or dates
  const combinedThread = useMemo(() => {
    const combined = [...emailThread, ...chatThread]

    return combined
      .map((item) => {
        return {
          ...item,
          time:
            item.__typename === 'EmailThreadItem'
              ? item.forwardedAt
              : item.__typename === 'ChatThreadItem'
              ? item.createdAt
              : '',
        }
      })
      .sort((a, b) => {
        return new Date(a.time).getTime() - new Date(b.time).getTime()
      })
  }, [emailThread, chatThread])

  const topRef = useRef<HTMLDivElement>(null)
  const bottomRef = useRef<HTMLDivElement>(null)

  const [isAtTop, setTop, setBottom] = useSwitch(true)

  const jumpToTop = useCallback(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    setTop()
  }, [setTop])

  const jumpToBottom = useCallback(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    setBottom()
  }, [setBottom])

  const hasThreadItems = useMemo(
    () => Boolean(emailThread.length || chatThread.length),
    [chatThread.length, emailThread.length],
  )

  const canViewConversationThread = useMemo(
    () => isAllowed('AdhocTodo.communicationThreads'),
    [isAllowed],
  )

  return (
    <>
      <Guard operationId={'AdhocTodo.communicationThreads'} policy={'blur'}>
        {canViewConversationThread ? (
          <a onClick={openModal}>
            {hasThreadItems && <CommentIcon label={'Chat'} />}
          </a>
        ) : (
          <CommentIcon label={'Chat'} />
        )}
      </Guard>
      <Modal
        heading={
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            <ScrollButton onClick={isAtTop ? jumpToBottom : jumpToTop}>
              {isAtTop ? (
                <ArrowDownIcon label={'down'} />
              ) : (
                <ArrowUpIcon label={'up'} />
              )}{' '}
              {`Scroll to ${isAtTop ? 'bottom' : 'top'}`}{' '}
            </ScrollButton>
            <CloseButton onClick={closeModal}>
              <CrossIcon label={''} />
            </CloseButton>
          </ModalHeader>
        }
        width={'large'}
        isOpen={isModalOpen}
        onClose={closeModal}
        autoFocus
      >
        <MessagesContainer id={'messages-container'}>
          <div ref={topRef} />
          {combinedThread.map((item, index) =>
            item.__typename === 'EmailThreadItem' ? (
              <EmailMessage key={index} message={item} />
            ) : item.__typename === 'ChatThreadItem' ? (
              <ChatMessage key={index} message={item} />
            ) : null,
          )}
          <div ref={bottomRef} />
        </MessagesContainer>
      </Modal>
    </>
  )
}

export default ConversationThread
