import SearchIcon from '@atlaskit/icon/glyph/search'
import WarningIcon from '@atlaskit/icon/glyph/warning'
import Tooltip from '@atlaskit/tooltip'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'

import Section from '../../../../components/Section'
import { Select } from '../../../../components/form'
import _AssistantScore from '../../../../components/users/AssistantScore'
import { Maybe } from '../../../../graphql'

import HistoricalData from './HistoricalData'
import MonthlyPerformance from './MonthlyPerformance'
import {
  StyledButton,
  DatePickerOuter,
  Outer,
  SectionTitle,
  SelectContainer,
  StyledSelect,
} from './styled'

interface Props {
  userId: string
  startDate?: Maybe<string>
}

const YEAR_INITIALIZER = 2020

const Score = ({ userId }: Props) => {
  //Setup date picker
  const startOfNextMonth = DateTime.local().plus({ months: 1 }).startOf('month')
  const [selectedDate, setSelectedDate] = useState(startOfNextMonth)
  const [isInvalidDate, setIsInvalidDate] = useState(false)
  const [queryDate, setQueryDate] = useState(startOfNextMonth)

  const [year, setYear] = useState(startOfNextMonth.year)
  const [month, setMonth] = useState(startOfNextMonth.monthLong)

  // only show months up to the current month if the year is the current year
  const monthOptions = useMemo(
    () =>
      Array.from(
        {
          length: year === startOfNextMonth.year ? startOfNextMonth.month : 12,
        },
        (_, i) => ({
          label: DateTime.fromObject({ month: i + 1 }).monthLong,
          value: DateTime.fromObject({ month: i + 1 }).monthLong,
        }),
      ).reverse(),
    [year, startOfNextMonth.year, startOfNextMonth.month],
  )

  const yearSpan = startOfNextMonth.year - YEAR_INITIALIZER

  const yearOptions = Array.from({ length: yearSpan }, (_, i) => ({
    label: startOfNextMonth.year - i,
    value: startOfNextMonth.year - i,
  }))

  // update the selected date when the year or month changes
  useEffect(() => {
    const startOfNextMonth = DateTime.local()
      .plus({ months: 1 })
      .startOf('month')
    const date = DateTime.fromFormat(`${year}-${month}-01`, 'yyyy-MMMM-dd')
    // if the date is invalid or in the future, disable the button
    if (!date.isValid || date > startOfNextMonth) {
      setIsInvalidDate(true)
    } else {
      setIsInvalidDate(false)
    }
    setSelectedDate(date)
  }, [year, month])

  return (
    <Outer>
      <SelectContainer>
        <DatePickerOuter>
          <Select
            placeholder={month}
            options={monthOptions}
            value={month}
            onChangeValue={setMonth}
          />
        </DatePickerOuter>
        <DatePickerOuter>
          <StyledSelect
            placeholder={year}
            options={yearOptions}
            value={year}
            onChangeValue={setYear}
            $isInvalid={isInvalidDate}
          />
        </DatePickerOuter>
        <StyledButton
          iconBefore={<SearchIcon label={''} size={'medium'} />}
          onClick={() => setQueryDate(selectedDate)}
          isDisabled={isInvalidDate}
        />
        {isInvalidDate && (
          <Tooltip position={'right'} content={'Invalid or Future Date'}>
            <WarningIcon label={''} size={'medium'} />
          </Tooltip>
        )}
      </SelectContainer>
      <SectionTitle>{`${queryDate.monthLong} ${queryDate.year} Performance`}</SectionTitle>
      <MonthlyPerformance date={queryDate.toISODate()} userId={userId} />
      <Section>
        <SectionTitle>{`Past 9 Months`}</SectionTitle>
        <HistoricalData userId={userId} />
      </Section>
    </Outer>
  )
}

export default Score
