import Select, { CheckboxSelect, OptionType } from '@atlaskit/select'
import uniq from 'lodash/uniq'
import React, { ComponentProps } from 'react'

import { LANGUAGE_CODES, getLanguageName } from './utils'

const DEFAULT_CODES = ['en', 'fr', 'es']

const getLanguageOption = (code: string): OptionType => ({
  value: code,
  label: getLanguageName(code),
})

type LanguagesSelectProps = ComponentProps<typeof CheckboxSelect> & {
  values?: string[] | null
  onChangeValues: (newValues: string[] | undefined) => void
}

export const LanguagesSelect = ({
  values,
  onChangeValues,
  ...props
}: LanguagesSelectProps) => (
  <CheckboxSelect
    options={uniq(
      [...(values || []), ...DEFAULT_CODES, ...LANGUAGE_CODES].filter(
        (c): c is string => !!c,
      ),
    ).map(getLanguageOption)}
    value={values?.map(getLanguageOption)}
    isMulti
    onChange={(options) =>
      // @ts-ignore
      onChangeValues(options?.map(({ value }: { value: string }) => value))
    }
    {...props}
  />
)

type LanguageSelectProps = ComponentProps<typeof Select> & {
  value?: string | null
  onChangeValue: (newValues: string | undefined) => void
  hideCodes?: string[]
  onlyCodes?: string[]
}

export const LanguageSelect = ({
  value,
  onChangeValue,
  hideCodes,
  onlyCodes,
  ...props
}: LanguageSelectProps) => (
  <Select<OptionType>
    // @ts-ignore
    options={uniq(
      [value, ...DEFAULT_CODES, ...LANGUAGE_CODES].filter(
        (c): c is string =>
          !!c &&
          !hideCodes?.includes(c) &&
          (!onlyCodes?.length || onlyCodes.includes(c)),
      ),
    ).map(getLanguageOption)}
    value={value ? getLanguageOption(value) : null}
    onChange={(option) => onChangeValue(option?.value?.toString())}
    {...props}
  />
)
