import Star from './Star'
import { RatingInner } from './styled'

export const Rating = ({ rating }: { rating: number }) => (
  <RatingInner>
    {[1, 2, 3, 4, 5].map((value) => (
      <Star key={value} value={value} rating={rating} />
    ))}
  </RatingInner>
)
