import { LoadingButton } from '@atlaskit/button'
import Popup, { PopupComponentProps } from '@atlaskit/popup'
import { elevation } from '@atlaskit/theme'
import React, {
  ComponentProps,
  forwardRef,
  useCallback,
  useEffect,
} from 'react'
import styled from 'styled-components'

import useSwitch from '../lib/useSwitch'

const PopupContentContainer = styled.div`
  ${elevation.e200};
  background-color: var(--ds-surface-overlay, #ffffff);
  border-radius: 3px;
  display: block;
  box-sizing: border-box;
  padding: 8px;
  min-width: 360px;
  max-width: 450px;
`

const PopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>(
  ({ children, ...props }, ref) => (
    <PopupContentContainer {...props} ref={ref}>
      {children}
    </PopupContentContainer>
  ),
)

type Props = Omit<
  ComponentProps<typeof Popup>,
  'isOpen' | 'trigger' | 'onClose'
> & {
  buttonProps: ComponentProps<typeof LoadingButton>
  onOpen?: () => void
  onClose?: () => void
}

const PopupButton = ({
  buttonProps,
  onOpen,
  onClose: _onClose,
  ...popupProps
}: Props) => {
  const [isOpen, , close, toggle] = useSwitch(false)

  const onClose = useCallback(() => {
    close()
    _onClose?.()
  }, [_onClose, close])

  useEffect(() => {
    if (onOpen && isOpen) {
      onOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Popup
      autoFocus={false}
      isOpen={isOpen}
      onClose={onClose}
      popupComponent={PopupContainer}
      trigger={(triggerProps) => (
        <LoadingButton
          {...triggerProps}
          isSelected={isOpen}
          onClick={toggle}
          appearance={buttonProps.appearance || (isOpen ? 'primary' : 'subtle')}
          {...buttonProps}
        />
      )}
      {...popupProps}
    />
  )
}

export default PopupButton
