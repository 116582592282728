import Avatar from '@atlaskit/avatar'
import { LoadingButton as Button } from '@atlaskit/button'
import { CodeBlock } from '@atlaskit/code'
import CompletedIcon from '@atlaskit/icon/glyph/check-circle-outline'
import ErrorIcon from '@atlaskit/icon/glyph/error'
import NotCompletedIcon from '@atlaskit/icon/glyph/media-services/preselected'
import InlineMessage from '@atlaskit/inline-message'
import { colors, typography } from '@atlaskit/theme'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import Box from '../../../../components/Box'
import Panel from '../../../../components/Panel'
import { Field } from '../../../../components/form'
import { EmailComposerButton } from '../../../../components/messages/EmailMessageComposer'
import { EmailComposerButton as LegacyEmailComposerButton } from '../../../../components/messages/SendgridEmailComposer'
import { SmsComposerButton } from '../../../../components/messages/SmsComposer'
import _WorkspaceOnboardingAvailabilityButton from '../../../../components/onboarding-availability/WorkspaceOnboardingAvailabilityButton'
import { SingleUserSelect } from '../../../../components/users/Select'
import {
  useCompleteOnboardingMutation,
  useSentOnboardingEmailMutation,
  OnboardingStepId,
  GetWorkspaceOnboardingQuery,
  useAssignOnboardingSpecialistMutation,
  OnboardingEmailIndex,
  useCancelOnboardingCallMutation,
  useUpdateOnboardingStepMutation,
  useGetMeQuery,
  useGetWorkspaceOnboardingAvailabilityQuery,
} from '../../../../graphql'
import useSwitch from '../../../../lib/useSwitch'

import ExitOnboardingModal from './ExitOnboardingModal'
import ScheduleOnboardingCallModal from './ScheduleOnboardingCallModal'
import { Steps, Step, Row, StepDetail, Column } from './components'

const Card = styled.div`
  display: flex;
  color: ${colors.text};
`

const OnboardingSpecialistOuter = styled.div`
  min-width: 280px;
`

const Name = styled.h3`
  ${typography.h700}
  margin-top: 8px;
`

const Hl = styled.div`
  height: 2px;
  width: 100%;
  background: ${colors.backgroundHover};
  margin: 16px 2px;
`

const Notice = styled.p`
  ${typography.h200}
`

const WorkspaceOnboardingAvailabilityButton = styled(
  _WorkspaceOnboardingAvailabilityButton,
)`
  margin-top: 8px;
`

enum AdminOnboardingStepId {
  ASSIGN_SPECIALIST = 'ASSIGN_SPECIALIST',
  SEND_EMAIL = 'SEND_EMAIL',
  SEND_EMAIL_2 = 'SEND_EMAIL_2',
  SEND_EMAIL_3 = 'SEND_EMAIL_3',
  SEND_EMAIL_4 = 'SEND_EMAIL_4',
  CALL = 'CALL',
}

const ONBOARDING_EMAIL_1_TEMPLATE_IDS = [
  'd-2f7258e31f80413492ccd4cbe70af789', // O1
  'd-c645312a4fad4f67af03bcb8a91286ad', // O1 french
  'd-b416b6a4afac4dea8d1b96488f01cf4d', // Teammate / O1 matching done
  'd-e6be8fbddf094853bf4e183d7813f577', // Teammate / O1 matching done / FR
]
const ONBOARDING_EMAIL_2_TEMPLATE_IDS = [
  'd-32f8a023332c4d53af32cb97cf84b4ba', // O2
  'd-166c99e852b84ca69241ede592adceca', // O2 FR
]
const ONBOARDING_EMAIL_3_TEMPLATE_IDS = [
  'd-e7a0903df2ea430282d4b07d3aa5da95', // O3
  'd-13d4d9200a1f423288f242b56058ad59', // O3 FR
]
const ONBOARDING_EMAIL_4_TEMPLATE_IDS = [
  'd-b1f97f5a554d453d9700fa161f200939', // O4
  'd-7892b21a96154d18bca9f947aaa01b88', // O4 FR
]
const ONBOARDING_TEAMMATE_NO_ASSISTANT_EMAIL_TEMPLATE_IDS = [
  'd-8be18b0faf1f4719bf912d6e4c8e5bba', // O1 matching NOT done
]
const ONBOARDING_ASSISTANT_SCHEDULED_CALL_EMAIL_TEMPLATE_IDS = [
  'b7394815-1886-4334-abeb-8a534b2c4b91',
]
const MISSING_CALL_TEMPLATE_IDS = ['4ae0386f-440f-4ce3-9ab2-3dcf24132aa2']

const TEXT_MESSAGE_1_TEMPLATES = [
  {
    label: 'Onboarding / O1',
    value:
      'Hi {{EXECUTIVE_GIVEN_NAME}}, welcome to Double!\n\nGreat news, you’ve been matched! Open your emails to meet your Double and schedule your onboarding call.\n\nDidn’t receive the email? Contact welcome@withdouble.com for assistance.\n\nWe’re excited to get you a few hours back to focus on what matters!\n\n{{SENDER_GIVEN_NAME}} @ Double HQ',
  },
]
const TEXT_MESSAGE_2_3_TEMPLATES = [
  {
    label: 'Onboarding / O2 & O3',
    value:
      'Hi {{EXECUTIVE_GIVEN_NAME}}, quick reminder that you’ve been matched and your double is ready to start and save you time!\n\nDidn’t receive the email? Contact welcome@withdouble.com for assistance.\n\n{{SENDER_GIVEN_NAME}} @ Double HQ',
  },
]
const TEXT_MESSAGE_4_TEMPLATES = [
  {
    label: 'Onboarding / O4',
    value:
      'Hi {{EXECUTIVE_GIVEN_NAME}}, quick reminder that you’ve been matched and your double is ready to start and save you time!\n\nIf you are unable to schedule your onboarding today, we will assign your assistant to another executive on our waitlist.\n\nDidn’t receive the email? Contact welcome@withdouble.com for assistance.\n\n{{SENDER_GIVEN_NAME}} @ Double HQ',
  },
]

function assertNever(value: never): never {
  throw new Error(`Unknown value: ${value}`)
}

function getStepTitle(stepId: OnboardingStepId): string {
  switch (stepId) {
    case OnboardingStepId.CALENDAR:
      return 'Share your calendar'
    case OnboardingStepId.INBOX:
      return 'Delegate your inbox'
    case OnboardingStepId.CREDENTIALS:
      return 'Share logins and passwords'
    case OnboardingStepId.PREFERENCES:
      return 'General preferences'
    case OnboardingStepId.THIRD_PARTY_APPS:
      return 'Your apps'
    case OnboardingStepId.DOUBLE_APPS:
      return 'Install Double'
    case OnboardingStepId.BILLING:
      return '💳  Payment details'
    case OnboardingStepId.SCHEDULE_CALL:
      return '📅  Schedule call'
    case OnboardingStepId.GOALS:
      return '🎯  Goals'
    case OnboardingStepId.DEMO:
      return '💻  App demo'
    case OnboardingStepId.TASKS:
      return '🔨  Tasks'

    default:
      assertNever(stepId)
  }
}

const ORDERED_STEP_IDS = [
  OnboardingStepId.BILLING,
  OnboardingStepId.SCHEDULE_CALL,
  OnboardingStepId.DEMO,
]

interface Props {
  data: GetWorkspaceOnboardingQuery
}

const OnboardingSection = ({ data }: Props) => {
  const { data: meData } = useGetMeQuery()
  const me = meData?.me
  const [_selectedStepId, selectStepId] = useState<
    AdminOnboardingStepId | OnboardingStepId
  >()
  const selectedStepId =
    _selectedStepId || AdminOnboardingStepId.ASSIGN_SPECIALIST

  const workspace = data.workspace
  const onboarding = data.workspace.onboarding
  const hasAssistant = workspace.assistants?.length > 0
  const isTeammate = !!workspace.executives[0]?.principalUser

  const [isSchedulingCall, openScheduleCallModal, closeScheduleCallModal] =
    useSwitch(false)
  const [
    isExitingOnboarding,
    openExitOnboardingModal,
    closeExitOnboardingModal,
  ] = useSwitch(false)

  const [_assignOnboardingSpecialist, { loading: loadingSpecialist }] =
    useAssignOnboardingSpecialistMutation()
  const assignOnboardingSpecialist = useCallback(
    (user: null | { id: string }) => {
      if (workspace) {
        _assignOnboardingSpecialist({
          variables: {
            input: {
              workspaceId: workspace.id,
              specialistId: user?.id || null,
            },
          },
        })
      }
    },
    [_assignOnboardingSpecialist, workspace],
  )

  const [sentEmail, { loading: loadingSentEmail }] =
    useSentOnboardingEmailMutation({
      variables: {
        input: {
          workspaceId: workspace.id,
          emailIndex:
            selectedStepId === AdminOnboardingStepId.SEND_EMAIL
              ? OnboardingEmailIndex.ONE
              : selectedStepId === AdminOnboardingStepId.SEND_EMAIL_2
              ? OnboardingEmailIndex.TWO
              : selectedStepId === AdminOnboardingStepId.SEND_EMAIL_3
              ? OnboardingEmailIndex.THREE
              : selectedStepId === AdminOnboardingStepId.SEND_EMAIL_4
              ? OnboardingEmailIndex.FOUR
              : null,
        },
      },
    })

  const [_completeOnboarding, { loading: loadingCompleteOnboarding }] =
    useCompleteOnboardingMutation()

  const completeOnboarding = useCallback(
    async (attendedOnboarding, enrollInNoCallOnboardingCampaign) => {
      await _completeOnboarding({
        variables: {
          input: {
            workspaceId: workspace.id,
            attendedOnboarding,
            enrollInNoCallOnboardingCampaign,
          },
        },
      })

      closeExitOnboardingModal()
    },
    [_completeOnboarding, closeExitOnboardingModal, workspace.id],
  )

  const [
    markScheduleCallAsCompleted,
    { loading: loadingCompleteScheduleCall },
  ] = useUpdateOnboardingStepMutation({
    variables: {
      input: {
        workspaceId: workspace.id,
        stepId: OnboardingStepId.SCHEDULE_CALL,
        isCompleted: true,
        data:
          workspace.onboarding?.steps?.find(
            ({ id }) => id === OnboardingStepId.SCHEDULE_CALL,
          )?.data || {},
      },
    },
  })

  const [_cancelScheduledCall, { loading: loadingCancelScheduleCall }] =
    useCancelOnboardingCallMutation({
      variables: {
        input: {
          workspaceId: workspace.id,
        },
      },
    })

  const cancelScheduledCall = useCallback(() => {
    if (
      window.confirm(
        'Cancel scheduled call? Executive will have to re-schedule',
      )
    ) {
      _cancelScheduledCall()
    }
  }, [_cancelScheduledCall])

  const selectedStep = onboarding?.steps.find(({ id }) => id === selectedStepId)

  useEffect(() => {
    if (!(onboarding && !_selectedStepId)) {
      return
    }

    if (
      onboarding.isCompleted ||
      onboarding.steps.some(
        ({ isCompleted, id }) =>
          isCompleted && id === OnboardingStepId.SCHEDULE_CALL,
      )
    ) {
      // Onboarding is either completed or call is scheduled, so go to last step, the call
      selectStepId(AdminOnboardingStepId.CALL)
      return
    }

    for (let index = ORDERED_STEP_IDS.length - 1; index >= 0; index--) {
      const step = onboarding.steps.find(
        ({ isCompleted, id }) => id === ORDERED_STEP_IDS[index] && isCompleted,
      )
      if (step) {
        if (index === ORDERED_STEP_IDS.length - 1) {
          selectStepId(AdminOnboardingStepId.CALL)
        } else {
          selectStepId(ORDERED_STEP_IDS[index + 1])
        }
        return
      }
    }

    if (onboarding.hasSentEmail4) {
      selectStepId(ORDERED_STEP_IDS[0])
    } else if (onboarding.hasSentEmail3) {
      selectStepId(AdminOnboardingStepId.SEND_EMAIL_4)
    } else if (onboarding.hasSentEmail2) {
      selectStepId(AdminOnboardingStepId.SEND_EMAIL_3)
    } else if (onboarding.hasSentEmail) {
      selectStepId(AdminOnboardingStepId.SEND_EMAIL_2)
    } else {
      selectStepId(AdminOnboardingStepId.ASSIGN_SPECIALIST)
    }
  }, [_selectedStepId, onboarding])

  const splashIsValid =
    onboarding?.splash.executiveEmail &&
    onboarding?.splash.assistantAvatarUrl &&
    onboarding?.splash.assistantBiography &&
    onboarding?.splash.assistantGivenName &&
    onboarding?.splash.assistantLocation

  const {
    data: onboardingAvailability,
    loading: loadingOnboardingAvailability,
  } = useGetWorkspaceOnboardingAvailabilityQuery({
    variables: {
      workspaceId: workspace.id,
    },
    skip: !(selectedStepId === AdminOnboardingStepId.SEND_EMAIL),
  })

  const onboardingSlots =
    onboardingAvailability?.workspace.onboarding?.availability.slots ?? []

  return (
    <>
      {!!onboarding && !onboarding?.isCompleted && (
        <Panel header={'Splash'}>
          <Box>
            <Card>
              <Column>
                <Avatar
                  src={onboarding?.splash.assistantAvatarUrl}
                  size={'xlarge'}
                />
                {!!workspace.assistants[0] && (
                  <NavLink to={`/users/${workspace.assistants[0]?.id || ''}`}>
                    <Button appearance={splashIsValid ? 'default' : 'primary'}>
                      {'Edit'}
                    </Button>
                  </NavLink>
                )}
              </Column>

              <Column>
                <Name>{onboarding?.splash.assistantGivenName}</Name>
                <Field label={'Location'}>
                  {() => <p>{onboarding?.splash.assistantLocation}</p>}
                </Field>
                <Field label={'Biography'}>
                  {() => <p>{onboarding?.splash.assistantBiography}</p>}
                </Field>
              </Column>
            </Card>
          </Box>
        </Panel>
      )}

      {hasAssistant ? (
        <Panel
          header={'Onboarding'}
          isDefaultExpanded={!onboarding?.isCompleted}
        >
          <Row>
            <Steps>
              <Step
                selected={
                  selectedStepId === AdminOnboardingStepId.ASSIGN_SPECIALIST
                }
                onClick={() =>
                  selectStepId(AdminOnboardingStepId.ASSIGN_SPECIALIST)
                }
              >
                {'💁  Assign Specialist'}
                {onboarding?.specialist ? (
                  <CompletedIcon label={''} primaryColor={colors.green()} />
                ) : (
                  <NotCompletedIcon
                    label={''}
                    primaryColor={colors.backgroundHover()}
                  />
                )}
              </Step>
              <Step
                selected={selectedStepId === AdminOnboardingStepId.SEND_EMAIL}
                onClick={() => selectStepId(AdminOnboardingStepId.SEND_EMAIL)}
              >
                {'✉️  Send Onboarding Email'}
                {onboarding?.hasSentEmail ? (
                  <CompletedIcon label={''} primaryColor={colors.green()} />
                ) : (
                  <NotCompletedIcon
                    label={''}
                    primaryColor={colors.backgroundHover()}
                  />
                )}
              </Step>
              <Step
                selected={selectedStepId === AdminOnboardingStepId.SEND_EMAIL_2}
                onClick={() => selectStepId(AdminOnboardingStepId.SEND_EMAIL_2)}
              >
                {'✉️  Send Reminder Email 2'}
                {onboarding?.hasSentEmail2 ? (
                  <CompletedIcon label={''} primaryColor={colors.green()} />
                ) : (
                  <NotCompletedIcon
                    label={''}
                    primaryColor={colors.backgroundHover()}
                  />
                )}
              </Step>
              <Step
                selected={selectedStepId === AdminOnboardingStepId.SEND_EMAIL_3}
                onClick={() => selectStepId(AdminOnboardingStepId.SEND_EMAIL_3)}
              >
                {'✉️  Send Reminder Email 3'}
                {onboarding?.hasSentEmail3 ? (
                  <CompletedIcon label={''} primaryColor={colors.green()} />
                ) : (
                  <NotCompletedIcon
                    label={''}
                    primaryColor={colors.backgroundHover()}
                  />
                )}
              </Step>
              <Step
                selected={selectedStepId === AdminOnboardingStepId.SEND_EMAIL_4}
                onClick={() => selectStepId(AdminOnboardingStepId.SEND_EMAIL_4)}
              >
                {'✉️  Send Reminder Email 4'}
                {onboarding?.hasSentEmail4 ? (
                  <CompletedIcon label={''} primaryColor={colors.green()} />
                ) : (
                  <NotCompletedIcon
                    label={''}
                    primaryColor={colors.backgroundHover()}
                  />
                )}
              </Step>
              {ORDERED_STEP_IDS.map((stepId) => {
                const step = onboarding?.steps.find(({ id }) => id === stepId)
                return (
                  <Step
                    key={stepId}
                    selected={selectedStepId === stepId}
                    onClick={() => selectStepId(stepId)}
                  >
                    {getStepTitle(stepId)}
                    {step?.isCompleted ? (
                      <CompletedIcon label={''} primaryColor={colors.green()} />
                    ) : step?.data?.error ? (
                      <ErrorIcon label={''} primaryColor={colors.red()} />
                    ) : (
                      <NotCompletedIcon
                        label={''}
                        primaryColor={colors.backgroundHover()}
                      />
                    )}
                  </Step>
                )
              })}
              <Step
                selected={selectedStepId === AdminOnboardingStepId.CALL}
                onClick={() => selectStepId(AdminOnboardingStepId.CALL)}
              >
                {'📞  Onboarding Call'}
                {onboarding?.isCompleted ? (
                  <CompletedIcon label={''} primaryColor={colors.green()} />
                ) : (
                  <NotCompletedIcon
                    label={''}
                    primaryColor={colors.backgroundHover()}
                  />
                )}
              </Step>
            </Steps>
            <StepDetail>
              {selectedStepId === AdminOnboardingStepId.ASSIGN_SPECIALIST && (
                <OnboardingSpecialistOuter>
                  <SingleUserSelect
                    value={onboarding?.specialist || undefined}
                    adminRoles={['ONBOARDING_SPECIALIST']}
                    onChange={assignOnboardingSpecialist}
                    placeholder={'Onboarding specialist (optional)'}
                    defaultOptions
                    maxMenuHeight={250}
                    isLoading={loadingSpecialist || undefined}
                    isClearable
                  />
                  <WorkspaceOnboardingAvailabilityButton
                    workspaceId={workspace.id}
                    children={'Check available call slots'}
                  />
                </OnboardingSpecialistOuter>
              )}
              {[
                AdminOnboardingStepId.SEND_EMAIL,
                AdminOnboardingStepId.SEND_EMAIL_2,
                AdminOnboardingStepId.SEND_EMAIL_3,
                AdminOnboardingStepId.SEND_EMAIL_4,
              ].includes(selectedStepId as AdminOnboardingStepId) && (
                <>
                  <LegacyEmailComposerButton
                    buttonProps={{
                      appearance: (
                        selectedStepId === AdminOnboardingStepId.SEND_EMAIL
                          ? onboarding?.hasSentEmail
                          : selectedStepId ===
                            AdminOnboardingStepId.SEND_EMAIL_2
                          ? onboarding?.hasSentEmail2
                          : selectedStepId ===
                            AdminOnboardingStepId.SEND_EMAIL_3
                          ? onboarding?.hasSentEmail3
                          : selectedStepId ===
                            AdminOnboardingStepId.SEND_EMAIL_4
                          ? onboarding?.hasSentEmail4
                          : false
                      )
                        ? 'default'
                        : 'primary',
                      isDisabled: !splashIsValid,
                      isLoading:
                        loadingSentEmail || loadingOnboardingAvailability,
                      children:
                        'Preview & send email' +
                        ((
                          selectedStepId === AdminOnboardingStepId.SEND_EMAIL
                            ? onboarding?.hasSentEmail
                            : selectedStepId ===
                              AdminOnboardingStepId.SEND_EMAIL_2
                            ? onboarding?.hasSentEmail2
                            : selectedStepId ===
                              AdminOnboardingStepId.SEND_EMAIL_3
                            ? onboarding?.hasSentEmail3
                            : selectedStepId ===
                              AdminOnboardingStepId.SEND_EMAIL_4
                            ? onboarding?.hasSentEmail4
                            : false
                        )
                          ? ' (again)'
                          : ''),
                    }}
                    activityProps={{
                      recipientEmails: workspace?.executives[0]?.profile.email
                        ? [workspace?.executives[0]?.profile.email]
                        : undefined,
                    }}
                    composerProps={{
                      onSendComplete: () => sentEmail(),
                      templateIds:
                        selectedStepId === AdminOnboardingStepId.SEND_EMAIL
                          ? ONBOARDING_EMAIL_1_TEMPLATE_IDS
                          : selectedStepId ===
                            AdminOnboardingStepId.SEND_EMAIL_2
                          ? ONBOARDING_EMAIL_2_TEMPLATE_IDS
                          : selectedStepId ===
                            AdminOnboardingStepId.SEND_EMAIL_3
                          ? ONBOARDING_EMAIL_3_TEMPLATE_IDS
                          : selectedStepId ===
                            AdminOnboardingStepId.SEND_EMAIL_4
                          ? ONBOARDING_EMAIL_4_TEMPLATE_IDS
                          : [],
                      email: {
                        workspaceId: workspace?.id,
                        userId: workspace?.executives[0].id,
                        vars: {
                          ONBOARDING_URL: onboarding?.url,
                          PRINCIPAL_GIVEN_NAME:
                            workspace.executives[0]?.principalUser?.profile
                              .givenName,
                          PRINCIPAL_ASSISTANT_GIVEN_NAME:
                            workspace.executives[0]?.principalUser
                              ?.workspaces?.[0]?.assistants?.[0]?.profile
                              .givenName,
                          HOURS_LIMIT_TEXT: workspace.executives[0]?.hoursLimit
                            ? `for up to ${workspace.executives[0]?.hoursLimit} hours per month`
                            : 'with no hour limit',
                          WORK_AND_PERSONAL: workspace.executives[0]
                            ?.isAllowedPersonal
                            ? 'work and personal'
                            : 'work',
                          NUMBER_OF_SLOTS_AVAILABLE: `${onboardingSlots.length}`,
                          NUMBER_OF_DAYS_AVAILABLE: (() => {
                            const uniqueDays = new Set(
                              onboardingSlots?.map(({ startAt }) =>
                                DateTime.fromJSDate(new Date(startAt), {
                                  zone:
                                    workspace.workingHoursTimeZone ||
                                    'America/New_York',
                                }).toISODate(),
                              ) || [],
                            )
                            return `${uniqueDays.size}`
                          })(),
                          FIRST_AVAILABLE_SLOT: (() => {
                            if (onboardingSlots.length === 0) {
                              return '--'
                            }
                            return DateTime.fromJSDate(
                              new Date(onboardingSlots[0].startAt),
                              {
                                zone:
                                  workspace.workingHoursTimeZone ||
                                  'America/New_York',
                              },
                            ).toLocaleString(DateTime.DATETIME_FULL, {
                              locale:
                                workspace.workingHoursTimeZone ===
                                'Europe/Paris'
                                  ? 'fr-FR'
                                  : 'en-US',
                            })
                          })(),
                        },
                        replyTo: {
                          email: 'welcome@withdouble.com',
                        },
                        tags: ['onboarding'],
                      },
                    }}
                  />
                  <SmsComposerButton
                    buttonProps={{
                      children: 'Preview and send SMS',
                    }}
                    composerProps={{
                      toUserId: workspace.executives[0]?.id,
                      templates:
                        selectedStepId === AdminOnboardingStepId.SEND_EMAIL
                          ? TEXT_MESSAGE_1_TEMPLATES
                          : selectedStepId ===
                            AdminOnboardingStepId.SEND_EMAIL_2
                          ? TEXT_MESSAGE_2_3_TEMPLATES
                          : selectedStepId ===
                            AdminOnboardingStepId.SEND_EMAIL_3
                          ? TEXT_MESSAGE_2_3_TEMPLATES
                          : selectedStepId ===
                            AdminOnboardingStepId.SEND_EMAIL_4
                          ? TEXT_MESSAGE_4_TEMPLATES
                          : [],
                      vars: {
                        SENDER_GIVEN_NAME: 'The Double Team',
                        EXECUTIVE_GIVEN_NAME:
                          workspace.executives[0]?.profile.givenName || '',
                        ASSISTANT_GIVEN_NAME:
                          workspace.assistants[0]?.profile.givenName || '',
                      },
                    }}
                  />
                </>
              )}
              {selectedStepId === AdminOnboardingStepId.CALL && (
                <>
                  {onboarding?.isCompleted ? (
                    <InlineMessage
                      title={'Onboarding is complete 🎉'}
                      type={'confirmation'}
                    />
                  ) : (
                    <>
                      <Notice>
                        {
                          'This email should be sent automatically when the executive schedules their onboarding via the app. Send it manually if you know what you are doing.'
                        }
                      </Notice>
                      <EmailComposerButton
                        buttonProps={{
                          appearance: 'default',
                          isDisabled: !splashIsValid,
                          children: 'Preview & send email to ASSISTANT',
                        }}
                        activityProps={{
                          messageTemplateIds:
                            ONBOARDING_ASSISTANT_SCHEDULED_CALL_EMAIL_TEMPLATE_IDS,
                          recipientEmails: workspace?.assistants[0]?.profile
                            .email
                            ? [workspace?.assistants[0]?.profile.email]
                            : undefined,
                        }}
                        composerProps={{
                          messageTemplateIds:
                            ONBOARDING_ASSISTANT_SCHEDULED_CALL_EMAIL_TEMPLATE_IDS,
                          toUserId: workspace?.assistants[0].id,
                          executiveId: workspace?.executives[0]?.id,
                          assistantId: workspace?.assistants[0]?.id,
                          email: {
                            tags: ['onboarding'],
                          },
                        }}
                      />
                      <Hl />
                      <Button
                        // If step Onboarding-call is completed, then make this button primary
                        appearance={
                          onboarding?.steps.some(
                            ({ id, isCompleted }) =>
                              id === OnboardingStepId.SCHEDULE_CALL &&
                              isCompleted,
                          )
                            ? 'primary'
                            : 'default'
                        }
                        isLoading={loadingCompleteOnboarding}
                        onClick={openExitOnboardingModal}
                        isDisabled={!!onboarding?.isCompleted}
                      >
                        {'All done! - Exit onboarding'}
                      </Button>

                      <ExitOnboardingModal
                        isPrincipal={!isTeammate}
                        isOpen={isExitingOnboarding}
                        onClose={closeExitOnboardingModal}
                        onSubmit={completeOnboarding}
                        loading={loadingCompleteOnboarding}
                      />

                      <EmailComposerButton
                        buttonProps={{
                          appearance: 'subtle',
                          children: `Is ${workspace.executives[0]?.profile.givenName} not showing up to the call?`,
                        }}
                        noActivity
                        composerProps={{
                          messageTemplateIds: MISSING_CALL_TEMPLATE_IDS,
                          toUserId: workspace?.executives[0].id,
                          executiveId: workspace?.executives[0]?.id,
                          assistantId: workspace?.assistants[0]?.id,
                          workspaceId: workspace?.id,
                          email: {
                            vars: {
                              RESCHEDULE_CALL_URL:
                                workspace.onboarding?.rescheduleCallUrl,
                              SENDER_GIVEN_NAME: me?.profile.givenName,
                            },
                            from: {
                              name:
                                me?.profile.givenName ||
                                'Double Customer Success Team',
                              email:
                                me?.profile.email || 'welcome@withdouble.com',
                            },
                            tags: ['onboarding', 'onboarding-call'],
                          },
                        }}
                      />
                    </>
                  )}
                </>
              )}
              {selectedStep?.id === OnboardingStepId.SCHEDULE_CALL &&
                onboarding?.callStartAt && (
                  <>
                    <InlineMessage
                      title={DateTime.fromJSDate(
                        new Date(onboarding?.callStartAt),
                      ).toLocaleString(DateTime.DATETIME_FULL)}
                      type={'confirmation'}
                    />
                    <Button
                      isLoading={loadingCancelScheduleCall}
                      appearance={'default'}
                      onClick={() => cancelScheduledCall()}
                    >
                      {'Cancel scheduled call'}
                    </Button>
                    <Notice>
                      {'Need to reschedule?'}
                      <br />
                      {'Send the following link to the exec:'}
                      <br />
                      {`https://app.withdouble.com/schedule-onboarding?token=${data.workspace.id}`}
                    </Notice>
                  </>
                )}
              {selectedStep?.id === OnboardingStepId.SCHEDULE_CALL && (
                <>
                  <ScheduleOnboardingCallModal
                    workspaceId={workspace.id}
                    isOpen={isSchedulingCall}
                    onClose={closeScheduleCallModal}
                  />
                  <Button
                    isLoading={isSchedulingCall}
                    appearance={'default'}
                    onClick={openScheduleCallModal}
                  >
                    {'Manually (re-)schedule call'}
                  </Button>
                </>
              )}
              {selectedStep?.id === OnboardingStepId.SCHEDULE_CALL &&
                !selectedStep.isCompleted && (
                  <Button
                    isLoading={loadingCompleteScheduleCall}
                    appearance={'default'}
                    onClick={() => markScheduleCallAsCompleted()}
                  >
                    {'Mark as scheduled'}
                  </Button>
                )}

              {!!selectedStep?.data &&
                Object.keys(selectedStep.data).length > 0 && (
                  <CodeBlock
                    showLineNumbers={false}
                    language={'json'}
                    text={JSON.stringify(selectedStep?.data, null, 2)}
                  />
                )}
            </StepDetail>
          </Row>
        </Panel>
      ) : isTeammate ? (
        <Panel header={'Pre-matching'} isDefaultExpanded>
          <InlineMessage
            title={'User is a teammate and has no assistant yet'}
            type={'info'}
          />

          <LegacyEmailComposerButton
            buttonProps={{
              appearance: 'primary',
              children: 'Preview & send pre-matching email',
            }}
            activityProps={{
              recipientEmails: workspace?.executives[0]?.profile.email
                ? [workspace?.executives[0]?.profile.email]
                : undefined,
            }}
            composerProps={{
              onSendComplete: () => sentEmail(),
              templateIds: ONBOARDING_TEAMMATE_NO_ASSISTANT_EMAIL_TEMPLATE_IDS,
              email: {
                workspaceId: workspace?.id,
                userId: workspace?.executives[0].id,
                vars: {
                  ONBOARDING_URL: onboarding?.url,
                  PRINCIPAL_GIVEN_NAME:
                    workspace.executives[0]?.principalUser?.profile.givenName,
                  PRINCIPAL_ASSISTANT_GIVEN_NAME:
                    workspace.executives[0]?.principalUser?.workspaces?.[0]
                      ?.assistants?.[0].profile.givenName,
                  HOURS_LIMIT_TEXT: workspace.executives[0]?.hoursLimit
                    ? `for up to ${workspace.executives[0]?.hoursLimit} hours per month`
                    : 'with no hour limit',
                  WORK_AND_PERSONAL: workspace.executives[0]?.isAllowedPersonal
                    ? 'work and personal'
                    : 'work',
                },
                replyTo: {
                  email: 'welcome@withdouble.com',
                },
                tags: ['onboarding'],
              },
            }}
          />
        </Panel>
      ) : (
        'Assistant is missing in this workspace'
      )}
    </>
  )
}

export default OnboardingSection
