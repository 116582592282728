import { RadioSelect } from '@atlaskit/select'
import memoize from 'lodash/memoize'
import React, { ComponentProps, useCallback, useMemo } from 'react'

import { CefrScale } from '../graphql'

type Option = {
  value: CefrScale
  label: string
}

const DESCRIPTIONS: Record<CefrScale, string> = {
  A1: 'Beginner',
  A2: 'Elementary',
  B1: 'Intermediate',
  B2: 'Upper intermediate',
  C1: 'Advanced',
  C2: 'Proficiency',
}

export const getCefrLabel = (level: CefrScale): string =>
  `${level} - ${DESCRIPTIONS[level]}`

const toOption = memoize(
  (level: CefrScale): Option => ({
    value: level,
    label: getCefrLabel(level),
  }),
)

const fromOption = ({ value }: Option): CefrScale => value

const OPTIONS = Object.values(CefrScale).map(toOption)

type Props = Omit<ComponentProps<typeof RadioSelect>, 'options'> & {
  value?: CefrScale | undefined | null
  onChangeValue?: (newValue: CefrScale | undefined | null) => void
}

const CefrScaleSelect = ({
  value: _value,
  onChangeValue,
  onChange: _onChange,
  ...selectProps
}: Props) => {
  const value = useMemo(() => {
    if (_value) {
      return toOption(_value)
    }
  }, [_value])

  const onChange = useCallback(
    (newValue: Option) => {
      _onChange?.(newValue)
      onChangeValue?.(newValue && fromOption(newValue))
    },
    [_onChange, onChangeValue],
  )

  return (
    <RadioSelect
      options={OPTIONS}
      value={value}
      // @ts-ignore
      onChange={onChange}
      {...selectProps}
    />
  )
}

export default CefrScaleSelect
