import Button from '@atlaskit/button'
import TestingIcon from '@atlaskit/icon/glyph/jira/test-session'
import PageHeader from '@atlaskit/page-header'
import { ValueType } from '@atlaskit/select'
import React, { useCallback, useMemo, useState } from 'react'

import PageHeaderOuter from '../../../components/list/PageHeaderOuter'
import {
  AutomationParameter,
  useScheduleAutopilotJobsMutation,
} from '../../../graphql'
import useValues from '../../../lib/useValues'
import { Page } from '../../types'
import Detail from '../Jobs/Detail'
import Item from '../Jobs/List/Item'
import { ItemContainer } from '../Jobs/List/styled'

import FilterEditor from './components/FilterEditor'
import {
  ButtonContainer,
  DetailOuter,
  FilterOuter,
  ListOuter,
  Outer,
  Sidebar,
} from './styled'

export type TestingFilter = {
  triggerId: string | null
  parameters: ((AutomationParameter & { value: string }) | null)[]
  automationIds: ValueType<{ value: string; label: string }, true>
}

const convertToParameterValueType = ({
  type: _type,
  value,
}: AutomationParameter & { value: string }) => {
  const type = _type.toLowerCase()

  if (type.startsWith('[') || type.includes('jsonobject')) {
    // array or JSONObject
    return JSON.parse(value)
  } else if (type.includes('int') || type.includes('float')) {
    return Number(value)
  } else if (type === 'boolean') {
    return value.toLowerCase() === 'true'
  } else {
    return value
  }
}

const AutopilotTesting: Page = () => {
  const [scheduleAutopilotJobs, { data }] = useScheduleAutopilotJobsMutation()
  const scheduledJobs = data?.scheduleAutopilotJobs.scheduledJobs || []

  const [filters, { patch: patchFilters }] = useValues<TestingFilter>(
    {
      triggerId: null,
      parameters: [],
      automationIds: [],
    },
    ['triggerId', 'parameters', 'automationIds'],
  )
  const [selectedJobId, selectJobId] = useState<string>()

  const canSubmit = useMemo(() => {
    if (!filters.automationIds?.length) return false

    for (const parameter of filters.parameters) {
      // TODO - should we add more robust type checking here?
      if (parameter?.isRequired && !parameter.value) {
        return false
      }
    }

    return true
  }, [filters.automationIds?.length, filters.parameters])

  const onScheduleAutopilotJobs = useCallback(() => {
    const automationIds =
      filters.automationIds?.map((automation) => automation.value) || []

    if (!filters.triggerId || !automationIds.length) return

    const params = filters.parameters.reduce((acc, curr) => {
      if (!curr?.value) return acc

      acc[curr.name] = convertToParameterValueType(curr)
      return acc
    }, {} as Record<string, any>)

    scheduleAutopilotJobs({
      variables: {
        input: {
          triggerId: filters.triggerId,
          automationIds,
          params,
        },
      },
    })
  }, [
    filters.automationIds,
    filters.triggerId,
    filters.parameters,
    scheduleAutopilotJobs,
  ])

  return (
    <Outer>
      <Sidebar>
        <PageHeaderOuter>
          <PageHeader>{'Autopilot Testing'}</PageHeader>
        </PageHeaderOuter>
        <FilterOuter>
          <FilterEditor filters={filters} onPatch={patchFilters} />
        </FilterOuter>
        <ButtonContainer>
          <Button
            appearance={'primary'}
            isDisabled={!canSubmit}
            onClick={onScheduleAutopilotJobs}
          >
            {'Schedule selected automations'}
          </Button>
        </ButtonContainer>
      </Sidebar>
      <ListOuter>
        {scheduledJobs.map((job) => (
          <ItemContainer key={job.id}>
            <Item key={job.id} job={job} onClick={selectJobId} />
          </ItemContainer>
        ))}
      </ListOuter>
      <DetailOuter>
        <Detail automationJobId={selectedJobId} />
      </DetailOuter>
    </Outer>
  )
}

AutopilotTesting.path = '/autopilot-testing'
AutopilotTesting.navTitle = 'Autopilot Testing'
AutopilotTesting.Icon = TestingIcon
AutopilotTesting.requiredOperationIds = ['Mutation.scheduleAutopilotJobs']

export default AutopilotTesting
