import { LoadingButton as Button } from '@atlaskit/button'
import CopyIcon from '@atlaskit/icon/glyph/copy'
import DeleteIcon from '@atlaskit/icon/glyph/trash'
import React, { useState, useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import {
  MoreDropdownMenu,
  DropdownItemGroup,
  DropdownItem,
} from '../../../components/DropdownMenu'
import MarkdownEditor from '../../../components/MarkdownEditor'
import { ToggleStateless as Toggle } from '../../../components/Toggle'
import { Field, TextField } from '../../../components/form'
import {
  DossierFolderFragment,
  UpsertDossierFolderInput,
  useUpsertDossierFolderMutation,
} from '../../../graphql'
import useSwitch from '../../../lib/useSwitch'
import useValues from '../../../lib/useValues'

import BoxCompact from './components/BoxCompact'
import {
  BoxOuter,
  ToggleOuter,
  Buttons,
  Spacer,
  Lock,
} from './components/styled'
import useDossier from './useDossier'

type Props = {
  folder: DossierFolderFragment
  isSelected: boolean
  isEditing: boolean
}

const Folder = ({ folder: originalFolder, isSelected, isEditing }: Props) => {
  const { url } = useRouteMatch()
  const navHistory = useHistory()
  const { evictLocalFolder } = useDossier()
  const [input, { setters, reset }] = useValues<UpsertDossierFolderInput>(
    {
      folderId: originalFolder.id,
      orderIndex: originalFolder.orderIndex,
    },
    ['isPrivate', 'label', 'orderIndex', 'header'],
  )

  const folder = {
    ...originalFolder,
    ...input,
  }

  const [useHeader, , , toggleHeader] = useSwitch(!!folder.header?.isEnabled)
  const [title, setTitle] = useState(folder.header?.title || '')
  const [description, setDescription] = useState(
    folder.header?.description || '',
  )
  const isHeaderInvalid = useHeader && (!title || !description)

  const [upsert, { loading }] = useUpsertDossierFolderMutation()

  const cancelEdit = useCallback(() => {
    reset()
    if (!originalFolder.label) {
      evictLocalFolder(originalFolder.id)
      navHistory.replace(url)
    } else {
      navHistory.replace(`${url}/${originalFolder.id}/sections`)
    }
  }, [
    reset,
    originalFolder.label,
    originalFolder.id,
    evictLocalFolder,
    navHistory,
    url,
  ])

  const save = useCallback(async () => {
    input.header = {
      isEnabled: useHeader,
      title,
      description,
    }

    await upsert({
      variables: {
        input,
      },
    })
    reset()
    navHistory.replace(`${url}/${originalFolder.id}/sections`)
  }, [
    description,
    input,
    navHistory,
    originalFolder.id,
    reset,
    title,
    upsert,
    url,
    useHeader,
  ])

  if (!isSelected || !isEditing) {
    return (
      <BoxCompact
        label={folder.label}
        iconAfter={folder?.isPrivate && <Lock />}
        isSelected={isSelected}
        disableDragHandle={isEditing}
        selectUrl={`${url}/${folder.id}/sections`}
        editUrl={`${url}/${folder.id}/edit`}
      />
    )
  }

  return (
    <BoxOuter isSelected>
      <Field label={'Name'}>
        <TextField
          value={folder.label || undefined}
          onChangeValue={setters.label}
          placeholder={'Tab name'}
          isCompact
          autoFocus
        />
      </Field>
      <Field label={''}>
        <ToggleOuter>
          <Toggle
            size={'large'}
            isChecked={useHeader}
            onChange={toggleHeader}
          />
          {'Use header'}
        </ToggleOuter>
      </Field>
      {useHeader && (
        <>
          <Field label={'Header Title'}>
            <TextField
              value={title}
              onChangeValue={setTitle}
              placeholder={'Header Title'}
              isCompact
              autoFocus
              isInvalid={useHeader && !title}
            />
          </Field>
          <Field label={'Header Description'}>
            <MarkdownEditor
              isInline
              value={description}
              onChange={setDescription}
              isInvalid={useHeader && !description}
            />
          </Field>
        </>
      )}
      <Field label={''}>
        <ToggleOuter>
          <Toggle
            size={'large'}
            isChecked={!!folder.isPrivate}
            onChange={() => setters.isPrivate?.(!folder.isPrivate)}
          />
          {'Private'}
        </ToggleOuter>
      </Field>

      <Buttons>
        <Button
          appearance={'primary'}
          spacing={'compact'}
          onClick={save}
          isLoading={loading}
          isDisabled={loading || isHeaderInvalid}
        >
          {'Save'}
        </Button>
        <Button spacing={'compact'} onClick={cancelEdit} isDisabled={loading}>
          {'Cancel'}
        </Button>
        <Spacer />
        <MoreDropdownMenu>
          <DropdownItemGroup>
            <DropdownItem
              onClick={() => navigator.clipboard.writeText(folder.id)}
              elemBefore={<CopyIcon size={'small'} label={''} />}
              description={folder.id}
            >
              {'Copy tab ID'}
            </DropdownItem>
            <DropdownItem
              // onClick={}
              isDisabled
              elemBefore={<DeleteIcon size={'small'} label={''} />}
              description={'Child sections and fields will be deleted'}
            >
              {'Delete tab'}
            </DropdownItem>
          </DropdownItemGroup>
        </MoreDropdownMenu>
      </Buttons>
    </BoxOuter>
  )
}
export default Folder
