import { borderRadius, colors } from '@atlaskit/theme'
import { SortableHandle } from 'react-sortable-hoc'
import styled, { css } from 'styled-components'

import { MatchingProposalReply } from '../../../../../graphql'

export const replyTint = (
  reply?: MatchingProposalReply | null | undefined,
): string => {
  if (!reply) {
    return colors.purple()
  }

  return {
    [MatchingProposalReply.YES]: colors.green(),
    [MatchingProposalReply.NO]: colors.red(),
    [MatchingProposalReply.NEED_MORE_INFO]: colors.yellow(),
  }[reply]
}

export const Outer = styled.div<{ $tint: string }>`
  border-radius: ${borderRadius()}px;
  background: ${colors.N20};
  padding: 8px 12px;
  border-left: ${borderRadius() + 1}px solid ${({ $tint }) => $tint};
  margin-top: 8px;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  column-gap: 4px;
`

export const DragHandle = SortableHandle(styled.div<{ isDisabled?: boolean }>`
  flex: 0 0 auto;
  margin-right: -2px;
  cursor: grab;

  [draggable='true'] & {
    cursor: grabbing;
  }

  ${({ isDisabled }) =>
    !!isDisabled &&
    css`
      opacity: 0.3;
      cursor: no-drop;
    `}
`)
