import styled from 'styled-components'

export const Inner = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 0 30px;

  & > *:not(:first-child) {
    margin-left: 12px;
  }
`

export const FieldsRow = styled.div`
  display: flex;

  & > div {
    flex: 0 0 auto;

    &:first-child {
      flex: 1;
    }

    & + div {
      margin-left: 16px;
    }
  }
`
