import styled, { css } from 'styled-components'

import _AccountIcon from '../../../components/AccountIcon'

export const TabContent = styled.div<{ row?: boolean }>`
  overflow: auto;
  padding: 0 0 24px;
  flex: 1;
  ${(props) =>
    props.row &&
    css`
      display: flex;
      flex-direction: row;
    `}
`

export const Outer = styled.section`
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: stretch;
  max-width: 600px;
`

export const Content = styled.div`
  flex: 1;
  padding: 0 30px;
  display: flex;
  flex-flow: row;
  overflow: auto;

  h3 {
    margin-bottom: 6px;
  }

  h4 + * {
    margin-top: 12px;
  }

  .column {
    flex: 1 1 50%;
    display: flex;
    flex-flow: column;

    & + .column {
      margin-left: 16px;
    }
  }
`
