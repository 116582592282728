import Button, { ButtonProps } from '@atlaskit/button'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

import { ReactComponent as _Icon } from './Lever.svg'

const Icon = styled(_Icon)`
  width: 20px;
  height: 20px;
`

const LeverButton = (props: ButtonProps) => (
  <Button appearance={'subtle'} iconBefore={<Icon />} {...props} />
)

type LeverOpportunityButtonProps = ComponentProps<typeof LeverButton> & {
  opportunityId: string
}

export const LeverOpportunityButton = ({
  opportunityId,
  ...props
}: LeverOpportunityButtonProps) => (
  <LeverButton
    target={'_blank'}
    href={`https://hire.lever.co/candidates/${opportunityId}`}
    {...props}
  />
)

export default LeverButton
