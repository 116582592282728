import Button, { ButtonProps } from '@atlaskit/button'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

import { ReactComponent as _Icon } from './Airtable.svg'

const Icon = styled(_Icon)`
  width: 20px;
  height: 20px;
`

export const AirtableButton = (props: ButtonProps) => (
  <Button appearance={'subtle'} iconBefore={<Icon />} {...props} />
)

type AirtableRecordButtonProps = ComponentProps<typeof AirtableButton> & {
  tableId: string
  viewId?: string
  recordId?: string
}

export const AirtableLinkButton = ({
  tableId,
  viewId,
  recordId,
  ...props
}: AirtableRecordButtonProps) => (
  <AirtableButton
    target={'_blank'}
    href={`https://airtable.com/appr49XC9TIVZoflS/${tableId}${
      viewId ? `/${viewId}` : ''
    }${recordId ? `/${recordId}` : ''}`}
    {...props}
  />
)

export const AirtableLeadLinkButton = (
  props: Omit<AirtableRecordButtonProps, 'tableId'>,
) => <AirtableLinkButton tableId={'tblOzxDMT8Fguk2iq'} {...props} />

export const AirtableClientLinkButton = (
  props: Omit<AirtableRecordButtonProps, 'tableId'>,
) => <AirtableLinkButton tableId={'tblFHI4Rrc25Nseae'} {...props} />

export const AirtableDoubleLinkButton = (
  props: Omit<AirtableRecordButtonProps, 'tableId'>,
) => <AirtableLinkButton tableId={'tblKOLWZVttrQZChT'} {...props} />

export default AirtableButton
