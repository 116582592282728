import DragIcon from '@atlaskit/icon/glyph/drag-handler'
import EditIcon from '@atlaskit/icon/glyph/edit'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router'

import {
  BoxOuter,
  BoxCompactInner,
  BoxCompactTexts,
  BoxCompactLabel,
  BoxCompactDescription,
  EditButton,
  DragHandle,
  Spacer,
} from './styled'

type Props = {
  label?: string | undefined | null
  description?: string | undefined | null
  iconAfter?: React.ReactNode
  isSelected?: boolean
  disableDragHandle?: boolean
  selectUrl?: string
  editUrl?: string
}

const BoxCompact = ({
  label,
  description,
  disableDragHandle,
  iconAfter,
  isSelected,
  selectUrl,
  editUrl,
}: Props) => {
  const navHistory = useHistory()

  const onEdit = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation()
      editUrl && navHistory.push(editUrl)
    },
    [editUrl, navHistory],
  )

  const onSelect = useCallback(() => {
    selectUrl && navHistory.push(selectUrl)
  }, [navHistory, selectUrl])

  return (
    <BoxOuter
      isSelected={isSelected}
      onClick={selectUrl ? onSelect : undefined}
    >
      <BoxCompactInner>
        <DragHandle isDisabled={!!disableDragHandle}>
          <DragIcon label={''} />
        </DragHandle>
        <BoxCompactTexts>
          <BoxCompactLabel>{label}</BoxCompactLabel>
          {!!description && (
            <BoxCompactDescription>{description}</BoxCompactDescription>
          )}
        </BoxCompactTexts>
        {iconAfter}
        <Spacer />
        <EditButton
          onClick={onEdit}
          appearance={'subtle'}
          iconBefore={<EditIcon label={''} size={'small'} />}
        />
      </BoxCompactInner>
    </BoxOuter>
  )
}

export default BoxCompact
