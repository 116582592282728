import React from 'react'

import { Matching_ForTransitionListItemFragment } from '../../../../../../graphql'
import { MATCHING_STATUS_LABEL } from '../../../utils'

import StageButton from './components/StageButton'

type Props = {
  matching: Matching_ForTransitionListItemFragment
}

const Matching = ({ matching }: Props) => {
  return (
    <StageButton matching={matching} stageId={'matching'}>
      {MATCHING_STATUS_LABEL[matching.transition!.matchingStatus]}
    </StageButton>
  )
}

export default Matching
