import { useApolloClient } from '@apollo/client'
import { AsyncSelect, OptionType } from '@atlaskit/select'
import React, { ComponentProps, useCallback, useState, useEffect } from 'react'

import {
  GetCityByIdDocument,
  GetCityByIdQuery,
  GetCityByIdQueryVariables,
  CityFragment,
} from '../graphql'
import { useGoogle } from '../lib/google'

type Props = ComponentProps<typeof AsyncSelect> & {
  lookup?: string | null
  value?: CityFragment | null
  onChangeValue: (newValue: CityFragment | null) => void
}

const CitySelect = ({ value, onChangeValue, lookup, ...props }: Props) => {
  const { placesAutocomplete } = useGoogle()
  const client = useApolloClient()
  const [loading, setLoading] = useState(false)

  const loadCityOptions = useCallback(
    (input: string, done: (options: OptionType[]) => void) => {
      input = input?.toLowerCase()

      placesAutocomplete.getPlacePredictions(
        {
          input,
          types: ['(cities)'],
        },
        (predictions) => {
          const options = (predictions || []).map(
            ({ description: label, place_id: value }) => ({ label, value }),
          )
          done(options)
        },
      )
    },
    [placesAutocomplete],
  )

  const onChange = useCallback(
    async (option) => {
      if (!option) {
        onChangeValue(null)
        return
      }

      setLoading(true)
      const { value } = option

      try {
        const { data } = await client.query<
          GetCityByIdQuery,
          GetCityByIdQueryVariables
        >({
          query: GetCityByIdDocument,
          variables: { placeId: value },
        })

        data?.city && onChangeValue(data.city)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    },
    [client, onChangeValue, setLoading],
  )

  useEffect(() => {
    if (lookup) {
      loadCityOptions(lookup, (options) => {
        if (options.length === 1) {
          onChange(options[0])
        }
      })
    }
  }, [loadCityOptions, lookup, onChange])

  return (
    <AsyncSelect
      loadOptions={loadCityOptions}
      value={
        value
          ? { label: value.address?.formatted, value: value.googleMapsPlaceId }
          : null
      }
      placeholder={'Select city'}
      noOptionsMessage={() => 'Search for a city'}
      isMulti={false}
      // @ts-ignore
      onChange={onChange}
      isLoading={loading}
      {...props}
    />
  )
}
export default CitySelect
