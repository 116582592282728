import { useApolloClient } from '@apollo/client'
import { AsyncSelect, SelectProps } from '@atlaskit/select'
import React, { useCallback } from 'react'

import {
  ListFeaturesDocument,
  ListFeaturesQuery,
  ListFeaturesQueryVariables,
  FeatureFragment,
} from '../../../graphql'

export type OptionType = Pick<FeatureFragment, 'id'>

type Props = SelectProps<OptionType>

const FeatureSelect = ({ ...props }: Props) => {
  const client = useApolloClient()

  const loadOptions = useCallback(
    (inputValue: string): Promise<FeatureFragment[]> => {
      return new Promise(async (resolve) => {
        try {
          const { data } = await client.query<
            ListFeaturesQuery,
            ListFeaturesQueryVariables
          >({
            query: ListFeaturesDocument,
          })

          resolve(
            data.list.items.filter(({ id }) =>
              id.toLowerCase().includes(inputValue.toLowerCase()),
            ),
          )
        } catch (error) {
          resolve([])
        }
      })
    },
    [client],
  )

  return (
    <AsyncSelect<OptionType>
      defaultOptions
      loadOptions={loadOptions}
      placeholder={'Select feature'}
      getOptionValue={(feature) => feature.id}
      getOptionLabel={(feature) => feature.id}
      {...props}
      isMulti={false}
    />
  )
}

export default FeatureSelect
