import { colors } from '@atlaskit/theme'
import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import AdminRolesSelect from '../../../components/AdminRolesSelect'
import { ErrorEmptyState } from '../../../components/EmptyState'
import GuardedOperationsSelect from '../../../components/GuardedOperationsSelect'
import MarkdownEditor from '../../../components/MarkdownEditor'
import { Field, SaveButtons, TextField } from '../../../components/form'
import {
  AdminRoleFragment,
  ListAdminRolesDocument,
  ListAdminRolesQuery,
  UpsertAdminRoleInput,
  useListAdminRolesQuery,
  useListQueryCache,
  useUpsertAdminRoleMutation,
} from '../../../graphql'
import useSwitch from '../../../lib/useSwitch'
import useValues from '../../../lib/useValues'
import { areEquivalentObjects } from '../../../lib/utils'

import { isNewAdminRole, NEW_ADMIN_ROLE_ID, PATH } from './utils'

const Outer = styled.div`
  box-shadow: 0 0 0 2px ${colors.backgroundHover},
    0 0 10px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: stretch;
  padding: 12px 24px;
  overflow: auto;
  min-width: 400px;
`

const getInputFromAdminRole = (
  adminRole: AdminRoleFragment,
): UpsertAdminRoleInput => ({
  roleId: adminRole.id,
  name: adminRole.name,
  description: adminRole.description,
  inheritedRoleIds: adminRole.inheritedRoles.map(({ id }) => id),
  operationIds: adminRole.operations.map(({ id }) => id),
})

const isValidInput = (
  input: Partial<UpsertAdminRoleInput>,
): input is UpsertAdminRoleInput =>
  Boolean(
    input &&
      input.roleId?.trim() &&
      input.roleId !== NEW_ADMIN_ROLE_ID &&
      input.name?.trim() &&
      Array.isArray(input.inheritedRoleIds) &&
      Array.isArray(input.operationIds),
  )

type Props = {
  adminRoleId: string
}

const AdminRole = ({ adminRoleId }: Props) => {
  const navHistory = useHistory()
  const [autoUpdateId, , disableAutoUpdateId] = useSwitch(
    adminRoleId === NEW_ADMIN_ROLE_ID,
  )
  const { upsertItem: upsertLocalAdminRole, removeItem: evictLocalAdminRole } =
    useListQueryCache<ListAdminRolesQuery, AdminRoleFragment>(
      ListAdminRolesDocument,
    )

  const [upsertAdminRole, { loading }] = useUpsertAdminRoleMutation()

  const { data, error } = useListAdminRolesQuery()
  const adminRole = data?.list.items.find(({ id }) => id === adminRoleId)

  const [input, { setters, set }] = useValues<Partial<UpsertAdminRoleInput>>(
    {},
    ['description', 'roleId', 'name', 'inheritedRoleIds', 'operationIds'],
  )

  useEffect(() => {
    if (adminRole) {
      set(getInputFromAdminRole(adminRole))
    }
  }, [adminRole, set])

  const updateId = useCallback(
    (id: string | null | undefined, isAutoUpdateFromName?: boolean) => {
      if (
        isNewAdminRole(adminRole) &&
        (!isAutoUpdateFromName || autoUpdateId)
      ) {
        const cleanId = id
          ?.toUpperCase()
          .replace(/[^a-z0-9_]/gi, '_')
          .replace(/_+/gi, '_')
          .replace(/(^_+)/gi, '')
        setters.roleId?.(cleanId)
        if (!isAutoUpdateFromName) {
          disableAutoUpdateId()
        }
      }
    },
    [autoUpdateId, disableAutoUpdateId, setters, adminRole],
  )

  const updateName = useCallback(
    (name: string | null | undefined) => {
      const cleanName = name?.trimStart()
      setters.name?.(cleanName)
      updateId(cleanName, true)
    },
    [setters, updateId],
  )

  const updateDescription = useCallback(
    (description: string | null | undefined) => {
      setters.description?.(description?.trim() || null)
    },
    [setters],
  )

  const cancel = useCallback(() => {
    if (adminRole && isNewAdminRole(adminRole)) {
      // Cancel creation of new role
      evictLocalAdminRole(adminRole)
      navHistory.replace(PATH)
    } else {
      set(adminRole ? getInputFromAdminRole(adminRole) : {})
    }
  }, [adminRole, evictLocalAdminRole, navHistory, set])

  const save = useCallback(() => {
    if (isValidInput(input)) {
      upsertAdminRole({
        variables: {
          input,
        },
        onCompleted: ({ upsertAdminRole: { adminRole: updatedAdminRole } }) => {
          if (adminRole && isNewAdminRole(adminRole)) {
            evictLocalAdminRole(adminRole)
            upsertLocalAdminRole(updatedAdminRole)
            navHistory.replace(`${PATH}/${updatedAdminRole.id}`)
          }
        },
      })
    }
  }, [
    input,
    upsertAdminRole,
    adminRole,
    evictLocalAdminRole,
    upsertLocalAdminRole,
    navHistory,
  ])

  if (!adminRole) {
    return null
  }

  return (
    <Outer>
      {error && <ErrorEmptyState error={error} />}

      <Field
        label={'ID'}
        isRequired
        isDisabled={!isNewAdminRole(adminRole)}
        helperMessage={
          isNewAdminRole(adminRole)
            ? 'Cannot be changed after creation. Allowed characters: A..Z 0..9 _'
            : undefined
        }
      >
        <TextField
          value={input.roleId}
          isReadOnly={!isNewAdminRole(adminRole)}
          isDisabled={!isNewAdminRole(adminRole)}
          isInvalid={!input.roleId}
          appearance={!isNewAdminRole(adminRole) ? 'subtle' : 'standard'}
          onChangeValue={updateId}
        />
      </Field>

      <Field label={'Name'} isRequired>
        <TextField
          value={input.name}
          isInvalid={!input.name?.trim()}
          onChangeValue={updateName}
          autoFocus={isNewAdminRole(adminRole)}
        />
      </Field>

      <Field label={'Description'} isRequired>
        <MarkdownEditor
          key={adminRoleId}
          value={input?.description || ' '}
          onChange={updateDescription}
        />
      </Field>

      <Field label={'Inherited Roles'}>
        <AdminRolesSelect
          values={input.inheritedRoleIds}
          onChangeValues={setters.inheritedRoleIds!}
          excludeValues={[NEW_ADMIN_ROLE_ID, adminRoleId]}
        />
      </Field>

      <Field label={'Authorized Operations'}>
        <GuardedOperationsSelect
          operationIds={input.operationIds || []}
          inheritedRoleIds={input.inheritedRoleIds || []}
          onChangeOperationIds={setters.operationIds}
        />
      </Field>

      {(isNewAdminRole(adminRole) ||
        !areEquivalentObjects(input, getInputFromAdminRole(adminRole))) && (
        <SaveButtons
          isDisabled={!isValidInput(input)}
          isLoading={loading}
          onCancel={cancel}
          onSave={save}
        />
      )}
    </Outer>
  )
}

export default AdminRole
