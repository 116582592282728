import { colors } from '@atlaskit/theme'
import styled from 'styled-components'

import _Badge from '../../../../../../components/Badge'

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  min-height: 34px;

  border-bottom: 1px solid ${colors.N30};
`

export const Badge = styled(_Badge)`
  margin-bottom: 3px;
`

export const Title = styled.span`
  font-weight: 600;
  text-transform: capitalize;
`

export const RightAlignedButton = styled.div`
  margin-left: auto;
`
