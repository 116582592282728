import {
  PhoneNumberUtil,
  PhoneNumberFormat,
  PhoneNumber,
} from 'google-libphonenumber'

export type Sms = {
  to?: string
  text?: string
}

export type SmsTemplate = {
  label: string
  value: string
}

export function parsePhoneNumber(
  raw: string | undefined,
): PhoneNumber | undefined {
  try {
    const utils = PhoneNumberUtil.getInstance()

    if (!raw) {
      return
    }

    const number = utils.parse(raw)
    if (!number) {
      return
    }

    if (!utils.isValidNumber(number)) {
      return
    }

    return number
  } catch (error) {
    // Ignore errors
  }
}

export function formatPhoneNumber(
  number: PhoneNumber,
  readable = false,
): string {
  return PhoneNumberUtil.getInstance().format(
    number,
    readable ? PhoneNumberFormat.INTERNATIONAL : PhoneNumberFormat.E164,
  )
}

export function getValidText(text: string | undefined): string {
  return (text || '')?.trim()
}

export function getValidSms({ to, text }: Sms): Required<Sms> | undefined {
  const number = parsePhoneNumber(to)
  if (!number) {
    return
  }

  const validText = getValidText(text)
  if (!validText?.length) {
    return
  }

  return {
    to: formatPhoneNumber(number, false),
    text: validText,
  }
}
