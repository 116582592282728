import { colors } from '@atlaskit/theme'
import styled from 'styled-components'

export const Outer = styled.div<{ $primaryBackground: boolean }>`
  background-color: ${({ $primaryBackground }) =>
    $primaryBackground ? colors.N20 : colors.backgroundActive};
  padding: 8px;
  h3 {
    margin-top: 0;
  }
`
