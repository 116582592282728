import { colors } from '@atlaskit/theme'
import styled, { css } from 'styled-components'

export const Table = styled.table``

type TdProps = {
  empty?: boolean
  centered?: boolean
  bold?: boolean
  grey?: boolean
}

export const Td = styled.td<TdProps>`
  font-size: smaller;
  padding: 4px 8px;
  border: 1px solid ${colors.backgroundHover()};

  ${({ empty }) =>
    !!empty &&
    css`
      border: none;
      background-color: transparent !important;
    `}

  ${({ centered }) =>
    !!centered &&
    css`
      text-align: center;
    `}

  ${({ bold }) =>
    !!bold &&
    css`
      font-weight: 600;
    `}

  ${({ grey }) =>
    !!grey &&
    css`
      background-color: ${colors.N20};
    `}
`

export const Tr = styled.tr<{ isFaded?: boolean }>`
  tbody &:hover ${Td} {
    background-color: ${colors.backgroundActive()};
    /* color: ${colors.textHover()}; */
  }

  ${({ isFaded }) =>
    !!isFaded &&
    css`
      color: ${colors.N400};
      td:first-child {
        padding-left: 16px;
      }
    `}
`
