import { LoadingButton as Button, ButtonGroup } from '@atlaskit/button'
import { transparentize } from 'polished'
import React from 'react'
import styled from 'styled-components'

const Buttons = styled.div`
  padding: 16px 4px;
  position: sticky;
  bottom: 0;
  background-color: ${transparentize(0.2, 'white')};
`

type Props = {
  onCancel: () => void | Promise<void>
  onSave: () => void | Promise<void>
  isLoading?: boolean
  isDisabled?: boolean
}

const SaveButtons = ({ onCancel, onSave, isLoading, isDisabled }: Props) => (
  <Buttons>
    <ButtonGroup>
      <Button
        isDisabled={isDisabled}
        isLoading={isLoading}
        appearance={'primary'}
        onClick={onSave}
      >
        {'Save'}
      </Button>
      <Button onClick={onCancel}>{'Cancel'}</Button>
    </ButtonGroup>
  </Buttons>
)

export default SaveButtons
