import { colors, typography } from '@atlaskit/theme'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

export const HEIGHT = 34

const Outer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  ${typography.h200()}
  padding: 8px 16px;
  border-top: 2px solid ${colors.backgroundHover};
`

type Props = ComponentProps<typeof Outer> & {
  isLoading: boolean
  hasMore: boolean
  itemCount: number
}

const Footer = ({ isLoading, hasMore, itemCount, ...props }: Props) => (
  <Outer {...props}>
    {itemCount && !isLoading
      ? `${hasMore ? 'More than ' : 'Total: '}${itemCount} people`
      : 'Loading...'}
  </Outer>
)

export default Footer
