import type { TagColor } from '@atlaskit/tag'
import memoize from 'lodash/memoize'
import sortBy from 'lodash/sortBy'

import type {
  Matching_ForMatchingListItemFragment,
  ListMatchingsQueryVariables,
} from '../../../graphql'
import { notFalsy } from '../../../lib/utils'

// @ts-ignore
const TAG_COLORS: TagColor[] = [
  'green',
  'blue',
  'red',
  'purple',
  'grey',
  'teal',
  'yellow',
  'greenLight',
  'blueLight',
  'redLight',
  'purpleLight',
  'greyLight',
  'tealLight',
  'yellowLight',
]

export type Filter = Omit<ListMatchingsQueryVariables, 'after'> & {
  needsMatching: boolean
}

export const DEFAULT_FILTER: Readonly<Filter> = Object.freeze({
  isArchived: false,
  isCompleted: false,
  isTransition: null,
  isHiddenFromCatalog: false,
  isPrincipalLead: true,
  needsMatching: true,
})

export const hasActiveFilter = (filter: Filter): boolean =>
  filter.isArchived != null ||
  filter.isCompleted != null ||
  filter.isTransition != null ||
  filter.isHiddenFromCatalog != null ||
  filter.needsMatching

export const getCountryColor = memoize(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (_country: unknown): TagColor => TAG_COLORS.pop()!,
)

export const sortMatchings = ([...matchings]: (
  | Matching_ForMatchingListItemFragment
  | null
  | undefined
)[]): Matching_ForMatchingListItemFragment[] => {
  const sortFields: ((
    matching: Matching_ForMatchingListItemFragment,
  ) => string)[] = [
    ({ profiles }) => profiles?.[0]?.matchDueDate || '',
    ({ profiles }) => profiles?.[0]?.country || '',
    ({ profiles }) => profiles?.[0]?.introCallDate || '',
  ]
  return sortBy(matchings.filter(notFalsy), sortFields)
}
