import Select, { CreatableSelect } from '@atlaskit/select'
import uniq from 'lodash/uniq'
import React, { ComponentProps } from 'react'

type Props = ComponentProps<typeof Select> & {
  isCreatable?: boolean
  values?: string[] | null
  onChangeValues: (newValues: string[]) => void
}

const KeywordsSelect = ({
  values,
  onChangeValues,
  isCreatable,
  ...props
}: Props) => {
  const S = isCreatable ? CreatableSelect : Select

  const options = values?.map((value) => ({ value }))

  return (
    <S
      placeholder={'Keywords'}
      noOptionsMessage={() => 'Add a keyword'}
      value={options}
      isClearable
      isMulti
      options={options}
      // @ts-ignore
      getOptionLabel={({ value }) => value || ''}
      // @ts-ignore
      getOptionValue={({ value }) => value}
      onCreateOption={(industry: string) =>
        onChangeValues(uniq([...(values || []), industry]))
      }
      onChange={(values) =>
        // @ts-ignore
        onChangeValues((values || []).map(({ value }) => value))
      }
      {...props}
    />
  )
}
export default KeywordsSelect
