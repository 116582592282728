import Button from '@atlaskit/button'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import ChevronLeftIcon from '@atlaskit/icon/glyph/chevron-left'
import TableTree, { Row, Rows } from '@atlaskit/table-tree'
import { useMemo } from 'react'

import {
  AssistantUser_PerformanceScoreMetricEventsFragment,
  Maybe,
  onlyIfAssistantUser,
  usePerformanceScoreEventsQuery,
} from '../../../../../../graphql'
import useSwitch from '../../../../../../lib/useSwitch'

import MetricItem from './MetricItem'
import { Badge, RightAlignedButton, RowContainer, Title } from './styled'

interface Props {
  key: string
  metricKind: string
  percentage: number
  value: number
  eventsReviewURL?: Maybe<string>
  date: string
  userId: string
}

type Event = {
  id: string
  content: AssistantUser_PerformanceScoreMetricEventsFragment
  hasChildren: boolean
}

const MetricKindSection = ({
  metricKind,
  percentage,
  value,
  date,
  userId,
}: Props) => {
  const [isOpen, , , Toggle] = useSwitch(true)
  const formattedTitle = useMemo(
    () =>
      (metricKind.endsWith('s') || metricKind.includes('FEEDBACK')
        ? metricKind
        : `${metricKind}s`
      )
        .replaceAll('_', ' ')
        .toLowerCase(),
    [metricKind],
  )

  const { data, loading, error } = usePerformanceScoreEventsQuery({
    variables: {
      userId,
      date,
    },
  })

  const metricEvents = useMemo(() => {
    const user = onlyIfAssistantUser(data?.user)
    return user?.performanceScore?.series[0].metrics.find(
      (metric) => metric.metricKind === metricKind,
    )?.events
  }, [data?.user, metricKind])

  const events: Event[] =
    metricEvents?.map(
      (event) =>
        event && {
          id: event.id,
          content: event,
          hasChildren: false,
        },
    ) || []

  return (
    <>
      <RowContainer>
        <Badge
          text={value}
          tint={
            value == null
              ? undefined
              : value === 5
              ? 'green'
              : value >= 3
              ? 'yellow'
              : 'red'
          }
          isLight
        />
        <Title>{formattedTitle}</Title>
        {`(${percentage}%)`}
        {events.length !== 0 && (
          <RightAlignedButton>
            <Button
              appearance={'subtle'}
              iconBefore={
                isOpen ? (
                  <ChevronDownIcon label={''} />
                ) : (
                  <ChevronLeftIcon label={''} />
                )
              }
              onClick={Toggle}
            />
          </RightAlignedButton>
        )}
      </RowContainer>
      {isOpen &&
        (loading ? (
          <p>{'Loading...'}</p>
        ) : error ? (
          <p>{'Error'}</p>
        ) : (
          <TableTree>
            <Rows
              items={events}
              render={({ id, content: { executive, tags, date } }: Event) => (
                <Row itemId={id} hasChildren={false}>
                  <MetricItem
                    metricKind={metricKind}
                    executive={executive || undefined}
                    tags={tags}
                    date={date}
                  />
                </Row>
              )}
            />
          </TableTree>
        ))}
    </>
  )
}

export default MetricKindSection
