import AvatarGroup from '@atlaskit/avatar-group'
import GoogleMap from 'google-map-react'
import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import styled, { css } from 'styled-components'

import { BasicWhoFragment } from '../../graphql'

import useWhos, { Filters } from './useWhos'
import { MAP_OPTIONS, DEFAULT_CENTER, DEFAULT_ZOOM, MAP_KEYS } from './utils'

const Outer = styled.div`
  flex: 1;
`

const Pin = styled.div<{ large?: boolean }>`
  transition: transform 300ms ease;
  transform: translate(-10px, -10px);
  ${({ large }) =>
    large &&
    css`
      transform: translate(-20px, -10px) scale(2);
    `}
`

const getMaxAvatarsForZoom = (zoom: number): number => {
  if (zoom < 7) {
    return 2
  }

  if (zoom < 11) {
    return 4
  }

  return 90
}

type Props = {
  filters: Filters
}

const Map = ({ filters }: Props) => {
  const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM)
  // eslint-disable-next-line prefer-const
  let { users, user } = useWhos(filters)
  const navHistory = useHistory()

  const didChangeMap = useCallback(
    ({ zoom }: { zoom: number }) => setZoom(zoom),
    [setZoom],
  )

  // Move selected user to beginning
  if (user) {
    users = [...users].sort((_a, { id }) => (id === user?.id ? 1 : -1))
  }

  // Group users by location
  const locations: Record<string, any> = {}
  for (const _user of users) {
    const coordinates = _user.city?.geo?.coordinates
    if (coordinates) {
      const key = coordinates.lat * 1000 + coordinates.lng
      if (!locations[key]) {
        locations[key] = {
          coordinates,
          users: [],
        }
      }
      locations[key].users.push(_user)
    }
  }

  return (
    <Outer>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <div style={{ height, width }}>
            <GoogleMap
              bootstrapURLKeys={MAP_KEYS}
              defaultCenter={DEFAULT_CENTER}
              defaultZoom={DEFAULT_ZOOM}
              onChange={didChangeMap}
              options={MAP_OPTIONS}
            >
              {Object.values(locations).map(({ coordinates, users }) => (
                <Pin
                  key={JSON.stringify(coordinates)}
                  // large={users[0].id === user?.id}
                  {...coordinates}
                >
                  <AvatarGroup
                    size={'xsmall'}
                    maxCount={getMaxAvatarsForZoom(zoom)}
                    data={users.map(
                      ({ id, givenName, avatarUrl }: BasicWhoFragment) => ({
                        key: id,
                        name: givenName,
                        src: avatarUrl,
                        onClick: () => navHistory.push(`/whoswho/${id}`),
                      }),
                    )}
                  />
                </Pin>
              ))}
            </GoogleMap>
          </div>
        )}
      </AutoSizer>
    </Outer>
  )
}

export default Map
