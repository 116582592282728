import styled from 'styled-components'

export const Outer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  min-height: 50px;
  width: 100%;
`

export const RowItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  min-width: 80px;
`
