import Icon from '@atlaskit/icon/glyph/billing'
import PageHeader from '@atlaskit/page-header'
import { colors } from '@atlaskit/theme'
import React, { useState } from 'react'
import styled from 'styled-components'

import useValues from '../../../lib/useValues'
import { Page } from '../../types'

import List from './List'
import PayPeriod from './PayPeriod'
import QueryEditor from './QueryEditor'
import SortEditor from './SortEditor'
import { ReportsQuery, isValidQuery, SortKey } from './utils'

const Outer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  flex: 1;
`

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 0 8px;
  flex: 1 1 30%;
  min-width: 360px;
  max-width: 460px;
  overflow: scroll;
  flex: 1;
  padding: 0 24px;
  background-color: ${colors.skeleton};
  border-right: solid 2px ${colors.backgroundHover};
`

const Spacer = styled.div`
  flex: 1;
`

const Header = styled.div`
  border-bottom: 2px solid ${colors.backgroundHover};
`

const Contractors: Page = () => {
  const [query, { patch: patchQuery }] = useValues<Partial<ReportsQuery>>({})
  const [sortKey, setSortKey] = useState(SortKey.FAMILY_NAME)

  return (
    <Outer>
      <Sidebar>
        <Header>
          <PageHeader>{'Contractors'}</PageHeader>
        </Header>
        <QueryEditor query={query} onChange={patchQuery} />
        <SortEditor value={sortKey} onChangeValue={setSortKey} />
        <Spacer />
        <PayPeriod />
      </Sidebar>
      {isValidQuery(query) && (
        <List key={JSON.stringify(query)} query={query} sortKey={sortKey} />
      )}
    </Outer>
  )
}

Contractors.path = '/contractors'
Contractors.navTitle = 'Contractors'
Contractors.Icon = Icon
Contractors.requiredOperationIds = ['Query.contractorReportsList']

export default Contractors
