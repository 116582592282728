import Icon from '@atlaskit/icon/glyph/person'
import React from 'react'
import { useRouteMatch, Route } from 'react-router-dom'
import styled from 'styled-components'

import { ErrorEmptyState } from '../../../components/EmptyState'
import { LoadingSpinner } from '../../../components/Spinner'
import { Page } from '../../types'

import Fields from './Fields'
import Folders from './Folders'
import Sections from './Sections'
import useDossier from './useDossier'

const Outer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  min-width: 600px;
  max-width: 1000px;
  height: 100%;
`

const Dossier: Page = () => {
  const { data, loading, error } = useDossier()
  const { path } = useRouteMatch()

  if (error) {
    return <ErrorEmptyState error={error} />
  }

  if (loading || !data) {
    return <LoadingSpinner />
  }

  return (
    <Outer>
      <Route path={`${path}`} component={Folders} />
      <Route path={`${path}/:folderId/sections`} component={Sections} />
      <Route
        path={`${path}/:folderId/sections/:sectionId/fields`}
        component={Fields}
      />
    </Outer>
  )
}

Dossier.path = '/dossier/folders'
Dossier.navTitle = 'Profile'
Dossier.Icon = Icon
Dossier.requiredOperationIds = ['Query.standardDossierFolders']

export default Dossier
