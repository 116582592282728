import CompletedIcon from '@atlaskit/icon/glyph/check-circle-outline'
import NotCompletedIcon from '@atlaskit/icon/glyph/media-services/preselected'
import { colors } from '@atlaskit/theme'
import React, { useState } from 'react'

import Panel from '../../../../components/Panel'
import { LoadingSpinner } from '../../../../components/Spinner'
import { EmailComposerButton } from '../../../../components/messages/EmailMessageComposer'
import {
  GetWorkspaceOnboardingQuery,
  useSentPostOnboardingEmailMutation,
  useGetWorkspaceOnboardingChecklistQuery,
} from '../../../../graphql'

import { Steps, Step, Row, StepDetail } from './components'

const POST_ONBOARDING_EMAIL_TEMPLATE_IDS = [
  '4de50b80-f67a-4ea8-b801-e3f0be197208',
]

enum StepId {
  SEND_EMAIL = 'SEND_EMAIL',
  CHECKLIST = 'CHECKLIST',
}

interface Props {
  data: GetWorkspaceOnboardingQuery
}

const InitialStage = ({ data }: Props) => {
  const [selectedStepId, selectStepId] = useState<StepId>(StepId.SEND_EMAIL)

  const workspace = data.workspace
  const onboarding = data.workspace.onboarding

  const checklistResult = useGetWorkspaceOnboardingChecklistQuery({
    variables: {
      workspaceId: workspace.id,
    },
    skip: selectedStepId !== StepId.CHECKLIST,
  })

  const [sentEmail, { loading: loadingSentEmail }] =
    useSentPostOnboardingEmailMutation({
      variables: {
        input: {
          workspaceId: workspace.id,
        },
      },
    })

  return (
    <Panel header={'Post Onboarding'}>
      <Row>
        <Steps>
          <Step
            selected={selectedStepId === StepId.SEND_EMAIL}
            onClick={() => selectStepId(StepId.SEND_EMAIL)}
          >
            {'Send post-onboarding email'}
            {onboarding?.hasSentPostOnboardingEmail ? (
              <CompletedIcon label={''} primaryColor={colors.green()} />
            ) : (
              <NotCompletedIcon
                label={''}
                primaryColor={colors.backgroundHover()}
              />
            )}
          </Step>
          <Step
            selected={selectedStepId === StepId.CHECKLIST}
            onClick={() => selectStepId(StepId.CHECKLIST)}
          >
            {'In-app checklist'}
          </Step>
        </Steps>
        <StepDetail>
          {selectedStepId === StepId.SEND_EMAIL && (
            <EmailComposerButton
              buttonProps={{
                isLoading: loadingSentEmail,
                children:
                  'Preview & send email' +
                  (onboarding?.hasSentPostOnboardingEmail ? ' (again)' : ''),
              }}
              activityProps={{
                messageTemplateIds: POST_ONBOARDING_EMAIL_TEMPLATE_IDS,
                recipientEmails: workspace?.executives[0]?.profile.email
                  ? [workspace?.executives[0]?.profile.email]
                  : undefined,
              }}
              composerProps={{
                onSendComplete: () => sentEmail(),
                messageTemplateIds: POST_ONBOARDING_EMAIL_TEMPLATE_IDS,
                toUserId: workspace?.executives[0]?.id,
                executiveId: workspace?.executives[0]?.id,
                assistantId: workspace?.assistants[0]?.id,
                email: {
                  tags: ['post-onboarding'],
                },
              }}
            />
          )}

          {selectedStepId === StepId.CHECKLIST && (
            <ul>
              {checklistResult.loading ? (
                <LoadingSpinner />
              ) : checklistResult.data?.workspace?.onboarding?.checklist?.tasks
                  ?.length ? (
                checklistResult.data?.workspace?.onboarding?.checklist?.tasks.map(
                  ({ id, title, isCompleted }) => (
                    <li key={id}>{`${isCompleted ? '✅' : '🔲'}  ${title}`}</li>
                  ),
                )
              ) : (
                'No tasks found'
              )}
            </ul>
          )}
        </StepDetail>
      </Row>
    </Panel>
  )
}

export default InitialStage
