import { LoadingButton as Button } from '@atlaskit/button'
import InlineMessage from '@atlaskit/inline-message'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { TextField, Field, FieldsRow } from '../../../../components/form'
import {
  useGetSettingByIdQuery,
  useUpsertSettingMutation,
} from '../../../../graphql'
import useValues from '../../../../lib/useValues'
import { areEquivalentObjects } from '../../../../lib/utils'

import { Settings } from './types'
import { DEFAULT_SETTINGS } from './utils'

const SETTING_ID = 'matcher'

const Outer = styled.div``

const Buttons = styled.div`
  padding: 16px 4px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  & > * + * {
    margin-right: 8px;
  }
`

export const useSettingsValues = () => {
  const result = useValues(DEFAULT_SETTINGS)
  const { data } = useGetSettingByIdQuery({ variables: { id: SETTING_ID } })

  if (result[0] === DEFAULT_SETTINGS && data?.settingById.data) {
    result[1].patch(data?.settingById.data)
  }

  return result
}

type Props = {
  values: Settings
  onPatch: (newValues: Partial<Settings>) => void
}

const SettingsEditor = ({ values, onPatch }: Props) => {
  const { data } = useGetSettingByIdQuery({ variables: { id: SETTING_ID } })
  const [upsertSetting, { loading: loadingUpsert, error: errorUpsert }] =
    useUpsertSettingMutation()

  const settings: Settings = useMemo(
    () => ({
      ...data?.settingById.data,
      ...values,
    }),
    [data, values],
  )

  const onSave = useCallback(
    () =>
      upsertSetting({
        variables: {
          input: { settingId: SETTING_ID, data: settings },
        },
      }),
    [settings, upsertSetting],
  )

  const onReset = useCallback(
    () => onPatch(data?.settingById.data as Settings),
    [data, onPatch],
  )

  return (
    <Outer>
      <FieldsRow>
        <Field label={'Industry'}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={0}
            max={999}
            inputMode={'numeric'}
            type={'number'}
            value={settings.industryPoints || 0}
            onChangeValue={(str) => onPatch({ industryPoints: parseInt(str) })}
          />
        </Field>

        <Field label={'Tool'}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={0}
            max={999}
            inputMode={'numeric'}
            type={'number'}
            value={settings.toolPoints || 0}
            onChangeValue={(str) => onPatch({ toolPoints: parseInt(str) })}
          />
        </Field>

        <Field label={'Personality'}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={0}
            max={999}
            inputMode={'numeric'}
            type={'number'}
            value={settings.personalityPoints || 0}
            onChangeValue={(str) =>
              onPatch({ personalityPoints: parseInt(str) })
            }
          />
        </Field>
      </FieldsRow>

      <FieldsRow>
        <Field label={'Hours needed'}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={0}
            max={999}
            inputMode={'numeric'}
            type={'number'}
            value={settings.hoursNeededPoints || 0}
            onChangeValue={(str) =>
              onPatch({ hoursNeededPoints: parseInt(str) })
            }
          />
        </Field>

        <Field label={'Match/Support'}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={0}
            max={999}
            inputMode={'numeric'}
            type={'number'}
            value={settings.openToPoints || 0}
            onChangeValue={(str) => onPatch({ openToPoints: parseInt(str) })}
          />
        </Field>

        <Field label={'Applied to lead'}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={0}
            max={999}
            inputMode={'numeric'}
            type={'number'}
            value={settings.appliedToLeadPoints || 0}
            onChangeValue={(str) =>
              onPatch({ appliedToLeadPoints: parseInt(str) })
            }
          />
        </Field>
      </FieldsRow>

      <FieldsRow>
        <Field label={'Language'}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={0}
            max={999}
            inputMode={'numeric'}
            type={'number'}
            value={settings.languagePoints || 0}
            onChangeValue={(str) => onPatch({ languagePoints: parseInt(str) })}
          />
        </Field>
        <Field label={'English level'}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={0}
            max={999}
            inputMode={'numeric'}
            type={'number'}
            value={settings.englishLevelPoints || 0}
            onChangeValue={(str) =>
              onPatch({ englishLevelPoints: parseInt(str) })
            }
          />
        </Field>
      </FieldsRow>

      <FieldsRow>
        <Field label={'TZ (working in)'}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={0}
            max={999}
            inputMode={'numeric'}
            type={'number'}
            value={settings.matchedTimeZonePoints || 0}
            onChangeValue={(str) =>
              onPatch({ matchedTimeZonePoints: parseInt(str) })
            }
          />
        </Field>
        <Field label={'TZ (OK with)'}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={0}
            max={999}
            inputMode={'numeric'}
            type={'number'}
            value={settings.otherTimeZonePoints || 0}
            onChangeValue={(str) =>
              onPatch({ otherTimeZonePoints: parseInt(str) })
            }
          />
        </Field>
        <Field label={'TZ ± hours'}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={0}
            max={12}
            inputMode={'numeric'}
            type={'number'}
            value={settings.timeZoneTolerance || 0}
            onChangeValue={(str) =>
              onPatch({ timeZoneTolerance: parseInt(str) })
            }
          />
        </Field>
      </FieldsRow>

      <FieldsRow>
        <Field label={'Worked hours bonus'}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={0}
            max={999}
            inputMode={'numeric'}
            type={'number'}
            value={settings.averageWeeklyHoursPoints || 0}
            onChangeValue={(str) =>
              onPatch({ averageWeeklyHoursPoints: parseInt(str) })
            }
          />
        </Field>
        <Field label={'for weekly average <'}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={1}
            max={100}
            inputMode={'numeric'}
            type={'number'}
            value={settings.averageWeeklyHoursThreshold || 0}
            onChangeValue={(str) =>
              onPatch({ averageWeeklyHoursThreshold: parseInt(str) })
            }
          />
        </Field>
      </FieldsRow>

      <FieldsRow>
        <Field label={"Recent onb'ing malus"}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={-999}
            max={0}
            inputMode={'numeric'}
            type={'number'}
            value={settings.recentOnboardingPoints || 0}
            onChangeValue={(str) =>
              onPatch({ recentOnboardingPoints: parseInt(str) })
            }
          />
        </Field>
        <Field label={'if ≤ X weeks ago'}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={1}
            max={100}
            inputMode={'numeric'}
            type={'number'}
            value={settings.recentOnboardingWeeksThreshold || 0}
            onChangeValue={(str) =>
              onPatch({ recentOnboardingWeeksThreshold: parseInt(str) })
            }
          />
        </Field>
      </FieldsRow>

      <FieldsRow>
        <Field label={'Skillset bonus'}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={0}
            max={999}
            inputMode={'numeric'}
            type={'number'}
            value={settings.skillsetBonusPoints || 0}
            onChangeValue={(str) =>
              onPatch({ skillsetBonusPoints: parseInt(str) })
            }
          />
        </Field>
        <Field label={'for rating >='}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={1}
            max={10}
            inputMode={'numeric'}
            type={'number'}
            value={settings.skillsetBonusThreshold || 0}
            onChangeValue={(str) =>
              onPatch({ skillsetBonusThreshold: parseInt(str) })
            }
          />
        </Field>
      </FieldsRow>

      <FieldsRow>
        <Field label={'Skillset malus'}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={-999}
            max={0}
            inputMode={'numeric'}
            type={'number'}
            value={settings.skillsetMalusPoints || 0}
            onChangeValue={(str) =>
              onPatch({ skillsetMalusPoints: parseInt(str) })
            }
          />
        </Field>
        <Field label={'for rating <='}>
          <TextField
            isCompact
            placeholder={''}
            step={1}
            min={1}
            max={10}
            inputMode={'numeric'}
            type={'number'}
            value={settings.skillsetMalusThreshold || 0}
            onChangeValue={(str) =>
              onPatch({ skillsetMalusThreshold: parseInt(str) })
            }
          />
        </Field>
      </FieldsRow>

      <Buttons>
        <Button
          isDisabled={areEquivalentObjects(data?.settingById.data, settings)}
          appearance={'primary'}
          isLoading={loadingUpsert}
          onClick={onSave}
          spacing={'compact'}
        >
          {'Save'}
        </Button>
        <Button
          isDisabled={
            loadingUpsert ||
            areEquivalentObjects(data?.settingById.data, settings)
          }
          onClick={onReset}
          spacing={'compact'}
        >
          {'Reset'}
        </Button>
        {errorUpsert && (
          <InlineMessage
            title={errorUpsert.message || 'Error saving settings'}
            type={'error'}
          />
        )}
      </Buttons>
    </Outer>
  )
}

export default SettingsEditor
