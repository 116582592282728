import React from 'react'

import Badge from '../../../../components/Badge'
import { Matching_IsHighTouchFragment } from '../../../../graphql'

type Props = {
  matching: Matching_IsHighTouchFragment
  noText?: boolean
}

const HighTouchBadge = ({ matching, noText }: Props) => {
  if (!(matching.isHighTouch || matching.isHighTouchMaybe)) {
    return null
  }

  return (
    <Badge
      emoji={'👑'}
      title={'High Touch'}
      text={
        noText ? undefined : matching.isHighTouch ? 'High Touch' : 'High Touch?'
      }
      tint={matching.isHighTouch ? 'teal' : undefined}
      isLight={!matching.isHighTouch}
    />
  )
}

export default HighTouchBadge
