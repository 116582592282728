import { typography, colors } from '@atlaskit/theme'
import styled, { css } from 'styled-components'

const DEFAULT_DT_WIDTH = '40%'

export const Dl = styled.dl<{ dtWidth?: string; singleColumn?: boolean }>`
  dt,
  dd {
    box-sizing: border-box;
    padding: 8px;
  }

  dd ~ dt ${({ singleColumn }) => (singleColumn ? '' : ', dd ~ dd')} {
    border-top: 1px solid ${colors.backgroundHover};
  }

  dt {
    float: left;
    width: ${({ dtWidth, singleColumn }) =>
      singleColumn ? '100%' : dtWidth || DEFAULT_DT_WIDTH};
    padding-bottom: 0;
    ${typography.h200()};
    margin: 0;
  }

  dd {
    ${({ singleColumn, dtWidth }) =>
      singleColumn
        ? css`
            float: left;
            padding-left: 8px;
            margin-left: 0;
          `
        : css`
            margin-left: ${singleColumn ? '100%' : dtWidth || DEFAULT_DT_WIDTH};
            border-left: 1px dotted ${colors.backgroundHover};
            padding-left: 16px;
          `}

    color: ${colors.text};
    font-size: 13px;
    white-space: pre-wrap;
  }

  dd:after {
    content: '';
    display: block;
    clear: both;
  }
`

export const Dt = styled.dt``

export const Dd = styled.dd``
