import { colors } from '@atlaskit/theme'
import styled from 'styled-components'

import ListContainer from '../../../components/list/ListContainer'

export const Outer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  min-width: 600px;
  height: 100%;
`

export const Sidebar = styled(ListContainer)`
  min-width: 250px;
  max-width: 350px;
`

export const FilterOuter = styled.div`
  padding: 0 24px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  padding: 0 24px;
`

export const ListOuter = styled.div`
  box-shadow: 0 0 0 2px ${colors.backgroundHover},
    0 0 10px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  width: 450px;
  min-width: 250px;
  flex-flow: column;
  align-items: stretch;
  padding: 0 12px;
  overflow: auto;
`

export const DetailOuter = styled.div`
  flex: 1;
  min-width: 400px;
  padding: 0 24px;
`
