import PageHeader from '@atlaskit/page-header'
import { colors } from '@atlaskit/theme'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

import Tabs from '../../../../components/Tabs'
import { TopicTemplateFragment } from '../../../../graphql'
import { isNewTemplate } from '../utils'

import Configuration from './Configuration'
import Form from './Form'
import Instructions from './Instructions'

const Outer = styled.div<ComponentProps<'div'>>`
  box-shadow: 0 0 0 2px ${colors.backgroundHover},
    0 0 10px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: stretch;
  padding: 0 24px;
  overflow: auto;
  min-width: 400px;
`

const TabContent = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`

interface TemplateProps {
  template: TopicTemplateFragment
}

const Template = ({ template }: TemplateProps) => {
  const tabs = [
    {
      label: 'Configuration',
      content: (
        <TabContent>
          <Configuration template={template} />
        </TabContent>
      ),
    },
  ].filter(Boolean)

  if (!isNewTemplate(template)) {
    tabs.push({
      label: 'Instructions',
      content: (
        <TabContent>
          <Instructions template={template} />
        </TabContent>
      ),
    })
    tabs.push({
      label: 'Preferences',
      content: (
        <TabContent>
          <Form template={template} />
        </TabContent>
      ),
    })
  }

  return (
    <>
      <PageHeader>{template.title}</PageHeader>
      <Tabs isContentPersisted leftTabs={tabs} />
    </>
  )
}

type MaybeTemplateProps = Partial<TemplateProps>

const MaybeTemplate = ({ template }: MaybeTemplateProps) => (
  <Outer>
    {!!template && <Template key={template.id} template={template} />}
  </Outer>
)

export default MaybeTemplate
