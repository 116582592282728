import React, { ComponentProps, useMemo } from 'react'

import Badge, { Tint } from '../../Badge'

export interface Props extends Partial<ComponentProps<typeof Badge>> {
  score?: number
}

export enum ScoreRank {
  'HIGH' = 'HIGH',
  'MEDIUM' = 'MEDIUM',
  'LOW' = 'LOW',
}

const getScoreRank = (score?: number): ScoreRank | undefined => {
  if (!score) return undefined
  if (score < 3.5) {
    return ScoreRank.LOW
  } else if (score >= 4.5) {
    return ScoreRank.HIGH
  } else {
    return ScoreRank.MEDIUM
  }
}

const AssistantScore = ({ score, ...badgeProps }: Props) => {
  const scoreRank = useMemo(() => getScoreRank(score), [score])
  const [emoji, tint] = useMemo(() => {
    switch (scoreRank) {
      case ScoreRank.HIGH:
        return ['🥇', 'green']
      case ScoreRank.MEDIUM:
        return ['🥈', 'yellow']
      case ScoreRank.LOW:
        return ['🥉', 'red']
      case undefined:
        return ['🏅', 'none']
    }
  }, [scoreRank])

  return (
    <Badge
      emoji={emoji}
      title={`Assistant Score`}
      text={score ?? '?'}
      isLight
      tint={tint as Tint}
      {...badgeProps}
    />
  )
}

export default AssistantScore
