import { compile } from 'handlebars'
import { DateTime, DurationLikeObject } from 'luxon'
import React, { Fragment } from 'react'

import { UserPronoun } from '../graphql'

export function renderTextWithLineBreaks(text?: string | null) {
  text = (text || '').trim()

  if (!text) {
    return ''
  }

  return text.split('\n').map((line, index) => (
    <Fragment key={index}>
      {index > 0 && <br />}
      {line}
    </Fragment>
  ))
}

export function toSlug(text: string | undefined): string {
  return (text || '')
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9_]/g, '-')
    .replace(/-+/g, '-')
}

const VAR_REPLACE_REGEX = /\{\{\s*([^}]+)\s*\}\}/g

export function isRequiredVar(varName: string): boolean {
  return (
    !varName.toUpperCase().includes('OPTIONAL') &&
    !varName.toUpperCase().startsWith('HAS_') &&
    !varName.toUpperCase().startsWith('IS_')
  )
}

export function includesVar(text: string): boolean {
  return VAR_REPLACE_REGEX.test(text)
}

export function replaceVarsInString(
  vars: Record<string, string | null | undefined>,
  preReplace: string,
  highlightPlaceholders?: boolean,
): string {
  if (highlightPlaceholders) {
    preReplace = preReplace.replace(
      /(.[^"])({{{?)([^^/#][a-z0-9_]+)(}}}?)(.?)/gi,
      (match, before, openBrackets, placeholder, closeBrackets, after) =>
        before !== '](' && after !== ')'
          ? `${before}<span style="background-color: ${
              vars[placeholder] ? '#fffdd5' : '#fecccc'
            };padding:0 ${
              vars[placeholder] ? '2px' : '15px'
            };border-radius: 2px;border: 1px solid ${
              vars[placeholder] ? '#f9f34c' : '#ff8181'
            };">${openBrackets}${placeholder}${closeBrackets}</span>${after}`
          : match,
    )
  }
  return compile(preReplace, { compat: true, noEscape: false })(vars)
}

export function extractVars(text: string): string[] {
  const vars = new Set<string>()

  text.replace(VAR_REPLACE_REGEX, function (match: string, aVar: string) {
    vars.add(aVar)
    return match
  })

  return [...vars]
}

export const timeAgo = (
  date: string | Date,
  unit: keyof DurationLikeObject,
): number => Math.floor(-DateTime.fromJSDate(new Date(date)).diffNow().as(unit))

export const daysAgo = (date: string | Date): number => timeAgo(date, 'days')

export enum DateType {
  SHORT = 'short',
  LONG = 'long',
}

export function formatDate(
  date: null | undefined | string | Date,
  dateType: DateType = DateType.LONG,
): string {
  if (!date) {
    return ''
  }

  const [year, month, day] = new Date(date).toISOString().split('-')
  const localDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
  return localDate.toLocaleDateString(undefined, {
    year: dateType === DateType.SHORT ? undefined : 'numeric',
    month: dateType,
    day: 'numeric',
  })
}

const PRONOUNS = {
  [UserPronoun.THEY]: {
    THEY: 'they',
    THEM: 'them',
    THEIR: 'their',
    THEIRS: 'theirs',
  },
  [UserPronoun.SHE]: {
    THEY: 'she',
    THEM: 'her',
    THEIR: 'her',
    THEIRS: 'hers',
  },
  [UserPronoun.HE]: {
    THEY: 'he',
    THEM: 'him',
    THEIR: 'his',
    THEIRS: 'his',
  },
} as const

export function getPronoun(
  userPronoun: UserPronoun | null | undefined,
  form: keyof (typeof PRONOUNS)[UserPronoun],
): string {
  if (!userPronoun) {
    userPronoun = UserPronoun.THEY
  }

  return PRONOUNS[userPronoun][form]
}
