import { RadioSelect } from '@atlaskit/select'
import React, { ComponentProps, useMemo } from 'react'

type Props = Omit<ComponentProps<typeof RadioSelect>, 'value'> & {
  yesLabel?: string
  noLabel?: string
  value: boolean | null | undefined
  onChangeValue: (newValue: boolean | null | undefined) => void
}

const BooleanSelect = ({
  yesLabel = 'Only',
  noLabel = 'Excluded',
  value,
  onChangeValue,
  onChange,
  placeholder = 'Not filtered',
  ...props
}: Props) => {
  const yesOption = useMemo(
    () => ({
      label: yesLabel,
      value: 'yes',
    }),
    [yesLabel],
  )

  const noOption = useMemo(
    () => ({
      label: noLabel,
      value: '',
    }),
    [noLabel],
  )

  return (
    <RadioSelect
      {...props}
      placeholder={placeholder}
      onChange={(selectedOption, action) => {
        onChange?.(selectedOption, action)
        onChangeValue(selectedOption ? !!selectedOption.value : null)
      }}
      options={[yesOption, noOption]}
      value={
        value === true ? yesOption : value === false ? noOption : undefined
      }
    />
  )
}

export default BooleanSelect
