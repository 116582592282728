import AkTabs, { TabList, TabPanel, Tab } from '@atlaskit/tabs'
import React, { ReactNode } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { toSlug } from './utils'

const Spacer = styled.div`
  flex: 1;
`

const SPACER_SLUG = 'SPACER_SLUG'

type TabSpecs = {
  slug?: string
  label: string
  icon?: ReactNode
  content: ReactNode
}

type Props = {
  leftTabs?: TabSpecs[]
  rightTabs?: TabSpecs[]
  isContentPersisted?: boolean
  basePath?: string
  notUrlBased?: boolean
  afterTabs?: ReactNode
}

const Tabs = ({
  leftTabs,
  rightTabs,
  basePath,
  notUrlBased,
  isContentPersisted,
  afterTabs,
}: Props) => {
  const history = useHistory()
  let {
    params: { 0: path, tabSlug: slug },
  } = useRouteMatch<{ 0?: string; tabSlug: string }>(
    `${basePath || '*'}/:tabSlug`,
  ) || { params: {} }

  path = basePath || path

  const tabs: TabSpecs[] = [
    ...(leftTabs || []),
    { slug: SPACER_SLUG, label: 'spacer', content: <Spacer /> },
    ...(rightTabs || []),
  ]

  // Maybe the param we caught is not a tab slug...
  if (
    slug &&
    !tabs.some(({ label, slug: aSlug }) => slug === (aSlug || toSlug(label)))
  ) {
    path += '/' + slug
    slug = undefined
  }

  if (notUrlBased) {
    return (
      <AkTabs
        id={'blip bloop'}
        shouldUnmountTabPanelOnChange={!isContentPersisted}
      >
        <TabList>
          {tabs?.map(({ slug, label, icon }) =>
            slug === SPACER_SLUG ? (
              <Spacer key={slug || label} />
            ) : (
              <Tab key={slug || label}>
                {icon}
                {label}
              </Tab>
            ),
          )}
          {afterTabs}
        </TabList>
        {tabs?.map(({ slug, label, content }) => (
          <TabPanel key={slug || label}>{content}</TabPanel>
        ))}
      </AkTabs>
    )
  }

  return (
    <AkTabs
      id={'blip bloop'}
      shouldUnmountTabPanelOnChange={!isContentPersisted}
      selected={tabs.findIndex(({ label, slug: aSlug }, index) =>
        slug ? slug === (aSlug || toSlug(label)) : !index,
      )}
      onChange={(newIndex) =>
        history.replace(
          path + '/' + (tabs[newIndex].slug || toSlug(tabs[newIndex].label)),
        )
      }
    >
      <TabList>
        {tabs?.map(({ slug, label, icon }) =>
          slug === SPACER_SLUG ? (
            <Spacer key={slug || label} />
          ) : (
            <Tab key={slug || label}>
              {icon}
              {label}
            </Tab>
          ),
        )}
        {afterTabs}
      </TabList>
      {tabs?.map(({ slug, label, content }) => (
        <TabPanel key={slug || label}>{content}</TabPanel>
      ))}
    </AkTabs>
  )
}

export default Tabs
