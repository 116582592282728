import PremiumIcon from '@atlaskit/icon/glyph/premium'
import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { Page } from '../../types'

import ExecCopilot from './ExecCopilot'
import ExportChat from './ExportChat'
import List from './List'
import {
  ASSISTANT_COPILOT_CATEGORY,
  ASSISTANT_COPILOT_EVERYDAY_TASK,
  ASSISTANT_EVERYDAY_TASKS_SYSTEM_PROMPT,
  ASSISTANT_EXEC_TASKS_SYSTEM_PROMPT,
  EXEC_COPILOT,
  EXPORT_CHAT,
} from './ROUTES'
import AssistantEverydayTask from './assistant/AssistantEverydayTask'
import AssistantExecTask from './assistant/AssistantExecTask'
import CategoryPromptEditor from './assistant/subpromptEditors/CategoryPromptEditor'
import TaskPromptEditor from './assistant/subpromptEditors/TaskPromptEditor'
import { Outer } from './styled'

const Copilot: Page = () => {
  const { url } = useRouteMatch()

  return (
    <Outer>
      <Route path={`${url}`} component={List} />
      <Route path={`${url}${EXPORT_CHAT}/:topicId?`} component={ExportChat} />
      <Route path={`${url}${EXEC_COPILOT}`} component={ExecCopilot} />
      <Route
        path={`${url}${ASSISTANT_EVERYDAY_TASKS_SYSTEM_PROMPT}`}
        component={AssistantEverydayTask}
      />
      <Route
        path={`${url}${ASSISTANT_EXEC_TASKS_SYSTEM_PROMPT}`}
        component={AssistantExecTask}
      />
      <Route
        path={`${url}${ASSISTANT_COPILOT_EVERYDAY_TASK}`}
        component={TaskPromptEditor}
      />
      <Route
        path={`${url}${ASSISTANT_COPILOT_CATEGORY}`}
        component={CategoryPromptEditor}
      />
    </Outer>
  )
}

Copilot.path = '/copilot'
Copilot.navTitle = 'Copilot'
Copilot.Icon = PremiumIcon
Copilot.requiredOperationIds = ['Query.usersList']

export default Copilot
