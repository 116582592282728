import React from 'react'
import styled from 'styled-components'

import { Page } from '../../types'

import EntryList from './EntryList'
import Header from './Header'
import { useTimeEntriesFilters } from './Header/FilterInputs'
import getTimeTrackingEntriesListQueryVariables from './getTimeTrackingEntriesListQueryVariables'
import { ReactComponent as TimerIcon } from './icons/timer.svg'

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  height: 100%;
`

const TimeEntries: Page = () => {
  const [filter, { setters }] = useTimeEntriesFilters()

  return (
    <Outer>
      <Header filter={filter} setters={setters} />
      <EntryList {...getTimeTrackingEntriesListQueryVariables(filter)} />
    </Outer>
  )
}

TimeEntries.path = '/finances/time-entries'
TimeEntries.navTitle = 'Time Entries'
// 💩
TimeEntries.Icon = TimerIcon as unknown as typeof React.PureComponent
TimeEntries.requiredOperationIds = ['Query.timeTrackingEntriesList']

export default TimeEntries
