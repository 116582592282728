import Select, { CreatableSelect } from '@atlaskit/select'
import uniq from 'lodash/uniq'
import React, { ComponentProps } from 'react'

import { useGetUserFieldsOptionsQuery } from '../graphql'

type Props = ComponentProps<typeof Select> & {
  isCreatable?: boolean
  values?: string[] | null
  onChangeValues: (newValues: string[]) => void
}

const ToolsSelect = ({
  values,
  onChangeValues,
  isCreatable,
  ...props
}: Props) => {
  const { data } = useGetUserFieldsOptionsQuery()

  const S = isCreatable ? CreatableSelect : Select

  return (
    <S
      value={values?.map((value) => ({ value }))}
      isClearable
      isMulti
      options={uniq(
        [...(values || []), ...(data?.userFieldsOptions.tools || [])].filter(
          (e: any): e is string => !!e,
        ),
      ).map((value) => ({ value }))}
      // @ts-ignore
      getOptionLabel={({ value }) => value || ''}
      // @ts-ignore
      getOptionValue={({ value }) => value}
      onCreateOption={(topic: string) =>
        onChangeValues(uniq([...(values || []), topic]))
      }
      onChange={(values) =>
        // @ts-ignore
        onChangeValues((values || []).map(({ value }) => value))
      }
      {...props}
    />
  )
}
export default ToolsSelect
