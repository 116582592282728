import Toggle from '@atlaskit/toggle'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  * {
    box-sizing: content-box;
  }

  label div div {
    position: static;
  }
`

type Props = ComponentProps<typeof Toggle>

// @ts-ignore
const _Toggle = (props: Props) => (
  <Outer>
    <Toggle {...props} />
  </Outer>
)

export const ToggleOuter = styled.label`
  display: flex;
  align-items: center;
`

export { _Toggle as default, _Toggle as ToggleStateless }
