import EmptyStarIcon from '@atlaskit/icon/glyph/star'
import FilledStarIcon from '@atlaskit/icon/glyph/star-filled'
import { colors } from '@atlaskit/theme'
import { transparentize } from 'polished'

const Star = ({ value, rating }: { value: number; rating: number }) => {
  const Icon = value > rating ? EmptyStarIcon : FilledStarIcon

  let iconColor =
    rating >= 5
      ? colors.green()
      : rating >= 4
      ? colors.purple()
      : rating >= 3
      ? colors.yellow()
      : colors.red()

  if (value > rating) {
    iconColor = transparentize(0.8, iconColor)
  }

  return (
    <Icon
      label={''}
      primaryColor={iconColor}
      size={value === rating ? 'medium' : 'small'}
    />
  )
}

export default Star
