import isUndefined from 'lodash/isUndefined'
import { DateTime } from 'luxon'

import { Hour, Meridiem } from './types'

const toDateTimeHour = (hour: Hour, meridiem: Meridiem) => {
  switch (meridiem) {
    case Meridiem.AM:
      if (hour === 12) {
        return 0
      } else return hour
    case Meridiem.PM:
      if (hour < 12) {
        return hour + 12
      } else return hour
  }
}

export const getHour = (dateTime: DateTime): Hour => {
  const hour = dateTime.hour
  if (hour === 0) {
    return 12
  } else if (hour === 12) {
    return 12
  } else {
    return (hour % 12) as Hour
  }
}

export const getMeridiem = (dateTime: DateTime) => {
  if (dateTime.hour < 12) {
    return Meridiem.AM
  } else {
    return Meridiem.PM
  }
}

export const getNewValue = (
  value: DateTime,
  {
    hour,
    minute,
    meridiem,
  }: {
    hour?: Hour
    minute?: number
    meridiem?: Meridiem
  },
) => {
  const newMeridiem = meridiem || getMeridiem(value)
  const newHour = toDateTimeHour(
    isUndefined(hour) ? getHour(value) : hour,
    newMeridiem,
  )
  const newMinute = isUndefined(minute) ? value.minute : minute

  return value.set({
    hour: newHour,
    minute: newMinute,
  })
}
