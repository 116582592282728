import PeopleGroupIcon from '@atlaskit/icon/glyph/people-group'
import React from 'react'
import { Route, useRouteMatch, Switch } from 'react-router-dom'

import Workspace from '../Users/Workspace'
import { Page } from '../types'

import List from './List'
import NavigationSidebar from './NavigationSidebar'
import Team from './Team'
import { Outer } from './styled'

const Teams: Page = () => {
  const { url } = useRouteMatch()

  return (
    <Outer>
      <Route path={`${url}`} component={List} />
      <Route path={`${url}/:teamId`} component={NavigationSidebar} />
      <Switch>
        <Route
          path={`${url}/:teamId/workspaces/:workspaceId`}
          component={Workspace}
        />
        <Route path={`${url}/:teamId`} component={Team} />
      </Switch>
    </Outer>
  )
}

Teams.path = '/teams'
Teams.navTitle = 'Teams'
Teams.Icon = PeopleGroupIcon
Teams.requiredOperationIds = ['Query.teamsList']

export default Teams
