import { Rating } from '../../../../../components/Rating'
import UserCell from '../../../../../components/users/Cell'
import {
  CsatFeedbackFragment,
  Userable_MinimalFragment,
} from '../../../../../graphql'
import { NavLink } from '../../../NavigationSidebar/styled'
import useActiveUserId from '../../useActiveUserId'

import { Outer } from './sharedStyles'

type Props = {
  counterpartUser: Userable_MinimalFragment
  feedback: CsatFeedbackFragment
  workspaceId: string
}

const WorkspaceUserRow = ({
  counterpartUser,
  feedback,
  workspaceId,
}: Props) => {
  const activeUserId = useActiveUserId()

  if (!activeUserId) {
    return null
  }
  return (
    <Outer>
      <NavLink
        key={activeUserId}
        to={`/users/${activeUserId}/workspaces/${workspaceId}/csat`}
      >
        <UserCell user={counterpartUser} />
      </NavLink>
      <Rating rating={feedback.rating || 0} />
    </Outer>
  )
}

export default WorkspaceUserRow
