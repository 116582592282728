import { Spinner } from '@blueprintjs/core'
import React from 'react'

import { useAssistantCopilotQuery } from '../../../../graphql'
import PromptEditor from '../PromptEditor'

import { CopilotPromptSettingsOverrideType } from './useUpdateAssistantCopilot'

const AssistantExecTask = () => {
  const { data, loading } = useAssistantCopilotQuery()

  const initialPrompt = data?.assistantCopilot.execTasksCopilotPromptSettings

  if (loading || !initialPrompt) return <Spinner />

  return (
    <PromptEditor
      promptId={'execTasksSystemPrompt'}
      prompt={initialPrompt}
      title={`🖥️ Dashboard tasks`}
      overridenPromptType={CopilotPromptSettingsOverrideType.EXEC_TASKS}
      helperMessage={'Used as prompt for the dashboard tasks.'}
    />
  )
}

export default AssistantExecTask
