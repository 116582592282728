import { useMemo } from 'react'

import {
  CategoryCopilotPromptSettingsFragment,
  TimeTrackingCategoryId,
  TimeTrackingEntryTemplateId,
  useAssistantCopilotQuery,
  TaskCopilotPromptSettingsFragment,
} from '../../../../graphql'

const useSubprompt = (
  id?: TimeTrackingEntryTemplateId | TimeTrackingCategoryId,
): {
  subprompt:
    | CategoryCopilotPromptSettingsFragment
    | TaskCopilotPromptSettingsFragment
    | null
  loading: boolean
} => {
  const { data, loading } = useAssistantCopilotQuery()

  const subprompt = useMemo(() => {
    if (!data) return null

    const graphQLPrompt =
      [
        ...(data.assistantCopilot?.categoryPrompts ?? []),
        ...(data.assistantCopilot?.taskPrompts ?? []),
      ].find((prompt) => prompt.id === id) ?? null

    if (!graphQLPrompt) return null

    return graphQLPrompt
  }, [data, id])

  return { subprompt, loading }
}

export default useSubprompt
