import Avatar, { AvatarItem } from '@atlaskit/avatar'
import { CodeBlock } from '@atlaskit/code'
import { colors } from '@atlaskit/theme'
import { DateTime } from 'luxon'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

import { Field } from '../../../../components/form'
import { BugReportFragment, BugReportFileFragment } from '../../../../graphql'

const isImageFile = ({ mime }: BugReportFileFragment): boolean =>
  Boolean(mime?.startsWith('image/'))

const Outer = styled.div<ComponentProps<'div'>>`
  box-shadow: 0 0 0 2px ${colors.backgroundHover},
    0 0 10px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: stretch;
  padding: 0 24px;
  overflow: auto;
  min-width: 400px;
`

const Content = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`

const Message = styled.p``

const Image = styled.img`
  max-width: 100px;
  max-height: 100px;
`

const Files = styled.ul``

const FileLink = styled.a`
  text-decoration: underline;

  & + & {
    margin-left: 16px;
  }
`

const When = styled.p``

interface BugReportProps {
  bugReport: BugReportFragment
}

const BugReport = ({ bugReport }: BugReportProps) => {
  const images = bugReport.files.filter((file) => isImageFile(file))
  const files = bugReport.files.filter((file) => !isImageFile(file))

  return (
    <>
      <Content>
        {!!bugReport.user && (
          <AvatarItem
            avatar={
              <Avatar src={bugReport.user.profile.avatarUrl || undefined} />
            }
            primaryText={bugReport.user.profile.displayName}
            href={`/users/${bugReport.user.id}`}
          />
        )}
        <Field label={'Message'}>
          <Message>{bugReport.message || 'none'}</Message>
        </Field>
        <Field label={'When'}>
          <When>
            {DateTime.fromJSDate(new Date(bugReport.createdAt))
              // @ts-ignore
              .toLocaleString(DateTime.DATETIME_MED)}
          </When>
        </Field>
        {images.length > 0 && (
          <Field label={'Images'}>
            <Files>
              {images.map(({ id, downloadUrl, name }) => (
                <FileLink key={id} href={downloadUrl} target={'_blank'}>
                  <Image src={downloadUrl} alt={name} />
                </FileLink>
              ))}
            </Files>
          </Field>
        )}
        {files.length > 0 && (
          <Field label={'Other Files'}>
            <Files>
              {files.map(({ id, downloadUrl, name }) => (
                <li key={id}>
                  <FileLink href={downloadUrl} target={'_blank'}>
                    {name}
                  </FileLink>
                </li>
              ))}
            </Files>
          </Field>
        )}
        <Field label={'Properties'}>
          <CodeBlock
            showLineNumbers={false}
            language={'json'}
            text={JSON.stringify(bugReport.properties, null, 2)}
          />
        </Field>
        <Field label={'Metadata'}>
          <CodeBlock
            showLineNumbers={false}
            language={'json'}
            text={JSON.stringify(bugReport.metadata, null, 2)}
          />
        </Field>
      </Content>
    </>
  )
}

type MaybeBugReportProps = Partial<BugReportProps>

const MaybeBugReport = ({ bugReport }: MaybeBugReportProps) => (
  <Outer>
    {!!bugReport && <BugReport key={bugReport.id} bugReport={bugReport} />}
  </Outer>
)

export default MaybeBugReport
