import React, { ComponentProps, useEffect, ComponentType } from 'react'
import { useHistory } from 'react-router'
import AutoSizer from 'react-virtualized-auto-sizer'
import InfiniteLoader from 'react-window-infinite-loader'

import { withListPagination, useListTeamsQuery } from '../../../graphql'
import { ErrorEmptyState } from '../../EmptyState'
import { LoadingSpinner } from '../../Spinner'
import { Skeleton, TeamCellProps } from '../Cell'

import Footer, { HEIGHT as FOOTER_HEIGHT } from './Footer'
import { FixedSizeList } from './styled'

interface Props extends ComponentProps<'div'> {
  search?: string
  isArchived?: boolean | null
  Cell: ComponentType<TeamCellProps>
  autoNavigateToSingleResult?: boolean
  showFooter?: boolean
}

const PAGE_SIZE = 50

const TeamsList = ({
  search,
  isArchived,
  Cell,
  className,
  autoNavigateToSingleResult,
  showFooter,
}: Props) => {
  const navHistory = useHistory()
  const { data, loading, error, fetchMore } = withListPagination(
    useListTeamsQuery({
      variables: {
        first: PAGE_SIZE,
        q: search,
        isArchived,
      },
    }),
  )

  const teams = loading || !data ? [] : data.list?.items || []
  const after = loading || !data ? '' : data.list?.after
  const itemCount = loading
    ? PAGE_SIZE
    : after
    ? teams.length + 1
    : teams.length
  const isItemLoaded = (index: number) => !after || index < teams.length

  const singleTeam = teams?.length === 1 ? teams[0] : undefined

  useEffect(() => {
    // auto navigate to result if search returns only one team
    if (autoNavigateToSingleResult && search && singleTeam) {
      navHistory.push(`/teams/${singleTeam.id}`)
    }
  }, [autoNavigateToSingleResult, navHistory, search, singleTeam])

  if (error) {
    return <ErrorEmptyState error={error} />
  }

  return (
    <>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={fetchMore}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                itemCount={itemCount}
                itemSize={56}
                onItemsRendered={onItemsRendered}
                height={height - (showFooter ? FOOTER_HEIGHT : 0)}
                width={width}
                ref={ref}
                className={className}
              >
                {({ index, style }) =>
                  loading ? (
                    <Skeleton />
                  ) : isItemLoaded(index) ? (
                    <Cell team={teams[index]} style={style} />
                  ) : (
                    <LoadingSpinner size={'xsmall'} />
                  )
                }
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
      {!!showFooter && (
        <Footer isLoading={loading} hasMore={!!after} itemCount={itemCount} />
      )}
    </>
  )
}

export default TeamsList
