import { colors, typography, borderRadius } from '@atlaskit/theme'
import styled, { css } from 'styled-components'

const columnWidth = 30

export const Outer = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  height: 100%;
  min-width: 600px;
  max-width: 1000px;
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
  padding: 0 24px 32px;
`

export const Row = styled.div<{ withHover?: boolean }>`
  display: flex;
  align-items: center;

  ${({ withHover }) =>
    withHover &&
    css`
      border-radius: ${borderRadius}px;
      &:hover {
        background-color: ${colors.backgroundHover};
      }
    `}
`

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SquareCell = styled(Cell)`
  flex: 0 0 ${columnWidth}px;
  height: 50px;
`

export const AvatarCell = styled(Cell)`
  flex: 0 0 300px;
  padding-left: 8px;
`

export const HeaderRow = styled(Row)`
  position: sticky;
  top: 0;
  background-color: #ffffffc6;
  z-index: 4;
  padding: 45px 0 8px;
`

export const HeaderTitleCell = styled(AvatarCell)`
  ${typography.h600}
  margin: 0;
  justify-content: flex-start;
  background-color: white;
`

export const HeaderLabelCell = styled(SquareCell)`
  ${typography.h100}
  margin: 0;
  overflow: visible;
  transform: rotate(-59deg) translate(1px, 6px);
  transform-origin: center;
  white-space: pre;
  width: ${columnWidth}px;
  justify-content: flex-start;
`

export const HeaderActionsCell = styled(Cell)`
  flex-grow: 1;
`

export const EmptyState = styled(Cell)`
  ${typography.h400}
  margin: 0;
  flex: 0 0 100px;
  justify-content: flex-start;
  padding-left: 50px;
`
