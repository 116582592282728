import { LoadingButton } from '@atlaskit/button'
import styled from 'styled-components'

import CollapsibleText from '../../../../../../components/CollapsibleText'
import { DebouncedTextArea } from '../../../../../../components/form'

export const ApplicationMotivation = styled(CollapsibleText)``

export const ReplyComment = styled(CollapsibleText)``

export const TextArea = styled(DebouncedTextArea)`
  font-size: smaller;
`

export const SendButton = styled(LoadingButton)`
  margin-top: 16px;
`
