import AkEmptyState from '@atlaskit/empty-state'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

const ERROR_EMOJIS = ['💥', '🙈', '🙀', '🤷', '🤦', '😭', '🦦', '💁']

const Emoji = styled.span`
  font-size: 100px;
`

type Props = ComponentProps<typeof AkEmptyState> & {
  emoji?: string
}

const EmptyState = ({ emoji, ...props }: Props) => (
  <AkEmptyState
    renderImage={emoji ? () => <Emoji>{emoji}</Emoji> : undefined}
    {...props}
  />
)

export default EmptyState

type ErrorEmptyStateProps = Partial<Props> & {
  error?: Error
}

export const ErrorEmptyState = ({ error, ...props }: ErrorEmptyStateProps) => (
  <EmptyState
    emoji={
      ERROR_EMOJIS[
        Math.min(
          Math.floor(Math.random() * ERROR_EMOJIS.length),
          ERROR_EMOJIS.length - 1,
        )
      ]
    }
    header={'An error occured'}
    description={error?.message}
    {...props}
  />
)

type NotFoundEmptyStateProps = Partial<Props>

export const NotFoundEmptyState = ({ ...props }: NotFoundEmptyStateProps) => (
  <EmptyState emoji={'👻'} header={'Not found'} {...props} />
)
