import React from 'react'

import { Field } from '../../../../../components/form'

import { TextArea } from './styled'

interface Props {
  label?: string
  value: string
  onChangeValue: (value: string) => void
  defaultValue: string
  isDisabled: boolean
  children?: React.ReactNode
}

const PromptEditor = ({
  label,
  value,
  onChangeValue,
  defaultValue,
  isDisabled,
  children,
}: Props) => {
  return (
    <Field label={label} key={label}>
      <TextArea
        value={value}
        onChangeValue={onChangeValue}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        minimumRows={6}
      />
      {children}
    </Field>
  )
}

export default PromptEditor
