import {
  useGetUserProfileQuery,
  Userable_MinimalFragment,
  Userable_MinimalFragmentDoc,
  namedOperations,
  possibleTypes,
} from '.'
import { useApolloClient } from '@apollo/client'

const useMinimalUser = (
  userId: string | null | undefined,
): Userable_MinimalFragment | null => {
  const client = useApolloClient()

  const cached =
    userId &&
    possibleTypes.Userable.reduce<Userable_MinimalFragment | null>(
      (cached, typename) => {
        return (
          cached ||
          client.readFragment<Userable_MinimalFragment>({
            id: `${typename}:${userId}`,
            fragmentName: namedOperations.Fragment.Userable_Minimal,
            fragment: Userable_MinimalFragmentDoc,
          })
        )
      },
      null,
    )

  const { data } = useGetUserProfileQuery({
    variables: { id: userId || '' },
    skip: Boolean(!userId || cached),
  })

  return cached || data?.user || null
}

export default useMinimalUser
