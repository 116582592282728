import get from 'lodash/get'

function valuesToLine(values: (string | null | undefined)[]): string {
  return (
    values
      .map((value) =>
        typeof value === 'string'
          ? value.includes(',') ||
            value.includes('"') ||
            value.includes('\n') ||
            value.includes('\r')
            ? `"${value.replace(/"/g, '""')}"`
            : value
          : '',
      )
      .join(',') + '\n'
  )
}

export interface CsvColumn<T> {
  header: string
  getter: string | ((record: T) => string | null | undefined)
}

type Result<T> = {
  headersLine: string
  toLine: (record: T) => string
}

export function csvFormater<T>(_columns: CsvColumn<T>[]): Result<T> {
  const columns = _columns
    // Convert getter "path" to getter function
    .map(({ getter, ...column }) => ({
      ...column,
      getter:
        typeof getter === 'string'
          ? (record: T) => get(record, getter)
          : getter,
    }))

  const toValues = (record: T): string[] =>
    columns.map(({ getter }) => getter(record))

  return {
    headersLine: valuesToLine(columns.map(({ header }) => header)),
    toLine: (record: T): string => valuesToLine(toValues(record)),
  }
}
