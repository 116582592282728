import React, { useMemo } from 'react'

import { AddUserAccountInput } from '../../graphql'
import useValues from '../../lib/useValues'
import Modal from '../Modal'
import { Field, TextField, FieldsRow } from '../form'

type Props = {
  show?: boolean
  onHide?: () => void
  onSubmit: (values: AddUserAccountInput) => void
}

const AddAccountModal = ({ show, onHide, onSubmit }: Props) => {
  const [formValues, { reset, setters }] = useValues<AddUserAccountInput>(
    {
      givenName: '',
      familyName: '',
      email: '',
    },
    ['givenName', 'familyName', 'email'],
  )
  const isValidForm = useMemo(() => {
    if (
      !(
        formValues.givenName?.trim() &&
        formValues.familyName?.trim() &&
        formValues.email?.trim()
      )
    ) {
      return false
    }

    return true
  }, [formValues.email, formValues.familyName, formValues.givenName])

  return (
    <Modal
      isOpen={show}
      onClose={() => onHide?.()}
      onCloseComplete={reset}
      width={'small'}
      heading={'Add account to user'}
      autoFocus
      actions={[
        {
          text: 'Create',
          onClick: () => onSubmit(formValues),
          isDisabled: !isValidForm,
        },
        { text: 'Cancel', onClick: onHide },
      ]}
    >
      <Field label={'Name'} isRequired>
        <FieldsRow>
          <TextField
            placeholder={'Given name'}
            value={formValues.givenName}
            onChangeValue={setters.givenName}
            isInvalid={!formValues.givenName?.trim()}
          />
          <TextField
            placeholder={'Family name'}
            value={formValues.familyName || ''}
            onChangeValue={setters.familyName}
            isInvalid={!formValues.familyName?.trim()}
          />
        </FieldsRow>
      </Field>

      <Field label={'Email'} isRequired>
        <TextField
          type={'email'}
          name={'email'}
          placeholder={'example@company.com'}
          value={formValues.email}
          onChangeValue={setters.email}
          isInvalid={!formValues.email?.trim()}
        />
      </Field>
    </Modal>
  )
}

export default AddAccountModal
