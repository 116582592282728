import AppVersionsIcon from '@atlaskit/icon/glyph/app-switcher'
import TargetsIcon from '@atlaskit/icon/glyph/person'
import { colors } from '@atlaskit/theme'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import PageHeader from '../../../components/PageHeader'
import CreateFeatureModal from '../../../components/features/CreateModal'
import ItemWithNavlink from '../../../components/list/ItemWithNavlink'
import ListContainer from '../../../components/list/ListContainer'
import ListOuter from '../../../components/list/ListOuter'
import PageHeaderOuter from '../../../components/list/PageHeaderOuter'
import {
  FeatureFragment,
  useUpsertFeatureMutation,
  UpsertFeatureInput,
} from '../../../graphql'
import useSwitch from '../../../lib/useSwitch'

import {
  featureIsEnabledForEveryone,
  featureIsDisabledForEveryone,
  featureIsEnabledForAllAppVersions,
} from './utils'

const Outer = styled(ListContainer)`
  min-width: 280px;
  max-width: 400px;
`

const Icons = styled.div`
  display: flex;
`

const targetsIconColor = (feature: FeatureFragment) =>
  featureIsEnabledForEveryone(feature)
    ? colors.green()
    : featureIsDisabledForEveryone(feature)
    ? colors.red()
    : colors.yellow()

const appVersionsIconColor = (feature: FeatureFragment) =>
  featureIsEnabledForAllAppVersions(feature) ? colors.green() : colors.yellow()

interface Props {
  features: FeatureFragment[]
  selectedFeatureId?: FeatureFragment['id']
}

const FeaturesList = ({ features }: Props) => {
  const history = useHistory()
  const [isModalOpen, openModal, closeModal] = useSwitch()
  const [searchTerm, setSearchTerm] = useState<string | undefined | null>()

  const [createFeature] = useUpsertFeatureMutation({
    refetchQueries: ['ListFeatures'],
    onCompleted({
      upsertFeature: {
        feature: { id },
      },
    }) {
      history.push(`/features/${id}`)
    },
  })

  const onSubmit = useCallback(
    (input: UpsertFeatureInput) => {
      closeModal()
      createFeature({
        variables: { input },
      })
    },
    [closeModal, createFeature],
  )

  return (
    <Outer>
      <PageHeaderOuter>
        <PageHeader
          createActions={[
            {
              label: 'Feature',
              onClick: openModal,
            },
          ]}
          searchTerm={searchTerm}
          onChangeSearchTerm={setSearchTerm}
        >
          {'Features'}
        </PageHeader>
      </PageHeaderOuter>
      <ListOuter>
        {features
          .filter(
            ({ id, description }) =>
              !searchTerm ||
              (id + description).toLowerCase().includes(searchTerm),
          )
          .map((feature) => (
            <ItemWithNavlink
              key={feature.id}
              href={`/features/${feature.id}`}
              isSelected={false}
              text={feature.description}
              subText={feature.id}
              after={() => (
                <Icons>
                  <TargetsIcon
                    size={'small'}
                    label={''}
                    primaryColor={targetsIconColor(feature)}
                  />
                  <AppVersionsIcon
                    size={'small'}
                    label={''}
                    primaryColor={appVersionsIconColor(feature)}
                  />
                </Icons>
              )}
            />
          ))}
      </ListOuter>
      <CreateFeatureModal
        title={'Create new feature'}
        show={isModalOpen}
        onHide={closeModal}
        onSubmit={onSubmit}
      />
    </Outer>
  )
}

export default FeaturesList
