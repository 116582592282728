import React from 'react'

import { Select } from '../../../../components/form'
import { PricingModel } from '../../../../graphql'

const MODEL_LABEL: { [key in PricingModel]: string } = {
  [PricingModel.HOURLY]: 'Hourly',
  [PricingModel.ORIGINAL]: 'Original',
}

const OPTIONS = [
  {
    value: PricingModel.HOURLY,
    label: MODEL_LABEL[PricingModel.HOURLY],
  },
  {
    value: PricingModel.ORIGINAL,
    label: MODEL_LABEL[PricingModel.ORIGINAL],
  },
]

const ModelSelect = ({
  value,
  ...props
}: Omit<React.ComponentProps<typeof Select>, 'options'>) => (
  <Select<PricingModel>
    value={value ? OPTIONS.find(({ value: cat }) => value === cat) : undefined}
    isSearchable={false}
    isClearable={false}
    options={OPTIONS}
    isMulti={false}
    {...props}
  />
)

export default ModelSelect
