import Button from '@atlaskit/button'
import AddIcon from '@atlaskit/icon/glyph/add'
import DragIcon from '@atlaskit/icon/glyph/drag-handler'
import PageHeader from '@atlaskit/page-header'
import { ButtonItem } from '@atlaskit/side-navigation'
import React, { useCallback } from 'react'
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from 'react-sortable-hoc'
import styled from 'styled-components'

import { ErrorEmptyState } from '../../../components/EmptyState'
import { LoadingSpinner } from '../../../components/Spinner'
import _ListOuter from '../../../components/list/ListOuter'
import PageHeaderOuter from '../../../components/list/PageHeaderOuter'
import {
  Skill,
  useSaveSkillset,
  useSkillset,
} from '../../../components/skillset/utils'
import useSwitch from '../../../lib/useSwitch'

import CreateModal from './CreateModal'

const Outer = styled.div`
  background-color: #fafbfc;
  display: flex;
  flex-direction: column;
  padding: 0;
  flex: 1 1 30%;
  min-width: 280px;
  max-width: 400px;
`

const ListOuter = SortableContainer(_ListOuter)

const _DragHandle = styled.div`
  cursor: grab;

  [draggable='true'] & {
    cursor: grabbing;
  }
`
const DragHandle = SortableHandle(_DragHandle)

const Item = SortableElement(styled(ButtonItem)`
  cursor: default;

  ${_DragHandle} {
    opacity: 0;
  }

  &:hover ${_DragHandle} {
    opacity: 1;
  }
`)

const List = () => {
  const [modalIsOpened, openModal, closeModal] = useSwitch()
  const [saveSkillset, { loading: isSaving }] = useSaveSkillset()
  const { skillset, loading, error } = useSkillset()

  const addSkill = useCallback(
    async (skill: Skill) => {
      if (skillset) {
        await saveSkillset({
          ...skillset,
          skills: [...skillset.skills, skill],
        })
      }
      closeModal()
    },
    [saveSkillset, skillset, closeModal],
  )

  const sortSkills: SortEndHandler = useCallback(
    ({ oldIndex, newIndex }) => {
      // Do not save if order did not change
      if (!skillset || oldIndex === newIndex) {
        return
      }

      const skills = [...skillset.skills]
      const skill = skills.splice(oldIndex, 1)[0]
      skills.splice(newIndex, 0, skill)

      saveSkillset({
        ...skillset,
        skills,
      })
    },
    [saveSkillset, skillset],
  )

  return (
    <Outer>
      <PageHeaderOuter>
        <PageHeader
          actions={
            <Button
              iconBefore={<AddIcon label={'Create Template'} />}
              appearance={'default'}
              onClick={openModal}
            />
          }
        >
          {'Skillset'}
        </PageHeader>
      </PageHeaderOuter>
      <ListOuter onSortEnd={sortSkills} useDragHandle lockAxis={'y'}>
        {error ? (
          <ErrorEmptyState error={error} />
        ) : !skillset?.skills.length ? null : (
          skillset.skills.map((skill, index) => (
            <Item
              key={skill.id}
              index={index}
              iconAfter={
                <DragHandle>
                  <DragIcon label={''} />
                </DragHandle>
              }
            >
              {skill.label}
            </Item>
          ))
        )}
        {!!loading && <LoadingSpinner />}
      </ListOuter>
      <CreateModal
        show={modalIsOpened}
        onSubmit={addSkill}
        isSubmitting={isSaving}
        onHide={closeModal}
      />
    </Outer>
  )
}

export default List
