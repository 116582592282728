import { colors } from '@atlaskit/theme'
import { DateTime } from 'luxon'
import React, { forwardRef, ComponentProps } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  padding: 3px;

  border-radius: 3px;

  :hover {
    background-color: ${colors.backgroundHover};
  }
`

type Props = ComponentProps<'div'> & {
  startedAtDateTime: DateTime
  endedAtDateTime?: DateTime
}

const TimeInfosButton = forwardRef(
  ({ startedAtDateTime, endedAtDateTime, ...divProps }: Props, ref) => {
    return (
      <Outer
        // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
        ref={ref as any}
        title={[
          startedAtDateTime.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS),
          endedAtDateTime?.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS),
        ]
          .filter(Boolean)
          .join('\n')}
        {...divProps}
      >
        <span>
          {`${startedAtDateTime.toLocaleString(DateTime.TIME_SIMPLE)}${
            endedAtDateTime
              ? ` - ${endedAtDateTime.toLocaleString(DateTime.TIME_SIMPLE)}`
              : ''
          }`}
        </span>
        <span>{startedAtDateTime.toLocaleString()}</span>
      </Outer>
    )
  },
)

export default TimeInfosButton
