import React, { useCallback } from 'react'
import styled from 'styled-components'

import { LoadingSpinner } from '../../../../components/Spinner'
import {
  FeatureFragment,
  useUpsertFeatureMutation,
  FeatureTargetInput,
  FeatureTarget,
} from '../../../../graphql'
import { deepCleanTypename } from '../../../../lib/utils'

import Target from './Target'

const Outer = styled.div`
  padding: 24px 0;
`

interface TargetsProps {
  feature: FeatureFragment
}

const Targets = ({ feature }: TargetsProps) => {
  const [upsertFeature, { loading }] = useUpsertFeatureMutation()

  const saveFeatureTarget = useCallback(
    (updatedTarget: FeatureTarget | null, updatedIndex: number) => {
      // Copy current targets
      const targets: FeatureTarget[] = [...feature.targets]
      if (updatedTarget) {
        // Insert updated target
        targets[updatedIndex] = updatedTarget
      } else if (window.confirm('Delete this feature flag target?')) {
        // Remove deleted target
        targets.splice(updatedIndex, 1)
      } else {
        return
      }

      // Clean inputs (remove __typename)
      const targetInputs = deepCleanTypename<FeatureTargetInput[]>(targets)

      upsertFeature({
        variables: {
          input: {
            featureId: feature.id,
            targets: targetInputs,
          },
        },
      })
    },
    [feature, upsertFeature],
  )

  return (
    <Outer>
      {feature.targets.map((target, index) => (
        <Target
          key={feature.id + index}
          target={target}
          onChange={(target) => saveFeatureTarget(target, index)}
          onDelete={index === 0 ? null : () => saveFeatureTarget(null, index)}
        />
      ))}
      <Target
        key={feature.targets.length}
        target={null}
        onChange={(target) => saveFeatureTarget(target, feature.targets.length)}
      />
      <LoadingSpinner show={loading} />
    </Outer>
  )
}

export default Targets
