import React, { ComponentProps, useCallback, useState } from 'react'
import { v4 as uuid } from 'uuid'

import Modal from '../../../../components/Modal'
import { TextField, FieldsRow, Field } from '../../../../components/form'
import { InvoiceEdit, InvoiceItemAbsolute } from '../utils'

type EditingItem = InvoiceItemAbsolute & { amountText?: string }

const DOLLAR_SIGN = <span style={{ paddingLeft: 8 }}>{'$'}</span>
const EMPTY_ITEM: EditingItem = Object.freeze({
  id: 'EMPTY',
  description: '',
  amount: 0,
})

const isValidItem = (item: InvoiceItemAbsolute): boolean =>
  Boolean(item.description && item.amount && item.id)
const isEmptyItem = (item: EditingItem): boolean =>
  !item.description && !item.amount && !item.amountText

type Props = ComponentProps<typeof Modal> & {
  value: InvoiceEdit | null
  onChangeValue: (newValue: InvoiceEdit | null) => void
}

const InvoiceEditor: React.FC<Props> = ({
  value,
  onChangeValue,
  onClose,
  ...props
}) => {
  const [newItems, setNewItems] = useState<EditingItem[]>(
    value?.additionalItems || [],
  )

  const patchItem = useCallback(
    (itemId: string, patch: Partial<EditingItem>) => {
      setNewItems(([...newItems]) => {
        let item = newItems.find(({ id }) => id === itemId)
        if (!item) {
          item = { ...EMPTY_ITEM, id: itemId }
          newItems.push(item)
        }

        Object.assign(item, patch)

        return newItems
      })
    },
    [setNewItems],
  )

  const setItemDescription = useCallback(
    (itemId: string, description: string) => {
      patchItem(itemId, { description })
    },
    [patchItem],
  )

  const setItemAmount = useCallback(
    (itemId: string, amountText: string) => {
      if (amountText.length === 0) {
        patchItem(itemId, { amount: 0, amountText: '' })
      } else if (/^-?\d*(?:\.\d?\d?)?$/.test(amountText)) {
        const amount = Number(amountText)
        if (!isNaN(amount)) {
          patchItem(itemId, { amountText })
        }
      }
    },
    [patchItem],
  )

  const clearItems = useCallback(() => setNewItems([]), [setNewItems])

  const save = useCallback(() => {
    const finalItems = newItems
      ?.map(
        ({ amount, amountText, ...item }): InvoiceItemAbsolute => ({
          ...item,
          amount: amountText ? parseFloat(amountText) : amount,
        }),
      )
      .filter(isValidItem)
    onChangeValue({
      ...value,
      additionalItems: finalItems?.length ? finalItems : undefined,
    })
  }, [newItems, onChangeValue, value])

  const renderedItems: EditingItem[] = [
    ...newItems,
    { ...EMPTY_ITEM, id: uuid() },
  ]

  return (
    <Modal
      width={'medium'}
      heading={'Edit invoice'}
      autoFocus
      actions={[
        { text: 'Save', onClick: save },
        { text: 'Clear items', onClick: clearItems },
        { text: 'Cancel', onClick: onClose },
      ]}
      onClose={onClose}
      {...props}
    >
      {renderedItems.map(
        (item, index) =>
          (index === renderedItems.length - 1 || !isEmptyItem(item)) && (
            <Field key={item.id} label={index === 0 ? 'Additional items' : ''}>
              <FieldsRow>
                <TextField
                  placeholder={'Description'}
                  value={item.description}
                  isInvalid={
                    !item.description && index < renderedItems.length - 1
                  }
                  onChangeValue={(value) => setItemDescription(item.id, value)}
                />
                <TextField
                  value={item.amountText || item.amount || ''}
                  // type={'number'}
                  placeholder={'9.99'}
                  elemBeforeInput={DOLLAR_SIGN}
                  isInvalid={
                    !item.amount &&
                    !item.amountText &&
                    index < renderedItems.length - 1
                  }
                  onChangeValue={(value) => setItemAmount(item.id, value)}
                />
              </FieldsRow>
            </Field>
          ),
      )}
    </Modal>
  )
}

export default InvoiceEditor
