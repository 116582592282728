import { colors } from '@atlaskit/theme'
import { NavLink as _NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Outer = styled.div`
  background-color: ${colors.N20};
  /* color: rgb(66, 82, 110); */
  display: flex;
  flex-direction: column;
  padding: 16px;
  flex: 0 2 300px;
  min-width: 230px;
  box-shadow: 0 0 0 2px #ebecf0;
  overflow-y: auto;
`

export const PrincipalUser = styled.div`
  position: relative;
  opacity: 0.6;
  &::after {
    content: '';
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`

export const NavLink = styled(_NavLink)<{
  $isIndented?: boolean
  $striked?: boolean | null
}>`
  ${({ $isIndented }) => !!$isIndented && `padding-left: 24px;`}
  ${({ $striked }) => !!$striked && `text-decoration: line-through;`}
`
