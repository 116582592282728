import { TimeTrackingEntriesListQueryVariables } from '../../../graphql'

import { Filter } from './types'

const getTimeTrackingEntriesListQueryVariables = (
  filter: Filter,
): TimeTrackingEntriesListQueryVariables => ({
  assistantIds: filter.assistantIds,
  endedAfter: filter.endedAfter?.toJSDate(),
  workspaceIds: filter.showDoubleTasksOnly ? [null] : undefined,
  executiveIds: filter.executiveIds,
  endedBefore: filter.endedBefore?.toJSDate(),
})

export default getTimeTrackingEntriesListQueryVariables
