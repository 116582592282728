import { SizeType } from '@atlaskit/avatar'
import styled, { css } from 'styled-components'

export const Spacer = styled.div`
  flex: 1;
`

export const Outer = styled.div<{ $striked?: boolean | null }>`
  display: flex;
  flex-flow: row;
  align-items: center;
  text-decoration: inherit;
  color: inherit;
  flex: 1;
  z-index: 0; // to fix an underlying high z-index
  ${({ $striked }) =>
    !!$striked &&
    css`
      text-decoration: line-through;
    `}
`

export const Name = styled.span<{ size?: SizeType }>`
  font-weight: 400;
  margin-left: 8px;

  :global(.active) > ${Outer} & {
    font-weight: 600;
  }

  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: smaller;
    `}

  ${({ size }) =>
    size === 'xsmall' &&
    css`
      font-weight: 500;
      font-size: x-small;
    `}
`
