import React, { ComponentProps } from 'react'

import {
  AssistantUser_CsatAverageFragment,
  isAssistantUser,
} from '../../../graphql'
import Badge from '../../Badge'

type AverageFeedbackUser = AssistantUser_CsatAverageFragment

export interface Props extends Partial<ComponentProps<typeof Badge>> {
  user?: AverageFeedbackUser
}

const AverageFeedback = ({ user, ...badgeProps }: Props) => {
  if (!isAssistantUser(user)) {
    return null
  }

  const rating = user?.csatAverage
  const label = rating === 5 ? '5' : rating?.toFixed(1) || '?'

  return (
    <Badge
      emoji={rating === 5 ? '🌟' : '⭐️'}
      title={`Average CSAT rating`}
      text={label}
      isLight
      tint={
        rating == null
          ? undefined
          : rating >= 4.5
          ? 'green'
          : rating >= 3
          ? 'yellow'
          : 'red'
      }
      {...badgeProps}
    />
  )
}

export default AverageFeedback
