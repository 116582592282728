import React from 'react'

import { UpsertFeatureInput } from '../../../graphql'
import useValues from '../../../lib/useValues'
import Modal from '../../Modal'
import { Field, TextField } from '../../form'

interface Props {
  title: string
  show?: boolean
  onHide?: () => void
  onSubmit: (values: UpsertFeatureInput) => void
}

const CreateModal = ({ title, show, onHide, onSubmit }: Props) => {
  const [input, { reset, patch }] = useValues<UpsertFeatureInput>({
    featureId: '',
    description: '',
    // Default predicates
    targets: [
      {
        predicates: [
          {
            featureId: 'team',
            isNegated: false,
          },
        ],
      },
      {
        predicates: [
          {
            featureId: 'testAccounts',
            isNegated: false,
          },
        ],
      },
    ],
  })

  return (
    <Modal
      isOpen={show}
      onClose={() => onHide?.()}
      onCloseComplete={reset}
      width={'small'}
      heading={title}
      autoFocus
      actions={[
        {
          text: 'Create Feature',
          onClick: () => onSubmit(input),
          isDisabled: !(input.featureId && input.description),
        },
        { text: 'Cancel', onClick: onHide },
      ]}
    >
      <Field
        isRequired
        label={'Feature ID'}
        helperMessage={'camelCase, ex: "shareProgress"'}
      >
        <TextField
          value={input.featureId}
          onChangeValue={(featureId) => patch({ featureId })}
        />
      </Field>
      <Field
        isRequired
        label={'Description'}
        helperMessage={'ex: "Share progress"'}
      >
        <TextField
          value={input.description || ''}
          onChangeValue={(description) => patch({ description })}
        />
      </Field>
    </Modal>
  )
}

export default CreateModal
