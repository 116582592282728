// @ts-ignore
import Avatar from '@atlaskit/avatar'
import { colors, typography } from '@atlaskit/theme'
import times from 'lodash/times'
import { transparentize } from 'polished'
import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { BasicWhoFragment } from '../../../graphql'
import { getMonthLetter } from '.././utils'
import useWhos from '../useWhos'

const Outer = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 0px 8px 8px 0px;
`

const Grid = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  background-color: ${transparentize(0.2, 'white')};
`

const DayNumbers = styled(Row)`
  flex: 0 0 auto;
  position: sticky;
  top: 0;
  z-index: 1;
`

const Cell = styled.div`
  flex: 1 0 ${100 / 32}%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px;
  min-width: 22px;
`

const DayNumber = styled(Cell)<{ highlight?: boolean }>`
  ${typography.h100()}
  margin: 0;
  padding: 4px 0;
  ${({ highlight }) =>
    highlight &&
    css`
      color: ${colors.textActive};
    `}
`

const MonthName = styled(Cell)<{ highlight?: boolean }>`
  ${typography.h200()}
  flex: 0 0 auto;
  margin: 0;
  min-width: 28px;
  ${({ highlight }) =>
    highlight &&
    css`
      color: ${colors.textActive};
    `}
`

const Birthdays = styled(Cell)<{ highlight?: boolean }>`
  *:nth-child(odd) > &:nth-child(odd),
  *:nth-child(even) > &:nth-child(even) {
    background-color: ${colors.N10};
  }

  &:hover {
    background-color: ${colors.backgroundHover}!important;
  }

  & > * {
    margin: 1px 0;
  }

  ${({ highlight }) =>
    highlight &&
    css`
      &,
      &:hover {
        background-color: ${colors.backgroundActive}!important;
      }
    `}
`

const Link = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TODAY_DAY = new Date().getDate()
const TODAY_MONTH_INDEX = new Date().getMonth()

const Calendar = () => {
  const { users } = useWhos({})

  const birthdaysByDate = useMemo(() => {
    const byDate: Record<string, BasicWhoFragment[]> = {}
    for (const user of users) {
      if (user.birthday?.month && user.birthday?.day) {
        const date = [user.birthday.month, user.birthday.day].join('-')
        if (!byDate[date]) {
          byDate[date] = []
        }
        byDate[date].push(user)
      }
    }
    return byDate
  }, [users])

  return (
    <Outer>
      <Grid>
        <DayNumbers>
          <MonthName />
          {times(31, (dayIndex) => (
            <DayNumber key={dayIndex} highlight={dayIndex + 1 === TODAY_DAY}>
              {dayIndex + 1}
            </DayNumber>
          ))}
        </DayNumbers>
        {times(12, (monthIndex) => (
          <Row key={monthIndex}>
            <MonthName highlight={monthIndex === TODAY_MONTH_INDEX}>
              {getMonthLetter(monthIndex)}
            </MonthName>
            {times(31, (dayIndex) => (
              <Birthdays
                key={monthIndex + '' + dayIndex}
                highlight={
                  dayIndex + 1 === TODAY_DAY && monthIndex === TODAY_MONTH_INDEX
                }
              >
                {(
                  birthdaysByDate[[monthIndex + 1, dayIndex + 1].join('-')] ||
                  []
                ).map(({ id, avatarUrl }) => (
                  <Link key={id} to={`/whoswho/${id}`}>
                    <Avatar src={avatarUrl!} size={'xsmall'} />
                  </Link>
                ))}
              </Birthdays>
            ))}
          </Row>
        ))}
      </Grid>
    </Outer>
  )
}

export default Calendar
