import {
  MessageTemplateFragment,
  onlyIfEmailMessageTemplate,
} from '../../../graphql'
import { isRequiredVar } from '../../utils'

import { Email } from './types'

export { isRequiredVar, includesVar, replaceVarsInString } from '../../utils'

export function missingVars(
  template: MessageTemplateFragment,
  languageCode: string,
  { vars }: Partial<Email>,
): string[] {
  return (
    template.languages
      .find((l) => l.languageCode === languageCode)
      ?.placeholders.filter(
        (name) => typeof vars?.[name] !== 'string' && isRequiredVar(name),
      ) || []
  )
}

export function isValidEmail(
  _template: MessageTemplateFragment,
  languageCode: string,
  email: Partial<Email>,
  checkPlaceholders = false,
): email is Email {
  const template = onlyIfEmailMessageTemplate(_template)

  // Check for basic metas
  if (
    !(
      template?.languages?.find((l) => l.languageCode === languageCode)?.fields
        ?.layoutId &&
      email.from?.email &&
      email.to?.[0]?.email &&
      email.subject &&
      email.body
    )
  ) {
    return false
  }

  // Check placeholders
  if (
    checkPlaceholders &&
    missingVars(template, languageCode, email).length > 0
  ) {
    return false
  }

  return true
}
