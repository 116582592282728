import memoize from 'lodash/memoize'
import { useCallback, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

import {
  ListWhosQuery,
  ListWhosDocument,
  BasicWhoFragment,
  useGetWhoLazyQuery,
  useListWhosQuery,
  useListQueryCache,
  withListPagination,
  UserCategory,
} from '../../graphql'

import { SearchTerms } from './SearchField'

const PAGE_SIZE = 200

const normalizeSearch = function (text: string): string {
  return (
    text
      ?.toLowerCase()
      .trim()
      // Bye bye accents (yes, it's a bit brutish, but Fuck It®)
      .replace(/[àáâäãåā]/gi, 'a')
      .replace(/[éèêëēėę]/gi, 'e')
      .replace(/[îïíīįì]/gi, 'i')
      .replace(/[ôöòóøōõ]/gi, 'o')
      .replace(/[ûüùúū]/gi, 'u')
      .replace(/[ÿ]/gi, 'y')
      .replace(/[æ]/gi, 'ae')
      .replace(/[œ]/gi, 'oe')
      .replace(/[çćč]/gi, 'c')
      .replace(/[ßśš]/gi, 's')
      .replace(/[ł]/gi, 'l')
      .replace(/[žźż]/gi, 'z')
      .replace(/[ñń]/gi, 'n')
  )
}

export interface Filters {
  search?: SearchTerms
  timeZones?: string[] | null | undefined
  language?: string | null | undefined
  hqOnly?: boolean
}

export default function ({ search, timeZones, language, hqOnly }: Filters) {
  const {
    params: { userId },
  } = useRouteMatch<{ userId: string }>('/whoswho/:userId') || { params: {} }
  const { findItem: findUser, upsertItem: upsertUser } = useListQueryCache<
    ListWhosQuery,
    BasicWhoFragment
  >(ListWhosDocument)
  const [
    fetchUser,
    { data: userData, loading: loadingUser, error: errorUser },
  ] = useGetWhoLazyQuery()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchUserOnce = useCallback(
    memoize((userId: string) => fetchUser({ variables: { userId } })),
    [fetchUser],
  )

  const {
    data: listData,
    fetchMore,
    loading: loadingUsers,
    error: errorUsers,
  } = withListPagination(
    useListWhosQuery({
      variables: { first: PAGE_SIZE },
    }),
  )

  if (userData?.who) {
    upsertUser(userData?.who, false, false)
  }

  const user = userId && listData ? findUser(userId) : undefined

  if (userId && !user && listData) {
    // We could not find user in cache, so load it
    fetchUserOnce(userId)
  }

  const filteredUsers = useMemo(() => {
    const users = listData?.list.items || []
    return users.filter(({ givenName, skills, city, category, languages }) => {
      if (
        search?.name &&
        !normalizeSearch(givenName).includes(normalizeSearch(search.name))
      ) {
        return false
      }

      if (search?.skill && !skills.includes(search.skill)) {
        return false
      }

      if (timeZones?.length && !timeZones.includes(city?.timeZone || '')) {
        return false
      }

      if (hqOnly && category !== UserCategory.TEAM) {
        return false
      }

      if (language && !languages?.includes(language)) {
        return false
      }

      return true
    })
  }, [search, timeZones, language, hqOnly, listData?.list.items])

  return {
    users: filteredUsers,
    loadingUsers,
    errorUsers,
    fetchMore,
    hasMore: !!listData?.list.after,

    user,
    loadingUser: !user && (loadingUser || loadingUsers),
    errorUser,
  }
}
