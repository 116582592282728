import {
  MessageTemplateKind,
  MessageTemplate_LabelsFragment,
  PossibleTypesResultData,
} from '../../../graphql'

export type MessageTemplateTypename =
  PossibleTypesResultData['possibleTypes']['MessageTemplate'][number]

export const KIND_BY_TYPENAME: Record<
  MessageTemplateTypename,
  MessageTemplateKind
> = {
  ['EmailMessageTemplate']: MessageTemplateKind.EMAIL,
  ['SlackMessageTemplate']: MessageTemplateKind.SLACK,
  ['SmsMessageTemplate']: MessageTemplateKind.SMS,
  ['TextMessageTemplate']: MessageTemplateKind.TEXT,
}

const KIND_LABEL: Record<MessageTemplateTypename, string> = {
  ['EmailMessageTemplate']: 'Email',
  ['SlackMessageTemplate']: 'Slack',
  ['SmsMessageTemplate']: 'SMS',
  ['TextMessageTemplate']: 'Single text',
}

export const getKindLabel = ({
  __typename,
}: {
  __typename?: undefined | MessageTemplateTypename
}): string => (__typename ? KIND_LABEL[__typename] : 'Other')

const KIND_EMOJI: Record<MessageTemplateTypename, string> = {
  ['EmailMessageTemplate']: '📥',
  ['SlackMessageTemplate']: '💬',
  ['SmsMessageTemplate']: '📱',
  ['TextMessageTemplate']: '',
}

export const getKindEmoji = ({
  __typename,
}: {
  __typename?: undefined | MessageTemplateTypename
}): string => (__typename ? KIND_EMOJI[__typename] : '')

export const NEW_TEMPLATE_TITLE = 'New ✨'

export const isNewTemplate = (
  template: MessageTemplate_LabelsFragment | null | undefined,
): boolean => template?.name === NEW_TEMPLATE_TITLE
