import memoize from 'lodash/memoize'
import { useState, useEffect } from 'react'

const GOOGLE_AUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || ''

export interface AuthTokenData {
  me: {
    id: string
    email: string
    givenName: string
    familyName: string
    avatarUrl: string
    isAssistant: boolean
  }
}

class Auth {
  public addEventListener: Function
  public removeEventListener: Function
  private dispatchEvent: Function

  private googleAuth: any
  private _idToken: string | null = null
  private _token: string | null = null

  constructor() {
    // Fake inheritance from EventTarget
    const target = document.createTextNode('')
    this.addEventListener = target.addEventListener.bind(target)
    this.removeEventListener = target.removeEventListener.bind(target)
    this.dispatchEvent = target.dispatchEvent.bind(target)

    Object.assign(this, {
      init: memoize(this.init.bind(this)),
      signIn: this.signIn.bind(this),
    })

    const self = this
    ;(async () => {
      try {
        await self.init()
        self.dispatchEvent(new Event('initialized'))
      } catch (error) {
        console.error(error)
      }
    })()
  }

  protected async init(): Promise<void> {
    // @ts-ignore
    const { gapi } = window

    for (let iteration = 0; iteration < 3; iteration++) {
      if (!(gapi && gapi.load)) {
        console.warn('Auth: window.gapi is missing, delaying execution')
        await new Promise((resolve) =>
          setTimeout(resolve, 500 + 500 * iteration),
        ) // eslint-disable-line
      }
    }

    if (!(gapi && gapi.load)) {
      console.warn("Auth: window.gapi is STILL missing, you're fucked.")
    }

    return new Promise(
      (
        resolve,
        reject, // eslint-disable-line
      ) =>
        gapi.load('auth2', async () => {
          try {
            const googleAuth = await gapi.auth2.init({
              // eslint-disable-next-line camelcase
              client_id: GOOGLE_AUTH_CLIENT_ID,
            })
            this.googleAuth = googleAuth
            this.onCurrentGoogleUser(this.googleAuth.currentUser.get())
            this.googleAuth.currentUser.listen(
              this.onCurrentGoogleUser.bind(this),
            )
            return resolve()
          } catch (error) {
            reject(error)
          }
        }),
    )
  }

  get isInitialized() {
    return !!this.googleAuth
  }

  get isSignedIn() {
    return !!this._idToken
  }

  get idToken(): string | null {
    return this._idToken
  }

  set idToken(newValue: string | null) {
    if (newValue !== this._idToken) {
      this._idToken = newValue
      this.dispatchEvent(new Event('signedIn'))

      if (!this._idToken) {
        this.token = null
      }
    }
  }

  get isAuthenticated() {
    return !!this.token
  }

  get token(): string | null {
    return this._token
  }

  set token(newValue: string | null) {
    if (newValue !== this._token) {
      this._token = newValue
      this.dispatchEvent(new Event('authenticated'))
    }
  }

  get tokenData(): null | AuthTokenData {
    if (this._token) {
      try {
        return JSON.parse(atob(this._token.split('.')[1])).data as AuthTokenData
      } catch (error) {
        console.error(error)
      }
    }

    return null
  }

  protected onCurrentGoogleUser(googleUser: any) {
    const authResponse = googleUser && googleUser.getAuthResponse()
    this.idToken = (authResponse && authResponse.id_token) || null
  }

  async signIn() {
    await this.init()
    this.googleAuth.signIn()
  }

  async signOut() {
    await this.googleAuth.disconnect()
  }
}

const sharedAuth = new Auth()

export function useAuth() {
  const [isInitialized, setIsInitialized] = useState(sharedAuth.isInitialized)
  const [isAuthenticated, setIsAuthenticated] = useState(
    sharedAuth.isAuthenticated,
  )
  const [isSignedIn, setIsSignedIn] = useState(sharedAuth.isSignedIn)

  useEffect(() => {
    function onChange() {
      setIsInitialized(sharedAuth.isInitialized)
      setIsAuthenticated(sharedAuth.isAuthenticated)
      setIsSignedIn(sharedAuth.isSignedIn)
    }

    sharedAuth.addEventListener('authenticated', onChange)
    sharedAuth.addEventListener('initialized', onChange)
    sharedAuth.addEventListener('signedIn', onChange)

    return () => {
      sharedAuth.removeEventListener('authenticated', onChange)
      sharedAuth.removeEventListener('initialized', onChange)
      sharedAuth.removeEventListener('signedIn', onChange)
    }
  }, [])

  return {
    isInitialized,
    isAuthenticated,
    isSignedIn,
    signIn: sharedAuth.signIn.bind(sharedAuth),
    signOut: sharedAuth.signOut.bind(sharedAuth),
  }
}

export default sharedAuth
