import { LoadingButton as Button, LoadingButtonProps } from '@atlaskit/button'
import Popup, { TriggerProps } from '@atlaskit/popup'
import { layers } from '@atlaskit/theme'
import React from 'react'
import styled from 'styled-components'

import { ErrorEmptyState } from '../EmptyState'
import { LoadingSpinner } from '../Spinner'

import Slots, { SlotFragment } from './Slots'

const Inner = styled.div`
  padding: 0 16px;
  min-height: 100px;
  min-width: 100px;
  max-height: 320px;
  overflow-y: auto;
  display: flex;
`

export type OnboardingAvailabilityButtonProps = Partial<TriggerProps> &
  Omit<Partial<LoadingButtonProps>, 'open'> & {
    slots: null | undefined | SlotFragment[]
    isOpen: boolean
    open: () => void
    close: () => void
    error?: Error
  }

const OnboardingAvailabilityButton = ({
  isOpen,
  open,
  close,
  slots,
  isLoading,
  error,
  ...buttonProps
}: OnboardingAvailabilityButtonProps) => {
  return (
    <Popup
      zIndex={layers.modal() + 1}
      isOpen={isOpen}
      onClose={close}
      placement={'auto'}
      trigger={(triggerProps) => (
        <Button
          {...triggerProps}
          {...buttonProps}
          isLoading={isLoading}
          isSelected={isOpen}
          onClick={open}
        />
      )}
      content={() =>
        !isOpen ? null : (
          <Inner>
            {isLoading ? (
              <LoadingSpinner size={'large'} />
            ) : error ? (
              <ErrorEmptyState error={error} />
            ) : (
              <Slots slots={slots || []} />
            )}
          </Inner>
        )
      }
    />
  )
}

export default OnboardingAvailabilityButton
