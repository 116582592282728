import Avatar, { AvatarItem } from '@atlaskit/avatar'
import { colors, typography, borderRadius } from '@atlaskit/theme'
import groupBy from 'lodash/groupBy'
import React, { Fragment, useMemo, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { LoadingSpinner } from '../../../components/Spinner'
import { Checkbox } from '../../../components/form'
import { useListContractorReportsQuery } from '../../../graphql'
import useValues from '../../../lib/useValues'

import ListActions from './ListActions'
import {
  formatCurrency,
  formatAmount,
  formatHours,
  ReportsQuery,
  getReportDisplayName,
  sortReports,
  getReportSection,
  SECTIONS,
  getContractorEmail,
  getTotalAmount,
  SortKey,
} from './utils'

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 3;
  max-width: 940px;
  color: ${colors.text()};
`

const Table = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  padding: 0 24px;
`

const TableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
`

const TableSection = styled(TableRow)`
  position: sticky;
  background-color: rgba(255, 255, 255, 0.95);
  border-bottom: solid 2px ${colors.backgroundHover};
  z-index: 2;
  padding-top: 32px;
`

const SectionTitle = styled.span`
  ${typography.h600()}
  padding: 0 8px;
  margin: 0;
`

const Report = styled(TableRow)<{ selected?: boolean }>`
  border-radius: ${borderRadius}px;
  &:hover {
    background-color: ${colors.backgroundHover()};
  }

  ${({ selected }) =>
    !selected &&
    css`
      & > * {
        opacity: 0.4;
      }
    `}
`

const Contractor = styled.div`
  flex: 0 0 35%;
`

const Vendor = styled.div`
  flex: 0 0 140px;
`

const Amount = styled.div`
  text-align: right;
  flex: 1 1 15%;
`

const TotalHours = styled(Amount)`
  font-weight: bold;
`

const DoubleHours = styled(Amount)``

const HourlyRate = styled(Amount)``

const Total = styled(Amount)`
  font-weight: bold;
  min-width: 100px;
`

const CheckboxOuter = styled.div`
  flex: 0 0 24px;
  margin-left: 32px;
  opacity: 1 !important;
`

type Props = {
  query: ReportsQuery
  sortKey: SortKey
}

const ContractorsList = ({ query, sortKey }: Props) => {
  const [defaultExcluded] = useState(() => ({}))
  const [
    excluded,
    { patch: patchExcluded, set: setExcluded, reset: resetExcluded },
  ] = useValues<Record<string, boolean>>(defaultExcluded)
  const { data, loading } = useListContractorReportsQuery({
    variables: query,
  })

  const reports = useMemo(
    () => sortReports(data?.list.items || [], sortKey),
    [data, sortKey],
  )
  const sections = useMemo(() => groupBy(reports, getReportSection), [reports])

  // Reset excluded on query change
  useEffect(() => {
    resetExcluded()
  }, [query, resetExcluded])

  // Pre-exclude $0.00 reports
  useEffect(() => {
    setExcluded(({ ...excluded }) => {
      for (const report of reports) {
        if (
          typeof excluded[report.id] === 'undefined' &&
          !getTotalAmount(report)
        ) {
          excluded[report.id] = true
        }
      }
      return excluded
    })
  }, [reports, setExcluded])

  return (
    <Outer>
      <Table>
        {SECTIONS.map(({ value, label }, index, { length }) => {
          const reports = sections[value] || []
          const summary = reports.reduce(
            (summary, { id, user, totalHours }) => {
              if (!excluded[id]) {
                summary.count++
                if (user?.hourlyRate) {
                  const currency = user?.currency || null
                  summary.currencies.set(
                    currency,
                    (summary.currencies.get(currency) || 0) +
                      user?.hourlyRate * totalHours,
                  )
                }
              }

              return summary
            },
            { count: 0, currencies: new Map() } as {
              count: number
              currencies: Map<string | null, number>
            },
          )

          return (
            <Fragment key={value}>
              <TableSection
                style={{
                  top: 66 * index,
                  bottom: -2 + (length - index - 1) * 66,
                }}
              >
                <Contractor>
                  <SectionTitle>
                    {label}
                    {` (${
                      summary.count !== reports.length
                        ? `${summary.count}/${reports.length}`
                        : reports.length
                    })`}
                  </SectionTitle>
                </Contractor>
                <Vendor>{'Vendor'}</Vendor>
                <TotalHours>{'Hours'}</TotalHours>
                <DoubleHours>{'(Double)'}</DoubleHours>
                <HourlyRate>{'Rate'}</HourlyRate>
                <Total>
                  {[...summary.currencies.keys()]
                    .map((currency) =>
                      formatAmount(summary.currencies.get(currency), currency),
                    )
                    .join('\n')}
                </Total>
                <CheckboxOuter>
                  {
                    <Checkbox
                      isChecked={summary.count > 0}
                      isIndeterminate={summary.count !== reports.length}
                      onChangeValue={(included) => {
                        const patch: typeof excluded = {}
                        for (const { id } of reports) {
                          patch[id] = !included
                        }
                        patchExcluded(patch)
                      }}
                    />
                  }
                </CheckboxOuter>
              </TableSection>
              {reports.map((report) => (
                <Report key={report.id} selected={!excluded[report.id]}>
                  <Contractor>
                    <AvatarItem
                      avatar={
                        <Avatar
                          src={report.user?.profile.avatarUrl || undefined}
                        />
                      }
                      primaryText={getReportDisplayName(report)}
                      secondaryText={getContractorEmail(report)}
                    />
                  </Contractor>
                  <Vendor>{report.user?.justworksVendorName || ''}</Vendor>
                  <TotalHours>{formatHours(report.totalHours || 0)}</TotalHours>
                  <DoubleHours>
                    {formatHours(report.doubleHours || 0)}
                  </DoubleHours>
                  <HourlyRate>{`${formatCurrency(
                    report.user?.currency || undefined,
                  )}${report.user?.hourlyRate || 0}`}</HourlyRate>
                  <Total>
                    {formatAmount(
                      getTotalAmount(report),
                      report.user?.currency,
                    )}
                  </Total>
                  <CheckboxOuter>
                    <Checkbox
                      isChecked={!excluded[report.id]}
                      onChangeValue={(included) =>
                        patchExcluded({ [report.id]: !included })
                      }
                    />
                  </CheckboxOuter>
                </Report>
              ))}
            </Fragment>
          )
        })}
      </Table>
      <ListActions
        query={query}
        reports={reports.filter(({ id }) => !excluded[id])}
      />
      <LoadingSpinner show={loading} />
    </Outer>
  )
}

export default ContractorsList
