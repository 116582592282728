import Lozenge from '@atlaskit/lozenge'
import React from 'react'

import {
  Matching_ForTransitionListItemFragment,
  UserCategory,
} from '../../../../../../graphql'

type Props = {
  matching: Matching_ForTransitionListItemFragment
}

const RequestedBy = ({ matching }: Props) => {
  const requestedBy = matching.transition?.requestedByUserCategory

  if (!requestedBy) {
    return null
  }

  return (
    <Lozenge
      appearance={
        requestedBy === UserCategory.EXECUTIVE
          ? 'new'
          : requestedBy === UserCategory.ASSISTANT
          ? 'moved'
          : 'removed'
      }
    >
      {requestedBy === UserCategory.ASSISTANT ? 'EA' : 'Client'}
    </Lozenge>
  )
}

export default RequestedBy
