import React from 'react'

import { Matching_ForTransitionListItemFragment } from '../../../../../../graphql'
import { HANDOVER_STATUS_LABEL } from '../../../utils'

import StageButton from './components/StageButton'

type Props = {
  matching: Matching_ForTransitionListItemFragment
}

const Handover = ({ matching }: Props) => {
  return (
    <StageButton matching={matching} stageId={'handover'}>
      {matching.transition!.handoverStatus
        ? HANDOVER_STATUS_LABEL[matching.transition!.handoverStatus]
        : 'TBD'}
    </StageButton>
  )
}

export default Handover
