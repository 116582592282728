import { DateTime } from 'luxon'
import React, { useMemo } from 'react'

import { useMarkPaidTimeTrackingEntriesMutation } from '../../../../graphql'
import useNow from '../../../../lib/useNow'

import DatePickerPopUp from './DatePickerPopUp'

const PAID_AT_TIME_ET_INFO = {
  zone: 'America/New_York',
  hour: 23,
}

const ARBITRARY_DATE_WITH_PAID_TIME = DateTime.fromObject(
  {
    year: 2021,
    month: 1,
    day: 1,
    hour: PAID_AT_TIME_ET_INFO.hour,
  },
  {
    zone: PAID_AT_TIME_ET_INFO.zone,
  },
)

type Props = {
  lastPaidAtDate?: DateTime
  onClose: () => void
}

const MarkPaidEntriesPopup = ({ lastPaidAtDate, onClose }: Props) => {
  const now = useNow({ zone: PAID_AT_TIME_ET_INFO.zone })

  const maxISODate = useMemo(() => {
    return (
      PAID_AT_TIME_ET_INFO.hour <= now.get('hour')
        ? // If the current time is after 11pm ET (8pm PT) then we can select today
          now
        : now.minus({ days: 1 })
    ).toISODate()
  }, [now])

  const minISODate = useMemo(() => {
    if (!lastPaidAtDate) return undefined

    return (
      lastPaidAtDate.setZone(PAID_AT_TIME_ET_INFO.zone).get('hour') <
      PAID_AT_TIME_ET_INFO.hour
        ? // If the last paid at time was manually set before 11pm ET (8pm PT)
          // then we allow the user to select the same date to push the paid at
          // time to 11pm ET (8pm PT)
          lastPaidAtDate
        : lastPaidAtDate.plus({ days: 1 })
    )?.toISODate()
  }, [lastPaidAtDate])

  const [markPaidEntries, { loading: markPaidEntriesLoading }] =
    useMarkPaidTimeTrackingEntriesMutation({
      onCompleted: () => {
        window.location.reload()
      },
    })

  return (
    <DatePickerPopUp
      title={'Paid for'}
      subtitle={`All time entries before ${ARBITRARY_DATE_WITH_PAID_TIME.setZone(
        PAID_AT_TIME_ET_INFO.zone,
      ).toFormat(
        'HH:mm ZZZZ',
      )} (${ARBITRARY_DATE_WITH_PAID_TIME.toLocal().toFormat(
        'HH:mm ZZZZ',
      )}) on the selected date have been processed through payroll.`}
      minDate={minISODate}
      maxDate={maxISODate}
      onClose={onClose}
      isSaving={markPaidEntriesLoading}
      onSave={async (isoDate) => {
        const [year, month, day] = isoDate
          .split('-')
          .map((stringValue) => parseInt(stringValue))

        const endedBeforeDateTime = DateTime.fromObject(
          {
            year,
            month,
            day,
            hour: PAID_AT_TIME_ET_INFO.hour,
          },
          {
            zone: PAID_AT_TIME_ET_INFO.zone,
          },
        )

        await markPaidEntries({
          variables: {
            input: {
              endedBefore: endedBeforeDateTime.toISO(),
            },
          },
        })

        onClose()
      }}
    />
  )
}

export default MarkPaidEntriesPopup
