import ChevronIcon from '@atlaskit/icon/glyph/chevron-down'
import Lozenge from '@atlaskit/lozenge'
// @ts-ignore
import { Group } from '@atlaskit/navigation-next'
import { colors } from '@atlaskit/theme'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import PageHeader from '../../../components/PageHeader'
import { Field, PopupSelect } from '../../../components/form'
import ItemWithNavlink from '../../../components/list/ItemWithNavlink'
import ListContainer from '../../../components/list/ListContainer'
import ListOuter from '../../../components/list/ListOuter'
import PageHeaderOuter from '../../../components/list/PageHeaderOuter'
import {
  TopicTemplateFragment,
  ListTopicTemplatesDocument,
  ListTopicTemplatesQuery,
  useListQueryCache,
  DiscoverCategory,
  DiscoverSectionKey,
  TopicTemplateType,
} from '../../../graphql'
import useValues from '../../../lib/useValues'

import CategorySelect from './components/CategorySelect'
import LabelsSelect from './components/LabelsSelect'
import {
  getSectionTitle,
  getCategoryIcon,
  NEW_TEMPLATE_TITLE,
  getTypeName,
  canFilterByCategory,
  canFilterByLabel,
  groupBySection,
} from './utils'

const Outer = styled(ListContainer)`
  min-width: 280px;
  max-width: 400px;
`

const Title = styled.span`
  cursor: pointer;
`

const Filters = styled.div``

const IconOuter = styled.div`
  height: 20px;
  width: 20px;
  svg {
    fill: ${colors.subtleText};
    height: 100%;
    width: 100%;

    a.active & {
      fill: ${colors.textActive};
    }
  }
`

interface Props {
  templates: TopicTemplateFragment[]
  selectedTemplateId?: TopicTemplateFragment['id']
}

const TemplatesList = ({ templates: allTemplates }: Props) => {
  const { upsertItem: upsertTemplate } = useListQueryCache<
    ListTopicTemplatesQuery,
    TopicTemplateFragment
  >(ListTopicTemplatesDocument)
  const navHistory = useHistory()
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>()
  const [filters, { patch: patchFilters, setters }] = useValues<{
    category?: DiscoverCategory
    labels: string[]
    type: TopicTemplateType
  }>(
    {
      labels: [],
      type: TopicTemplateType.TODO,
    },
    ['category', 'labels', 'type'],
  )

  const createTemplate = useCallback(() => {
    const newTemplate: Required<TopicTemplateFragment> = {
      __typename: 'TopicTemplate',
      id: uuid(),
      type: filters.type,
      category: DiscoverCategory.INTERNAL,
      sectionKey: DiscoverSectionKey.FEATURED,
      isDraft: true,
      isNew: true,
      title: NEW_TEMPLATE_TITLE,
      description: '',
      instructions: '',
      introduction: null,
      introductionTitle: null,
      introductionImageUrl: null,
      imageUrl: null,
      keywords: [],
      labels: [],
      form: null,
      privateTo: null,
      assigneeRole: null,
      notepad: null,
    }

    upsertTemplate(newTemplate)

    navHistory.push(`/templates/${newTemplate.id}`)
  }, [filters.type, navHistory, upsertTemplate])

  const templates = allTemplates
    .filter(({ type }) => type === filters.type)
    .filter(
      ({ title }) => !searchTerm || title.toLowerCase().includes(searchTerm),
    )
    .filter(
      ({ category }) =>
        !canFilterByCategory(filters.type) ||
        !filters.category ||
        filters.category === category,
    )
    .filter(
      ({ labels }) =>
        !canFilterByLabel(filters.type) ||
        !filters.labels.length ||
        filters.labels.every((label) => labels.includes(label)),
    )

  return (
    <Outer>
      <PageHeaderOuter>
        <PageHeader
          createActions={[
            {
              label: 'Template',
              onClick: createTemplate,
            },
          ]}
          searchTerm={searchTerm}
          onChangeSearchTerm={setSearchTerm}
          hasActiveFilter={Boolean(filters.category || filters.labels.length)}
          filtersContent={
            canFilterByCategory(filters.type) || canFilterByLabel(filters.type)
              ? () => (
                  <Filters>
                    {canFilterByCategory(filters.type) && (
                      <Field label={'Category'}>
                        <CategorySelect
                          value={filters.category}
                          onChangeValue={(category) =>
                            patchFilters({ category })
                          }
                          isClearable
                        />
                      </Field>
                    )}
                    {canFilterByLabel(filters.type) && (
                      <Field label={'Labels'}>
                        <LabelsSelect
                          values={filters.labels}
                          onChangeValues={(labels) => patchFilters({ labels })}
                          isClearable
                          isCreatable
                        />
                      </Field>
                    )}
                  </Filters>
                )
              : undefined
          }
        >
          <PopupSelect<TopicTemplateType>
            options={Object.values(TopicTemplateType)}
            value={filters.type}
            getOptionLabel={(type) => getTypeName(type, true)}
            onChangeValue={setters.type}
            target={({ ref }) => (
              <Title ref={ref}>
                {getTypeName(filters.type, true)} <ChevronIcon label={''} />
              </Title>
            )}
          />
        </PageHeader>
      </PageHeaderOuter>
      <ListOuter>
        {groupBySection(filters.type) ? (
          <>
            <Group heading={'Internal (users cannot create manually)'}>
              {templates
                .filter(
                  ({ category }) => category === DiscoverCategory.INTERNAL,
                )
                .map((template) => (
                  <ItemWithNavlink
                    key={template.id}
                    href={`/templates/${template.id}`}
                    isSelected={false}
                    text={template.title}
                    // subText={''}
                    before={() => {
                      if (!canFilterByCategory(template.type)) {
                        return null
                      }
                      const Icon = getCategoryIcon(template.category)
                      return (
                        <IconOuter>
                          <Icon />
                        </IconOuter>
                      )
                    }}
                    after={() => (
                      <>
                        {template.isDraft && (
                          <Lozenge appearance={'moved'}>{'Draft'}</Lozenge>
                        )}
                      </>
                    )}
                  />
                ))}
            </Group>
            {Object.values(DiscoverSectionKey).map((key) => (
              <Group heading={getSectionTitle(key)} key={key}>
                {templates
                  .filter(
                    ({ sectionKey, category }) =>
                      category !== DiscoverCategory.INTERNAL &&
                      sectionKey === key,
                  )
                  .map((template) => (
                    <ItemWithNavlink
                      key={template.id}
                      href={`/templates/${template.id}`}
                      isSelected={false}
                      text={template.title}
                      // subText={''}
                      before={() => {
                        if (!canFilterByCategory(template.type)) {
                          return null
                        }
                        const Icon = getCategoryIcon(template.category)
                        return (
                          <IconOuter>
                            <Icon />
                          </IconOuter>
                        )
                      }}
                      after={() => (
                        <>
                          {template.isDraft ? (
                            <Lozenge appearance={'moved'}>{'Draft'}</Lozenge>
                          ) : (
                            template.isNew && (
                              <Lozenge appearance={'new'}>{'NEW'}</Lozenge>
                            )
                          )}
                        </>
                      )}
                    />
                  ))}
              </Group>
            ))}
          </>
        ) : (
          templates.map((template) => (
            <ItemWithNavlink
              key={template.id}
              href={`/templates/${template.id}`}
              isSelected={false}
              text={template.title}
              // subText={''}
              before={() => {
                if (!canFilterByCategory(template.type)) {
                  return null
                }
                const Icon = getCategoryIcon(template.category)
                return (
                  <IconOuter>
                    <Icon />
                  </IconOuter>
                )
              }}
              after={() => (
                <>
                  {template.isDraft ? (
                    <Lozenge appearance={'moved'}>{'Draft'}</Lozenge>
                  ) : (
                    template.isNew && (
                      <Lozenge appearance={'new'}>{'NEW'}</Lozenge>
                    )
                  )}
                </>
              )}
            />
          ))
        )}
      </ListOuter>
    </Outer>
  )
}

export default TemplatesList
