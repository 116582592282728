import CopyIcon from '@atlaskit/icon/glyph/copy'
import { InlineEditableTextfield } from '@atlaskit/inline-edit'
import TableTree from '@atlaskit/table-tree'
import { typography } from '@atlaskit/theme'
import { groupBy } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import Badge, { Tint } from '../../../components/Badge'
import {
  MoreDropdownMenu,
  DropdownItemGroup,
  DropdownItem,
} from '../../../components/DropdownMenu'
import { ErrorEmptyState } from '../../../components/EmptyState'
import PageHeader from '../../../components/PageHeader'
import { LoadingSpinner } from '../../../components/Spinner'
import {
  useListMetricEventsQuery,
  MetricEventFragment,
  MetricEventPropertyFragment,
  useUpdateMetricEventMutation,
  useUpdateMetricEventPropertyMutation,
} from '../../../graphql'

const Outer = styled.div`
  padding: 0 24px 48px;
  overflow: auto;
  height: 100%;
  width: 100%;

  [role='treegrid']
    [role='row']
    [role='gridcell']:first-child
    span
    span:first-child
    button {
    margin-top: -5px;
  }
`

type Content = {
  event?: MetricEventFragment & { section: string }
  property?: MetricEventPropertyFragment
  section?: string
}

const Name = styled.div`
  ${typography.h100()};
  margin: 6px 0 0;
`

const Section = styled.div`
  ${typography.h500()};
  margin: 5px 0;
`

const DescriptionOuter = styled.div`
  margin-top: -9px;
  /* margin-bottom: -4px; */
`

export const getAppColor = (app: string): Tint | undefined => {
  if (app.includes('Web')) {
    return 'blue'
  }
  if (app.includes('iOS')) {
    return 'green'
  }
  if (app.includes('Server')) {
    return 'red'
  }
  if (app.includes('Slack')) {
    return 'yellow'
  }
  if (app.includes('Extension')) {
    return 'teal'
  }
}

const NameCell = ({ event, property }: Content) => (
  <Name>{property?.name || event?.name}</Name>
)

const Description = ({ event, section, property }: Content) => {
  const [updateEvent] = useUpdateMetricEventMutation()
  const [updateEventProperty] = useUpdateMetricEventPropertyMutation()

  const description =
    (property ? property.description : event?.description)?.trim() || ''

  const updateDescription = useCallback(
    (newDescription: string) => {
      newDescription = newDescription?.trim() || ''

      if (event && description !== newDescription) {
        if (property) {
          updateEventProperty({
            variables: {
              input: {
                metricEventId: event.id,
                propertyName: property.name,
                description: newDescription,
              },
            },
          })
        } else {
          updateEvent({
            variables: {
              input: {
                metricEventId: event.id,
                description: newDescription,
              },
            },
          })
        }
      }
    },
    [updateEvent, updateEventProperty, event, description, property],
  )

  if (section && !event && !property) {
    return <Section>{section}</Section>
  }

  return (
    <DescriptionOuter>
      <InlineEditableTextfield
        defaultValue={description}
        onConfirm={updateDescription}
        placeholder={'Edit'}
        isCompact
      />
    </DescriptionOuter>
  )
}

const Apps = ({ event, property }: Content) => (
  <div>
    {!property &&
      event?.apps.map((app) => (
        <Badge text={app} key={app} tint={getAppColor(app)} isLight />
      ))}
  </div>
)

const Actions = ({ event, property }: Content) => {
  if (!event || property) {
    return null
  }
  return (
    <MoreDropdownMenu>
      <DropdownItemGroup>
        <DropdownItem
          onClick={() => navigator.clipboard.writeText(event.trackedEventName)}
          elemBefore={<CopyIcon label={''} />}
          description={event.trackedEventName}
        >
          {'Copy tracked event name'}
        </DropdownItem>
        <DropdownItem
          onClick={() => navigator.clipboard.writeText(event.sql)}
          elemBefore={<CopyIcon label={''} />}
        >
          {'Copy BigQuery SQL'}
        </DropdownItem>
      </DropdownItemGroup>
    </MoreDropdownMenu>
  )
}

const MetricEventsList = () => {
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>()
  const { data, loading, error } = useListMetricEventsQuery()

  const events = useMemo(() => {
    return (data?.list.items || [])
      .filter((event) => {
        const q = searchTerm?.trim().toLowerCase()

        return (
          !q ||
          event.name.toLowerCase().includes(q) ||
          event.description?.toLowerCase().includes(q) ||
          event.trackedEventName.toLowerCase().includes(q)
        )
      })
      .map((event) => ({
        ...event,
        section: event.description?.split('/')[0]?.trim() || 'Other',
      }))
  }, [data, searchTerm])

  const items = useMemo(() => {
    const grouped = groupBy(events, 'section')
    return Object.keys(grouped)
      .sort()
      .map((section) => ({
        id: `section-${section}`,
        content: { section },
        hasChildren: true,
        children: grouped[section].map((event) => ({
          id: event.id,
          content: {
            event,
          },
          hasChildren: !!event.properties.length,
          children: event.properties.map((property) => ({
            id: property.id,
            content: {
              event,
              property,
            },
          })),
        })),
      }))
  }, [events])

  return (
    <Outer>
      <PageHeader searchTerm={searchTerm} onChangeSearchTerm={setSearchTerm}>
        {'Metric Events'}
      </PageHeader>
      {!!error && <ErrorEmptyState error={error} />}

      <TableTree
        columns={[Description, NameCell, Apps, Actions]}
        headers={[
          'Description',
          'Name (event or property)',
          'Tracked from',
          'Goodies',
        ]}
        columnWidths={['45%', '25%', '20%', '10%']}
        items={items}
      />

      <LoadingSpinner show={loading} />
    </Outer>
  )
}

export default MetricEventsList
