import { useMemo } from 'react'

import { useGetMessageTemplateQuery } from '../../../../graphql'
import { useMessageTemplateListCache } from '../useListMessageTemplates'
import { isNewTemplate } from '../utils'

const useMessageTemplate = (
  templateId: string,
  options?: Omit<Parameters<typeof useGetMessageTemplateQuery>[0], 'variables'>,
) => {
  const { findItem } = useMessageTemplateListCache()

  const cached = findItem(templateId)

  const { data, ...rest } = useGetMessageTemplateQuery({
    skip: isNewTemplate(cached),
    ...options,
    variables: {
      id: templateId,
    },
  })

  return useMemo(
    () => ({
      messageTemplate: data?.messageTemplate || cached,
      data,
      ...rest,
    }),
    [cached, data, rest],
  )
}

export default useMessageTemplate
