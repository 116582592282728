import Table from '@atlaskit/dynamic-table'
import { DateTime } from 'luxon'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { PricingSelection } from '../../graphql'
import Section from '../Section'

import { Notepad, PackageRow, Timestamp } from './styled'

function formatDate(date: null | undefined | string | Date): string {
  if (!date) {
    return ''
  }

  return DateTime.fromJSDate(new Date(date), {
    zone: 'America/New_York',
  }).toLocaleString({
    year: '2-digit',
    month: 'short',
    day: 'numeric',
  })
}

const TABLE_HEAD = {
  cells: [
    {
      content: 'Selected',
      shouldTruncate: false,
    },
    {
      content: 'Active',
      shouldTruncate: false,
    },
    {
      content: 'Expire',
      shouldTruncate: false,
    },
    {
      content: 'Package',
      shouldTruncate: true,
    },
  ],
}

type Props = {
  pricingSelections: PricingSelection[]
  loading?: boolean
}

const PricingPackageHistory = ({ pricingSelections, loading }: Props) => {
  const { pathname } = useLocation()

  if (pricingSelections.length === 0) {
    return null
  }

  return (
    <Section title={'Pricing History'}>
      <Table
        isLoading={loading}
        head={TABLE_HEAD}
        rows={pricingSelections.map(
          ({ selectedAt, activeAt, expireAt, pricing, notepad }) => ({
            key: String(selectedAt),
            cells: [
              {
                content: (
                  <Timestamp title={String(selectedAt)}>
                    {formatDate(selectedAt)}
                  </Timestamp>
                ),
              },
              {
                content: (
                  <Timestamp title={String(activeAt)}>
                    {formatDate(activeAt)}
                  </Timestamp>
                ),
              },
              {
                content: !!expireAt && (
                  <Timestamp title={expireAt && String(expireAt)}>
                    {formatDate(expireAt)}
                  </Timestamp>
                ),
              },
              {
                content: pricing ? (
                  <PackageRow to={`${pathname}/pricings/${pricing.id}`}>
                    <strong>{pricing.ref}</strong>
                    {pricing.priceDescription
                      ? ` - ${pricing.priceDescription}`
                      : ''}
                    <br />
                    {pricing.hoursDescription}
                    {!!notepad && (
                      <Notepad text={notepad} withQuote noCollapse />
                    )}
                  </PackageRow>
                ) : (
                  <PackageRow to={'#'}>{'Removed package'}</PackageRow>
                ),
              },
            ],
          }),
        )}
        emptyView={<span>{'No feedback sent yet'}</span>}
      />
    </Section>
  )
}

export default PricingPackageHistory
