import React, { ComponentProps, useMemo } from 'react'

import Badge, { Tint } from '../../../../../components/Badge'
import { TodoStatus } from '../../../../../graphql'

export interface Props extends Partial<ComponentProps<typeof Badge>> {
  status: TodoStatus
  isHidden?: boolean
}

const StatusBadge = ({ status, isHidden = false }: Props) => {
  const text = useMemo(() => status.replace('_', ' ') ?? '?', [status])

  const [emoji, tint] = useMemo(() => {
    switch (status) {
      case TodoStatus.DONE:
        return ['✅', 'green']
      case TodoStatus.IN_PROGRESS:
        return ['📝', 'yellow']
      case TodoStatus.READY:
        return ['🙋', 'teal']
      case TodoStatus.DRAFT:
        return ['💭', 'red']
      default:
        return ['', 'none']
    }
  }, [status])

  return (
    <>
      {isHidden && <Badge emoji={'🙈'} text={'Hidden'} isLight tint={'none'} />}
      <Badge emoji={emoji} text={text} isLight tint={tint as Tint} />
    </>
  )
}

export default StatusBadge
