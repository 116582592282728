import { Presence, SizeType, Status } from '@atlaskit/avatar'
import Lozenge from '@atlaskit/lozenge'
import { Classes } from '@blueprintjs/core'
import React, { ComponentProps } from 'react'
import styled, { css } from 'styled-components'

import {
  BasicUserFragment as _User,
  useMinimalUser,
  UserCategory,
} from '../../../graphql'
import { UserAvatarsGroup } from '../Avatar'

const Spacer = styled.div`
  flex: 1;
`

const Outer = styled.div<{ $striked?: boolean | null }>`
  display: flex;
  flex-flow: row;
  align-items: center;
  text-decoration: inherit;
  color: inherit;
  flex: 1;
  z-index: 0; // to fix an underlying high z-index
  ${({ $striked }) =>
    !!$striked &&
    css`
      text-decoration: line-through;
    `}
`

const Badges = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 8px;
  & > * + * {
    margin-top: 2px;
  }
`

const Name = styled.span<{ size?: SizeType }>`
  font-weight: 400;
  margin-left: 8px;

  :global(.active) > ${Outer} & {
    font-weight: 600;
  }

  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: smaller;
    `}

  ${({ size }) =>
    size === 'xsmall' &&
    css`
      font-weight: 500;
      font-size: x-small;
    `}
`
type User = Pick<_User, 'id'> &
  Partial<
    Pick<
      _User,
      // @ts-ignore
      'category' | 'isOnPause' | 'dsm' | 'isArchived' | 'hasPoolWorkspace'
    >
  > & {
    profile?: null | undefined | Partial<_User['profile']>
  }

export type UsersCellProps = ComponentProps<typeof Outer> & {
  skeleton?: boolean
  users: User[]
  size?: SizeType
  withCategoryBadge?: boolean
  withOnPauseBadge?: boolean
  withSuccessManagerAvatar?: boolean
  status?: ComponentProps<typeof Status>['status']
  presence?: ComponentProps<typeof Presence>['presence']
}

export type UserCellProps = Omit<UsersCellProps, 'users'> & {
  user?: User | null
}

const UserCell = ({ user, ...props }: UserCellProps) => (
  <UsersCell users={user ? [user] : []} {...props} />
)

export const UsersCell = ({
  users,
  skeleton,
  size = 'medium',
  withCategoryBadge = false,
  withSuccessManagerAvatar = false,
  withOnPauseBadge = false,
  status,
  presence,
  ...props
}: UsersCellProps) => {
  const firstUser = useMinimalUser(users[0]?.id)

  let label = `${users.length} users`
  const firstUserName = firstUser?.profile?.displayName
  const category = firstUser?.category

  if (firstUserName) {
    label = firstUserName + (users.length > 1 ? ` + ${users.length - 1}` : '')
  } else if (users.length === 1) {
    label = '-------------------'
  }

  const dsm = users.length === 1 && users[0]?.dsm
  const isArchived = users[0]?.isArchived
  const hasPoolWorkspace = users[0]?.hasPoolWorkspace || false

  return (
    <Outer title={label} $striked={!!isArchived} {...props}>
      <UserAvatarsGroup
        users={users}
        size={size}
        status={status}
        presence={presence}
      />
      <Name size={size} className={skeleton ? Classes.SKELETON : undefined}>
        {label}
      </Name>
      <Spacer />
      {withSuccessManagerAvatar && dsm && (
        // @ts-ignore
        <UserAvatarsGroup users={[dsm]} size={'small'} />
      )}
      {!!withCategoryBadge && hasPoolWorkspace && (
        <Lozenge appearance={'inprogress'}>{'🏊'}</Lozenge>
      )}
      <Badges>
        {!!withCategoryBadge && (
          <Lozenge
            appearance={
              category === UserCategory.SANDBOX
                ? 'default'
                : category === UserCategory.EXECUTIVE
                ? 'new'
                : category === UserCategory.ASSISTANT
                ? 'moved'
                : undefined
            }
            isBold={
              // @ts-ignore
              !!firstUser?.isOnPause ||
              // @ts-ignore
              (!!category && [UserCategory.TEAM].includes(category))
            }
          >
            {/* @ts-ignore */}
            {firstUser?.isOnPause ? 'on pause - ' : ''}
            {category === UserCategory.SANDBOX
              ? 'Demo'
              : category === UserCategory.TEAM
              ? 'HQ Team'
              : category === UserCategory.EXECUTIVE
              ? 'Exec'
              : category === UserCategory.ASSISTANT
              ? 'Asst'
              : ''}
          </Lozenge>
        )}
        {/* @ts-ignore */}
        {!!withOnPauseBadge && !withCategoryBadge && firstUser?.isOnPause && (
          <Lozenge appearance={'moved'} isBold>
            {'On Pause'}
          </Lozenge>
        )}
      </Badges>
    </Outer>
  )
}

const SKELETON_USER: User = {
  id: '',
  profile: {
    displayName: '---------------',
    avatarUrl: '',
    emails: [],
  },
  category: UserCategory.EXECUTIVE,
}

export const Skeleton = (props: any) => (
  <UserCell skeleton user={SKELETON_USER} {...props} />
)

export default UserCell
