import Tooltip from '@atlaskit/tooltip'
import { isNil } from 'lodash'
import React, { useMemo } from 'react'

import Badge, { Tint } from '../../../../../components/Badge'
import { CheckoutSessionLineItemFragment } from '../../../../../graphql'

type Props = {
  quantity: number
  unitAmount: number
  currency: string
}

const TokenCount = ({ quantity, unitAmount, currency }: Props) => {
  const formattedPrice = useMemo(() => {
    return Intl.NumberFormat(
      // use navigator.language to get the user's locale
      navigator.language,
      {
        style: 'currency',
        currency,
      },
    ).format((quantity * unitAmount) / 100)
  }, [currency, quantity, unitAmount])

  const tint = useMemo(() => {
    return quantity < 2
      ? 'purple'
      : quantity < 3
      ? 'blue'
      : quantity < 4
      ? 'teal'
      : quantity < 5
      ? 'green'
      : 'red'
  }, [quantity])

  return (
    <Tooltip content={formattedPrice}>
      <Badge text={quantity} isLight tint={tint as Tint} />
    </Tooltip>
  )
}

const TokenCountContainer = ({
  checkoutLineItem,
}: {
  checkoutLineItem?: CheckoutSessionLineItemFragment | null
}) => {
  if (isNil(checkoutLineItem) || isNil(checkoutLineItem.quantity)) return null

  if (
    isNil(checkoutLineItem.price) ||
    isNil(checkoutLineItem.price.unitAmount) ||
    isNil(checkoutLineItem.price.currency)
  ) {
    return <span>{`${checkoutLineItem.quantity}`}</span>
  }

  return (
    <TokenCount
      quantity={checkoutLineItem.quantity}
      unitAmount={checkoutLineItem.price.unitAmount}
      currency={checkoutLineItem.price.currency}
    />
  )
}

export default TokenCountContainer
