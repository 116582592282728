import GrantPoolTokens from './GrantPoolTokens'
import TokenBalance from './TokenBalance'
import { BalanceAndGrantOuter, Outer } from './styled'

type Props = {
  executiveId: string
  tokenBalance?: number
}
const Transactions = ({ executiveId, tokenBalance }: Props) => {
  return (
    <Outer>
      <BalanceAndGrantOuter>
        <TokenBalance balance={tokenBalance} />
        <GrantPoolTokens executiveId={executiveId} />
      </BalanceAndGrantOuter>
    </Outer>
  )
}

export default Transactions
