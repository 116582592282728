import ErrorBanner from '../../../../../../components/ErrorBanner'
import { LoadingSpinner } from '../../../../../../components/Spinner'

import { Table, Td, Tr } from './styled'

type SeriesItem = {
  category: string
  metrics: (string | number)[]
}

interface Props {
  loading?: boolean
  error?: Error
  months: string[]
  seriesMatrix?: SeriesItem[]
}

const HistoricalDataContainer = ({
  loading,
  error,
  months,
  seriesMatrix,
}: Props) => {
  if (loading) return <LoadingSpinner />
  if (error) return <ErrorBanner error={error} />

  const scores = seriesMatrix?.pop()?.metrics || []

  return (
    <Table cellSpacing={'0'} cellPadding={'0'}>
      <thead>
        <Tr>
          <Td empty />
          {months.map((month) => (
            <Td key={month} centered bold>
              {month}
            </Td>
          ))}
        </Tr>
      </thead>
      <tbody>
        {seriesMatrix &&
          seriesMatrix.map((series) => (
            <Tr key={series.category}>
              <Td bold>{series.category.replaceAll('_', ' ')}</Td>
              {series.metrics.map((metric, metricIndex) => (
                <Td key={`${metricIndex}-${metric}`} centered>
                  {metric}
                </Td>
              ))}
            </Tr>
          ))}
      </tbody>
      <tfoot>
        <Tr>
          <Td bold grey>
            {'COMPUTED SCORE'}
          </Td>
          {scores.map((score, i) => (
            <Td key={i} centered bold grey>
              {score}
            </Td>
          ))}
        </Tr>
      </tfoot>
    </Table>
  )
}

export default HistoricalDataContainer
