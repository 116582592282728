import React from 'react'

import Modal from '../../../components/Modal'
import { Field, TextField } from '../../../components/form'
import {
  Skill,
  sanitizeId,
  isValidSkill,
  sanitizeSkill,
} from '../../../components/skillset/utils'
import useValues from '../../../lib/useValues'

interface Props {
  show: boolean
  onHide: () => void
  onSubmit: (skill: Skill) => void
  isSubmitting: boolean
}

const CreateModal = ({ show, onHide, onSubmit, isSubmitting }: Props) => {
  const [formValues, { reset, patch }] = useValues<Skill>({
    id: '',
    label: '',
  })

  return (
    <Modal
      isOpen={show}
      onClose={() => onHide?.()}
      onCloseComplete={reset}
      width={'small'}
      heading={'Add new skill'}
      autoFocus
      actions={[
        {
          text: 'Create',
          onClick: () => onSubmit(sanitizeSkill(formValues)),
          isDisabled: !isValidSkill(formValues),
          isLoading: isSubmitting,
        },
        { text: 'Cancel', onClick: onHide },
      ]}
    >
      <Field label={'Label'} isRequired>
        <TextField
          placeholder={'e.g. Travel planning'}
          value={formValues.label}
          onChangeValue={(label) => patch({ label, id: sanitizeId(label) })}
        />
      </Field>

      <Field
        label={'Identifier'}
        isRequired
        helperMessage={'For internal reference'}
      >
        <TextField
          placeholder={''}
          value={formValues.id}
          onChangeValue={(id) => patch({ id })}
        />
      </Field>
    </Modal>
  )
}

export default CreateModal
