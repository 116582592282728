import React, { useEffect } from 'react'

import MarkdownEditor from '../../../../../components/MarkdownEditor'
import { Field } from '../../../../../components/form'
import type { TextFieldsValues } from '../../../../../components/messages/types'
import { Outer } from '../../components/styled'

type TextFieldsProps = {
  values: TextFieldsValues
  onPatch: (newValues: Partial<TextFieldsValues>) => void | Promise<void>
  onValidity: (isValid: boolean) => void | Promise<void>
}

const TextFields = ({ values, onPatch, onValidity }: TextFieldsProps) => {
  useEffect(() => {
    onValidity(!!values?.text?.trim())
  }, [values, onValidity])

  return (
    <Outer>
      <Field label={'Text'} isRequired>
        <MarkdownEditor
          isInvalid={!values.text?.trim()}
          value={values.text}
          onChange={(text) => onPatch({ text })}
          minHeight={90}
        />
      </Field>
    </Outer>
  )
}

export default TextFields
