import Icon from '@atlaskit/icon/glyph/jira/test-session'
import React from 'react'
import { useRouteMatch, Redirect } from 'react-router-dom'
import styled from 'styled-components'

import { ErrorEmptyState } from '../../../components/EmptyState'
import { LoadingSpinner } from '../../../components/Spinner'
import { useListBugReportsQuery } from '../../../graphql'
import { Page } from '../../types'

import BugReport from './BugReport'
import List from './List'

const Outer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  height: 100%;
  min-width: 600px;
  max-width: 1000px;
`

const BugReports: Page = () => {
  const { data, loading, error } = useListBugReportsQuery()
  const { url } = useRouteMatch()
  const {
    params: { bugReportId },
  } = useRouteMatch<{ bugReportId: string }>(`${url}/:bugReportId`) || {
    params: {},
  }

  if (error) {
    return <ErrorEmptyState error={error} />
  }

  if (loading || !data) {
    return <LoadingSpinner />
  }

  const bugReport = bugReportId
    ? data.list.items.find(({ id }) => id === bugReportId)
    : undefined
  if (bugReportId && !bugReport) {
    return <Redirect to={url} />
  }

  return (
    <Outer>
      <List bugReports={data.list.items} selectedBugReportId={bugReportId} />
      <BugReport bugReport={bugReport} />
    </Outer>
  )
}

BugReports.path = '/bugReports'
BugReports.navTitle = 'Bug Reports'
BugReports.Icon = Icon
BugReports.requiredOperationIds = ['Query.bugReportsList']

export default BugReports
