import Button from '@atlaskit/button'
import LockIcon from '@atlaskit/icon/glyph/lock-filled'
import { colors, borderRadius, typography } from '@atlaskit/theme'
import React from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import styled, { css } from 'styled-components'

export const ListOuter = styled.div`
  padding: 0 16px;
  overflow: auto;
  border-right: 1px solid ${colors.backgroundHover};
  background-color: ${colors.N20};
`

export const NarrowListOuter = styled(ListOuter)`
  width: 30%;
`
export const WideListOuter = styled(ListOuter)`
  width: 40%;
`

export const EditButton = styled(Button)`
  opacity: 0;
`

export const BoxOuter = styled.div<{ isSelected?: boolean }>`
  border-radius: ${borderRadius}px;
  border: 2px solid ${colors.backgroundHover};
  padding: 0 8px 8px;
  margin-bottom: 8px;
  background: white;
  min-width: 200px;

  ${({ isSelected }) =>
    !!isSelected &&
    css`
      border-color: ${colors.primary};
    `}

  &:hover ${EditButton} {
    opacity: 1;
  }
`

export const BoxCompactInner = styled.div`
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const BoxCompactTexts = styled.div`
  display: flex;
  flex-direction: column;
`

export const BoxCompactLabel = styled.h3`
  ${typography.h400()}
  margin: 0;
  cursor: default;
`

export const BoxCompactDescription = styled.p`
  font-size: x-small;
  color: ${colors.subtleHeading};
  margin: 0;
  cursor: default;
`

export const DragHandle = SortableHandle(styled.div<{ isDisabled?: boolean }>`
  flex: 0 0 auto;
  margin-right: 4px;
  cursor: grab;

  [draggable='true'] & {
    cursor: grabbing;
  }

  ${({ isDisabled }) =>
    !!isDisabled &&
    css`
      visibility: hidden;
    `}
`)

export const Spacer = styled.div`
  flex: 1;
`

export const ToggleOuter = styled.div`
  display: flex;
  align-items: center;
`

export const Buttons = styled.div`
  padding: 24px 4px 4px;
  display: flex;
  & > * + * {
    margin-left: 8px;
  }
`

export const Lock = () => (
  <LockIcon label={''} size={'small'} primaryColor={colors.subtleHeading()} />
)

export const FieldOption = styled.div`
  display: flex;

  & > * + * {
    margin-left: 8px;
  }

  & + & {
    margin-top: 8px;
  }
`
