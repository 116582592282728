import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import PricingSelect from '../../../components/PricingSelect'
import { Field, Select } from '../../../components/form'

import {
  ReportsQuery,
  getZonedDateTimeFromJSDate,
  getZonedDateTimeFromDateString,
} from './utils'

const Outer = styled.div``

const getSelectOption = (d: DateTime) => ({
  value: d.toISODate(),
  label: d.toLocaleString({ year: 'numeric', month: 'long' }),
})

const MONTH_OPTIONS = [-1, 0, 1, 2].map((deltaMonths) => {
  const date = DateTime.now()
    .setZone('America/New_York')
    .minus({ months: deltaMonths })
    .startOf('month')
  return getSelectOption(date)
})

type Props = {
  query?: Partial<ReportsQuery>
  onChange: (newQuery: Partial<ReportsQuery>) => void
}

const QueryEditor = ({ query, onChange }: Props) => {
  const onSelectMonth = useCallback(
    (fromDateStr: string) => {
      const date = getZonedDateTimeFromDateString(fromDateStr)
      onChange({
        ...query,
        from: date.toJSDate(),
        to: date.endOf('month').toJSDate(),
      })
    },
    [onChange, query],
  )

  const helperMessage = useMemo(() => {
    if (!(query?.from && query?.to)) {
      return undefined
    }

    return [
      'From',
      getZonedDateTimeFromJSDate(query.from).toLocaleString({
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'short',
      }),
      'to',
      getZonedDateTimeFromJSDate(query.to).toLocaleString({
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'short',
      }),
    ].join(' ')
  }, [query])

  return (
    <Outer>
      <Field label={'Billing Period'} helperMessage={helperMessage}>
        <Select
          options={MONTH_OPTIONS}
          value={
            query?.from
              ? getSelectOption(getZonedDateTimeFromJSDate(query.from))
              : undefined
          }
          placeholder={'Select month'}
          onChangeValue={onSelectMonth}
          validationState={!query?.from ? 'error' : 'default'}
        />
      </Field>
      <Field
        label={'Default pricing package'}
        helperMessage={'Used for clients with no package selected'}
      >
        <PricingSelect
          value={query?.defaultPricing}
          onChangeValue={(defaultPricing) =>
            onChange({
              ...query,
              defaultPricing,
            })
          }
          validationState={!query?.defaultPricing ? 'error' : 'default'}
        />
      </Field>
    </Outer>
  )
}

export default QueryEditor
