import Icon from '@atlaskit/icon/glyph/bitbucket/builds'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { ErrorEmptyState } from '../../../components/EmptyState'
import { LoadingSpinner } from '../../../components/Spinner'
import { useListAutomationsQuery, withListPagination } from '../../../graphql'
import { Page } from '../../types'

import Automation from './Automation'
import List from './List'

const Outer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  min-width: 600px;
  max-width: 1000px;
  height: 100%;
`

const Automations: Page = () => {
  const { data, loading, error } = withListPagination(useListAutomationsQuery())
  const { url } = useRouteMatch()
  const {
    params: { automationId },
  } = useRouteMatch<{ automationId: string }>(`${url}/:automationId`) || {
    params: {},
  }

  if (error) {
    return <ErrorEmptyState error={error} />
  }

  if (loading || !data) {
    return <LoadingSpinner />
  }

  return (
    <Outer>
      <List automations={data.list.items} />
      <Automation
        automation={
          automationId
            ? data.list.items.find(({ id }) => id === automationId)
            : undefined
        }
      />
    </Outer>
  )
}

Automations.path = '/automations'
Automations.navTitle = 'Automations 🚧'
Automations.Icon = Icon
Automations.requiredOperationIds = ['Query.automationsList']

export default Automations
