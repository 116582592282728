import Icon from '@atlaskit/icon/glyph/graph-line'
import React from 'react'
import styled from 'styled-components'

import { Page } from '../../types'

import List from './List'

const Outer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  min-width: 600px;
  max-width: 100%;
  height: 100%;
`

const MetricEvents: Page = () => {
  return (
    <Outer>
      <List />
    </Outer>
  )
}

MetricEvents.path = '/metric-events'
MetricEvents.navTitle = 'Metric Events'
MetricEvents.Icon = Icon
MetricEvents.requiredOperationIds = ['Query.metricEventsList']

export default MetricEvents
