import React from 'react'

import Acl from './Acl'
import EaTeamLead from './EaTeamLead'
import OnlyIfAdminRoles from './OnlyIfAdminRoles'
import ScheduleOnboardingConfig from './ScheduleOnboardingConfig'

type Props = {
  userId: string
}

const Admin = ({ userId }: Props) => {
  return (
    <div>
      <OnlyIfAdminRoles userId={userId} roles={['EA_TEAM_LEAD']}>
        <EaTeamLead userId={userId} />
      </OnlyIfAdminRoles>

      <OnlyIfAdminRoles
        userId={userId}
        roles={['ONBOARDING_SPECIALIST', 'SUCCESS_SPECIALIST']}
      >
        <ScheduleOnboardingConfig userId={userId} />
      </OnlyIfAdminRoles>

      <Acl userId={userId} />
    </div>
  )
}

export default Admin
