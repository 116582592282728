import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'

import { LoadingSpinner } from '../../../components/Spinner'
import Tabs from '../../../components/Tabs'
import { FullUserFragment, useGetWorkspaceQuery } from '../../../graphql'
import { notFalsy } from '../../../lib/utils'
import AutomationJobsInline from '../../autopilot/Jobs/ListInline'
import Header from '../User/Header'

import DelegationPath from './DelegationPath'
import Feedbacks from './Feedbacks'
import Reporting from './Reporting'
import Settings from './Settings'
import Transactions from './Transactions'
import Users from './Users'

const Outer = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: stretch;
  max-width: 600px;
`

const Content = styled.div`
  padding: 0 30px;
  display: flex;
  flex-flow: row;
  overflow: auto;
  flex: 1;
`

const TabContent = styled.div<{ row?: boolean }>`
  overflow: auto;
  padding: 0 0 24px;
  flex: 1;
`

type Props = RouteComponentProps<{ workspaceId: string }>

const Workspace = ({
  match: {
    params: { workspaceId },
  },
}: Props) => {
  const { data: workspaceData, loading } = useGetWorkspaceQuery({
    variables: { workspaceId },
  })

  const workspace = workspaceData?.workspace

  const tabs = [
    {
      label: 'Users',
      content: (
        <TabContent>
          <Users workspaceId={workspaceId} />
        </TabContent>
      ),
    },
    !workspace?.isPool && {
      label: 'CSAT',
      content: (
        <TabContent>
          {workspace && (
            <Feedbacks
              workspaceId={workspaceId}
              executiveId={workspace.executives[0].id}
            />
          )}
        </TabContent>
      ),
    },
    {
      label: 'Reporting',
      content: (
        <TabContent>
          <Reporting workspaceId={workspaceId} />
        </TabContent>
      ),
    },
    {
      label: 'Settings',
      content: (
        <TabContent>
          <Settings workspaceId={workspaceId} />
        </TabContent>
      ),
    },
    !workspace?.isPool && {
      label: 'Delegation Path',
      content: (
        <TabContent>
          {workspace && <DelegationPath workspace={workspace} />}
        </TabContent>
      ),
    },
    !!workspace?.isPool &&
      workspace.executives[0]?.id && {
        label: 'Transactions',
        content: (
          <TabContent>
            <Transactions
              executiveId={workspace.executives[0].id}
              tokenBalance={workspace.poolTokenBalance?.availablePoolTokenCount}
            />
          </TabContent>
        ),
      },
  ].filter(notFalsy)

  const rightTabs = [
    {
      label: '🤖',
      slug: 'autopilot',
      content: (
        <TabContent>
          <AutomationJobsInline
            filter={{
              workspaceId: workspace?.id,
            }}
          />
        </TabContent>
      ),
    },
  ]

  return (
    <Outer>
      <Header user={workspace?.executives[0] as FullUserFragment} isDisabled />

      <Content>{<Tabs leftTabs={tabs} rightTabs={rightTabs} />}</Content>

      <LoadingSpinner show={loading} />
    </Outer>
  )
}

export default Workspace
