import React, {
  useState,
  useCallback,
  useMemo,
  ComponentProps,
  useEffect,
} from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import {
  CreateHqInput,
  CityFragment,
  useCreateHqMutation,
  LeverOpportunityFragment,
} from '../../graphql'
import useSwitch from '../../lib/useSwitch'
import useValues from '../../lib/useValues'
import CitySelect from '../CitySelect'
import LeverOpportunitySelect from '../LeverOpportunitySelect'
import Modal from '../Modal'
import { Field, TextField, FieldsRow } from '../form'

const EMAIL_DOMAIN = '@withdouble.com'
const MONTH_IN_MS = 31 * 24 * 3_600_000
const HIRED_AFTER = new Date(Date.now() - 2 * MONTH_IN_MS)

const EmailDecorator = styled.span`
  padding: 0 32px;
`

type Props = ComponentProps<typeof Modal>

const CreateModal = ({ ...modalProps }: Props) => {
  const history = useHistory()

  const [shouldGenerateEmail, enableEmailGeneration, disableEmailGeneration] =
    useSwitch(true)
  const [cityLookup, setCityLookup] = useState<string | null>(null)
  const [city, setCity] = useState<CityFragment | null>(null)
  const [formValues, { reset: resetForm, setters }] = useValues<CreateHqInput>(
    {
      givenName: '',
      familyName: '',
      email: '',
      position: undefined,
      leverId: undefined,
      cityPlaceId: undefined,
    },
    ['givenName', 'familyName', 'email', 'leverId', 'cityPlaceId', 'position'],
  )

  const reset = useCallback(() => {
    resetForm()
    setCity(null)
    enableEmailGeneration()
  }, [enableEmailGeneration, resetForm])

  const [createHq, { loading: loadingCreateHq }] = useCreateHqMutation({
    onCompleted({ user: { id } }) {
      // @ts-ignore
      modalProps.onClose?.()
      history.push(`/users/${id}/infos`)
    },
  })

  const onChangeCity = useCallback(
    (city: CityFragment | null) => {
      setCityLookup(null)
      setCity(city)
      setters.cityPlaceId?.(city?.googleMapsPlaceId)
    },
    [setters],
  )

  const onChangeEmail = useCallback(
    (email: string) => {
      setters.email(email.split('@')[0].toLowerCase() + EMAIL_DOMAIN)
      disableEmailGeneration()
    },
    [disableEmailGeneration, setters],
  )

  const onSelectLeverOpportunity = useCallback(
    (opp: LeverOpportunityFragment | null) => {
      if (opp) {
        // Set lever ID
        setters.leverId?.(opp.id)
        // Try to auto-fill names
        if (!(formValues.givenName || formValues.familyName)) {
          const [givenName, familyName, ...others] = opp.displayName
            .trim()
            .split(/\s+/g)
          if (!others.length) {
            setters.givenName?.(givenName)
            setters.familyName?.(familyName)
          }
        }
        // Auto-fill city
        if (!formValues.cityPlaceId && opp.location) {
          setCityLookup(opp.location)
        }
        // Auto-fill position
        if (!formValues.position && opp.postingTitle) {
          setters.position?.(opp.postingTitle)
        }
      }
    },
    [
      formValues.cityPlaceId,
      formValues.familyName,
      formValues.givenName,
      formValues.position,
      setters,
    ],
  )

  // Auto-generate email username based on name
  useEffect(() => {
    if (shouldGenerateEmail) {
      const username = [formValues.givenName]
        .map((name) =>
          name
            ?.toLowerCase()
            .trim()
            .replace(/[^a-z]/g, ''),
        )
        .filter(Boolean)
        .join('.')
      if (username) {
        const email = username + EMAIL_DOMAIN
        if (email !== formValues.email) {
          setters.email(email)
        }
      }
    }
  }, [
    formValues.email,
    formValues.familyName,
    formValues.givenName,
    setters,
    shouldGenerateEmail,
  ])

  const onSubmit = useCallback(() => {
    createHq({
      variables: { input: formValues },
    })
  }, [createHq, formValues])

  const isValidForm = useMemo(() => {
    if (
      !(
        formValues.givenName?.trim() &&
        formValues.familyName?.trim() &&
        formValues.email?.trim().endsWith(EMAIL_DOMAIN)
      )
    ) {
      return false
    }

    return true
  }, [formValues.email, formValues.familyName, formValues.givenName])

  return (
    <Modal
      onCloseComplete={reset}
      width={'small'}
      heading={'Create Double HQ team member 🎉'}
      autoFocus
      actions={[
        {
          text: 'Create',
          onClick: onSubmit,
          isDisabled: !isValidForm,
          isLoading: loadingCreateHq,
        },
        { text: 'Cancel', onClick: modalProps.onClose },
      ]}
      {...modalProps}
    >
      <Field label={'Import from Lever'}>
        <LeverOpportunitySelect
          hiredAfter={HIRED_AFTER}
          isUser={false}
          includePostingTitle
          valueId={formValues.leverId}
          onChangeValue={onSelectLeverOpportunity}
        />
      </Field>

      <Field label={'Name'} isRequired>
        <FieldsRow>
          <TextField
            placeholder={'Given name'}
            value={formValues.givenName}
            onChangeValue={setters.givenName}
            isInvalid={!formValues.givenName?.trim()}
          />
          <TextField
            placeholder={'Family name'}
            value={formValues.familyName || ''}
            onChangeValue={setters.familyName}
            isInvalid={!formValues.familyName?.trim()}
          />
        </FieldsRow>
      </Field>

      <Field label={'Double email'} isRequired>
        <TextField
          type={'email'}
          placeholder={'james.doe'}
          value={formValues.email.split('@')[0]}
          onChangeValue={onChangeEmail}
          isInvalid={!formValues.email.trim()}
          elemAfterInput={<EmailDecorator>{EMAIL_DOMAIN}</EmailDecorator>}
        />
      </Field>

      <Field label={'Position'}>
        <TextField
          placeholder={'Chief Awesomeness Officer'}
          value={formValues.position || ''}
          onChangeValue={setters.position}
        />
      </Field>

      <Field label={'City'}>
        <CitySelect
          lookup={cityLookup}
          value={city}
          onChangeValue={onChangeCity}
          menuPlacement={'top'}
        />
      </Field>
    </Modal>
  )
}

export default CreateModal
