import { RadioSelect, SelectProps } from '@atlaskit/select'
import React, { useCallback, useMemo } from 'react'

import { TransitionMatchingStatus } from '../../../../../graphql'
import { MATCHING_STATUS_LABEL } from '../../utils'

type Option = { value: TransitionMatchingStatus; label: string }

const OPTIONS: Option[] = [
  TransitionMatchingStatus.HOLD,
  TransitionMatchingStatus.READY_TO_MATCH,
  TransitionMatchingStatus.MATCH_PROPOSED,
  TransitionMatchingStatus.MATCH_CONFIRMED,
].map((value) => ({
  value,
  label: MATCHING_STATUS_LABEL[value],
}))

type Props = Omit<SelectProps<Option>, 'options'> & {
  value?: TransitionMatchingStatus | undefined | null
  onChangeValue?: (
    newValue: TransitionMatchingStatus | undefined | null,
  ) => void
}

const TransitionMatchingStatusSelect = ({
  value: _value,
  onChangeValue,
  onChange: _onChange,
  ...selectProps
}: Props) => {
  const value = useMemo(() => {
    if (_value) {
      return OPTIONS.find(({ value }) => _value === value)
    }
  }, [_value])

  const onChange = useCallback(
    (newValue: Option) => {
      _onChange?.(newValue)
      onChangeValue?.(newValue?.value)
    },
    [_onChange, onChangeValue],
  )

  return (
    <RadioSelect
      options={OPTIONS}
      value={value}
      // @ts-ignore
      onChange={onChange}
      placeholder={'Current status'}
      isClearable={false}
      isSearchable={false}
      isOptionDisabled={(option) =>
        _value !== TransitionMatchingStatus.HOLD ||
        option.value !== TransitionMatchingStatus.READY_TO_MATCH
      }
      {...selectProps}
    />
  )
}

export default TransitionMatchingStatusSelect
