import Icon from '@atlaskit/icon/glyph/calendar'
import React from 'react'

import { useGetOnboardingCallAvailabilityQuery } from '../../graphql'
import useSwitch from '../../lib/useSwitch'

import OnboardingAvailabilityButton, {
  OnboardingAvailabilityButtonProps,
} from './OnboardingAvailabilityButton'

type Props = Partial<OnboardingAvailabilityButtonProps> & {
  specialistId?: string | undefined | null
  timeZone?: string | undefined | null
  assistantId: string
}

const PreviewOnboardingCallAvailabilityButton = ({
  assistantId,
  specialistId,
  timeZone,
  ...buttonProps
}: Props) => {
  const [isOpen, open, close] = useSwitch(false)

  const { data, loading, error } = useGetOnboardingCallAvailabilityQuery({
    variables: {
      assistantId,
      specialistId,
      timeZone,
    },
  })

  const count = data?.onboardingCallAvailability.slots?.length || 0

  return (
    <OnboardingAvailabilityButton
      isOpen={isOpen}
      open={open}
      close={close}
      slots={data?.onboardingCallAvailability.slots}
      error={error}
      isLoading={loading}
      iconBefore={<Icon label={''} />}
      children={loading ? undefined : count}
      appearance={
        loading
          ? 'subtle'
          : count < 3
          ? 'danger'
          : count < 6
          ? 'warning'
          : 'default'
      }
      {...buttonProps}
    />
  )
}

export default PreviewOnboardingCallAvailabilityButton
