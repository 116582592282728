import { DateTime } from 'luxon'

import Badge from '../../../../components/Badge'

type Props = {
  date: string
}

const DueDateBadge = ({ date }: Props) => {
  const dateTime = DateTime.fromISO(date)
  const formatedDate = dateTime.toLocaleString({
    day: 'numeric',
    month: 'short',
  })
  const dateDiff = dateTime.diffNow().as('days')
  const isPast = dateDiff < 0
  // is date in the next 7 days
  const isSoon = dateDiff < 7
  return (
    <Badge
      text={formatedDate}
      title={'Internal Matching Date'}
      tint={isPast ? 'red' : isSoon ? 'yellow' : undefined}
    />
  )
}

export default DueDateBadge
