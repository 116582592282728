import { LoadingButton as Button } from '@atlaskit/button'
import Drawer from '@atlaskit/drawer'
import InlineMessage from '@atlaskit/inline-message'
import { markdownToHtml } from '@withdouble/markdown'
import React, { ComponentProps, useCallback, useState } from 'react'
import styled from 'styled-components'

import {
  useSendEmailMessageMutation,
  namedOperations,
  useGetMessageTemplateQuery,
  onlyIfEmailMessageTemplate,
} from '../../../graphql'
import useSwitch from '../../../lib/useSwitch'
import useValues from '../../../lib/useValues'
import { notFalsy } from '../../../lib/utils'
import { replaceVarsInString } from '../../utils'
import EmailActivity from '../EmailActivity'
import Preview from '../SendgridEmailComposer/Preview'

import Editor from './Editor'
import { Email } from './types'
import { missingVars, isValidEmail } from './utils'

const Outer = styled.div`
  display: flex;
  flex-direction: row;
`

const Spacer = styled.div`
  flex: 0 0 16px;
`

const PreviewOuter = styled.div`
  flex: 0 1 35%;
  min-width: 400px;
`

const Buttons = styled.div`
  padding: 16px 4px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  & > * + * {
    margin-right: 8px;
  }
`

type Props = {
  messageTemplateIds: string[]
  workspaceId?: string
  executiveId?: string
  assistantId?: string
  toUserId?: string
  email?: Partial<Email>
  onCancel?: () => void
  onSendComplete?: (template: { id: string }) => void
}

const EmailComposer = ({
  messageTemplateIds,
  email: defaultEmail,
  workspaceId,
  assistantId,
  executiveId,
  toUserId,
  onCancel,
  onSendComplete,
}: Props) => {
  const [messageTemplateId, setMessageTemplateId] = useState<string>()
  const [languageCode, setLanguageCode] = useState<string>()
  const [looksGood, setLooksGood] = useSwitch(false)
  const [isSent, setIsSent] = useSwitch(false)
  const [email, { patch, set }] = useValues<Partial<Email>>({
    vars: {},
    ...defaultEmail,
  })

  const { data: templateData } = useGetMessageTemplateQuery({
    variables: { id: messageTemplateId || '' },
    skip: !messageTemplateId,
  })
  const messageTemplate = onlyIfEmailMessageTemplate(
    messageTemplateId && templateData?.messageTemplate.id === messageTemplateId
      ? templateData?.messageTemplate
      : null,
  )

  const [sendEmail, { loading: loadingSend, error: errorSend }] =
    useSendEmailMessageMutation({
      refetchQueries: [namedOperations.Query.GetEmailActivities],
    })

  const send = useCallback(async () => {
    if (
      messageTemplate &&
      languageCode &&
      isValidEmail(messageTemplate, languageCode, email)
    ) {
      const { vars, ...fields } = email
      const result = await sendEmail({
        variables: {
          input: {
            messageTemplateId: messageTemplate.id,
            languageCode,
            fields: {
              ...fields,
              tags: [
                workspaceId && `workspaceId:${workspaceId}`,
                toUserId && `userId:${toUserId}`,
                ...(fields.tags || []),
              ].filter(notFalsy),
            },
            vars,
          },
        },
      })
      setIsSent()
      onSendComplete?.(messageTemplate)

      return result
    }
  }, [
    email,
    sendEmail,
    onSendComplete,
    setIsSent,
    workspaceId,
    toUserId,
    languageCode,
    messageTemplate,
  ])

  return (
    <Outer>
      <Editor
        messageTemplateId={messageTemplateId}
        onSelectMessageTemplateId={setMessageTemplateId}
        languageCode={languageCode}
        onSelectLanguageCode={setLanguageCode}
        email={email}
        messageTemplateIds={messageTemplateIds}
        workspaceId={workspaceId}
        executiveId={executiveId}
        assistantId={assistantId}
        toUserId={toUserId}
        onPatch={patch}
        onReplace={set}
      />
      <Spacer />
      {email.layoutId && (
        <PreviewOuter>
          <Preview
            // @ts-ignore
            email={{
              ...email,
              vars: {
                SUBJECT: replaceVarsInString(
                  email.vars || {},
                  email.subject || '',
                  true,
                ),
                PREVIEW: replaceVarsInString(
                  email.vars || {},
                  email.preview || '',
                  true,
                ),
                BODY: markdownToHtml(
                  replaceVarsInString(email.vars || {}, email.body || '', true),
                  { noEscape: true },
                ),
              },
              templateId: email.layoutId,
            }}
          />
          <Buttons>
            <Button
              isDisabled={isSent || !looksGood}
              appearance={'primary'}
              onClick={send}
              isLoading={loadingSend}
            >
              {'Send'}
            </Button>
            <Button
              isDisabled={looksGood}
              appearance={!looksGood ? 'primary' : 'default'}
              onClick={setLooksGood}
            >
              {'Looks good!'}
            </Button>
            {!!onCancel && (
              <Button onClick={onCancel}>{isSent ? 'Close' : 'Cancel'}</Button>
            )}
            {!!(
              messageTemplate &&
              languageCode &&
              missingVars(messageTemplate, languageCode, email).length > 0
            ) && (
              <InlineMessage title={'Missing variables'} type={'warning'}>
                {missingVars(messageTemplate, languageCode, email).join(', ')}
              </InlineMessage>
            )}
            {errorSend && (
              <InlineMessage title={'Error sending email'} type={'error'}>
                {errorSend.message}
              </InlineMessage>
            )}
            {isSent && (
              <InlineMessage
                title={'Email was successfully sent 👍'}
                type={'confirmation'}
              >
                {'🎉 🏖 🎯 🙌 🥳'}
              </InlineMessage>
            )}
          </Buttons>
        </PreviewOuter>
      )}

      {/* <LoadingSpinner show={loadingSend} /> */}
    </Outer>
  )
}

const DrawerInner = styled.div`
  padding: 0 24px 0 8px;
`

type DrawerProps = ComponentProps<typeof EmailComposer> & {
  isOpen?: boolean
  onClose?: () => void
  onOpenComplete?: () => void
  onCloseComplete?: () => void
}

export const EmailComposerDrawer = ({
  isOpen,
  onClose,
  onOpenComplete,
  onCloseComplete,
  ...props
}: DrawerProps) => (
  <Drawer
    isOpen={!!isOpen}
    onClose={onClose}
    onOpenComplete={onOpenComplete}
    onCloseComplete={onCloseComplete}
    width={'extended'}
  >
    <DrawerInner>
      <EmailComposer onCancel={onClose} {...props} />
    </DrawerInner>
  </Drawer>
)

type EmailComposerButtonProps = {
  noActivity?: boolean
  buttonProps: ComponentProps<typeof Button>
  activityProps?: Omit<ComponentProps<typeof EmailActivity>, 'templateIds'>
  composerProps: ComponentProps<typeof EmailComposer>
}

export const EmailComposerButton = ({
  noActivity,
  buttonProps: _buttonProps,
  activityProps,
  composerProps,
}: EmailComposerButtonProps) => {
  const [isComposerOpen, openComposer, closeComposer] = useSwitch(false)

  const { isLoading, ...buttonProps } = { ..._buttonProps }

  return (
    <>
      <Button
        appearance={'default'}
        isLoading={isComposerOpen || isLoading}
        {...buttonProps}
        onClick={openComposer}
      />
      {!noActivity && (
        <EmailActivity
          messageTemplateIds={composerProps.messageTemplateIds}
          {...activityProps}
        />
      )}
      <EmailComposerDrawer
        isOpen={isComposerOpen}
        onClose={closeComposer}
        {...composerProps}
      />
    </>
  )
}

export default EmailComposer
