import React from 'react'

import Badge from '../../../../components/Badge'
import { Matching_ProposalsReplyFragment } from '../../../../graphql'

type Props = {
  matching: Matching_ProposalsReplyFragment | null
  noText?: boolean
}

const InstantInviteBadge = ({ matching, noText }: Props) => {
  const proposals = matching?.proposals || []
  if (!proposals) {
    return null
  }

  const instantInviteProposal = proposals.filter(
    (proposal) => proposal.isInstantInvite,
  )[0]
  if (!instantInviteProposal) {
    return null
  }

  const reply = instantInviteProposal.reply

  return (
    <Badge
      emoji={'⚡️'}
      title={'Instant Invite'}
      text={noText ? undefined : 'Instant Invite'}
      tint={
        reply === 'YES'
          ? 'green'
          : reply === 'NO'
          ? 'red'
          : reply === 'NEED_MORE_INFO'
          ? 'yellow'
          : 'purple'
      }
    />
  )
}

export default InstantInviteBadge
