import React from 'react'

import { Matching_ForTransitionListItemFragment } from '../../../../../../graphql'
import { INFORMING_STATUS_LABEL } from '../../../utils'

import StageButton from './components/StageButton'

type Props = {
  matching: Matching_ForTransitionListItemFragment
}

const Informing = ({ matching }: Props) => {
  return (
    <StageButton matching={matching} stageId={'informing'}>
      {INFORMING_STATUS_LABEL[matching.transition!.informingStatus]}
    </StageButton>
  )
}

export default Informing
