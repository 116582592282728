import { colors } from '@atlaskit/theme'
import { NavLink as _NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Outer = styled.div`
  background-color: ${colors.N20};
  display: flex;
  flex-direction: column;
  padding: 16px;
  flex: 0 2 300px;
  min-width: 230px;
  box-shadow: 0 0 0 2px #ebecf0;
  overflow-y: auto;
`

export const NavLink = styled(_NavLink)<{
  $isIndented?: boolean
  $striked?: boolean | null
}>`
  ${({ $isIndented }) => !!$isIndented && `padding-left: 24px;`}
  ${({ $striked }) => !!$striked && `text-decoration: line-through;`}
`
