// @ts-ignore
import { Group } from '@atlaskit/navigation-next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { ErrorEmptyState } from '../../../components/EmptyState'
import PageHeader from '../../../components/PageHeader'
import { LoadingSpinner } from '../../../components/Spinner'
import ItemWithNavlink from '../../../components/list/ItemWithNavlink'
import ListContainer from '../../../components/list/ListContainer'
import ListOuter from '../../../components/list/ListOuter'
import PageHeaderOuter from '../../../components/list/PageHeaderOuter'
import { MessageTemplate_LabelsFragment } from '../../../graphql'
import useValues from '../../../lib/useValues'
import { group, getSectionedName } from '../utils'

import useListMessageTemplates, {
  useMessageTemplateListCache,
} from './useListMessageTemplates'
import {
  getKindEmoji,
  MessageTemplateTypename,
  NEW_TEMPLATE_TITLE,
} from './utils'

const Outer = styled(ListContainer)`
  min-width: 280px;
  max-width: 400px;
`

const MessageTemplatesList = () => {
  const { upsertItem: upsertLocalTemplate } = useMessageTemplateListCache()
  const navHistory = useHistory()
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>()
  const [filters] = useValues<{
    kind: MessageTemplate_LabelsFragment['__typename'] | null
  }>({ kind: null }, ['kind'])

  const { data, loading, error, fetchMore } = useListMessageTemplates(
    searchTerm
      ? {
          q: searchTerm,
        }
      : undefined,
  )

  // 💩 But since this component displays a grouped list, an infinite list would
  // not work. We fetch all items.
  useEffect(() => {
    if (data?.list?.after) {
      fetchMore()
    }
  }, [data, fetchMore])

  const createMessageTemplate = useCallback(
    (__typename: MessageTemplateTypename) => {
      const id = uuid()
      const newMessageTemplate: Required<MessageTemplate_LabelsFragment> = {
        __typename,
        id,
        name: NEW_TEMPLATE_TITLE,
        description: '',
      }

      upsertLocalTemplate(newMessageTemplate)

      navHistory.push(
        `/message-templates/${newMessageTemplate.id}/configuration`,
      )
    },
    [navHistory, upsertLocalTemplate],
  )

  const groups = useMemo(
    () =>
      group(
        (data?.list.items || []).filter(
          ({ __typename }) => !filters.kind || filters.kind === __typename,
        ),
      ),
    [data, filters],
  )

  return (
    <Outer>
      <PageHeaderOuter>
        <PageHeader
          createActions={[
            {
              label: 'Email',
              onClick: () => createMessageTemplate('EmailMessageTemplate'),
            },
            {
              label: 'Slack',
              onClick: () => createMessageTemplate('SlackMessageTemplate'),
            },
            {
              label: 'SMS',
              onClick: () => createMessageTemplate('SmsMessageTemplate'),
            },
            {
              label: 'Generic copy',
              onClick: () => createMessageTemplate('TextMessageTemplate'),
            },
          ]}
          searchTerm={searchTerm}
          onChangeSearchTerm={setSearchTerm}
        >
          {'Message Templates'}
        </PageHeader>
      </PageHeaderOuter>
      <ListOuter>
        {error ? (
          <ErrorEmptyState error={error} />
        ) : !data || loading ? (
          <LoadingSpinner />
        ) : (
          groups.map(({ name, items: templates }) => (
            <Group heading={name || 'Other'} key={name || 'Other'}>
              {templates.map((template) => (
                <ItemWithNavlink
                  key={template.id}
                  href={`/message-templates/${template.id}`}
                  isSelected={false}
                  text={getSectionedName(template.name).name}
                  after={() => getKindEmoji(template)}
                />
              ))}
            </Group>
          ))
        )}
      </ListOuter>
    </Outer>
  )
}

export default MessageTemplatesList
