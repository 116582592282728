import { v4 as uuidv4 } from 'uuid'

import {
  TimeTrackingCategoryId,
  TimeTrackingEntryTemplateId,
  UpdateAssistantCopilotSettingsMutation,
  useAssistantCopilotQuery,
  useUpdateAssistantCopilotSettingsMutation,
  UpdateAssistantCopilotSettingsInput,
  MainCopilotPromptSettingsFragment,
  CopilotPromptSettingsOverrideFragment,
  MainCopilotPromptSettingsInput,
  CopilotPromptSettingsOverrideInput,
  TaskCopilotPromptSettingsFragment,
  TaskCopilotPromptSettingsInput,
  CategoryCopilotPromptSettingsFragment,
  CategoryCopilotPromptSettingsInput,
} from '../../../../graphql'
import { deepCleanTypename } from '../../../../lib/utils'

const toCopilotPromptSettingsOverrideInput = (
  overridePrompts: CopilotPromptSettingsOverrideFragment[],
): CopilotPromptSettingsOverrideInput[] =>
  overridePrompts.map(({ users, ...overridePrompt }) => ({
    ...overridePrompt,
    userIds: users?.map(({ id }) => id) ?? null,
  }))

const toMainCopilotPromptSettingsInput = (
  mainCopilotPromptSettingsFragment: MainCopilotPromptSettingsFragment,
): MainCopilotPromptSettingsInput => ({
  firstCalledFunctionInfo: {
    inerhitIfNull:
      mainCopilotPromptSettingsFragment.firstCalledFunctionInfo.inerhitIfNull,
    functionName:
      mainCopilotPromptSettingsFragment.firstCalledFunctionInfo.functionName,
  },
  functions: mainCopilotPromptSettingsFragment.functions,
  systemPrompt: mainCopilotPromptSettingsFragment.systemPrompt,
  overridePrompts: toCopilotPromptSettingsOverrideInput(
    mainCopilotPromptSettingsFragment.overridePrompts,
  ),
})

const toTaskCopilotPromptSettingsInput = (
  taskCopilotPromptSettings: TaskCopilotPromptSettingsFragment[],
): TaskCopilotPromptSettingsInput[] =>
  taskCopilotPromptSettings.map(
    ({ label: _label, overridePrompts, ...taskPrompt }) => ({
      ...taskPrompt,
      overridePrompts: toCopilotPromptSettingsOverrideInput(overridePrompts),
    }),
  )

const toCategoryCopilotPromptSettingsInput = (
  categoryCopilotPromptSettings: CategoryCopilotPromptSettingsFragment[],
): CategoryCopilotPromptSettingsInput[] =>
  categoryCopilotPromptSettings.map(
    ({ label: _label, overridePrompts, ...categoryPrompt }) => ({
      ...categoryPrompt,
      overridePrompts: toCopilotPromptSettingsOverrideInput(overridePrompts),
    }),
  )

const toUpdateAssistantCopilotInput = (
  optimisticResponse: UpdateAssistantCopilotSettingsMutation,
): UpdateAssistantCopilotSettingsInput =>
  deepCleanTypename({
    everydayTasksCopilotPromptSettings: toMainCopilotPromptSettingsInput(
      optimisticResponse.updateAssistantCopilotSettings.assistantCopilot
        .everydayTasksCopilotPromptSettings,
    ),
    execTasksCopilotPromptSettings: toMainCopilotPromptSettingsInput(
      optimisticResponse.updateAssistantCopilotSettings.assistantCopilot
        .execTasksCopilotPromptSettings,
    ),
    taskPrompts: toTaskCopilotPromptSettingsInput(
      optimisticResponse.updateAssistantCopilotSettings.assistantCopilot
        .taskPrompts,
    ),
    categoryPrompts: toCategoryCopilotPromptSettingsInput(
      optimisticResponse.updateAssistantCopilotSettings.assistantCopilot
        .categoryPrompts,
    ),
  })

const findOverridePrompt = <
  T extends
    | MainCopilotPromptSettingsFragment
    | CategoryCopilotPromptSettingsFragment
    | TaskCopilotPromptSettingsFragment,
>(
  promptList: T[],
  overridePrompt: Pick<CopilotPromptSettingsOverrideFragment, 'id'>,
): T | undefined =>
  promptList.find(({ overridePrompts }) =>
    overridePrompts?.find(({ id }) => id === overridePrompt.id),
  )

const isOverridePromptIn = (
  prompt: MainCopilotPromptSettingsFragment,
  overridePrompt: Pick<CopilotPromptSettingsOverrideFragment, 'id'>,
) => !!findOverridePrompt([prompt], overridePrompt)

export enum CopilotPromptSettingsOverrideType {
  EVERYDAY_TASKS = 'EVERYDAY_TASKS',
  EXEC_TASKS = 'EXEC_TASKS',
  TASK = 'TASK',
  CATEGORY = 'CATEGORY',
}

type OverridenPromptInfo = {
  type: CopilotPromptSettingsOverrideType
  id?: TimeTrackingCategoryId | TimeTrackingEntryTemplateId
}

const useUpdateAssistantCopilot = (): {
  updateEverydayTasksCopilotPromptSettings: (
    everydayTasksCopilotPromptSettings: MainCopilotPromptSettingsFragment,
  ) => Promise<void>
  updateExecTasksCopilotPromptSettings: (
    execTasksCopilotPromptSettings: MainCopilotPromptSettingsFragment,
  ) => Promise<void>
  updateTaskPrompt: (
    taskPrompt: TaskCopilotPromptSettingsFragment,
  ) => Promise<void>
  updateCategoryPrompt: (
    categoryPrompt: CategoryCopilotPromptSettingsFragment,
  ) => Promise<void>
  updateCopilotPromptSettingsOverride: (
    overridePrompt: CopilotPromptSettingsOverrideFragment,
  ) => Promise<void>
  createCopilotPromptSettingsOverride: ({
    overridenPromptInfo,
    userIds,
  }: {
    userIds: string[]
    overridenPromptInfo: OverridenPromptInfo
  }) => Promise<void>
  deleteCopilotPromptSettingsOverride: (id: string) => Promise<void>
  loading: boolean
} => {
  const { data, loading } = useAssistantCopilotQuery()
  const [mutate, { loading: mutationLoading }] =
    useUpdateAssistantCopilotSettingsMutation()

  const updateEverydayTasksCopilotPromptSettings = async (
    everydayTasksCopilotPromptSettings: MainCopilotPromptSettingsFragment,
  ) => {
    if (!data) return

    const { id, execTasksCopilotPromptSettings, taskPrompts, categoryPrompts } =
      data.assistantCopilot

    const optimisticResponse: UpdateAssistantCopilotSettingsMutation = {
      __typename: 'Mutation',
      updateAssistantCopilotSettings: {
        __typename: 'UpdateAssistantCopilotSettingsOutput',
        assistantCopilot: {
          __typename: 'AssistantCopilot',
          id,
          everydayTasksCopilotPromptSettings,
          execTasksCopilotPromptSettings,
          taskPrompts,
          categoryPrompts,
        },
      },
    }

    await mutate({
      variables: {
        input: toUpdateAssistantCopilotInput(optimisticResponse),
      },
      optimisticResponse,
    })
  }

  const updateExecTasksCopilotPromptSettings = async (
    execTasksCopilotPromptSettings: MainCopilotPromptSettingsFragment,
  ) => {
    if (!data) return

    const {
      id,
      everydayTasksCopilotPromptSettings,
      taskPrompts,
      categoryPrompts,
    } = data.assistantCopilot

    const optimisticResponse: UpdateAssistantCopilotSettingsMutation = {
      __typename: 'Mutation',
      updateAssistantCopilotSettings: {
        __typename: 'UpdateAssistantCopilotSettingsOutput',
        assistantCopilot: {
          __typename: 'AssistantCopilot',
          id,
          everydayTasksCopilotPromptSettings,
          execTasksCopilotPromptSettings,
          taskPrompts,
          categoryPrompts,
        },
      },
    }

    await mutate({
      variables: {
        input: toUpdateAssistantCopilotInput(optimisticResponse),
      },
      optimisticResponse,
    })
  }

  const updateTaskPrompt = async (
    taskPrompt: TaskCopilotPromptSettingsFragment,
  ) => {
    if (!data) return

    const {
      id,
      everydayTasksCopilotPromptSettings,
      execTasksCopilotPromptSettings,
      categoryPrompts,
      taskPrompts,
    } = data.assistantCopilot

    const optimisticResponse: UpdateAssistantCopilotSettingsMutation = {
      __typename: 'Mutation',
      updateAssistantCopilotSettings: {
        __typename: 'UpdateAssistantCopilotSettingsOutput',
        assistantCopilot: {
          __typename: 'AssistantCopilot',
          id,
          everydayTasksCopilotPromptSettings,
          execTasksCopilotPromptSettings,
          taskPrompts: taskPrompts?.map((prompt) => {
            if (prompt.id !== taskPrompt.id) return prompt

            return {
              ...taskPrompt,
              functions: taskPrompt.functions || null,
            }
          }),
          categoryPrompts,
        },
      },
    }

    await mutate({
      variables: {
        input: toUpdateAssistantCopilotInput(optimisticResponse),
      },
      optimisticResponse,
    })
  }

  const updateCategoryPrompt = async (
    categoryPrompt: CategoryCopilotPromptSettingsFragment,
  ) => {
    if (!data) return

    const {
      id,
      everydayTasksCopilotPromptSettings,
      execTasksCopilotPromptSettings,
      taskPrompts,
      categoryPrompts,
    } = data.assistantCopilot

    const optimisticResponse: UpdateAssistantCopilotSettingsMutation = {
      __typename: 'Mutation',
      updateAssistantCopilotSettings: {
        __typename: 'UpdateAssistantCopilotSettingsOutput',
        assistantCopilot: {
          __typename: 'AssistantCopilot',
          id,
          everydayTasksCopilotPromptSettings,
          execTasksCopilotPromptSettings,
          taskPrompts,
          categoryPrompts: categoryPrompts?.map((prompt) => {
            if (prompt.id !== categoryPrompt.id) return prompt

            return {
              ...categoryPrompt,
              functions: categoryPrompt.functions || null,
            }
          }),
        },
      },
    }

    await mutate({
      variables: {
        input: toUpdateAssistantCopilotInput(optimisticResponse),
      },
      optimisticResponse,
    })
  }

  const updateCopilotPromptSettingsOverride = async (
    _overridePrompt: CopilotPromptSettingsOverrideFragment,
  ) => {
    if (!data) return

    const {
      everydayTasksCopilotPromptSettings,
      execTasksCopilotPromptSettings,
      taskPrompts,
      categoryPrompts,
    } = data.assistantCopilot

    const overridePrompt = {
      ..._overridePrompt,
      functions: _overridePrompt.functions || null,
    }

    if (
      isOverridePromptIn(everydayTasksCopilotPromptSettings, overridePrompt)
    ) {
      await updateEverydayTasksCopilotPromptSettings({
        ...everydayTasksCopilotPromptSettings,
        overridePrompts: [
          ...(everydayTasksCopilotPromptSettings.overridePrompts || []).map(
            (prompt) => {
              if (prompt.id !== overridePrompt.id) return prompt

              return {
                ...overridePrompt,
                functions: overridePrompt.functions || null,
              }
            },
          ),
        ],
      })
    } else if (
      isOverridePromptIn(execTasksCopilotPromptSettings, overridePrompt)
    ) {
      await updateExecTasksCopilotPromptSettings({
        ...execTasksCopilotPromptSettings,
        overridePrompts: [
          ...(execTasksCopilotPromptSettings.overridePrompts || []).map(
            (prompt) => {
              if (prompt.id !== overridePrompt.id) return prompt

              return {
                ...overridePrompt,
              }
            },
          ),
        ],
      })
    } else if (findOverridePrompt(taskPrompts, overridePrompt)) {
      const taskPrompt = findOverridePrompt(taskPrompts, overridePrompt)

      if (!taskPrompt) return

      await updateTaskPrompt({
        ...taskPrompt,
        overridePrompts: [
          ...(taskPrompt?.overridePrompts || []).map((prompt) => {
            if (prompt.id !== overridePrompt.id) return prompt

            return {
              ...overridePrompt,
            }
          }),
        ],
      })
    } else if (findOverridePrompt(categoryPrompts, overridePrompt)) {
      const categoryPrompt = findOverridePrompt(categoryPrompts, overridePrompt)

      if (!categoryPrompt) return

      await updateCategoryPrompt({
        ...categoryPrompt,
        overridePrompts: [
          ...(categoryPrompt?.overridePrompts || []).map((prompt) => {
            if (prompt.id !== overridePrompt.id) return prompt

            return {
              ...overridePrompt,
            }
          }),
        ],
      })
    } else {
    }
  }

  const createCopilotPromptSettingsOverride = async ({
    overridenPromptInfo,
    userIds,
  }: {
    userIds: string[]
    overridenPromptInfo: OverridenPromptInfo
  }) => {
    if (!data) return

    const {
      everydayTasksCopilotPromptSettings,
      execTasksCopilotPromptSettings,
      taskPrompts,
      categoryPrompts,
    } = data.assistantCopilot

    const overridePrompt: CopilotPromptSettingsOverrideFragment = {
      __typename: 'CopilotPromptSettingsOverride',
      id: uuidv4(),
      firstCalledFunctionInfo: {
        __typename: 'FirstCalledFunctionInfo',
        functionName: null,
        inerhitIfNull: false,
      },
      systemPrompt: '',
      users: userIds.map((id) => ({
        __typename: 'AssistantUser',
        id,
        profile: {
          __typename: 'UserProfile',
          id,
          emails: [],
          avatarUrl: null,
          email: null,
          givenName: null,
          familyName: null,
          displayName: null,
        },
      })),
      functions: null,
    }

    overridePrompt.id

    switch (overridenPromptInfo.type) {
      case CopilotPromptSettingsOverrideType.EVERYDAY_TASKS:
        await updateEverydayTasksCopilotPromptSettings({
          ...everydayTasksCopilotPromptSettings,
          overridePrompts: [
            ...(everydayTasksCopilotPromptSettings.overridePrompts || []),
            overridePrompt,
          ],
        })
        break
      case CopilotPromptSettingsOverrideType.EXEC_TASKS:
        await updateExecTasksCopilotPromptSettings({
          ...execTasksCopilotPromptSettings,
          overridePrompts: [
            ...(execTasksCopilotPromptSettings.overridePrompts || []),
            overridePrompt,
          ],
        })
        break
      case CopilotPromptSettingsOverrideType.TASK:
        const taskPrompt = taskPrompts.find(
          ({ id }) => id === overridenPromptInfo.id,
        )

        if (!taskPrompt) return

        await updateTaskPrompt({
          ...taskPrompt,
          overridePrompts: [
            ...(taskPrompt?.overridePrompts || []),
            overridePrompt,
          ],
        })
        break
      case CopilotPromptSettingsOverrideType.CATEGORY:
        const categoryPrompt = categoryPrompts.find(
          ({ id }) => id === overridenPromptInfo.id,
        )

        if (!categoryPrompt) return

        await updateCategoryPrompt({
          ...categoryPrompt,
          overridePrompts: [
            ...(categoryPrompt?.overridePrompts || []),
            overridePrompt,
          ],
        })
        break
    }
  }

  const deleteCopilotPromptSettingsOverride = async (id: string) => {
    if (!data) return

    const {
      everydayTasksCopilotPromptSettings,
      execTasksCopilotPromptSettings,
      taskPrompts,
      categoryPrompts,
    } = data.assistantCopilot

    if (isOverridePromptIn(everydayTasksCopilotPromptSettings, { id })) {
      await updateEverydayTasksCopilotPromptSettings({
        ...everydayTasksCopilotPromptSettings,
        overridePrompts:
          everydayTasksCopilotPromptSettings.overridePrompts?.filter(
            ({ id: _id }) => _id !== id,
          ),
      })
    } else if (isOverridePromptIn(execTasksCopilotPromptSettings, { id })) {
      await updateExecTasksCopilotPromptSettings({
        ...execTasksCopilotPromptSettings,
        overridePrompts: execTasksCopilotPromptSettings.overridePrompts?.filter(
          ({ id: _id }) => _id !== id,
        ),
      })
    } else if (findOverridePrompt(taskPrompts, { id })) {
      const taskPrompt = findOverridePrompt(taskPrompts, { id })

      if (!taskPrompt) return

      await updateTaskPrompt({
        ...taskPrompt,
        overridePrompts: taskPrompt?.overridePrompts?.filter(
          ({ id: _id }) => _id !== id,
        ),
      })
    } else if (findOverridePrompt(categoryPrompts, { id })) {
      const categoryPrompt = findOverridePrompt(categoryPrompts, { id })

      if (!categoryPrompt) return

      await updateCategoryPrompt({
        ...categoryPrompt,
        overridePrompts: categoryPrompt?.overridePrompts?.filter(
          ({ id: _id }) => _id !== id,
        ),
      })
    } else {
    }
  }

  return {
    updateEverydayTasksCopilotPromptSettings,
    updateExecTasksCopilotPromptSettings,
    updateTaskPrompt,
    updateCategoryPrompt,
    updateCopilotPromptSettingsOverride,
    createCopilotPromptSettingsOverride,
    deleteCopilotPromptSettingsOverride,
    loading: loading || mutationLoading,
  }
}

export default useUpdateAssistantCopilot
