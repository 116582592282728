import Avatar from '@atlaskit/avatar'
import Comment, { CommentAuthor, CommentTime } from '@atlaskit/comment'
import { markdownToHtml } from '@withdouble/markdown'
import React from 'react'

import {
  ChatThreadItemFragment,
  UserCategory,
} from '../../../../../../../graphql'

import { Outer } from './styled'

type Props = {
  message: ChatThreadItemFragment
}

type ContentProps = {
  content: string
}
const CommentContent = ({ content }: ContentProps) => (
  <div dangerouslySetInnerHTML={{ __html: content }} />
)

const ChatMessage = ({ message }: Props) => {
  const date = new Date(message.createdAt).toLocaleString()
  const { author, content } = message
  const { profile, category } = author
  const { displayName, avatarUrl } = profile
  return (
    <Outer $primaryBackground={category === UserCategory.ASSISTANT}>
      <Comment
        author={<CommentAuthor>{displayName}</CommentAuthor>}
        time={<CommentTime>{date}</CommentTime>}
        avatar={<Avatar name={displayName || ''} src={avatarUrl || ''} />}
        content={<CommentContent content={markdownToHtml(content)} />}
      />
    </Outer>
  )
}

export default ChatMessage
