import React, { useCallback } from 'react'
import styled from 'styled-components'

import { Field, TextArea, TextField } from '../../form'

import { Sms, getValidText, parsePhoneNumber, formatPhoneNumber } from './utils'

type Props = {
  value: Sms
  onPatch: (patch: Partial<Sms>) => void
}

const Outer = styled.div``

const Preview = ({ value, onPatch }: Props) => {
  const onChangeText = useCallback(
    (text: string) => {
      onPatch({ text })
    },
    [onPatch],
  )

  const onChangeTo = useCallback(
    (to: string) => {
      onPatch({ to })
    },
    [onPatch],
  )

  const phoneNumber = parsePhoneNumber(value.to)

  return (
    <Outer>
      <Field label={'Phone Number'} isRequired>
        <TextField
          value={phoneNumber ? formatPhoneNumber(phoneNumber, true) : value.to}
          onChangeValue={onChangeTo}
          isInvalid={!phoneNumber}
          placeholder={'+1 234 567 8910'}
          isRequired
        />
      </Field>
      <Field label={'Message'} isRequired>
        <TextArea
          value={value.text || ''}
          onChangeValue={onChangeText}
          isInvalid={!getValidText(value.text)}
          isRequired
          minimumRows={8}
        />
      </Field>
    </Outer>
  )
}

export default Preview
