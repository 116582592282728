import FileIcon from '@atlaskit/icon/glyph/file'
import ImageIcon from '@atlaskit/icon/glyph/image'
import React from 'react'

import { EmailThreadItemFragment } from '../../../../../../../graphql'

import StyledEmailMessage from './StyledEmailMessage'
import {
  Attachment,
  Attachments,
  Body,
  EmailAddresses,
  EmailMessageContainer,
} from './styled'

type Props = {
  message: EmailThreadItemFragment
}

const EmailNameAndAddress = ({
  name,
  email,
}: {
  name?: string
  email?: string
}) => {
  return <span>{`${name} <${email}>`}</span>
}

const EmailMessage = ({ message }: Props) => {
  const date = new Date(message.forwardedAt).toLocaleString()

  return (
    <EmailMessageContainer>
      <EmailAddresses>
        <strong>{`Date:`}</strong> {date}
        <br />
        <strong>{`From:`}</strong>{' '}
        <EmailNameAndAddress
          name={message.from.name || ''}
          email={message.from.email || 'Unknown Email'}
        />
        <br />
        <strong>{`To:`}</strong>
        <EmailNameAndAddress
          name={message.to?.name || ''}
          email={message.to?.email || 'Unknown Email'}
        />
      </EmailAddresses>
      <Body>
        <StyledEmailMessage rawText={message.text} />
      </Body>
      <Attachments>
        {message.attachments?.map((attachment, index) => (
          <Attachment key={index}>
            {attachment.type?.includes('image') ? (
              <ImageIcon label={'Image'} />
            ) : (
              <FileIcon label={'File'} />
            )}
            {attachment.filename}
          </Attachment>
        ))}
      </Attachments>
    </EmailMessageContainer>
  )
}

export default EmailMessage
