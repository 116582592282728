import { borderRadius, colors, typography } from '@atlaskit/theme'
import styled from 'styled-components'

export const Outer = styled.div`
  padding: 8px 0px;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  overflow: auto;
`

export const ActionsOuter = styled.div`
  position: absolute;
  right: 4px;
  z-index: 1;
`

export const LogsList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 8px;
  row-gap: 4px;
`

export const LogsListDateTime = styled.div`
  ${typography.h300()};
`

export const LogOuter = styled.div<{ $level?: string | null }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px 12px 6px;
  background: ${colors.N20};
  border-left: 4px solid
    ${({ $level }) =>
      $level === 'error'
        ? colors.red()
        : $level === 'warn'
        ? colors.yellow()
        : $level === 'info'
        ? colors.blue()
        : colors.N50};

  &:hover {
    background: ${colors.backgroundHover};
  }
`

export const LogHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`

export const LogMessage = styled.span`
  color: ${colors.text};
  font-size: smaller;
`

export const LogActionName = styled.span<{ $color: string }>`
  color: ${colors.text};
  font-size: smaller;
  border-radius: ${borderRadius}px;
  background: ${({ $color }) => $color};
  padding: 1px 4px;
`
