import styled from 'styled-components'

export const Outer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  height: 100%;
`

export const PageOuter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  height: 100%;

  padding: 24px 16px;
`
