import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'

import { useGetUserPhoneNumberQuery } from '../../../graphql'
import { Select, Field, TextField } from '../../form'
import { extractVars, replaceVarsInString } from '../../utils'

import { SmsTemplate, Sms } from './utils'

const Outer = styled.div``

type Vars = Record<string, string>

type Props = {
  toUserId?: string
  templates?: SmsTemplate[]
  vars?: Vars
  onPatch: (newValue: Partial<Sms>) => void
}

const Editor = ({ toUserId, templates, onPatch, vars: initialVars }: Props) => {
  const [template, setTemplate] = useState<SmsTemplate | undefined>()
  const [vars, setVars] = useState<Vars>(initialVars || {})

  const { data } = useGetUserPhoneNumberQuery({
    variables: {
      userId: toUserId || '',
    },
    skip: !toUserId,
  })

  const onChangeTemplate = useCallback(
    (templateText: SmsTemplate['value']) => {
      const template = templates?.find(({ value }) => value === templateText)
      setTemplate(template)
    },
    [templates, setTemplate],
  )

  const onChangeVar = useCallback(
    (varName: string, value: string) => {
      setVars((vars) => ({
        ...vars,
        [varName]: value,
      }))
    },
    [setVars],
  )

  useEffect(() => {
    if (template) {
      onPatch({
        text: replaceVarsInString(vars, template.value),
      })
    }
  }, [onPatch, vars, template])

  useEffect(() => {
    if (data?.user?.phoneNumber) {
      onPatch({
        to: data.user.phoneNumber,
      })
    }
  }, [onPatch, data])

  return (
    <Outer>
      {!!templates?.length && (
        <Field label={'Template'}>
          <Select<SmsTemplate['value']>
            value={template}
            options={templates}
            onChangeValue={onChangeTemplate}
            // validationState={template ? 'default' : 'error'}
          />
        </Field>
      )}
      {extractVars(template?.value || '').map((varName) => (
        <Field
          key={varName}
          label={varName.toLowerCase().replaceAll('_', ' ')}
          isRequired
        >
          <TextField
            value={vars[varName] || ''}
            isInvalid={!vars[varName]}
            onChangeValue={(value) => onChangeVar(varName, value)}
          />
        </Field>
      ))}
    </Outer>
  )
}

export default Editor
