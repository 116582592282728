// import { Email } from './types'
import { colors, borderRadius, typography } from '@atlaskit/theme'
import React, { useRef, useEffect, MutableRefObject, useState } from 'react'
import styled from 'styled-components'

import {
  SendEmailFromTemplateInput,
  useGetEmailTemplateByIdQuery,
} from '../../../graphql'
import { LoadingSpinner } from '../../Spinner'
import { Field } from '../../form'
import { formatEmailWithName } from '../utils'

import { replaceVarsInString } from './utils'

const Outer = styled.div``
const Inner = styled.div`
  border-radius: ${borderRadius}px;
  border: solid 2px ${colors.backgroundHover};
  padding: 0 8px 0;
`
const Iframe = styled.iframe`
  width: 100%;
  height: 550px;
  border: none;
  border-top: solid 2px ${colors.backgroundHover};
  margin-top: 8px;
`
const Subject = styled.p`
  ${typography.h500}
  margin: 12px 8px 8px;
`
const Meta = styled.p`
  margin: 0 8px;
`
const MetaName = styled.span`
  ${typography.h100}
  opacity: 0.6;
`
const MetaValue = styled.span`
  ${typography.h200}
`

const useUpdateIframeDocumentEffect = (
  ref: MutableRefObject<null | HTMLIFrameElement>,
  body: string | null,
) =>
  useEffect(() => {
    if (!ref.current) {
      return
    }

    const iframe = ref.current
    const doc =
      (iframe && iframe.contentWindow && iframe.contentWindow.document) || null

    if (!doc) {
      return
    }

    doc.open()
    doc.write(
      (body || '').replace(
        '<head>',
        `<head><style type="text/css">* {font-family: -apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;}</style>`,
      ),
    )
    doc.close()
  }, [ref, body])

type Props = {
  email: Partial<SendEmailFromTemplateInput>
  highlightVars?: boolean
}

const Preview = ({ email, highlightVars }: Props) => {
  const { templateId, vars, from, to, replyTo, cc, bcc } = email
  const ref = useRef<null | HTMLIFrameElement>(null)
  const [content, setContent] = useState<{ body: string; subject: string }>({
    body: '',
    subject: '',
  })
  const { data, loading } = useGetEmailTemplateByIdQuery({
    variables: {
      id: templateId || '',
    },
    skip: !templateId,
  })

  const template = data?.emailTemplate

  useEffect(() => {
    if (template) {
      setContent({
        body: replaceVarsInString(
          vars as Record<string, string>,
          template.html,
          highlightVars,
        ),
        subject:
          replaceVarsInString(
            vars as Record<string, string>,
            template.subject,
            highlightVars,
          ) || '🚨 MISSING SUBJECT',
      })
    }
  }, [template, vars, highlightVars])

  useUpdateIframeDocumentEffect(ref, content.body)

  return (
    <Outer>
      <Field label={'Preview'}>
        <Inner>
          <Subject dangerouslySetInnerHTML={{ __html: content.subject }} />
          <Meta>
            <MetaName>{'From: '}</MetaName>
            <MetaValue>{from && formatEmailWithName(from)}</MetaValue>
          </Meta>
          {!!replyTo?.email && (
            <Meta>
              <MetaName>{'Reply To: '}</MetaName>
              <MetaValue>{formatEmailWithName(replyTo)}</MetaValue>
            </Meta>
          )}
          <Meta>
            <MetaName>{'To: '}</MetaName>
            <MetaValue>
              {to?.map((someone) => formatEmailWithName(someone)).join(', ')}
            </MetaValue>
          </Meta>
          {!!cc?.length && (
            <Meta>
              <MetaName>{'CC: '}</MetaName>
              <MetaValue>
                {cc.map((someone) => formatEmailWithName(someone)).join(', ')}
              </MetaValue>
            </Meta>
          )}
          {!!bcc?.length && (
            <Meta>
              <MetaName>{'BCC: '}</MetaName>
              <MetaValue>
                {bcc.map((someone) => formatEmailWithName(someone)).join(', ')}
              </MetaValue>
            </Meta>
          )}
          <Iframe title={'Transactional Email Preview'} ref={ref} />
        </Inner>
      </Field>
      <LoadingSpinner show={loading} />
    </Outer>
  )
}

export default Preview
