import React, { ComponentProps, useMemo, useCallback } from 'react'

import Modal from '../../../../components/Modal'
import { Field, Checkbox } from '../../../../components/form'
import {
  useRemoveWorkspaceUserMutation,
  RemoveWorkspaceUserInput,
  useGetWorkspaceQuery,
} from '../../../../graphql'
import useValues from '../../../../lib/useValues'

export type Values = Omit<RemoveWorkspaceUserInput, 'workspaceId' | 'userId'>

type Props = ComponentProps<typeof Modal> & {
  workspaceId: string
  assistantId: string
}

const RemoveAssistantModal = ({
  workspaceId,
  assistantId,
  onClose,
  ...modalProps
}: Props) => {
  const [values, { reset, setters }] = useValues<Values>(
    {
      sendNotOnboardedSlackMessage: false,
    },
    ['sendNotOnboardedSlackMessage'],
  )

  const { data: workspaceData, loading: loadingWorkspace } =
    useGetWorkspaceQuery({
      variables: {
        workspaceId,
      },
      onCompleted: ({ workspace }) => {
        setters.sendNotOnboardedSlackMessage?.(
          !workspace.onboarding?.isCompleted,
        )
      },
    })

  const [removeWorkspaceUser, { loading: loadingRemove }] =
    useRemoveWorkspaceUserMutation()
  const removeAssistant = useCallback(async () => {
    try {
      await removeWorkspaceUser({
        variables: {
          input: {
            workspaceId,
            userId: assistantId,
            ...values,
          },
        },
      })

      onClose?.()
    } catch (error) {
      console.error(error)
    }
  }, [assistantId, onClose, removeWorkspaceUser, values, workspaceId])

  const isLoading = loadingRemove || loadingWorkspace

  const actions = useMemo(
    () => [
      { text: 'Remove', onClick: removeAssistant, isLoading },
      { text: 'Cancel', onClick: onClose },
    ],
    [isLoading, onClose, removeAssistant],
  )

  return (
    <Modal
      heading={'Remove Assistant'}
      width={'small'}
      onCloseComplete={reset}
      {...modalProps}
      onClose={onClose}
      actions={actions}
    >
      {workspaceData && !workspaceData.workspace.onboarding?.isCompleted && (
        <Field
          label={''}
          helperMessage={'Onboarding did not happen, send optional message'}
        >
          <Checkbox
            label={'Send Slack message to assistant'}
            isChecked={!!values.sendNotOnboardedSlackMessage}
            onChangeValue={setters.sendNotOnboardedSlackMessage}
          />
        </Field>
      )}
    </Modal>
  )
}

export default RemoveAssistantModal
