import React, { ComponentProps, useCallback } from 'react'
import styled from 'styled-components'

import TextField from './TextField'

const EmailDecorator = styled.span`
  padding: 0 8px 0 0px;
`

type Props = ComponentProps<typeof TextField> & {
  value: string | null | undefined
  domain?: string
}

const EmailAddressField = ({
  value,
  domain,
  isInvalid,
  onChangeValue: _onChangeValue,
  ...props
}: Props) => {
  domain = domain?.split('@').pop()

  const onChangeValue = useCallback(
    (newValue: string) => {
      newValue = newValue?.trim().toLowerCase().replace(/\s/gi, '')
      _onChangeValue?.(domain ? `${newValue}@${domain}` : newValue)
    },
    [domain, _onChangeValue],
  )

  return (
    <TextField
      type={domain ? 'text' : 'email'}
      placeholder={'james.doe'}
      value={domain ? value?.split('@')[0] : value}
      isInvalid={
        isInvalid ||
        (typeof value === 'string' &&
          !/^[-a-z0-9\.]+@[-a-z0-9\.]+$/.test(value))
      }
      elemAfterInput={domain && <EmailDecorator>{`@${domain}`}</EmailDecorator>}
      onChangeValue={onChangeValue}
      {...props}
    />
  )
}

export default EmailAddressField
