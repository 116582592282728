import Button from '@atlaskit/button'
import Calendar from '@atlaskit/calendar'
import { ErrorMessage } from '@atlaskit/form'
import { DateTime, Duration } from 'luxon'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { LoadingSpinner } from '../../../../../../components/Spinner'
import { Field } from '../../../../../../components/form'
import useNow from '../../../../../../lib/useNow'

import TimePicker from './TimePicker'

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px 0px;
`

const TimePickersOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0px 16px;
`

const TimePickers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;

  & > * {
    flex: 1;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0px 16px;
  gap: 8px;
`

type Props = {
  onCancel: () => void
  onSave: (data: { startedAt: DateTime; endedAt: DateTime }) => void
  startedAtDateTime: DateTime
  endedAtDateTime?: DateTime
  saving?: boolean
}

const TimeEntryEditPopup = ({
  onCancel,
  onSave,
  startedAtDateTime,
  endedAtDateTime,
  saving,
}: Props) => {
  const now = useNow()
  const [localStartedAt, setLocalStartedAt] =
    useState<DateTime>(startedAtDateTime)
  const [localEndedAt, setLocalEndedAt] = useState<DateTime>(
    endedAtDateTime || startedAtDateTime.plus({ minutes: 1 }),
  )

  const errorMessage = useMemo(() => {
    if (localEndedAt && localStartedAt >= localEndedAt) {
      return 'The end date must be after the start date'
    }

    if (
      localEndedAt &&
      localEndedAt.diff(localStartedAt) > Duration.fromObject({ hours: 8 })
    ) {
      return 'The entry duration cannot exceed 8 hours'
    }

    if (localStartedAt > now || localEndedAt > now) {
      return 'The start and end dates cannot be in the future'
    }

    return undefined
  }, [localEndedAt, localStartedAt, now])

  return (
    <Outer>
      <TimePickersOuter>
        <TimePickers>
          <Field label={'Start'}>
            <TimePicker
              onChange={(date) =>
                setLocalStartedAt(
                  localStartedAt
                    .set({
                      hour: date.hour,
                      minute: date.minute,
                    })
                    .startOf('minute'),
                )
              }
              value={localStartedAt}
            />
          </Field>
          <Field label={'End'}>
            <TimePicker
              onChange={(date) =>
                setLocalEndedAt(
                  localEndedAt
                    .set({
                      hour: date.hour,
                      minute: date.minute,
                    })
                    .startOf('minute'),
                )
              }
              value={localEndedAt}
            />
          </Field>
        </TimePickers>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </TimePickersOuter>
      <Calendar
        maxDate={DateTime.now().toISO()}
        onSelect={
          // iso is a date string with the format 'yyyy-mm-dd'
          ({ iso }) => {
            const [year, month, day] = iso
              .split('-')
              .map((stringValue) => parseInt(stringValue))

            setLocalStartedAt((localStartedAt) =>
              localStartedAt.set({ year, month, day }),
            )

            setLocalEndedAt((localEndedAt) =>
              localEndedAt?.set({ year, month, day }),
            )
          }
        }
        defaultSelected={[localStartedAt.toISODate()]}
      />
      <Footer>
        <Button onClick={onCancel} appearance={'default'}>
          {'Cancel'}
        </Button>
        <Button
          appearance={'primary'}
          isDisabled={
            !!errorMessage ||
            // If the user hasn't changed the date, don't allow them to save
            (localEndedAt === endedAtDateTime &&
              localStartedAt === startedAtDateTime)
          }
          onClick={() =>
            onSave({ startedAt: localStartedAt, endedAt: localEndedAt! })
          }
        >
          {'Save'}
        </Button>
      </Footer>

      <LoadingSpinner size={'large'} backdrop show={saving} />
    </Outer>
  )
}

export default TimeEntryEditPopup
