import { QueryResult } from '@apollo/client'
import uniqBy from 'lodash/uniqBy'

type FetchMore = () => Promise<void>

function withListPagination<T extends QueryResult>(
  result: T,
): Omit<T, 'fetchMore'> & { fetchMore: FetchMore; hasMore: boolean } {
  const { fetchMore, data, loading } = result

  const fetchNextPage = async () => {
    const after = data?.list?.after
    const before = data?.list?.before

    if (loading || !(after || before)) {
      return
    }

    await fetchMore({
      variables: { after, before },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult?.list?.items) {
          return prev
        }
        fetchMoreResult.list.items = uniqBy(
          [...(prev?.list?.items || []), ...fetchMoreResult.list.items],
          'id',
        )
        return fetchMoreResult
      },
    })
  }

  return {
    ...result,
    hasMore: !loading && !!(data?.list?.after || data?.list.before),
    fetchMore: fetchNextPage,
  }
}

export default withListPagination
