import styled from 'styled-components'

export const Outer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`

export const FilterContainer = styled.div`
  padding: 0 20px;
  max-width: 600px;
`

export const TableOuter = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
`
