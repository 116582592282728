import { colors } from '@atlaskit/theme'
import styled from 'styled-components'

export const Outer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 8px;
`

export const HeaderFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ToggleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`

export const HelpText = styled.div`
  font-size: 12px;
  color: ${colors.N80};
  margin-top: 4px;
  white-space: pre-wrap;
`

export const PromptText = styled.div`
  background-color: ${colors.N20};
  text-color: #000;
  padding: 4px;
  border-radius: 4px;
  font-size: 12px;
`
