import React, { useEffect } from 'react'

import MarkdownEditor from '../../../../../components/MarkdownEditor'
import { Field } from '../../../../../components/form'
import { SmsFieldsValues } from '../../../../../components/messages/types'
import { Outer } from '../../components/styled'

type SmsFieldsProps = {
  values: SmsFieldsValues
  onPatch: (newValues: Partial<SmsFieldsValues>) => void | Promise<void>
  onValidity: (isValid: boolean) => void | Promise<void>
}

const SmsFields = ({ values, onPatch, onValidity }: SmsFieldsProps) => {
  useEffect(() => {
    onValidity(!!values?.text?.trim())
  }, [values, onValidity])

  return (
    <Outer>
      <Field label={'Message'} isRequired>
        <MarkdownEditor
          isInvalid={!values.text?.trim()}
          value={values.text}
          onChange={(text) => onPatch({ text })}
          minHeight={90}
        />
      </Field>
    </Outer>
  )
}

export default SmsFields
