import { Spinner } from '@blueprintjs/core'
import React from 'react'

import { useAssistantCopilotQuery } from '../../../../graphql'
import PromptEditor from '../PromptEditor'

import { CopilotPromptSettingsOverrideType } from './useUpdateAssistantCopilot'

const AssistantEverydayTask = () => {
  const { data, loading } = useAssistantCopilotQuery()

  const initialPrompt =
    data?.assistantCopilot.everydayTasksCopilotPromptSettings

  if (loading || !initialPrompt) return <Spinner />

  return (
    <PromptEditor
      promptId={'everydayTasksSystemPrompt'} // Used like a key
      prompt={data.assistantCopilot.everydayTasksCopilotPromptSettings}
      title={`🧑‍💻 Everyday Tasks`}
      overridenPromptType={CopilotPromptSettingsOverrideType.EVERYDAY_TASKS}
      helperMessage={
        'Used as the default prompt for Everyday Tasks, unless a prompt is set for the category or the task.'
      }
    />
  )
}

export default AssistantEverydayTask
