import { DateTime } from 'luxon'
import { useMemo } from 'react'

import { Matching_ForTransitionFullFragment } from '../../../../../../graphql'
import useNow from '../../../../../../lib/useNow'
import { notFalsy } from '../../../../../../lib/utils'
import { Bundled } from '../../../utils'
import { MatchingForTransitionStatus } from '../types'

type Props = {
  matching: Bundled<Matching_ForTransitionFullFragment>
}

function getDateTime(date: Date | string | undefined | null) {
  return date ? DateTime.fromJSDate(new Date(date)) : null
}

export default function useGetIntroCallStatus({ matching }: Props) {
  const now = useNow()

  const introCallStatus = useMemo(() => {
    const introCalls = matching.candidates
      ?.map((candidate) => {
        return getDateTime(candidate.introCallAt)
      })
      .filter(notFalsy)

    const hasAnIntroCallAt = !!introCalls?.length
    const introCallsCompleted = introCalls?.every(
      (introCall) => introCall < now,
    )

    const hasConfirmedAssistant = !!matching.confirmedAssistant
    const callStartAt = getDateTime(matching.onboarding?.callStartAt)
    const hasTransitionCall = !!callStartAt
    const transitionCallCompleted = callStartAt && callStartAt < now

    //Do they have a confirmed assistant? If not, they need to complete intro calls
    if (!hasConfirmedAssistant) {
      //Do they have a scheduled intro call? If not, they need to schedule intro calls
      if (!hasAnIntroCallAt) {
        return MatchingForTransitionStatus.INTRO_CALL_NOT_SCHEDULED
      } else if (introCallsCompleted) {
        return MatchingForTransitionStatus.ASSISTANT_NOT_CONFIRMED
      } else {
        return MatchingForTransitionStatus.INTRO_CALL_SCHEDULED
      }
    } else {
      if (!hasTransitionCall) {
        return MatchingForTransitionStatus.TRANSITION_CALL_NOT_SCHEDULED
      } else if (transitionCallCompleted) {
        return MatchingForTransitionStatus.TRANSITION_CALL_COMPLETED
      } else {
        return MatchingForTransitionStatus.TRANSITION_CALL_SCHEDULED
      }
    }
  }, [
    matching.candidates,
    matching.confirmedAssistant,
    matching.onboarding?.callStartAt,
    now,
  ])
  return introCallStatus
}
