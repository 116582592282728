import { typography, colors } from '@atlaskit/theme'
import styled from 'styled-components'

import InlineMessage from '../../../../components/InlineMessage'

export const PanelOuter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;
`

export const ActionsHeader = styled.h3`
  ${typography.h700}
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
`

export const LimitedWidth = styled.div`
  max-width: 300px;
`

export const FieldSpacer = styled.div`
  height: 24px;
`

export const InfoNotice = styled(InlineMessage)`
  align-self: center;
`

export const InfoNoticeText = styled.p`
  min-width: 250px;
  font-size: 12px;

  a {
    color: ${colors.link};
  }

  ul {
    list-style: inside;
  }
`
