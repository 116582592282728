import { Presence, SizeType, Status } from '@atlaskit/avatar'
import { Classes } from '@blueprintjs/core'
import React, { ComponentProps } from 'react'

import { TeamFragment } from '../../../graphql'
import TeamAvatar from '../Avatar'

import { Name, Outer, Spacer } from './styled'

type Team = Pick<TeamFragment, 'id'> &
  Partial<Pick<TeamFragment, 'name' | 'iconUrl' | 'isArchived'>>

export type TeamCellProps = ComponentProps<typeof Outer> & {
  skeleton?: boolean
  team: Team
  size?: SizeType
  withSuccessManagerAvatar?: boolean
  status?: ComponentProps<typeof Status>['status']
  presence?: ComponentProps<typeof Presence>['presence']
}

const Cell = ({
  team,
  skeleton,
  size = 'medium',
  status,
  presence,
  ...props
}: TeamCellProps) => {
  const label = team.name || '-------------------'
  const isArchived = team.isArchived

  return (
    <Outer title={label} $striked={isArchived} {...props}>
      <TeamAvatar team={team} size={size} status={status} presence={presence} />
      <Name size={size} className={skeleton ? Classes.SKELETON : undefined}>
        {label}
      </Name>
      <Spacer />
    </Outer>
  )
}

const SKELETON_TEAM: Team = {
  id: '',
  isArchived: false,
  name: '-------------------',
  iconUrl: '',
}

export const Skeleton = (props: any) => (
  <Cell skeleton team={SKELETON_TEAM} {...props} />
)

export default Cell
