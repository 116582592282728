import React, { ComponentProps } from 'react'

import { Select } from '../../../../components/form'
import { DossierInputableType } from '../../../../graphql'

export const labelForType: Record<DossierInputableType, string> = {
  [DossierInputableType.TEXT]: 'Text',
  [DossierInputableType.EMAIL]: 'Email address',
  [DossierInputableType.PHONE_NUMBER]: 'Phone number',
  [DossierInputableType.ANNIVERSARY_DATE]: 'Anniversary date',
  [DossierInputableType.PLACE]: 'Place',
}

const OPTIONS = [
  { value: null, label: 'Free-form markdown notes only' },
  ...Object.values(DossierInputableType).map((value) => ({
    value,
    label: labelForType[value],
  })),
]

type Props = Omit<
  ComponentProps<typeof Select>,
  'onChangeValue' | 'options'
> & {
  onChangeValue?: (newValue: DossierInputableType) => void
}

const FieldInputTypeSelect = ({ value, ...props }: Props) => (
  <Select
    menuPlacement={'auto'}
    value={OPTIONS.find((o) => o.value === value || (!value && !o.value))}
    creatable={false}
    isSearchable={false}
    isClearable={false}
    options={OPTIONS}
    isMulti={false}
    placeholder={'Type'}
    {...props}
  />
)

export default FieldInputTypeSelect
