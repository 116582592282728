import React, { useCallback, useState } from 'react'

import Modal from '../../../../../components/Modal'
import { Field, Checkbox } from '../../../../../components/form'

interface Props {
  isOpen: boolean
  isPrincipal: boolean
  loading: boolean
  onClose: () => void
  onSubmit: (
    attendedOnboarding: boolean,
    enrollInNoCallOnboardingCampaign: boolean,
  ) => void
}

const ExitOnboardingModal = ({
  isOpen,
  isPrincipal,
  loading,
  onClose,
  onSubmit,
}: Props) => {
  const [attendedOnboarding, setAttendedOnboarding] = useState(isPrincipal)
  const [addToCampaign, setAddToCampaign] = useState(!isPrincipal)

  const resetModal = useCallback(() => {
    setAttendedOnboarding(isPrincipal)
    setAddToCampaign(!isPrincipal)
  }, [isPrincipal])

  return (
    <Modal
      isOpen={isOpen}
      heading={'Exit Onboarding'}
      width={'small'}
      onCloseComplete={resetModal}
      onClose={onClose}
      autoFocus={false}
      actions={[
        {
          text: 'Complete Onboarding',
          onClick: () => onSubmit(attendedOnboarding, addToCampaign),
          isLoading: loading,
        },
        { text: 'Cancel', onClick: onClose },
      ]}
    >
      <p>{'Client Type: ' + (isPrincipal ? 'Principal' : 'Teammate')}</p>
      <Field label={''}>
        <Checkbox
          label={'Attended Onboarding Call'}
          isChecked={attendedOnboarding}
          onChangeValue={() => setAttendedOnboarding(!attendedOnboarding)}
        />
      </Field>

      {!isPrincipal && (
        <Field
          label={''}
          helperMessage={
            'Teammates may not attend the onboarding call and can be signed up for the Hubspot +1 email campaign.'
          }
        >
          <Checkbox
            label={'Add to the +1 communication campaign'}
            isChecked={addToCampaign}
            onChangeValue={() => setAddToCampaign(!addToCampaign)}
          />
        </Field>
      )}
    </Modal>
  )
}

export default ExitOnboardingModal
