import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'

import {
  EmailAddressWithNameInput,
  useListEmailTemplatesQuery,
  useGetEmailTemplateByIdQuery,
  useGetWorkspaceQuery,
  WorkspaceUserRole,
  UserCategory,
  BasicUserFragment,
} from '../../../graphql'
import { LoadingSpinner } from '../../Spinner'
import {
  Select,
  TextArea,
  Field,
  FieldsRow,
  TextField,
  ImageUrlField,
} from '../../form'
import { SingleUserSelect } from '../../users/Select'
import { SENDERS, formatEmailWithName } from '../utils'

import { Email } from './types'
import { isRequiredVar } from './utils'

const Outer = styled.div`
  flex: 1 1 auto;
  min-width: 400px;
  max-width: 640px;
`

function notFalsy<T>(v: T | undefined | null | false | '' | 0): v is T {
  return !!v
}

const senderOption = (
  sender: EmailAddressWithNameInput | undefined,
): { value: string; label: string } | undefined =>
  sender && {
    value: sender.email,
    label: sender.name ? `${sender.name} <${sender.email}>` : sender.email,
  }

const templateOption = ({
  id,
  name,
}: {
  id: string
  name: string
}): { value: string; label: string } => ({
  value: id,
  label: name,
})

type Props = {
  templateIds: string[]
  toRole?: WorkspaceUserRole.EXECUTIVE | WorkspaceUserRole.ASSISTANT | null
  email: Partial<Email>
  onPatch: (email: Partial<Email>) => void
}

const Editor = ({
  templateIds,
  email,
  onPatch,
  toRole = WorkspaceUserRole.EXECUTIVE,
}: Props) => {
  const { data: workspaceData, loading: loadingWorkspace } =
    useGetWorkspaceQuery({
      variables: { workspaceId: email.workspaceId || '' },
      skip: !email.workspaceId,
    })
  const { data: templatesData, loading: loadingTemplates } =
    useListEmailTemplatesQuery()
  const { data: templateData, loading: loadingTemplate } =
    useGetEmailTemplateByIdQuery({
      variables: { id: email.templateId || '' },
      skip: !email.templateId,
    })

  const loading = loadingTemplates || loadingTemplate || loadingWorkspace

  const workspace = workspaceData?.workspace
  const templates = templateIds
    .map((templateId) =>
      templatesData?.emailTemplates.find(({ id }) => templateId === id),
    )
    .filter((t): t is any => !!t)
  const template =
    email.templateId && templateData?.emailTemplate.id === email.templateId
      ? templateData?.emailTemplate
      : null

  const toUsers = useMemo(() => {
    const toUsers = []

    if (
      workspace?.executives.length &&
      toRole !== WorkspaceUserRole.ASSISTANT
    ) {
      toUsers.push(
        ...workspace.executives
          .map(({ id, profile }) => ({
            id,
            profile,
            role: WorkspaceUserRole.EXECUTIVE,
          }))
          .filter(notFalsy),
      )
    }

    if (
      workspace?.assistants.length &&
      toRole !== WorkspaceUserRole.EXECUTIVE
    ) {
      toUsers.push(
        ...workspace.assistants
          .map(({ id, profile }) => ({
            id,
            profile,
            role: WorkspaceUserRole.ASSISTANT,
          }))
          .filter(notFalsy),
      )
    }

    return toUsers
  }, [toRole, workspace])

  const onSelectToUser = useCallback(
    ({ id, profile }: any) => {
      onPatch({
        userId: id,
        to: [
          {
            email: profile.email,
            name: profile.displayName,
          },
        ],
      })
    },
    [onPatch],
  )

  const onSelectExecutiveGivenName = useCallback(
    (name: string | undefined | null) => {
      onPatch({
        vars: {
          ...email.vars,
          EXECUTIVE_GIVEN_NAME: name,
        },
      })
    },
    [email, onPatch],
  )

  const onSelectAssistantGivenName = useCallback(
    (name: string | undefined | null) => {
      onPatch({
        vars: {
          ...email.vars,
          ASSISTANT_GIVEN_NAME: name,
        },
      })
    },
    [email, onPatch],
  )

  const onSelectOtherAssistant = useCallback(
    (user: BasicUserFragment | undefined | null) => {
      onPatch({
        vars: {
          ...email.vars,
          OTHER_ASSISTANT_GIVEN_NAME: user?.profile.givenName,
        },
      })
    },
    [email, onPatch],
  )

  useEffect(() => {
    if (email.from?.email && !email.vars?.SENDER_GIVEN_NAME) {
      const sender = SENDERS.find(
        (sender) => email?.from?.email === sender.email,
      )
      if (sender?.givenName) {
        onPatch({
          vars: {
            ...email.vars,
            SENDER_GIVEN_NAME: sender.givenName || null,
          },
        })
      }
    }
  }, [email, email.vars, onPatch])

  // Set default value when possible
  if (!email.templateId && templateIds.length === 1) {
    onPatch({ templateId: templateIds[0] })
  }
  if (
    !email.vars?.EXECUTIVE_GIVEN_NAME &&
    workspace?.executives?.length === 1
  ) {
    onSelectExecutiveGivenName(workspace.executives[0].profile.givenName)
  }
  if (
    !email.vars?.ASSISTANT_GIVEN_NAME &&
    workspace?.assistants?.length === 1
  ) {
    onSelectAssistantGivenName(workspace.assistants[0].profile.givenName)
  }
  if (!email.to?.[0]?.email && toUsers.length) {
    onSelectToUser(toUsers[0])
  }

  return (
    <Outer>
      <Field label={'Template'} isRequired>
        <Select
          value={template ? templateOption(template) : undefined}
          options={(templates || []).map(templateOption)}
          onChangeValue={(templateId) => onPatch({ templateId })}
          validationState={template ? 'default' : 'error'}
          isDisabled={(templates || []).length === 1}
        />
      </Field>

      <FieldsRow>
        <Field label={'From Sender'} isRequired>
          <Select
            validationState={email?.from?.email ? 'default' : 'error'}
            value={senderOption(
              SENDERS.find((sender) => email?.from?.email === sender.email),
            )}
            options={SENDERS.map(senderOption).filter(notFalsy)}
            onChangeValue={(senderEmail) => {
              const sender = SENDERS.find(
                (sender) => senderEmail === sender.email,
              )
              if (sender) {
                onPatch({
                  from: {
                    email: sender.email,
                    name: sender.name,
                  },
                  vars: {
                    ...email.vars,
                    SENDER_GIVEN_NAME: sender.givenName || null,
                  },
                })
              }
            }}
          />
        </Field>
        <Field label={'Reply To'}>
          <Select
            isClearable
            value={senderOption(
              SENDERS.find((sender) => email?.replyTo?.email === sender.email),
            )}
            options={SENDERS.map(senderOption).filter(notFalsy)}
            onChangeValue={(senderEmail) => {
              onPatch({
                replyTo: senderEmail
                  ? {
                      email: senderEmail,
                    }
                  : null,
              })
            }}
          />
        </Field>
      </FieldsRow>

      <Field label={'To'} isRequired>
        <Select
          validationState={email?.to?.[0]?.email ? 'default' : 'error'}
          value={toUsers.find(
            ({ id, profile }) =>
              id === email.userId && profile.email === email.to?.[0]?.email,
          )}
          options={toUsers}
          // @ts-ignore
          getOptionLabel={({ role, profile: { displayName, email } }) =>
            `${role}: ${formatEmailWithName({ displayName, email })}`
          }
          // @ts-ignore
          onChange={onSelectToUser}
        />
      </Field>

      <FieldsRow>
        <Field label={'CC'}>
          <Select<string[]>
            isClearable
            isCreatable
            isMulti
            value={
              email?.cc?.map(({ email }: { email: string }) =>
                senderOption(
                  SENDERS.find((sender) => email === sender.email) || { email },
                ),
              ) || []
            }
            options={[
              ...(workspace?.assistants.map(
                ({ profile: { email, ...profile } }) =>
                  !!email && {
                    value: email,
                    label: formatEmailWithName({ email, ...profile }),
                  },
              ) || []),
              ...SENDERS.map(senderOption),
            ].filter(notFalsy)}
            onChangeValue={(ccEmails) => {
              onPatch({
                cc:
                  ccEmails?.map((email) => ({
                    email,
                  })) || null,
              })
            }}
          />
        </Field>
        <Field label={'BCC'}>
          <Select<string[]>
            isClearable
            isCreatable
            isMulti
            value={
              email?.bcc?.map(({ email }: { email: string }) =>
                senderOption(
                  SENDERS.find((sender) => email === sender.email) || { email },
                ),
              ) || []
            }
            options={SENDERS.map(senderOption).filter(notFalsy)}
            onChangeValue={(bccEmails) => {
              onPatch({
                bcc:
                  bccEmails?.map((email) => ({
                    email,
                  })) || null,
              })
            }}
          />
        </Field>
      </FieldsRow>

      <FieldsRow>
        {!!template?.placeholders.includes('EXECUTIVE_GIVEN_NAME') && (
          <Field label={'Executive'} isRequired>
            <Select
              validationState={
                email.vars?.EXECUTIVE_GIVEN_NAME ? 'default' : 'error'
              }
              value={workspace?.executives
                .map(({ profile: { givenName } }) => ({ value: givenName }))
                .find(
                  ({ value }) => value === email.vars?.EXECUTIVE_GIVEN_NAME,
                )}
              options={workspace?.executives.map(
                ({ profile: { givenName } }) => ({ value: givenName }),
              )}
              // @ts-ignore
              getOptionLabel={({ value }) => value}
              onChangeValue={onSelectExecutiveGivenName}
            />
          </Field>
        )}
        {!!template?.placeholders.includes('ASSISTANT_GIVEN_NAME') && (
          <Field label={'Assistant'} isRequired>
            <Select
              validationState={
                email.vars?.ASSISTANT_GIVEN_NAME ? 'default' : 'error'
              }
              value={workspace?.assistants
                .map(({ profile: { givenName } }) => ({ value: givenName }))
                .find(
                  ({ value }) => value === email.vars?.ASSISTANT_GIVEN_NAME,
                )}
              options={workspace?.assistants.map(
                ({ profile: { givenName } }) => ({ value: givenName }),
              )}
              // @ts-ignore
              getOptionLabel={({ value }) => value}
              onChangeValue={onSelectAssistantGivenName}
            />
          </Field>
        )}
      </FieldsRow>

      <FieldsRow>
        {!!template?.placeholders.some((varName) =>
          varName.startsWith('OTHER_ASSISTANT'),
        ) && (
          <Field label={'Other Assistant'} isRequired>
            <SingleUserSelect
              categories={[UserCategory.ASSISTANT]}
              onChange={onSelectOtherAssistant}
            />
          </Field>
        )}
      </FieldsRow>

      {template?.placeholders.map((varName) =>
        varName.startsWith('FREE_FORM') ? (
          <Field
            label={varName
              .replace('FREE_FORM_', '')
              .replace(/_/g, ' ')
              .toLowerCase()}
            isRequired={isRequiredVar(varName)}
            key={varName}
          >
            <TextArea
              isInvalid={
                typeof email.vars?.[varName] !== 'string' &&
                isRequiredVar(varName)
              }
              value={email.vars?.[varName] || ''}
              onChangeValue={(value) =>
                onPatch({
                  vars: {
                    ...email.vars,
                    [varName]: value,
                  },
                })
              }
            />
          </Field>
        ) : varName.endsWith('IMAGE_URL') ? (
          <Field
            label={varName.replace(/_/g, ' ').toLowerCase()}
            isRequired
            key={varName}
          >
            <ImageUrlField
              isInvalid={
                !(
                  typeof email.vars?.[varName] === 'string' &&
                  email.vars?.[varName].startsWith('https://')
                )
              }
              value={email.vars?.[varName] || ''}
              onChangeValue={(value) =>
                onPatch({
                  vars: {
                    ...email.vars,
                    [varName]: value,
                  },
                })
              }
            />
          </Field>
        ) : varName.endsWith('URL') ? (
          <Field
            label={varName.replace(/_/g, ' ').toLowerCase()}
            isRequired
            key={varName}
          >
            <TextField
              isInvalid={
                !(
                  typeof email.vars?.[varName] === 'string' &&
                  email.vars?.[varName].startsWith('https://')
                )
              }
              value={email.vars?.[varName] || ''}
              placeholder={'https://...'}
              onChangeValue={(value) =>
                onPatch({
                  vars: {
                    ...email.vars,
                    [varName]: value,
                  },
                })
              }
            />
          </Field>
        ) : null,
      )}

      <LoadingSpinner show={loading} />
    </Outer>
  )
}

export default Editor
