import ConfigurationIcon from '@atlaskit/icon/glyph/info'
import PreviewIcon from '@atlaskit/icon/glyph/send'
import Lozenge from '@atlaskit/lozenge'
import PageHeader from '@atlaskit/page-header'
import { colors } from '@atlaskit/theme'
import truncate from 'lodash/truncate'
import uniq from 'lodash/uniq'
import React, { ComponentProps, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import Tabs from '../../../../components/Tabs'
import { LanguageSelect } from '../../../../components/languages/select'
import { getLanguageName } from '../../../../components/languages/utils'
import { EmailComposerButton } from '../../../../components/messages/EmailMessageComposer'
import { notFalsy } from '../../../../lib/utils'
import AutomationsPage from '../../Automations'
import { getKindLabel, isNewTemplate } from '../utils'

import Automations from './Automations'
import Configuration from './Configuration'
import Language from './Language'
import useMessageTemplate from './useMessageTemplate'

const Outer = styled.div<ComponentProps<'div'>>`
  box-shadow: 0 0 0 2px ${colors.backgroundHover},
    0 0 10px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: stretch;
  padding: 0 24px;
  overflow: auto;
  min-width: 400px;
`

const Spacer = styled.div`
  width: 4px;
`

const TabContent = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Subtitle = styled.p`
  font-size: small;
  font-weight: 400;
  line-height: 1.5em;
  margin-top: 1em;
`

const CreateLanguageButton = styled.div`
  min-width: 120px;
  margin-right: 8px;
`

const LozengeOuter = styled.span`
  margin-left: 8px;
  display: inline-block;

  & > * {
    vertical-align: middle;
  }
`

interface MessageTemplateProps {
  templateId: string
}

const MessageTemplate = ({ templateId }: MessageTemplateProps) => {
  const navHistory = useHistory()
  const { url } = useRouteMatch()
  const { messageTemplate: partialTemplate, data } =
    useMessageTemplate(templateId)

  const [newLanguageCode, setNewLanguageCode] = useState<string>()

  const template = data?.messageTemplate

  useEffect(() => {
    if (newLanguageCode) {
      navHistory.push(`${url}/${newLanguageCode}`)
    }
  }, [newLanguageCode, navHistory, url])

  const languageCodes = uniq(
    [
      ...(template?.languages.map(({ languageCode }) => languageCode) || []),
      newLanguageCode,
    ].filter((l): l is string => !!l),
  )

  return (
    <>
      <PageHeader>
        {partialTemplate?.name}
        {partialTemplate ? (
          <LozengeOuter>
            <Lozenge appearance={'default'}>
              {getKindLabel(partialTemplate)}
            </Lozenge>
          </LozengeOuter>
        ) : undefined}
        <Subtitle>
          {truncate(partialTemplate?.description || '', { length: 80 })}
        </Subtitle>
      </PageHeader>
      <Tabs
        isContentPersisted
        leftTabs={languageCodes.map((languageCode) => ({
          slug: languageCode,
          label: getLanguageName(languageCode),
          content: !!template && (
            <TabContent>
              <Language template={template} languageCode={languageCode} />
            </TabContent>
          ),
        }))}
        rightTabs={[
          {
            slug: 'configuration',
            label: '',
            icon: <ConfigurationIcon size={'medium'} label={''} />,
            content: (
              <TabContent>
                <Configuration templateId={templateId} />
              </TabContent>
            ),
          },
          !isNewTemplate(partialTemplate) && {
            slug: 'automations',
            label: '',
            icon: <AutomationsPage.Icon size={'medium'} label={''} />,
            content: (
              <TabContent>
                <Automations templateId={templateId} />
              </TabContent>
            ),
          },
        ].filter(notFalsy)}
        afterTabs={
          partialTemplate &&
          !isNewTemplate(partialTemplate) && (
            <>
              {template?.__typename === 'EmailMessageTemplate' && (
                <>
                  <EmailComposerButton
                    noActivity
                    buttonProps={{
                      iconBefore: <PreviewIcon label={''} />,
                    }}
                    composerProps={{
                      messageTemplateIds: [templateId],
                    }}
                  />
                  <Spacer />
                </>
              )}
              <CreateLanguageButton>
                <LanguageSelect
                  spacing={'compact'}
                  placeholder={'Translate'}
                  onChangeValue={setNewLanguageCode}
                  hideCodes={template?.languages.map(
                    ({ languageCode }) => languageCode,
                  )}
                />
              </CreateLanguageButton>
            </>
          )
        }
      />
    </>
  )
}

type MaybeMessageTemplateProps = Partial<MessageTemplateProps>

const MaybeMessageTemplate = ({
  templateId,
  ...rest
}: MaybeMessageTemplateProps) => (
  <Outer>
    {!!templateId && (
      <MessageTemplate key={templateId} templateId={templateId} {...rest} />
    )}
  </Outer>
)

export default MaybeMessageTemplate
