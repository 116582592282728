import Spinner, { SpinnerProps } from '@atlaskit/spinner'
import { transparentize } from 'polished'
import React from 'react'
import styled from 'styled-components'

const Backdrop = styled.div<{ color?: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ color }) => transparentize(0.7, color || '#fff')};
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Spinner

interface LoadingSpinnerProps extends Partial<SpinnerProps> {
  show?: boolean | boolean[]
  backdrop?: boolean
  backdropColor?: string
}

export const LoadingSpinner = ({
  show = true,
  backdrop = true,
  size = 'large',
  backdropColor = 'white',
  ...props
}: LoadingSpinnerProps) => {
  if (!show) {
    return null
  }

  if (Array.isArray(show) && !show.some(Boolean)) {
    return null
  }

  const spinner = <Spinner size={size} {...props} />

  return backdrop ? (
    <Backdrop color={backdropColor} children={spinner} />
  ) : (
    spinner
  )
}
