import React, { ComponentProps } from 'react'

import { Outer } from './styled'

export const HEIGHT = 34

type Props = ComponentProps<typeof Outer> & {
  isLoading: boolean
  hasMore: boolean
  itemCount: number
}

const Footer = ({ isLoading, hasMore, itemCount, ...props }: Props) => (
  <Outer {...props}>
    {itemCount && !isLoading
      ? `${hasMore ? 'More than ' : 'Total: '}${itemCount} team${
          itemCount > 1 ? 's' : ''
        }`
      : 'Loading...'}
  </Outer>
)

export default Footer
