import { LoadingButton as Button, ButtonGroup } from '@atlaskit/button'
import { transparentize } from 'polished'
import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { LoadingSpinner } from '../../../../components/Spinner'
import { ToggleStateless as Toggle } from '../../../../components/Toggle'
import { Field, TextField, TextArea } from '../../../../components/form'
import {
  PricingFragment,
  useUpsertPricingMutation,
  UpsertPricingInput,
  useListQueryCache,
  ListPricingsQuery,
  ListPricingsDocument,
} from '../../../../graphql'
import { areEquivalentObjects, deepCleanTypename } from '../../../../lib/utils'
import { isNewPricing } from '../utils'

const Outer = styled.div`
  padding: 8px 0;
  flex: 1;
  margin-bottom: 24px;
`

const ToggleOuter = styled.div`
  display: flex;
  align-items: center;
`

const Buttons = styled.div`
  padding: 16px 4px;
  position: sticky;
  bottom: 0;
  background-color: ${transparentize(0.2, 'white')};
`

interface ConfigurationProps {
  pricing: PricingFragment
  isEditable?: boolean
  unsetEditable?: () => void
}

const Configuration = ({
  pricing: defaultPricing,
  isEditable,
  unsetEditable,
}: ConfigurationProps) => {
  const navHistory = useHistory()
  const [values, setValues] = useState<
    Omit<Partial<UpsertPricingInput>, '__typename' | 'pricingId'>
  >({
    ...(isNewPricing(defaultPricing) ? { label: null, ref: null } : null),
  })
  const [upsertPricing, { loading }] = useUpsertPricingMutation()
  const { removeItem: removePricing } = useListQueryCache<
    ListPricingsQuery,
    PricingFragment
  >(ListPricingsDocument)

  const setValue = useCallback(
    (value: Partial<UpsertPricingInput>) => {
      setValues((prev) => ({ ...prev, ...value }))
    },
    [setValues],
  )

  const resetPricing = useCallback(() => {
    setValues({})
  }, [])

  const cancelEdit = useCallback(() => {
    if (isNewPricing(defaultPricing)) {
      // cancel creation of new pricing
      removePricing(defaultPricing)
      navHistory.replace('/pricings')
    } else {
      resetPricing()
    }
  }, [defaultPricing, navHistory, removePricing, resetPricing])

  const savePricing = useCallback(async () => {
    try {
      await upsertPricing({
        variables: {
          input: {
            pricingId: defaultPricing.id,
            ...deepCleanTypename<typeof values>(values),
            ...(values.sellingPoints
              ? {
                  sellingPoints: values.sellingPoints
                    .map((str) => str.trim())
                    .filter(Boolean),
                }
              : null),
          },
        },
      })

      if (unsetEditable) {
        unsetEditable()
      }
      resetPricing()
    } catch (error) {
      console.error(error)
    }
  }, [upsertPricing, defaultPricing.id, values, unsetEditable, resetPricing])

  const pricing = {
    ...defaultPricing,
    ...values,
  }

  return (
    <Outer>
      <Field
        label={'Reference'}
        isRequired
        helperMessage={'For internal reference only, not visible to users.'}
      >
        <TextField
          autoFocus={isNewPricing(defaultPricing)}
          isReadOnly={!isNewPricing(defaultPricing)}
          isDisabled={!isNewPricing(defaultPricing)}
          value={pricing.ref || ''}
          onChangeValue={(ref) => setValue({ ref })}
        />
      </Field>

      <Field
        label={'Label'}
        isRequired
        helperMessage={'Visible to users and on website'}
      >
        <TextField
          autoFocus={!isNewPricing(defaultPricing) && !pricing.label}
          value={pricing.label || ''}
          onChangeValue={(label) => setValue({ label })}
          isReadOnly={!isEditable}
          isInvalid={!pricing.label}
        />
      </Field>

      <Field
        label={'Description'}
        helperMessage={'Visible to users and on website'}
      >
        <TextField
          value={pricing.description || ''}
          onChangeValue={(description) => setValue({ description })}
          isReadOnly={!isEditable}
        />
      </Field>

      <Field
        label={'Description of hours'}
        isRequired={!!pricing.isPublic}
        helperMessage={'Visible to users and on website'}
      >
        <TextField
          value={pricing.hoursDescription || ''}
          onChangeValue={(hoursDescription) => setValue({ hoursDescription })}
          placeholder={'Includes X hours, every additional hour is $YY'}
          isReadOnly={!isEditable}
          isInvalid={!!pricing.isPublic && !pricing.hoursDescription}
        />
      </Field>

      <Field
        label={'Description of price'}
        isRequired={!!pricing.isPublic}
        helperMessage={'Visible to users and on website'}
      >
        <TextField
          value={pricing.priceDescription || ''}
          placeholder={'$999,999,999.99/month'}
          onChangeValue={(priceDescription) => setValue({ priceDescription })}
          isReadOnly={!isEditable}
          isInvalid={!!pricing.isPublic && !pricing.priceDescription}
        />
      </Field>

      <Field
        label={'Selling Points Notice'}
        helperMessage={'Displayed above list of selling points'}
      >
        <TextField
          value={pricing.sellingPointsNotice || ''}
          placeholder={'Everything in XXX, plus:'}
          onChangeValue={(sellingPointsNotice) =>
            setValue({ sellingPointsNotice })
          }
          isReadOnly={!isEditable}
        />
      </Field>

      <Field
        label={'Selling points'}
        isRequired={!!pricing.isPublic}
        helperMessage={'One per line - Visible to users and on website'}
      >
        <TextArea
          value={pricing.sellingPoints?.join('\n') || ''}
          onChangeValue={(sellingPoints) => {
            setValue({ sellingPoints: sellingPoints.split(/\n|\r/g) })
          }}
          isReadOnly={!isEditable}
          isInvalid={
            !!pricing.isPublic && !pricing.sellingPoints?.join('').trim()
          }
        />
      </Field>

      <Field
        label={'"Learn More" Link'}
        helperMessage={'Visible to users but NOT on website'}
      >
        <TextField
          value={pricing.learnMoreUrl || ''}
          placeholder={'https://withdouble.com/pricing'}
          onChangeValue={(learnMoreUrl) => setValue({ learnMoreUrl })}
          type={'url'}
          isReadOnly={!isEditable}
        />
      </Field>

      <Field label={'Can it be shared by team members?'}>
        <ToggleOuter>
          <Toggle
            size={'large'}
            isChecked={!!pricing.isSharable}
            onChange={() => setValue({ isSharable: !pricing.isSharable })}
            isDisabled={!isEditable}
          />
          {!!pricing.isSharable ? 'Is ' : 'Is not '}
          {'shareable'}
        </ToggleOuter>

        {pricing.isSharable && (
          <>
            <ToggleOuter>
              <Toggle
                size={'large'}
                isChecked={!!pricing.includesTeamFeatures}
                onChange={() =>
                  setValue({
                    includesTeamFeatures: !pricing.includesTeamFeatures,
                  })
                }
                isDisabled={!isEditable}
              />
              {!!pricing.includesTeamFeatures ? 'Has ' : 'Does not have '}{' '}
              {'Team Features'}
            </ToggleOuter>
            <Field label={'How many +1s can get instant workspace access?'}>
              <ToggleOuter>
                <TextField
                  type={'number'}
                  value={pricing.instantInviteMaxUsers || 0}
                  min={0}
                  max={10}
                  onChangeValue={(instantInviteMaxUsers) =>
                    setValue({
                      instantInviteMaxUsers: Number(instantInviteMaxUsers),
                    })
                  }
                  isReadOnly={!isEditable}
                  isInvalid={
                    !!pricing?.instantInviteMaxUsers &&
                    pricing?.instantInviteMaxUsers > 10
                  }
                />
              </ToggleOuter>
            </Field>
          </>
        )}
      </Field>

      <Field label={'Includes Premium Perks?'}>
        <ToggleOuter>
          <Toggle
            size={'large'}
            isChecked={!!pricing.includesPremiumPerks}
            onChange={() =>
              setValue({
                includesPremiumPerks: !pricing.includesPremiumPerks,
              })
            }
            isDisabled={!isEditable}
          />
          {!!pricing.includesPremiumPerks ? 'Includes ' : 'Does not include '}{' '}
          {'Premium Perks'}
        </ToggleOuter>
      </Field>

      <Field
        label={'A valid plan for users to be on'}
        helperMessage={
          'A package that is unavailable can not be assigned to any users. To display on website it must be made available AND public.'
        }
      >
        <ToggleOuter>
          <Toggle
            size={'large'}
            isChecked={!!pricing.isAvailable}
            onChange={() =>
              setValue({
                isAvailable: !pricing.isAvailable,
                isPublic:
                  !pricing.isAvailable === false ? false : pricing.isPublic,
              })
            }
            isDisabled={!isEditable}
          />
          {!!pricing.isAvailable ? 'Is ' : 'Is not '}
          {'available'}
        </ToggleOuter>
      </Field>

      {pricing.isAvailable && (
        <Field
          label={'Visibility to app users and on website for selection'}
          helperMessage={
            'A package that is not public can still be seen by users on it.'
          }
        >
          <ToggleOuter>
            <Toggle
              size={'large'}
              isChecked={!!pricing.isPublic}
              onChange={() =>
                setValue({
                  isAvailable: pricing.isAvailable,
                  isPublic: !pricing.isPublic,
                })
              }
              isDisabled={!isEditable}
            />
            {!!pricing.isPublic ? 'Is ' : 'Is not '}
            {'public'}
          </ToggleOuter>
        </Field>
      )}

      {!areEquivalentObjects(pricing, defaultPricing) && (
        <Buttons>
          <ButtonGroup>
            <Button appearance={'primary'} onClick={savePricing}>
              {'Save'}
            </Button>
            <Button onClick={cancelEdit}>{'Cancel'}</Button>
          </ButtonGroup>
        </Buttons>
      )}

      <LoadingSpinner show={loading} />
    </Outer>
  )
}

export default Configuration
