import { Banner } from '@atlaskit/page-layout'
import { colors } from '@atlaskit/theme'
import React from 'react'
import styled from 'styled-components'

const Inner = styled.div`
  background-color: ${colors.yellow};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const NotProdBanner = () =>
  window.location.hostname.includes('beta.') ||
  window.location.hostname.includes('wip.') ? (
    <Banner>
      <Inner>{'This is a test environment, not production.'}</Inner>
    </Banner>
  ) : null

export default NotProdBanner
