import React from 'react'
import styled from 'styled-components'

import {
  DropdownItem,
  DropdownItemGroup,
  MoreDropdownMenu,
} from '../../../../components/DropdownMenu'
import { InvoicingReportFragment } from '../../../../graphql'
import { formatAmount, formatHours } from '../../Contractors/utils'
import {
  getReportTotals,
  InvoiceEdit,
  ReportSummary,
  ReportsQuery,
} from '../utils'

import exportToCsv from './exportToCsv'

const Outer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: end;
  padding: 0 8px;
`

const Spacer = styled.div`
  flex: 0 0 32px;
`

type Props = {
  query: ReportsQuery
  reports: ReportSummary[]
  edits: Record<InvoicingReportFragment['id'], InvoiceEdit | null>
}

const Totals = ({ reports, edits, query }: Props) => {
  const summary = reports.reduce(
    (summary, report) => {
      const { id } = report
      if (!edits[id]?.isExcluded) {
        const { hours, amount } = getReportTotals(report, edits[id])
        summary.count++
        summary.hours += hours
        summary.amount += amount
      }

      return summary
    },
    { count: 0, amount: 0, hours: 0 },
  )

  const formater = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return (
    <Outer>
      {formater.format(summary.count)}
      {'/'}
      {formater.format(reports.length)}
      {' invoices'}
      <Spacer />
      {formatHours(summary.hours)}
      <Spacer />
      {formatAmount(summary.amount, 'USD')}
      <Spacer />
      <MoreDropdownMenu>
        <DropdownItemGroup>
          <DropdownItem
            isDisabled={!summary.count}
            onClick={() => {
              const filteredReports = reports.filter(
                (r) => !edits[r.id]?.isExcluded,
              )
              exportToCsv(filteredReports, edits, query)
            }}
          >
            {'Export to CSV'}
          </DropdownItem>
        </DropdownItemGroup>
      </MoreDropdownMenu>
    </Outer>
  )
}

export default Totals
