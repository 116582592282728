import React from 'react'

import { Matching_ForTransitionListItemFragment } from '../../../../../../graphql'

import StageButton from './components/StageButton'

type Props = {
  matching: Matching_ForTransitionListItemFragment
}

const Changeover = ({ matching }: Props) => {
  const completions = [
    matching.onboarding.isCompleted,
    matching.transition?.didFollowUpWithNewAssistantAt,
    matching.transition?.didRequestExecutiveFeedbackAt,
    matching.transition?.didSwitch1PasswordAt,
    matching.transition?.didSwitchAssistantsAt,
    matching.completedAt,
  ]

  return (
    <StageButton matching={matching} stageId={'changeover'}>{`${
      completions.filter(Boolean).length
    }/${completions.length}`}</StageButton>
  )
}

export default Changeover
