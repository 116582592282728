import React from 'react'

import Badge from '../../../../components/Badge'
import { Matching_ApplicationsIdFragment } from '../../../../graphql'

type Props = {
  matching: Matching_ApplicationsIdFragment
}

const ApplicationsBadge = ({ matching }: Props) => {
  return (
    <Badge
      text={matching.applications.length || 0}
      emoji={'💁'}
      title={'Applications'}
    />
  )
}

export default ApplicationsBadge
