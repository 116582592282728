import { ModalHeader as _ModalHeader } from '@atlaskit/modal-dialog'
import { colors } from '@atlaskit/theme'
import styled from 'styled-components'

import _Modal from '../../../../../../components/Modal'

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding-bottom: 20px;
`

export const Modal = styled(_Modal)`
  padding: 20px;
`

export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

export const ScrollButton = styled.button`
  background-color: ${colors.N20};
  border: none;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
`
