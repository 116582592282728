import { ApolloError } from '@apollo/client'
import Spinner from '@atlaskit/spinner'
import TableTree, { Cell, Headers, Row, Rows } from '@atlaskit/table-tree'
import Tooltip from '@atlaskit/tooltip'
import { useCallback, useState } from 'react'

import { LoadingSpinner } from '../../../../components/Spinner'
import { AdhocTodoFragment } from '../../../../graphql'
import { useOnHasScrolledToBottomEffect } from '../../../../lib/useHasScrolledToBottom'

import Actions from './cells/Actions'
import Assistant from './cells/Assistant'
import ConversationThread from './cells/ConversationThread'
import Dates from './cells/Dates'
import StatusBadge from './cells/StatusBadge'
import TokenCount from './cells/TokenCount'
import ExecutiveWithFeedback from './cells/executive/ExecutiveWithFeedback'
import {
  Outer,
  Header,
  RowsContainer,
  SpinnerOuter,
  RowContainer,
} from './styled'

enum HEADER_WIDTH {
  X_SMALL = 1,
  SMALL = 1.5,
  MEDIUM = 2,
  LARGE = 3,
}

type Header = {
  label: string
  width: HEADER_WIDTH
  sortable: boolean
}

const HEADERS: Header[] = [
  {
    label: 'Status',
    width: HEADER_WIDTH.SMALL,
    sortable: true,
  },
  {
    label: 'Customer',
    width: HEADER_WIDTH.LARGE,
    sortable: true,
  },
  {
    label: 'Dates',
    width: HEADER_WIDTH.LARGE,
    sortable: true,
  },
  {
    label: 'Double',
    width: HEADER_WIDTH.LARGE,
    sortable: true,
  },
  {
    label: 'Token Count',
    width: HEADER_WIDTH.X_SMALL,
    sortable: false,
  },
  {
    label: 'Task Name',
    width: HEADER_WIDTH.LARGE,
    sortable: false,
  },
  {
    label: 'Convo',
    width: HEADER_WIDTH.X_SMALL,
    sortable: false,
  },
  {
    label: 'Action',
    width: HEADER_WIDTH.SMALL,
    sortable: false,
  },
]

const getHeaderWidthPercentage = (width: HEADER_WIDTH) => {
  const totalNbUnit = HEADERS.reduce((acc, header) => acc + header.width, 0)
  return (width / totalNbUnit) * 100
}

type Props = {
  items: any[]
  sort: boolean
  loading: boolean
  error: ApolloError | undefined
  hasMore: boolean
  fetchMore: () => void
}

const Table = ({ sort, items, loading, error, hasMore, fetchMore }: Props) => {
  const [rowsContainerRefState, setRowsContainerRefState] =
    useState<HTMLDivElement>()
  const onHasScrollToBottom = useCallback(() => {
    if (hasMore) fetchMore()
  }, [fetchMore, hasMore])
  useOnHasScrolledToBottomEffect(rowsContainerRefState, onHasScrollToBottom, {
    offset: 300,
    dependencies: [hasMore],
  })

  return (
    <Outer>
      <TableTree>
        <Headers>
          {HEADERS.map((header) => (
            <Header
              key={header.label}
              width={`${getHeaderWidthPercentage(header.width)}%`}
              $isSortable={sort && header.sortable}
            >
              {header.label}
            </Header>
          ))}
        </Headers>
        {loading && <LoadingSpinner />}
        {error && (
          <div>
            {'Error: '}
            {error.message}
          </div>
        )}
        <RowsContainer ref={setRowsContainerRefState as any}>
          <Rows
            items={items}
            render={(item: AdhocTodoFragment) => (
              <RowContainer key={item.id} $isHidden={item.isHiddenFromPool}>
                <Row itemId={item.id}>
                  <Cell>
                    <StatusBadge
                      status={item.status}
                      isHidden={item.isHiddenFromPool}
                    />
                  </Cell>
                  <Cell>
                    <ExecutiveWithFeedback
                      executive={item.executiveUser || item.executive}
                      showFeedback={item.status === 'DONE'}
                      rating={item.feedback?.rating}
                      comments={item.feedback?.ratingComment}
                    />
                  </Cell>
                  <Cell>
                    <Dates
                      createdAt={item.createdAt}
                      completedAt={item.completedAt}
                      updatedAt={item.updatedAt}
                    />
                  </Cell>
                  <Cell>
                    <Assistant
                      assistant={item.dispatchedTo}
                      feedback={item.completionReport?.notes}
                    />
                  </Cell>
                  <Cell>
                    <TokenCount
                      checkoutLineItem={item.checkoutSession?.lineItems?.[0]}
                    />
                  </Cell>
                  <Cell>
                    <Tooltip content={item.title}>
                      <span>
                        {/* Only show the first 64char of item.title */}
                        {`${item.title?.substring(0, 64)}${
                          (item.title?.length ?? 0) > 64 ? '...' : ''
                        }`}
                      </span>
                    </Tooltip>
                  </Cell>
                  <Cell>
                    <ConversationThread
                      title={`${item.title || 'Message thread'} for ${
                        item.executive?.givenName
                      }`}
                      conversationThreads={item.communicationThreads}
                    />
                  </Cell>
                  <Cell>
                    <Actions
                      adhocId={item.id}
                      removeDoubleDisabled={item.status === 'DONE'}
                      hasDouble={!!item.dispatchedTo}
                      hasSandboxDouble={
                        item.dispatchedTo?.category === 'SANDBOX'
                      }
                      isHiddenFromPool={item.isHiddenFromPool}
                      isPool={item.isPool}
                    />
                  </Cell>
                </Row>
              </RowContainer>
            )}
          />
          {hasMore && (
            <Row>
              <SpinnerOuter>
                <Spinner />
              </SpinnerOuter>
            </Row>
          )}
        </RowsContainer>
      </TableTree>
    </Outer>
  )
}

export default Table
