import {
  ErrorMessage,
  Label as AkLabel,
  HelperMessage as AkHelperMessage,
} from '@atlaskit/form'
import React, { ReactNode } from 'react'

import { PromptInputContainer } from '../pages/apps/Copilot/ExecCopilot/styled'

import { TextArea } from './form'

type Props = {
  value?: string
  isDisabled?: boolean
  onChange?: (value?: null | string) => void
  errorMessage?: string
  defaultValue?: string
  helperMessage?: ReactNode
  className?: string
}

const FunctionsEditor = ({
  className,
  value,
  isDisabled,
  onChange,
  errorMessage,
  defaultValue,
  helperMessage,
}: Props) => {
  return (
    <PromptInputContainer className={className}>
      <AkLabel htmlFor={'area-function-definitions'}>
        <span>{'Function Definitions'}</span>
      </AkLabel>
      <TextArea
        value={value}
        onChangeValue={onChange}
        name={'area'}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        isInvalid={!!errorMessage}
      />
      {helperMessage && <AkHelperMessage>{helperMessage}</AkHelperMessage>}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </PromptInputContainer>
  )
}

export default FunctionsEditor
