import Avatar, { AvatarItem } from '@atlaskit/avatar'
import Button from '@atlaskit/button'
import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { AirtableLinkButton } from '../../../../components/AirtableButton'
import Badge from '../../../../components/Badge'
import { getCefrLabel } from '../../../../components/CefrScaleSelect'
import { LeverOpportunityButton } from '../../../../components/LeverButton'
import MarkdownEditor from '../../../../components/MarkdownEditor'
import { getLanguageName } from '../../../../components/languages/utils'
import { useSkillset } from '../../../../components/skillset/utils'
import AssistantScore from '../../../../components/users/AssistantScore'
import AverageFeedback from '../../../../components/users/AverageFeedback'
import UserCell from '../../../../components/users/Cell'
import { daysAgo } from '../../../../components/utils'
import { isAssistantable, isAssistantUser } from '../../../../graphql'
import { useUpdateUserMutation } from '../../../../graphql/generated/hooks'
import useSwitch from '../../../../lib/useSwitch'
import { getAssistantCountryRateCurrency } from '../Matching/utils'

import {
  Card,
  Value,
  Gutter,
  Profile,
  CardHeader,
  Attributes,
  Attribute,
  Paragraph,
} from './styled'
import { Criteria, Settings, MatcherAssistant } from './types'
import {
  isLanguageInCriteria,
  isEnglishLevelInCriteria,
  isIndustryInCriteria,
  isPersonalityInCriteria,
  getAssistantScore,
  getTimeZonesDiff,
  isToolInCriteria,
  isBonusSkillsetRating,
  isMalusSkillsetRating,
  isAverageWeeklyHoursUnderThreshold,
  isLatestOnboardingRecent,
  isHoursNeededInCriteria,
  getAverageWeeklyHoursRange,
  getAssistantTimeZones,
  didApply,
  didDecline,
} from './utils'

const Buttons = styled.div`
  padding: 8px 8px 8px;
  display: flex;
  justify-content: space-between;
`

type AssistantProfileProps = {
  assistant: MatcherAssistant
  settings: Settings
  criteria: Criteria
  noScore?: boolean
  motivation?: string | null | undefined
  onQueue?: (assistantId: string) => void
}

export const AssistantProfile = ({
  assistant,
  criteria,
  settings,
  noScore,
  motivation,
  onQueue,
}: AssistantProfileProps) => {
  const [isExpanded, , , toggleExpand] = useSwitch(false)
  const { skillNameById } = useSkillset()

  const [updateUser, updateUserResult] = useUpdateUserMutation()

  if (!isAssistantable(assistant)) {
    return null
  }

  const score = getAssistantScore(assistant, criteria, settings)
  const timeZones = getAssistantTimeZones(assistant)
  const projectedAverageWeeklyHours = getAverageWeeklyHoursRange(assistant)
  const secondaryText = getAssistantCountryRateCurrency(
    assistant.city?.address?.countryCode,
    assistant.currency,
    assistant.hourlyRate,
  )

  return (
    <Profile isExpanded={false}>
      <CardHeader>
        <AvatarItem
          avatar={<Avatar src={assistant.profile.avatarUrl!} />}
          primaryText={assistant.profile.displayName}
          href={`/users/${assistant.id}`}
          target={'_blank'}
          secondaryText={secondaryText}
        />
        {/* <Spacer /> */}
        {didApply(assistant.id, criteria) && (
          <Badge tint={'green'} isLight text={'Applied'} />
        )}
        {didDecline(assistant.id, criteria) && (
          <Badge tint={'red'} isLight text={'Declined'} />
        )}
        {isAssistantUser(assistant) && (
          <>
            <AverageFeedback user={assistant} />
            <AssistantScore
              score={assistant.performanceScoreValue || undefined}
            />
          </>
        )}
        {isExpanded && (
          <>
            <Gutter />
            {!!assistant.airtableDoubleRecordId && (
              <AirtableLinkButton
                tableId={'tblKOLWZVttrQZChT'}
                recordId={assistant.airtableDoubleRecordId}
              />
            )}
            {!!assistant.leverId && (
              <LeverOpportunityButton opportunityId={assistant.leverId} />
            )}
          </>
        )}
        <Gutter />
        {!noScore && (
          <Badge tint={score > 0 ? 'blue' : undefined} isLight text={score} />
        )}
        <a
          href={`https://app.mode.com/withdouble/reports/979fc58b8621?run=now&param_double_id=${assistant.id}`}
          target={'_blank'}
        >
          <Badge tint={'none'} text={'👑'} />
        </a>
        <Gutter />
      </CardHeader>
      <Attributes>
        {!!isExpanded && (
          <Attribute emoji={'📝'}>
            <MarkdownEditor
              appearance={assistant?.matchingNotepad ? 'subtle' : 'default'}
              bottomToolbar
              value={assistant?.matchingNotepad || null}
              onChangeDebounceMillis={1000}
              onChange={(matchingNotepad) =>
                updateUser({
                  variables: {
                    userId: assistant.id,
                    input: {
                      matchingNotepad,
                    },
                  },
                })
              }
              isInvalid={!!updateUserResult.error}
              isDisabled={!!updateUserResult.loading}
            />
          </Attribute>
        )}
        {!!motivation && (
          <Attribute emoji={'💁'}>
            <Value>{motivation}</Value>
          </Attribute>
        )}
        {!!assistant.dsm && (
          <Attribute emoji={'🧑‍🏫'}>
            <UserCell
              size={isExpanded ? 'small' : 'xsmall'}
              user={assistant.dsm}
            />
          </Attribute>
        )}
        <Attribute emoji={'✋'}>
          <Value
            striked={!assistant.isOpenToMatch}
            highlight={!!(criteria.isMatch && assistant.isOpenToMatch)}
          >
            {'Match'}
          </Value>
          {', '}
          <Value
            striked={!assistant.isOpenToSupport}
            highlight={!!(criteria.isSupport && assistant.isOpenToSupport)}
          >
            {'Support'}
          </Value>
          {!!assistant.openToUpdatedAt &&
            ` (as of ${daysAgo(assistant.openToUpdatedAt)} days ago)`}
        </Attribute>
        <NavLink
          to={`/users/${assistant.id}/reporting`}
          target={'_blank'}
          title={'Go to reporting'}
        >
          <Attribute emoji={'⏳'}>
            <Value
              highlight={isAverageWeeklyHoursUnderThreshold(
                projectedAverageWeeklyHours,
                settings,
              )}
            >
              {`Weekly hours: ${assistant.averageWeeklyWorkedHours}`}
              {(projectedAverageWeeklyHours.from !==
                assistant.averageWeeklyWorkedHours ||
                projectedAverageWeeklyHours.to !==
                  assistant.averageWeeklyWorkedHours) &&
                ` (projected: ${projectedAverageWeeklyHours.from} to ${projectedAverageWeeklyHours.to})`}
            </Value>
            <br />
            <Value
              highlight={isHoursNeededInCriteria(
                assistant.targetTotalWeeklyHours,
                projectedAverageWeeklyHours,
                criteria,
              )}
            >
              {`Target: ${assistant.targetTotalWeeklyHours}`}
              {!!assistant.targetAdditionalWeeklyHours &&
                ` (wants additional: ${assistant.targetAdditionalWeeklyHours})`}
            </Value>
          </Attribute>
        </NavLink>
        <Attribute emoji={'🎰'}>
          <Value
            highlight={isLatestOnboardingRecent(
              assistant.latestOnboardingAt,
              settings,
            )}
            bad={isLatestOnboardingRecent(
              assistant.latestOnboardingAt,
              settings,
            )}
          >
            {`${
              isLatestOnboardingRecent(assistant.latestOnboardingAt, settings)
                ? 'Has a'
                : 'No'
            } recent or pending onboarding`}
          </Value>
        </Attribute>
        {!!timeZones.all.length && (
          <Attribute emoji={'🕓'}>
            {!!timeZones.current.length && (
              <>
                {'Currently matched in: '}
                {timeZones.current.map((tz, index) => (
                  <Fragment key={tz}>
                    {index > 0 && ', '}
                    <Value
                      highlight={getTimeZonesDiff(tz, criteria.timeZone) < 1}
                      good={tz === criteria.timeZone}
                    >
                      {tz}
                    </Value>
                  </Fragment>
                ))}
                <br />
              </>
            )}
            {!!timeZones.other.length && (
              <>
                {'Ok to work in: '}
                {timeZones.other.map((tz, index) => (
                  <Fragment key={tz}>
                    {index > 0 && ', '}
                    <Value
                      highlight={getTimeZonesDiff(tz, criteria.timeZone) < 1}
                    >
                      {tz}
                    </Value>
                  </Fragment>
                ))}
              </>
            )}
          </Attribute>
        )}

        <Attribute emoji={'📍'}>
          <Value>{assistant.city?.address?.formatted || 'MISSING'}</Value>
        </Attribute>

        {assistant.languages.join(', ') && (
          <Attribute emoji={'🗣'}>
            {assistant.languages.map((code, index) => (
              <Fragment key={code}>
                {index > 0 && ', '}
                <Value highlight={isLanguageInCriteria(code, criteria)}>
                  {getLanguageName(code)}
                </Value>
              </Fragment>
            ))}
          </Attribute>
        )}
        {assistant.englishLevel && (
          <Attribute emoji={'🗣'}>
            {'English: '}
            <Value
              highlight={isEnglishLevelInCriteria(
                assistant.englishLevel,
                criteria,
              )}
            >
              {getCefrLabel(assistant.englishLevel)}
            </Value>
          </Attribute>
        )}

        {((isExpanded && Object.keys(assistant.skillsetRating || {}).length) ||
          !!criteria.skillset?.length) && (
          <Attribute emoji={'🎯'}>
            {(isExpanded
              ? Object.keys(assistant.skillsetRating || {})
              : criteria.skillset || []
            ).map((skillsetId, index) => (
              <Fragment key={skillsetId}>
                {index > 0 && <br />}
                <Value
                  highlight={
                    criteria.skillset?.includes(skillsetId) &&
                    (isBonusSkillsetRating(
                      assistant.skillsetRating?.[skillsetId],
                      settings,
                    ) ||
                      isMalusSkillsetRating(
                        assistant.skillsetRating?.[skillsetId],
                        settings,
                      ))
                  }
                >
                  {skillNameById(skillsetId) + ': '}
                  <Value
                    good={isBonusSkillsetRating(
                      assistant.skillsetRating?.[skillsetId],
                      settings,
                    )}
                    bad={isMalusSkillsetRating(
                      assistant.skillsetRating?.[skillsetId],
                      settings,
                    )}
                  >
                    {assistant.skillsetRating?.[skillsetId] || '–'}
                  </Value>
                </Value>
              </Fragment>
            ))}
          </Attribute>
        )}

        {assistant.industries.join(', ') && (
          <Attribute emoji={'🏢'}>
            {assistant.industries.map((industry, index) => (
              <Fragment key={industry}>
                {index > 0 && ', '}
                <Value highlight={isIndustryInCriteria(industry, criteria)}>
                  {industry}
                </Value>
              </Fragment>
            ))}
          </Attribute>
        )}
        {assistant.interestedInIndustries.join(', ') && (
          <Attribute emoji={'🧑‍🎓'}>
            {assistant.interestedInIndustries.map((industry, index) => (
              <Fragment key={industry}>
                {index > 0 && ', '}
                <Value highlight={isIndustryInCriteria(industry, criteria)}>
                  {industry}
                </Value>
              </Fragment>
            ))}
          </Attribute>
        )}
        {assistant.experienceInTools.join(', ') && (
          <Attribute emoji={'🛠'}>
            {assistant.experienceInTools.map((tool, index) => (
              <Fragment key={tool}>
                {index > 0 && ', '}
                <Value highlight={isToolInCriteria(tool, criteria)}>
                  {tool}
                </Value>
              </Fragment>
            ))}
          </Attribute>
        )}
        {assistant.personalityTraits.join(', ') && (
          <Attribute emoji={'🎭'}>
            {assistant.personalityTraits.map((trait, index) => (
              <Fragment key={trait}>
                {index > 0 && ', '}
                <Value highlight={isPersonalityInCriteria(trait, criteria)}>
                  {trait}
                </Value>
              </Fragment>
            ))}
          </Attribute>
        )}

        {isExpanded && assistant.skills.join(', ') && (
          <Attribute emoji={'💪'}>
            {assistant.skills.map((str, index) => (
              <Fragment key={str}>
                {index > 0 && ', '}
                <Value>{str}</Value>
              </Fragment>
            ))}
          </Attribute>
        )}

        {isExpanded && assistant.biography && (
          <Attribute emoji={'📖'}>
            <Paragraph>{assistant.biography}</Paragraph>
          </Attribute>
        )}

        {isExpanded && assistant.funFacts && (
          <Attribute emoji={'😍'}>
            <Paragraph>{assistant.funFacts}</Paragraph>
          </Attribute>
        )}
      </Attributes>

      <Buttons>
        <Button
          onClick={toggleExpand}
          appearance={'subtle'}
          spacing={'compact'}
        >
          {isExpanded ? 'See less' : 'See more'}
        </Button>
        {!!onQueue && (
          <Button
            spacing={'compact'}
            onClick={() => onQueue(assistant.id)}
            appearance={'primary'}
          >
            {'Add to queue'}
          </Button>
        )}
      </Buttons>
    </Profile>
  )
}

type AssistantCardProps = AssistantProfileProps

const AssistantCard = (props: AssistantCardProps) => {
  if (!isAssistantable(props.assistant)) {
    return null
  }

  return (
    <Card>
      <AssistantProfile {...props} />
    </Card>
  )
}

export default AssistantCard
