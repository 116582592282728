import { FormSection } from '@atlaskit/form'
import React, { useMemo } from 'react'

import { TestingFilter } from '../..'
import { Field, TextField } from '../../../../../components/form'
import { notFalsy } from '../../../../../lib/utils'
import AutomationTriggerSelect from '../../../Automations/components/TriggerSelect'
import AutomationSelect from '../AutomationSelect'

import { Outer } from './styled'

type FilterEditorProps = {
  filters: TestingFilter
  onPatch: (patch: Partial<TestingFilter>) => void
}

const FilterEditor = ({ filters, onPatch }: FilterEditorProps) => {
  const parameters = useMemo(() => {
    return filters.parameters.filter(notFalsy)
  }, [filters.parameters])

  return (
    <Outer>
      <FormSection title={'Automation Details'}>
        <Field label={'Trigger'}>
          <AutomationTriggerSelect
            valueId={filters.triggerId}
            onChangeValue={(newValue) => {
              onPatch({
                triggerId: newValue?.id,
                automationIds: [],
                parameters:
                  newValue?.parameters.map((param) =>
                    param ? { ...param, value: '' } : null,
                  ) || [],
              })
            }}
            isClearable
          />
        </Field>
        <Field label={'Automations to schedule'}>
          <AutomationSelect
            valueIds={filters.automationIds}
            filters={filters}
            onChangeValueIds={(newValue) => {
              onPatch({
                automationIds: newValue,
              })
            }}
            isDisabled={!filters.triggerId}
          />
        </Field>
      </FormSection>
      {parameters.length ? (
        <FormSection title={'Parameters'}>
          {parameters.map(({ name, type, isRequired, value }) => (
            <Field key={name} label={name} isRequired={isRequired}>
              <TextField
                placeholder={type}
                value={value}
                onChange={({ currentTarget: { value: newValue } }) => {
                  onPatch({
                    parameters: parameters.map((param) =>
                      param.name === name
                        ? {
                            ...param,
                            value: newValue,
                          }
                        : param,
                    ),
                  })
                }}
              />
            </Field>
          ))}
        </FormSection>
      ) : null}
    </Outer>
  )
}

export default FilterEditor
