import DisplayDate from './DisplayDate'
import { Outer } from './styled'

type AdhocDate = string | null | undefined | Date

type Props = {
  createdAt: AdhocDate
  completedAt: AdhocDate
  updatedAt: AdhocDate
}

const Dates = ({ createdAt, completedAt, updatedAt }: Props) => {
  return (
    <Outer>
      <DisplayDate title={'Created'} date={createdAt} />
      <DisplayDate title={'Completed'} date={completedAt} />
      <DisplayDate title={'Updated'} date={updatedAt} />
    </Outer>
  )
}
export default Dates
