import React from 'react'

import Badge from '../../../../components/Badge'
import { Matching_IsTransitionFragment } from '../../../../graphql'

type Props = {
  matching: Matching_IsTransitionFragment
}

const TransitionBadge = ({ matching }: Props) => {
  if (!matching.isTransition) {
    return null
  }

  return <Badge emoji={'🔀'} title={'Transition'} />
}

export default TransitionBadge
