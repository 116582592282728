import { ContractorReportFragment } from '../../../graphql'

export enum SortKey {
  GIVEN_NAME = 'GIVEN_NAME',
  FAMILY_NAME = 'FAMILY_NAME',
}

export const TIME_ZONE = {
  abbr: 'ET',
  iana: 'America/New_York',
}

export enum ReportsSection {
  ASSISTANTS_USA = 'ASSISTANTS_USA',
  VENDORS_USA = 'VENDORS_USA',
  ASSISTANTS_EU = 'ASSISTANTS_EU',
  OTHERS = 'OTHERS',
}

export type ReportsQuery = {
  from: Date
  to: Date
}

export const SECTIONS: { value: ReportsSection; label: string }[] = [
  {
    value: ReportsSection.ASSISTANTS_USA,
    label: 'Assistants USA',
  },
  {
    value: ReportsSection.VENDORS_USA,
    label: 'Vendors USA',
  },
  {
    value: ReportsSection.ASSISTANTS_EU,
    label: 'Assistants Europe',
  },
  {
    value: ReportsSection.OTHERS,
    label: 'Others',
  },
]

export function getReportDisplayName(
  report: ContractorReportFragment,
): string | null | undefined {
  return report.user?.profile.displayName
}

function getReportSortField(
  report: ContractorReportFragment,
  sortKey: SortKey,
): string | null | undefined {
  return report.user?.profile.displayName?.split(' ')[
    sortKey === SortKey.FAMILY_NAME ? 1 : 0
  ]
}

export function sortReports(
  reports: ContractorReportFragment[],
  sortKey: SortKey,
): typeof reports {
  return [...reports].sort((a, b) => {
    const aField = getReportSortField(a, sortKey) || ''
    const bField = getReportSortField(b, sortKey) || ''

    return aField < bField ? -1 : 1
  })
}

export function getReportSection(
  report: ContractorReportFragment,
): ReportsSection {
  if (report?.user?.city?.address?.countryCode === 'US') {
    if (report.user.justworksVendorName) {
      return ReportsSection.VENDORS_USA
    } else {
      return ReportsSection.ASSISTANTS_USA
    }
  }
  return ReportsSection.ASSISTANTS_EU
  // return ReportsSection.OTHERS
}

export function isValidQuery(
  query: Partial<ReportsQuery> | undefined,
): query is ReportsQuery {
  return Boolean(
    query?.from?.valueOf?.() && query.to?.valueOf?.() && query.from < query.to,
  )
}

export function areSameQueries(
  query1: Partial<ReportsQuery> | undefined,
  query2: Partial<ReportsQuery> | undefined,
): boolean {
  return Boolean(
    query1?.from?.valueOf?.() === query2?.from?.valueOf?.() &&
      query1?.to?.valueOf?.() === query2?.to?.valueOf?.(),
  )
}

export function formatAmount(
  amount: number | undefined | null,
  currencyCode?: string | undefined | null,
): string {
  if (typeof amount !== 'number') {
    return ''
  }

  if (isNaN(amount)) {
    return ''
  }

  if (!currencyCode) {
    return new Intl.NumberFormat('en-US').format(amount)
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
  }).format(amount)
}

export function formatCurrency(currencyCode: string | undefined): string {
  if (currencyCode === 'USD') {
    return '$'
  }

  if (currencyCode === 'EUR') {
    return '€'
  }

  return currencyCode || ''
}

export function formatHours(
  hours: number | undefined,
  withUnit = true,
): string {
  if (typeof hours !== 'number') {
    return ''
  }

  if (isNaN(hours)) {
    return ''
  }

  return (
    new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(hours) + (withUnit ? ' hours' : '')
  )
}

export function getContractorEmail(
  report: ContractorReportFragment,
): string | undefined | null {
  return report.user?.primaryEmail
}

export function getTotalAmount(report: ContractorReportFragment): number {
  return report.totalHours * (report.user?.hourlyRate || 0)
}
