import { typography } from '@atlaskit/theme'
import React from 'react'
import styled from 'styled-components'

import { Dl, Dt, Dd } from '../../../../components/DlDtDd'
import { LoadingSpinner } from '../../../../components/Spinner'
import Toggle, { ToggleOuter } from '../../../../components/Toggle'
import { Select } from '../../../../components/form'
import {
  Matching_CatalogVisibilityFragment,
  Matching_IsHighTouchFragment,
  useUpdateMatchingMutation,
} from '../../../../graphql'

const Notice = styled.p`
  ${typography.h100}
`

type Matching = Matching_IsHighTouchFragment &
  Matching_CatalogVisibilityFragment

interface Props {
  matching: Matching
}

const Configuration = ({ matching }: Props) => {
  const [updateMatching, updateMatchingMutation] = useUpdateMatchingMutation()

  return (
    <Dl>
      <Dt>{'Is visible in catalog?'}</Dt>
      <Dd>
        <ToggleOuter>
          <Toggle
            isChecked={!matching.isHiddenFromCatalog}
            onChange={() => {
              updateMatching({
                variables: {
                  input: {
                    matchingId: matching.id,
                    isHiddenFromCatalog: !matching.isHiddenFromCatalog,
                  },
                },
              })
            }}
          />
        </ToggleOuter>
      </Dd>

      <Dt>{'Is High Touch? 👑'}</Dt>
      <Dd>
        <ToggleOuter>
          <Toggle
            isChecked={matching.isHighTouch}
            onChange={() => {
              updateMatching({
                variables: {
                  input: {
                    matchingId: matching.id,
                    isHighTouch: !matching.isHighTouch,
                  },
                },
              })
            }}
          />
        </ToggleOuter>
      </Dd>

      <Dt>{'Number of candidates (assistants who accept the match)'}</Dt>
      <Dd>
        <Select<number>
          isRadio
          isSearchable={false}
          isClearable={false}
          isCreatable={false}
          options={[{ value: 1 }, { value: 2 }]}
          value={{ value: matching.targetCandidatesCount }}
          // @ts-ignore
          getOptionLabel={(o) => o.value}
          // @ts-ignore
          getOptionValue={(o) => o.value}
          onChangeValue={(targetCandidatesCount) => {
            updateMatching({
              variables: {
                input: {
                  matchingId: matching.id,
                  targetCandidatesCount,
                },
              },
            })
          }}
        />
        <Notice>
          {
            'If 1, then the first assistant to accept the match will be confirmed and we move to onboarding.'
          }
        </Notice>
        <Notice>
          {
            'If 2, then we require 2 assistants to accept the match, then send then to the executive to schedule intro calls and select one. Usually used for high touch transitions.'
          }
        </Notice>
      </Dd>

      <LoadingSpinner show={updateMatchingMutation.loading} />
    </Dl>
  )
}

export default Configuration
