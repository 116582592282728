// @ts-ignore
import { Group as AkGroup } from '@atlaskit/navigation-next'
import AkPageHeader from '@atlaskit/page-header'
import React, { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

import Spinner from '../../../../components/Spinner'
import {
  CategoryCopilotPromptSettingsFragment,
  isCategoryCopilotPromptSettings,
  TaskCopilotPromptSettingsFragment,
  useAssistantCopilotQuery,
} from '../../../../graphql'
import useIsAllowedOperation from '../../../../hooks/useIsAllowedOperation'
import {
  ASSISTANT_COPILOT_CATEGORIES,
  ASSISTANT_COPILOT_EVERYDAY_TASKS,
  ASSISTANT_EVERYDAY_TASKS_SYSTEM_PROMPT,
  ASSISTANT_EXEC_TASKS_SYSTEM_PROMPT,
  EXEC_COPILOT,
  EXPORT_CHAT,
} from '../ROUTES'

import Item from './Item'
import { Outer, ListOuter } from './styled'

type Group = {
  category:
    | '🚢 Export Chat'
    | '🖥️  Dashboard common Copilot Prompts'
    | '⏱️  Assistant - System Prompts'
    | '🗂️ Assistant - Tags'
    | '🧑‍💻 Assistant - Everyday Tasks'
  items: {
    label: string
    path: string
  }[]
}

const toItem = (
  prompt:
    | CategoryCopilotPromptSettingsFragment
    | TaskCopilotPromptSettingsFragment,
) => {
  const {
    __typename,
    id,
    label,
    firstCalledFunctionInfo,
    functions,
    systemPrompt,
    overridePrompts,
  } = prompt

  const hasChanges =
    !!functions?.length ||
    !!systemPrompt ||
    !firstCalledFunctionInfo ||
    (firstCalledFunctionInfo.functionName === null &&
      !firstCalledFunctionInfo.inerhitIfNull) ||
    firstCalledFunctionInfo.functionName ||
    overridePrompts.length > 0

  return {
    label: `${label}${hasChanges ? ' - 📝' : ''}`,
    path: `${
      isCategoryCopilotPromptSettings(prompt)
        ? ASSISTANT_COPILOT_CATEGORIES
        : ASSISTANT_COPILOT_EVERYDAY_TASKS
    }/${id}`,
  }
}

const DEFAULT_GROUPS: Group[] = [
  {
    category: '🖥️  Dashboard common Copilot Prompts',
    items: [
      {
        label: 'Exec copilot',
        path: EXEC_COPILOT,
      },
    ],
  },
  {
    category: '⏱️  Assistant - System Prompts',
    items: [
      {
        label: 'Everyday Tasks',
        path: ASSISTANT_EVERYDAY_TASKS_SYSTEM_PROMPT,
      },
      {
        label: 'Dashboard Tasks',
        path: ASSISTANT_EXEC_TASKS_SYSTEM_PROMPT,
      },
    ],
  },
]

const List = () => {
  const isAllowed = useIsAllowedOperation()
  const canExportChatToStudio = isAllowed('Mutation.exportChatToStudio')
  const { data, loading } = useAssistantCopilotQuery()

  const { url } = useRouteMatch()
  const groups = useMemo((): Group[] => {
    const groups = [...DEFAULT_GROUPS]

    if (canExportChatToStudio) {
      groups.unshift({
        category: '🚢 Export Chat',
        items: [
          {
            label: 'Export to Axflow Studio',
            path: EXPORT_CHAT,
          },
        ],
      })
    }

    if (!data || loading) {
      return groups
    }

    return groups.concat([
      {
        category: '🗂️ Assistant - Tags',
        items: data.assistantCopilot.categoryPrompts.map(toItem),
      },
      {
        category: '🧑‍💻 Assistant - Everyday Tasks',
        items: data.assistantCopilot.taskPrompts.map(toItem),
      },
    ])
  }, [canExportChatToStudio, data, loading])

  return (
    <Outer>
      <AkPageHeader>{'🤖 Copilot'}</AkPageHeader>
      <ListOuter>
        {loading ? (
          <Spinner />
        ) : (
          groups.map(({ category, items }) => (
            <AkGroup key={category} heading={category}>
              {items.map(({ label, path }) => (
                <Item key={path} text={label} href={`${url}${path}`} />
              ))}
            </AkGroup>
          ))
        )}
      </ListOuter>
    </Outer>
  )
}

export default List
