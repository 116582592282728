// @ts-ignore
import { Group } from '@atlaskit/navigation-next'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import PageHeader from '../../../components/PageHeader'
import ItemWithNavlink from '../../../components/list/ItemWithNavlink'
import ListContainer from '../../../components/list/ListContainer'
import ListOuter from '../../../components/list/ListOuter'
import PageHeaderOuter from '../../../components/list/PageHeaderOuter'
import {
  PricingFragment,
  ListPricingsDocument,
  ListPricingsQuery,
  useListQueryCache,
} from '../../../graphql'

import { generateNewPricing } from './utils'

const Outer = styled(ListContainer)`
  min-width: 280px;
  max-width: 400px;
`

interface Props {
  pricings: PricingFragment[]
  selectedPricingId?: PricingFragment['id']
}

const PricingsList = ({ pricings: allPricings }: Props) => {
  const { upsertItem: upsertPricing } = useListQueryCache<
    ListPricingsQuery,
    PricingFragment
  >(ListPricingsDocument)
  const navHistory = useHistory()

  const createPricing = useCallback(() => {
    const newPricing = generateNewPricing()

    upsertPricing(newPricing)

    navHistory.push(`/pricings/${newPricing.id}`)
  }, [navHistory, upsertPricing])

  const pricings = allPricings

  return (
    <Outer>
      <PageHeaderOuter>
        <PageHeader
          createActions={[
            {
              label: 'Pricing',
              onClick: createPricing,
            },
          ]}
        >
          {'Pricing Packages'}
        </PageHeader>
      </PageHeaderOuter>
      <ListOuter>
        <Group heading={'Public'}>
          {pricings
            .filter(({ isPublic }) => !!isPublic)
            .map((pricing) => (
              <ItemWithNavlink
                key={pricing.id}
                href={`/pricings/${pricing.id}`}
                isSelected={false}
                text={pricing.label}
                subText={pricing.ref}
              />
            ))}
        </Group>
        <Group heading={'Hidden'}>
          {pricings
            .filter(({ isPublic, isAvailable }) => !isPublic && isAvailable)
            .map((pricing) => (
              <ItemWithNavlink
                key={pricing.id}
                href={`/pricings/${pricing.id}`}
                isSelected={false}
                text={pricing.label}
                subText={pricing.ref}
              />
            ))}
        </Group>
        <Group heading={'Unavailable'}>
          {pricings
            .filter(({ isAvailable }) => !isAvailable)
            .map((pricing) => (
              <ItemWithNavlink
                key={pricing.id}
                href={`/pricings/${pricing.id}`}
                isSelected={false}
                text={pricing.label}
                subText={pricing.ref}
              />
            ))}
        </Group>
      </ListOuter>
    </Outer>
  )
}

export default PricingsList
