import sortBy from 'lodash/sortBy'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ErrorEmptyState } from '../../../components/EmptyState'
import PageHeader from '../../../components/PageHeader'
import { LoadingSpinner } from '../../../components/Spinner'
import ItemWithNavlink from '../../../components/list/ItemWithNavlink'
import ListContainer from '../../../components/list/ListContainer'
import ListOuter from '../../../components/list/ListOuter'
import PageHeaderOuter from '../../../components/list/PageHeaderOuter'
import {
  useListAdminRolesQuery,
  AdminRoleFragment,
  useListQueryCache,
  ListAdminRolesQuery,
  ListAdminRolesDocument,
} from '../../../graphql'

import { NEW_ADMIN_ROLE_ID, PATH } from './utils'

const Outer = styled(ListContainer)`
  min-width: 280px;
  max-width: 400px;
`

const AdminRolesList = () => {
  const { upsertItem: upsertLocalAdminRole } = useListQueryCache<
    ListAdminRolesQuery,
    AdminRoleFragment
  >(ListAdminRolesDocument)
  const navHistory = useHistory()
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>()

  const { data, loading, error } = useListAdminRolesQuery()

  const createAdminRole = useCallback(() => {
    const newAdminRole: Required<AdminRoleFragment> = {
      __typename: 'AdminRole',
      id: NEW_ADMIN_ROLE_ID,
      name: '',
      description: '',
      inheritedRoles: [],
      operations: [],
    }

    upsertLocalAdminRole(newAdminRole)

    navHistory.push(`${PATH}/${newAdminRole.id}`)
  }, [navHistory, upsertLocalAdminRole])

  const roles = useMemo(() => {
    return sortBy(data?.list.items || [], 'name').filter(
      (role) =>
        !searchTerm ||
        [role.id, role.description, role.name]
          .join(' ')
          .toLowerCase()
          .includes(searchTerm.toLowerCase().trim()),
    )
  }, [data, searchTerm])

  return (
    <Outer>
      <PageHeaderOuter>
        <PageHeader
          createActions={[
            {
              label: 'Role',
              onClick: createAdminRole,
            },
          ]}
          searchTerm={searchTerm}
          onChangeSearchTerm={setSearchTerm}
        >
          {'Admin Roles'}
        </PageHeader>
      </PageHeaderOuter>
      <ListOuter>
        {error ? (
          <ErrorEmptyState error={error} />
        ) : !data || loading ? (
          <LoadingSpinner />
        ) : (
          roles.map((adminRole) => (
            <ItemWithNavlink
              key={adminRole.id}
              href={`${PATH}/${adminRole.id}`}
              isSelected={false}
              text={adminRole.name || '(blank)'}
            />
          ))
        )}
      </ListOuter>
    </Outer>
  )
}

export default AdminRolesList
