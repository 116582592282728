import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'

const ONE_MINUTE = 60 * 1000

type Options = {
  interval?: number
  zone?: string
}

const useNow = ({
  interval = ONE_MINUTE,
  zone = 'local',
}: Options = {}): DateTime => {
  const [now, setNow] = useState(() => DateTime.now().setZone(zone))

  useEffect(
    () => {
      const intervalId = setInterval(() => {
        setNow(DateTime.now().setZone(zone))
      }, interval)

      return () => clearInterval(intervalId)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return now
}

export default useNow
