import React from 'react'

import InlineMessage from '../../../../components/InlineMessage'
import { LoadingSpinner } from '../../../../components/Spinner'
import {
  BasicWorkspaceFragment,
  useGetWorkspaceOnboardingQuery,
} from '../../../../graphql'

import InitialStage from './InitialStage'
import Onboarding from './Onboarding'
import TemporaryCoverage from './TemporaryCoverage'

interface Props {
  workspace: BasicWorkspaceFragment
}

const DelegationPath = ({ workspace }: Props) => {
  const {
    data,
    loading: loadingOnboarding,
    error,
  } = useGetWorkspaceOnboardingQuery({
    variables: {
      workspaceId: workspace.id,
    },
    // To force refresh
    fetchPolicy: 'network-only',
  })

  const onboarding = data?.workspace.onboarding

  return (
    <>
      {!!error && <InlineMessage type={'error'} title={error.message} />}
      {!!data && <Onboarding data={data} />}

      {!!(data?.workspace && onboarding?.isCompleted) && (
        <InitialStage data={data} />
      )}

      {!!(data?.workspace && onboarding?.isCompleted) && (
        <TemporaryCoverage data={data} />
      )}

      <LoadingSpinner show={loadingOnboarding} />
    </>
  )
}

export default DelegationPath
