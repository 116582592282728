import { DatePicker } from '@atlaskit/datetime-picker'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { ToggleStateless } from '../../../../components/Toggle'
import { Field } from '../../../../components/form'
import {
  MultiUserSelect as UnstyledMultiUserSelect,
  OptionType as UserOptionType,
} from '../../../../components/users/Select'
import { UserCategory } from '../../../../graphql'
import useValues, { Setters } from '../../../../lib/useValues'
import { Filter } from '../types'

const TIME_ZONE = {
  abbr: 'ET',
  iana: 'America/New_York',
}

const Outer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & > * {
    min-width: 140px;
    margin-right: 12px;

    :last-child {
      margin-right: 0px;
    }
  }
`

const MultiUserSelect = styled(UnstyledMultiUserSelect)`
  min-width: 240px;
`

const ShowDoubleTasksOuter = styled.div`
  padding-top: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const useTimeEntriesFilters = () =>
  useValues<Filter>(
    {
      assistantIds: [],
      executiveIds: [],
      showDoubleTasksOnly: false,
      endedAfter: undefined,
      endedBefore: undefined,
    },
    [
      'assistantIds',
      'executiveIds',
      'showDoubleTasksOnly',
      'endedAfter',
      'endedBefore',
    ],
  )

export type Props = {
  filter: Filter
  setters: Setters<Filter>
}

const FilterInputs = ({ filter, setters }: Props) => {
  const [now, setNow] = useState(DateTime.local())
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(DateTime.local())
    }, 30 * 60 * 1000) // 30 minutes

    return () => clearInterval(interval)
  })

  return (
    <Outer>
      <Field label={'From (12:00am ET)'}>
        <DatePicker
          placeholder={'Select a date'}
          value={filter.endedAfter?.toISODate()}
          maxDate={filter.endedBefore?.toISODate() || now.toISODate()}
          onChange={(date) => {
            setters.endedAfter(
              date
                ? DateTime.fromISO(date, { zone: TIME_ZONE.iana })
                : undefined,
            )
          }}
        />
      </Field>
      <Field label={'To (11:59pm ET)'}>
        <DatePicker
          placeholder={'Select a date'}
          value={filter.endedBefore?.toISODate()}
          minDate={filter.endedAfter?.toISODate() || undefined}
          maxDate={now.toISODate()}
          onChange={(date) => {
            setters.endedBefore(
              date
                ? DateTime.fromISO(date, { zone: TIME_ZONE.iana }).set({
                    hour: 23,
                    minute: 59,
                  })
                : undefined,
            )
          }}
        />
      </Field>
      <Field label={'Executive'}>
        <MultiUserSelect
          keepInputValueOnSelect
          categories={[
            UserCategory.EXECUTIVE,
            UserCategory.SANDBOX,
            UserCategory.TEAM,
          ]}
          isArchived={null}
          isDisabled={filter.showDoubleTasksOnly}
          value={filter.executiveIds.map((id) => ({ id }))}
          onChange={(users: UserOptionType[]) =>
            setters.executiveIds((users || []).map(({ id }) => id))
          }
        />
        <ShowDoubleTasksOuter>
          <ToggleStateless
            isChecked={filter.showDoubleTasksOnly}
            onChange={() => {
              const newShowDoubleTasksOnly = !filter.showDoubleTasksOnly
              setters.showDoubleTasksOnly(newShowDoubleTasksOnly)
              if (newShowDoubleTasksOnly) {
                setters.executiveIds([])
              }
            }}
            label={'Show Double Tasks'}
          />
          <span>{'Show Double Tasks only'}</span>
        </ShowDoubleTasksOuter>
      </Field>
      <Field label={'Assistant'}>
        <MultiUserSelect
          isArchived={null}
          categories={[UserCategory.ASSISTANT, UserCategory.SANDBOX]}
          value={filter.assistantIds.map((id) => ({ id }))}
          onChange={(users: UserOptionType[]) =>
            setters.assistantIds((users || []).map(({ id }) => id))
          }
        />
      </Field>
    </Outer>
  )
}

export default FilterInputs
