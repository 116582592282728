import Button from '@atlaskit/button'
import {
  Label as AkLabel,
  HelperMessage as AkHelperMessage,
} from '@atlaskit/form'
import AkSelect from '@atlaskit/select'
import { compact, isEqual } from 'lodash'
import React, { useMemo } from 'react'

import FunctionsEditor from '../../../../../components/FunctionEditor'
import { SaveButtons, TextArea } from '../../../../../components/form'
import {
  CopilotPromptSettingsFragment,
  CopilotFunction,
} from '../../../../../graphql'
import useValues from '../../../../../lib/useValues'
import { deepCleanTypename } from '../../../../../lib/utils'
import { PromptInputContainer } from '../../ExecCopilot/styled'

import { Outer, PromptsContainer } from './styled'

type EditablePrompt = Omit<CopilotPromptSettingsFragment, 'functions'> & {
  functions?: string
}

const toPrompt = ({
  functions,
  ...prompt
}: EditablePrompt): CopilotPromptSettingsFragment => ({
  ...prompt,
  functions: functions ? JSON.parse(functions) : undefined,
})

type Props = {
  title: string
  canInherit?: boolean
  prompt: CopilotPromptSettingsFragment
  onSavePrompt: (
    newPrompt: Omit<CopilotPromptSettingsFragment, '__typename'>,
  ) => void
  isSaving: boolean
  helperMessage?: string
  availableFunctions?: CopilotFunction[] | null
  functionEditorHelperMessage?: string
  onDelete?: () => void
}

const Editor = ({
  title,
  canInherit = true,
  prompt: initialPrompt,
  onSavePrompt,
  availableFunctions,
  isSaving,
  helperMessage,
  functionEditorHelperMessage,
  onDelete,
}: Props) => {
  const initialSystemPrompt = initialPrompt?.systemPrompt ?? null
  const initialStringifiedFunctions = useMemo(
    () =>
      initialPrompt?.functions
        ? JSON.stringify(deepCleanTypename(initialPrompt.functions), null, 2)
        : '',
    [initialPrompt?.functions],
  )
  const initialFirstCalledFunctionInfo = initialPrompt.firstCalledFunctionInfo

  const [newPrompt, { setters, reset, isValid }] = useValues<EditablePrompt>(
    {
      systemPrompt: initialSystemPrompt,
      functions: initialStringifiedFunctions,
      firstCalledFunctionInfo: initialFirstCalledFunctionInfo,
    },
    ['functions', 'systemPrompt', 'firstCalledFunctionInfo'],
    {
      validate: ({ functions }) => {
        if (!functions) return true

        try {
          JSON.parse(functions)
          return true
        } catch (e) {
          return false
        }
      },
      handleUndefined: true,
    },
  )

  const firstCalledFunctionsOptions = useMemo(
    () =>
      compact([
        { label: 'None', value: 'None' },
        canInherit ? { label: 'Inherit', value: 'Inherit' } : undefined,
        ...(availableFunctions || []).map(({ name }) => ({
          label: name,
          value: name,
        })),
      ]),
    [availableFunctions, canInherit],
  )

  const isSaveButtonDisabled = useMemo(
    () =>
      !isValid ||
      (newPrompt.systemPrompt === initialSystemPrompt &&
        newPrompt.functions === initialStringifiedFunctions &&
        isEqual(
          deepCleanTypename(newPrompt.firstCalledFunctionInfo),
          deepCleanTypename(initialFirstCalledFunctionInfo),
        )),
    [
      initialFirstCalledFunctionInfo,
      initialStringifiedFunctions,
      initialSystemPrompt,
      isValid,
      newPrompt.firstCalledFunctionInfo,
      newPrompt.functions,
      newPrompt.systemPrompt,
    ],
  )

  return (
    <Outer>
      <h1>{title}</h1>
      <PromptsContainer>
        <PromptInputContainer>
          <AkLabel htmlFor={'prompt-input'}>{'Prompt'}</AkLabel>
          <TextArea
            isDisabled={isSaving}
            value={newPrompt.systemPrompt ?? undefined}
            onChangeValue={setters.systemPrompt}
          />
          {helperMessage && <AkHelperMessage>{helperMessage}</AkHelperMessage>}
        </PromptInputContainer>

        <FunctionsEditor
          errorMessage={isValid ? undefined : 'Invalid JSON'}
          isDisabled={isSaving}
          value={newPrompt.functions ?? undefined}
          onChange={setters.functions}
          helperMessage={functionEditorHelperMessage}
        />
      </PromptsContainer>

      <AkLabel htmlFor={'select-first-call-function'}>
        {'Call a function with first call'}
      </AkLabel>
      <AkSelect
        id={'select-first-call-function'}
        defaultValue={
          initialFirstCalledFunctionInfo.functionName
            ? {
                label: initialFirstCalledFunctionInfo.functionName,
                value: initialFirstCalledFunctionInfo.functionName,
              }
            : initialFirstCalledFunctionInfo.inerhitIfNull
            ? {
                label: 'Inherit',
                value: 'Inherit',
              }
            : {
                label: 'None',
                value: 'None',
              }
        }
        onChange={(option) => {
          setters.firstCalledFunctionInfo(
            option?.value === 'None'
              ? {
                  functionName: null,
                  inerhitIfNull: false,
                }
              : option?.value === 'Inherit'
              ? {
                  functionName: null,
                  inerhitIfNull: true,
                }
              : {
                  functionName: option?.value,
                  inerhitIfNull: false,
                },
          )
        }}
        options={firstCalledFunctionsOptions}
      />

      <SaveButtons
        isDisabled={isSaveButtonDisabled}
        isLoading={isSaving}
        onCancel={reset}
        onSave={() => onSavePrompt(toPrompt(newPrompt))}
      />

      {onDelete && (
        <div>
          <Button appearance={'danger'} onClick={onDelete}>
            {'Delete'}
          </Button>
        </div>
      )}
    </Outer>
  )
}

export default Editor
