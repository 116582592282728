import Button from '@atlaskit/button'
import { Label } from '@atlaskit/form'
import InfoIcon from '@atlaskit/icon/glyph/info'
import Select, { OptionType } from '@atlaskit/select'
import Toggle from '@atlaskit/toggle'
import Tooltip from '@atlaskit/tooltip'
import { useCallback, useMemo, useState } from 'react'

import FunctionsEditor from '../../../../components/FunctionEditor'
import { Field, SaveButtons, TextArea } from '../../../../components/form'
import {
  CopilotFragment,
  useGetCopilotQuery,
  useUpdateCopilotSettingsMutation,
} from '../../../../graphql'
import useIsAllowedOperation from '../../../../hooks/useIsAllowedOperation'
import useSwitch from '../../../../lib/useSwitch'
import useValues from '../../../../lib/useValues'
import { deepCleanTypename } from '../../../../lib/utils'

import {
  ButtonOuter,
  FieldsRow,
  MessageCapField,
  Outer,
  PromptInputContainer,
  PromptsContainer,
  ToggleInputContainer,
} from './styled'

// options from 1 to 10
const MESSAGE_CAP_OPTIONS: OptionType[] = Array.from(
  { length: 10 },
  (_, i) => i + 1,
).map((i) => ({
  label: String(i),
  value: i,
}))

const canParseJSON = (str: string) => {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}

const ExecCopilot = () => {
  const isAllowed = useIsAllowedOperation()
  const hasEditPermission = isAllowed('Mutation.updateCopilotSettings')
  const [inEditMode, startEdit, stopEdit, , ,] = useSwitch(false)
  const [copilotErrorMessage, setCopilotErrorMessage] = useState<string>()

  const { data, loading, error } = useGetCopilotQuery()
  const functionsEnabled = !!data?.copilot.functionsEnabled
  const messagesPrompt = data?.copilot.messagesPrompt
  const functionsPrompt = data?.copilot.functionsPrompt
  const functions = useMemo(
    () =>
      // remove '__typename' from function objects
      deepCleanTypename(data?.copilot.functions),
    [data?.copilot.functions],
  )
  const updatedAt = data?.copilot.updatedAt

  const [newCopilotSettings, { setters, reset }] = useValues<
    Omit<Partial<CopilotFragment>, 'id' | 'functions'> & {
      // we want to display functions as a string in the textarea
      functions?: string
    }
  >(
    {
      messagesPrompt,
      functionsPrompt,
      functions: JSON.stringify(functions, null, 2),
    },
    ['messagesPrompt', 'functionsPrompt', 'functions'],
  )

  const [updateCopilotSettings] = useUpdateCopilotSettingsMutation()

  const onSave = useCallback(async () => {
    if (newCopilotSettings.functions) {
      if (!canParseJSON(newCopilotSettings.functions)) {
        setCopilotErrorMessage('Invalid JSON format')
        return
      }

      const functions: { name?: string; description?: string }[] = JSON.parse(
        newCopilotSettings.functions,
      )

      const offendingFunction = functions.find(
        (func) => func.description && func.description.length > 1024,
      )
      if (offendingFunction) {
        setCopilotErrorMessage(
          `${
            `"${offendingFunction.name}"` || 'A function'
          } description is too long. Max length is 1024 characters.`,
        )
        return
      }
    }
    setCopilotErrorMessage(undefined)

    stopEdit()
    if (
      !newCopilotSettings.messagesPrompt &&
      !newCopilotSettings.functionsPrompt &&
      !newCopilotSettings.functions
    ) {
      return
    }
    await updateCopilotSettings({
      variables: {
        input: {
          ...(newCopilotSettings.messagesPrompt && {
            messagesPrompt: newCopilotSettings.messagesPrompt,
          }),
          ...(newCopilotSettings.functionsPrompt && {
            functionsPrompt: newCopilotSettings.functionsPrompt,
          }),
          ...(newCopilotSettings.functions &&
            canParseJSON(newCopilotSettings.functions) && {
              functions: JSON.parse(newCopilotSettings.functions),
            }),
        },
      },
    })
  }, [newCopilotSettings, stopEdit, updateCopilotSettings])

  const onCancel = useCallback(() => {
    stopEdit()
    reset()
    setCopilotErrorMessage(undefined)
  }, [stopEdit, reset])

  const messageCapSelected = useCallback(
    async ({ value }: { value: number; label: string }) => {
      if (value !== data?.copilot.messageCap) {
        await updateCopilotSettings({
          variables: {
            input: {
              messageCap: value,
            },
          },
        })
      }
    },
    [data?.copilot.messageCap, updateCopilotSettings],
  )

  return (
    <Outer>
      <h1>{'🤖 Executive Copilot Prompt'}</h1>
      {error ? (
        <h3>{'Error loading prompt. Try again later.'}</h3>
      ) : loading || !data ? (
        <h3>{'Loading prompt...'}</h3>
      ) : (
        <>
          <ToggleInputContainer>
            <Label htmlFor={'toggle-copilot-functions'}>
              {`Copilot Functions: ${
                functionsEnabled ? 'Enabled' : 'Disabled'
              }`}
            </Label>
            <Toggle
              size={'large'}
              isChecked={functionsEnabled}
              onChange={() => {
                updateCopilotSettings({
                  variables: {
                    input: {
                      functionsEnabled: !functionsEnabled,
                    },
                  },
                })
              }}
            />
          </ToggleInputContainer>
          <PromptsContainer>
            <PromptInputContainer>
              <Label htmlFor={'area-messages-prompt'}>
                {'Messages Prompt'}
              </Label>
              <TextArea
                value={newCopilotSettings.messagesPrompt}
                onChangeValue={setters.messagesPrompt}
                name={'area'}
                defaultValue={messagesPrompt}
                isDisabled={
                  !hasEditPermission || functionsEnabled || !inEditMode
                }
              />
            </PromptInputContainer>
            <PromptInputContainer>
              <Label htmlFor={'area-functions-prompt'}>
                {'Functions Prompt'}
              </Label>
              <TextArea
                value={newCopilotSettings.functionsPrompt}
                onChangeValue={setters.functionsPrompt}
                name={'area'}
                defaultValue={functionsPrompt}
                isDisabled={
                  !hasEditPermission || !functionsEnabled || !inEditMode
                }
              />
            </PromptInputContainer>
          </PromptsContainer>
          <PromptsContainer>
            <PromptInputContainer>
              {/* empty placeholder for positioning */}
            </PromptInputContainer>
            <FunctionsEditor
              value={newCopilotSettings.functions}
              defaultValue={JSON.stringify(functions, null, 2)}
              onChange={setters.functions}
              isDisabled={
                !hasEditPermission || !functionsEnabled || !inEditMode
              }
              errorMessage={copilotErrorMessage}
              helperMessage={
                <i>
                  {'do '}
                  <b>{'NOT'}</b>
                  {' adjust the `name` fields'}
                </i>
              }
            />
          </PromptsContainer>
          {inEditMode ? (
            <SaveButtons onCancel={onCancel} onSave={onSave} />
          ) : (
            <ButtonOuter>
              <Button
                onClick={startEdit}
                isDisabled={!hasEditPermission}
                appearance={'primary'}
              >
                {'Edit'}
              </Button>
              {!hasEditPermission && (
                <Tooltip
                  content={`You don't have permission to edit the copilot prompt.`}
                >
                  <InfoIcon label={'More info'} size={'small'} />
                </Tooltip>
              )}
            </ButtonOuter>
          )}
          <FieldsRow>
            <MessageCapField>
              <Field
                label={'Message Cap'}
                helperMessage={
                  'Number of Copilot messages before we stop prompting'
                }
              >
                <Select
                  isDisabled={!hasEditPermission}
                  value={MESSAGE_CAP_OPTIONS.find(
                    (option) => option.value === data?.copilot.messageCap,
                  )}
                  options={MESSAGE_CAP_OPTIONS}
                  // @ts-ignore
                  onChange={messageCapSelected}
                />
              </Field>
            </MessageCapField>
          </FieldsRow>
          <span>{`Last updated: ${updatedAt}`}</span>
        </>
      )}
    </Outer>
  )
}

export default ExecCopilot
