import React from 'react'
import { Link as _Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { UsersCell } from '../../../../../../components/users/Cell'
import { Matching_ForTransitionListItemFragment } from '../../../../../../graphql'
import { notFalsy } from '../../../../../../lib/utils'
import { Bundled } from '../../../utils'

const Outer = styled.span`
  display: flex;
  align-items: center;
`

const Link = styled(_Link)<{
  $isCrowned: boolean | null | undefined
  $isFadedCrowned: boolean
}>`
  ${({ $isCrowned, $isFadedCrowned }) =>
    $isCrowned === false
      ? null
      : !!($isCrowned || $isFadedCrowned) &&
        css`
          &:after {
            content: '👑';
            transform: rotate(30deg);
            position: absolute;
            top: -9px;
            left: 20px;
            font-size: 20px;
            ${$isFadedCrowned ? 'opacity: 0.4' : ''}
          }
        `}
`

type Props = {
  matching: Bundled<Matching_ForTransitionListItemFragment>
}

const Executive = ({ matching }: Props) => {
  const { executive } = matching
  if (!executive) {
    return null
  }

  const executives = matching.bundle?.length
    ? matching.bundle.map((matching) => matching.executive)
    : [matching.executive]

  return (
    <Outer>
      <Link
        to={`/users/${executive.id}/workspaces/${matching.workspace?.id || ''}`}
        target={'_blank'}
        $isCrowned={matching.isHighTouch}
        $isFadedCrowned={!matching.isHighTouch && matching.isHighTouchMaybe}
      >
        <UsersCell users={executives.filter(notFalsy)} />
      </Link>
    </Outer>
  )
}

export default Executive
