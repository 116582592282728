import { LoadingButton as Button } from '@atlaskit/button'
import { SimpleTag as Tag } from '@atlaskit/tag'
import React, { ComponentProps, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import {
  MoreDropdownMenu,
  DropdownItemGroup,
  DropdownItem,
} from '../../../components/DropdownMenu'
import { LoadingSpinner } from '../../../components/Spinner'
import { ToggleStateless as Toggle } from '../../../components/Toggle'
import AddAccountModal from '../../../components/users/AddAccountModal'
import {
  AddUserAccountInput,
  useAddUserAccountMutation,
  UserAccountFragment,
  useDeleteUserAccountMutation,
  useSelectPrimaryUserAccountMutation,
} from '../../../graphql'

import {
  Accounts as AccountsOuter,
  Account as AccountOuter,
  AccountRow,
  AccountSpacer,
  AccountTags,
  AccountIcon,
} from './styled'

interface Props extends ComponentProps<'div'> {
  accounts: UserAccountFragment[]
  selectedAccountKeys: null | string[]
  onToggleAccount: (accountId: string, checked: boolean) => void
}

interface AccountProps {
  account: UserAccountFragment
  selectable: boolean
  selected: boolean
  deletable?: boolean
  onToggle: (checked: boolean) => void
  onDelete?: () => void
  onMakePrimary?: () => void
}

export const Account = ({
  account,
  deletable,
  selectable,
  selected,
  onToggle,
  onDelete,
  onMakePrimary,
}: AccountProps) => {
  const [username, domain] = account.label.split('@')

  return (
    <AccountOuter>
      <AccountRow>
        <AccountIcon account={account} />
        {username && domain ? (
          <span>
            {username}
            {'@'}
            <b>{domain}</b>
          </span>
        ) : username ? (
          <span>{username}</span>
        ) : (
          <span>{'Fake account without email'}</span>
        )}
        <AccountSpacer />
        {selectable ? (
          <Toggle
            isChecked={selected}
            onChange={() => onToggle(!selected)}
            size={'regular'}
          />
        ) : (
          <MoreDropdownMenu>
            <DropdownItemGroup>
              <DropdownItem
                isDisabled={!!account.isPrimary || !onMakePrimary}
                onClick={onMakePrimary}
              >
                {'Make primary'}
              </DropdownItem>
              <DropdownItem
                isDisabled={!deletable || !onDelete}
                onClick={onDelete}
                description={
                  account.isPrimary
                    ? 'Cannot delete primary account'
                    : undefined
                }
              >
                {'Delete'}
              </DropdownItem>
            </DropdownItemGroup>
          </MoreDropdownMenu>
        )}
      </AccountRow>
      {(account.isDisconnected || account.isPrimary) && (
        <AccountTags>
          {account.isPrimary && <Tag color={'blueLight'} text={'Primary'} />}
          {!(account.scopes && account.scopes.length) ? (
            <Tag color={'yellowLight'} text={'Never connected'} />
          ) : (
            account.isDisconnected && (
              <Tag color={'redLight'} text={'Disconnected'} />
            )
          )}
        </AccountTags>
      )}
    </AccountOuter>
  )
}

const Accounts = ({
  accounts,
  selectedAccountKeys,
  onToggleAccount,
}: Props) => {
  const { userId } = useParams<{ userId: string }>()
  const [showModal, setShowModal] = useState(false)
  const toggleModal = useCallback(
    () => setShowModal((prev) => !prev),
    [setShowModal],
  )
  const [addUserAccount, { loading }] = useAddUserAccountMutation()
  const [deleteUserAccount, { loading: loadingDelete }] =
    useDeleteUserAccountMutation()
  const [selectPrimaryUserAccount, { loading: loadingSelectPrimary }] =
    useSelectPrimaryUserAccountMutation()

  const onSubmit = useCallback(
    (input: AddUserAccountInput) => {
      setShowModal(false)
      addUserAccount({
        variables: { userId, input },
      })
    },
    [setShowModal, userId, addUserAccount],
  )

  return (
    <AccountsOuter>
      {accounts.map((account) => (
        <Account
          key={account.key}
          account={account}
          selectable={!!selectedAccountKeys}
          deletable={accounts.length > 1 && !account.isPrimary}
          selected={(selectedAccountKeys || []).includes(account.key)}
          onToggle={(checked) => onToggleAccount(account.id, checked)}
          onDelete={() => {
            deleteUserAccount({
              variables: { input: { userId, accountKey: account.key } },
            })
          }}
          onMakePrimary={() => {
            selectPrimaryUserAccount({
              variables: { input: { userId, accountKey: account.key } },
            })
          }}
        />
      ))}
      <Button
        isLoading={loading}
        style={{ marginTop: 16 }}
        onClick={toggleModal}
      >
        {'Add Account'}
      </Button>
      <LoadingSpinner show={loadingDelete || loadingSelectPrimary} />
      <AddAccountModal
        show={showModal}
        onHide={toggleModal}
        onSubmit={onSubmit}
      />
    </AccountsOuter>
  )
}

export default Accounts
