import Button from '@atlaskit/button'
import AddIcon from '@atlaskit/icon/glyph/add'
import PageHeader from '@atlaskit/page-header'
import React, { useMemo, useCallback } from 'react'
import { RouteComponentProps, useHistory, useRouteMatch } from 'react-router'
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
} from 'react-sortable-hoc'

import { useUpsertDossierSectionMutation } from '../../../graphql'

import Section from './Section'
import { NarrowListOuter as ListOuter } from './components/styled'
import useDossier from './useDossier'
import { sortEntities, getSortedEntity } from './utils'

const SortableSection = SortableElement(Section)
const SortableListOuter = SortableContainer(ListOuter)

type Props = RouteComponentProps<{ folderId: string }>

const Sections = ({
  match: {
    params: { folderId },
  },
}: Props) => {
  const navHistory = useHistory()
  const { folders, createLocalSection } = useDossier()
  const _sections = folders?.find(({ id }) => id === folderId)?.sections

  const sections = useMemo(
    () => [...(_sections || [])].sort(sortEntities),
    [_sections],
  )

  const match = useRouteMatch<{
    0: string
    sectionId?: string
    isEditing?: string
  }>('*/sections/:sectionId?/:isEditing(edit)?')
  const [upsertSection] = useUpsertDossierSectionMutation()

  const { sectionId, isEditing } = {
    ...match?.params,
  }

  const sortSections: SortEndHandler = useCallback(
    ({ oldIndex, newIndex }) => {
      const sorted = getSortedEntity(sections, oldIndex, newIndex)
      if (sorted) {
        upsertSection({
          variables: {
            input: {
              sectionId: sorted.entity.id,
              orderIndex: sorted.orderIndex,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            upsertDossierSection: {
              __typename: 'UpsertDossierSectionOutput',
              section: {
                ...sorted.entity,
                orderIndex: sorted.orderIndex,
              },
            },
          },
        })
      }
    },
    [sections, upsertSection],
  )

  const createSection = useCallback(() => {
    const section = createLocalSection(folderId)
    navHistory.push(`${match?.params[0] || ''}/sections/${section.id}/edit`)
  }, [createLocalSection, folderId, match, navHistory])

  return (
    <SortableListOuter onSortEnd={sortSections} useDragHandle lockAxis={'y'}>
      <PageHeader
        actions={
          <Button
            iconBefore={<AddIcon label={''} />}
            appearance={'default'}
            onClick={createSection}
          />
        }
      >
        {'Sections'}
      </PageHeader>
      {sections.map((section, index) => (
        <SortableSection
          key={section.id}
          index={index}
          folderId={folderId}
          section={section}
          isSelected={sectionId === section.id}
          isEditing={!!isEditing}
        />
      ))}
    </SortableListOuter>
  )
}

export default Sections
