import { CodeBlock } from '@atlaskit/code'
import cloneDeep from 'lodash/cloneDeep'
import React from 'react'
import styled from 'styled-components'

import MarkdownEditor from '../../../../components/MarkdownEditor'
import { Field, TextField } from '../../../../components/form'
import { AutomationFragment } from '../../../../graphql'
import { deepCleanTypename } from '../../../../lib/utils'
import AutomationTriggerSelect from '../components/TriggerSelect'

const Outer = styled.div`
  padding: 8px 0;
  flex: 1;
  margin-bottom: 24px;
`

interface ConfigurationProps {
  automation: AutomationFragment
}

const Configuration = ({ automation }: ConfigurationProps) => {
  return (
    <Outer>
      <Field label={'Name'} isRequired>
        <TextField value={automation.name || ''} />
      </Field>

      <Field label={'Description'} isRequired>
        <MarkdownEditor
          appearance={'subtle'}
          value={automation.description || ''}
          onChange={console.debug}
        />
      </Field>

      <Field label={'Triggered when'} isRequired>
        <AutomationTriggerSelect
          valueId={automation.triggerOn.id}
          showDescription
        />
      </Field>

      <Field label={'Delay'} isRequired>
        <CodeBlock
          showLineNumbers={false}
          language={'json'}
          text={JSON.stringify(
            deepCleanTypename({ ...automation.delay }),
            null,
            2,
          )}
        />
      </Field>

      <Field label={'Actions'} isRequired>
        <CodeBlock
          showLineNumbers={false}
          language={'json'}
          text={JSON.stringify(
            deepCleanTypename(cloneDeep([...automation.actionGroups])),
            null,
            2,
          )}
        />
      </Field>
    </Outer>
  )
}

export default Configuration
