import { EmailTemplate } from '../../../graphql'
import { isRequiredVar } from '../../utils'

import { Email } from './types'

export { isRequiredVar, includesVar, replaceVarsInString } from '../../utils'

export function missingVars(
  { placeholders }: Pick<EmailTemplate, 'placeholders'>,
  { vars }: Partial<Email>,
): string[] {
  return placeholders.filter(
    (name) => typeof vars?.[name] !== 'string' && isRequiredVar(name),
  )
}

export function isValidEmail(
  template: Pick<EmailTemplate, 'id' | 'placeholders'>,
  email: Partial<Email>,
): email is Email {
  // Check for basic metas
  if (
    !(
      email.templateId &&
      email.templateId === template.id &&
      email.from?.email &&
      email.to?.[0]?.email
    )
  ) {
    return false
  }

  // Check placeholders
  if (missingVars(template, email).length > 0) {
    return false
  }

  return true
}
