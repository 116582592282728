import { LoadingButton as Button } from '@atlaskit/button'
import Table from '@atlaskit/dynamic-table'
import CommentIcon from '@atlaskit/icon/glyph/comment'
import InlineMessage from '@atlaskit/inline-message'
import Tag from '@atlaskit/tag'
import { colors, borderRadius } from '@atlaskit/theme'
import Tooltip from '@atlaskit/tooltip'
import { transparentize } from 'polished'
import React, { useCallback } from 'react'
import { Line } from 'react-chartjs-2'
import styled from 'styled-components'

import {
  MoreDropdownMenu as _MoreDropdownMenu,
  DropdownItemGroup,
  DropdownItem,
} from '../../../components/DropdownMenu'
import { Rating } from '../../../components/Rating'
import Avatar from '../../../components/users/Avatar'
import { DateType, formatDate } from '../../../components/utils'
import {
  useListWorkspaceFeebacksQuery,
  useSendCsatFeedbackRequestMutation,
  useDeleteFeedbackMutation,
  useUpdateCsatFeedbackMutation,
  CsatFeedbackFragment,
} from '../../../graphql'

const COLORS = [
  colors.P200,
  colors.Y200,
  colors.T200,
  colors.R200,
  colors.G200,
  colors.B200,
  colors.P400,
  colors.Y400,
  colors.T400,
  colors.R400,
  colors.G400,
  colors.B400,
]

const Outer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px 0px;
`

const ChartOuter = styled.div`
  padding: 16px 0;
`

const Tags = styled.div``

const Timestamp = styled.span`
  white-space: nowrap;
  font-size: smaller;
  font-weight: 600;
  color: ${colors.subtleText};
`

const MoreDropdownMenu = styled(_MoreDropdownMenu)`
  margin-right: 4px;
`

const Emails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CommentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.subtleText};
`

const Comment = ({ comment }: { comment: string | undefined | null }) =>
  comment ? (
    <Tooltip content={comment} position={'top'}>
      <CommentIcon label={''} />
    </Tooltip>
  ) : null

const TABLE_HEAD = {
  cells: [
    {
      content: 'Double',
      shouldTruncate: false,
    },
    {
      content: 'Rating',
      shouldTruncate: false,
    },
    {
      content: 'Date',
      shouldTruncate: false,
    },
    {
      content: 'Tags',
      shouldTruncate: false,
    },
    {
      content: 'Comments',
      shouldTruncate: false,
    },
    {
      content: '',
      shouldTruncate: false,
    },
  ],
}

interface Props {
  workspaceId: string
  executiveId: string
}

const Feedbacks = ({ workspaceId, executiveId }: Props) => {
  const { data, loading } = useListWorkspaceFeebacksQuery({
    variables: {
      workspaceId,
    },
  })

  const [
    sendFeedbackRequest,
    { data: dataSendFeedbackRequest, loading: loadingSendGeedbackRequest },
  ] = useSendCsatFeedbackRequestMutation({
    variables: {
      input: {
        userId: executiveId,
        workspaceId,
      },
    },
  })
  const maySendFeedbackRequest = useCallback(() => {
    const confirmed = window.confirm(
      'Are you sure you want to send a monthly CSAT email? Please make sure user hasn’t received one recently in their `Emails` tab.',
    )
    if (!confirmed) {
      return
    }

    sendFeedbackRequest()
  }, [sendFeedbackRequest])

  const [updateFeedback, { loading: loadingUpdate }] =
    useUpdateCsatFeedbackMutation()
  const [deleteFeedback, { loading: loadingDelete }] =
    useDeleteFeedbackMutation({
      update: (cache, { data }) => {
        const deletedFeedback = data?.deleteFeedback.feedback
        if (deletedFeedback) {
          cache.evict({
            id: cache.identify(deletedFeedback),
          })
          cache.gc()
        }
      },
    })

  const feedbacks = data?.list.items ?? []
  const isSubscribed = data?.workspace?.feedbackRequestEmail

  const groupedFeedbacks = feedbacks.reduce<CsatFeedbackFragment[][]>(
    (groups, feedback) => {
      const group = groups[groups.length - 1]

      if (
        group &&
        group[group.length - 1].assistant?.id === feedback.assistant?.id
      ) {
        group.push(feedback)
      } else {
        groups.push([feedback])
      }

      return groups
    },
    [],
  )

  return (
    <Outer>
      {isSubscribed != null && (
        <Emails>
          <InlineMessage
            title={`User is ${
              isSubscribed ? '' : 'un'
            }subscribed to CSAT emails`}
            type={isSubscribed ? 'confirmation' : 'warning'}
          />
          <Button
            appearance={'default'}
            onClick={maySendFeedbackRequest}
            isLoading={loadingSendGeedbackRequest}
            isDisabled={!!dataSendFeedbackRequest}
          >
            {dataSendFeedbackRequest
              ? 'Monthly CSAT email sent 👍'
              : 'Send monthly CSAT email'}
          </Button>
        </Emails>
      )}

      <ChartOuter>
        <Line
          height={100}
          options={{
            legend: {
              labels: {
                fontColor: '#172B4D',
              },
              position: 'bottom',
              align: 'start',
            },
            tooltips: {
              backgroundColor: transparentize(
                0.1,
                /*colors.heading()*/ '#172B4D',
              ),
              cornerRadius: borderRadius(),
            },
            scales: {
              xAxes: [
                {
                  display: false,
                  type: 'time',
                  time: {
                    unit: 'day',
                    tooltipFormat: 'll',
                  },
                  gridLines: {
                    color: colors.N40,
                  },
                  ticks: {
                    fontColor: colors.N300,
                  },
                },
              ],
              yAxes: [
                {
                  type: 'linear',
                  gridLines: {
                    color: colors.N40,
                  },
                  ticks: {
                    stepSize: 1,
                    fontColor: colors.N300,
                    min: 0,
                    max: 5,
                  },
                },
              ],
            },
          }}
          data={{
            datasets: groupedFeedbacks.map((feedbacks, index) => ({
              label:
                feedbacks[0].assistant?.profile.displayName || 'Assistant TBD',
              lineTension: 0.2,
              backgroundColor: COLORS[index],
              borderColor: COLORS[index],
              data: feedbacks.map(({ rating, createdAt }) => ({
                t: new Date(createdAt),
                y: rating || 0,
              })),
            })),
          }}
        />
      </ChartOuter>

      {groupedFeedbacks.map((feedbacks) => (
        <Table
          key={feedbacks[0].assistant?.id || Date.now()}
          isLoading={loading}
          head={TABLE_HEAD}
          rows={feedbacks.map(
            ({ id, rating, tags, createdAt, assistant, commentText }) => ({
              key: id,
              cells: [
                {
                  content: !!assistant && (
                    <Tooltip content={assistant.profile.displayName}>
                      <Avatar size={'small'} user={assistant} />
                    </Tooltip>
                  ),
                },
                {
                  content: <Rating rating={rating || 0} />,
                },
                {
                  content: (
                    <Tooltip content={formatDate(createdAt)}>
                      <Timestamp>
                        {formatDate(createdAt, DateType.SHORT)}
                      </Timestamp>
                    </Tooltip>
                  ),
                },
                {
                  content: (
                    <Tags>
                      {tags.map((tag) => (
                        <Tag key={tag} text={tag} isRemovable={false} />
                      ))}
                    </Tags>
                  ),
                },
                {
                  content: (
                    <CommentContainer>
                      <Comment comment={commentText} />
                    </CommentContainer>
                  ),
                },
                {
                  content: (
                    <MoreDropdownMenu
                      isLoading={loadingDelete || loadingUpdate}
                    >
                      <DropdownItemGroup title={'Change rating to'}>
                        {[5, 4, 3, 2, 1].map(
                          (r) =>
                            r !== rating && (
                              <DropdownItem
                                key={r}
                                onClick={() =>
                                  updateFeedback({
                                    variables: {
                                      input: {
                                        feedbackId: id,
                                        rating: r,
                                      },
                                    },
                                  })
                                }
                              >
                                <Rating rating={r} />
                              </DropdownItem>
                            ),
                        )}
                      </DropdownItemGroup>
                      <DropdownItemGroup title={'Careful 👇 💣 💥 ☠️'}>
                        <DropdownItem
                          onClick={() =>
                            deleteFeedback({
                              variables: {
                                input: { feedbackId: id },
                              },
                            })
                          }
                        >
                          {'Delete feedback'}
                        </DropdownItem>
                      </DropdownItemGroup>
                    </MoreDropdownMenu>
                  ),
                },
              ],
            }),
          )}
          emptyView={<span>{'No feedback sent yet'}</span>}
        />
      ))}
    </Outer>
  )
}

export default Feedbacks
