import { DateTime } from 'luxon'
import React, { ComponentProps } from 'react'

import ErrorBanner from '../../../../../components/ErrorBanner'
import Section from '../../../../../components/Section'
import { LoadingSpinner } from '../../../../../components/Spinner'
import {
  onlyIfAssistantUser,
  usePerformanceScoreMonthQuery,
} from '../../../../../graphql'

import MetricKindSection from './MetricKindSection'
import { AssistantScore, Button, ScoreColumn, ScoreRow } from './styled'

const SCORE_METRIC_URL =
  'https://www.notion.so/withdouble/Metrics-Sources-729add42887f4c7f8e1ca8df21481596?pvs=4'

interface Props extends ComponentProps<'div'> {
  date: string
  userId: string
}

const Score = ({ date, userId }: Props) => {
  const dateObj = DateTime.fromISO(date)
  const selectedMonth = DateTime.fromISO(date).monthLong

  const { data, error, loading } = usePerformanceScoreMonthQuery({
    variables: { userId, date },
  })

  // Get series for the month selected
  const user = onlyIfAssistantUser(data?.user)
  const selectedSeries = user?.performanceScore?.series[0]

  if (loading) return <LoadingSpinner />
  if (error) return <ErrorBanner error={error} />

  if (!selectedSeries)
    return (
      <Section>
        <h3>{`No data for ${selectedMonth}`}</h3>
      </Section>
    )

  return (
    <>
      <ScoreRow>
        <AssistantScore score={selectedSeries?.value} />
        <ScoreColumn>
          <Button
            target={'_blank'}
            href={SCORE_METRIC_URL}
            appearance={'subtle-link'}
          >
            {'Score Calculation Details'}
          </Button>
          <span>
            {`Score considers feedback for a 6 month lookback period ending ${dateObj
              .startOf('month')
              .toLocaleString(DateTime.DATE_FULL)}.`}
          </span>
        </ScoreColumn>
      </ScoreRow>

      <Section>
        {selectedSeries?.metrics.map(
          ({ id, metricKind, weight, value, eventsReviewURL }) => {
            return (
              <MetricKindSection
                key={id}
                metricKind={metricKind}
                percentage={weight || 0}
                value={value || 5}
                eventsReviewURL={eventsReviewURL}
                date={selectedSeries?.date}
                userId={userId}
              />
            )
          },
        )}
      </Section>
    </>
  )
}

export default Score
