import { DateTime } from 'luxon'
import { useMemo } from 'react'

import {
  TimeTrackingEntriesListQueryVariables,
  useTimeTrackingGeneralInfosQuery,
} from '../../../graphql/generated/hooks'

const useTimeEntriesLockedAt = (
  variables: TimeTrackingEntriesListQueryVariables,
): DateTime | undefined => {
  const { data } = useTimeTrackingGeneralInfosQuery({
    variables,
  })

  return useMemo(() => {
    const maybeISOLockedAtDate = data?.timeTrackingEntriesList.lockedAt
    if (!maybeISOLockedAtDate) return

    return DateTime.fromJSDate(new Date(maybeISOLockedAtDate))
  }, [data?.timeTrackingEntriesList.lockedAt])
}

export default useTimeEntriesLockedAt
