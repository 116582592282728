import styled from 'styled-components'

// Styled div for the email message
export const EmailMessageContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 20px;
  font-family: Arial, sans-serif;
`

// Styled paragraph for the email body
export const Body = styled.p`
  color: #666666;
  font-size: 14px;
`

// Styled paragraph for the email addresses
export const EmailAddresses = styled.p`
  color: #999999;
  font-size: 12px;
  margin-top: 5px;
`

export const Attachments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const Attachment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  color: #666666;
`
