import { useState, useCallback, useMemo, SetStateAction, Dispatch } from 'react'

export type Setters<T extends object> = {
  [P in keyof T]: (value: T[P] | null) => void
}

type Output<T extends object> = [
  T,
  {
    set: Dispatch<SetStateAction<T>>
    patch: <K extends keyof T>(patch: K | Partial<T>, value?: T[K]) => void
    reset: () => void
    setters: Setters<T>
    isValid?: boolean
  },
]

const isObject = (value: unknown): value is object => typeof value !== 'string'

const useValues = <T extends object>(
  defaultValues: T,
  setterKeys: (keyof T)[] = [],
  {
    validate,
    handleUndefined = false,
  }: {
    validate?: (o: T) => boolean
    handleUndefined?: boolean
  } = {
    handleUndefined: false,
  },
): Output<T> => {
  const [values, set] = useState<T>(defaultValues)

  const patch = useCallback(
    <K extends keyof T>(patch: K | Partial<T>, value?: T[K]) => {
      if (isObject(patch)) {
        set((prev) => ({
          ...prev,
          ...patch,
        }))
      } else {
        set((prev) => ({
          ...prev,
          [patch]: value,
        }))
      }
    },
    [set],
  )

  const reset = useCallback(() => set(defaultValues), [defaultValues])

  const setters = useMemo(
    () =>
      setterKeys.reduce(
        (setters, key) => ({
          ...setters,
          [key]: (value: T[typeof key]) =>
            set((prev) => ({
              ...prev,
              [key]: (handleUndefined ? value === null : value == null)
                ? null
                : value,
            })),
        }),
        {} as Setters<T>,
      ),
    [handleUndefined, setterKeys],
  )

  return [
    values,
    {
      set,
      patch,
      reset,
      setters,
      isValid: validate && validate(values),
    },
  ]
}

export default useValues
