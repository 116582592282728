import Button from '@atlaskit/button'
import Drawer from '@atlaskit/drawer'
import PageHeader from '@atlaskit/page-header'
import { colors } from '@atlaskit/theme'
import React, { ComponentProps, useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import Tabs from '../../../../components/Tabs'
import { useListPricingsQuery, PricingFragment } from '../../../../graphql'
import useSwitch from '../../../../lib/useSwitch'
import { isNewPricing } from '../utils'

import Configuration from './Configuration'
import Model from './Model'

const Outer = styled.div<ComponentProps<'div'>>`
  box-shadow: 0 0 0 2px ${colors.backgroundHover},
    0 0 10px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: stretch;
  padding: 0 24px;
  overflow: auto;
  min-width: 400px;
`

const TabContent = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`

interface PricingProps {
  pricing: PricingFragment
}

const Pricing = ({ pricing }: PricingProps) => {
  const [isEditable, setEditable, unsetEditable] = useSwitch(
    isNewPricing(pricing),
  )
  const tabs = [
    {
      label: 'Configuration',
      content: (
        <TabContent>
          <Configuration
            pricing={pricing}
            isEditable={isEditable}
            unsetEditable={unsetEditable}
          />
        </TabContent>
      ),
    },
    {
      label: 'Calculation',
      content: (
        <TabContent>
          <Model pricing={pricing} isEditable={isEditable} />
        </TabContent>
      ),
    },
  ]

  return (
    <>
      <PageHeader
        actions={
          isEditable ? undefined : (
            <Button onClick={setEditable}>{'Edit'}</Button>
          )
        }
      >
        {pricing.ref}
      </PageHeader>
      <Tabs leftTabs={tabs} />
    </>
  )
}

type MaybePricingProps = {
  pricingId?: PricingFragment['id']
}

const MaybePricing = ({ pricingId }: MaybePricingProps) => {
  const { data } = useListPricingsQuery({
    skip: !pricingId,
  })

  const pricing = data?.list.items.find(({ id }) => id === pricingId)

  return (
    <Outer>{!!pricing && <Pricing key={pricing.id} pricing={pricing} />}</Outer>
  )
}

export default MaybePricing

export const PricingDrawer = () => {
  const path = '/pricings'
  const navHistory = useHistory()
  const match = useRouteMatch<{ pricingId: string }>(`*${path}/:pricingId`)
  const pricingId = match?.params.pricingId

  const onClose = useCallback(() => {
    if (match?.url) {
      navHistory.replace(match.url.split(path)[0])
    }
  }, [match, navHistory])

  return (
    <Drawer onClose={onClose} isOpen={!!pricingId} width={'wide'}>
      <MaybePricing pricingId={pricingId} />
    </Drawer>
  )
}
