import ChevronIcon from '@atlaskit/icon/glyph/chevron-down'
import PageHeader from '@atlaskit/page-header'
import React, { useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { AirtableLinkButton } from '../../../../components/AirtableButton'
import EmptyState, { ErrorEmptyState } from '../../../../components/EmptyState'
import { LoadingSpinner } from '../../../../components/Spinner'
import { PopupSelect } from '../../../../components/form'
import {
  useListTransitionMatchingsQuery,
  Matching_ForTransitionListItemFragment,
  withListPagination,
  useGetMeQuery,
} from '../../../../graphql'
import useActiveMatching from '../useActiveMatching'
import { buildBundles } from '../utils'

import Table from './Table'
import { Provider as SortingProvider, Sorting } from './Table/useSorting'
import {
  getTransitionStatus,
  TransitionStatus,
  TRANSITION_STATUS_LABEL,
  ListView,
  LIST_VIEW_LABEL,
} from './utils'

const Outer = styled.div`
  padding: 0 24px 48px;
  overflow: auto;
  height: 100%;
  width: 100%;
`

const Title = styled.span`
  cursor: pointer;
`

const List = () => {
  const { matching: activeMatching } = useActiveMatching()
  const navHistory = useHistory()
  const route = useRouteMatch()
  const { data: meData } = useGetMeQuery()

  const [view, setView] = useState<ListView>(ListView.ACTIVE)
  const sortingContextValue = useState<Sorting>({
    key: 'transition.airtableTransitionRequest.completionDeadline',
  })

  const { data, loading, error, fetchMore, hasMore } = withListPagination(
    useListTransitionMatchingsQuery({
      variables: {
        isCompleted: view === ListView.ARCHIVED,
      },
    }),
  )

  const meId = meData?.me.id
  const shouldGroupByStatus = view !== ListView.ARCHIVED
  const shouldFilterMineOnly = view === ListView.MINE

  const all = useMemo(() => data?.list.items || [], [data])

  const displayed = useMemo(() => {
    const displayed = !shouldFilterMineOnly
      ? all
      : all.filter(({ transition }) =>
          Boolean(
            transition?.fromAssistant?.dsm?.id === meId ||
              transition?.csm?.id === meId,
          ),
        )

    if (view !== ListView.ARCHIVED) {
      return buildBundles(displayed)
    } else {
      return displayed
    }
  }, [shouldFilterMineOnly, all, view, meId])

  const groupedByStatus = useMemo(() => {
    return !shouldGroupByStatus
      ? {}
      : displayed.reduce<
          Partial<
            Record<TransitionStatus, Matching_ForTransitionListItemFragment[]>
          >
        >((groupedByStatus, matching) => {
          const status = getTransitionStatus(matching)
          if (!groupedByStatus[status]) {
            groupedByStatus[status] = []
          }
          groupedByStatus[status]?.push(matching)
          return groupedByStatus
        }, {})
  }, [displayed, shouldGroupByStatus])

  // 💩 Fetch ALLLLLLLL transitions
  // Should move this to dynamic loading
  if (hasMore) {
    fetchMore()
  }

  return (
    <SortingProvider value={sortingContextValue}>
      <Outer
        onClick={activeMatching ? () => navHistory.push(route.path) : undefined}
      >
        <PageHeader>
          <PopupSelect<ListView>
            options={Object.values(ListView)}
            value={view}
            getOptionLabel={(view) => LIST_VIEW_LABEL[view]}
            onChangeValue={setView}
            target={({ ref }) => (
              <Title ref={ref}>
                {LIST_VIEW_LABEL[view]} {`(${displayed.length})`}{' '}
                <ChevronIcon label={''} />
              </Title>
            )}
          />
          <AirtableLinkButton
            tableId={'tblEMytMhBXVoSSRM'}
            spacing={'compact'}
          />
        </PageHeader>
        {shouldGroupByStatus ? (
          [
            TransitionStatus.INFORMING,
            TransitionStatus.INFORMING_CHURN_RISK,
            TransitionStatus.INFORMING_DETERMINING_NEEDS,
            TransitionStatus.INFORMING_NON_RESPONSIVE,
            TransitionStatus.INFORMING_ACCEPTED,
            TransitionStatus.READY_TO_MATCH,
            TransitionStatus.MATCH_PROPOSED,
            TransitionStatus.CANDIDATES,
            TransitionStatus.MATCH_CONFIRMED,
            TransitionStatus.SCHEDULED,
            TransitionStatus.COMPLETED,
          ].map(
            (status) =>
              groupedByStatus[status]?.length && (
                <Table
                  key={status}
                  caption={`${TRANSITION_STATUS_LABEL[status]} (${groupedByStatus[status]?.length})`}
                  matchings={groupedByStatus[status]!}
                  sort
                />
              ),
          )
        ) : (
          <Table matchings={displayed} sort={false} />
        )}

        {loading ? (
          <LoadingSpinner show />
        ) : error ? (
          <ErrorEmptyState error={error} />
        ) : !displayed?.length ? (
          <EmptyState
            header={'No transitions!'}
            emoji={'🏖'}
            description={'Seat back, relax, and enjoy the absence of chaos.'}
          />
        ) : null}
      </Outer>
    </SortingProvider>
  )
}

export default List
