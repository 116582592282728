import { uniqBy } from 'lodash'
import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import {
  CopilotPromptSettingsFragment,
  CopilotPromptSettingsOverridableFragment,
  CopilotPromptSettingsOverrideFragment,
  CategoryCopilotPromptSettingsFragment,
  CopilotFunction,
  MainCopilotPromptSettingsFragment,
  TaskCopilotPromptSettingsFragment,
  TimeTrackingCategoryId,
  TimeTrackingEntryTemplateId,
} from '../../../../graphql'
import useUpdateAssistantCopilot, {
  CopilotPromptSettingsOverrideType,
} from '../assistant/useUpdateAssistantCopilot'

import Editor from './Editor'
import OverrideList from './OverrideList'
import { Outer } from './styled'

type Props = {
  promptId: string
  prompt: CopilotPromptSettingsFragment &
    CopilotPromptSettingsOverridableFragment
  helperMessage: string
  upperLevelFunctions?: CopilotFunction[] | null
  subpromptId?: TimeTrackingCategoryId | TimeTrackingEntryTemplateId
  title: string
  overridenPromptType: CopilotPromptSettingsOverrideType
}

const PromptEditor = ({
  promptId,
  helperMessage,
  prompt,
  upperLevelFunctions,
  title,
  subpromptId,
  overridenPromptType,
}: Props) => {
  const {
    updateCopilotPromptSettingsOverride,
    updateCategoryPrompt,
    updateTaskPrompt,
    deleteCopilotPromptSettingsOverride,
    updateEverydayTasksCopilotPromptSettings,
    updateExecTasksCopilotPromptSettings,
    loading: isSaving,
  } = useUpdateAssistantCopilot()

  const { url } = useRouteMatch()

  return (
    <Outer>
      <OverrideList
        overridenPromptType={overridenPromptType}
        overridePrompts={prompt.overridePrompts}
        subpromptId={subpromptId}
      />
      <Switch>
        <Route
          path={`${url}/:overridePromptId?`}
          children={({ match }) => {
            const overridePromptId = match?.params.overridePromptId

            const overridePrompt =
              overridePromptId &&
              prompt.overridePrompts?.find(
                (overridePrompt) => overridePrompt.id === overridePromptId,
              )

            if (overridePromptId && !overridePrompt) return null

            const promptToEdit = overridePrompt || prompt

            return (
              <Editor
                key={`${promptId}${overridePromptId}`}
                title={title}
                isSaving={isSaving}
                onSavePrompt={(newPrompt) => {
                  if (overridePrompt) {
                    updateCopilotPromptSettingsOverride({
                      ...(promptToEdit as CopilotPromptSettingsOverrideFragment),
                      ...newPrompt,
                    })

                    return
                  }

                  switch (overridenPromptType) {
                    case CopilotPromptSettingsOverrideType.CATEGORY:
                      return updateCategoryPrompt({
                        ...(prompt as CategoryCopilotPromptSettingsFragment),
                        ...newPrompt,
                      })
                    case CopilotPromptSettingsOverrideType.TASK:
                      return updateTaskPrompt({
                        ...(prompt as TaskCopilotPromptSettingsFragment),
                        ...newPrompt,
                      })
                    case CopilotPromptSettingsOverrideType.EVERYDAY_TASKS:
                      return updateEverydayTasksCopilotPromptSettings({
                        ...(prompt as MainCopilotPromptSettingsFragment),
                        ...newPrompt,
                      })
                    case CopilotPromptSettingsOverrideType.EXEC_TASKS:
                      return updateExecTasksCopilotPromptSettings({
                        ...(prompt as MainCopilotPromptSettingsFragment),
                        ...newPrompt,
                      })
                  }
                }}
                onDelete={
                  overridePrompt
                    ? () => {
                        if (
                          !window.confirm(
                            'Are you sure you want to delete this prompt?',
                          )
                        )
                          return

                        deleteCopilotPromptSettingsOverride(overridePromptId)
                      }
                    : undefined
                }
                availableFunctions={uniqBy(
                  [
                    // For override prompts, we also include the functions from main prompt
                    ...((overridePromptId && prompt.functions) || []),
                    ...(promptToEdit.functions || []),
                    ...(upperLevelFunctions || []),
                  ],
                  'name',
                )}
                prompt={promptToEdit}
                helperMessage={helperMessage}
                functionEditorHelperMessage={
                  'Will be appended to the upper level functions. If a function with the same name is declared here, it will override the upper level one.'
                }
                canInherit={
                  overridenPromptType ===
                    CopilotPromptSettingsOverrideType.CATEGORY ||
                  overridenPromptType === CopilotPromptSettingsOverrideType.TASK
                }
              />
            )
          }}
        ></Route>
      </Switch>
    </Outer>
  )
}

export default PromptEditor
