import { Header as _Header } from '@atlaskit/table-tree'
import { colors } from '@atlaskit/theme'
import styled, { css } from 'styled-components'

export const Outer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  & > div {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
`

export const Header = styled(_Header)<{
  $isSortable?: boolean
  $isSorted?: boolean
}>`
  ${({ $isSortable }) =>
    $isSortable &&
    css`
      cursor: pointer;
      &:after {
        content: '▾';
        margin-left: 4px;
        color: ${colors.N80};
      }
      &:hover,
      &:hover:after {
        color: ${colors.textHover};
      }
    `}

  ${({ $isSorted, $isSortable }) =>
    $isSorted &&
    $isSortable &&
    css`
      &,
      &:after {
        color: ${colors.textActive}!important;
      }
    `}
`

export const RowsContainer = styled.div`
  display: flex;
  overflow: auto;
  flex: 1 1 0;
  flex-direction: column;
`

export const RowContainer = styled.div<{ $isHidden?: boolean }>`
  ${({ $isHidden }) =>
    $isHidden &&
    css`
      background-color: ${colors.N20};
    `}
`

export const SpinnerOuter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 16px;
`

export const CenterColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
