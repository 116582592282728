import React from 'react'

import Badge from '../../../../components/Badge'
import { Matching_CompletedAtFragment } from '../../../../graphql'

type Props = {
  matching: Matching_CompletedAtFragment
  noText?: boolean
}

const CompletedBadge = ({ matching, noText }: Props) => {
  if (!matching.completedAt) {
    return null
  }

  return (
    <Badge
      emoji={'☑️'}
      title={`Completed on ${matching.completedAt}`}
      text={noText ? undefined : 'Completed'}
      tint={'green'}
    />
  )
}

export default CompletedBadge
