import { DocumentNode, QueryResult } from '@apollo/client'
import uniqBy from 'lodash/uniqBy'
import { useEffect } from 'react'

type TData = {
  item: unknown
}

const getTopLevelItem = (data: TData) => data?.item

export function useListUpdateSubscription<
  TSubscriptionData extends TData,
  T extends QueryResult,
>(
  result: T,
  subscriptionDocument: DocumentNode,
  extractItem: (data: TSubscriptionData) => unknown = getTopLevelItem,
): T {
  const { subscribeToMore, variables } = result

  useEffect(
    () =>
      subscribeToMore<TSubscriptionData>({
        document: subscriptionDocument,
        variables: variables,
        updateQuery: (prev, { subscriptionData }) => {
          const item =
            subscriptionData.data && extractItem(subscriptionData.data)
          if (!item) {
            return prev
          }
          return {
            ...prev,
            list: {
              ...prev.list,
              items: uniqBy([...(prev?.list?.items || []), item], 'id'),
            },
          }
        },
      }),
    [variables, subscribeToMore, subscriptionDocument, extractItem],
  )

  return result
}

export default useListUpdateSubscription
