import { Link as _Link } from 'react-router-dom'
import styled from 'styled-components'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`
