import { DateTime, Duration } from 'luxon'
import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  TimeTrackingEntry_BulkFragment,
  useClearAssistantHourlyRateUpdatesMutation,
  useTimeTrackingEntriesListBulkQuery,
  withListPagination,
} from '../../../../graphql'
import { csvFormater } from '../../../../lib/csv'
import {
  createWritableCSVFileStream,
  WritableFileStream,
} from '../../../../lib/writeFile'
import { TIME_ZONE } from '../../Contractors/utils'
import getTimeTrackingEntriesListQueryVariables from '../getTimeTrackingEntriesListQueryVariables'
import { Filter } from '../types'

type TimeTrackingEntryFragmentWithDateTime = TimeTrackingEntry_BulkFragment & {
  startedDateTime: DateTime
  endedDateTime?: DateTime
}

const getEntryHourlyRate = (entry: TimeTrackingEntryFragmentWithDateTime) => {
  if (!entry.user.hourlyRate) return null

  const hourlyRateUpdates = entry.user.hourlyRateUpdates
  const entryStartedAdDateTime = DateTime.fromJSDate(new Date(entry.startedAt))

  const rateAtTimeOfEntry = hourlyRateUpdates?.reduce<{
    rate: number
    durationSinceUpdate?: Duration
  }>(
    (latestRateUpdate, update) => {
      const updatedAtDateTime = DateTime.fromJSDate(
        new Date(update.updatedAt),
        // T-5602: The update should be applied to all entries entered after the
        // start of the day of the update.
        {
          zone: 'America/New_York',
        },
      ).startOf('day')

      if (updatedAtDateTime > entryStartedAdDateTime) {
        return latestRateUpdate
      }

      const currentUpdateDurationSinceUpdate =
        entryStartedAdDateTime.diff(updatedAtDateTime)

      const { durationSinceUpdate } = latestRateUpdate
      if (
        durationSinceUpdate &&
        currentUpdateDurationSinceUpdate > durationSinceUpdate
      ) {
        return latestRateUpdate
      }

      return {
        rate: update.hourlyRate,
        durationSinceUpdate: currentUpdateDurationSinceUpdate,
      }
    },
    {
      rate: entry.user.hourlyRate,
    },
  ).rate

  return rateAtTimeOfEntry?.toString() || 'hidden'
}

const toTimeTrackingEntryFragmentWithDateTime = (
  entry: TimeTrackingEntry_BulkFragment,
): TimeTrackingEntryFragmentWithDateTime => ({
  ...entry,
  startedDateTime: DateTime.fromJSDate(new Date(entry.startedAt)),
  endedDateTime: entry.endedAt
    ? DateTime.fromJSDate(new Date(entry.endedAt))
    : undefined,
})

const createAndDownloadCSV = async (
  timeEntries: TimeTrackingEntry_BulkFragment[],
  fileStream: WritableFileStream,
) => {
  const { headersLine, toLine } =
    csvFormater<TimeTrackingEntryFragmentWithDateTime>([
      {
        header: 'Entry ID',
        getter: (entry) => entry.id,
      },
      {
        header: 'Executive ID',
        getter: (entry) => entry.workspace?.executives[0]?.id,
      },
      {
        header: 'Executive',
        getter: (entry) => entry.workspace?.executives[0]?.profile.displayName,
      },
      {
        header: 'Assistant ID',
        getter: (entry) => entry.user.id,
      },
      {
        header: 'Assistant',
        getter: (entry) => entry.user.profile.displayName,
      },
      {
        header: 'Task',
        getter: (entry) => entry.title,
      },
      {
        header: 'Category ID',
        getter: (entry) => entry.tags[0]?.id,
      },
      {
        header: 'Category',
        getter: (entry) => entry.tags[0]?.label,
      },
      {
        header: 'Duration',
        getter: (entry) =>
          entry.duration && Duration.fromISO(entry.duration).toFormat('hh:mm'),
      },
      {
        header: 'Total (hours)',
        getter: (entry) =>
          // Duration in hours
          entry.duration &&
          Duration.fromISO(entry.duration).as('hours').toString(),
      },
      {
        header: 'Start Date (ET)',
        getter: (entry) =>
          entry.startedDateTime.setZone(TIME_ZONE.iana).toFormat('yyyy-MM-dd'),
      },
      {
        header: 'Start Time (ET)',
        getter: (entry) =>
          entry.startedDateTime.setZone(TIME_ZONE.iana).toFormat('HH:mm'),
      },
      {
        header: 'End Date (ET)',
        getter: (entry) =>
          entry.endedDateTime?.setZone(TIME_ZONE.iana).toFormat('yyyy-MM-dd'),
      },
      {
        header: 'End Time (ET)',
        getter: (entry) =>
          entry.endedDateTime?.setZone(TIME_ZONE.iana).toFormat('HH:mm'),
      },
      {
        header: 'Start ISO',
        getter: (entry) => new Date(entry.startedAt).toISOString(),
      },
      {
        header: 'End ISO',
        getter: (entry) =>
          entry.endedAt ? new Date(entry.endedAt).toISOString() : null,
      },
      {
        header: 'Source',
        getter: (entry) => (entry.isToggl ? 'Toggl' : 'Double'),
      },
      {
        header: 'Rate',
        getter: getEntryHourlyRate,
      },
      {
        header: 'Currency',
        getter: (entry) => entry.user.currency || '',
      },
    ])

  await fileStream.write(headersLine)

  for (const entry of timeEntries) {
    await fileStream.write(
      toLine(toTimeTrackingEntryFragmentWithDateTime(entry)),
    )
  }

  await fileStream.close()
}

const useExportToCSV = (filter: Filter): [() => void, { loading: boolean }] => {
  const [fileStream, setFileStream] = useState<WritableFileStream>()

  const { data, loading, fetchMore, hasMore } = withListPagination(
    useTimeTrackingEntriesListBulkQuery({
      variables: {
        ...getTimeTrackingEntriesListQueryVariables(filter),
        first: 1000,
      },
      skip: !fileStream,
    }),
  )

  // Keep fetching until all entries have been fetched
  useEffect(() => {
    if (!filter.endedAfter || !fileStream || !hasMore) return

    fetchMore()

    // Run this effect when `after` and `isExporting` change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.list.after, fileStream])

  useEffect(() => {
    if (!fileStream || loading || data?.list.after || !data?.list.items) {
      return
    }

    setFileStream(undefined)

    createAndDownloadCSV(data.list.items, fileStream)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, fileStream, loading]) // Do not run this effect when `filter` changes

  const exportToCSV = useCallback(async () => {
    if (fileStream || !filter.endedAfter) return

    const now = DateTime.now()
    const filename = `Time Tracking ${filter.endedAfter
      .setZone(TIME_ZONE.iana)
      .toFormat('yyyy-MM-dd')} - ${(filter.endedBefore || now)
      ?.setZone(TIME_ZONE.iana)
      .toFormat('yyyy-MM-dd')} (exported on ${now.toFormat('yyyy-MM-dd')}).csv`

    setFileStream(await createWritableCSVFileStream(filename))
  }, [fileStream, filter.endedAfter, filter.endedBefore])

  const [clearAssistantHourlyRateUpdates] =
    useClearAssistantHourlyRateUpdatesMutation()

  return useMemo(
    () => [
      async () => {
        await clearAssistantHourlyRateUpdates()
        return await exportToCSV()
      },
      {
        loading: !!fileStream,
      },
    ],
    [clearAssistantHourlyRateUpdates, exportToCSV, fileStream],
  )
}

export default useExportToCSV
