import { LoadingButton as Button } from '@atlaskit/button'
import Select from '@atlaskit/select'
import TextField from '@atlaskit/textfield'
import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import Editor from '../../../../components/MarkdownEditor'
import { LoadingSpinner } from '../../../../components/Spinner'
import { ToggleStateless as Toggle } from '../../../../components/Toggle'
import {
  Field,
  FieldsRow,
  ImageUrlField,
  SaveButtons,
} from '../../../../components/form'
import {
  TopicTemplateFragment,
  DiscoverCategory,
  useUpsertTopicTemplateMutation,
  DiscoverSectionKey,
  UpsertTopicTemplateInput,
  useListQueryCache,
  ListTopicTemplatesQuery,
  ListTopicTemplatesDocument,
  useArchiveTopicTemplateMutation,
  WorkspaceUserRole,
} from '../../../../graphql'
import { areEquivalentObjects, deepCleanTypename } from '../../../../lib/utils'
import CategorySelect from '../components/CategorySelect'
import KeywordsSelect from '../components/KeywordsSelect'
import LabelsSelect from '../components/LabelsSelect'
import { getSectionTitle, isNewTemplate } from '../utils'

const Outer = styled.div`
  padding: 8px 0;
  flex: 1;
  margin-bottom: 24px;
`

const ToggleOuter = styled.div`
  display: flex;
  align-items: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

interface ConfigurationProps {
  template: TopicTemplateFragment
}

const Configuration = ({ template: defaultTemplate }: ConfigurationProps) => {
  const [revision, setRevision] = useState(Date.now())
  const navHistory = useHistory()
  const [values, setValues] = useState<
    Omit<Partial<UpsertTopicTemplateInput>, '__typename'>
  >({
    ...(isNewTemplate(defaultTemplate)
      ? { title: '', type: defaultTemplate.type }
      : null),
  })
  const [upsertTemplate, { loading }] = useUpsertTopicTemplateMutation()
  const [archiveTemplate, { loading: loadingArchive }] =
    useArchiveTopicTemplateMutation()
  const { removeItem: removeTemplate } = useListQueryCache<
    ListTopicTemplatesQuery,
    TopicTemplateFragment
  >(ListTopicTemplatesDocument)

  const setValue = useCallback(
    (value) => {
      setValues((prev) => ({ ...prev, ...value }))
    },
    [setValues],
  )

  const resetTemplate = useCallback(() => {
    setValues({})
    setRevision(Date.now())
  }, [])

  const cancelEdit = useCallback(() => {
    setRevision(Date.now())
    if (isNewTemplate(defaultTemplate)) {
      // cancel creation of new template
      removeTemplate(defaultTemplate)
      navHistory.replace('/templates')
    } else {
      resetTemplate()
    }
  }, [defaultTemplate, navHistory, removeTemplate, resetTemplate])

  const saveTemplate = useCallback(() => {
    try {
      upsertTemplate({
        variables: {
          input: {
            // @ts-ignore
            templateId: defaultTemplate.id,
            ...deepCleanTypename(values),
          },
        },
      })
      resetTemplate()
    } catch (error) {
      console.error(error)
    }
  }, [upsertTemplate, defaultTemplate.id, values, resetTemplate])

  const mayArchive = useCallback(async () => {
    if (
      !window.confirm(
        'Archive template? If it’s a flow, the corresponding todos will be marked as done.',
      )
    ) {
      return
    }

    try {
      await archiveTemplate({
        variables: {
          input: { templateId: defaultTemplate.id },
        },
      })

      // Remove template from cache and go back to list
      removeTemplate(defaultTemplate)
      return navHistory.replace('/templates')
    } catch (error) {
      console.error(error)
    }
  }, [archiveTemplate, defaultTemplate, navHistory, removeTemplate])

  const template = {
    ...defaultTemplate,
    ...values,
  }

  return (
    <Outer>
      <Field label={'Title'} isRequired>
        <TextField
          autoFocus={!template.title}
          value={template.title || ''}
          onChange={({ currentTarget: { value: title } }) =>
            setValue({ title })
          }
        />
      </Field>

      {template.category !== DiscoverCategory.INTERNAL && (
        <Field label={'Description'}>
          <Editor
            key={(defaultTemplate.description || 'empty') + revision}
            value={template.description || ''}
            onChange={(description) => setValue({ description })}
          />
        </Field>
      )}

      <Field label={'Illustration'}>
        <ImageUrlField
          value={template.imageUrl}
          onChangeValue={(imageUrl) => setValue({ imageUrl })}
        />
      </Field>

      <Field
        label={'Chat Introduction'}
        helperMessage={'Displayed to assistants and execs in chat.'}
      >
        <Editor
          key={(defaultTemplate.introduction || 'empty') + revision}
          value={template.introduction}
          onChange={(introduction) => setValue({ introduction })}
          isInline
        />
      </Field>
      <Field label={'Chat Introduction Title'}>
        <Editor
          key={(defaultTemplate.introductionTitle || 'empty') + revision}
          value={template.introductionTitle}
          onChange={(introductionTitle) => setValue({ introductionTitle })}
          isInline
        />
      </Field>
      <Field label={'Chat Introduction Image (@3x)'}>
        <ImageUrlField
          value={template.introductionImageUrl}
          onChangeValue={(introductionImageUrl) =>
            setValue({ introductionImageUrl })
          }
        />
      </Field>

      <Field
        label={'Todo notes'}
        helperMessage={'Added to notes of todo, visible to assistants'}
      >
        <Editor
          key={(defaultTemplate.notepad || 'empty') + revision}
          value={template.notepad}
          onChange={(notepad) => setValue({ notepad })}
          isInline={false}
        />
      </Field>

      <FieldsRow>
        <Field label={'Todo will be assigned to'}>
          <Select<{ assigneeRole: WorkspaceUserRole }>
            menuPlacement={'auto'}
            value={
              template.assigneeRole
                ? { assigneeRole: template.assigneeRole }
                : undefined
            }
            creatable={false}
            isSearchable={false}
            isClearable
            options={[
              { assigneeRole: WorkspaceUserRole.ASSISTANT },
              { assigneeRole: WorkspaceUserRole.EXECUTIVE },
            ]}
            getOptionLabel={({ assigneeRole }) => assigneeRole}
            getOptionValue={({ assigneeRole }) => assigneeRole}
            isMulti={false}
            placeholder={'Assigned to'}
            // @ts-ignore Value type not well recognized
            onChange={(newValue) =>
              setValue({ assigneeRole: newValue?.assigneeRole || null })
            }
          />
        </Field>
        <Field label={'Todo will be private to'}>
          <Select<{ privateTo: WorkspaceUserRole }>
            menuPlacement={'auto'}
            value={
              template.privateTo ? { privateTo: template.privateTo } : undefined
            }
            creatable={false}
            isSearchable={false}
            isClearable
            options={[
              { privateTo: WorkspaceUserRole.ASSISTANT },
              { privateTo: WorkspaceUserRole.EXECUTIVE },
            ]}
            getOptionLabel={({ privateTo }) => privateTo}
            getOptionValue={({ privateTo }) => privateTo}
            isMulti={false}
            placeholder={'Private to'}
            // @ts-ignore Value type not well recognized
            onChange={(newValue) =>
              setValue({ privateTo: newValue?.privateTo || null })
            }
          />
        </Field>
      </FieldsRow>

      {template.category !== DiscoverCategory.INTERNAL && (
        <Field
          label={'Keywords'}
          helperMessage={'Used to match text messages.'}
        >
          <KeywordsSelect
            isCreatable
            values={template.keywords}
            onChangeValues={(keywords) => setValue({ keywords })}
          />
        </Field>
      )}

      <Field
        label={'Labels'}
        helperMessage={'For classification, metrics, tracking.'}
      >
        <LabelsSelect
          isCreatable
          values={template.labels}
          onChangeValues={(labels) => setValue({ labels })}
        />
      </Field>

      <FieldsRow>
        <Column>
          <Field label={'Category'} isRequired>
            <CategorySelect
              value={template.category || DiscoverCategory.CORE}
              onChangeValue={(category) => setValue({ category })}
            />
          </Field>

          {template.category !== DiscoverCategory.INTERNAL && (
            <Field label={'Section'} isRequired>
              <Select<{ sectionKey: DiscoverSectionKey }>
                menuPlacement={'auto'}
                value={{
                  sectionKey:
                    template.sectionKey || DiscoverSectionKey.FEATURED,
                }}
                creatable={false}
                // isSearchable={false}
                isClearable={false}
                options={Object.values(DiscoverSectionKey).map(
                  (sectionKey) => ({ sectionKey }),
                )}
                getOptionLabel={({ sectionKey }) => getSectionTitle(sectionKey)}
                getOptionValue={({ sectionKey }) => sectionKey}
                isMulti={false}
                placeholder={'Choose a Section'}
                onChange={setValue}
              />
            </Field>
          )}
        </Column>
        <Column>
          <Field label={'Danger Zone 😄'}>
            <ToggleOuter>
              <Toggle
                size={'large'}
                isChecked={!template.isDraft}
                onChange={() => setValue({ isDraft: !template.isDraft })}
              />
              {'Ready for production'}
            </ToggleOuter>
            <ToggleOuter>
              <Toggle
                size={'large'}
                isChecked={!!template.isNew}
                isDisabled={!!template.isDraft}
                onChange={() => setValue({ isNew: !template.isNew })}
              />
              {'Has NEW badge'}
            </ToggleOuter>
          </Field>
          {!isNewTemplate(defaultTemplate) && (
            <Field label={'Super Duper Danger 😱'}>
              <div>
                <Button
                  appearance={'warning'}
                  onClick={mayArchive}
                  isLoading={!!loadingArchive}
                >
                  {'Archive'}
                </Button>
              </div>
            </Field>
          )}
        </Column>
      </FieldsRow>

      {!areEquivalentObjects(template, defaultTemplate) && (
        <SaveButtons
          isLoading={loading}
          onCancel={cancelEdit}
          onSave={saveTemplate}
        />
      )}

      <LoadingSpinner show={loading} />
    </Outer>
  )
}

export default Configuration
