import { CodeBlock } from '@atlaskit/code'
import React, { Fragment } from 'react'

import { Dl, Dt, Dd } from '../../../../components/DlDtDd'
import { AutomationJobLogFragment } from '../../../../graphql'
import useSwitch from '../../../../lib/useSwitch'
import { formatDateTime } from '../../utils'

import { LogMessage, LogOuter, LogHeader, LogActionName } from './styled'

type Props = {
  log: AutomationJobLogFragment
  getActionColor: (actionId: string) => string
}

const AutomationJobLogItem = ({ log, getActionColor }: Props) => {
  const [isExpanded, , , toggleExpanded] = useSwitch(false)

  const {
    automationId: _dump,
    config,
    params,
    error,
    results,
    ...meta
  } = log.meta || {}

  const sections = {
    Config: config,
    Params: params,
    'Raw error': error,
    Results: results,
    Meta: meta,
  }

  return (
    <LogOuter $level={log.level} onClick={toggleExpanded}>
      {!isExpanded ? (
        <LogHeader>
          <LogMessage>{log.message}</LogMessage>
          {!!log.action && (
            <LogActionName $color={getActionColor(log.action.id)}>
              {log.action?.name}
            </LogActionName>
          )}
        </LogHeader>
      ) : (
        <Dl dtWidth={'100px'}>
          <Dt>{'Message'}</Dt>
          <Dd>
            <strong>{log.message}</strong>
          </Dd>

          {!!log.action && (
            <>
              <Dt>{'Action'}</Dt>
              <Dd>
                <LogActionName $color={getActionColor(log.action.id)}>
                  {log.action?.name}
                </LogActionName>
              </Dd>
            </>
          )}

          {!!error?.message && (
            <>
              <Dt>{'Error'}</Dt>
              <Dd>{error.message}</Dd>
            </>
          )}

          {!!error?.stack && (
            <>
              <Dt>{'Stack'}</Dt>
              <Dd>{error.stack}</Dd>
            </>
          )}

          {Object.entries(sections)
            .filter(([, data]) => data && Object.keys(data).length)
            .map(([name, data], index) => (
              <Fragment key={index}>
                <Dt>{name}</Dt>
                <Dd>
                  {
                    <CodeBlock
                      showLineNumbers={false}
                      language={'json'}
                      text={JSON.stringify(data, null, 2)}
                    />
                  }
                </Dd>
              </Fragment>
            ))}

          <Dt>{'Date-time'}</Dt>
          <Dd>{formatDateTime(log.at)}</Dd>
        </Dl>
      )}
    </LogOuter>
  )
}

export default AutomationJobLogItem
