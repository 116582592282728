/* eslint-disable promise/prefer-await-to-then */
import UploadIcon from '@atlaskit/icon/glyph/upload'
import { Radio } from '@atlaskit/radio'
import Range from '@atlaskit/range'
import { colors, typography } from '@atlaskit/theme'
import React, {
  ComponentProps,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import _Canvas from 'react-avatar-editor'
import styled from 'styled-components'

import { getTransparentAvatar } from '../../lib/removebg'
import useSwitch from '../../lib/useSwitch'
import ColorPicker from '../ColorPicker'
import Modal from '../Modal'
import { LoadingSpinner } from '../Spinner'
import { Field } from '../form'
import { S3Uploader, useGetSignedUrl } from '../form/ImageUrlField'

const CANVAS_SIZE = 150
const CANVAS_OVERLAY_COLOR = [255, 255, 255, 0.6]
// const FINAL_IMAGE_SIZE = 2_000
const BG_COLORS = [
  '#e0e9d4',
  '#e1d3e2',
  '#d9f4fc',
  '#d9d7ed',
  '#f9e1dc',
  '#fae6c4',
]

const Outer = styled.div`
  display: flex;
  align-items: center;
`

const InputOuter = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.92);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    ${typography.h400}
  }

  input {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    opacity: 0;
  }
`

const Canvas = styled(_Canvas)`
  width: ${CANVAS_SIZE}px!important;
  height: ${CANVAS_SIZE}px!important;
  border-radius: ${CANVAS_SIZE}px;
  box-shadow: 0 0 0 4px white, 0 0 0 8px ${colors.primary};
  margin: 8px;
`

const Controls = styled.div`
  padding: 0 24px;
`

const BbColorRadioOuter = styled.div`
  display: flex;
  align-items: center;
`

const doSetTransparentPhoto = async (
  photoUrl: string,
  setTransparentPhotoUrl: (url: string) => void,
) => {
  const transparentPhotoUrl = await getTransparentAvatar(photoUrl)
  setTransparentPhotoUrl(transparentPhotoUrl)
}

type Props = ComponentProps<typeof Modal> & {
  onSubmitPhotoUrl: (url: string) => void
  isLoadingProp?: boolean
}

const UploadAvatarModal = ({
  onSubmitPhotoUrl,
  onClose,
  onCloseComplete: _onCloseComplete,
  isLoadingProp = false,
  ...modalProps
}: Props) => {
  const canvasRef = useRef<_Canvas | undefined>()
  const [isLoadingState, startLoading, stopLoading] = useSwitch(false)
  const isLoading = isLoadingProp || isLoadingState
  const [isUploading, startUploading, stopUploading] = useSwitch(false)
  const [originalPhotoUrl, setOriginalPhotoUrl] = useState<
    string | null | undefined
  >()
  const [transparentPhotoUrl, setTransparentPhotoUrl] = useState<
    string | undefined
  >()
  const [scale, setScale] = useState(1)
  const [bgColor, setBgColor] = useState<string | null>(null)
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0.5,
    y: 0.5,
  })
  const getSignedUrl = useGetSignedUrl()

  const reset = useCallback(() => {
    stopLoading()
    setOriginalPhotoUrl(undefined)
    setTransparentPhotoUrl(undefined)
    setBgColor(null)
    setScale(1)
    setPosition({ x: 0.5, y: 0.5 })
  }, [stopLoading])

  const onCloseComplete = useCallback(
    (element: HTMLElement) => {
      reset()
      _onCloseComplete?.(element)
    },
    [_onCloseComplete, reset],
  )

  const onSelectedFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      startLoading()
      const file = event.target.files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = () => setOriginalPhotoUrl(reader.result?.toString())
        reader.onabort = stopLoading
        reader.onerror = stopLoading
        reader.onloadend = stopLoading
        reader.readAsDataURL(file)
      }
    },
    [startLoading, stopLoading, setOriginalPhotoUrl],
  )

  const onSubmit = useCallback(() => {
    canvasRef.current?.getImage().toBlob(async (blob) => {
      if (blob) {
        startUploading()
        const file = new File([blob], 'avatar', { type: blob.type })
        const uploader = new S3Uploader({
          getSignedUrl,
          // 💩 Passing an empty object to remove default x-amz-acl header,
          // ACL is already set on the signed url
          // See directory.api src/admin/modules/uploadPublicFile.ts:59
          uploadRequestHeaders: {},
          // @ts-ignore
          onFinishS3Put: ({ publicUrl }) => onSubmitPhotoUrl(publicUrl),
          onError: (error) => {
            console.error(error)
            stopUploading()
          },
        })

        try {
          await uploader.uploadFile(file)
        } catch (error) {
          console.error(error)
        } finally {
          stopUploading()
        }
      }
    }, 'image/jpeg')
  }, [getSignedUrl, onSubmitPhotoUrl, startUploading, stopUploading])

  useEffect(() => {
    if (originalPhotoUrl && bgColor) {
      startLoading()
      // eslint-disable-next-line promise/catch-or-return
      doSetTransparentPhoto(originalPhotoUrl, setTransparentPhotoUrl)
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          stopLoading()
        })
    }
  }, [
    bgColor,
    originalPhotoUrl,
    setTransparentPhotoUrl,
    startLoading,
    stopLoading,
  ])

  return (
    <Modal
      heading={'Upload User Photo'}
      width={'small'}
      actions={[
        {
          text: 'Save',
          onClick: onSubmit,
          isDisabled: !(bgColor ? transparentPhotoUrl : originalPhotoUrl),
          isLoading: isUploading,
        },
        {
          text: 'Cancel',
          onClick: onClose,
        },
      ]}
      autoFocus={false}
      onClose={onClose}
      onCloseComplete={onCloseComplete}
      {...modalProps}
    >
      <Outer>
        <Canvas
          // @ts-ignore
          ref={canvasRef}
          image={(bgColor ? transparentPhotoUrl : originalPhotoUrl) || ''}
          width={CANVAS_SIZE}
          height={CANVAS_SIZE}
          border={0}
          borderRadius={CANVAS_SIZE}
          color={CANVAS_OVERLAY_COLOR}
          // @ts-ignore
          backgroundColor={bgColor || undefined}
          scale={scale}
          rotate={0}
          position={position}
          onPositionChange={setPosition}
        />
        <Controls>
          <Field label={'Zoom'}>
            <Range
              step={0.01}
              min={1}
              max={2}
              value={scale}
              onChange={setScale}
              isDisabled={isUploading}
            />
          </Field>
          <Field label={'Background'}>
            <Radio
              label={'Original'}
              isChecked={!bgColor}
              onChange={() => setBgColor(null)}
              isDisabled={isUploading}
            />
            <BbColorRadioOuter>
              <Radio
                label={''}
                isChecked={!!bgColor}
                onChange={() => setBgColor(BG_COLORS[0])}
                isDisabled={isUploading}
              />
              <ColorPicker
                palette={BG_COLORS}
                selectedColor={bgColor || undefined}
                checkMarkColor={colors.primary()}
                onChange={setBgColor}
              />
            </BbColorRadioOuter>
          </Field>
        </Controls>
        {!originalPhotoUrl && (
          <InputOuter>
            <UploadIcon label={''} size={'large'} />
            <p>{'Drop or click to select image'}</p>
            <input
              type={'file'}
              accept={'.jpg, .jpeg, .png'}
              placeholder={'Click to select a file or drop'}
              onChange={onSelectedFile}
            />
          </InputOuter>
        )}
        <LoadingSpinner show={isLoading} />
      </Outer>
    </Modal>
  )
}

export default UploadAvatarModal
