import { LoadingButton as Button } from '@atlaskit/button'
import { FormSection } from '@atlaskit/form'
import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { LoadingSpinner } from '../../../components/Spinner'
import TimeZoneSelect from '../../../components/TimeZoneSelect'
import { ToggleStateless as Toggle } from '../../../components/Toggle'
import { Field } from '../../../components/form'
import {
  useUpdateWorkspaceMutation,
  useResetOnboardingMutation,
  useGetWorkspaceQuery,
  useArchiveWorkspaceMutation,
} from '../../../graphql'

const ToggleOuter = styled.div`
  display: flex;
  align-items: center;
  white-space: break-spaces;
`

const Breather = styled.div`
  height: 8px;
`

type Props = { workspaceId: string }

const WorkspaceSettings = ({ workspaceId }: Props) => {
  const navHistory = useHistory()
  const location = useLocation()
  const { data: workspaceData, loading: loadingWorkspace } =
    useGetWorkspaceQuery({
      variables: { workspaceId },
    })
  const [updateWorkspace, { loading: loadingUpdateWorkspace }] =
    useUpdateWorkspaceMutation()
  const [archiveWorkspace, { loading: loadingArchiveWorkspace }] =
    useArchiveWorkspaceMutation({
      variables: {
        input: {
          workspaceId,
        },
      },
      onCompleted: () => {
        // set the url to the user profile
        navHistory.replace(location.pathname.split('/workspaces')[0])
        // 💩 Force clear cache
        window.location.reload()
      },
    })
  const [resetOnboarding, { loading: loadingResetOnboarding }] =
    useResetOnboardingMutation({
      variables: {
        input: {
          workspaceId,
        },
      },
      refetchQueries: ['GetUser'],
    })

  const loading = loadingWorkspace || loadingUpdateWorkspace

  const workspace = workspaceData?.workspace

  const onArchiveWorkspace = useCallback(async () => {
    if (!window.confirm('Are you sure you want to archive this workspace?')) {
      return
    }

    await archiveWorkspace()
  }, [archiveWorkspace])

  return (
    <>
      <FormSection title={'Usage Alerts Emails'}>
        <h5>{'Notify every 5 hours billed'}</h5>
        <ToggleOuter>
          <Toggle
            size={'large'}
            isChecked={Boolean(workspace?.usageAlertEmail)}
            onChange={() => {
              try {
                updateWorkspace({
                  variables: {
                    input: {
                      workspaceId: workspaceId,
                      usageAlertEmail: !workspace?.usageAlertEmail,
                    },
                  },
                })
              } catch (error) {
                console.error(error)
              }
            }}
          />
          {'Email to '}
          <strong>{'Executive'}</strong>
        </ToggleOuter>

        <ToggleOuter>
          <Toggle
            size={'large'}
            isChecked={Boolean(workspace?.usageAlertAssistantEmail)}
            onChange={() => {
              try {
                updateWorkspace({
                  variables: {
                    input: {
                      workspaceId: workspaceId,
                      usageAlertAssistantEmail:
                        !workspace?.usageAlertAssistantEmail,
                    },
                  },
                })
              } catch (error) {
                console.error(error)
              }
            }}
          />
          {'Email to '}
          <strong>{'Assistant'}</strong>
        </ToggleOuter>

        <h5>{'Notify at 80% and 100% utilization'}</h5>
        <ToggleOuter>
          <Toggle
            size={'large'}
            isChecked={Boolean(workspace?.usageLimitsEmail)}
            onChange={() => {
              try {
                updateWorkspace({
                  variables: {
                    input: {
                      workspaceId: workspaceId,
                      usageLimitsEmail: !workspace?.usageLimitsEmail,
                    },
                  },
                })
              } catch (error) {
                console.error(error)
              }
            }}
          />
          {'Email to '}
          <strong>{'Executive'}</strong>
        </ToggleOuter>
      </FormSection>

      <FormSection title={'Other Email Subscriptions'}>
        <Breather />

        <ToggleOuter>
          <Toggle
            size={'large'}
            isChecked={Boolean(workspace?.weeklyDigestEmail)}
            onChange={() => {
              try {
                updateWorkspace({
                  variables: {
                    input: {
                      workspaceId: workspaceId,
                      weeklyDigestEmail: !workspace?.weeklyDigestEmail,
                    },
                  },
                })
              } catch (error) {
                console.error(error)
              }
            }}
          />
          {'Weekly Digest'}
        </ToggleOuter>

        <ToggleOuter>
          <Toggle
            size={'large'}
            isChecked={Boolean(workspace?.eveningDigestEmail)}
            onChange={() => {
              try {
                updateWorkspace({
                  variables: {
                    input: {
                      workspaceId: workspaceId,
                      eveningDigestEmail: !workspace?.eveningDigestEmail,
                    },
                  },
                })
              } catch (error) {
                console.error(error)
              }
            }}
          />
          {'Evening Digest'}
        </ToggleOuter>

        <ToggleOuter>
          <Toggle
            size={'large'}
            isChecked={Boolean(workspace?.groupedNotificationsEmail)}
            onChange={() => {
              try {
                updateWorkspace({
                  variables: {
                    input: {
                      workspaceId: workspaceId,
                      groupedNotificationsEmail:
                        !workspace?.groupedNotificationsEmail,
                    },
                  },
                })
              } catch (error) {
                console.error(error)
              }
            }}
          />
          {'Grouped Messages Notification'}
        </ToggleOuter>

        <ToggleOuter>
          <Toggle
            size={'large'}
            isChecked={Boolean(workspace?.feedbackRequestEmail)}
            onChange={() => {
              try {
                updateWorkspace({
                  variables: {
                    input: {
                      workspaceId: workspaceId,
                      feedbackRequestEmail: !workspace?.feedbackRequestEmail,
                    },
                  },
                })
              } catch (error) {
                console.error(error)
              }
            }}
          />
          {'CSAT'}
        </ToggleOuter>
      </FormSection>

      <FormSection title={'Other Preferences'}>
        <ToggleOuter>
          <Toggle
            size={'large'}
            isChecked={Boolean(workspace?.copilotEnabled)}
            onChange={() => {
              try {
                updateWorkspace({
                  variables: {
                    input: {
                      workspaceId: workspaceId,
                      copilot: {
                        enabled: !workspace?.copilotEnabled,
                      },
                    },
                  },
                })
              } catch (error) {
                console.error(error)
              }
            }}
          />
          {'Copilot'}
        </ToggleOuter>
      </FormSection>

      <FormSection title={'Configuration'}>
        <Field label={'Working Hours Time-Zone'}>
          <TimeZoneSelect
            value={workspace?.workingHoursTimeZone}
            // @ts-ignore
            onChangeValue={(workingHoursTimeZone: string) => {
              try {
                updateWorkspace({
                  variables: {
                    input: { workspaceId: workspaceId, workingHoursTimeZone },
                  },
                })
              } catch (error) {
                console.error(error)
              }
            }}
          />
        </Field>
      </FormSection>

      <FormSection title={'Danger Zone'}>
        <Breather />
        <Button
          appearance={'danger'}
          onClick={() => resetOnboarding()}
          isLoading={loadingResetOnboarding}
        >
          {'Restart Onboarding'}
        </Button>
        <Breather />
        <Button
          appearance={'danger'}
          onClick={onArchiveWorkspace}
          isLoading={loadingArchiveWorkspace}
        >
          {'Archive Workspace'}
        </Button>
      </FormSection>

      <LoadingSpinner show={loading} />
    </>
  )
}

export default WorkspaceSettings
