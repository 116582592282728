import { LoadingButton as Button, ButtonGroup } from '@atlaskit/button'
import { transparentize } from 'polished'
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'

import Editor from '../../../../components/MarkdownEditor'
import { LoadingSpinner } from '../../../../components/Spinner'
import { Field } from '../../../../components/form'
import {
  TopicTemplateFragment,
  useUpsertTopicTemplateMutation,
  UpsertTopicTemplateInput,
} from '../../../../graphql'
import { areEquivalentObjects, deepCleanTypename } from '../../../../lib/utils'

const Outer = styled.div`
  padding: 8px 0;
  flex: 1;
  margin-bottom: 24px;
`

const Buttons = styled.div`
  padding: 16px 4px;
  position: sticky;
  bottom: 0;
  background-color: ${transparentize(0.2, 'white')};
`

interface InstructionsProps {
  template: TopicTemplateFragment
}

const Instructions = ({ template: defaultTemplate }: InstructionsProps) => {
  const [revision, setRevision] = useState(Date.now())
  const [values, setValues] = useState<
    Omit<Partial<UpsertTopicTemplateInput>, '__typename'>
  >({})
  const [upsertTemplate, { loading }] = useUpsertTopicTemplateMutation()

  const setValue = useCallback(
    (value) => {
      setValues((prev) => ({ ...prev, ...value }))
    },
    [setValues],
  )

  const resetTemplate = useCallback(() => {
    setValues({})
    setRevision(Date.now())
  }, [])

  const cancelEdit = useCallback(() => {
    setRevision(Date.now())
    resetTemplate()
  }, [resetTemplate])

  const saveTemplate = useCallback(async () => {
    try {
      await upsertTemplate({
        variables: {
          input: {
            // @ts-ignore
            templateId: defaultTemplate.id,
            ...deepCleanTypename(values),
          },
        },
      })
      resetTemplate()
    } catch (error) {
      console.error(error)
    }
  }, [upsertTemplate, defaultTemplate.id, values, resetTemplate])

  const template = {
    ...defaultTemplate,
    ...values,
  }

  return (
    <Outer>
      <Field
        label={'Instructions'}
        helperMessage={'Displayed to assistants in yellow banner.'}
      >
        <Editor
          key={(defaultTemplate.instructions || 'empty') + revision}
          value={template.instructions}
          onChange={(instructions) => setValue({ instructions })}
          isInline={false}
        />
      </Field>

      {!areEquivalentObjects(template, defaultTemplate) && (
        <Buttons>
          <ButtonGroup>
            <Button appearance={'primary'} onClick={saveTemplate}>
              {'Save'}
            </Button>
            <Button onClick={cancelEdit}>{'Cancel'}</Button>
          </ButtonGroup>
        </Buttons>
      )}

      <LoadingSpinner show={loading} />
    </Outer>
  )
}

export default Instructions
