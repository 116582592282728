import React, { ComponentProps, useCallback } from 'react'

import {
  BasicUserFragment,
  UserCategory,
  useUpdateTeamMutation,
  useUpdateUserMutation,
} from '../../graphql'

import UserSelectorModal from './SelectorModal'

export enum EntityType {
  USER = 'user',
  TEAM = 'team',
}

type Props = Omit<ComponentProps<typeof UserSelectorModal>, 'onSelectUser'> & {
  entityId: string
  entityType: EntityType
  userCategory?: UserCategory | null
}

const AssignSuccessManagerModal = ({
  entityId,
  entityType,
  userCategory,
  onClose,
  ...modalProps
}: Props) => {
  const [updateUser] = useUpdateUserMutation()
  const [updateTeam] = useUpdateTeamMutation()

  const assignSuccessManager = useCallback(
    async (manager: BasicUserFragment) => {
      if (entityType === EntityType.USER) {
        await updateUser({
          variables: {
            userId: entityId,
            input: {
              successManagerId: manager.id,
            },
          },
        })
      } else if (entityType === EntityType.TEAM) {
        await updateTeam({
          variables: {
            teamId: entityId,
            input: {
              successManagerId: manager.id,
            },
          },
        })
      }
      onClose?.()
    },
    [entityId, entityType, onClose, updateTeam, updateUser],
  )

  return (
    <UserSelectorModal
      adminRoles={
        userCategory === UserCategory.ASSISTANT
          ? ['EA_TEAM_LEAD']
          : ['ONBOARDING_SPECIALIST', 'SUCCESS_SPECIALIST']
      }
      {...modalProps}
      onSelectUser={assignSuccessManager}
      onClose={onClose}
    />
  )
}

export default AssignSuccessManagerModal
