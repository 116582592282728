import styled from 'styled-components'

import _AccountIcon from '../../../components/AccountIcon'

export const MainOuter = styled.section`
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: stretch;
  max-width: 600px;
`

export const MainContent = styled.div`
  flex: 1;
  padding: 0 30px;
  display: flex;
  flex-flow: row;
  overflow: auto;

  h3 {
    margin-bottom: 6px;
  }

  h4 + * {
    margin-top: 12px;
  }

  .column {
    flex: 1 1 50%;
    display: flex;
    flex-flow: column;

    & + .column {
      margin-left: 16px;
    }
  }
`

export const Accounts = styled.div`
  margin-top: 8px;
`

export const Account = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  padding-left: 4px;

  & + & {
    border-top: 1px solid #f6f6f6;
    margin-top: 4px;
    padding-top: 4px;
  }

  b {
    font-weight: 500;
  }
`

export const AccountRow = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 0;
  font-size: 13px;
  padding: 6px 0;
`

export const AccountSpacer = styled.div`
  flex: 1 0 4px;
`

export const AccountTags = styled(AccountRow)`
  padding-left: 24px;
  padding-bottom: 6px;
`

export const AccountIcon = styled(_AccountIcon)`
  margin-right: 8px;
`
