import { LoadingButton as Button } from '@atlaskit/button'
import CopyIcon from '@atlaskit/icon/glyph/copy'
import DeleteIcon from '@atlaskit/icon/glyph/trash'
import React, { useCallback } from 'react'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'

import {
  MoreDropdownMenu,
  DropdownItemGroup,
  DropdownItem,
} from '../../../components/DropdownMenu'
import EmojiPicker from '../../../components/EmojiPicker'
import Toggle from '../../../components/Toggle'
import { Field, TextField } from '../../../components/form'
import {
  DossierSectionFragment,
  UpsertDossierSectionInput,
  useUpsertDossierSectionMutation,
} from '../../../graphql'
import useValues from '../../../lib/useValues'
import { areEquivalentObjects } from '../../../lib/utils'

import BoxCompact from './components/BoxCompact'
import FieldInputTypeSelect from './components/FieldInputTypeSelect'
import {
  BoxOuter,
  Buttons,
  Spacer,
  ToggleOuter,
  Lock,
} from './components/styled'
import useDossier from './useDossier'

type Props = {
  folderId: string
  section: DossierSectionFragment
  isSelected: boolean
  isEditing: boolean
}

const Section = ({
  folderId,
  section: originalSection,
  isSelected,
  isEditing,
}: Props) => {
  const { url } = useRouteMatch()
  const navHistory = useHistory()
  const { evictLocalSection } = useDossier()
  const [input, { setters, reset }] = useValues<UpsertDossierSectionInput>(
    {
      sectionId: originalSection.id,
      orderIndex: originalSection.orderIndex,
      folderId,
    },
    ['isPrivate', 'label', 'emoji', 'orderIndex', 'defaultInputType'],
  )

  const section = {
    ...originalSection,
    ...input,
  }

  const [upsert, { loading }] = useUpsertDossierSectionMutation()

  const cancelEdit = useCallback(() => {
    reset()
    if (!originalSection.label) {
      evictLocalSection(originalSection.id)
      navHistory.replace(url)
    } else {
      navHistory.replace(`${url}/${originalSection.id}/fields`)
    }
  }, [
    reset,
    originalSection.label,
    originalSection.id,
    evictLocalSection,
    navHistory,
    url,
  ])

  const save = useCallback(async () => {
    await upsert({
      variables: {
        input,
      },
    })
    reset()
    navHistory.replace(`${url}/${originalSection.id}/fields`)
  }, [input, navHistory, originalSection.id, reset, upsert, url])

  if (!isSelected || !isEditing) {
    return (
      <BoxCompact
        label={(section.emoji || '') + ' ' + section.label}
        iconAfter={section?.isPrivate && <Lock />}
        isSelected={isSelected}
        disableDragHandle={isEditing}
        selectUrl={`${url}/${section.id}/fields`}
        editUrl={`${url}/${section.id}/edit`}
      />
    )
  }

  return (
    <BoxOuter isSelected>
      <Field label={'Name'}>
        <TextField
          value={section.label || undefined}
          onChangeValue={setters.label}
          placeholder={'Section name'}
          isCompact
          autoFocus
          elemBeforeInput={
            <EmojiPicker value={section.emoji} onChangeValue={setters.emoji} />
          }
        />
      </Field>

      <Field label={'Default field type'} isRequired>
        <FieldInputTypeSelect
          value={section?.defaultInputType}
          onChangeValue={setters.defaultInputType}
          spacing={'compact'}
        />
      </Field>

      <Field label={''}>
        <ToggleOuter>
          <Toggle
            size={'large'}
            isChecked={!!section.isPrivate}
            onChange={() => setters.isPrivate?.(!section.isPrivate)}
          />
          {'Private'}
        </ToggleOuter>
      </Field>

      <Buttons>
        <Button
          appearance={'primary'}
          spacing={'compact'}
          onClick={save}
          isDisabled={areEquivalentObjects(section, originalSection)}
          isLoading={loading}
        >
          {'Save'}
        </Button>
        <Button spacing={'compact'} onClick={cancelEdit} isDisabled={loading}>
          {'Cancel'}
        </Button>
        <Spacer />
        <MoreDropdownMenu>
          <DropdownItemGroup>
            <DropdownItem
              onClick={() => navigator.clipboard.writeText(section.id)}
              elemBefore={<CopyIcon size={'small'} label={''} />}
              description={section.id}
            >
              {'Copy section ID'}
            </DropdownItem>
            <DropdownItem
              // onClick={}
              isDisabled
              elemBefore={<DeleteIcon size={'small'} label={''} />}
              description={'Child fields will be deleted'}
            >
              {'Delete section'}
            </DropdownItem>
          </DropdownItemGroup>
        </MoreDropdownMenu>
      </Buttons>
    </BoxOuter>
  )
}

export default Section
