import { Maybe } from '../../../../graphql/generated/hooks'

export function getFlagEmoji(countryCode: string) {
  return countryCode
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt(0)))
}

export const getAssistantCountryRateCurrency = (
  country?: Maybe<string>,
  currency?: Maybe<string>,
  rate?: Maybe<number>,
) => {
  const currencySymbol =
    currency === 'USD'
      ? '$'
      : currency === 'CAD'
      ? '$'
      : currency === 'EUR'
      ? '€'
      : currency === 'GBP'
      ? '£'
      : ''

  const flag = country ? getFlagEmoji(country) : ''

  return `${flag} ${currencySymbol}${rate}/hr ${currency}`
}
