import Select from '@atlaskit/select'
import sortBy from 'lodash/sortBy'
import React, { ComponentProps } from 'react'

import { useListAdminRolesQuery } from '../graphql'

type Props = ComponentProps<typeof Select> & {
  values?: string[] | null
  onChangeValues: (newValues: string[]) => void
  excludeValues?: string[]
}

const AdminRolesSelect = ({
  values,
  onChangeValues,
  excludeValues,
  ...props
}: Props) => {
  const { data, loading } = useListAdminRolesQuery()

  const roles = sortBy(data?.list.items || [], 'name').filter(
    ({ id }) => !excludeValues || !excludeValues.includes(id),
  )

  return (
    <Select
      value={roles.length ? values?.map((value) => ({ value })) : []}
      isLoading={loading}
      isClearable
      isMulti
      options={roles.map(({ id: value }) => ({ value }))}
      // @ts-ignore
      getOptionLabel={({ value }) => roles.find(({ id }) => id === value)?.name}
      // @ts-ignore
      getOptionValue={({ value }) => value}
      onChange={(values) =>
        // @ts-ignore
        onChangeValues((values || []).map(({ value }) => value))
      }
      {...props}
    />
  )
}
export default AdminRolesSelect
