import { LoadingButton as Button } from '@atlaskit/button'
import CopyIcon from '@atlaskit/icon/glyph/copy'
import RemoveIcon from '@atlaskit/icon/glyph/editor/remove'
import DeleteIcon from '@atlaskit/icon/glyph/trash'
import React, { useCallback, useMemo } from 'react'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'

import {
  MoreDropdownMenu,
  DropdownItemGroup,
  DropdownItem,
} from '../../../components/DropdownMenu'
import { ToggleStateless as Toggle } from '../../../components/Toggle'
import {
  TextField,
  Field,
  Select,
  Checkbox,
  FieldsRow,
} from '../../../components/form'
import {
  DossierFieldFragment,
  DossierInputablePlaceHint,
  DossierInputablePlaceInput,
  DossierInputableTextInput,
  DossierInputableTextOptionInput,
  DossierInputableType,
  UpsertDossierFieldInput,
  useUpsertDossierFieldMutation,
} from '../../../graphql'
import useValues from '../../../lib/useValues'
import { deepCleanTypename } from '../../../lib/utils'

import BoxCompact from './components/BoxCompact'
import FieldInputTypeSelect, {
  labelForType,
} from './components/FieldInputTypeSelect'
import {
  BoxOuter,
  Buttons,
  Spacer,
  FieldOption,
  ToggleOuter,
} from './components/styled'
import useDossier from './useDossier'
import {
  inputableTypenameToType,
  PLACE_HINT_LABEL,
  PLACE_HINT_DESCRIPTION,
} from './utils'

const EMPTY_TEXT_OPTION: DossierInputableTextOptionInput = Object.freeze({
  text: '',
  isRecommended: false,
})

type Props = {
  sectionId: string
  field: DossierFieldFragment
  isSelected: boolean
  isEditing: boolean
}

const DossierFieldComponent = ({
  sectionId,
  field,
  isSelected,
  isEditing,
}: Props) => {
  const { url } = useRouteMatch()
  const { evictLocalField } = useDossier()
  const navHistory = useHistory()
  const [input, { setters, reset }] = useValues<UpsertDossierFieldInput>(
    {
      fieldId: field.id,
      orderIndex: field.orderIndex,
      sectionId,
    },
    ['label', 'orderIndex', 'input'],
  )

  const inputable: UpsertDossierFieldInput['input'] = useMemo(() => {
    if (input && 'input' in input) {
      return input.input
    }

    const type = inputableTypenameToType(field.input?.__typename)
    if (!field.input || !type) {
      return null
    }

    const inputable: UpsertDossierFieldInput['input'] = {
      type,
      maxValues: field.input?.maxValues,
    }

    const { __typename: _dump1, maxValues: _dump2, ...extensions } = field.input

    if (
      field.input?.__typename === 'DossierInputableText' &&
      type === DossierInputableType.TEXT
    ) {
      inputable.text = extensions as DossierInputableTextInput
    }

    if (
      field.input?.__typename === 'DossierInputablePlace' &&
      type === DossierInputableType.PLACE
    ) {
      inputable.place = extensions as DossierInputablePlaceInput
    }

    return inputable
  }, [field.input, input])

  const [upsert, { loading }] = useUpsertDossierFieldMutation()

  const cancelEdit = useCallback(() => {
    reset()
    if (!field.label) {
      evictLocalField(field.id)
      navHistory.replace(url)
    } else {
      navHistory.replace(`${url}/${field.id}`)
    }
  }, [reset, field.label, field.id, evictLocalField, navHistory, url])

  const save = useCallback(async () => {
    await upsert({
      variables: {
        input: deepCleanTypename(input),
      },
    })
    reset()
    navHistory.replace(`${url}/${field.id}`)
  }, [upsert, input, reset, navHistory, url, field.id])

  const setInputType = useCallback(
    (type: DossierInputableType) => {
      setters.input?.(
        type
          ? {
              type,
              maxValues: 1,
              text:
                type === DossierInputableType.TEXT
                  ? { isAllowingFreeForm: true }
                  : undefined,
              place: undefined,
            }
          : null,
      )
    },
    [setters],
  )

  const patchInputTextExtensions = useCallback(
    (patch: DossierInputableTextInput) => {
      if (inputable) {
        setters.input?.({
          ...inputable,
          text: {
            ...inputable.text,
            ...patch,
          },
        })
      }
    },
    [inputable, setters],
  )

  const setInputTextOptionAtIndex = useCallback(
    (option: DossierInputableTextOptionInput | null, atIndex: number) => {
      if (inputable) {
        const options = inputable?.text?.options
          ? [...inputable.text.options]
          : []
        if (option) {
          options[atIndex] = option
        } else {
          options.splice(atIndex, 1)
        }
        patchInputTextExtensions({ options })
      }
    },
    [inputable, patchInputTextExtensions],
  )

  const patchInputPlaceExtensions = useCallback(
    (patch: DossierInputablePlaceInput) => {
      if (inputable) {
        setters.input?.({
          ...inputable,
          place: {
            ...inputable.place,
            ...patch,
          },
        })
      }
    },
    [inputable, setters],
  )

  if (!isSelected || !isEditing) {
    return (
      <BoxCompact
        label={field.label}
        description={
          !inputable?.type
            ? undefined
            : inputable.type === DossierInputableType.TEXT &&
              inputable.text?.options?.length
            ? inputable.text.options
                .map(({ text }) => text)
                .concat(inputable.text.isAllowingFreeForm ? 'other' : [])
                .join(', ')
            : inputable.type === DossierInputableType.PLACE &&
              inputable.place?.hint
            ? `${labelForType[inputable.type]} - ${
                PLACE_HINT_LABEL[inputable.place.hint]
              }`
            : labelForType[inputable.type]
        }
        isSelected={isSelected}
        disableDragHandle={isEditing}
        editUrl={`${url}/${field.id}/edit`}
        selectUrl={`${url}/${field.id}`}
      />
    )
  }

  return (
    <BoxOuter isSelected>
      <Field label={'Name'} isRequired>
        <TextField
          value={input.label || field.label || undefined}
          onChangeValue={setters.label}
          placeholder={'Field name'}
          isCompact
          autoFocus
        />
      </Field>

      <Field label={'Type'} isRequired>
        <FieldInputTypeSelect
          value={inputable?.type}
          onChangeValue={setInputType}
          spacing={'compact'}
        />
      </Field>

      {inputable?.type === DossierInputableType.PLACE && (
        <Field
          label={'Place type'}
          helperMessage={'Hint for autocomplete suggestions'}
        >
          <Select<{ hint: DossierInputablePlaceHint }>
            menuPlacement={'auto'}
            value={
              inputable?.place?.hint ? { hint: inputable.place.hint } : null
            }
            creatable={false}
            isSearchable={false}
            isClearable
            options={Object.values(DossierInputablePlaceHint).map((hint) => ({
              hint,
            }))}
            // @ts-ignore
            getOptionLabel={({ hint }) =>
              // @ts-ignore
              `${PLACE_HINT_LABEL[hint]} - ${PLACE_HINT_DESCRIPTION[hint]}`
            }
            // @ts-ignore
            getOptionValue={({ hint }) => hint}
            isMulti={false}
            onChange={(value) =>
              // @ts-ignore
              patchInputPlaceExtensions({ hint: value?.hint })
            }
            spacing={'compact'}
          />
        </Field>
      )}

      {!!inputable?.type && (
        <Field label={'How many values max?'} isRequired>
          <Select<{ maxValues: null | number }>
            menuPlacement={'auto'}
            value={{ maxValues: inputable?.maxValues || null }}
            creatable={false}
            isSearchable={false}
            isClearable={false}
            options={[
              { maxValues: null },
              ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((maxValues) => ({
                maxValues,
              })),
            ]}
            // @ts-ignore
            getOptionLabel={({ maxValues }) =>
              !maxValues ? 'No limit' : maxValues
            }
            // @ts-ignore
            getOptionValue={({ maxValues }) => maxValues}
            isMulti={false}
            // @ts-ignore
            onChange={({ maxValues }) =>
              setters.input?.({ ...inputable, maxValues })
            }
            spacing={'compact'}
          />
        </Field>
      )}

      {inputable?.type === DossierInputableType.TEXT && (
        <>
          <Field label={'Options (check to mark as recommended)'}>
            {[...(inputable.text?.options || []), EMPTY_TEXT_OPTION].map(
              ({ text, isRecommended }, index) => (
                <FieldOption key={index}>
                  <TextField
                    isCompact
                    value={text || ''}
                    isInvalid={
                      index < (inputable.text?.options?.length || 0) &&
                      !text.trim()
                    }
                    placeholder={
                      index === inputable.text?.options?.length
                        ? 'New Option'
                        : ''
                    }
                    onChangeValue={(text) =>
                      setInputTextOptionAtIndex(
                        {
                          isRecommended: false,
                          ...inputable.text?.options?.[index],
                          text: text.trimStart(),
                        },
                        index,
                      )
                    }
                    elemAfterInput={
                      index < (inputable.text?.options?.length || 0) && (
                        <Checkbox
                          isChecked={!!isRecommended}
                          onChangeValue={(isChecked) =>
                            setInputTextOptionAtIndex(
                              {
                                text: '',
                                ...inputable.text?.options?.[index],
                                isRecommended: isChecked,
                              },
                              index,
                            )
                          }
                        />
                      )
                    }
                  />
                  {index < (inputable.text?.options?.length || 0) && (
                    <Button
                      iconAfter={<RemoveIcon label={'Remove'} />}
                      appearance={'subtle'}
                      spacing={'none'}
                      onClick={() => setInputTextOptionAtIndex(null, index)}
                    />
                  )}
                </FieldOption>
              ),
            )}
          </Field>
          <Field label={''}>
            <FieldsRow>
              <ToggleOuter>
                <Toggle
                  size={'large'}
                  isChecked={!!inputable.text?.isAllowingFreeForm}
                  onChange={() =>
                    patchInputTextExtensions({
                      isAllowingFreeForm: !inputable.text?.isAllowingFreeForm,
                    })
                  }
                />
                {'Free-form'}
              </ToggleOuter>
              {!!inputable.text?.isAllowingFreeForm && (
                <TextField
                  isCompact
                  value={inputable.text?.freeFormPlaceholder || ''}
                  placeholder={'Free-form placeholder'}
                  onChangeValue={(freeFormPlaceholder) =>
                    patchInputTextExtensions({ freeFormPlaceholder })
                  }
                />
              )}
            </FieldsRow>
          </Field>
        </>
      )}

      <Buttons>
        <Button
          appearance={'primary'}
          spacing={'compact'}
          onClick={save}
          isLoading={loading}
        >
          {'Save'}
        </Button>
        <Button spacing={'compact'} onClick={cancelEdit} isDisabled={loading}>
          {'Cancel'}
        </Button>
        <Spacer />
        <MoreDropdownMenu>
          <DropdownItemGroup>
            <DropdownItem
              onClick={() => navigator.clipboard.writeText(field.id)}
              elemBefore={<CopyIcon size={'small'} label={''} />}
              description={field.id}
            >
              {'Copy field ID'}
            </DropdownItem>
            <DropdownItem
              // onClick={}
              isDisabled
              elemBefore={<DeleteIcon size={'small'} label={''} />}
            >
              {'Delete field'}
            </DropdownItem>
          </DropdownItemGroup>
        </MoreDropdownMenu>
      </Buttons>
    </BoxOuter>
  )
}

export default DossierFieldComponent
