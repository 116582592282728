import { useMemo } from 'react'

import { useListPricingsQuery, PricingFragment } from '../../../../graphql'

const usePublicPricings = (): PricingFragment[] | undefined => {
  const { data: pricingsData } = useListPricingsQuery()

  return useMemo(() => {
    return pricingsData?.list.items.filter(({ isPublic }) => isPublic)
  }, [pricingsData])
}

export default usePublicPricings
