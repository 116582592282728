// @ts-ignore
import { Group } from '@atlaskit/navigation-next'
import React, { useState } from 'react'
import styled from 'styled-components'

import PageHeader from '../../../../components/PageHeader'
import { Field } from '../../../../components/form'
import ListContainer from '../../../../components/list/ListContainer'
import ListOuter from '../../../../components/list/ListOuter'
import PageHeaderOuter from '../../../../components/list/PageHeaderOuter'
import { AutomationFragment } from '../../../../graphql'
import useValues from '../../../../lib/useValues'
import { group } from '../../utils'
import AutomationTriggerSelect from '../components/TriggerSelect'

import Item from './Item'

const Outer = styled(ListContainer)`
  min-width: 280px;
  max-width: 400px;
`

interface Props {
  automations: AutomationFragment[]
}

const AutomationsList = ({ automations: allAutomations }: Props) => {
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>('')
  const [filters, { setters }] = useValues<{ triggerId: string | null }>(
    { triggerId: null },
    ['triggerId'],
  )

  const automations = allAutomations
    .filter(
      ({ name, description }) =>
        !searchTerm ||
        name.toLowerCase().includes(searchTerm) ||
        description?.toLowerCase().includes(searchTerm),
    )
    .filter(
      ({ triggerOn: { id } }) => !filters.triggerId || filters.triggerId === id,
    )

  const groups = group(automations)

  return (
    <Outer>
      <PageHeaderOuter>
        <PageHeader
          searchTerm={searchTerm}
          onChangeSearchTerm={setSearchTerm}
          hasActiveFilter={!!filters.triggerId}
          filtersContent={() => (
            <Field label={'Triggered when'}>
              <AutomationTriggerSelect
                valueId={filters.triggerId}
                onChangeValueId={setters.triggerId}
                isClearable
              />
            </Field>
          )}
        >
          {'Automations'}
        </PageHeader>
      </PageHeaderOuter>
      <ListOuter>
        {groups.map(({ name, items: automations }) => (
          <Group heading={name || 'Other'} key={name || 'Other'}>
            {automations.map((automation) => (
              <Item
                key={automation.id}
                automation={automation}
                withSectionedName
              />
            ))}
          </Group>
        ))}
      </ListOuter>
    </Outer>
  )
}

export default AutomationsList
