import { colors } from '@atlaskit/theme'
import styled, { css } from 'styled-components'

export const Outer = styled.div`
  background-color: ${colors.backgroundHover};
  border-radius: 3px;
  height: 40px;
  padding: 4px 8px;

  display: flex;
  flex-direction: row;
  align-items: center;

  align-self: stretch;

  & > *:focus {
    outline: 0;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    background-color: transparent;
    padding-left: 2px;
  }
`

const inputStyle = css`
  background-color: transparent;
  border: none;
  color: ${colors.text};
  width: 22px;
`

export const HourInput = styled.input`
  ${inputStyle}
  text-align: right;
`

export const MinuteInput = styled.input`
  ${inputStyle}
  text-align: left;
`
