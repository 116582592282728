import { useCallback, useState } from 'react'

const useLocalStorage = <T>({
  key,
  initialValue,
}: {
  key: string
  initialValue: T
}): [T, (value: T) => void, () => void] => {
  const [valueState, setValueState] = useState(() => {
    const storedValue = window.localStorage.getItem(key)
    return storedValue ? JSON.parse(storedValue) : initialValue
  })

  const setValue = useCallback(
    (value: any) => {
      window.localStorage.setItem(key, JSON.stringify(value))
      setValueState(value)
    },
    [key],
  )

  const clearValue = useCallback(() => {
    window.localStorage.removeItem(key)
    setValueState(initialValue)
  }, [initialValue, key])

  return [valueState, setValue, clearValue]
}

export default useLocalStorage
