import Lozenge from '@atlaskit/lozenge'
import { typography } from '@atlaskit/theme'
import { DateTime } from 'luxon'
import React from 'react'
import styled from 'styled-components'

import { Matching_ForTransitionListItemFragment } from '../../../../../../graphql'

const Outer = styled.span`
  ${typography.h300}
`

type Props = {
  matching: Matching_ForTransitionListItemFragment
}

const Deadline = ({ matching }: Props) => {
  const deadlineOn = matching.transition?.deadlineOn

  if (!deadlineOn) {
    return null
  }

  const dateTime = DateTime.fromISO(deadlineOn)
  const daysUntilDeadline = dateTime.diffNow().as('days')

  const formatedDate = dateTime.toLocaleString({
    day: 'numeric',
    month: 'short',
  })

  return (
    <Outer>
      {daysUntilDeadline < 2 ? (
        <Lozenge appearance={'removed'}>{formatedDate}</Lozenge>
      ) : daysUntilDeadline < 7 ? (
        <Lozenge appearance={'inprogress'}>{formatedDate}</Lozenge>
      ) : (
        formatedDate
      )}
    </Outer>
  )
}

export default Deadline
