import { LoadingButton as Button } from '@atlaskit/button'
import Drawer from '@atlaskit/drawer'
import InlineMessage from '@atlaskit/inline-message'
import React, { ComponentProps, useCallback, useState } from 'react'
import styled from 'styled-components'

import { useSendSmsMutation } from '../../../graphql'
import useSwitch from '../../../lib/useSwitch'
import useValues from '../../../lib/useValues'
import { LoadingSpinner } from '../../Spinner'

import Editor from './Editor'
import Preview from './Preview'
import { Sms, getValidSms } from './utils'

const Outer = styled.div``

const Buttons = styled.div`
  padding: 16px 4px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  & > * + * {
    margin-right: 8px;
  }
`

type Props = Pick<
  ComponentProps<typeof Editor>,
  'templates' | 'vars' | 'toUserId'
> & {
  onCancel?: () => void
  onSendComplete?: (sms: Sms) => void
}

const SmsComposer = ({
  toUserId,
  templates,
  vars,
  onSendComplete,
  onCancel,
}: Props) => {
  const [isSent, setIsSent] = useState(false)
  const [sms, { patch }] = useValues<Sms>({})
  const [sendSms, { loading: loadingSend, error: errorSend }] =
    useSendSmsMutation()

  const send = useCallback(async () => {
    const validSms = getValidSms(sms)
    if (validSms) {
      const result = await sendSms({
        variables: {
          input: validSms,
        },
      })
      setIsSent(true)
      onSendComplete?.(validSms)

      return result
    }
  }, [sms, sendSms, onSendComplete, setIsSent])

  return (
    <Outer>
      <Editor
        toUserId={toUserId}
        templates={templates}
        vars={vars}
        onPatch={patch}
      />
      <Preview value={sms} onPatch={patch} />
      <Buttons>
        <Button
          isDisabled={isSent || !getValidSms(sms)}
          appearance={'primary'}
          onClick={send}
          isLoading={loadingSend}
        >
          {'Send'}
        </Button>
        {!!onCancel && (
          <Button onClick={onCancel}>{isSent ? 'Close' : 'Cancel'}</Button>
        )}
        {errorSend && (
          <InlineMessage
            title={errorSend.message || 'Error sending SMS'}
            type={'error'}
          />
        )}
        {isSent && (
          <InlineMessage
            title={'SMS was successfully sent 👍'}
            type={'confirmation'}
          />
        )}
      </Buttons>
      <LoadingSpinner show={loadingSend} />
    </Outer>
  )
}

const DrawerInner = styled.div`
  padding: 0 24px 0 8px;
`

type DrawerProps = ComponentProps<typeof SmsComposer> & {
  isOpen?: boolean
  onClose?: () => void
  onOpenComplete?: () => void
  onCloseComplete?: () => void
}

export const SmsComposerDrawer = ({
  isOpen,
  onClose,
  onOpenComplete,
  onCloseComplete,
  ...props
}: DrawerProps) => (
  <Drawer
    isOpen={!!isOpen}
    onClose={onClose}
    onOpenComplete={onOpenComplete}
    onCloseComplete={onCloseComplete}
    width={'medium'}
  >
    <DrawerInner>
      <SmsComposer onCancel={onClose} {...props} />
    </DrawerInner>
  </Drawer>
)

type SmsComposerButtonProps = {
  buttonProps: ComponentProps<typeof Button>
  composerProps: ComponentProps<typeof SmsComposer>
}

export const SmsComposerButton = ({
  buttonProps: _buttonProps,
  composerProps,
}: SmsComposerButtonProps) => {
  const [isComposerOpen, openComposer, closeComposer] = useSwitch(false)

  const { isLoading, ...buttonProps } = { ..._buttonProps }

  return (
    <>
      <Button
        appearance={'default'}
        isLoading={isComposerOpen || isLoading}
        {...buttonProps}
        onClick={openComposer}
      />
      <SmsComposerDrawer
        isOpen={isComposerOpen}
        onClose={closeComposer}
        {...composerProps}
      />
    </>
  )
}
