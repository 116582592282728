import Button from '@atlaskit/button'
import React, { useCallback, useMemo, useState } from 'react'

import {
  useDelegationCoachQuery,
  useUpdateDelegationCoachSettingsMutation,
} from '../../../../graphql'
import { PageOuter } from '../styled'

import { FunctionsEditor } from './styled'

type Props = {
  initialTaskSuggestionsFunction: string
}

const DelegationCoachTaskSuggestionsFunction = ({
  initialTaskSuggestionsFunction,
}: Props) => {
  const [updateDelegationCoachSettings, { loading: mutationLoading }] =
    useUpdateDelegationCoachSettingsMutation()

  const [taskSuggestionsFunctionState, setTaskSuggestionsFunctionState] =
    useState<string>(initialTaskSuggestionsFunction)

  const onValueChange = useCallback((newValue: string) => {
    try {
      JSON.parse(newValue)
      setErrorMessageState(undefined)
    } catch (e) {
      setErrorMessageState('Invalid JSON')
    }

    setTaskSuggestionsFunctionState(newValue)
  }, [])

  const [errorMessageState, setErrorMessageState] = useState<
    undefined | string
  >(undefined)

  const onSave = useCallback(() => {
    try {
      JSON.parse(taskSuggestionsFunctionState || '')
    } catch (e) {
      setErrorMessageState('Invalid JSON')
      return
    }

    updateDelegationCoachSettings({
      variables: {
        input: {
          taskSuggestionsFunction: JSON.stringify(
            JSON.parse(taskSuggestionsFunctionState),
          ),
        },
      },
      // TODO OptimisticResponse
    })

    setErrorMessageState(undefined)
  }, [taskSuggestionsFunctionState, updateDelegationCoachSettings])

  return (
    <>
      <FunctionsEditor
        value={taskSuggestionsFunctionState}
        onChange={(newValue) => onValueChange(newValue || '')}
        isDisabled={mutationLoading}
        helperMessage={'Only update the description fields'}
        errorMessage={errorMessageState}
      />
      <Button
        appearance={'primary'}
        onClick={onSave}
        isDisabled={
          !!errorMessageState ||
          !taskSuggestionsFunctionState ||
          taskSuggestionsFunctionState === initialTaskSuggestionsFunction
        }
      >
        {'Save'}
      </Button>
    </>
  )
}

const DelegationCoachTaskSuggestionsFunctionContainer = () => {
  const { data, loading: queryLoading, error } = useDelegationCoachQuery()

  const taskSuggestionsFunction = useMemo(
    () =>
      data?.delegationCoach.taskSuggestionsFunction
        ? JSON.stringify(
            JSON.parse(data.delegationCoach.taskSuggestionsFunction),
            null,
            2,
          )
        : undefined,
    [data?.delegationCoach.taskSuggestionsFunction],
  )

  return (
    <PageOuter>
      <h1>{'🤖 Delegation Coach Task Suggestions Function'}</h1>
      {error ? (
        <h3>{'Error, try again later.'}</h3>
      ) : queryLoading || !data ? (
        <h3>{'Loading...'}</h3>
      ) : (
        <DelegationCoachTaskSuggestionsFunction
          initialTaskSuggestionsFunction={taskSuggestionsFunction || '{}'}
        />
      )}
    </PageOuter>
  )
}

export default DelegationCoachTaskSuggestionsFunctionContainer
