import styled from 'styled-components'

// Styled div for the email message
export const EmailMessageContainer = styled.div`
  padding: 10px;
`

// Styled paragraph for the email text
export const EmailText = styled.p`
  color: #333333;
  font-size: 14px;
  margin-bottom: 10px;
  white-space: pre-line;
`

// Styled link for URLs in the email text
export const EmailLink = styled.a`
  color: blue;
  text-decoration: underline;
`
