export const EXEC_COPILOT = '/exec'

export const ASSISTANT_COPILOT = '/assistant'
export const ASSISTANT_EVERYDAY_TASKS_SYSTEM_PROMPT = `${ASSISTANT_COPILOT}/everyday-tasks-system-prompt`
export const ASSISTANT_EXEC_TASKS_SYSTEM_PROMPT = `${ASSISTANT_COPILOT}/exec-tasks-system-prompt`
export const ASSISTANT_COPILOT_EVERYDAY_TASKS = `${ASSISTANT_COPILOT}/everyday-tasks`
export const ASSISTANT_COPILOT_EVERYDAY_TASK = `${ASSISTANT_COPILOT_EVERYDAY_TASKS}/:taskId`
export const ASSISTANT_COPILOT_CATEGORIES = `${ASSISTANT_COPILOT}/category`
export const ASSISTANT_COPILOT_CATEGORY = `${ASSISTANT_COPILOT_CATEGORIES}/:categoryId`

export const EXPORT_CHAT = '/export-chat'
