import { DatePicker } from '@atlaskit/datetime-picker'
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message'
import { DateTime } from 'luxon'
import React, { useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
  EntityPricingFragment,
  ExecutivableBillingFragment,
  SelectEntityPricingInput,
  TeamBillingFragment,
} from '../../graphql'
import useValues from '../../lib/useValues'
import PricingSelect from '../PricingSelect'
import { Field, TextArea } from '../form'

import { SectionMessageOuter } from './styled'

type EntityType = ExecutivableBillingFragment | TeamBillingFragment

const isUser = (entity?: EntityType): entity is ExecutivableBillingFragment =>
  !!entity && entity?.__typename !== 'Team'

const isJsxElement = (
  item: JSX.Element | false | undefined | null,
): item is JSX.Element => !!item

type Props = {
  entity?: EntityType
  currentEntityPricing?: EntityPricingFragment | null
  isExpiringPricing?: boolean
  startExpiringPricing?: () => void
  stopExpiringPricing?: () => void
  isSelectingPricing: boolean
  startSelectingPricing: () => void
  stopSelectingPricing: () => void
  selectEntityPricing: (input: {
    variables: { input: SelectEntityPricingInput }
  }) => void
  expireUserPricing?: (input: {
    variables: { input: { userPricingId: string; expireAt: Date } }
  }) => void
}

const PricingPackage = ({
  entity,
  currentEntityPricing,
  isExpiringPricing,
  startExpiringPricing,
  stopExpiringPricing,
  isSelectingPricing,
  startSelectingPricing,
  stopSelectingPricing,
  selectEntityPricing,
  expireUserPricing,
}: Props) => {
  const navHistory = useHistory()
  const { pathname } = useLocation()

  const [newSelection, { setters }] = useValues<
    Omit<Partial<SelectEntityPricingInput>, 'entityId'>
  >({}, ['pricingId', 'notepad', 'activeAt', 'expireAt'])

  const [expiration, setExpiration] = useState<Date>()

  const isUserEntity = isUser(entity)
  const didSelectValidPricing = newSelection.pricingId && newSelection.activeAt
  const currentPricing = currentEntityPricing?.pricing
  const isNotOwnUserPricing =
    isUserEntity && currentEntityPricing?.owner?.id !== entity.id
  const canRemovePricing = Boolean(
    startExpiringPricing &&
      !isNotOwnUserPricing &&
      isUserEntity &&
      (entity as ExecutivableBillingFragment).principalUser?.id,
  )

  const savePricing = useCallback(() => {
    const { pricingId, activeAt } = newSelection
    if (entity?.id && didSelectValidPricing && pricingId && activeAt) {
      selectEntityPricing({
        variables: {
          input: {
            ...newSelection,
            entityId: entity.id,
            activeAt: new Date(activeAt),
            pricingId,
          },
        },
      })
    }
  }, [didSelectValidPricing, entity?.id, newSelection, selectEntityPricing])

  const doExpirePricing = useCallback(() => {
    if (
      entity?.id &&
      currentEntityPricing?.id &&
      expiration &&
      expireUserPricing
    ) {
      expireUserPricing({
        variables: {
          input: {
            userPricingId: currentEntityPricing.id,
            expireAt: expiration,
          },
        },
      })
    }
  }, [currentEntityPricing?.id, entity?.id, expiration, expireUserPricing])

  return (
    <SectionMessageOuter>
      {isSelectingPricing ? (
        <SectionMessage
          appearance={'discovery'}
          title={'Select new pricing package'}
          actions={[
            <SectionMessageAction
              key={'cancel-select-pricing'}
              onClick={stopSelectingPricing}
            >
              {'Cancel'}
            </SectionMessageAction>,
            didSelectValidPricing ? (
              <SectionMessageAction onClick={savePricing}>
                {'Select'}
              </SectionMessageAction>
            ) : (
              <div />
            ),
          ]}
        >
          <PricingSelect
            valueId={newSelection.pricingId}
            onChangeValueId={setters.pricingId}
          />
          <Field label={'Active from (12:00am ET)'} isRequired>
            <DatePicker
              placeholder={'Select a date (usually first day of month)'}
              value={
                newSelection.activeAt
                  ? DateTime.fromJSDate(new Date(newSelection.activeAt), {
                      zone: 'America/New_York',
                    }).toISODate()
                  : undefined
              }
              maxDate={
                newSelection.expireAt
                  ? DateTime.fromJSDate(new Date(newSelection.expireAt), {
                      zone: 'America/New_York',
                    }).toISODate()
                  : undefined
              }
              onChange={(date) =>
                setters.activeAt?.(
                  date
                    ? DateTime.fromISO(date)
                        .setZone('America/New_York')
                        .startOf('day')
                        .toJSDate()
                    : undefined,
                )
              }
            />
          </Field>
          <Field label={'Active until (11:59pm ET)'}>
            <DatePicker
              placeholder={'Optional (usually last day of month)'}
              value={
                newSelection.expireAt
                  ? DateTime.fromJSDate(new Date(newSelection.expireAt), {
                      zone: 'America/New_York',
                    }).toISODate()
                  : undefined
              }
              minDate={
                newSelection.activeAt
                  ? DateTime.fromJSDate(new Date(newSelection.activeAt), {
                      zone: 'America/New_York',
                    }).toISODate()
                  : undefined
              }
              onChange={(date) =>
                setters.expireAt?.(
                  date
                    ? DateTime.fromISO(date)
                        .setZone('America/New_York')
                        .endOf('day')
                        .toJSDate()
                    : undefined,
                )
              }
            />
          </Field>
          <Field label={'Comments, description'}>
            <TextArea
              value={newSelection.notepad || undefined}
              onChangeValue={setters.notepad}
              placeholder={'Optional'}
            />
          </Field>
        </SectionMessage>
      ) : isExpiringPricing ? (
        <SectionMessage
          appearance={'discovery'}
          title={'Set pricing package expiration'}
          actions={[
            <SectionMessageAction
              key={'cancel-expiring-pricing'}
              onClick={stopExpiringPricing}
            >
              {'Cancel'}
            </SectionMessageAction>,
            expiration ? (
              <SectionMessageAction onClick={doExpirePricing}>
                {'Expire'}
              </SectionMessageAction>
            ) : (
              <div />
            ),
          ]}
        >
          <Field label={'Expire on (11:59pm ET)'}>
            <DatePicker
              placeholder={'Select date (usually last day of month)'}
              value={
                expiration
                  ? DateTime.fromJSDate(new Date(expiration), {
                      zone: 'America/New_York',
                    }).toISODate()
                  : undefined
              }
              minDate={
                currentEntityPricing?.activeAt
                  ? DateTime.fromJSDate(
                      new Date(currentEntityPricing?.activeAt),
                      {
                        zone: 'America/New_York',
                      },
                    ).toISODate()
                  : undefined
              }
              onChange={(date) =>
                setExpiration(
                  date
                    ? DateTime.fromISO(date)
                        .setZone('America/New_York')
                        .endOf('day')
                        .toJSDate()
                    : undefined,
                )
              }
            />
          </Field>
        </SectionMessage>
      ) : !currentPricing ? (
        <SectionMessage
          appearance={'warning'}
          title={'No package selected'}
          actions={[
            <SectionMessageAction
              key={'select-pricing'}
              onClick={() => startSelectingPricing()}
            >
              {'Select a pricing package'}
            </SectionMessageAction>,
          ]}
        >
          <p>{`This ${
            isUserEntity ? 'user' : 'team'
          } has no pricing package assigned yet`}</p>
        </SectionMessage>
      ) : (
        <SectionMessage
          appearance={'information'}
          title={'Current pricing package'}
          actions={[
            <SectionMessageAction
              key={'view-pricing'}
              onClick={() =>
                navHistory.push(`${pathname}/pricings/${currentPricing.id}`)
              }
            >
              {'View pricing'}
            </SectionMessageAction>,
            <SectionMessageAction
              key={'change-pricing'}
              onClick={startSelectingPricing}
            >
              {'Change package'}
            </SectionMessageAction>,
            canRemovePricing && (
              <SectionMessageAction onClick={startExpiringPricing}>
                {'Remove package'}
              </SectionMessageAction>
            ),
          ].filter(isJsxElement)}
        >
          <p>
            {`${isUserEntity ? 'User' : 'Team'} is on `}
            <strong>
              {/* TS gymnastics because we know entity is a user if isNotOwnUserPricing is true */}
              {isNotOwnUserPricing && 'profile' in currentEntityPricing?.owner
                ? `${currentEntityPricing.owner.profile.displayName}’s `
                : ''}
              {currentPricing.ref}
            </strong>
            {' package'}
            {currentPricing.priceDescription
              ? ` for ${currentPricing.priceDescription}`
              : ''}
            <br />
            {currentPricing.hoursDescription}
            {isNotOwnUserPricing && (
              <>
                <br />
                <br />
                {'Hours Limit: '}
                <strong>{entity.hoursLimit || 'no limit'}</strong>
                <br />
                {'Are personal tasks allowed? '}
                <strong>
                  {entity.isAllowedPersonal == null
                    ? 'TBD'
                    : entity.isAllowedPersonal
                    ? 'Yes'
                    : 'No'}
                </strong>
              </>
            )}
          </p>
        </SectionMessage>
      )}
    </SectionMessageOuter>
  )
}

export default PricingPackage
