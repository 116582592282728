import Button from '@atlaskit/button'
import SearchIcon from '@atlaskit/icon/glyph/search'
import ClearIcon from '@atlaskit/icon/glyph/select-clear'
import debounce from 'lodash/debounce'
import React, {
  useState,
  useCallback,
  KeyboardEvent,
  ComponentProps,
  useRef,
  useEffect,
} from 'react'

import TextField from './TextField'

interface Props extends ComponentProps<typeof TextField> {
  waitMs?: number
  initialValue?: string
}

const ON_KEY_DOWN = ({ target, key }: KeyboardEvent<HTMLInputElement>) => {
  if (key === 'Escape') {
    // @ts-ignore
    target.blur()
  }
}

const ON_KEY_UP = ({ target, key }: KeyboardEvent<HTMLInputElement>) => {
  if (key === 'Enter') {
    // @ts-ignore
    target.blur()
  }
}

export default function SearchTextField({
  value: _value,
  initialValue,
  onChangeValue,
  waitMs = 300,
  ...props
}: Props) {
  const inputRef = useRef<HTMLInputElement>()
  const [hasText, setHasText] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChangeValue = useCallback(
    debounce(onChangeValue || (() => null), waitMs, { maxWait: 2000 }),
    [onChangeValue],
  )

  const _onChangeValue = useCallback(
    (text: string) => {
      setHasText(!!text)
      debouncedOnChangeValue(text?.toLowerCase().trim())
    },
    [debouncedOnChangeValue, setHasText],
  )

  const clearInput = useCallback(() => {
    if (inputRef.current) {
      // @ts-ignore
      inputRef.current.value = ''
      debouncedOnChangeValue.cancel()
      setHasText(false)
      onChangeValue?.('')
    }
  }, [debouncedOnChangeValue, onChangeValue])

  // Set initial value
  useEffect(() => {
    if (initialValue && inputRef.current) {
      inputRef.current.value = initialValue
      _onChangeValue(initialValue)
    }
  }, [initialValue, _onChangeValue])

  const onKeyDown = useCallback(ON_KEY_DOWN, [])
  const onKeyUp = useCallback(ON_KEY_UP, [])

  return (
    <TextField
      ref={inputRef}
      onChangeValue={_onChangeValue}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      elemAfterInput={
        <Button
          appearance={'subtle-link'}
          iconBefore={
            hasText ? (
              <ClearIcon size={'small'} label={'Clear'} />
            ) : (
              <SearchIcon size={'small'} label={'Search users'} />
            )
          }
          onClick={clearInput}
        />
      }
      {...props}
    />
  )
}
