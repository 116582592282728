import React, { useMemo } from 'react'
import { RouteChildrenProps } from 'react-router-dom'

import Spinner from '../../../../../components/Spinner'
import { TimeTrackingEntryTemplateId } from '../../../../../graphql'
import PromptEditor from '../../PromptEditor'
import useSubprompt from '../../PromptEditor/useSubprompt'
import useSystemFunctions from '../useSystemFunctions'
import { CopilotPromptSettingsOverrideType } from '../useUpdateAssistantCopilot'

const TaskPromptEditor = ({
  match,
}: RouteChildrenProps<{ taskId?: string }>) => {
  const templateId = match?.params.taskId as
    | TimeTrackingEntryTemplateId
    | undefined

  const { systemFunctions, loading: systemFunctionsLoading } =
    useSystemFunctions()
  const { subprompt, loading: promptLoading } = useSubprompt(templateId)

  // TODO: Get emoji from the backend
  const title = useMemo(() => `🗂️ ${subprompt?.label}`, [subprompt?.label])

  if (!templateId) return null

  if (systemFunctionsLoading || promptLoading || !subprompt) return <Spinner />

  return (
    <PromptEditor
      promptId={templateId}
      subpromptId={templateId}
      prompt={subprompt}
      title={title}
      upperLevelFunctions={systemFunctions}
      overridenPromptType={CopilotPromptSettingsOverrideType.TASK}
      helperMessage={
        'If set, will be used in place of its Category prompt (if set) or the Everyday Tasks prompt.'
      }
    />
  )
}

export default TaskPromptEditor
