import { useMemo } from 'react'

const WITHDOUBLE = 'withdouble.com/'

const LOCAL_APP = 'http://localhost:8080/'
const STAGING_APP = `https://staging.${WITHDOUBLE}`
const PROD_APP = `https://app.${WITHDOUBLE}`

const LOCAL_DASHBOARD = 'http://localhost:8081/'
const PROD_DASHBOARD = `https://dashboard.${WITHDOUBLE}`

const LOCAL_COCKPIT = 'http://localhost:3000/'
const STAGING_COCKPIT = `https://beta.cockpit.${WITHDOUBLE}`
const PRODUCTION_COCKPIT = `https://cockpit.${WITHDOUBLE}`

enum DB_ENV {
  LOCALHOST = 'LOCALHOST',
  STAGING = 'STAGING',
  PRODUCTION = 'PRODUCTION',
}

interface ClientDomain {
  dbEnv: DB_ENV
  cockpitUrl: string
  appUrl: string
  dashboardUrl: string
}

const useGetClientDomain = (graphUrl?: string): ClientDomain | null =>
  useMemo(() => {
    const url = graphUrl || process.env.REACT_APP_GRAPHQL_ENDPOINT
    if (!url) {
      console.error(
        'GraphQL endpoint is not defined. Pass it as a parameter or define it in process.env.REACT_APP_GRAPHQL_ENDPOINT',
      )
      return null
    }
    const dbEnv = url.includes('localhost')
      ? DB_ENV.LOCALHOST
      : url.includes('staging')
      ? DB_ENV.STAGING
      : DB_ENV.PRODUCTION

    let cockpitUrl: string
    let appUrl: string
    let dashboardUrl: string
    switch (dbEnv) {
      case DB_ENV.LOCALHOST:
        cockpitUrl = LOCAL_COCKPIT
        appUrl = LOCAL_APP
        dashboardUrl = LOCAL_DASHBOARD
        break
      case DB_ENV.STAGING:
        cockpitUrl = STAGING_COCKPIT
        appUrl = STAGING_APP
        dashboardUrl = PROD_DASHBOARD
        break
      case DB_ENV.PRODUCTION:
        cockpitUrl = PRODUCTION_COCKPIT
        appUrl = PROD_APP
        dashboardUrl = PROD_DASHBOARD
        break
    }

    return { dbEnv, cockpitUrl, appUrl, dashboardUrl }
  }, [graphUrl])

export default useGetClientDomain
