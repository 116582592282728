import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { ErrorEmptyState } from '../../../components/EmptyState'
import { LoadingSpinner } from '../../../components/Spinner'
import Tabs from '../../../components/Tabs'
import { useGetTeamQuery } from '../../../graphql'

import Billing from './Billing'
import Header from './Header'
import Reporting from './Reporting'
import { Content, Outer, TabContent } from './styled'

const Team = () => {
  const {
    url,
    params: { teamId },
  } = useRouteMatch<{ teamId: string }>()
  const { data, loading, error } = useGetTeamQuery({
    variables: { id: teamId },
    skip: !teamId,
  })

  const mock = !data?.team
  const team = data?.team

  if (!team) {
    return null
  }

  const tabs = [
    // Billing & package
    {
      label: 'Billing',
      content: (
        <TabContent>
          <Billing teamId={team.id} />
        </TabContent>
      ),
    },
    // TODO - show team reporting here?
    {
      label: 'Reporting',
      content: (
        <TabContent>
          <Reporting />
        </TabContent>
      ),
    },
  ]

  // TODO - filter automations by teamId?
  // const rightTabs = [
  //   {
  //     label: '🤖',
  //     slug: 'autopilot',
  //     content: (
  //       <TabContent>
  //         <AutomationJobsInline
  //           filter={{
  //             userId: user.id,
  //           }}
  //         />
  //       </TabContent>
  //     ),
  //   },
  // ]

  return (
    <Outer>
      <Header team={team} skeleton={mock} />

      <Content>
        {error ? (
          <ErrorEmptyState error={error} />
        ) : (
          <Tabs leftTabs={tabs} basePath={url} />
        )}
        <LoadingSpinner show={loading} />
      </Content>
    </Outer>
  )
}

export default Team
