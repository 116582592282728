import React from 'react'

import Badge from '../../../../components/Badge'
import { Matching_BookmarksCountFragment } from '../../../../graphql'

type Props = {
  matching: Matching_BookmarksCountFragment
}

const BookmarksBadge = ({ matching }: Props) => {
  return (
    <Badge
      text={matching.bookmarksCount || 0}
      emoji={'🔖'}
      title={'Favorited'}
    />
  )
}

export default BookmarksBadge
