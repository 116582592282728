import AkColorPicker from '@atlaskit/color-picker'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

type ColorCode = string

const Outer = styled.div`
  div[color] {
    margin-top: 1px;
    margin-left: 1px;
  }
`

type Props = Omit<
  ComponentProps<typeof AkColorPicker>,
  'palette' | 'selectedColor'
> & {
  palette: ColorCode[]
  selectedColor?: ColorCode
}

function normalizeColor(color?: ColorCode): ColorCode | undefined {
  return color?.toLowerCase()
}

function ensureColorInPalette(
  palette: ColorCode[],
  color?: ColorCode,
): ColorCode[] {
  // Switch palette to lower case
  color = normalizeColor(color)
  palette = palette
    .map(normalizeColor)
    .filter((color): color is ColorCode => !!color)

  if (color && !palette.includes(color)) {
    palette = [...palette, color]
  }

  return palette
}

const ColorPicker = ({ palette, selectedColor, ...props }: Props) => (
  <Outer>
    <AkColorPicker
      {...props}
      selectedColor={normalizeColor(selectedColor)}
      palette={ensureColorInPalette(palette, selectedColor).map((value) => ({
        label: value,
        value,
      }))}
    />
  </Outer>
)

export default ColorPicker
