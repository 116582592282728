import { between, before, after, middleish } from '@withdouble/ordering'

import {
  DossierFolderFragment,
  DossierFieldFragment,
  DossierSectionFragment,
  DossierInputableType,
  PossibleTypesResultData,
  DossierInputablePlaceHint,
} from '../../../graphql'

export function assertNever(x: never): never {
  return x
}

export function orderIndexAfter<
  T extends
    | DossierFolderFragment
    | DossierSectionFragment
    | DossierFieldFragment,
>(entities: T[] | null | undefined): string {
  entities = entities || []

  for (let index = entities.length - 1; index >= 0; index--) {
    const orderIndex = entities[index].orderIndex
    if (orderIndex) {
      return after(orderIndex)
    }
  }

  return middleish()
}

export function getSortedEntity<
  T extends
    | DossierFolderFragment
    | DossierSectionFragment
    | DossierFieldFragment,
>(
  _entities: T[],
  oldIndex: number,
  newIndex: number,
): null | { entity: T; orderIndex: string } {
  if (newIndex === oldIndex) {
    return null
  }

  const entities = [..._entities]
  const entity = entities.splice(oldIndex, 1)[0]
  const entityBefore = newIndex > 0 ? entities[newIndex - 1] : undefined
  const entityAfter = entities[newIndex]
  let orderIndex: string | undefined

  if (!entityBefore?.orderIndex && !entityAfter?.orderIndex) {
    orderIndex = middleish()
  } else if (entityBefore?.orderIndex && entityAfter?.orderIndex) {
    orderIndex = between(entityBefore.orderIndex, entityAfter.orderIndex)
  } else if (entityBefore?.orderIndex) {
    orderIndex = after(entityBefore.orderIndex)
  } else if (entityAfter?.orderIndex) {
    orderIndex = before(entityAfter.orderIndex)
  }

  if (entity && orderIndex) {
    return { entity, orderIndex }
  }

  return null
}

export function sortEntities<
  T extends
    | DossierFolderFragment
    | DossierSectionFragment
    | DossierFieldFragment,
>(a: T, b: T): 1 | -1 {
  if (a.orderIndex && b.orderIndex) {
    return a.orderIndex < b.orderIndex ? -1 : 1
  }

  if (a.orderIndex) {
    return -1
  }

  if (b.orderIndex) {
    return 1
  }

  return -1
}

type DossierInputableTypename =
  PossibleTypesResultData['possibleTypes']['DossierInputable'][number]

export function inputableTypenameToType(
  inputableTypename: undefined | null | DossierInputableTypename,
): null | DossierInputableType {
  if (!inputableTypename) {
    return null
  }

  switch (inputableTypename) {
    case 'DossierInputableText':
      return DossierInputableType.TEXT
    case 'DossierInputableEmail':
      return DossierInputableType.EMAIL
    case 'DossierInputablePhoneNumber':
      return DossierInputableType.PHONE_NUMBER
    case 'DossierInputablePlace':
      return DossierInputableType.PLACE
    case 'DossierInputableAnniversaryDate':
      return DossierInputableType.ANNIVERSARY_DATE
    default:
      return assertNever(inputableTypename)
  }
}

export const PLACE_HINT_LABEL: Record<DossierInputablePlaceHint, string> = {
  [DossierInputablePlaceHint.ADDRESS]: 'Address',
  [DossierInputablePlaceHint.ESTABLISHMENT]: 'Establishment',
  [DossierInputablePlaceHint.CITY]: 'City',
}

export const PLACE_HINT_DESCRIPTION: Record<DossierInputablePlaceHint, string> =
  {
    [DossierInputablePlaceHint.ADDRESS]: 'Precise address (e.g. shipping)',
    [DossierInputablePlaceHint.ESTABLISHMENT]: 'Businesses, public places',
    [DossierInputablePlaceHint.CITY]: 'City-level only address',
  }
