import React from 'react'

import BooleanSelect from '../../../../components/BooleanSelect'
import { Field, TextField } from '../../../../components/form'
import UserSelect from '../../../../components/users/Select'
import { ListFilter, reduceFilter } from '../utils'

import { Outer } from './styled'

type FilterEditorProps = {
  value: ListFilter
  onPatch: (patch: Partial<ListFilter>) => void
  isCompact?: boolean
}

const FilterEditor = ({ value, onPatch, isCompact }: FilterEditorProps) => {
  const filter = reduceFilter(value)

  const isFixed = (key: keyof ListFilter): boolean =>
    Boolean(value.fixed && key in value.fixed)

  return (
    <Outer>
      {/* <Field label={'Automation'}>
        <UserSelect
          isClearable
          isDisabled={!!value.fixed?.userId}
          value={filter?.userId ? { id: filter.userId } : undefined}
          onChange={(user) => onPatch({ userId: user?.id || null })}
        />
      </Field> */}

      <Field label={'Matching Id'}>
        <TextField
          isDisabled={!!value?.fixed?.hasParams?.matchingId}
          value={filter?.hasParams?.matchingId}
          onChangeValue={(matchingId) =>
            onPatch({ hasParams: matchingId ? { matchingId } : null })
          }
          isCompact={isCompact}
        />
      </Field>

      <Field label={'User'}>
        <UserSelect
          isClearable
          isDisabled={isFixed('userId')}
          value={filter?.userId ? { id: filter.userId } : undefined}
          onChange={(user) => onPatch({ userId: user?.id || null })}
          spacing={isCompact ? 'compact' : 'default'}
        />
      </Field>

      <Field label={'Successfully completed jobs'}>
        <BooleanSelect
          isClearable
          isDisabled={isFixed('hasCompleted')}
          value={filter.hasCompleted}
          onChangeValue={(hasCompleted) => onPatch({ hasCompleted })}
          spacing={isCompact ? 'compact' : 'default'}
        />
      </Field>

      <Field label={'Pending (will run) jobs'}>
        <BooleanSelect
          isClearable
          isDisabled={isFixed('isPending')}
          value={filter.isPending}
          onChangeValue={(isPending) => onPatch({ isPending })}
          spacing={isCompact ? 'compact' : 'default'}
        />
      </Field>

      <Field label={'Failed jobs (will not be attempted again)'}>
        <BooleanSelect
          isClearable
          isDisabled={isFixed('hasFailed')}
          value={filter.hasFailed}
          onChangeValue={(hasFailed) => onPatch({ hasFailed })}
          spacing={isCompact ? 'compact' : 'default'}
        />
      </Field>

      <Field label={'Encountered error'}>
        <BooleanSelect
          isClearable
          isDisabled={isFixed('hasError')}
          value={filter.hasError}
          onChangeValue={(hasError) => onPatch({ hasError })}
          spacing={isCompact ? 'compact' : 'default'}
        />
      </Field>

      <Field label={'Reviewed'}>
        <BooleanSelect
          isClearable
          isDisabled={isFixed('isReviewed')}
          value={filter.isReviewed}
          onChangeValue={(isReviewed) => onPatch({ isReviewed })}
          spacing={isCompact ? 'compact' : 'default'}
        />
      </Field>
    </Outer>
  )
}

export default FilterEditor
