import Button from '@atlaskit/button'
import defaultsDeep from 'lodash/defaultsDeep'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  EmailAddressWithNameInput,
  useListMessageTemplatesQuery,
  useGetMessageTemplateQuery,
  MessageTemplateKind,
  onlyIfEmailMessageTemplate,
  MessageTemplateFragment,
  useGetWorkspaceQuery,
  useGetUserQuery,
} from '../../../graphql'
import { notFalsy } from '../../../lib/utils'
import MarkdownEditor from '../../MarkdownEditor'
import { LoadingSpinner } from '../../Spinner'
import { ToggleStateless as Toggle } from '../../Toggle'
import { Select, Field, FieldsRow, TextField, ImageUrlField } from '../../form'
import { LanguageSelect } from '../../languages/select'
import { getPronoun } from '../../utils'
import { SENDERS } from '../utils'

import { Email } from './types'

const TemplateOuter = styled.div`
  flex: 0 1 35%;
  min-width: 400px;
`

const VarsOuter = styled.div`
  flex: 0 1 30%;
`

const Spacer = styled.div`
  flex: 0 0 16px;
`

const senderOption = (
  sender: Partial<EmailAddressWithNameInput> | undefined,
): { value: string; label: string } | undefined =>
  sender?.email
    ? {
        value: sender.email,
        label: sender.name ? `${sender.name} <${sender.email}>` : sender.email,
      }
    : undefined

const templateOption = ({
  id,
  name,
}: Pick<MessageTemplateFragment, 'id' | 'name'>): {
  value: string
  label: string
} => ({
  value: id,
  label: name,
})

type Props = {
  messageTemplateIds: string[]
  email: Partial<Email>
  workspaceId?: string
  executiveId?: string
  assistantId?: string
  toUserId?: string
  onPatch: (email: Partial<Email>) => void
  onReplace: (
    email: Partial<Email> | ((prev: Partial<Email>) => Partial<Email>),
  ) => void
  messageTemplateId?: string
  onSelectMessageTemplateId: (t: string | undefined) => void
  languageCode?: string
  onSelectLanguageCode: (l: string | undefined) => void
}

const Editor = ({
  messageTemplateIds,
  email,
  onPatch,
  onReplace,
  messageTemplateId,
  onSelectMessageTemplateId,
  languageCode,
  onSelectLanguageCode,
  workspaceId,
  executiveId: _executiveId,
  assistantId: _assistantId,
  toUserId,
}: Props) => {
  const [executiveId, setExecutiveId] = useState(_executiveId)
  const [assistantId, setAssistantId] = useState(_assistantId)

  const { data: workspaceData, loading: loadingWorkspace } =
    useGetWorkspaceQuery({
      variables: { workspaceId: workspaceId || '' },
      skip: !workspaceId,
    })
  const { data: executiveData, loading: loadingExecutive } = useGetUserQuery({
    variables: {
      id: executiveId || '',
    },
    skip: !executiveId,
  })
  const { data: assistantData, loading: loadingAssistant } = useGetUserQuery({
    variables: {
      id: assistantId || '',
    },
    skip: !assistantId,
  })
  const { data: toUserData, loading: loadingToUser } = useGetUserQuery({
    variables: {
      id: toUserId || '',
    },
    skip: !toUserId,
  })
  const { data: templatesData, loading: loadingTemplates } =
    useListMessageTemplatesQuery({
      variables: {
        kinds: [MessageTemplateKind.EMAIL],
      },
    })

  const { data: templateData, loading: loadingTemplate } =
    useGetMessageTemplateQuery({
      variables: { id: messageTemplateId || '' },
      skip: !messageTemplateId,
    })

  const loading =
    loadingTemplates ||
    loadingTemplate ||
    loadingWorkspace ||
    loadingExecutive ||
    loadingAssistant ||
    loadingToUser

  const workspace = workspaceData?.workspace
  const templates = messageTemplateIds
    .map((templateId) =>
      templatesData?.list.items.find(({ id }) => templateId === id),
    )
    .filter(notFalsy)

  const template = onlyIfEmailMessageTemplate(
    messageTemplateId && templateData?.messageTemplate.id === messageTemplateId
      ? templateData?.messageTemplate
      : null,
  )

  const templateLanguage = template?.languages.find(
    (v) => languageCode === v.languageCode,
  )

  // Auto-select template if only one
  useEffect(() => {
    if (!messageTemplateId && messageTemplateIds.length === 1) {
      onSelectMessageTemplateId(messageTemplateIds[0])
    }
  }, [messageTemplateId, messageTemplateIds, onSelectMessageTemplateId])

  // Auto-select language if only one
  useEffect(() => {
    if (!languageCode && template?.languages.length === 1) {
      onSelectLanguageCode(template.languages[0].languageCode)
    }
  }, [languageCode, template, onSelectLanguageCode])

  // Auto-select executive if only one
  useEffect(() => {
    if (!executiveId && workspace?.executives?.length === 1) {
      setExecutiveId(workspace.executives[0].id)
    }
  }, [executiveId, setExecutiveId, workspace])

  // Auto-select assistant if only one
  useEffect(() => {
    if (!assistantId && workspace?.assistants?.length === 1) {
      setAssistantId(workspace.assistants[0].id)
    }
  }, [assistantId, setAssistantId, workspace])

  // Update email when selecting a template
  useEffect(() => {
    if (templateLanguage) {
      onReplace(
        ({ body: _body, subject: _subject, preview: _preview, ...email }) =>
          defaultsDeep({}, email, templateLanguage.fields),
      )
    }
  }, [templateLanguage, onReplace])

  // Auto-add executive info
  useEffect(() => {
    if (executiveData?.user.profile && !email.vars?.['EXECUTIVE_ID']) {
      onPatch({
        vars: {
          ...email.vars,
          EXECUTIVE_ID: executiveData.user.id,
          EXECUTIVE_GIVEN_NAME: executiveData.user.profile.givenName,
          EXECUTIVE_DISPLAY_NAME: executiveData.user.profile.displayName,
          EXECUTIVE_THEY: getPronoun(executiveData.user.pronoun, 'THEY'),
          EXECUTIVE_THEM: getPronoun(executiveData.user.pronoun, 'THEM'),
          EXECUTIVE_THEIR: getPronoun(executiveData.user.pronoun, 'THEIR'),
          EXECUTIVE_THEIRS: getPronoun(executiveData.user.pronoun, 'THEIRS'),
        },
      })
    }
  }, [executiveData, onPatch, email])

  // Auto-add assistant info
  useEffect(() => {
    if (assistantData?.user.profile && !email.vars?.['ASSISTANT_ID']) {
      onPatch({
        vars: {
          ...email.vars,
          ASSISTANT_ID: assistantData.user.id,
          ASSISTANT_GIVEN_NAME: assistantData.user.profile.givenName,
          ASSISTANT_DISPLAY_NAME: assistantData.user.profile.displayName,
          ASSISTANT_THEY: getPronoun(assistantData.user.pronoun, 'THEY'),
          ASSISTANT_THEM: getPronoun(assistantData.user.pronoun, 'THEM'),
          ASSISTANT_THEIR: getPronoun(assistantData.user.pronoun, 'THEIR'),
          ASSISTANT_THEIRS: getPronoun(assistantData.user.pronoun, 'THEIRS'),
        },
      })
    }
  }, [assistantData, onPatch, email])

  // Auto-add recipient info
  useEffect(() => {
    if (toUserData?.user.profile?.email && !email.to?.[0]?.email) {
      onPatch({
        to: [
          {
            email: toUserData.user.profile.email,
            name: toUserData.user.profile.displayName,
          },
        ],
      })
    }
  }, [toUserData, onPatch, email])

  return (
    <>
      <TemplateOuter>
        <FieldsRow>
          <Field label={'Template'} isRequired>
            <Select
              value={template ? templateOption(template) : undefined}
              options={(templates || []).map(templateOption)}
              onChangeValue={onSelectMessageTemplateId}
              validationState={template ? 'default' : 'error'}
              isDisabled={(templates || []).length === 1}
              isLoading={loadingTemplates}
            />
          </Field>
          {!!template && (
            <Field label={'Language'} isRequired>
              <LanguageSelect
                value={languageCode}
                onChangeValue={onSelectLanguageCode}
                onlyCodes={template.languages.map(
                  ({ languageCode }) => languageCode,
                )}
                validationState={templateLanguage ? 'default' : 'error'}
              />
            </Field>
          )}
        </FieldsRow>

        {!!templateLanguage && (
          <>
            <Field label={'Edit subject'}>
              <MarkdownEditor
                isInvalid={!email.subject?.trim()}
                isInline
                value={email.subject}
                onChange={(subject) => subject?.trim() && onPatch({ subject })}
                noToolbar
              />
            </Field>

            <Field label={'Edit preview'}>
              <MarkdownEditor
                isInline
                value={email.preview}
                onChange={(preview) => preview?.trim() && onPatch({ preview })}
                noToolbar
              />
            </Field>

            <Field label={'Edit message'}>
              <MarkdownEditor
                isInvalid={!email.body?.trim()}
                value={email.body}
                onChange={(body) => body?.trim() && onPatch({ body })}
                minHeight={150}
              />
            </Field>

            <Button
              appearance={'subtle'}
              target={'_blank'}
              href={`/message-templates/${messageTemplateId}`}
            >
              {'Edit template'}
            </Button>
          </>
        )}
      </TemplateOuter>

      <Spacer />

      <VarsOuter>
        {templateLanguage && (
          <>
            <FieldsRow>
              <Field label={'From Sender'} isRequired>
                <Select
                  validationState={email?.from?.email ? 'default' : 'error'}
                  value={senderOption(
                    SENDERS.find(
                      (sender) => email?.from?.email === sender.email,
                    ),
                  )}
                  options={SENDERS.map(senderOption).filter(notFalsy)}
                  onChangeValue={(senderEmail) => {
                    const sender = SENDERS.find(
                      (sender) => senderEmail === sender.email,
                    )
                    if (sender) {
                      onPatch({
                        from: {
                          email: sender.email,
                          name: sender.name,
                        },
                        vars: {
                          ...email.vars,
                          SENDER_GIVEN_NAME: sender.givenName || null,
                        },
                      })
                    }
                  }}
                />
              </Field>
              <Field label={'Reply To'}>
                <Select
                  isClearable
                  value={senderOption(
                    SENDERS.find(
                      (sender) => email?.replyTo?.email === sender.email,
                    ),
                  )}
                  options={SENDERS.map(senderOption).filter(notFalsy)}
                  onChangeValue={(senderEmail) => {
                    onPatch({
                      replyTo: senderEmail
                        ? {
                            email: senderEmail,
                          }
                        : undefined,
                    })
                  }}
                />
              </Field>
            </FieldsRow>

            <Field label={'To'} isRequired>
              <Select<string[]>
                isClearable
                isCreatable
                isMulti
                value={email?.to?.map(senderOption) || []}
                onChangeValue={(emails) => {
                  onPatch({
                    to:
                      emails?.map((email) => ({
                        email,
                      })) || null,
                  })
                }}
              />
            </Field>

            <FieldsRow>
              <Field label={'CC'}>
                <Select<string[]>
                  isClearable
                  isCreatable
                  isMulti
                  value={
                    email?.cc?.map(({ email }) =>
                      senderOption(
                        SENDERS.find((sender) => email === sender.email) || {
                          email,
                        },
                      ),
                    ) || []
                  }
                  options={SENDERS.map(senderOption).filter(notFalsy)}
                  onChangeValue={(ccEmails) => {
                    onPatch({
                      cc:
                        ccEmails?.map((email) => ({
                          email,
                        })) || null,
                    })
                  }}
                />
              </Field>
              <Field label={'BCC'}>
                <Select<string[]>
                  isClearable
                  isCreatable
                  isMulti
                  value={
                    email?.bcc?.map(({ email }) =>
                      senderOption(
                        SENDERS.find((sender) => email === sender.email) || {
                          email,
                        },
                      ),
                    ) || []
                  }
                  options={SENDERS.map(senderOption).filter(notFalsy)}
                  onChangeValue={(bccEmails) => {
                    onPatch({
                      bcc:
                        bccEmails?.map((email) => ({
                          email,
                        })) || null,
                    })
                  }}
                />
              </Field>
            </FieldsRow>

            <FieldsRow>
              {!!templateLanguage?.placeholders.some((varName) =>
                varName.startsWith('EXECUTIVE'),
              ) &&
                workspaceId && (
                  <Field label={'Executive'} isRequired>
                    <Select
                      validationState={
                        email.vars?.EXECUTIVE_ID ? 'default' : 'error'
                      }
                      value={workspace?.executives
                        .map(({ id, profile: { displayName } }) => ({
                          value: id,
                          label: displayName,
                        }))
                        .find(
                          ({ value }) => value === email.vars?.EXECUTIVE_ID,
                        )}
                      options={workspace?.executives.map(
                        ({ id, profile: { displayName } }) => ({
                          value: id,
                          label: displayName,
                        }),
                      )}
                      onChangeValue={setExecutiveId}
                    />
                  </Field>
                )}
              {!!templateLanguage?.placeholders.some((varName) =>
                varName.startsWith('ASSISTANT'),
              ) &&
                workspaceId && (
                  <Field label={'Assistant'} isRequired>
                    <Select
                      validationState={
                        email.vars?.ASSISTANT_ID ? 'default' : 'error'
                      }
                      value={workspace?.assistants
                        .map(({ id, profile: { displayName } }) => ({
                          value: id,
                          label: displayName,
                        }))
                        .find(
                          ({ value }) => value === email.vars?.ASSISTANT_ID,
                        )}
                      options={workspace?.assistants.map(
                        ({ id, profile: { displayName } }) => ({
                          value: id,
                          label: displayName,
                        }),
                      )}
                      onChangeValue={setAssistantId}
                    />
                  </Field>
                )}
            </FieldsRow>

            {templateLanguage?.placeholders.map((varName) =>
              varName.endsWith('IMAGE_URL') ? (
                <Field label={varName} isRequired key={varName}>
                  <ImageUrlField
                    isInvalid={
                      !(
                        typeof email.vars?.[varName] === 'string' &&
                        email.vars?.[varName]?.startsWith('https://')
                      )
                    }
                    value={email.vars?.[varName] || ''}
                    onChangeValue={(value) =>
                      onPatch({
                        vars: {
                          ...email.vars,
                          [varName]: value || '',
                        },
                      })
                    }
                  />
                </Field>
              ) : varName.endsWith('URL') ? (
                <Field label={varName} isRequired key={varName}>
                  <TextField
                    isInvalid={
                      !(
                        typeof email.vars?.[varName] === 'string' &&
                        email.vars?.[varName]?.startsWith('https://')
                      )
                    }
                    value={email.vars?.[varName] || ''}
                    placeholder={'https://...'}
                    onChangeValue={(value) =>
                      onPatch({
                        vars: {
                          ...email.vars,
                          [varName]: value,
                        },
                      })
                    }
                  />
                </Field>
              ) : varName.toUpperCase().startsWith('HAS_') ||
                varName.toUpperCase().startsWith('IS_') ? (
                <Field label={varName} isRequired key={varName}>
                  <Toggle
                    isChecked={!!email.vars?.[varName]}
                    onChange={() =>
                      onPatch({
                        vars: {
                          ...email.vars,
                          [varName]: email.vars?.[varName] ? null : 'ok',
                        },
                      })
                    }
                  />
                </Field>
              ) : (
                <Field label={varName} key={varName} isRequired>
                  <TextField
                    isInvalid={!email.vars?.[varName]}
                    value={email.vars?.[varName] || ''}
                    onChangeValue={(value) =>
                      onPatch({
                        vars: {
                          ...email.vars,
                          [varName]: value,
                        },
                      })
                    }
                  />
                </Field>
              ),
            )}
          </>
        )}

        <LoadingSpinner show={loading} />
      </VarsOuter>
    </>
  )
}

export default Editor
