import React from 'react'
import styled, { css } from 'styled-components'

import { BasicUserFragment } from '../../../graphql'
import UserCell from '../Cell'

const OptionContainer = styled.div<{ isFocused: boolean }>`
  padding: 4px 0 4px 6px;
  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: #e8e9ee;
    `}
`

export const MultiValueLabel = ({
  innerProps,
  data: user,
  innerRef,
}: {
  innerProps: any
  data: BasicUserFragment
  innerRef: any
}) => {
  return <UserCell ref={innerRef} user={user} size={'xsmall'} {...innerProps} />
}

export const Option = ({
  innerRef,
  data: user,
  innerProps,
  isFocused,
}: {
  innerRef: any
  data: BasicUserFragment
  innerProps: any
  isFocused: boolean
}) => {
  return (
    <OptionContainer ref={innerRef} {...innerProps} isFocused={isFocused}>
      <UserCell user={user} size={'small'} />
    </OptionContainer>
  )
}

export const SingleValue = ({
  innerProps,
  data: user,
}: {
  innerProps: any
  data: BasicUserFragment
}) => {
  return <UserCell user={user} size={'small'} {...innerProps} />
}
