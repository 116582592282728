// @ts-ignore
import { Item } from '@atlaskit/navigation-next'
import Tooltip from '@atlaskit/tooltip'

import TeamAvatar from '../../../components/teams/Avatar'
import { UserAvatarsGroup } from '../../../components/users/Avatar'
import { WorkspaceWithTeamFragment } from '../../../graphql'
import { NavLink } from '../NavigationSidebar/styled'

const WorkspaceItem = ({
  workspace,
  isSelected,
  userId,
  friendlyTeamName,
}: {
  userId: string
  isSelected: boolean
  workspace: WorkspaceWithTeamFragment
  friendlyTeamName: string
}) => (
  <NavLink
    key={workspace.id}
    to={`/users/${userId}/workspaces/${workspace.id}`}
    $striked={workspace.isArchived}
  >
    <Item
      isSelected={isSelected}
      text={workspace.executives[0]?.profile.displayName}
      subText={workspace.assistants[0]?.profile.displayName}
      before={() => (
        <UserAvatarsGroup
          size={'small'}
          maxCount={2}
          users={[...workspace.executives, ...workspace.assistants]}
          isPool={workspace.isPool}
        />
      )}
      after={() =>
        workspace.team ? (
          <Tooltip content={friendlyTeamName}>
            <TeamAvatar team={workspace.team} size={'xsmall'} />
          </Tooltip>
        ) : null
      }
      spacing={'compact'}
    />
  </NavLink>
)

export default WorkspaceItem
