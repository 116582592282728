import { Loader, google as Google } from 'google-maps'

const loader = new Loader(process.env.REACT_APP_GOOGLE_API_KEY, {
  libraries: ['places'],
})

let google: Google

loader
  .load()
  .then((googleLib) => (google = googleLib))
  .catch(console.error)

// const fakeMap = document.createElement('div')

export function useGoogle() {
  return {
    // places: google && new google.maps.places.PlacesService(fakeMap),
    placesAutocomplete: google && new google.maps.places.AutocompleteService(),
  }
}
